import Phaser from 'phaser';
import Ribbon from '../../../baseClasses/Ribbon';
import { mainUIResources, quizSoundResources, mainUISoundResources } from '../../../../utils/assetsPath';
import { gridAlignGroupElements, addChildrenToContainer } from '../../../../utils/functions';

export default class ResultWindow extends Phaser.GameObjects.Container{
    constructor(scene,x,y,data){
        super(scene,x,y);
        this.scene = scene;
        this.quizTitle = null;
        this.totalQuestions = null;
        this.correctQuestionsAnswered = null;
        this.wrongQuestionsAnswered = null;
        this.score = null;
    
        this.setData(data);

        this.buildWindow(scene);
    }

    setData(data){
        this.setQuizId(data.id);
        this.setQuizTitle(data.title);
        this.setQuizStatus(data.isCompleted);
        this.setTotalQuestions(data.totalQuestions);
        this.setCorrectQuestions(data.correctAnswers);
        this.setWrongQuestions();
        this.setScore();
    }

    setQuizId(id){
        this.quizId = id;
    }

    setQuizTitle(title){
        this.quizTitle = title;
    }

    setQuizStatus(status){
        this.isCompleted = status;
    }

    setTotalQuestions(number){
        this.totalQuestions =  number;
    }

    setCorrectQuestions(number){
        this.correctQuestionsAnswered = number;
    }

    setWrongQuestions(){
        this.wrongQuestionsAnswered = this.totalQuestions - this.correctQuestionsAnswered;
    }

    setScore(){
        let totalPoints = this.totalQuestions * 5;
        let earnedPoints = this.correctQuestionsAnswered * 5;
        this.score = earnedPoints/totalPoints * totalPoints;
    }

    
    setWindow(scene){
        let container = scene.add.container(0,0);
        container.setSize(1200,960);

        let board = scene.add.graphics();
        board.lineStyle(2,0x000,1).fillStyle(0x726330,1);
        board.fillRoundedRect(-600,-480,1200,960,20)
        .strokeRoundedRect(-600,-480,1200,960,20);

        let innerBody = scene.add.graphics();
        innerBody.fillStyle(0xB1E654,1);
        innerBody.fillRoundedRect(-570,-450,1140,900,20);

        container.add([board,innerBody]);
        return container;
    }

    setRibbon(scene){
        let ribbon = new Ribbon(scene,0,0,mainUIResources.RIBBON_WINDOW_MODAL.key,`PUNTUACIÓN FINAL`);
        ribbon.createRibbon(scene);
        ribbon.changeBodyScale(1.3,1.3);
        ribbon.text.setStyle({
            fontFamily : 'Helvetica',
            fontSize : '40px',
            color : '#000',
            align : 'center',
            stroke : '#000',
            strokeThickness : 2, 
        });
        return ribbon;
    }

    buildWindow(scene){
        this.window = this.setWindow(scene);
        this.ribbon = this.setRibbon(scene);
        this.labels = this.createLabels(scene);
        this.title = this.setTitleBoard(scene);
        this.button = this.setButton(scene);
        this.add([this.window,this.ribbon,this.title,this.labels,this.button]);

        this.activateButton(this.button);

        this.window.setPosition(0,-20);
        this.title.setPosition(0,-235);
        this.ribbon.setPosition(0,-375);
        this.labels.setPosition(0,-100);
        this.button.setPosition(0,300);

        scene.time.addEvent({
            delay : 2000,
            callback : () => {
                this.unlockStars(scene);
            }
        })
    }

    createLabels(scene){
        let container = scene.add.container(0,0),
            width = 500,
            height = 100;

        container.setSize(width,height);
        
        let rightAnswersLabel = this.setWindowLabel(scene,'ACIERTOS');
        let wrongAnswersLabel = this.setWindowLabel(scene,'ERRORES');
        let resultLabel = this.setWindowLabel(scene,'PUNTUACIÓN FINAL');

        this.addPuntuationBox(scene,rightAnswersLabel,this.correctQuestionsAnswered);
        this.addPuntuationBox(scene,wrongAnswersLabel,this.wrongQuestionsAnswered);
        this.addStars(scene,resultLabel);

        let group = scene.add.group([rightAnswersLabel,wrongAnswersLabel,resultLabel]);
        let gridConfig = {
            width : 1,
            height : 3,
            cellHeight : 110,
            position : 6,
            x : 0,
            y : 0
        }

        gridAlignGroupElements(scene,group,gridConfig);
        addChildrenToContainer(container,group.getChildren());
        return container;
    }

    addPuntuationBox(scene,label,value){
        let puntuationBox = this.setPuntuationBox(scene,value);
        label.add(puntuationBox);
        puntuationBox.setPosition(label.width/2-150,label.height/2-50);
    }

    createStars(scene){
        let container = scene.add.container();
        container.setSize(300,100);
        this.starsList = [];
        for(let i=0;i<3;i++){
            let star = scene.add.image(0,0,mainUIResources.STAR.key);
            star.setAlpha(0);
            this.starsList.push(star);
        }
        let group = scene.add.group(this.starsList);
        let gridConfig = {width : 3,height : 1, cellWidth : 100, position : 6,x : -100, y : 20};
        gridAlignGroupElements(scene,group,gridConfig);
        addChildrenToContainer(container,group.getChildren());
        return container;
    }

    showStar(star,scene){
        scene.tweens.add({
            targets : star,
            alpha : {
                from : 0, to : 1},
            duration : 500,
            ease : 'Linear',
            onStart : () =>{scene.sound.play(quizSoundResources.STAR_WINK_EFFECT.key)}
        });
    }

    unlockStars(scene){
        let number = 0;
        let totalPoints = this.totalQuestions * 5;
        if(this.score<(totalPoints * 0.33)){
            number = 0;
            scene.sound.play(quizSoundResources.NO_POINTS_SCORE_EFFECT.key);
        }else if(this.score>=( totalPoints * 0.33 )&&this.score<(totalPoints * 0.66)){
            number = 1;
        }else if(this.score>=( totalPoints * 0.66 )&&this.score<( totalPoints * 1 )){
            number = 2;
        }else if(this.score=== ( totalPoints * 1 )){
            number = 3;
        }

        for(let i = 0;i<number;i++){
            let star = this.starsList[i];
            scene.time.addEvent({delay:500*i,callback:()=>{
                this.showStar(star,scene);
            }})
        }
    }

    addStars(scene,label){
        let stars = this.createStars(scene);
        label.add(stars);

        stars.setPosition(label.width/3,label.height/2-70);
    }

    setTitleBoard(scene){
        let container = scene.add.container(0,0),
            width = 800,
            height = 100,
            radius = 20,
            style = {
                fontFamily : 'Arial Black',
                fontSize : '40px',
                color : '#0C1B7E',
                align : 'center',
                stroke : '#0C1B7E',
                strokeThickness : 1
            },
            sentence = scene.add.text(0,0,`TRIVIA\n"${this.quizTitle}"`,style),
            board = scene.add.graphics();

        container.setSize(width,height);
        board.fillStyle(0xEAF95F,1).lineStyle(2,0x000,1);
        board.fillRoundedRect(-width/2,-height/2,width,height,radius)
        .strokeRoundedRect(-width/2,-height/2,width,height,radius);
        sentence.setOrigin(0.5,0.5);
        container.add([board,sentence]);
        return container;
    }

    setWindowLabel(scene,word){
        let container = scene.add.container(0,0),
            width = 1000,
            height = 100,
            radius = 20;

        container.setSize(width,height);

        let body = scene.add.graphics();
        body.fillStyle(0x1D978E,0.8);
        body.fillRoundedRect(-width/2,-height/2,width,height,radius);
        
        let text = scene.add.text(-width/2+20,-height/2+30,word,{
            fontFamily : 'Arial Black',
            fontSize : '40px',
            color : '#000',
            align : 'center',
            stroke : '#000',
            strokeThickness : 1});
        container.add([body,text]);
        return container;    
    }

    setPuntuationBox(scene,puntuation){
        let container = scene.add.container(),
            width = 200,
            height = 80,
            body = scene.add.graphics(),
            text = scene.add.text(0,0,puntuation),
            style = {
                fontFamily : 'Arial Black',
                fontSize : '40px',
                color : '#000',
                align : 'center',
                stroke : '#000',
                strokeThickness : 1
            };
        
        text.setStyle(style);
        text.setOrigin(0.5);
        container.setSize(width,height);

        body.fillStyle(0xffffff,1)
        .fillRoundedRect(-width/2,-height/2,width,height,20);

        container.add([body,text]);
        return container;
    }

    setButton(scene){
        let container = scene.add.container(0,0),
            width = 500,
            height = 200,
            radius = 20,
            board = scene.add.graphics(),
            innerBody = scene.add.graphics(),
            text = scene.add.text(0,0,'TERMINAR\nLA TRIVIA'),
            style = {
                fontFamily : 'Arial Black',
                fontSize : '40px',
                color : '#0A7111',
                align : 'center',
                stroke : '#0A7111',
                strokeThickness : 1
            };

        container.setSize(width,height);
        board.fillStyle(0x726330,1)
        .fillRoundedRect(-width/2,-height/2,width,height,radius);

        innerBody.fillStyle(0xE2A4A4,1)
        .fillRoundedRect(-width/2+10,-height/2+10,width-20,height-20,radius);

        text.setOrigin(0.5).setStyle(style);

        container.add([board,innerBody,text]);
        return container;
    }

    activateButton(button){
        button.setInteractive();
        button.on('pointerdown',()=>{
            let body = button.getAll()[1],
                text = button.getAll()[2],
                width = button.width,
                height = button.height,
                radius = 20;
            
            body.clear();
            body.fillStyle(0x99C580,1);
            body.fillRoundedRect(-width/2+10,-height/2+10,width-20,height-20,radius);

            text.setColor('#E68E25');
            this.scene.sound.play(mainUISoundResources.ASSISTANT_MENU_BUTTON_CLIC.key);
        });

        button.on('pointerup',()=>{
            let body = button.getAll()[1],
                text = button.getAll()[2],
                width = button.width,
                height = button.height,
                radius = 20;
            
            body.clear();
            body.fillStyle(0xE2A4A4,1);
            body.fillRoundedRect(-width/2+10,-height/2+10,width-20,height-20,radius);
            text.setColor('#0A7111');

            this.scene.events.emit('result_window_done', this.createResultsFile());
        })
    }

    createResultsFile(){
        return {
            quizId : this.quizId,
            quizTitle : this.quizTitle,
            isCompleted : this.isCompleted,
            totalQuestions : this.totalQuestions,
            correctAnswers : this.correctQuestionsAnswered,
            wrongAnswers : this.wrongQuestionsAnswered,
            score : this.score
        }
    }




}