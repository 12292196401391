import apiClient from "../configService";

export function generateQuizRecords(idPlayer){
    return apiClient.post('/quiz_records/player',idPlayer);
}

export function getPlayerQuizRecords(idPlayer){
    return apiClient.get('/quiz_records/player?playerId='+idPlayer);
}

export function updatePlayerQuizRecord(idTale, params){
    return apiClient.put('/quiz_records/quiz_record/'+idTale,params);
}

