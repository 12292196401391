import Phaser from 'phaser';
import { gridAlignGroupElements, addChildrenToContainer } from '../../utils/functions';

export default class Menu extends Phaser.GameObjects.Container{
    constructor(scene,x,y,texture){
        super(scene,x,y);
        this.texture = texture;
        this.childrenList=[];
        this.setBackground(scene);
        this.gridConfig = null;
    }

    addNewChild(child){
        this.childrenList.push(child);
    }

    setBackground(scene){
        this.background = scene.add.image(0,0,this.texture);
        this.add(this.background).setSize(this.background.width,this.background.height);
    }

    prepareChildren(scene){
        this.childrenGroup = scene.add.group(this.childrenList);
        gridAlignGroupElements(scene,this.childrenGroup,this.gridConfig);    
    }

    createMenu(scene){
        this.prepareChildren(scene);
        addChildrenToContainer(this,this.childrenGroup.getChildren());
    }

    changeSize(width,height){
        this.setDisplaySize(width,height);
    }

    chooseButton(button,style){
        this.selectedButton = button;
        this.selectedButton.selectButton(style);
    }

    discardButton(){
        this.childrenList.forEach((btn)=>{
            if(btn!==this.selectedButton){
                btn.deselectButton();
            }
        })
    }

    chooseAssistantButton(button){
        this.selectedButton = button;
        this.selectedButton.changeToPressedButton();
    }

    discardAssistantButton(){
        this.childrenList.forEach((btn)=>{
            if(btn!==this.selectedButton){
                btn.changeToNormalButton();
            }
        })
    }

    activateButtons(style){
        this.childrenList.forEach((button)=>{
            button.setInteractive().on('pointerdown',()=>{
                this.chooseButton(button,style);
            });
            button.setInteractive().on('pointerup',()=>{
                this.discardButton();
            })
        })
    }

    deselectButtons(){
        this.selectedButton = null;
        this.childrenList.forEach((btn)=>{
            btn.deselectButton();
        })
    }

    sleepButtons(){
        this.childrenList.forEach((btn)=>{
            btn.disableInteractive();
            if(btn!==this.selectedButton){
                btn.setAlpha(0.5);
            }
        })
    }

    wakeButtons(isClean){
        this.childrenList.forEach((btn)=>{
            btn.setAlpha(1);
            if(isClean===true){
                btn.changeToNormalButton();
                this.selectedButton = null;
            }
            btn.setInteractive()});   
    }

}