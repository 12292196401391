import Phaser from 'phaser';
import { gridAlignGroupElements, addChildrenToContainer } from '../../../../utils/functions';

export default class DialectField extends Phaser.GameObjects.Container{
    constructor(scene,x,y,info){
        super(scene,x,y);

        this.setInfo(info);

        this.buildField();
    }

    setInfo(info){
        this.info = info;
    }

    setScene(scene){
        this.scene = scene;
    }

    setTextBody(data){
        let style = {
            fontFamily : 'Helvetica',
            // fontSize : '30px',
            align : 'center',
            color : '#F6FA0E',
            stroke : '#F6FA0E',
            strokeThickness : 1
            },
            text = this.scene.add.text(0,0,data,style),
            radius = 15,
            container = this.scene.add.container(),
            body = this.scene.add.graphics();

        let aux = data.split(''),
            width = 360, height = 0;

        if(aux.length>=12){
            text.setFontSize('20px');            
        }else{
            text.setFontSize('30px');
        }
        height = text.height+15;
        
        text.setOrigin(0.5);
        container.setSize(width,height);
        body.fillStyle(0x3C90CB,1)
        .fillRoundedRect(-width/2,-height/2,width,height,radius);

        container.add([body,text]);
        return container;
    }

    setPlainContainer(phrase){
        let container = this.scene.add.container(),
            width = 80,
            height = 80,
            style = {
                fontFamily : 'Helvetica',
                fontSize : '18px',
                align : 'center',
                color : '#000',
                stroke : '#000',
                strokeThickness : 1
            },
            text = this.scene.add.text(0,0,phrase,style);
            text.setOrigin(0.5);

            container.add(text).setSize(width,height);
            return container;
    }

    setBackground(width,height){
        let graphics = this.scene.add.graphics();
        graphics.fillStyle(0x2d2d2d,0.5)
        .fillRoundedRect(-width/2,-height/2,width,height,20);
        this.add(graphics);
    }

    buildField(){
        this.firstPart = this.setPlainContainer('La palabra kichwa:');
        this.kichwaPart = this.setTextBody(this.info.kichwaWord);
        this.spanishPart = this.setTextBody(this.info.spanishWord);
        this.meaningPart = this.setPlainContainer('en español significa:');

        let group = this.scene.add.group([this.firstPart,this.kichwaPart,this.meaningPart,this.spanishPart]),
            gridConfig = {
                width : 1,
                height : group.getChildren().length,
                cellHeight : 40,
                position : 6,
                y : -65
            },
            width = 400,
            height = 170;



        this.setBackground(width,height);
        gridAlignGroupElements(this.scene,group,gridConfig);
        addChildrenToContainer(this,group.getChildren());
        this.scene.add.existing(this);
    }

}