import Page from "../../../baseClasses/page";
import SmallInfoCard from "../game-objects/smallInfoCard";
import { gridAlignGroupElements, addChildrenToContainer } from "../../../../utils/functions";

export default class DescriptionPage extends Page{
    constructor(scene,x,y,title,data){
        super(scene,x,y,'CARTILLA\nINFORMATIVA');
        this.cardsList = [];
        this.infoList = [];
        this.buildPage(scene);
        this.loadData(data,title,scene);
    }


    setTitle(title){
        this.titleName = title;
    }

    setInfoList(data){
        this.infoList = data;
    }

    loadData(data,title,scene){
        this.setInfoList(data);
        this.setTitle(title);
        this.addTitle(scene);
    }

    addInfoCard(x,y,scene,data){
        let infoCard = new SmallInfoCard(scene,x,y,data);
        this.cardsList.push(infoCard)
    }

    deployCards(scene,gridConfig){
        let group = scene.add.group(this.cardsList);
        gridAlignGroupElements(scene,group,gridConfig);
        addChildrenToContainer(this,group.getChildren());
    }

    setDescriptionTitle(scene, text){
        let label = scene.add.graphics();
        label.fillStyle(0x1D978E,1).lineStyle(2,0x000,1);
        label.fillRoundedRect(-225,-25,450,50,15)
        .strokeRoundedRect(-225,-25,450,50,15);

        let title = scene.add.text(0,0,text,{fontFamily:'Helvetica',fontSize:'20px',color:'#000',align:'center'});
        title.setOrigin(0.5);
        let titleContainer = scene.add.container(0,0,[label,title]);
        titleContainer.setSize(450,50);
        return titleContainer;
    }

    addTitle(scene){
        this.title = this.setDescriptionTitle(scene,this.titleName);
        this.title.setPosition(0,-190);
        this.add(this.title);
    }
}