import Page from "../../../baseClasses/page";
import Label from "../../../baseClasses/Label";
import { coverPageResources } from "../../../../utils/assetsPath";

export default class CoverPage extends Page{
    constructor(scene,x,y,taleTitle){
        super(scene,x,y,'PORTADA\nDEL RELATO');
        this.taleTitle = taleTitle;

        this.buildPage(scene);
        this.addPageContent(scene);
    }

    setTitleLabel(scene){
        this.titleLabel = new Label(scene,0,-130,coverPageResources.PAGE_TITLE_LABEL.key,this.taleTitle);
        this.titleLabel.body.setScale(0.95,1);
        this.titleLabel.setTextStyle({fontFamily:'Helvetica', fontSize:'47px',color:'#DD2D06',stroke:'#DD2D06',strokeThickness:2});
        this.add(this.titleLabel);
    }

    setPriceBox(scene){
        this.priceBox = scene.add.image(0,0,coverPageResources.PAGE_PRICE_BOX.key);
        this.price = scene.add.image(0,0,coverPageResources.PAGE_PRICE.key);
        this.price.setTint(0x000);

        let priceContainer = scene.add.container(0,130,[this.priceBox,this.price]);
        priceContainer.setSize(this.priceBox.width,this.priceBox.height);

        this.add(priceContainer);
    }

    addPageContent(scene){
        this.setTitleLabel(scene);
        this.setPriceBox(scene);
    }

    unlockPrice(){
        this.price.clearTint();
    }

    //guide method only
    highlightPrice(){
        this.price.clearTint();
    }
}