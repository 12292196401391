import Button from "../baseClasses/Button";
import Label from "../baseClasses/Label";
import { mainUIResources } from "../../utils/assetsPath";
import { Image } from "../../utils/functions";

export default class MainMenuBtn extends Button{
    constructor(scene,x,y,icon,title){
        super(scene,x,y,mainUIResources.BODY_BTN_MAIN_MENU.key,icon,title);
        this.window = new Image(scene,0,0,mainUIResources.BTN_WINDOW_MAIN_MENU.key);
        this.label = new Label(scene,0,0,mainUIResources.LABEL_MAIN_MENU_BTN.key,this.text);
        this.createWindowContainer(scene);
        this.setupText();
    }
    
    createWindowContainer(scene){
        this.setContainer();
        this.icon.setPosition(-170 ,0);
        this.label.setPosition(70,0);
        this.add([this.icon,this.label]);
    }

    setupText(){
        this.label.text.setFontFamily('Franklin Gothic Heavy');
        this.label.text.setFontSize(26);
        this.label.text.setColor('#000');
        this.label.text.setStroke('#000',1);
    }
}