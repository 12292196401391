import DescriptionPage from "./descriptionPage";
import MeaningCard from "../game-objects/meaningCard";

export default class IngredientsPage extends DescriptionPage{
    constructor(scene,x,y,data,dishName){
        super(scene,x,y,'INGREDIENTES PRINCIPALES');
        this.setDishName(dishName);
        this.addPageContent(scene,data);

    }

    setDishName(name){
        this.dishName = name;
    }

    addPageContent(scene,data){
        let card = new MeaningCard(scene,0,0,data,`Para preparar el ${this.dishName} se necesita: `);
        this.add(card);
    }
}