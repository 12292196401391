import apiClient from "../configService";


export function generateToolRecords (idPlayer){
    return apiClient.post('/tool_records/player',idPlayer);
}

export function getPlayerToolRecords (idPlayer){
    return apiClient.get('/tool_records/player?playerId='+idPlayer);
}

export function updatePlayerToolRecord(idTool, params ){
    return apiClient.put('/tool_records/tool/'+idTool, params );
}