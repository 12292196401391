import { sisayPachaResources, pawkarRaymiResources, allpaCruzResources, intiRaymiResources, pallayRaymiResources, sawariyResources, kullaRaymiResources, riksichiyResources, velanakuyResources, kapakRaymiResources, yumbadaResources, llulluMuruRaymiResources } from "../../resources_path/festivities/festivitiesResources";
import { authorsList } from "../authors/authorsInformation";
import { festivitiesQuizzesList } from "../../quizzes/festivities/festivityQuizzes";
import { festivitiesPuzzlePricesInformation } from "../prices/pricesInformation";

const SISAY_PACHA = {
    title:'Sisay Pacha\nCarnaval',
    coverPhoto:sisayPachaResources.COVER.key,
    description:{
        mainFacts : [
            'Esta fiesta forma parte de la celebración del Pawkar raymi en la región interandina.',
            'Su celebración inicia desde el sábado de carnaval hasta el siguiente sábado durante 8 días.',
            'Esta fiesta es muy extensa por la duración de los 8 días en los cantones de Colta y Guamote.',
            'Se finaliza esta celebración con el entierro del carnaval.',
            'Esta fiesta se celebra en la sierra centro del país en las provincias de Tungurahua, Bolivar, Chimborazo y Cañar.',
            'Esta es temporada de visitar y conversar con la pakcha, el rio, las vertientes.'
        ],
        meaning : [
            'la yaku mama (madre agua)',
            'los granos tiernos de la Pachamama',
            'la unidad',
            'el gran levantamiento cultural',
            'la productividad',
            'la abundancia',
            'la fertilidad',
            'la reciprocidad'
        ],
        ritual : [
            {photo:sisayPachaResources.SISAY_PACHA_01.key, description:'Los taitas, las mamas, los guambras y los comuneros, preparan todo lo necesario como personajes, los trajes, los cánticos, con semanas de antelación.'},
            {photo:sisayPachaResources.SISAY_PACHA_02.key, description:'Afinan los instrumentos andinos, como rondadores, pingullos, quenas, tambores y con guitarras y rondines'},
            {photo:sisayPachaResources.SISAY_PACHA_03.key, description:'Se organizan los warmi tukushkas, los yana tukushkas, los kulta tukushkas, el Tayta Carnaval y la Mama Shalva en grupos de varias personas y siguen una ruta de un sector a otro.'},
            {photo:sisayPachaResources.SISAY_PACHA_04.key, description:'Los grupos visitan a las familias (ayllukuna) de casa en casa, a los compadres y vecinos de la comunidad.'},
            {photo:sisayPachaResources.SISAY_PACHA_05.key, description:'Los grupos ingresan saludando con canticos, coplas y bailes autóctonos de cada sector, como símbolo de respeto y consideración.'},
            {photo:sisayPachaResources.SISAY_PACHA_06.key, description:'Dentro de los hogares se produce la vivencia y el intercambio de cánticos alusivos al amor, a la chakra, a la noche, al día, a los lugares sagrados, a los peligros.'},
            {photo:sisayPachaResources.SISAY_PACHA_07.key, description:'Los dueños de casa brindan los alimentos, como el kuchi kara (el cuero de chancho), el cuy con papas y mote, y la chicha de jora.'},
            {photo:sisayPachaResources.SISAY_PACHA_08.key, description:'Durante esta época los jovenes aprovechan para enamorar a las chicas a través de sus cánticos, bailes y la entonación de instrumentos.'},
            {photo:sisayPachaResources.SISAY_PACHA_09.key, description:'Después de la vivencia los grupos hacen su retirada con agradecimiento por la atención brindada.'},
            {photo:sisayPachaResources.SISAY_PACHA_10.key, description:'Los grupos continuan en la larga lista de visitas que les espera por algunos días más.'},
        ]
    },
    dialects: [
        {kichwaWord : 'yaku mama', spanishWord : 'madre agua'},
        {kichwaWord : 'ayllukuna', spanishWord : 'familias'},
        {kichwaWord : 'kuchi kara', spanishWord : 'cuero de chancho'},
    ]
}

const PAWKAR_RAYMI = {
    title:'Pawkar Raymi\nTumarina',
    coverPhoto:pawkarRaymiResources.COVER.key,
    description:{
        mainFacts : [
            'Es una antigua ritualidad andina realizada en la época del engendramiento y florecimiento de los granos tiernos.',
            'Su celebración inicia desde el martes de carnaval hasta el 21 de marzo cuando ocurre el equinoccio.',
            'Esta fiesta es ampliamente conocida en toda la serranía ecuatoriana.',
            'Esta fiesta se la conoce como Sisay Pacha en la sierra centro y sierra norte del Ecuador',
            'Por otro lado, en zonas como en Puntiatzil, Cayambe se la conoce como Mushuk Nina o Yaya Carnaval',
            'En la sierra centro del país como por ejemplo Tungurahua, Bolivar, Chimborazo y Cañar se la llama Mama Shalva',
            'En esta temporada se acostumbra a visitar y conversar con la pakcha, el rio, las vertientes.',
            'Esta fiesta se celebra en la sierra centro del país en las provincias de Tungurahua, Bolivar, Chimborazo y Cañar.',
            'Una de las actividades tradicionales consiste en practicar el ritual Akcha Rutuchi (ritual de corte de pelo).',
            'El Akcha Rutuchi es realizado cada septenio como parte de la regeneración de la vida.'
        ],
        meaning : [
            'la madre agua(yaku mama)',
            'la madre tierra(allpa mama)',
            'el florecimiento de las chakras',
            'los granos tiernos de la Pachamama',
            'el festejo de vínculos armoniosos con la Pachamama',
            'la variedad de colores',
            'la productividad',
            'la fertilidad',
            'la abundancia'
        ],
        ritual : [
            {photo:pawkarRaymiResources.PAWKAR_RAYMI_01.key, description:'Los ahijados de las comunidades salen a recolectar las flores de: naccha sisa, tauri sisa, tucto sisa, chochos, claveles (wayta) , borraja, de haba, de papa, de oca y de maíz.'},
            {photo:pawkarRaymiResources.PAWKAR_RAYMI_02.key, description:'Se realiza en la madrugada (tutamanta), muy tempranito de manera comunal o familiar, justo cuando los primeros rayos del sol acarician la tierra.'},
            {photo:pawkarRaymiResources.PAWKAR_RAYMI_03.key, description:'Los ahijados van poniendo flores silvestres y flores recogidas de la chakra mojados en aguas de las vertientes o cascadas en la corona de las cabezas de sus padrinos, madrinas y sus familiares, indicando respeto y reverencia mutua.'},
            {photo:pawkarRaymiResources.PAWKAR_RAYMI_04.key, description:'Se pronuncian frases de buenos deseos como "deseo que su vida florezca como las mismas flores de la chakrita"(kikimpak kawsay chakra sisa shina sisayashpa katichun).'},
            {photo:pawkarRaymiResources.PAWKAR_RAYMI_05.key, description:'Después de que todos hayan recibido las flores con agüita en su cabeza, el dueño de casa invita a una comidita.'},
            {photo:pawkarRaymiResources.PAWKAR_RAYMI_06.key, description:'Como parte del ritual las familias crean una kinlla, una puerta que tiene la forma de cruz tejida con hojas del kutul de maíz y adornada con flores de choclo, de claveles y romero.'},
            {photo:pawkarRaymiResources.PAWKAR_RAYMI_07.key, description:'La kinlla es recorrida en forma de procesión por toda la comunidad y es clavada en un sector de la plaza central para proteger a las chakritas de las heladas y de las lanchas.'},
            {photo:pawkarRaymiResources.PAWKAR_RAYMI_08.key, description:'Cuando las familias llegan a la plaza principal con la kinlla, las abuelitas se sientan al frente para compartir la comida, como el maíz fermentada(champus) con rosca tanda y todas las comidas que llevan las familias.'},
            {photo:pawkarRaymiResources.PAWKAR_RAYMI_09.key, description:'Luego de compartir la comida se realizan fiestas y el prioste organiza un baile general para todos.'},            
        ]
    },
    dialects: [
        {kichwaWord : 'Akcha Rutuchi', spanishWord : 'fiesta de corte de pelo'},
        {kichwaWord : 'yaku mama', spanishWord : 'madre agua'},
        {kichwaWord : 'allpa mama', spanishWord : 'madre tierra'},
        {kichwaWord : 'tutamanta', spanishWord : 'madrugada'},
        {kichwaWord : 'wayta(s)', spanishWord : 'clavel(es)'},
        {kichwaWord : 'champus', spanishWord : 'maíz fermentado'},
        {kichwaWord : 'kinlla', spanishWord : 'puerta ceremonial adornada con flores'},
    ]
}

const ALLPA_CRUZ = {
    title:'Allpa Cruz',
    coverPhoto:allpaCruzResources.COVER.key,
    description:{
        mainFacts : [
            'Es un ritual tradicional celebrado del 01 al 03 de mayo en la comunidad Lanpata Santa Cruz, Panzaleo, provincia de Cotopaxi.',
            'La preparación de la romería comienza cuando la chakra de maíz comienza a estar “kau” o el choclo esté bien maduro',
            'El choclo comienza a estar “kau” entre los meses de abril y mayo',
            'Los priostes convocan comparsas con bandas de pueblo, danzantes(tushuk), pingulleros, ashangueros, wakos.',
            'Además los priostes se encargan de organizar la comida, la bebida y las ceremonias religiosas.',
            'A la fiesta se suman además las camisonas, los negros, los saumeriantes, alumbrantas, vacas locas y payasos.',
            'Las familias llevan comidas(kukayu) y bebidas preparadas al prioste de la comunidad.',
        ],
        meaning : [
            'la protección y el renacimiento de las vidas(humanas, vegetales y animales)',
            'la aparición de la constelación de la cruz del sur',
            'las estrellas y al cielo andino(chakana)',
            'el pronóstico de buenos tiempos venideros',
            'el pronóstico de futuras épocas lluviosas',
            'el pronóstico de futuras épocas de sequía'
        ],
        ritual : [
            {photo:allpaCruzResources.ALLPA_CRUZ_01.key, description:'Las familias comienzan el ritual frotando con una vela grande a los miembros de la familia, a los animales domésticos y hasta la chakra'},
            {photo:allpaCruzResources.ALLPA_CRUZ_02.key, description:'Se amarra el pelo, la pluma de cada animal, persona o chakra frotada a la vela.'},
            {photo:allpaCruzResources.ALLPA_CRUZ_03.key, description:'Se prende la vela en la mesa frente al allpa cruz.'},
            {photo:allpaCruzResources.ALLPA_CRUZ_04.key, description:'Se mantienen en silencio y las familias comienzan a observar el comportamiento de la vela como el derrame de sus lágrimas.'},
            {photo:allpaCruzResources.ALLPA_CRUZ_05.key, description:'Se reza y se interpreta el comportamiento de la vela para pronosticar los tiempos venideros de cada miembro familiar.'},
            {photo:allpaCruzResources.ALLPA_CRUZ_06.key, description:'Después comienza la fiesta con las bandas de pueblo, danzantes, pingulleros, ashangueros, wakos, camisona, los negros, los saumeriantes, alumbrantas, vacas locas y payasos.'},
            {photo:allpaCruzResources.ALLPA_CRUZ_07.key, description:'Luego de la fiesta, las familias asisten a misa y al final los priostes invitan a la comida, bebida y bailada en su casa.'}      
        ]
    },
    dialects: [
        {kichwaWord : 'kau', spanishWord : 'cuando el choclo está maduro'},
        {kichwaWord : 'tushuk', spanishWord : 'danzantes'},
        {kichwaWord : 'kukayu', spanishWord : 'comida casera'},
        {kichwaWord : 'chakana', spanishWord : 'cielo andino'},
    ]
}

const INTI_RAYMI = {
    title:'Inti Raymi',
    coverPhoto:intiRaymiResources.COVER.key,
    description:{
        mainFacts : [
            'Es una fiesta ancestral celebrada en el mes de junio o Inti.',
            'Se la realiza en agradecimiento a la pachamama por los frutos otorgados por ella durante ese año.',
            'Es festejada durante el tiempo de la cosecha del maíz y de otros granos tradicionales.',
            'Las fiestas se organizan en cada comunidad por iniciativa propia o por los cabildos para bailar de casa en casa.',
            'Se preparan comidas tradicionales, bebidas, nuevas melodías y canciones representativas sobre las vivencias de esta temporada.',
            'Las fiestas duran aproximadamente una semana y siguen un cronograma de actividades específico'
        ],
        meaning : [
            'la Pachamama',
            'la fertilidad',
            'la reciprocidad',
            'la gratitud',
            'la reciprocidad hacia el cosmos',
            'la cosecha de las semillitas maduras'
        ],
        ritual : [
            {photo:intiRaymiResources.INTI_RAYMI_01.key, description:'El 21 de junio inicia el Inti Raymi, durante este día se repasan cantos y bailes de casa en casa anunciando el inicio de la festividad. Se muelen los granos cosechados y se prepara la buda(uchujaku).'},
            {photo:intiRaymiResources.INTI_RAYMI_02.key, description:'El 22 de junio se realiza el armay tuta, donde los taitas, mamas y wawas acuden a las vertientes o cascadas a bañarse en la madrugada.'},
            {photo:intiRaymiResources.INTI_RAYMI_03.key, description:'El 23 de junio se festeja el waka allichiy puncha, donde se elaboran castillos de carrizo y adornados con frutas, panes y bebidas y danzan por toda la comunidad cargandola.'},
            {photo:intiRaymiResources.INTI_RAYMI_04.key, description:'El 24 de junio se celebra el hatun puncha, donde los comuneros asisten a la misa mayor y se dan un día de descanso de la fiesta.'},
            {photo:intiRaymiResources.INTI_RAYMI_05.key, description:'El 25 de junio ocurre el kallariy capilla puncha, donde se arman tinkus y se forcejean mediante el baile y el canto.'},
            {photo:intiRaymiResources.INTI_RAYMI_06.key, description:'El 26 de junio se festeja el wakcha karay puncha donde se retiran los alimentos colgados del altar y se reparten y se realiza el último baile de casa en casa buscando los puchukuna.'},
            {photo:intiRaymiResources.INTI_RAYMI_07.key, description:'Se "saumerean" a las familias, la casa y las wayunkas de las semillas diciendo "kurukuna kaymanta rinkichy takiwan tushuywanmi kachanchi"(a todos los gusanos/gorgojos vayanse al son de la música y el canto).'}      
        ]
    },
    dialects: [
        {kichwaWord : 'Inti Raymi', spanishWord : 'fiesta del sol'},
        {kichwaWord : 'uchujaku/buda', spanishWord : 'colada'},
    ]
}

const PALLAY_RAYMI = {
    title:'Pallay Raymi\nFiesta de las Octavas',
    coverPhoto:pallayRaymiResources.COVER.key,
    description:{
        mainFacts : [
            'Es una fiesta chacarera ritualística celebrada en el mes de agosto en las comunidades del pueblo Kayambi.',
            'Se festeja para que las semillitas regresen otra vez a reverdecer los campos.',
            'Es una fiesta donde se conmemoran a las últimas cosechas para luego dejar que la tierra descanse.',
            'El nombre Pallay Raymi que significa la fiesta de la cosecha.',
            'Los bailes se lo realizan entre hombres y mujeres y van contrapunteandose en las coplas.',
            'Durante este mes se recogen y se cosechan a las semillitas de diversidad.'
        ],
        meaning : [
            'la madre tierra(allpa mama)',
            'el padre sol(inti yaya)',
            'la madre luna(killa mama)',
            'la madre agua(yaku mama)',
            'en agradecimiento a la Pachamama',
            'la abundancia',
            'la fertilidad'
        ],
        ritual : [
            {photo:pallayRaymiResources.PALLAY_RAYMI_01.key, description:'Los taitas, las mamas van a las vertientes a bañarse y llegan al lugar diciendo lo siguiente: haku pukyuman sinchyashpa tikramunkapak!'},
            {photo:pallayRaymiResources.PALLAY_RAYMI_02.key, description:'En algunos saberes locales del pueblo Kayambi entierran los espíritus que encabezan(aya humas) porque ellos están al frente encabezando al grupo en la fiesta.'},
            {photo:pallayRaymiResources.PALLAY_RAYMI_03.key, description:'Cada familia prepara comida y bebida para recibir a los campanilleros, aruchicos los cuales llegan a cada hogar diciendo: aswata yanuna kanchi tushunkapak shamuk kunaman (debemos cocinar chicha para los bailarines que vendrán)'},
            {photo:pallayRaymiResources.PALLAY_RAYMI_04.key, description:'En esta fiesta las familias suelen preparar: comida a base de los granitos cosechados como el mote pelado acompañado de un pedazo de carne de res y chicha(aswa) o colada (buda).'},
            {photo:pallayRaymiResources.PALLAY_RAYMI_05.key, description:'Los campanilleros y aruchicos(bailarines y músicos) van por las noches de casa en casa entonando y bailando coplas hasta el amanecer.'},
            {photo:pallayRaymiResources.PALLAY_RAYMI_06.key, description:'Van tocando campanillas, flautas, guitarras, rondín, churos y el samarro.  En sus bailes demuestran una gran destreza y habilidad.'},
            {photo:pallayRaymiResources.PALLAY_RAYMI_07.key, description:'Dependiendo de la comunidad, la afinación de las guitarras son diversas. Las danzas de los campanilleros y aruchicos hacen resonar las campanas de bronce y de cobre de sus vestimentas.'},
            {photo:pallayRaymiResources.PALLAY_RAYMI_08.key, description:'Resuenan con fuerza sus campanas para ahuyentar a las malas energías (chikikuna) de los ayus.'},
            {photo:pallayRaymiResources.PALLAY_RAYMI_09.key, description:'Las familias en agradecimiento les brindan la comida y bebida preparada. Si los aruchicos no son bien recibidos, les van insultando en forma de coplas.'},
        ]
    },
    dialects: [
        {kichwaWord : 'inti yaya', spanishWord : 'padre sol'},
        {kichwaWord : 'allpa mama', spanishWord : 'madre tierra'},
        {kichwaWord : 'killa mama', spanishWord : 'madre luna'},
        {kichwaWord : 'yaku mama', spanishWord : 'madre agua'},
        {kichwaWord : 'aya umas/ayus', spanishWord : 'espíritus'},
        {kichwaWord : 'awsa', spanishWord : 'chicha'},
        {kichwaWord : 'uchujaku/buda', spanishWord : 'colada'},
        {kichwaWord : 'aruchiku', spanishWord : 'bailarines musicales'},
        {kichwaWord : 'chikuna', spanishWord : 'malas energías'},
    ]
}

const SAWARIY = {
    title:'Sawariy',
    coverPhoto:sawariyResources.COVER.key,
    description:{
        mainFacts : [
            'El Sawariy es una festividad que conmemora el matrimonio(sawarina) indígena andino en las comunidades del norte del país.',
            'Es un conjunto de diversos rituales y creencias andinas.',
            'Es una de las fiestas familiares más importantes y la más larga.',
            'Esta fiesta es dirigida por comuneros mayores de edad, conocedores de estos rituales.',
            'La conformación de la pareja indígena está estrechamente ligado a la práctica de criar y sustentar a la comunidad.',
        ],
        meaning : [
            'lazos de fraternidad entre familias',
            'el compromiso',
            'el respeto',
            'el florecimiento de la yunta en pareja',
            'la convivencia cariñosa',
            'el amor mutuo'
        ],
        ritual : [
            {photo: sawariyResources.SAWARIY_01.key, description:'En esta festividad participan el Tayta o Mama Servicia, los padrinos y madrinas, los roperos, los alcaldes, los ñaupadores, los músicos, las cocineras y los familiares de los novios'},
            {photo: sawariyResources.SAWARIY_02.key, description:'El Tayta o Mama servicia se encargan de repartir la comida y bebida a todas las familias que acompañan durante todo el proceso de la fiesta.'},
            {photo: sawariyResources.SAWARIY_03.key, description:'Los padrinos se encargan de realizar la celebración andina que incluye la contratación de músicos como el arpa y violín, roperos y alcaldes, la compra de vestimenta apropiada para los novios e implementos de la cocina para los novios.'},
            {photo: sawariyResources.SAWARIY_04.key, description:'Los roperos se encargan de cargar todo el ajuar (vestimentas) de los novios y vestirlos en donde ordenen los padrinos.'},
            {photo: sawariyResources.SAWARIY_05.key, description:'Los alcaldes se encargan de preparar el carrizo grande tuburoso y llenarlo con trago. También preparan una cruz elaborado a base de ortiga, romero y clavel rojo.\nEl ñaupador (tukuy rikuk) es la persona encargada de estar vigilante de todo los que sucede en el sawary, en especial el segundo día de la ceremonia andina.'},
            {photo: sawariyResources.SAWARIY_06.key, description:'Los músicos deben preparar cantos, melodías pertinentes a cada actividad de la festividad.\nLos familiares más cercanos dan la bendición a los novios y consejos y entregan los implementos para la agricultura y la cocina.'},
            {photo: sawariyResources.SAWARIY_07.key, description:'Durante el primer día se realiza el pedido de mano a la novia(maki mañay) llevando papas, cuyes, frutas, gallinas, bebidas mientras bailan al son de la música durante todo el camino hasta el hogar de la novia.'},
            {photo: sawariyResources.SAWARIY_08.key, description:'En el segundo día se realiza el pedido de mano a los padrinos y madrinas(achitaytakunapa maki mañay) de la misma manera.  Posteriormente los padrinos y madrinas organizan el banquete y la bailada con todos los personajes invitados.'},
            {photo: sawariyResources.SAWARIY_09.key, description:'En el tercer día se celebra el matrimonio eclesiástico acompañado de la fiesta organizada por los padrinos. En el cuarto día se realiza el ritual andino ñawi maillay, un lavado de manos, cara y pies con flores y ortiga en una vertiente o cascada(pukllu/pakcha).'},
            {photo: sawariyResources.SAWARIY_10.key, description:'En el quinto día se realiza la fiesta para las cocineras, se prepara comida exclusivamente para ellas y para todas las familias presentes. Los dueños de casa les regalan toda la comida sobrante de la fiesta.'},
        ]
    },
    dialects: [
        {kichwaWord : 'sawarina', spanishWord : 'matrimonio'},
        {kichwaWord : 'ajuar', spanishWord : 'vestimentas'},
        {kichwaWord : 'tukuy rikuk', spanishWord : 'ñaupador'},
        {kichwaWord : 'maki mañay', spanishWord : 'novia'},
        {kichwaWord : 'achitaytakunapa', spanishWord : 'padrinos'},
        {kichwaWord : 'pukllu/pkacha', spanishWord : 'cascadas/vertientes'},
    ]
}

const KULLA_RAYMI = {
    title:'Kulla Raymi',
    coverPhoto:kullaRaymiResources.COVER.key,
    description:{
        mainFacts : [
            'Es una fiesta celebrada en honor a las mujeres, tanto mujer humana, madre tierra y semilla.',
            'Esta fiesta se la celebra en el mes de septiembre, que es el inicio del ciclo agrícola dentro del mundo andino.',
            'Lleva este nombre en honor a la luna señora del sol y de todos los planetas y estrellas.',
            'Época en la que la tierra muestra su esplendor, integridad y fecundidad para recibir a la semilla(kawsaymama).',
            'Las comunidades indígenas, a lo largo de miles de años han actuado como guardianes y protectoras de las semillas.',
            ' Han logrado diversificar un sinfín de variedades locales a través de la siembra y el intercambio.',
            'Las variedades locales no solo sirven como fuente de alimentación sino también como medicina tradicional y espiritual.',
        ],
        meaning : [
            'respeto hacia la luna, señora del sol',
            'respeto hacia las mujeres',
            'respeto hacia la madre tierra',
            'respeto hacia las semillas',
            'la fecundidad de la semilla en el vientre de la allpamama',
            'la semilla como símbolo del retorno y la regeneración de la vida',
            'la fertilidad y la creación de vida'
        ],
        ritual : [
            {photo:kullaRaymiResources.KULLA_RAYMI_01.key, description:'Se arman ferias o mintalay en la comunidad y los mintalaes presentan una gran variedad de semillas.'},
            {photo:kullaRaymiResources.KULLA_RAYMI_02.key, description:'Las familias intercambian las semillas entre si para que florezca la diversidad y la variabilidad de las semillas en sus chakras.'},
            {photo:kullaRaymiResources.KULLA_RAYMI_03.key, description:'Las abuelitas(jatun mama) son encargadas de llevar a las mejores semillas escogidas(akllashka muyukuna) a ser bendecidas y saumeriadas por las madres sabias (yachak mama).'},
            {photo:kullaRaymiResources.KULLA_RAYMI_04.key, description:'Las semillas escogidas son adornadas y floreadas con flores y plantas silvestres por ejemplo ñakcha sisa, aya tawri, aya mashua, allpa quinua.'},
            {photo:kullaRaymiResources.KULLA_RAYMI_05.key, description:'Cuando se presente la sequía en el periodo de la siembra, las semillas bendecidas son sumergidas en la madre agua(yaku mamita).'},
            {photo:kullaRaymiResources.KULLA_RAYMI_06.key, description:'Cuando se presenten lluvias en el periodo de la siembra, las semillas se siembran directamente.'},
            {photo:kullaRaymiResources.KULLA_RAYMI_07.key, description:'Las familas hacen mingas para hacer los surcos(wachukuna) mientras que los niños cantan y gritan a los espíritus de los cerros para que bajen a cuidar sus sembríos.'},
            {photo:kullaRaymiResources.KULLA_RAYMI_08.key, description:'Al finalizar la siembra los encargados ponen chicha en las cuatro esquinas de la chakra para que la tierrita reciba con agrado a las semillas.'},
        ]
    },
    dialects: [
        {kichwaWord : 'kawsaymama', spanishWord : 'semillas'},
        {kichwaWord : 'yachak mama', spanishWord : 'madres sabias'},
        {kichwaWord : 'akllashka muyukuna', spanishWord : 'semillas escogidas'},
        {kichwaWord : 'jatun mama', spanishWord : 'abuelita'},
        {kichwaWord : 'yaku mama', spanishWord : 'madre agua'},
        {kichwaWord : 'wachukuna', spanishWord : 'surcos'},
    ]
}

const RIKSICHIY = {
    title:'Riksichiy\nAkcha Rutuchi',
    coverPhoto:riksichiyResources.COVER.key,
    description:{
        mainFacts : [
            'Es un ritual de padrinazgo(achik tayta, achik mama) donde los padres de familia(ayllu) del niño(a) esperan el reconocimiento(riksichiy) de sus padrinos.',
            'Los padrinos vienen cargados de regalos, exclusivamente de animales domésticos al ahijado(markak churi) o ahijada(markak ushushi).',
            'En este ritual tambien son presentados los hijos de los padrinos a sus ahijados.  Existen diferentes términos para describir este encuentro.',
            'Si son presentados el ahijado y el hijo del padrino se denomina markak wawki.',
            'Si son presentados la ahijada y el hijo del padrino se denomina markak pani.',
            'Si son presentados la ahijada y la hija del padrino se denomina markak ñaña.',
            'Este ritual se lo suele realizar tras cumplirse el primer septenario del ahijado.',
            'En las zonas de la sierra sur del Ecuador este ritual se lo conoce como Akcha Rutuchi.',
            'En el Akcha Rutuchi los padrinos cortan por primera vez el cabello largo de sus ahijados y también les ofrecen regalos.',
            'A las ahijadas se les cortan en promedio 10 cm de cabello.',
            'Este padrinazgo puede durar un septenario o toda la vida dependiendo de quién sea el padrino.',
            'Los padrinos optan por realizar este ritual durante la celebración de las cuatros fiestas andinas importantes del año.',
            'Aunque también se la puede realizar durante alguna festividad local celebrada dentro de cada comunidad andina.'
        ],
        meaning : [
            'el tejido de lazos de familiaridad con otros pueblos o comunidades',
            'respeto hacia los padrinos como guía y consejero espiritual',
            'respeto hacia los ahijados como las nuevas semillas de la sociedad'
        ],
        ritual : [
            {photo:riksichiyResources.RIKSICHIY_01.key, description:'Las familias que reciben a los padrinos arman un lugar exclusivo dentro de sus hogares para realizar el reencuentro.'},
            {photo:riksichiyResources.RIKSICHIY_02.key, description:'Corren a buscar flores, las mejores gallinas, cuy, borregos, se arman coronas de flores para el padrino/madrina.'},
            {photo:riksichiyResources.RIKSICHIY_03.key, description:'Preparan la comilona junto con las bebidas apropiadas para brindarles a los achik tayta/achik mama y los familiares que los acompañan.'},
            {photo:riksichiyResources.RIKSICHIY_04.key, description:'Los padrinos llegan a los hogares y entregan los presentes a sus ahijados. Los ahijados les colocan las coronas de flores silvestres a sus padrinos en señal de agradecimiento.'},
            {photo:riksichiyResources.RIKSICHIY_05.key, description:'Las familias hacen que participen a los ahijados pidiendo la bendición y augurandole buenos deseos a sus padrinos y viceversa.'},
            {photo:riksichiyResources.RIKSICHIY_06.key, description:'En otros casos, las familias arman una mesada con flores, frutos, y los ahijados se colocan en el centro junto con su padrino/madrina donde este le corta el pelo adornado con hilos de colores.'},
            {photo:riksichiyResources.RIKSICHIY_07.key, description:'Una mitad la entregan a los padres y la otra mitad se lleva el padrino/madrina mientras van dando guías y consejos como si fueran sus segundos padres/madres.'},
            {photo:riksichiyResources.RIKSICHIY_08.key, description:'Al final los invitados se sirven la comida preparada y se realiza un gran baile con todos los presentes.'},
        ]
    },
    dialects: [
        {kichwaWord : 'achik tayta', spanishWord : 'apadrinazgo'},
        {kichwaWord : 'achik mama', spanishWord : 'amadrinazgo'},
        {kichwaWord : 'ayllu', spanishWord : 'familia'},
        {kichwaWord : 'riksichiy', spanishWord : 'reconocimiento'},
        {kichwaWord : 'markak churi', spanishWord : 'ahijado'},
        {kichwaWord : 'markak wawki', spanishWord : 'encuentro entre ahijado e hijo del padrino'},
        {kichwaWord : 'markak pani', spanishWord : 'encuentro entre ahijada e hijo del padrino'},
        {kichwaWord : 'markak ñaña', spanishWord : 'encuentro entre ahijada e hija del padrino'},
        {kichwaWord : 'Akcha Rutuchi', spanishWord : 'fiesta del corte de pelo'},
    ]
}

const VELANAKUY = {
    title:'Velanakuy',
    coverPhoto:velanakuyResources.COVER.key,
    description:{
        mainFacts : [
            'El Velanakuy es un ritual de ofrenda a los difuntos o difuntas.',
            'Las comunidades andinas denominan a este ritual como Wakcha Karay.',
            'El Velanakuy se celebra durante los primeros días del mes de noviembre, o sasi, de cada año.',
            'Esta tradición se la celebra durante los días previos a la visita al cementerio.',
            'En esta tradición se honran las vidas de los seres queridos que se han adelantado a otro mundo.',
            'Además las familias honran a los difuntos a través de recuerdos sobre sus sabidurías y vivencias.',
            'Como parte de la tradición, las familias realizan vigilias y visitas a los cementerios.',
            'Durante las vigilias van cargando ofrendas de flores y comida(kukawi) tradicional a los difuntos.',
            'Las comunidades aprovechan el ritual para reflexionar sobre el sentido de la muerte, el ciclo natural en la vida.',
            'A través de la celebración de la vida se honran a los seres queridos que ya no están presentes.',
            'El ánima del difunto está presente a su manera y se debe complacer con comida y bebida de su gusto.',
        ],
        meaning : [
            'los seres queridos fallecidos',
            'la memoria de los difuntos',
            'la reflexión sobre la muerte',
            'el ciclo natural de la vida',
            'la celebración de la vida',
            'las ánimas de los muertos',
            'las sabidurías aprendidas',
            'las experiencias vivenciadas'
        ],
        ritual : [
            {photo:velanakuyResources.VELANAKUY_01.key, description:'La noche anterior las familias escogen el hogar en donde prepararán las ofrendas, los rezos y los juegos dirigidos en nombre del ánima del difunto para que se sienta querido, crezca y los proteja.'},
            {photo:velanakuyResources.VELANAKUY_02.key, description:'Las familias preparan las comidas, como el cuy asado, papas con sarsa de zambo o de maní, así como también las frutas, flores, semillas que más le gustaban al difunto.'},
            {photo:velanakuyResources.VELANAKUY_03.key, description:'Cada integrante de la familia trae una vela pequeña y se frotan nominando a la persona o difunto. Al terminar prenden la vela y la colocan en un altar pequeño.'},
            {photo:velanakuyResources.VELANAKUY_04.key, description:'Mientras la vela se consume y comienza a lagrimear, las familias degustan de las ofrendas del difunto. Ponen fotos del difunto, incienso y agua de flores, comen, cantan ríen, lloran y conversan como eran en vida los difuntos.'},
            {photo:velanakuyResources.VELANAKUY_05.key, description:'Cuando la vela se haya consumido completamente, los familiares empiezan a interpretar las lágrimas de la vela pronosticando el tiempo venidero para los dueños de la vela.'},
            {photo:velanakuyResources.VELANAKUY_06.key, description:'Al día siguiente la familia lleva el kukayu al cementerio para la pampa mikuna(wakcha karay en las comunidades indígenas de Otavalo).'},
        ]
    },
    dialects: [
        {kichwaWord : 'kukawi', spanishWord : 'ofrendas de flores'},
        {kichwaWord : 'kukayu', spanishWord : 'comida casera'},
        {kichwaWord : 'ayllu', spanishWord : 'familia'},
    ]
}

const KAPAK_RAYMI = {
    title:'Kapak Raymi',
    coverPhoto:kapakRaymiResources.COVER.key,
    description:{
        mainFacts : [
            'Es el tiempo del deshierbe y celebración del ciclo de vida inicial de los niños y niñas.',
            'En este tiempo, los sabios ancestros acostumbran a seleccionar los destinos de los hijos(as) de acuerdo a las habilidades que habían demostrado en sus primeros años de vida.',
            'Desde mediados de noviembre e inicios de diciembre cuando las plantas de maíz llegan aproximadamente a 10 cm. de altura se procede a realizar primer deshierbe.',
            'Es necesario tener mucho cuidado para no dañar a las plantas y quitar solamente las hierbas que perjudican la chakra.',
        ],
        meaning : [
            'el inicio del ciclo de vida',
            'el crecimiento de los wawas',
            'la formación de carácter',
            'la elección del rol en la comunidad',
        ],
        ritual : [
            {photo:kapakRaymiResources.KAPAK_RAYMI_01.key, description:'Para el deshierbe se escoge un día del cuarto menguante y que tenga mucho sol, para que las hierbas no benéficas no vuelvan a revivir y multiplicarse de nuevo.'},
            {photo:kapakRaymiResources.KAPAK_RAYMI_02.key, description:'Las familias, realizamos el wachu aysay, que es el acto de romper cuidadosamente los surcos con la yunta y el arado para facilitar el trabajo con el azadón.'},
            {photo:kapakRaymiResources.KAPAK_RAYMI_03.key, description:'En la víspera las familias preparan las herramientas necesarias, así como también la comida y la chicha.'},
            {photo:kapakRaymiResources.KAPAK_RAYMI_04.key, description:'El día del deshierbe las familias se dirigen a la chakra y se sacan el sombrero y cantan en voz alta oraciones plegarias a las divinidades por la fertilidad de la madre tierra.'},
            {photo:kapakRaymiResources.KAPAK_RAYMI_05.key, description:'En el tiempo del Mushuk-Wara, los varones reciben su primer “pantalón” y las mujeres su primera “cinta” para el pelo'},
            {photo:kapakRaymiResources.KAPAK_RAYMI_06.key, description:'A partir de ese momento iniciarían a perfeccionarse en las habilidades y destrezas que hasta ese entonces habían demostrado tener mayor inclinación en sus juegos, imitaciones, gustos, comidas y otros.'},
        ]
    },
    dialects: [
        {kichwaWord : 'wawa(s)', spanishWord : 'niños(as)'},
        {kichwaWord : 'wachu aysay', spanishWord : 'romper con cuidado el surco con la yunta'},
        {kichwaWord : 'ayllu', spanishWord : 'familia'},
    ]
}

const YUMBADA = {
    title:'Yumbada',
    coverPhoto:yumbadaResources.COVER.key,
    description:{
        mainFacts : [
            'Es una fiesta que los convoca en agradecimiento a los frutos recibidos de la tierra.',
            'El danzante yumbo se vuelve "un intermediario entre el cielo y la tierra".',
            'La fiesta se organiza con “priostazgos” llevando la imagen del santo al que los yumbos consideraban muy milagroso.',
            'En la danza participan el pingullero y los capariches.',
            'Acompañan a los danzantes 2 personajes: los monos y el mamaco o pingullero.',
            'Los monos van protegiendo a los yumbos del contacto humano.',
            'El mamaco o pingullero va toca un pequeño tambor con su mano izquierda y un pingullo de 3 notas con la derecha.'
        ],
        meaning : [
            'gratitud',
            'vínculos con las deidades',
            'la fertilidad de las chakras',
            'el ciclo natural de la vida',
            'la generosidad',
            'la candidez',
            'la hospitalidad',
            'la retribución de un árduo trabajo',
            'el trabajo comunitario'
        ],
        ritual : [
            {photo:yumbadaResources.YUMBADA_01.key, description:'Esta fiesta es planeada con antelación durante todo el año.  Las familias participantes se preparan durante todo ese tiempo para preparar las vestimentas tradicionales.'},
            {photo:yumbadaResources.YUMBADA_02.key, description:'La fiesta comienza con la recogida de los Yumbos.  Los comuneros rondan de casa en casa en busca de los danzantes.'},
            {photo:yumbadaResources.YUMBADA_03.key, description:'Las máximas autoridades como el gobernador y los principales cabecillas dan su bendición y consejos para conservar las fuerzas necesarias para danzar durante los tres días que se acostumbra a celebrar esta la fiesta.'},
            {photo:yumbadaResources.YUMBADA_04.key, description:'Las familias preparan las comidas que serán degustadas durante la ceremonia, como el cuy asado, el mote con mapaguira, la fritada, las habas, el choclo, los mellocos, etc.'},
            {photo:yumbadaResources.YUMBADA_05.key, description:'El antialbazo, a través del amanecer y el ocaso, da punto inicial y final oficial al baile.  Los comuneros inician la toma de la plaza central.'},
            {photo:yumbadaResources.YUMBADA_06.key, description:'El domingo festivo se lleva a cabo una misa y se comparten los alimentos(kukayu), los yumbos se ubican en fila a través de toda la plaza para agradecer a la tierra por los alimentos y recibir la bendición de los cabecillas.'},
            {photo:yumbadaResources.YUMBADA_07.key, description:'Los yumbos recogen, cada uno, una porción del alimento para después iniciar el ritual de la matanza.'},
        ]
    },
    dialects: [
        {kichwaWord : 'pingullu', spanishWord : 'flautín tradicional andino'},
        {kichwaWord : 'kukayu', spanishWord : 'comida casera'},
        {kichwaWord : 'yumbus', spanishWord : 'danzantes, músicos tradicionales'},
    ]
}

const LLULLU_MURU_RAYMI = {
    title:'Llullu Muru Raymi',
    coverPhoto:llulluMuruRaymiResources.COVER.key,
    description:{
        mainFacts : [
            'También es conocida como la fiesta de los granos tiernos.',
            'Es una fiesta tradicional celebrada en agradecimiento a la madre tierra y al padre sol por los granos tiernos recibidos.',
            'Se celebra a finales del mes de marzo e inicios del mes de abril de cada año.',
            'Se festeja en la sierra norte ecuatoriana, principalmente en las provincias de Imbabura y Pichincha.',
        ],
        meaning : [
            'el ciclo natural de la vida',
            'la abundancia',
            'la gratitud con la pachamama',
            'vínculos con las deidades',
            'la fertilidad de las chakras',
        ],
        ritual : [
            {photo:llulluMuruRaymiResources.LLULLU_MURU_RAYMI_01.key, description:'Esta fiesta es planeada con antelación.  Las familias participantes se preparan para recibir a esta fiesta con los alimentos, las bebidas, la música y los bailes necesarios.'},
            {photo:llulluMuruRaymiResources.LLULLU_MURU_RAYMI_02.key, description:'Durante esta fiesta se desarrollan ferias para el intercambio de los granos tiernos familiares producidos en la comunidad.'},
            {photo:llulluMuruRaymiResources.LLULLU_MURU_RAYMI_03.key, description:'Esta fiesta es aprovechada para elaborar la fanesca utilizando de misma manera los granos tiernos producidos.'},
            {photo:llulluMuruRaymiResources.LLULLU_MURU_RAYMI_04.key, description:'Se lleva a cabo un desfile de los comuneros, los cuáles son concentrados en la entrada principal de la comunidad.'},
            {photo:llulluMuruRaymiResources.LLULLU_MURU_RAYMI_05.key, description:'Los comuneros procederán a desfilar por cada vivienda de la comunidad al son de la música y los alegres bailes entonados por los músicos.'},
            {photo:llulluMuruRaymiResources.LLULLU_MURU_RAYMI_06.key, description:'El desfile continúa hasta llegar a la plaza central o las casas comunales, donde son esperados más grupos de danza y musicales.'},
            {photo:llulluMuruRaymiResources.LLULLU_MURU_RAYMI_07.key, description:'El domingo festivo se lleva a cabo una misa para bendecir a los granos tiernos y se aprovecha el momento para compartir los alimentos (kukayu) preparados por las familias.'},
        ]
    },
    dialects: [
        {kichwaWord : 'pingullu', spanishWord : 'flautín tradicional andino'},
        {kichwaWord : 'kukayu', spanishWord : 'comida casera'},
    ]
}

export const festivityList = {
    FESTIVITY01: {
        month: 'ENERO',
        type : 'FESTIVIDADES',
        content : YUMBADA,
        author : authorsList.AUTHOR_03,
        quiz : festivitiesQuizzesList.QUIZ_YUMBADA,
        price : festivitiesPuzzlePricesInformation.PUZZLE_PRICE_YUMBADA
    },
    FESTIVITY02: {
        month: 'FEBRERO',
        type : 'FESTIVIDADES',
        content : SISAY_PACHA,
        author : authorsList.AUTHOR_04,
        quiz : festivitiesQuizzesList.QUIZ_SISAY_PACHA,
        price : festivitiesPuzzlePricesInformation.PUZZLE_PRICE_SISAY_PACHA
    },
    FESTIVITY03: {
        month: 'MARZO',
        type : 'FESTIVIDADES',
        content : PAWKAR_RAYMI,
        author : authorsList.AUTHOR_04,
        quiz : festivitiesQuizzesList.QUIZ_PAWKAR_RAYMI,
        price : festivitiesPuzzlePricesInformation.PUZZLE_PRICE_PAWKAR_RAYMI
    },
    FESTIVITY04: {
        month: 'ABRIL',
        type : 'FESTIVIDADES',
        content : LLULLU_MURU_RAYMI,
        author : authorsList.AUTHOR_02,
        quiz : festivitiesQuizzesList.QUIZ_LLULLU_MURU_RAYMI,
        price : festivitiesPuzzlePricesInformation.PUZZLE_PRICE_LLULLU_MURU_RAYMI
    },
    FESTIVITY05: {
        month: 'MAYO',
        type : 'FESTIVIDADES',
        content : ALLPA_CRUZ,
        author : authorsList.AUTHOR_01,
        quiz : festivitiesQuizzesList.QUIZ_ALLPA_CRUZ,
        price : festivitiesPuzzlePricesInformation.PUZZLE_PRICE_ALLPA_CRUZ
    },
    FESTIVITY06: {
        month: 'JUNIO',
        type : 'FESTIVIDADES',
        content : INTI_RAYMI,
        author : authorsList.AUTHOR_05,
        quiz : festivitiesQuizzesList.QUIZ_INTI_RAYMI,
        price : festivitiesPuzzlePricesInformation.PUZZLE_PRICE_INTI_RAYMI
    },
    FESTIVITY07: {
        month: 'JULIO',
        type : 'FESTIVIDADES',
        content : PALLAY_RAYMI,
        author : authorsList.AUTHOR_03,
        quiz : festivitiesQuizzesList.QUIZ_PALLAY_RAYMI,
        price : festivitiesPuzzlePricesInformation.PUZZLE_PRICE_PALLAY_RAYMI
    },
    FESTIVITY08: {
        month: 'AGOSTO',
        type : 'FESTIVIDADES',
        content : SAWARIY,
        author : authorsList.AUTHOR_01,
        quiz : festivitiesQuizzesList.QUIZ_SAWARIY,
        price : festivitiesPuzzlePricesInformation.PUZZLE_PRICE_SAWARIY
    },
    FESTIVITY09: {
        month: 'SEPTIEMBRE',
        type : 'FESTIVIDADES',
        content : KULLA_RAYMI,
        author : authorsList.AUTHOR_05,
        quiz : festivitiesQuizzesList.QUIZ_KULLA_RAYMI,
        price : festivitiesPuzzlePricesInformation.PUZZLE_PRICE_KULLA_RAYMI
    },
    FESTIVITY10: {
        month: 'OCTUBRE',
        type : 'FESTIVIDADES',
        content : RIKSICHIY,
        author : authorsList.AUTHOR_02,
        quiz : festivitiesQuizzesList.QUIZ_RIKSICHIY,
        price : festivitiesPuzzlePricesInformation.PUZZLE_PRICE_RIKSICHIY
    },
    FESTIVITY11: {
        month: 'NOVIEMBRE',
        type : 'FESTIVIDADES',
        content : VELANAKUY,
        author : authorsList.AUTHOR_06,
        quiz : festivitiesQuizzesList.QUIZ_VELANAKUY,
        price : festivitiesPuzzlePricesInformation.PUZZLE_PRICE_VELANAKUY
    },
    FESTIVITY12: {
        month: 'DICIEMBRE',
        type : 'FESTIVIDADES',
        content : KAPAK_RAYMI,
        author : authorsList.AUTHOR_06,
        quiz : festivitiesQuizzesList.QUIZ_KAPAK_RAYMI,
        price : festivitiesPuzzlePricesInformation.PUZZLE_PRICE_KAPAK_RAYMI
    }
}