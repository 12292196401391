import * as types from '../actions/actionTypes';
import initialState from './initial-state/initialState';


export default function toolReducer (state = initialState.tool_records, action){
    switch(action.type){
        case types.CREATE_TOOL_RECORDS_SUCCESS : 
            return state;
  
        case types.LOAD_TOOL_LIST : 
            return action.recordList;
  
        case types.LOAD_TOOL_RECORDS_SUCCESS : 
            return state;

        default : 
            return state;
    }
}