import { seedsInfo } from "../seeds/seedsInformation"
import { booksList } from "../../books/booksInformation"
import { talesTitleResources } from "../../assetsPath"

export const talesTitle = {
    ENERO:{ label : talesTitleResources.TITLE_01.key},
    FEBRERO:{ label : talesTitleResources.TITLE_02.key},
    MARZO:{ label : talesTitleResources.TITLE_03.key},
    ABRIL:{ label : talesTitleResources.TITLE_04.key},
    MAYO:{ label : talesTitleResources.TITLE_05.key},
    JUNIO:{ label : talesTitleResources.TITLE_06.key},
    JULIO:{ label : talesTitleResources.TITLE_07.key},
    AGOSTO:{ label : talesTitleResources.TITLE_08.key},
    SEPTIEMBRE:{ label : talesTitleResources.TITLE_09.key},
    OCTUBRE:{ label : talesTitleResources.TITLE_10.key},
    NOVIEMBRE:{ label : talesTitleResources.TITLE_11.key},
    DICIEMBRE:{ label : talesTitleResources.TITLE_12.key},
    BLOCKED : { label : talesTitleResources.TITLE_UNKNOWN.key}
} 

export const talesInfo = {
    CULLA:{
        id : 1,
        key : 'TALE_CULLA',
        month:'enero',
        seed: null,
        title: talesTitle.ENERO,
        book : booksList.BOOK_01
    },
    PANCHI:{
        id : 2,
        key : 'TALE_PANCHI',
        month:'febrero',
        seed: seedsInfo.ZAMBO,
        title: talesTitle.FEBRERO,
        book : booksList.BOOK_02
    },
    PAUCAR:{
        id : 3, 
        key : 'TALE_PAUCAR',
        month:'marzo',
        seed: seedsInfo.PAPA,
        title: talesTitle.MARZO,
        book : booksList.BOOK_03
    },
    AIRIHUA:{
        id : 4,
        key : 'TALE_AIRIHUA',
        month:'abril',
        seed: seedsInfo.MAIZ,
        title: talesTitle.ABRIL,
        book : booksList.BOOK_04
    },
    AIMURAI:{
        id : 5,
        key : 'TALE_AIMURAI',
        month:'mayo',
        seed: seedsInfo.SAPALLO,
        title: talesTitle.MAYO,
        book : booksList.BOOK_05
    },
    RAIMI:{
        id : 6,
        key : 'TALE_RAIMI',
        month:'junio',
        seed: seedsInfo.CAMOTE,
        title: talesTitle.JUNIO,
        book : booksList.BOOK_06
    },
    SITUA:{
        id : 7,
        key : 'TALE_SITUA',
        month:'julio',
        seed: seedsInfo.ARRACACHA,
        title: talesTitle.JULIO,
        book : booksList.BOOK_07
    },
    CARHUA:{
        id : 8,
        key : 'TALE_CARHUA',
        month:'agosto',
        seed: seedsInfo.JICAMA,
        title: talesTitle.AGOSTO,
        book : booksList.BOOK_08
    },
    CUSQUI:{
        id : 9,
        key : 'TALE_CUSQUI',
        month:'septiembre',
        seed: seedsInfo.MELLOCO,
        title: talesTitle.SEPTIEMBRE,
        book : booksList.BOOK_09
    },
    HUAIRU:{
        id : 10,
        key : 'TALE_HUAIRU',
        month:'octubre',
        seed: seedsInfo.MASHUA,
        title: talesTitle.OCTUBRE,
        book : booksList.BOOK_10
    },
    SASI:{
        id : 11,
        key : 'TALE_SASI',
        month:'noviembre',
        seed: seedsInfo.OCA,
        title: talesTitle.NOVIEMBRE,
        book : booksList.BOOK_11
    },
    CAMAI:{
        id : 12,
        key : 'TALE_CAMAI',
        month:'diciembre',
        seed: seedsInfo.CHOCHO,
        title: talesTitle.DICIEMBRE,
        book : booksList.BOOK_12
    }    
}

export const mainTalesList = [
    ...Object.values(talesInfo)
]

export const quickGameTalesList  = [
    {tales_id : 1, status : "new"},
    {tales_id : 2, status : "blocked"},
    {tales_id : 3, status : "blocked"},
    {tales_id : 4, status : "blocked"},
    {tales_id : 5, status : "blocked"},
    {tales_id : 6, status : "blocked"},
    {tales_id : 7, status : "blocked"},
    {tales_id : 8, status : "blocked"},
    {tales_id : 9, status : "blocked"},
    {tales_id : 10, status : "blocked"},
    {tales_id : 11, status : "blocked"},
    {tales_id : 12, status : "blocked"},
]

export const mockGameTalesList  = [
    {tales_id : 1, status : "new"},
    {tales_id : 2, status : "new"},
    {tales_id : 3, status : "new"},
    {tales_id : 4, status : "new"},
    {tales_id : 5, status : "new"},
    {tales_id : 6, status : "new"},
    {tales_id : 7, status : "new"},
    {tales_id : 8, status : "new"},
    {tales_id : 9, status : "new"},
    {tales_id : 10, status : "new"},
    {tales_id : 11, status : "new"},
    {tales_id : 12, status : "new"},
]