 import Phaser from 'phaser';
import { gridAlignGroupElements, addChildrenToContainer } from '../../../../utils/functions';
import PhotoBox from './photoBox';

export default class InfoBox extends Phaser.GameObjects.Container{
    constructor(scene,x,y,data){
        super(scene,x,y);
        this.loadData(data);

        this.createDataBox(scene);
    }

    setCompleteName(name){
        this.completeName = name;
    }

    setCommunityName(community){
        this.community = community;
    }

    setCommunityState(state){
        this.communityState = state;
    }

    setPhoto(photo){
        this.photo = photo;
    }

    loadData(data){
        this.setCompleteName(data.name);
        this.setCommunityName(data.community);
        this.setCommunityState(data.state);
        this.setPhoto(data.photo);
    }

    setCardBody(scene){
        this.bodyCard = scene.add.graphics();
        this.bodyCard.fillStyle(0x9B8465,1)
        .lineStyle(2,0x000,1);
        this.bodyCard.fillRoundedRect(-225,-190,450,470,15)
        .strokeRoundedRect(-225,-190,450,470,15);
        this.add(this.bodyCard).setSize(470,470); 
    }

    createDataField(scene,data){
        let field = scene.add.graphics();
        field.fillStyle(0xffffff,0.5).lineStyle(1,0x000,0.5);
        field.fillRoundedRect(-150,0,400,40,5)
        .strokeRoundedRect(-150,0,400,40,5);

        let text = scene.add.text(0,0,data,{fontFamily:'Helvetica',fontSize:'20px',color:'#000'});
        text.setOrigin(0.3,-0.4);
        let container = scene.add.container(0,0,[field,text]);
        container.setSize(400,50);

        return container;
    }

    createDataLabel(scene,title){
        let label = scene.add.graphics();
        let text = scene.add.text(7,5,title,{fontFamily:'Helvetica',fontSize:'16px',color:'#000'});
        
        label.fillStyle(0x1D978E,1).lineStyle(1,0x000,1);
        label.fillRoundedRect(-2,0,text.width+20,text.height+10,15)
        .strokeRoundedRect(-2,0,text.width+20,text.height+10,15);
        let container = scene.add.container(0,0,[label,text]);
        container.setSize(100,30);

        return container;
    }

    createField(scene,title,data){
        let labelPart = this.createDataLabel(scene,title);
        let dataPart = this.createDataField(scene,data);

        let group = scene.add.group([labelPart,dataPart]);
        gridAlignGroupElements(scene,group,{width:1,height:2,cellWidth:0,cellHeight:25});

        let container = scene.add.container(0,0,group.getChildren());
        return container;
    }

    createDataBox(scene){
        this.setCardBody(scene);
        this.nameField = this.createField(scene,'NOMBRES COMPLETOS',this.completeName);
        this.communityField = this.createField(scene,'COMUNIDAD',this.community);
        this.stateField = this.createField(scene,'PROVINCIA',this.communityState);

        let group = scene.add.group([this.nameField,this.communityField,this.stateField]);
        gridAlignGroupElements(scene,group,{width:1,height:3,cellWidth:0,cellHeight:85,x:-250,y:50});
        addChildrenToContainer(this,group.getChildren());
        this.add(this.nameField);

        this.addPhoto(scene);
    }

    addPhoto(scene){
        this.photoBox = new PhotoBox(scene,0,-90,this.photo);
        this.photoBox.setScale(0.8);
        this.add(this.photoBox);
    }
}