import Page from "../../../baseClasses/page";
import InfoBox from "../game-objects/infoBox";

export default class IdCardPage extends Page{
    constructor(scene,x,y,author){
        super(scene,x,y,'CARNET DEL TESORO\nVIVIENTE');
        this.buildPage(scene);
        this.setAuthor(author);
        this.addPageContent(scene);
    }

    setAuthor(author){
        this.author = author;
    }

    addInfoBox(scene){
        this.infoBox = new InfoBox(scene,0,0,this.author);
        this.add(this.infoBox);
    }

    addPageContent(scene){
        this.addInfoBox(scene);
    }
}