import initialState from "./initial-state/initialState";
import * as types from '../actions/actionTypes';

export default function newPlayerReducer (state = initialState.newPlayer, action){
    switch(action.type){
        case types.LOAD_NEW_PLAYER : 
            return state
        case types.SET_NEW_PLAYER:
            return action.newPlayer
        default:
            return state;
    }
}