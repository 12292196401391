import Phaser from 'phaser';

export default class Window extends Phaser.GameObjects.Container{
    constructor(scene,x,y,texture){
        super(scene,x,y);
        this.texture = texture;
    }

    createModuleWindow(scene){
        this.moduleWindow = scene.add.image(0,0,this.texture);
    }
}