import { authorsList } from "../authors/authorsInformation"
import { foodQuizzesList } from "../../quizzes/food/foodQuizzes"
import { chuklluKamllaResources, milichakiResources, mishkiKamllaResources, mishkiZamboResources, uchukutaResources, mutiLuciResources, champusResources, makiMachikaResources, yamurAsuaResources, katzuKamllaResources, yanaApiResources, churuApiResources } from "../../resources_path/food/foodResources"
import { foodPuzzlePricesInformation } from "../prices/pricesInformation"


const CHUKLLU_KAMLLA = {
    title : 'Chukllu Kamlla',
    coverPhoto : chuklluKamllaResources.COVER.key,
    description : {
        mainFacts : [
            'El choclo frito (Chukllu Kamlla) es una comida que se prepara cuando salen los primeros choclos en la chakra.',
            'Esta comida anuncia la llegada de los granos tiernos en la chakra, las familias se ponen muy contentas y disfrutan al comerlas.',
            'Para preparar esta comida se deben cosechan solo los choclos tiernos, hasta que cambien de color.',
            'Se come acompañado de cualquier cosa o solito como una golosina o abrebocas aunque solo sabe mucho mejor.',
            'Esta comida se debe comerla calientita en familia porque en frío se anguya (se endura).'
        ],
        ingredients : [
            'Choclo tierno',
            'Aceite o grasa animal',
            'Cebolla blanca',
            'Pizca de sal'
        ],
        preparation : [
            {photo:chuklluKamllaResources.CHUKLLU_KAMLLA_01.key, description:'En un sartén poner el aceite o grasa de animal, la cebolla blanca picada gruesa para que no se quemen y el choclo tierno desgranado a fuego moderado.'},
            {photo:chuklluKamllaResources.CHUKLLU_KAMLLA_02.key, description:'Revolver o mecer constantemente para que se tuesten todos los choclos tiernos de la mejor manera, colocar sal al gusto.'},
            {photo:chuklluKamllaResources.CHUKLLU_KAMLLA_03.key, description:'Cuando los choclos tiernos estén listos se deben sacar de la hornilla.'},
            {photo:chuklluKamllaResources.CHUKLLU_KAMLLA_04.key, description:'Se puede acompañar este plato con arroz, papas o ensaladas, o comerlo solo.'},
        ],
    },
    dialects: [
        {kichwaWord : 'chukllu kamlla', spanishWord : 'choclo frito'},
        {kichwaWord : 'anguya', spanishWord : 'endurecer/se endurece'},
    ]
}
const MILICHAKI = {
    title : 'Milichaki',
    coverPhoto : milichakiResources.COVER.key,
    description : {
        mainFacts : [
            'El milichaki o tamal es un plato típico del pueblo Saraguro.',
            'Se prepara en el mes de febrero antes de las fiestas de carnaval y para celebrar la fiesta de la Supalata.',
            'Se la prepara con las semillas sobrantes del maíz, con las pepas molidas de zambo y mezcladas con quesillo.',
            'Su preparación representa la abundancia de la chakra y por ende las buenas siembras.',
        ],
        ingredients : [
            'mote pelado',
            'quesillo',
            'pepa de zambo',
            'aji'
        ],
        preparation : [
            {photo:milichakiResources.MILICHAKI_01.key, description:'Pelar el maíz sobrante de la puchusara con ceniza de madera de kashko y romerillo'},
            {photo:milichakiResources.MILICHAKI_02.key, description:'Dejar en remojo por una noche el mote pelado.'},
            {photo:milichakiResources.MILICHAKI_03.key, description:'Moler el mote pelado al día siguiente.'},
            {photo:milichakiResources.MILICHAKI_04.key, description:'Mezclar la harina del mote pelado con agua y sal, hasta que tenga una consistencia moldeable'},
            {photo:milichakiResources.MILICHAKI_05.key, description:'Realizar unos moldes ovalados y en el medio poner el shunku al gusto'},
            {photo:milichakiResources.MILICHAKI_06.key, description:'Condimentar al gusto con quesillo, aji o pepas de zambo molida.'},
            {photo:milichakiResources.MILICHAKI_07.key, description:'Cuando esté listo, envolver con hojas de maíz verde.'},
            {photo:milichakiResources.MILICHAKI_08.key, description:'Poner a hervir en poca agua caliente hasta que la hoja este cocinada.'},
        ],
    },
    dialects: [
        {kichwaWord : 'milichaki', spanishWord : 'tamal lojano'},
        {kichwaWord : 'Supalata', spanishWord : 'fiesta de carnaval'},
        {kichwaWord : 'kashko', spanishWord : 'variedad de árbol silvestre'},
        {kichwaWord : 'shunku', spanishWord : 'encurtido'},
        {kichwaWord : 'muyukuna zambu', spanishWord : 'pepa de zambo'},
    ]
}

const MISHKI_ZAMBU = {
    title : 'Mishki Zambo',
    coverPhoto : mishkiZamboResources.COVER.key,
    description : {
        mainFacts : [
            'El Mishki Zambu es un platillo tradicional elaborado a base de zambo bien maduro, y soleado por muchas semanas o meses para que se endulcen con el sol.',
            'Cuando el zambo tenga un color amarillo anaranjado está listo para preparar o cocinar este plato.',
            'El zambo es una planta trepadora que puede dar frutos sobre los árboles grandes.',
            'Hay tres variedades de zambos, el zambo de corteza verde, zambo de corteza verde con blanco y el zambo blanco. Cada uno con sus semillas diferentes.',
        ],
        ingredients : [
            'zambo (zambu)',
            'harina de maíz blanco (sara jaku yurak)',
            'harina de maíz amarillo (sara jaku killu)',
            'mashua (variedad de papa dulce)',
            'panela (mishki)',
            'canela (mishki kara)',
            'leche (ñuñu)'
        ],
        preparation : [
            {photo:mishkiZamboResources.MISHKI_ZAMBU_01.key, description:'Lavar el zambo y golpearlo contra una superficie dura.'},
            {photo:mishkiZamboResources.MISHKI_ZAMBU_02.key, description:'Sacar las cáscaras y el corazón del zambo. Si desea se incluye las semillas en su preparación o se la retira para otros preparados.'},
            {photo:mishkiZamboResources.MISHKI_ZAMBU_03.key, description:'Colocar el zambo en una olla con poca agua y cocinarlo durante un par de horas hasta que esté espeso (hecho colada) y sin hilachas.'},
            {photo:mishkiZamboResources.MISHKI_ZAMBU_04.key, description:'Colocar el resto de ingredientes en la olla de zambo y dejar hervir por unos minutos mientras se mezclan con una wisha(cuchara de palo grande).'},
            {photo:mishkiZamboResources.MISHKI_ZAMBU_05.key, description:'Endulzar el zambo al gusto y servir con rosca tanda (pan redondo integral) o chaquisara.'},
        ],
    },
    dialects: [
        {kichwaWord : 'mishki zambu', spanishWord : 'dulce de zambo'},
        {kichwaWord : 'zambu', spanishWord : 'zambo'},
        {kichwaWord : 'sara jaku yurak', spanishWord : 'harina de maíz blanco'},
        {kichwaWord : 'sara jaku killu', spanishWord : 'harina de maíz amarillo'},
        {kichwaWord : 'mashua', spanishWord : 'variedad de papa dulce'},
        {kichwaWord : 'mishki', spanishWord : 'panela'},
        {kichwaWord : 'mishki kara', spanishWord : 'canela'},
        {kichwaWord : 'ñuñu', spanishWord : 'leche'},
        {kichwaWord : 'ruska tanda', spanishWord : 'pan redondo integral'},
        {kichwaWord : 'wisha', spanishWord : 'cuchara de palo grande'},
    ]
}

const UCHUKUTA = {
    title : 'Uchukuta',
    coverPhoto : uchukutaResources.COVER.key,
    description : {
        mainFacts : [
            'Es una colada o sopa a base de harina de haba, maíz, avilla o alverja, papas.',
            'La Uchukuta tradicionalmente es servida acompañada de carne de cuy asado.',
            'Es una comida nativa nutracéutica tradicional muy antigua, preparada solo en eventos importantes.',
            'La Uchukuta se la come exclusivamente en matrimonios, bautizos, fiestas de la comunidad, velorios.',
            'Es preparada y consumida por las comunidades de las zonas bajas de la provincia de Cotopaxi y de Pichincha.',
            'La Uchukuta es un indicador de estatus comunal, familiar muy alta e importante porque su preparación es costosa.',
        ],
        ingredients : [
            'Papas pequeñas peladas (arapapa)',
            'Harina de haba, maíz, avilla y alverja',
            'Cebolla larga o blanca',
            'Manteca animal',
            'Maní tostado y molido',
            'Ají',
            'Carne de cuy asada',
            'Ajo',
            'Achiote',
            'Huevo duro cocinado',
            'Picadillo de cilantro, cebolla y perejil'
        ],
        preparation : [
            {photo:uchukutaResources.UCHUKUTA_01.key, description:'Se debe preparar un refrito con el ajo, la cebolla y el achote en cualquier manteca.'},
            {photo:uchukutaResources.UCHUKUTA_02.key, description:'Luego se elabora una sopa añadiendo agua al refrito y las harinas del haba, maíz, avilla y alverja previamente disueltas en agua fría.'},
            {photo:uchukutaResources.UCHUKUTA_03.key, description:'Se añade el maní tostado y molido cuando comienza a espesar la sopa.'},
            {photo:uchukutaResources.UCHUKUTA_04.key, description:'Cocinar a parte con sal y cebolla las papas pequeñas junto con la colada.'},
            {photo:uchukutaResources.UCHUKUTA_05.key, description:'Servir el plato con presas de cuy asado en tulpa y acompañado con rodajitas de huevo y picadillo.'},
        ],
    },
    dialects: [
        {kichwaWord : 'arapapa', spanishWord : 'papa pequeña pelada'},
    ]
}

const MUTI_LUCI = {
    title : 'Muti Luci',
    coverPhoto : mutiLuciResources.COVER.key,
    description : {
        mainFacts : [
            'El muti luci (sopita de choclo semi-maduro) es una comida muy ancestral de las comunidades de Imbabura',
            'Se la prepara en los meses de abril o mayo coincidiendo con las fiestas de las cruces',
            'Esta comida está en vías de desaparición debido a la introducción de las comidas modernas en las comunidades indígenas.',
            'Su preparación requiere tiempo y dedicación.',
            'Se hace exclusivamente con choclos blancos o amarillos'
        ],
        ingredients : [
            'Choclo semi-maduro',
            'Fréjol tierno',
            'Hierba buena',
            'Cebolla frita',
            'Sal al gusto',
            'Papas',
            'Carne de borrego',
        ],
        preparation : [
            {photo:mutiLuciResources.MUTI_LUCI_01.key, description:'Se desgrana los choclitos semi maduros recién cosechados de la chakra.'},
            {photo:mutiLuciResources.MUTI_LUCI_02.key, description:'Se debe poner los choclitos desgranados en el tiesto y tostarlos hasta que tome un color amarillo brillante con puntitos negros.'},
            {photo:mutiLuciResources.MUTI_LUCI_03.key, description:'Se muele el gruesu (choclo partido) y se lo pone en la olla para cocinar con el frejol tierno, las papas y la carne de borrego'},
            {photo:mutiLuciResources.MUTI_LUCI_04.key, description:'Se puede condimentar con la cebolla frita, la hierba buena y sal al gusto antes de sacar la sopa.'},
        ],
    },
    dialects: [
        {kichwaWord : 'muti luci', spanishWord : 'sopita de choclo semi-maduro'},
        {kichwaWord : 'gruesu', spanishWord : 'choclo partido'},
    ]
}

const CHAMPUS = {
    title : 'Champus',
    coverPhoto : champusResources.COVER.key,
    description : {
        mainFacts : [
            'Es una comida ritual que se come en fiestas grandes como matrimonios, bautizos, misas de honra.',
            'Aunque también, el chámpus puede ser consumido durante la época de finados.',
            'Es una colada de maíz fermentada, es por eso que algunos pueblos lo llaman yogurt de maíz.',
            'Es una comida nutracéutica porque nutre y cura a quien la consume. Además tiene varios beneficios.',
            'Puede ayudar a prevenir la gastritis y a fortalecer la flora intestinal previniendo el estreñimiento.',
            'La tradición de hacer champús está muy presente en las comunidades de Zumbahua y Saraguro.',
            'Esta tradición también se practica en todos los pueblos de la nacionalidad quichua de Imbabura.'
        ],
        ingredients : [
            'Harina de maíz',
            'Panela',
            'Agua caliente y fría',
            'Hojas de naranja',
            'Arrayán(wila)'
        ],
        preparation : [
            {photo:champusResources.CHAMPUS_01.key, description:'Se desgrana el maíz amarillo o blanco y llevar al molino para moler.'},
            {photo:champusResources.CHAMPUS_02.key, description:'La harina molida se coloca en un recipiente de barro y se pone mitad de agua fría y mitad de agua caliente y se reposa por 7 días.'},
            {photo:champusResources.CHAMPUS_03.key, description:'El champus está listo para cocinarse cuando se encuentre totalmente cubierto de una pasta verde azulada con una pasta blanca.'},
            {photo:champusResources.CHAMPUS_04.key, description:'Antes de poner en la olla para cocinar se debe botar toda la pasta de arriba del fermento hasta que quede totalmente limpio.'},
            {photo:champusResources.CHAMPUS_05.key, description:'Sacar la cantidad que se quiera cocinar y se la pone a hervir durante una hora.'},
            {photo:champusResources.CHAMPUS_06.key, description:'Antes de sacar el chámpus del fuego se debe condimentar con la panela, las hojas de naranja y el arrayán al gusto.'},
            {photo:champusResources.CHAMPUS_07.key, description:'Comer el chámpus con rosca tanda (pancito redondo integral) o pampazo (pancito redondo de maíz)'},
        ],
    },
    dialects: [
        {kichwaWord : 'champus', spanishWord : 'yogurt de maíz'},
        {kichwaWord : 'wila', spanishWord : 'arrayán'},
        {kichwaWord : 'pampazu', spanishWord : 'pan redondo de maíz'},
    ]
}

const MAKI_MACHIKA = {
    title : 'Maki Machika\n(Mashka o Jaku)',
    coverPhoto : makiMachikaResources.COVER.key,
    description : {
        mainFacts : [
            'Es un plato muy tradicional, de la serranía ecuatoriana. Este plato es sinónimo de fortaleza, fuerza, poder.',
            'La Llullu Machika se hace del trigo o la cebada que todavía esta verde.  Su sabor es riquísimo(ninan mishki)',
            'Los mayores tienen un famoso dicho acerca de este plato: "sinchi runakunaka maki machika mikunkuna" (las personas son fuertes cuando comen machica hecha a mano).',
            'Los saberes de otros pueblos indican que se pueden hacer varios tipos de machikas con los distintos granos que se producen en sus comunidades.',
            'Gracias a la diversidad se pueden realizar machikas de: quinua, de maíz, de haba, de trigo, de cebada.',
            'Se pueden mezclar diferentes tipos de machikas como por ejemplo la machika de quinua con maíz y haba, o la machika de trigo y cebada.',
            'Se la suele llevar como golosina(kukayu) al momento de ir a pastar o a las mingas, raymis.',
            'En los matrimonios, se les suele hacer chapu a los novios, suegros, consuegros como una forma de integrar a las familias.',
        ],
        ingredients : [
            'Granos de maíz',
            'Granos de haba',
            'Granos de quinua',
            'Granos de trigo',
            'Granos de cebada'
        ],
        preparation : [
            {photo:makiMachikaResources.MAKI_MACHIKA_01.key, description:'Se corta el tallo con la hoz de la cebadita cuando aún está verde'},
            {photo:makiMachikaResources.MAKI_MACHIKA_02.key, description:'Se golpea la cebada hasta quedar solo con los granos y se avienta con un recipiente en el viento dejando a un lado el tamo, y a otro lado el granito.'},
            {photo:makiMachikaResources.MAKI_MACHIKA_03.key, description:'Con el arnero(ajichu) se quita los sobrantes del tallo y alguna impureza.'},
            {photo:makiMachikaResources.MAKI_MACHIKA_04.key, description:'Antes de poner en la olla para cocinar se debe botar toda la pasta de arriba del fermento hasta que quede totalmente limpio.'},
            {photo:makiMachikaResources.MAKI_MACHIKA_05.key, description:'Se calienta el tiesto(kallana), todos los granitos se ubican en el tiesto y se van recalentando hasta que pierda humedad y quede seco.'},
            {photo:makiMachikaResources.MAKI_MACHIKA_06.key, description:'Se hace una última limpieza refregando con las manitos, se avienta y ya queda lista para llevar a la piedra de moler(kutana rumi)'},
            {photo:makiMachikaResources.MAKI_MACHIKA_07.key, description:'Empieza a brotar un polvo de un olor agradable y así se podrá ver a la machika en su ambiente natural.'},
            {photo:makiMachikaResources.MAKI_MACHIKA_08.key, description:'Se cierne con una cedazo(shushuna) la machika y se pone chicharrón al gusto o queso para hacer machika traposa, saber tradicional de Cotopaxi.'},
        ],
    },
    dialects: [
        {kichwaWord : 'ninan mishki', spanishWord : 'de sabor delicioso'},
        {kichwaWord : 'tamu', spanishWord : 'cebada'},
        {kichwaWord : 'rastrujus', spanishWord : 'tallos'},
        {kichwaWord : 'ajichu', spanishWord : 'arnero'},
        {kichwaWord : 'kallana', spanishWord : 'tiesto'},
        {kichwaWord : 'kutana rumi', spanishWord : 'piedra de moler'},
        {kichwaWord : 'shushuna', spanishWord : 'cedazo'},
    ]
}

const MISHKI_KAMLLA = {
    title : 'Mishki Kamlla',
    coverPhoto : mishkiKamllaResources.COVER.key,
    description : {
        mainFacts : [
            'El tostado dulce(mishki kamlla) es una golosina nutritiva que se la hace en cualquier momento, siempre que haya maíz suave y bien seco.',
            'Este alimento se lo consume especialmente en la época de hambruna(yarjay pacha), en los meses de julio y agosto.',
            'Se les da a los wawas como premio por alguna actividad bien hecha.',
            'Esta golosina es una alternativa al caramelo que se venden en tiendas de las urbes.',
            'Se la suele llevar como golosina(kukayu) al momento de ir a pastar o a las mingas, raymis o para el recreo de los wawas.'
        ],
        ingredients : [
            'Granos de maíz',
            'Agua',
            'Panela',
            'Canela',
            'Aceite'
        ],
        preparation : [
            {photo:mishkiKamllaResources.MISHKI_KAMLLA_01.key, description:'En un recipiente tostar el maíz con el aceite moviendo constantemente sin agregar nada.'},
            {photo:mishkiKamllaResources.MISHKI_KAMLLA_02.key, description:'En una olla o sartén poner agua dependiendo de la cantidad del tostado que hizo.'},
            {photo:mishkiKamllaResources.MISHKI_KAMLLA_03.key, description:'Agregar la canela y la panela y derretir a fuego lento sin dejar de mover hasta que se diluya toda la panela.'},
            {photo:mishkiKamllaResources.MISHKI_KAMLLA_04.key, description:'Agregar el tostado moviendo constantemente hasta que la miel se pegue en el tostado.'},
            {photo:mishkiKamllaResources.MISHKI_KAMLLA_05.key, description:'Sacar los tostados y dejarlos reposar hasta que se enfríen completamente.'}
        ],
    },
    dialects: [
        {kichwaWord : 'mishki kamlla', spanishWord : 'tostado dulce'},
        {kichwaWord : 'yarjay pacha', spanishWord : 'época de hambruna'},
        {kichwaWord : 'kukayu', spanishWord : 'golosina'}
    ]
}

const YAMUR_ASUA = {
    title : 'Yamur Asua',
    coverPhoto : yamurAsuaResources.COVER.key,
    description : {
        mainFacts : [
            'La chicha del Yamor(Yamur Asua) es una bebida especial que se toma solo en ciertos tiempos del ciclo agrícola como el Kulla Raymi.',
            'Es elaborada en honor a la celebración de la fecundidad y la feminidad por el comienzo de la siembra.',
            'Es una bebida muy ceremonial que está relacionada a la crianza de la biodiversidad, de los maíces, de lo humano y de la naturaleza en general.',
            'Para elaborarla se debe bajar los maíces de los soberados (wayungas) y clasificar los diferentes tipos de maíces.',
            'Los maíces clasificados son desgranados y aventados para que el viento se lleve sus basuritas.',
            'Se acostumbra a tostar la variedad de maíces y ser llevados a los molinos.'
        ],
        ingredients : [
            'Chulpi',
            'Granos de maíz blanco',
            'Granos de maíz amarillo',
            'Granos de maíz color ceniza(Ilipta)',
            'Canguil',
            'Morocho blanco',
            'Morocho amarillo',
            'Panela',
            'Hierba luisa',
            'Cedrón',
            'Manzanilla'
        ],
        preparation : [
            {photo:yamurAsuaResources.YAMUR_ASUA_01.key, description:'Se muelen la variedad de maíces, y cernir en un cedazo o cernidor.'},
            {photo:yamurAsuaResources.YAMUR_ASUA_02.key, description:'Se coloca la harina de variedades de maíces en una olla con mucha agua para cocinar y se hierbe durante 2 horas.'},
            {photo:yamurAsuaResources.YAMUR_ASUA_03.key, description:'Se colocan las especias dulces y la panela al gusto minutos antes de sacar la chicha del yamor cocinado.'},
            {photo:yamurAsuaResources.YAMUR_ASUA_04.key, description:'Se deja fermentar durante 4 días en una olla de barro(pundu).'},
            {photo:yamurAsuaResources.YAMUR_ASUA_05.key, description:'Servir con empanadas rellenas con alverja y arroz y tortillas de papa y carne colorada.'},
        ],
    },
    dialects: [
        {kichwaWord : 'yamur ashua', spanishWord : 'chicha del yamor'},
        {kichwaWord : 'yarjay pacha', spanishWord : 'época de hambruna'},
        {kichwaWord : 'kukayu', spanishWord : 'golosina'},
        {kichwaWord : 'wañunga', spanishWord : 'soberado'},
        {kichwaWord : 'pundu', spanishWord : 'olla de barro'},
    ]
    }

const KATZU_KAMLLA = {
    title : 'Katzu Kamlla',
    coverPhoto : katzuKamllaResources.COVER.key,
    description : {
        mainFacts : [
            'Los catzos(Katzu Kamlla) es una comida tradicional de la parte norte de nuestro país, comprendido entre las provincias de Pichincha y Carchi.',
            'Hay cuatro tipos de catzos: catzo blanco, catzo café, catzo verde y catzo negro.',
            'Su consumo es principalmente en el entorno familiar.',
            'En la provincia de Imbabura se lo suele comercializar en los mercados.',
            'Los catzos más ricos son los de color blanco y el café y se los encuentra los primeros días de los meses de lluvia de octubre a diciembre.',
            'Tienen un sabor exquisito y un valor nutricional muy alto.',
            'Los caztos aparecen a las 5:30 de la mañana después de una lluvia y un amanecer nublado.',
            'Primero empieza a volar el catzo alcalde o mayoral, luego empiezan a salir todos los catzos del potrero, y hay que recogerlos silenciosamente.'
        ],
        ingredients : [
            'Catzos(katzus)',
            'Cebolla Blanca',
            'Harina blanca',
            'Sal',
            'Tostado de maíz',
            'Leche'
        ],
        preparation : [
            {photo:katzuKamllaResources.KATZU_KAMLLA_01.key, description:'Se quitan las alas y en otras comunidades, como por ejemplo, en Chimborazo también le quitan las patas.'},
            {photo:katzuKamllaResources.KATZU_KAMLLA_02.key, description:'Se los lava y deja reposar en harina blanca y sal.  En las comunidades de Chimborazo, algunas veces también se los pone en la leche.'},
            {photo:katzuKamllaResources.KATZU_KAMLLA_03.key, description:'Se los fríe a fuego lento con la misma grasa que produce el escarabajo.'},
            {photo:katzuKamllaResources.KATZU_KAMLLA_04.key, description:'Se puede condimentar con cebolla blanca, lo cual se le mezcla con tostado de maíz previamente preparado'}
        ],
    },
    dialects: [
        {kichwaWord : 'katzu kamlla', spanishWord : 'catzos fritos'},
        {kichwaWord : 'katzu(s)', spanishWord : 'escarabajos'},
    ]
}

const YANA_API = {
    title : 'Yana Api',
    coverPhoto : yanaApiResources.COVER.key,
    description : {
        mainFacts : [
            'La colada morada, o (Yana Api), es una bebida espesa de color morado.',
            'Es elaborada a base de harina de maíz morado o negro, frutos rojos, especias y hierbas dulces.',
            'Es una comida tradicional en toda la serranía ecuatoriana.',
            'Se acostumbra a acompañar a la Yana Api con panes de sal en forma de figuritas o muñecos.',
            'La Yana Api tradicional es endulzada utilizando panela.',
            'Se la prepara durante la celebración del día de los difuntos, el 2 de noviembre de cada año.',
            'Se la prepara como una forma para recordar a todos los seres queridos que han fallecido.',
            'A parte de la colada morada también se aprovecha para comer un sin fin de comidas producidas en la chakra.',
        ],
        ingredients : [
            'Harina de maíz morado o negro',
            'Sangoracha(atacoyura)',
            'Amaranto',
            'Jugo de maracuyá',
            'Runa mora',
            'Mortiños',
            'Arrayán(wila)',
            'Flor de canela(Ishpingo)',
            'Canela',
            'Clavos de olor',
            'Pimienta dulce',
            'Anís estrellado',
            'Panela',
            'Hojas de hierba luisa'
        ],
        preparation : [
            {photo:yanaApiResources.YANA_API_01.key, description:'Dejar colgado en las wayungas los maíces para que agarren fuerza.'},
            {photo:yanaApiResources.YANA_API_02.key, description:'Durante el mes de octubre se desgrana los maíces y se muelen para hacer harina de maíz negro(sara haku).'},
            {photo:yanaApiResources.YANA_API_03.key, description:'Colocar la harina de maíz negro en un pondo (pundu/mawma) de barro.'},
            {photo:yanaApiResources.YANA_API_04.key, description:'Mezclar la harina con agua caliente y agua fría y dejarla reposar por una semana.  Botar la pasta oscura hasta que esté limpio el fermento.'},
            {photo:yanaApiResources.YANA_API_05.key, description:'Poner el fermento en la olla para cocinar y hervir durante una hora, tendiendo mucho cuidado que se haga tukru(gelatinoso).'},
            {photo:yanaApiResources.YANA_API_06.key, description:'Agregar el mortiño(pushgay), runa mora, capulí; las hierbas dulces.  Como un secretito, se debe poner un atado de amaranto(ataku yura) por un ratito y se lo saca.'},
            {photo:yanaApiResources.YANA_API_07.key, description:'Condimentar con las hojas de arrayan o wila y la panela al gusto.  Dejar hervir un poquito más y servir con wawa tanda.'},
        ],
    },
    dialects: [
        {kichwaWord : 'yana api', spanishWord : 'colada morada'},
        {kichwaWord : 'atakuyura', spanishWord : 'sangorocha'},
        {kichwaWord : 'wila', spanishWord : 'arrayán'},
        {kichwaWord : 'pundu/mawma', spanishWord : 'pondo'},
        {kichwaWord : 'sara haku', spanishWord : 'harina de maíz negro'},
        {kichwaWord : 'tukru', spanishWord : 'estado gelatinoso'},
        {kichwaWord : 'pushgay', spanishWord : 'mortiño'},
        {kichwaWord : 'ataku yura', spanishWord : 'amaranto'},
    ]
}

const CHURU_API = {
    title : 'Churu Api',
    coverPhoto : churuApiResources.COVER.key,
    description : {
        mainFacts : [
            'El Churu Api se come todo el tiempo especialmente en el mes de noviembre y durante semana santa.',
            'El Churu proviene de las tierras calientes del chota.',
            'Para preparar el churu api, se elabora con harina(uchujaku)'
        ],
        ingredients : [
            'Maíz',
            'Arvejas',
            'Cebada',
            'Habas',
            'Trigo',
            'Lenteja',
            'Poroto Rojo'
        ],
        preparation : [
            {photo:churuApiResources.CHURU_API_01.key, description:'Todos los granos del uchujaku son tostados por separado y molidos con ajo pelado, achiote en pepa y comino.'},
            {photo:churuApiResources.CHURU_API_02.key, description:'En una olla se remoja la harina y se pone a hervir con todos los igredientes de sal.'},
            {photo:churuApiResources.CHURU_API_03.key, description:'Para servir se mezcla con churu, pero antes hay que lavarlo con mucha sal y limón.'}
        ],
    },
    dialects: [
        {kichwaWord : 'churu api', spanishWord : 'sopa/colada de churos'},
        {kichwaWord : 'uchujaku', spanishWord : 'harina'},
        {kichwaWord : 'churu', spanishWord : 'churo'},
    ]
}

export const foodList = {
    FOOD01: {
        month : 'ENERO',
        type : 'COMIDAS',
        content : CHUKLLU_KAMLLA,
        author : authorsList.AUTHOR_01,
        quiz : foodQuizzesList.QUIZ_CHUKLLU_KAMLLA,
        price : foodPuzzlePricesInformation.PUZZLE_PRICE_CHUKLLU_KAMLLA
    },
    FOOD02: {
        month : 'FEBRERO',
        type : 'COMIDAS',
        content : MILICHAKI,
        author : authorsList.AUTHOR_04,
        quiz : foodQuizzesList.QUIZ_MILICHAKI,
        price : foodPuzzlePricesInformation.PUZZLE_PRICE_MILICHAKI
    },
    FOOD03: {
        month : 'MARZO',
        type : 'COMIDAS',
        content : MISHKI_ZAMBU,
        author : authorsList.AUTHOR_04,
        quiz : foodQuizzesList.QUIZ_MISHKI_ZAMBU,
        price : foodPuzzlePricesInformation.PUZZLE_PRICE_MISHKI_ZAMBO
    },
    FOOD04: {
        month : 'ABRIL',
        type : 'COMIDAS',
        content : UCHUKUTA,
        author : authorsList.AUTHOR_06,
        quiz : foodQuizzesList.QUIZ_UCHUKUTA,
        price : foodPuzzlePricesInformation.PUZZLE_PRICE_UCHUKUTA
    },
    FOOD05: {
        month : 'MAYO',
        type : 'COMIDAS',
        content : MUTI_LUCI,
        author : authorsList.AUTHOR_03,
        quiz : foodQuizzesList.QUIZ_MUTI_LUCI,
        price : foodPuzzlePricesInformation.PUZZLE_PRICE_MUTI_LUCI
    },
    FOOD06: {
        month : 'JUNIO',
        type : 'COMIDAS',
        content : CHAMPUS,
        author : authorsList.AUTHOR_02,
        quiz : foodQuizzesList.QUIZ_CHAMPUS,
        price : foodPuzzlePricesInformation.PUZZLE_PRICE_CHAMPUS
    },
    FOOD07: {
        month : 'JULIO',
        type : 'COMIDAS',
        content : MAKI_MACHIKA,
        author : authorsList.AUTHOR_05,
        quiz : foodQuizzesList.QUIZ_MAKI_MACHIKA,
        price : foodPuzzlePricesInformation.PUZZLE_PRICE_MAKI_MACHIKA
    },
    FOOD08: {
        month : 'AGOSTO',
        type : 'COMIDAS',
        content : MISHKI_KAMLLA,
        author : authorsList.AUTHOR_05,
        quiz : foodQuizzesList.QUIZ_MISHKI_KAMLLA,
        price : foodPuzzlePricesInformation.PUZZLE_PRICE_MISHKI_KAMLLA
    },
    FOOD09: {
        month : 'SEPTIEMBRE',
        type : 'COMIDAS',
        content : YAMUR_ASUA,
        author : authorsList.AUTHOR_01,
        quiz : foodQuizzesList.QUIZ_YAMUR_ASUA,
        price : foodPuzzlePricesInformation.PUZZLE_PRICE_YAMUR_ASUA
    },
    FOOD10: {
        month : 'OCTUBRE',
        type : 'COMIDAS',
        content : KATZU_KAMLLA,
        author : authorsList.AUTHOR_03,
        quiz : foodQuizzesList.QUIZ_KAZTU_KAMLLA,
        price : foodPuzzlePricesInformation.PUZZLE_PRICE_KATZU_KAMLLA
    },
    FOOD11: {
        month : 'NOVIEMBRE',
        type : 'COMIDAS',
        content : YANA_API,
        author : authorsList.AUTHOR_06,
        quiz : foodQuizzesList.QUIZ_YANA_API,
        price : foodPuzzlePricesInformation.PUZZLE_PRICE_YANA_API
    },
    FOOD12: {
        month : 'DICIEMBRE',
        type : 'COMIDAS',
        content : CHURU_API,
        author : authorsList.AUTHOR_02,
        quiz : foodQuizzesList.QUIZ_CHURU_API,
        price : foodPuzzlePricesInformation.PUZZLE_PRICE_CHURU_API
    },
}

