import ModuleScene from "./ModuleScene";
import { scenesKey } from "../utils/gameConstants";
import TaleLibrary from "../GameObjects/commonObjects/taleLibrary";
import Tale from "../GameObjects/baseClasses/tale";
import { gridAlignGroupElements } from "../utils/functions";
import { libraryDialogsList } from "../utils/information/assistant/assistantDialogs";
import { mainTalesList } from "../utils/information/tales/talesInformation";

export default class LibraryScene extends ModuleScene{
    constructor(){
        super({key:scenesKey.LIBRARY_SCENE});
    }

    create(){
        this.createWindow('BIBLIOTECA DE RELATOS VIVENCIALES');
        this.displaySceneWindow();

        this.createLibrary();

        this.listenUpcomingEvents();
        this.initialCheckScene();
    }

    addLibrary(){
        this.taleLibrary = new TaleLibrary(this,0,0);
        this.windowScene.addElement(this.taleLibrary);
        this.taleLibrary.setPosition(this.windowScene.width*0.0005,this.windowScene.height*0.070);
        this.taleLibrary.setTaleActions(this);
    }

    createLibrary(){
        this.addLibrary();
    }

    listenUpcomingEvents(){
        this.events.on('tale_completed',(bookData)=>{
            this.taleLibrary.completeATale(bookData);
        })   
    }

    getLibrary(){
        return this.taleLibrary;
    }

    initialCheckScene(){
        let taleLibrary = this.getLibrary(),
            talesList = taleLibrary.talesList;
        if(talesList.length>0){
            let blockedTales = [], newTales = [];
            talesList.forEach(tale => {
                switch(tale.getStatus()){
                    case 'blocked':
                        blockedTales.push(tale);
                        break;
                    case 'new':
                        newTales.push(tale);
                        break;
                    default : 
                    break;
                }
            })
            if(blockedTales.length===11 && newTales.length === 1){
                this.showGuideArrow();
            }else{
                console.log('ya existen tales revisados!');
            }
        }else{
            console.log('no existen registros de tales');
        }
    }


    

    startGuide(){
        this.emitToMainUI('changeVolume',0.2);
        //make sure this doesn't affect the real elements
        let talesList = this.taleLibrary.talesList,
            library = this.taleLibrary.box;
        talesList.forEach((tale) => {
            tale.setVisible(false);
            tale.disableInteractive();
        });
        library.setAlpha(0.5);
        this.createGuideElements();
        // this.time.addEvent({delay:1000,callback:()=>{this.finishGuideAction()}}) 
    }

    createGuideElements(){
        this.guideElementsList = {};
        
        //list of tales
        let talesList = this.addGuideTales();
        this.guideElementsList.talesList = talesList;
    }

    addGuideTales(){
        let talesList = [];
        let gridConfig = { width:4, height:3, cellWidth:220, cellHeight:303,
            position:6, x:-330, y:-220};

        for (let init=0,finish=12;init<finish;init++){
            let tale = new Tale(this,0,0,mainTalesList[init], 'guide');
            tale.setAlpha(0.5);
            talesList.push(tale);
        }
        talesList[0].changeGuideStatus('new');
        let group = this.add.group(talesList);
        gridAlignGroupElements(this,group,gridConfig);
        group.getChildren().forEach((tale)=>{
            this.windowScene.addElement(tale);
        })
        return talesList;
    }

    showGuideLibrary(){
        let library = this.taleLibrary.box,
            talesList = this.guideElementsList.talesList;
        library.setAlpha(1);
        talesList.forEach((tale)=>{tale.setAlpha(1)});
        // this.time.addEvent({delay:1000,callback:()=>{this.finishGuideAction()}}) 
    }

    showNewStatus(){
        let talesList = this.guideElementsList.talesList,
            tale = talesList[0],
            library = this.taleLibrary.box;
        
        library.setAlpha(0.5);
        talesList.forEach((t)=>{
            if(t!==tale){
                t.setAlpha(0.5);
            }
        })
        // this.time.addEvent({delay:1000,callback:()=>{this.finishGuideAction()}}) 
    }

    showSeenStatus(){
        let tale = this.guideElementsList.talesList[0];
        tale.changeGuideStatus('seen');
        // this.time.addEvent({delay:1000,callback:()=>{this.finishGuideAction()}}) 
    }

    showCompletedStatus(){
        let tale = this.guideElementsList.talesList[0];
        tale.changeGuideStatus('completed');
        // this.time.addEvent({delay:1000,callback:()=>{this.finishGuideAction()}}) 
    }

    showBlockedStatus(){
        let talesList = this.guideElementsList.talesList,
            tale = talesList[0],
            library = this.taleLibrary.box;
        
        tale.setAlpha(0.5);
        library.setAlpha(1);
        talesList.forEach((t)=>{
            if(t!==tale){
                t.setAlpha(1);
            }
        })
        // this.time.addEvent({delay:1000,callback:()=>{this.finishGuideAction()}}) 
    }

    showTaleProducts(){
        let talesList = this.guideElementsList.talesList,
            tale = talesList[3],
            library = this.taleLibrary.box;
        library.setAlpha(0.5);
        talesList.forEach((t)=>{
            if(t!==tale){
                t.setAlpha(0.5);
            }
        })
        // this.time.addEvent({delay:1000,callback:()=>{this.finishGuideAction()}}) 
    }

    hideGuideElements(){
        let talesList = this.guideElementsList.talesList;
        talesList.forEach((tale)=>{
            tale.setAlpha(0.5);
        })
        // this.time.addEvent({delay:1000,callback:()=>{this.finishGuideAction()}}) 
    }

    createGuide(){
        this.startGuide();
        this.showGuidePart(libraryDialogsList.guide_dialogs.INTRO_DIALOG,
        500,()=>{this.showFirstGuidePart()});
        setTimeout(()=>{this.disableOmitButton()},1000);
    }

    showFirstGuidePart(){
        this.showGuideLibrary();
        this.showGuidePart(libraryDialogsList.guide_dialogs.DIALOG_01,
        500,()=>{this.showSecondGuidePart()});
    }

    showSecondGuidePart(){
        this.showNewStatus();
        this.showGuidePart(libraryDialogsList.guide_dialogs.DIALOG_02,
        500,()=>{this.showThirdGuidePart()});
    }

    showThirdGuidePart(){
        this.showSeenStatus();
        this.showGuidePart(libraryDialogsList.guide_dialogs.DIALOG_03,
        500,()=>{this.showFourthGuidePart()});
    }

    showFourthGuidePart(){
        this.showCompletedStatus();
        this.showGuidePart(libraryDialogsList.guide_dialogs.DIALOG_04,
        500,()=>{this.showFifthGuidePart()});
    }

    showFifthGuidePart(){
        this.showBlockedStatus();
        this.showGuidePart(libraryDialogsList.guide_dialogs.DIALOG_05,
        500,()=>{this.showSixthGuidePart()});
    }

    showSixthGuidePart(){
        this.showTaleProducts();
        this.showGuidePart(libraryDialogsList.guide_dialogs.DIALOG_06,
        500,()=>{this.showLastGuidePart()});
    }

    showLastGuidePart(){
        this.hideGuideElements();
        this.showGuidePart(libraryDialogsList.guide_dialogs.FINAL_DIALOG,
        500,()=>{
            this.deleteGuideElements();
            this.quitGuide()});
        setTimeout(()=>{this.disableOmitButton()},1000);    
    }

    quitGuide(){
        this.emitToMainUI('changeVolume',0.8);
        //make sure the elements return safe and sounnd
        let talesList = this.taleLibrary.talesList,
            library = this.taleLibrary.box;
        talesList.forEach((tale) => {
            tale.setVisible(true);
            tale.setInteractive();
        });
        library.setAlpha(1);
    }

    deleteGuideElements(){
        let talesList = this.guideElementsList.talesList;
        talesList.forEach((tale)=>{
            tale.destroy();
        })
    }

    endGuide(){
        this.deleteGuideElements();
        this.quitGuide();
    }
}