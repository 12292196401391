import WindowModal from "../baseClasses/windowModal";
import { changeBtnAppereance } from "../../utils/functions";
import { scenesKey } from "../../utils/gameConstants";
import { mainUISoundResources } from "../../utils/assetsPath";

export default class ConfirmationModal extends WindowModal{
    constructor(scene,x,y){
        super(scene,x,y);
        this.setScene(scene);
        this.description = null;
        this.title = null;
    }

    setScene(scene){ this.scene = scene}
    getScene(){ return this.scene }

    setValues(title,message){
        this.setTitle(title);
        this.setDescription(message);
    }

    setDescription(description){
        this.description = description;
    }

    setTitle(title){
        this.title = title;
    }
   
    buildModal(scene){
        this.setBody(scene);
        this.setRibbon(scene,this.title);
        this.showMessage(this.description);
        this.addAuxButtons();
        scene.add.existing(this);
    }

    activeButtons(yesAction,noAction){
        this.yesButton.setInteractive();
        this.noButton.setInteractive();

        this.yesButton.on('pointerdown',()=>{
            this.getScene().sound.play(mainUISoundResources.DIALOG_MODAL_BUTTON_CLIC.key);
            changeBtnAppereance(this.yesButton,0x99C580,'#E68E25');
        });

        this.yesButton.on('pointerup',()=>{
            changeBtnAppereance(this.yesButton,0x78D25E,'#e92929');
            yesAction();
        });

        this.noButton.on('pointerdown',()=>{
            this.getScene().sound.play(mainUISoundResources.DIALOG_MODAL_BUTTON_CLIC.key);
            changeBtnAppereance(this.noButton,0x99C580,'#E68E25');
        });

        this.noButton.on('pointerup',()=>{
            changeBtnAppereance(this.noButton,0x78D25E,'#e92929');
            noAction();
        });
    }

    createMessage(message){
        let container = this.scene.add.container(0,0),
            style = {
                fontFamily:'Segoe UI',
                fontSize:'40px',
                color:'#f30000',
                align:'center',
                stroke:'#f30000',
                strokeThickness:2
            },
            text = this.scene.add.text(0,0,message,style),
            width = text.width + 20,
            height = text.height + 20,
            radius = 20,
            box = this.scene.add.graphics();

        container.setSize(width,height);
        text.setOrigin(0.5,0.5);
        box.fillStyle(0xE3E63B,1).lineStyle(2,0x000,1)
        .fillRoundedRect(-width/2,-height/2,width,height,radius)
        .strokeRoundedRect(-width/2,-height/2,width,height,radius);

        container.add([box,text]);
        return container;
    }

    showMessage(message){
        this.message = this.createMessage(message);
        this.add(this.message);
        this.message.setPosition(0,-200);
    }

    cancelNewQuizTry(result){
        let selectedTaleScene = this.scene.scene.get(localStorage.getItem('selectedTale'));
        selectedTaleScene.events.emit('result_modal_closed');
        this.scene.scene.switch(localStorage.getItem('selectedTale'));
        let mainMenuUI = this.scene.scene.get(scenesKey.MAINUI_SCENE);
        mainMenuUI.activeButtons();
        localStorage.removeItem('selectedQuiz');
        localStorage.setItem('newScore',JSON.stringify(result));

        this.updateChapterQuizScore();
    }

    updateChapterQuizScore(){
        let selectedTaleScene = this.scene.scene.get(localStorage.getItem('selectedTale'));
        selectedTaleScene.events.emit('update_quiz_score');
    }
    

}