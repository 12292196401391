import { CREATE_TALE_RECORDS_SUCCESS, LOAD_TALE_RECORDS_SUCCESS, LOAD_TALES_LIST } from './actionTypes';
import { generateTaleRecords, getPlayerTaleRecords } from '../services/gameServices/talesService';

export function createNewTaleRecords(idPlayer){
    return (dispatch)=>{
        return generateTaleRecords(idPlayer).then(response => {
            dispatch(createTaleRecordsSuccess())
        }).catch(error => {throw(error)})
    }
}

export function loadPlayerTaleRecords(idPlayer){
    return dispatch => {
        return getPlayerTaleRecords(idPlayer).then(response => {
            let talesList = response.data;
            dispatch(loadTalesList(talesList));
            dispatch(loadTaleRecordsSuccess());
        }).catch(error => {throw error});
    }
}

function createTaleRecordsSuccess(){
    return {type : CREATE_TALE_RECORDS_SUCCESS}
}

function loadTalesList(recordList){
    return { type : LOAD_TALES_LIST, recordList }
}

function loadTaleRecordsSuccess(){
    return {type : LOAD_TALE_RECORDS_SUCCESS}
}