import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
// import { bindActionCreators } from 'redux';
import * as playerActions from '../../../actions/playerActions';

function LoginButton(props){
    let history = useHistory();
    let {givenCI, givenPassword} = props;

    // let loginPlayer = (credentials) => {
    //     props.actions.login(credentials);
    // }

    let handleClick = ()=>{
        if(givenCI!==null && givenPassword!==null){
            if(givenCI!=='' && givenPassword!==''){
                let credentials = {CI : givenCI, password : givenPassword};
                props.loginPlayer(credentials);
                setTimeout(()=>{history.push('/game')},500);
            }else{
                console.log('no deje los campos en blanco!');
            }
        }else{
            console.log('llene los campos necesarios!');
        }
    }
    
    return (
        <Button onClick = {handleClick} style ={{width:'100%'}}>
            Log In!
        </Button>
    );  
}

function mapStateToProps(state){
    return {};
}

function mapDispatchToProps(dispatch){
    return {
        // actions : bindActionCreators(playerActions,dispatch)
        loginPlayer : credentials => dispatch(playerActions.login(credentials))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(LoginButton);