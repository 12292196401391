import { scenesKey } from '../../../utils/gameConstants';
import FormScene from '../baseScenes/formScene';
import { domResources } from '../../../utils/assetsPath';
import { postNewPlayer } from '../../../../../services/reactServices/playerService';
import { generateTaleRecords } from '../../../../../services/gameServices/talesService';
import { generateToolRecords } from '../../../../../services/gameServices/toolsService';
import { generateQuizRecords } from '../../../../../services/gameServices/quizService';
import { generateSeedRecords } from '../../../../../services/gameServices/seedService';
import { generatePuzzlePieceRecords } from '../../../../../services/gameServices/piecesService';
import { generateChakraRecords } from '../../../../../services/gameServices/chakraService';

const expresiones = {
	usuario: /^[a-zA-Z0-9\_\-]{4,16}$/, // Letras, numeros, guion y guion_bajo
	nombre: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, // Letras y espacios, pueden llevar acentos.
	password: /^.{4,12}$/, // 4 a 12 digitos.
	correo: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
	cedula: /^\d{7,10}$/ // 7 a 10 numeros.
}

const inputsFields = {
    usuario : {name : 'grupo__usuario', status : false},
    nombre : {name : 'grupo__nombre', status : false},
    password : {name : 'grupo__password',status : false},
    password2 : {name : 'grupo__password2', status : false},
    correo : {name : 'grupo__correo',status: false},
    cedula : {name : 'grupo__cedula',status : false}
}

export default class SignUpScene extends FormScene{
    constructor(){
        super({key: scenesKey.SIGNUP_SCENE});
        this.setInitialProperties();
        this.setFormData(domResources.FORM_SIGNUP.key);
    }

    setUserNickName(userName){ this.userNickName = userName }
    getUserNickName(){ return this.userNickName }

    setUserName(username){ this.userName = username }
    getUserName(){ return this.userName }

    setUserPassword(userPassword){ this.userPassword = userPassword }
    getUserPassword(){ return this.userPassword }

    setUserEmail(userEmail){ this.userEmail = userEmail }
    getUserEmail(){ return this.userEmail }

    setUserCI(userCI){ this.userCI = userCI }
    getUserCI(){ return this.userCI }

    setInitialProperties(){
        this.setUserNickName(null);
        this.setUserName(null);
        this.setUserPassword(null);
        this.setUserEmail(null);
        this.setUserCI(null);
    }

    create(){
        this.buildScene();
        this.activeForm();
    }

    buildScene(){
        this.addBackground();
        this.addForm();
        this.addGameTitle();
        this.zoomInBackground();
        this.createReturnOption();
        this.redirectScene();
        this.addEventsToInputs();

    }

    redirectScene(){
        let returnOption = this.getReturnOption().getAll()[0];
        returnOption.on('pointerup',()=>{
            this.scene.start(scenesKey.WELCOME_SCENE);
        })
    }

    activeForm(){
        let form = this.getForm();
        form.addListener("submit");
        form.on("submit",(event)=>{
            event.preventDefault()
            let terminos = document.getElementById('terminos');
            if(inputsFields.usuario.status && inputsFields.nombre.status && 
               inputsFields.password.status && inputsFields.cedula.status && inputsFields.correo.status&&terminos.checked){
                this.sendToDatabase(this.getInputsData());   
                this.resetForm();
                document.getElementById('formulario__mensaje-exito').classList.add('formulario__mensaje-exito-activo');
                document.getElementById('formulario__mensaje').classList.remove('formulario__mensaje-activo');
                setTimeout(()=>{document.getElementById('formulario__mensaje-exito').classList.remove('formulario__mensaje-exito-activo')},2000);   
                   
               }else{
                document.getElementById('formulario__mensaje').classList.add('formulario__mensaje-activo');
               }
        })
    }

    addEventsToInputs(){
        let inputsList = document.querySelectorAll('#formulario input');
        
        inputsList.forEach(input => {
            input.addEventListener('keyup',()=>{
                this.validateInput(input);
            })
            input.addEventListener('blur',()=>{
                this.validateInput(input);
            })
        })
        
    }

    resetForm(){
        //clean form
        let formulario = document.getElementById('formulario');
        formulario.reset();

        //clean inputs status
        let inputsData = [Object.values(inputsFields)];
        inputsData.forEach(data => { data.status = false });

        //remove input icons
        document.querySelectorAll('.formulario__grupo-correcto').forEach(icono =>{
            icono.classList.remove('formulario__grupo-correcto');
        })

        this.resetInputValues();
    }

    getInputsData(){
        let inputsList = document.querySelectorAll('#formulario input');
        let newPlayerRecord = {};
        inputsList.forEach(input => {
            this.saveInputData(input);
        })
        newPlayerRecord.nickname = this.getUserNickName();
        newPlayerRecord.name = this.getUserName();
        newPlayerRecord.CI = this.getUserCI();
        newPlayerRecord.password = this.getUserPassword();
        newPlayerRecord.email = this.getUserEmail();

        return newPlayerRecord;
    }

    saveInputData(input){
        let inputName = input.name,
            inputValue = input.value;
        switch(inputName){
            case 'usuario':
                this.setUserNickName(inputValue);
            break;

            case 'nombre':
                this.setUserName(inputValue);
            break;

            case 'password':
                this.setUserPassword(inputValue);
            break;

            case 'correo':
                this.setUserEmail(inputValue);
            break;

            case 'cedula':
                this.setUserCI(inputValue);
            break;

            default :
            break;
        }
    }

    resetInputValues(){
        this.setUserNickName(null);
        this.setUserName(null);
        this.setUserPassword(null);
        this.setUserEmail(null);
        this.setUserCI(null);
    }

    validateInput(input){
        let inputName = input.name,
            inputValue = input.value;
        switch(inputName){
            case 'usuario':
                this.validateField(expresiones.usuario,inputValue,inputsFields.usuario);
            break;
            case 'nombre':
                this.validateField(expresiones.nombre,inputValue,inputsFields.nombre);
            break;
            case 'password':
                this.validateField(expresiones.password,inputValue,inputsFields.password);
                this.validatePassword2();
            break;
            case 'password2':
                this.validatePassword2();
            break;
            case 'correo':
                this.validateField(expresiones.correo,inputValue,inputsFields.correo);
            break;
            case 'cedula':
                this.validateField(expresiones.cedula,inputValue,inputsFields.cedula);
            break;
            default:
            break;
        }
    }

    validateField(expression, inputValue, inputField){
        let inputName = inputField.name;
        if(expression.test(inputValue)){
            document.getElementById(inputName).classList.add('formulario__grupo-correcto');
            document.getElementById(inputName).classList.remove('formulario__grupo-incorrecto');
            document.querySelector('#'+inputName+' i').classList.add('fa-check-circle');
            document.querySelector('#'+inputName+' i').classList.remove('fa-times-circle');
            document.querySelector('#'+inputName+' .formulario__input-error').classList.remove('formulario__input-error-activo');
            inputField.status = true;
        }else{
            document.getElementById(inputName).classList.add('formulario__grupo-incorrecto');
            document.getElementById(inputName).classList.remove('formulario__grupo-correcto');
            document.querySelector('#'+inputName+' i').classList.add('fa-times-circle');
            document.querySelector('#'+inputName+' i').classList.remove('fa-check-circle');
            document.querySelector('#'+inputName+' .formulario__input-error').classList.add('formulario__input-error-activo');
            inputField.status = false;
        }
    }

    validatePassword2(){
        let inputPassword = document.getElementById('password'),
            inputPassword2 = document.getElementById('password2');

        if(inputPassword.value === inputPassword2.value){
            document.getElementById('grupo__password2').classList.add('formulario__grupo-correcto');
            document.getElementById('grupo__password2').classList.remove('formulario__grupo-incorrecto');
            document.querySelector('#grupo__password2 i').classList.add('fa-check-circle');
            document.querySelector('#grupo__password2 i').classList.remove('fa-times-circle');
            document.querySelector('#grupo__password2 .formulario__input-error').classList.remove('formulario__input-error-activo');
            inputsFields.password.status = true;
        }else{
            document.getElementById('grupo__password2').classList.add('formulario__grupo-incorrecto');
            document.getElementById('grupo__password2').classList.remove('formulario__grupo-correcto');
            document.querySelector('#grupo__password2 i').classList.add('fa-times-circle');
            document.querySelector('#grupo__password2 i').classList.remove('fa-check-circle');
            document.querySelector('#grupo__password2 .formulario__input-error').classList.add('formulario__input-error-activo');
            inputsFields.password.status = false;
        }

    }



    sendToDatabase(newPlayer){
        postNewPlayer(newPlayer).then((response)=>{
            let data = response.data;
            if(Object.prototype.toString.call(data) === '[object Array]'){
                let newPlayerCreated = data[0],
                    playerRecord = {playerId : newPlayerCreated.id};
                localStorage.setItem('loggedPlayer',JSON.stringify(newPlayerCreated));
                this.createGameRecords(playerRecord);
                this.checkUploadedGameRecords();                    
            }else{ alert(data) }
        })
    }

    createNewTaleRecords(playerRecord){
        generateTaleRecords(playerRecord).then(response=>{
            let taleRecords = response.data;
            localStorage.setItem('taleRecords',JSON.stringify(taleRecords));
        })
    }
    
    createNewToolRecords(playerRecord){
        generateToolRecords(playerRecord).then(response =>{
            let toolRecords = response.data;
            localStorage.setItem('toolRecords',JSON.stringify(toolRecords));
        })
    }

    createNewQuizRecords(playerRecord){
        generateQuizRecords(playerRecord).then(response => {
            let quizRecords = response.data;
            localStorage.setItem('quizRecords',JSON.stringify(quizRecords));
        })
    }

    createNewSeedRecords(playerRecord){
        generateSeedRecords(playerRecord).then(response => {
            let seedRecords = response.data;
            localStorage.setItem('seedRecords',JSON.stringify(seedRecords));
        })
    }

    createNewPuzzlePieceRecords(playerRecord){
        generatePuzzlePieceRecords(playerRecord).then(response => {
            let pieceRecords = response.data;
            localStorage.setItem('pieceRecords',JSON.stringify(pieceRecords));
        })
    }

    createNewChakraRecords(playerRecord){
        generateChakraRecords(playerRecord).then(response => {
            let chakraRecords = response.data;
            localStorage.setItem('chakraRecords',JSON.stringify(chakraRecords));
        })
    }

    createGameRecords(playerRecord){
        this.createNewTaleRecords(playerRecord);
        this.createNewPuzzlePieceRecords(playerRecord);
        this.createNewQuizRecords(playerRecord);
        this.createNewSeedRecords(playerRecord);
        this.createNewToolRecords(playerRecord);
        this.createNewChakraRecords(playerRecord);
    }

    checkUploadedGameRecords(){
        if(localStorage.hasOwnProperty('loggedPlayer') && 
           localStorage.hasOwnProperty('taleRecords') && 
           localStorage.hasOwnProperty('quizRecords') &&
           localStorage.hasOwnProperty('seedRecords') &&
           localStorage.hasOwnProperty('pieceRecords') &&
           localStorage.hasOwnProperty('toolRecords') &&
           localStorage.hasOwnProperty('chakraRecords')){
               this.goToGameMenu();
           }else{
               console.log('aun no están cargados las listas');
               setTimeout(()=>{this.checkUploadedGameRecords()},500);
           }
    }

    goToGameMenu(){
        this.scene.start(scenesKey.GAME_MENU_SCENE);
    }


    

    

    
}