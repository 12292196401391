import Phaser from 'phaser'
import { gridAlignGroupElements, addChildrenToContainer } from '../../../../utils/functions';

export default class GameOptionButton extends Phaser.GameObjects.Container{
    constructor(scene,x,y,data){
        super(scene,x,y);

        this.setScene(scene);
        this.setInitialProperties(data);
        this.buildGameOptionButton();
        this.activateButton();
    }

    setScene(scene){ this.scene = scene }
    getScene(){ return this.scene }

    setOptionTexture(texture){ this.optionTexture = texture }
    getOptionTexture(){ return this.optionTexture }

    setPressedOptionTexture(texture){ this.pressedOptionTexture = texture }
    getPressedOptionTexture(){ return this.pressedOptionTexture }

    setIconTexture(texture){ this.iconTexture = texture}
    getIconTexture(){ return this.iconTexture }

    setPressedIconTexture(texture){ this.pressedIconTexture = texture }
    getPressedIconTexture(){ return this.pressedIconTexture }

    setGridConfig(config){ this.gridConfig = config }
    getGridConfig(){ return this.gridConfig }

    setOptionButton(button){ this.optionButton = button }
    getOptionButton(){ return this.optionButton }

    setButtonIcon(icon){ this.buttonIcon = icon }
    getButtonIcon(){ return this.buttonIcon }


    setInitialProperties(data){
        this.setOptionTexture(data.optionTexture);
        this.setPressedOptionTexture(data.pressedOptionTexture);
        this.setIconTexture(data.iconTexture);
        this.setPressedIconTexture(data.pressedIconTexture);

        this.setGridConfig({width : 2, height : 1, cellWidth: 270, position : 6})
    }

    createOptionButton(){
        let scene = this.getScene(),
            texture = this.getOptionTexture(),
            optionButton = scene.add.image(0,0,texture);
        this.setOptionButton(optionButton);
    }

    createButtonIcon(){
        let scene = this.getScene(),
            texture = this.getIconTexture(),
            buttonIcon = scene.add.image(0,0,texture);
        this.setButtonIcon(buttonIcon);
    }

    createComponents(){
        this.createOptionButton();
        this.createButtonIcon();
    }

    buildGameOptionButton(){
        this.createComponents();
        let scene = this.getScene(),
            button = this.getOptionButton(),
            icon = this.getButtonIcon(),
            group = scene.add.group([icon,button]),
            gridConfig = this.getGridConfig();
        
        gridAlignGroupElements(scene,group,gridConfig);
        addChildrenToContainer(this,group.getChildren());
        this.bringToTop(icon);
    }

    activateButton(){
        let button = this.getOptionButton();
        this.setSize(button.width,button.height).setInteractive();
        this.on('pointerdown',()=>{this.selectOptionButton()});
        this.on('pointerup',()=>{this.deselectOptionButton()});
        this.on('pointerover',()=>{this.popUpButton()});
        this.on('pointerout',()=>{this.popDownButton()});
    }

    selectOptionButton(){
        let pressedButtonTexture = this.getPressedOptionTexture(),
            pressedButtonIconTexture = this.getPressedIconTexture();
        this.changeOptionButtonTexture(pressedButtonTexture);
        this.changeButtonIconTexture(pressedButtonIconTexture);
    }

    deselectOptionButton(){
        let buttonTexture = this.getOptionTexture(),
            iconTexture = this.getIconTexture();
        this.changeOptionButtonTexture(buttonTexture);
        this.changeButtonIconTexture(iconTexture);
    }

    changeOptionButtonTexture(newTexture){
        let button = this.getOptionButton();
        button.setTexture(newTexture);
    }

    changeButtonIconTexture(newTexture){
        let icon = this.getButtonIcon();
        icon.setTexture(newTexture);
    }

    popUpButton(){
        let scene = this.getScene();
        scene.tweens.add({
            targets : this,
            scale : {from : 1, to : 1.1},
            duration : 200
        })
    }

    popDownButton(){
        let scene = this.getScene();
        scene.tweens.add({
            targets : this,
            scale : {from : 1.1, to : 1},
            duration : 200
        })
    }

}