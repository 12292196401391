import { generatePuzzlePieceRecords, getPlayerPuzzlePiecesRecords } from "../services/gameServices/piecesService"
import { CREATE_PIECE_RECORDS_SUCCESS, LOAD_PIECE_RECORDS_SUCCESS, LOAD_PIECE_LIST } from "./actionTypes";


export function createNewPuzzlePieceRecords(idPlayer){
    return dispatch => {
        return generatePuzzlePieceRecords(idPlayer).then(response => {
            dispatch(createPieceRecordsSuccess())
        }).catch(error=>{throw error})
    }
}

export function loadPlayerPieceRecords(idPlayer){
    return dispatch => {
        return getPlayerPuzzlePiecesRecords(idPlayer).then(response => {
            dispatch(loadPieceList(response.data));
            dispatch(loadPieceRecordsSuccess());
        }).catch(error => {throw error});
    }
}

function createPieceRecordsSuccess(){
    return {type : CREATE_PIECE_RECORDS_SUCCESS};
}

function loadPieceList(recordList){
    return { type : LOAD_PIECE_LIST, recordList };
}

function loadPieceRecordsSuccess(){
    return { type: LOAD_PIECE_RECORDS_SUCCESS };
}

