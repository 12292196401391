import { SET_NEW_PLAYER, LOAD_NEW_PLAYER, SIGN_PLAYER_REFUSED, SIGN_PLAYER_SUCCESS } from "./actionTypes";
import { postNewPlayer } from "../services/reactServices/playerService";
import { createNewTaleRecords } from "./talesActions";
import { createNewToolRecords } from "./toolActions";
import { createNewQuizRecords } from "./quizActions";
import { createNewPuzzlePieceRecords } from "./pieceActions";
import { createNewSeedRecords } from "./seedActions";
import { createNewChakraRecords } from "./chakraActions";


export function setNewPlayer(newPlayer){
    return {type : SET_NEW_PLAYER,newPlayer}
}

export function submitNewPlayer(newPlayer){
    return function(dispatch){
        return postNewPlayer(newPlayer).then(
            (response)=>{
            let newPlayerRecord = response.data;
            if(typeof newPlayerRecord === 'object' && newPlayerRecord.constructor === Array){
                let playerRecord = {playerId : newPlayerRecord[0].id};
                dispatch(createNewTaleRecords(playerRecord));
                dispatch(createNewToolRecords(playerRecord));
                dispatch(createNewQuizRecords(playerRecord));
                dispatch(createNewSeedRecords(playerRecord));
                dispatch(createNewPuzzlePieceRecords(playerRecord));
                dispatch(createNewChakraRecords(playerRecord));
                dispatch(setNewPlayer(newPlayerRecord));
                dispatch(registrationSuccessed());
            }else{
                console.log('no se modifico el nuevo usuario');
                dispatch(refuseRegistration());
            }
            }).catch((error => {throw(error)}))   
    }
}

export function loadNewPlayer(){
    return {type : LOAD_NEW_PLAYER}
}

function refuseRegistration(){
    return {type : SIGN_PLAYER_REFUSED}
}

function registrationSuccessed(){
    return { type : SIGN_PLAYER_SUCCESS }
}