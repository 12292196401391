import Phaser from 'phaser';
import RedSlot from '../commonObjects/redSlot';
import YellowSlot from '../commonObjects/yellowSlot';
import GreenSlot from '../commonObjects/greenSlot';
import BlueSlot from '../commonObjects/blueSlot';

export default class CaracMonth extends Phaser.GameObjects.Container{

    constructor(scene,x,y,monthInfo){
        super(scene,x,y);
        this.setMonthData(monthInfo);
        this.buildMonth(scene);
    }

    setId(id){
        this.id = id;
    }

    setName(name){
        this.name = name;
    }

    setKeyName(keyName){
        this.keyName = keyName;
    }

    setSeed(seed){
        this.seed = seed; 
    }

    setStatus(status){
        this.completed = status;
    }

    setLists(){
        this.slotsList = [];
        this.filledSlots = [];
    }

    setSlotsIdList(list){
        this.slotIdList = list;
    }


    setMonthData(data){
        this.setId(data.id);
        this.setName(data.name);
        this.setKeyName(data.keyName);
        this.setSeed(data.seed);
        this.setSlotsIdList(data.slotPiecesIdList);
        this.setLists();
        this.setStatus(false);
    }





    setMonthTitleName(scene){
        this.title = scene.add.image(0,78,this.keyName);
        this.title.setScale(0.6,0.6);
        this.add(this.title);
    }

    setSlotId(slot, type){
        switch(type){
            case 1:
                slot.setPuzzlePieceId(this.slotIdList.festivity);
            break;
            case 2:
                slot.setPuzzlePieceId(this.slotIdList.food);
            break;
            case 3:
                slot.setPuzzlePieceId(this.slotIdList.signs);
            break;
            case 4:
                slot.setPuzzlePieceId(this.slotIdList.dialects);
            break;
            default :
            console.log('tipo incorrecto');
            break;
        }
    }

    setFestivitySlot(scene){
        this.festivitySlot = new RedSlot(scene,0,-200);
        this.setSlotId(this.festivitySlot,1);
        this.add(this.festivitySlot);
    }

    setFoodSlot(scene){
        this.foodSlot = new YellowSlot(scene,0,-101);
        this.setSlotId(this.foodSlot,2);
        this.add(this.foodSlot);
    }

    setSignSlot(scene){
        this.signSlot = new GreenSlot(scene,0,-24);
        this.setSlotId(this.signSlot,3);
        this.add(this.signSlot);
    }

    setDialectSlot(scene){
        this.dialectSlot = new BlueSlot(scene,0,37);
        this.setSlotId(this.dialectSlot,4);
        this.add(this.dialectSlot);
    }

    buildMonth(scene){
        this.setFestivitySlot(scene);
        this.setFoodSlot(scene);
        this.setSignSlot(scene);
        this.setDialectSlot(scene);
        this.setMonthTitleName(scene);
        this.slotsList = [this.festivitySlot,this.foodSlot,this.signSlot,this.dialectSlot];        
        this.slotsList.forEach((slot)=>{
            slot.setParent(this);
        })
        this.setSize(this.festivitySlot.width,600);
        scene.add.existing(this);        
    }

    checkSlot(slot){
        this.filledSlots.push(slot);
        let index = this.slotsList.indexOf(slot);
        if(index>-1){
            this.slotsList.splice(index,1);
        }
    }

    checkAvailableSlots(){
        if(this.slotsList.length===0 && this.filledSlots.length===4){
            this.setStatus(true);
        }
    }


}