import Phaser from 'phaser';
import { gridAlignGroupElements, addChildrenToContainer } from "../../utils/functions";
import { seedInitChakraState } from '../../utils/information/chakra-states/statesInformation';
import ChakraBubble from '../commonObjects/chakraBubble';

export default class ChakraState extends Phaser.GameObjects.Container{
    constructor (scene,x,y,stateKey, chakraParentId){
        super(scene,x,y);
        this.setInitialStateProperties(chakraParentId);
        this.setStateProperties(scene,stateKey);
        this.buildState();
    }

    setInitialStateProperties(chakraParentId){
        this.setScene(null);
        this.setChakraParentId(chakraParentId);
        this.setBackgroundData(null);
        this.setStateId(null);
        this.setElementsList([]);
        this.setUniqueElement(null);
        this.setTotalElements(null);
        this.setTool(null);
        this.setAction(null);
        this.setDuration(null);
        this.setStateScale(null);
        this.setGridConfig(null);
        this.setIsReady(false);
        this.setSeed(null);
    }

    setStateProperties(scene,stateKey){
        this.setScene(scene);
        this.setBackgroundData(stateKey.background);
        this.setBackground(scene,stateKey.background);
        this.setStateId(stateKey.id);
        this.setUniqueElement(stateKey.uniqueElement);
        this.setTotalElements(stateKey.totalElements);
        this.setAction(stateKey.action);
        this.setDuration(stateKey.duration);
        this.setStateScale(stateKey.scale);
        this.setGridConfig(stateKey.gridConfig);
        if(stateKey.tool){
            this.setTool(stateKey.tool);
            this.setBubbleIcon(this.getTool()[0].bubbleIcon);
        }else if(stateKey.seedIcon){
            this.setBubbleIcon(stateKey.seedIcon);
        }
    }

    getScene(){ return this.scene }
    setScene(scene){ this.scene= scene }

    getChakraParentId(){ return this.chakraParentId }
    setChakraParentId(id){ this.chakraParentId = id }

    getBackgroundData(){ return this.backgroundData }
    setBackgroundData(backgroundData){ this.backgroundData = backgroundData }

    getStateId(){ return this.stateId }
    setStateId(id){ this.stateId = id }

    getUniqueElement(){ return this.uniqueElement }
    setUniqueElement(element){ this.uniqueElement = element }

    getTotalElements(){ return this.totalElements }
    setTotalElements(number){ this.totalElements = number }

    getElementsList(){ return this.elementsList }
    setElementsList(list){ this.elementsList = list }
    
    getTool(){ return this.tool }
    setTool(tool) { this.tool = tool }

    getBubbleIcon(){ return this.bubbleIcon }
    setBubbleIcon(icon){ this.bubbleIcon = icon}

    getChakraBubble(){ return this.chakraBubble }
    setChakraBubble(chakraBubble){ this.chakraBubble = chakraBubble }
    
    getAction(){ return this.action }
    setAction (action){ this.action = action }

    getDuration(){ return this.duration }
    setDuration(duration){ this.duration = duration }

    getStateScale(){ return this.stateScale }
    setStateScale(scale){ this.stateScale = scale }

    getGridConfig(){ return this.gridConfig }
    setGridConfig(config){ this.gridConfig = config }

    getIsReady(){ return this.isReady }
    setIsReady(flag){ this.isReady = flag }

    getIsSeeded(){ return this.isSeeded }
    setIsSeeded(flag){ this.isSeeded = flag }

    getSeed(){ return this.seed }
    setSeed(seed){ this.seed = seed }

    getBackground(){ return this.background }
    setBackground(background){ this.background = background }

    setBubbleAnim(anim){ this.bubbleAnim = anim }
    getBubbleAnim(){ return this.bubbleAnim }
    
    
    addBackground(){
        let scene = this.getScene(),
            backgroundData = this.getBackgroundData();
        this.setBackground(scene.add.image(0,0,backgroundData));
        let background = this.getBackground();
        this.add(background).setSize(background.width,background.height);
    }

    addNewElement(){
        let scene = this.getScene(),
            uniqueElement = this.getUniqueElement(),
            stateScale = this.getStateScale(),
            element = scene.add.image(0,0,uniqueElement);
        element.setScale(stateScale.x,stateScale.y);
        this.addOneElementToList(element);
    }

    addChakraBubble(){
        this.setChakraBubble(new ChakraBubble(this.getScene(),0,-50,this.getBubbleIcon()));
        this.getChakraBubble().setScale(0.3);
        this.add(this.getChakraBubble());
        this.createBubbleAnim();
    }

    hideChakraBubble(){
        this.getChakraBubble().setVisible(false)
        // this.stopBubbleAnim();
    }
    showChakraBubble(){ 
        this.bringToTop(this.getChakraBubble())
        this.getChakraBubble().setVisible(true);
    }



    changeUniqueElement(element){
        let seedStateInfo = seedInitChakraState.SEEDED,
            uniqueElement = seedStateInfo.uniqueElement;
        element.setTexture(uniqueElement);
    }

    addOneElementToList(element){
        this.getElementsList().push(element);
    }

    alignElementsList(){
        let elementsList = this.getElementsList(),
            scene = this.getScene(),
            gridConfig = this.getGridConfig(),
            group = scene.add.group(elementsList);
        gridAlignGroupElements(scene,group,gridConfig);
        addChildrenToContainer(this,group.getChildren());
    }

    fillElementsList(){
        for (let init = 0, finish = this.getTotalElements();init<finish;init++){
            this.addNewElement();
        }
        this.alignElementsList();
    }

    buildState(){
        this.addBackground();
        this.addChakraBubble();
        this.hideChakraBubble();
        this.fillElementsList();
    }

    setElementBehavior(element){
        let action = this.getAction();
        element.on('pointerdown',()=>{
            this.getScene().events.emit('setSelectedChakra',this.getChakraParentId());
            element.setTint(0x2d2d2d);
        });
        switch(action){
            case 'destroy':
                element.on('pointerup',()=>{
                    this.destroyElement(element);
                    this.getScene().events.emit('check_chakra_status');
                });
            break;

            case 'change':
                element.on('pointerup',()=>{
                    element.clearTint();
                    this.changeElement(element);
                    this.getScene().events.emit('check_chakra_status');
                });
            break;
            default:
            break;
        }
    }

    lockElements(){
        this.getElementsList().forEach((element)=>{
            element.disableInteractive();
            this.hideChakraBubble();
        });
    }

    unlockElements(){
        this.getElementsList().forEach((element)=>{
            element.setInteractive();
            this.showChakraBubble();
        });
    }

    destroyElement(element){
        let elementsList = this.getElementsList();
        this.remove(element);
        let index = elementsList.indexOf(element);
        if(index>-1){
            elementsList.splice(index,1);
        }
        this.checkList();
    }

    changeElement(element){
        let elementsList = this.getElementsList();
        this.changeUniqueElement(element);
        let index = elementsList.indexOf(element);
        if(index>-1){
            elementsList.splice(index,1);
        }
        this.checkIsSeeded();
    }

    checkList(){
        let elementsList = this.getElementsList();
        if(elementsList.length===0){
            this.setIsReady(true);
        }
    }

    checkIsSeeded(){
        let elementsList = this.getElementsList();
        if(elementsList.length===0){
            this.setIsSeeded(true);
        }
    }

    showElements(){
        let elementsList = this.getElementsList();
        elementsList.forEach(element => {element.setVisible(true)});
    }

    hideElements(){
        let elementsList = this.getElementsList();
        elementsList.forEach(element => {element.setVisible(false)});
    }

    createBubbleAnim(){
        let scene = this.getScene();
        let bubble = this.getChakraBubble();
        let anim = scene.tweens.add({
            targets : bubble,
            y : {from : -50, to : -30},
            yoyo : true,
            repeat : -1
        })
        this.setBubbleAnim(anim);
    }

    playBubbleAnim(){
        let anim = this.getBubbleAnim();
        anim.restart();
    }

    stopBubbleAnim(){
        let anim = this.getBubbleAnim();
        anim.stop();
    }

    


}