import React from 'react';
import {useHistory} from 'react-router-dom';
import {Button} from 'react-bootstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as newPlayerActions from '../../../actions/newPlayerActions';
import * as talesActions from '../../../actions/talesActions';

function SignInButton(props){
    let history = useHistory();
    let {givenName, givenCI,givenPassword} = props;

    let registerNewPlayer = (newPlayer)=>{
        props.newPlayerActions.submitNewPlayer(newPlayer);
    }
    
    let handleClick = (event) => {
        event.preventDefault();
        if(givenName!=='' && givenCI!=='' && givenPassword!==''){
            let newPlayer = {name : givenName,CI : givenCI, password : givenPassword};
            registerNewPlayer(newPlayer);
            setTimeout(()=>{history.push('/login')},500);
        } 
    }

    return(
        <Button  onClick = {handleClick} style ={{width:'100%'}}>
            Submit!
        </Button>
    );
}

function MapStateToProps(state){
    return{
        newPlayerAdded : state.newPlayer
    }
}

function MapDispatchToProps(dispatch){
    return {
       newPlayerActions : bindActionCreators(newPlayerActions,dispatch),
       taleActions : bindActionCreators(talesActions,dispatch)
    }
}


export default connect(MapStateToProps,MapDispatchToProps) (SignInButton);