import * as types from '../actions/actionTypes';
import initialState from "./initial-state/initialState";


export default function pieceReducer( state = initialState.piece_records, action ){
    switch(action.type){

        case types.CREATE_PIECE_RECORDS_SUCCESS : 
            return state;
        
        case types.LOAD_PIECE_LIST :
            return action.recordList;

        case types.LOAD_PIECE_RECORDS_SUCCESS :
            return state;

        default :
            return state;
    }
}