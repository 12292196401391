export const festivityPricesResources = {
    PRICE_SISAY_PACHA : {
        'key': 'PRICE_SISAY_PACHA',
        'path' : './funcaracAssets/content/puzzle/festivities/icon_sisay_pacha.png'
    },
    PRICE_PAWKAR_RAYMI : {
        'key': 'PRICE_PAWKAR_RAYMI',
        'path' : './funcaracAssets/content/puzzle/festivities/icon_pawkar_raymi.png'
    },
    PRICE_ALLPA_CRUZ : {
        'key': 'PRICE_ALLPA_CRUZ',
        'path' : './funcaracAssets/content/puzzle/festivities/icon_allpa_cruz.png'
    },
    PRICE_INTI_RAYMI : {
        'key': 'PRICE_INTI_RAYMI',
        'path' : './funcaracAssets/content/puzzle/festivities/icon_inti_raymi.png'
    },
    PRICE_PALLAY_RAYMI : {
        'key': 'PRICE_PALLAY_RAYMI',
        'path' : './funcaracAssets/content/puzzle/festivities/icon_pallay_raymi.png'
    },
    PRICE_SAWARIY : {
        'key': 'PRICE_SAWARIY',
        'path' : './funcaracAssets/content/puzzle/festivities/icon_sawariy.png'
    },
    PRICE_KULLA_RAYMI : {
        'key': 'PRICE_KULLA_RAYMI',
        'path' : './funcaracAssets/content/puzzle/festivities/icon_kulla_raymi.png'
    },
    PRICE_RIKSICHIY : {
        'key': 'PRICE_RIKSICHIY',
        'path' : './funcaracAssets/content/puzzle/festivities/icon_riksichiy.png'
    },
    PRICE_VELANAKUY : {
        'key': 'PRICE_VELANAKUY',
        'path' : './funcaracAssets/content/puzzle/festivities/icon_velanakuy.png'
    },
    PRICE_KAPAK_RAYMI : {
        'key': 'PRICE_KAPAK_RAYMI',
        'path' : './funcaracAssets/content/puzzle/festivities/icon_kapak_raymi.png'
    },
    PRICE_YUMBADA : {
        'key': 'PRICE_YUMBADA',
        'path' : './funcaracAssets/content/puzzle/festivities/icon_yumbada.png'
    },
    PRICE_LLULLU_MURU_RAYMI : {
        'key': 'LLULLU_MURU_RAYMI',
        'path' : './funcaracAssets/content/puzzle/festivities/icon_llullu_muru_raymi.png'
    }
}

export const foodPricesResources = {
    PRICE_CHUKLLU_KAMLLA : {
        'key': 'PRICE_CHUKLLU_KAMLLA',
        'path' : './funcaracAssets/content/puzzle/food/icon_chukllu_kamlla.png'
    },
    PRICE_MILICHAKI : {
        'key': 'PRICE_MILICHAKI',
        'path' : './funcaracAssets/content/puzzle/food/icon_milichaki.png'
    },
    PRICE_MISHKI_ZAMBO : {
        'key': 'PRICE_MISHKI_ZAMBO',
        'path' : './funcaracAssets/content/puzzle/food/icon_mishki_zambo.png'
    },
    PRICE_UCHUKUTA : {
        'key': 'PRICE_UCHUKUTA',
        'path' : './funcaracAssets/content/puzzle/food/icon_uchukuta.png'
    },
    PRICE_MUTI_LUCI : {
        'key': 'PRICE_MUTI_LUCI',
        'path' : './funcaracAssets/content/puzzle/food/icon_muti_luci.png'
    },
    PRICE_CHAMPUS : {
        'key': 'PRICE_CHAMPUS',
        'path' : './funcaracAssets/content/puzzle/food/icon_champus.png'
    },
    PRICE_MAKI_MACHIKA : {
        'key': 'PRICE_MAKI_MACHIKA',
        'path' : './funcaracAssets/content/puzzle/food/icon_maki_machika.png'
    },
    PRICE_MISHKI_KAMLLA : {
        'key': 'PRICE_MISHKI_KAMLLA',
        'path' : './funcaracAssets/content/puzzle/food/icon_mishki_kamlla.png'
    },
    PRICE_YAMUR_ASUA : {
        'key': 'PRICE_YAMUR_ASUA',
        'path' : './funcaracAssets/content/puzzle/food/icon_yamur_asua.png'
    },
    PRICE_KATZU_KAMLLA : {
        'key': 'PRICE_KATZU_KAMLLA',
        'path' : './funcaracAssets/content/puzzle/food/icon_katzu_kamlla.png'
    },
    PRICE_YANA_API : {
        'key': 'PRICE_YANA_API',
        'path' : './funcaracAssets/content/puzzle/food/icon_yana_api.png'
    },
    PRICE_CHURU_API : {
        'key': 'PRICE_CHURU_API',
        'path' : './funcaracAssets/content/puzzle/food/icon_churu_api.png'
    }
}

export const signsSecretsPriceResources = {
    PRICE_GROUP_01 : {
        'key': 'PRICE_GROUP_01',
        'path' : './funcaracAssets/content/puzzle/signs secrets/icon_group_01.png'
    },
    PRICE_GROUP_02 : {
        'key': 'PRICE_GROUP_02',
        'path' : './funcaracAssets/content/puzzle/signs secrets/icon_group_02.png'
    },
    PRICE_GROUP_03 : {
        'key': 'PRICE_GROUP_03',
        'path' : './funcaracAssets/content/puzzle/signs secrets/icon_group_03.png'
    },
    PRICE_GROUP_04 : {
        'key': 'PRICE_GROUP_04',
        'path' : './funcaracAssets/content/puzzle/signs secrets/icon_group_04.png'
    },
    PRICE_GROUP_05 : {
        'key': 'PRICE_GROUP_05',
        'path' : './funcaracAssets/content/puzzle/signs secrets/icon_group_05.png'
    },
    PRICE_GROUP_06 : {
        'key': 'PRICE_GROUP_06',
        'path' : './funcaracAssets/content/puzzle/signs secrets/icon_group_06.png'
    },
    PRICE_GROUP_07 : {
        'key': 'PRICE_GROUP_07',
        'path' : './funcaracAssets/content/puzzle/signs secrets/icon_group_07.png'
    },
    PRICE_GROUP_08 : {
        'key': 'PRICE_GROUP_08',
        'path' : './funcaracAssets/content/puzzle/signs secrets/icon_group_08.png'
    },
    PRICE_GROUP_09 : {
        'key': 'PRICE_GROUP_09',
        'path' : './funcaracAssets/content/puzzle/signs secrets/icon_group_09.png'
    },
    PRICE_GROUP_10 : {
        'key': 'PRICE_GROUP_10',
        'path' : './funcaracAssets/content/puzzle/signs secrets/icon_group_10.png'
    },
    PRICE_GROUP_11 : {
        'key': 'PRICE_GROUP_11',
        'path' : './funcaracAssets/content/puzzle/signs secrets/icon_group_11.png'
    },
    PRICE_GROUP_12 : {
        'key': 'PRICE_GROUP_12',
        'path' : './funcaracAssets/content/puzzle/signs secrets/icon_group_12.png'
    }
}

export const dialectsPriceResources = {
    PRICE_DIALECT : {
        'key': 'PRICE_DIALECT',
        'path' : './funcaracAssets/content/puzzle/dialects/icon_dialect.png'
    },
}