import React from "react";
import "./App.css";
import AppRouter from "./components/dev-components/navigator/appRouter";
import { Provider } from 'react-redux'; 
import configureStore from "./store/configureStore";
import FuncaracGame from "./components/FuncaracGame/FuncaracGame";

// import FuncaracGame from "./components/FuncaracGame/FuncaracGame";

// const store = configureStore();

function App(){
    return(<FuncaracGame/>)

    // return(
    //     <Provider store = {store}>
    //         <AppRouter>
    //         </AppRouter>
    //     </Provider>
    // );
}

export default App;
