export const festivitiesQuizzesList = {
    QUIZ_YUMBADA : {
        id : 1,
        title : 'Prueba sobre la Yumbada',
        questions : [
            {
                questionNumber : 1,
                question : '¿Qué representa un danzante yumbo durante la fiesta de la Yumbada?',
                options : ['Un representante de la Pachamama','Un intermediario entre el cielo y la tierra','Un profeta y anunciante de futuros sucesos','Un bailarín y cantante excepcional'],
                answer : 'Un intermediario entre el cielo y la tierra'
            },
            {
                questionNumber : 2,
                question : '¿Quiénes son los dos personajes más importantes de la Yumbada?',
                options : ['Los ñaupadores y los chakareros','Los campanilleros y los priostes','Los roperos y los músicos','Los pingulleros y los capariches'],
                answer : 'Los pingulleros y los capariches'
            },
            {
                questionNumber : 3,
                question : '¿Quiénes protegen a los yumbos del contacto humano?',
                options : ['Los pingulleros','Los monos','Los capariches','Los perros'],
                answer : 'Los monos'
            },
            {
                questionNumber : 4,
                question : '¿Quién anuncia el inicio y el fin oficial del baile?',
                options : ['Los yumbos','Los pingulleros','El antialbazo','El prioste'],
                answer : 'El antialbazo'
            },
            {
                questionNumber : 5,
                question : '¿Qué se acostumbra a hacer durante el domingo festivo?',
                options : ['Cosechar los primeros frutos de la temporada','Tomar un baño ritual en una cascada','Asistir a misa y compartir el kukayu','Desinfectar las chacras comunales'],
                answer : 'Asistir a misa y compartir el kukayu'
            },
            {
                questionNumber : 6,
                question : '¿Qué es la recogida de los yumbos?',
                options : ['Los comuneros rondan por\nlas casas buscando danzantes','Los yumbos desfilan atrapando\na la gente despistada','Los yumbos bailan alrededor de\nlas chacras recogiendo sus frutos','Las familias invitan el kukayu\na los yumbos danzantes presentes'],
                answer : 'Los comuneros rondan por\nlas casas buscando danzantes'
            },
        ]  
    },
    QUIZ_SISAY_PACHA : {
        id : 2,
        title : 'Prueba sobre el Sisay Pacha',
        questions : [
            {
                questionNumber : 1,
                question : '¿En qué día de la semana comienza la fiesta del Sisay Pacha?',
                options : ['lunes','domingo','sábado','viernes'],
                answer : 'sábado'
            },
            {
                questionNumber : 2,
                question : '¿Durante cuántos días dura la fiesta del Sisay Pacha?',
                options : ['8 días','12 días','14 días','5 días'],
                answer : '8 días'
            },
            {
                questionNumber : 3,
                question : '¿Con cúal actividad culmina la fiesta del Sisay Pacha?',
                options : ['conversación con el apu','la afinación y entonación de instrumentos','entierro del carnaval','la salida de los grupos musicales'],
                answer : 'entierro del carnaval'
            },
            {
                questionNumber : 4,
                question : '¿A qué raymi pertenece la celebración del Sisay Pacha?',
                options : ['Inti Raymi','Kulla Raymi','Pawkar Raymi','Kapak Raymi'],
                answer : 'Pawkar Raymi'
            },
            {
                questionNumber : 5,
                question : '¿Cuál de estos términos no representa la celebración del Sisay Pacha?',
                options : ['la justicia','la abundancia','la reciprocidad','la unidad'],
                answer : 'la justicia'
            },
            {
                questionNumber : 6,
                question : '¿De estos instrumentos musicales, cuál no es utilizado durante la fiesta del Sisay Pacha?',
                options : ['quenas','pingullos','rondadores','maracas'],
                answer : 'maracas'
            },
        ]  
    },
    QUIZ_PAWKAR_RAYMI : {
        id : 3,
        title : 'Prueba sobre el Pawkar Raymi',
        questions : [
            {
                questionNumber : 1,
                question : '¿Durante qué época importante se festeja el Pawkar Raymi?',
                options : ['durante el primer deshierbe','durante la época del vuelo de catsos','durante el florecimiento de los granos','durante la cosecha de las chakras'],
                answer : 'durante el florecimiento de los granos'
            },
            {
                questionNumber : 2,
                question : '¿En que parte de la sierra ecuatoriana el Pawkar Raymi se la conoce como Mama Shalva?',
                options : ['en la sierra norte','en la sierra centro','en la sierra sur','en toda la sierra'],
                answer : 'en la sierra centro'
            },
            {
                questionNumber : 3,
                question : '¿Qué es la kinlla?',
                options : ['es una planta medicinal de hojas silvestres','es un instrumento musical de viento','es una puerta adornada con flores','es un platillo a base de harina de maíz'],
                answer : 'es una puerta adornada con flores'
            },
            {
                questionNumber : 4,
                question : '¿Cuál de estas flores silvestres no se recoge durante el Pawkar Raymi?',
                options : ['naccha sisa','tauri sisa','chacha luisa','wayta'],
                answer : 'chacha luisa'
            },
            {
                questionNumber : 5,
                question : '¿Luego de la peregrinación, quién se encarga de repartir el kukayu a los comuneros?',
                options : ['el prioste','las abuelitas','los wawas','los tamborilleros'],
                answer : 'las abuelitas'
            },
            {
                questionNumber : 6,
                question : 'En general, ¿Cúando empieza y finaliza el Pawkar Raymi?',
                options : ['empieza el martes de carnaval y finaliza el 21 de marzo','empieza el sábado previo al carnaval y finaliza el primero de marzo','empieza el lunes de carnaval y finaliza en 15 días','empieza el martes de carnaval y finaliza en semana santa'],
                answer : 'empieza el martes de carnaval y finaliza el 21 de marzo'
            },
        ]  
    },
    QUIZ_LLULLU_MURU_RAYMI : {
        id : 4,
        title : 'Prueba sobre el Llullu Muru Raymi',
        questions : [
            {
                questionNumber : 1,
                question : '¿Con qué otro nombre se le conoce a la fiesta Llullu Muru Raymi?',
                options : ['Fiesta del sol naciente','Fesita del corte de pelo','Fiesta de los granos tiernos','Fiesta de las chacras secas'],
                answer : 'Fiesta de los granos tiernos'
            },
            {
                questionNumber : 2,
                question : '¿Qué platillo especial se prepara durante el Llullu Muru Raymi utilizando los granos tiernos?',
                options : ['La fanesca','La colada morada','La colada de zambo','La maki machika'],
                answer : 'La fanesca'
            },
            {
                questionNumber : 3,
                question : '¿En qué provincias de la sierra se festeja el Llullu Muru Raymi?',
                options : ['En las provincias de Cañar y Chimborazo','En las provincias de Azuay y Loja','En las provincias de Tungurahua y Cotopaxi','En las provincias de Imbabura y Pichincha'],
                answer : 'En las provincias de Imbabura y Pichincha'
            },
            {
                questionNumber : 4,
                question : '¿En qué evento importante se realizan intercambios de granos familiares?',
                options : ['En los conversatorios comunales','En los desfiles','En las ferias','En las misas'],
                answer : 'En las ferias'
            },
            {
                questionNumber : 5,
                question : '¿Cuándo se realiza la fiesta del Llullu Muru Raymi?',
                options : ['A finales de junio e inicios de julio','A finales de marzo e inicios de abril','A finales de enero e inicios de febrero','Durante la fiesta del carnaval'],
                answer : 'A finales de marzo e inicios de abril'
            },
            {
                questionNumber : 6,
                question : '¿A quién se agradece durante la fiesta del Llullu Muru Raymi?',
                options : ['A la madre agua y al padre sol','A la madre tierra y al padre sol','A la madre semilla y al padre cuscungo','A la madre lluvia y al padre fuego'],
                answer : 'A la madre tierra y al padre sol'
            },
        ]  
    },

    QUIZ_ALLPA_CRUZ : {
        id : 5,
        title : 'Prueba sobre el Allpa Cruz',
        questions : [
            {
                questionNumber : 1,
                question : '¿Entre qué meses del año el grano de choclo comienza a estar "KAU"?',
                options : ['entre enero y febrero','entre abril y mayo','entre octubre y noviembre','entre junio y julio'],
                answer : 'entre abril y mayo'
            },
            {
                questionNumber : 2,
                question : '¿Cuál de estos fenómenos no es pronosticado por esta fiesta?',
                options : ['buenos tiempos venideros','futuras épocas lluviosas','próximas épocas de sequía','futuros desastres naturales'],
                answer : 'futuros desastres naturales'
            },
            {
                questionNumber : 3,
                question : '¿Durante el Allpa Cruz qué actividad ceremonial realizan las familias comuneras?',
                options : ['velan y recuerdan a los familiares fallecidos','frotan a cada miembro familiar con una vela','celebran el florecimiento de las chakras','festejan el reencuentro entre el padrino y el ahijado'],
                answer : 'frotan a cada miembro familiar con una vela'
            },
            {
                questionNumber : 4,
                question : '¿A cuál de estos términos se le atribuye el nombre y el festejo del Allpa Cruz?',
                options : ['a la cruces de los familiares fallecidos','a la cruz de la religión católica','a la constelación de la cruz del sur','a la cruz que representa los cuatro puntos cardinales'],
                answer : 'a la constelación de la cruz del sur'
            },
            {
                questionNumber : 5,
                question : '¿Cuál de estos objetos no es amarrado a la vela grande?',
                options : ['la pluma de los animales','los pelos de cada familiar','las semillas de las chacras','los cordones de los zapatos'],
                answer : 'los cordones de los zapatos'
            },
            {
                questionNumber : 6,
                question : '¿Qué comportamiento de la vela es importante de notar durante esta fiesta?',
                options : ['el derrame de sus lágrimas','el encogimiento de su tamaño','el aroma de su candela','el color de su mecha'],
                answer : 'el derrame de sus lágrimas'
            },
        ]  
    },
    QUIZ_INTI_RAYMI : {
        id : 6,
        title : 'Prueba sobre el Inti Raymi',
        questions : [
            {
                questionNumber : 1,
                question : '¿Cuánto dura aproximadamente la fiesta del Inti Raymi?',
                options : ['10 días','7 días','30 días','15 días'],
                answer : '7 días'
            },
            {
                questionNumber : 2,
                question : '¿Por qué se realiza la fiesta del Inti Raymi?',
                options : ['para recordar a los seres que se han adelantado','para preparar a las chakras para la próxima siembra','para agradecer a la pachamama por los frutos otorgados','para prevenir desastres naturales'],
                answer : 'para agradecer a la pachamama por los frutos otorgados'
            },
            {
                questionNumber : 3,
                question : '¿Qué es el armay tuta?',
                options : ['es una técnica para cosechar los maíces de la chakra','es una vestimenta típica usada en los bailes','es un baño espiritual en una vertiente o cascada','es una persona que resuena campanas al son del baile'],
                answer : 'es un baño espiritual en una vertiente o cascada'
            },
            {
                questionNumber : 4,
                question : '¿Qué es la actividad más importante realizada en la fiesta waka allichiy puncha?',
                options : ['el baño ceremonial en la cascada o vertiente','el forcejeo entre tinkus a través del baile y el canto','la retirada de los alimentos colgados del altar sagrado','la elaboración de castillos adornados con frutas'],
                answer : 'la elaboración de castillos adornados con frutas'
            },
            {
                questionNumber : 5,
                question : '¿En qué fiesta los comuneros asisten a misa y se dan un día de descanso?',
                options : ['en el waka allichiy puncha','en el hatun puncha','en el kallariy capilla puncha','en el wakcha karay'],
                answer : 'en el hatun puncha'
            },
            {
                questionNumber : 6,
                question : '¿De estas actividades cuáles no se acostumbran a hacer durante el Inti Raymi?',
                options : ['componer melodías alegres sobre la temporada pasada','saumerear las familias y las viviendas','iniciar una nueva siembra de las semillas familiares','bailar de casa en casa por toda la comunidad'],
                answer : 'iniciar una nueva siembra de las semillas familiares'
            },
        ]  
    },
    QUIZ_PALLAY_RAYMI : {
        id : 7,
        title : 'Prueba sobre el Pallay Raymi',
        questions : [
            {
                questionNumber : 1,
                question : '¿Por qué se realiza la fiesta del Pallay Raymi?',
                options : ['para venerar a los sabios chacareros de la comunidad','para conmemorar a las últimas cosechas','para festejar el proceso de crecimiento de los wawas','para homenajear a las mujeres y su fertilidad'],
                answer : 'para conmemorar a las últimas cosechas'
            },
            {
                questionNumber : 2,
                question : '¿Cuál es la principal actividad realizada durante el Pallay Raymi?',
                options : ['el primer deshierbe de las chakras con la hoz','el regalo del primer pantalón a los wawas','la recolección y cosecha de las semillas maduras','el corte de pelo a los ahijados'],
                answer : 'la recolección y cosecha de las semillas maduras'
            },
            {
                questionNumber : 3,
                question : '¿Qué significa Pallay Raymi?',
                options : ['fiesta del sol','fiesta de la cosecha','fiesta de las mujeres','fiesta del deshierbe'],
                answer : 'fiesta de la cosecha'
            },
            {
                questionNumber : 4,
                question : '¿Cuál de estos términos no es venerado durante el Pallay Raymi?',
                options : ['el padre sol','el padre katzu blanco','la madre luna','la madre agua'],
                answer : 'el padre katzu blanco'
            },
            {
                questionNumber : 5,
                question : '¿Quienes son los aruchicos?',
                options : ['son las abuelas mayores que bendicen las semillitas','son los priostes que organizan la fiesta','son los wawas que reparten flores recogidas del campo','son los bailarines y músicos que alegran la fiesta'],
                answer : 'son los bailarines y músicos que alegran la fiesta'
            },
            {
                questionNumber : 6,
                question : '¿Para que resuenan con fuerza sus campanas los campanilleros en sus bailes?',
                options : ['para ahuyentar a las malas energías','para mejorar el proceso de la cosecha','para alegrar las entonadas del baile','para invitar a bailar a las familias'],
                answer : 'para ahuyentar a las malas energías'
            },
        ]  
    },
    QUIZ_SAWARIY : {
        id : 8,
        title : 'Prueba sobre el Sawariy',
        questions : [
            {
                questionNumber : 1,
                question : '¿Qué conmemora la fiesta ritual Sawariy?',
                options : ['La llegada de los katzos blancos sobre las chacras','El encuentro entre el padrino y su ahijado','El matrimonio indígena andino','El solsticio de verano en la comunidad indígena'],
                answer : 'El matrimonio indígena andino'
            },
            {
                questionNumber : 2,
                question : '¿Cuál de estos valores no son promovidos al festejar el Sawariy?',
                options : ['Respeto','Valentía','Compromiso','Fraternidad'],
                answer : 'Valentía'
            },
            {
                questionNumber : 3,
                question : '¿Qué personaje se encarga de cargar el ajuar de los novios y vestirlos?',
                options : ['Los roperos','Los ñaupadores','Los padrinos','Los tayta o mama servicia'],
                answer : 'Los roperos'
            },
            {
                questionNumber : 4,
                question : '¿Quién es el ñaupador?',
                options : ['La persona que compra la vestimenta apropiada','La persona que organiza el banquete de la fiesta','La persona que reparte la comida y la bebida durante la fiesta','La persona que vigila todo lo que sucede en la fiesta'],
                answer : 'La persona que vigila todo lo que sucede en la fiesta'
            },
            {
                questionNumber : 5,
                question : '¿En qué día de la ceremonia se realiza la fiesta de las cocineras?',
                options : ['En el 5to día','En el 3er día','En el 1er día','En el 4to día'],
                answer : 'En el 5to día'
            },
            {
                questionNumber : 6,
                question : '¿En qué consiste el ritual andino ñawi maillay?',
                options : ['En el pedido de mano a la novia','En el lavado de manos, cara y pies de ambos novios en la vertiente/cascada','En el pedido de mano a los padrinos y madrinas','En el matrimonio eclesiástico entre los novios'],
                answer : 'En el lavado de manos, cara y pies de ambos novios en la vertiente/cascada'
            },
        ]  
    },
    QUIZ_KULLA_RAYMI : {
        id : 9,
        title : 'Prueba sobre el Kulla Raymi',
        questions : [
            {
                questionNumber : 1,
                question : '¿Por qué se celebra el Kulla Raymi?',
                options : ['Porque se conmemoran a los sabios ancestros de la comunidad','Porque se honra a las mujeres, a la fertilidad y la regeneración de vida','Porque es el momento donde se debe descansar las chacras','Porque el prioste se ha construido una nueva casa'],
                answer : 'Porque se honra a las mujeres, a la fertilidad y la regeneración de vida'
            },
            {
                questionNumber : 2,
                question : '¿Qué representa la época de festejo del Kulla Raymi?',
                options : ['El inicio del ciclo agrícola','La última cosecha del año','La alineación de las constelaciones del sur','El festejo del primer deshierbe de la chacra'],
                answer : 'El inicio del ciclo agrícola'
            },
            {
                questionNumber : 3,
                question : '¿Cómo se festeja el Kulla Raymi?',
                options : ['Se arman tinkus y forcejean entre bailes y cantos','Se arman ferias y se intercambian las semillas familiares','Se intercambian ropajes y se reparten golosinas entre la comunidad','Se frotan velas y se pronostican el futuro de las familias'],
                answer : 'Se arman ferias y se intercambian las semillas familiares'
            },
            {
                questionNumber : 4,
                question : '¿Para qué se pone chicha en las cuatro esquinas de la chakra?',
                options : ['Para que la chakrita no se muera de sed','Para confundir a los catzos que vuelan sobre la chakrita','Para que la tierra reciba con agrado a las semillas','Para que el prioste de la comunidad lo reciba como un tributo'],
                answer : 'Para que la tierra reciba con agrado a las semillas'
            },
            {
                questionNumber : 5,
                question : '¿Quién se encarga de escoger a las mejores semillas a ser bendecidas y saumereadas?',
                options : ['Las yachak mamas','Los ñaupadores','Los priostes','Las jatun mamas'],
                answer : 'Las jatun mamas'
            },
            {
                questionNumber : 6,
                question : '¿Quién se encarga de bendecir a las semillas escogidas?',
                options : ['Las yachak mamas','Los padrinos y madrinas','Las jatun mamas','Los chakareros'],
                answer : 'Las yachak mamas'
            },
        ]  
    },
    QUIZ_RIKSICHIY : {
        id : 10,
        title : 'Prueba sobre el Riksichiy',
        questions : [
            {
                questionNumber : 1,
                question : '¿En qué consiste el festejo del Riksichiy?',
                options : ['Bendecir y saumerear a las semillitas familiares','Cosechar los alimentos ofrecidos por la Pachamama','Juntar y reencontrar a los padrinos con sus ahijados','Identificar y definir los roles de los wawas dentro de la comunidad'],
                answer : 'Juntar y reencontrar a los padrinos con sus ahijados'
            },
            {
                questionNumber : 2,
                question : '¿Cuál de estas actividades no realiza el padrino en el Riksichiy?',
                options : ['Reconocer a sus ahijados como sus protegidos','Arar y cosechar los productos de sus ahijados','Cortar un mechón de cabello de sus ahijados','Entregar regalos exclusivos a sus ahijados'],
                answer : 'Arar y cosechar los productos de sus ahijados'
            },
            {
                questionNumber : 3,
                question : '¿Desde cuándo se puede realizar el ritual del Riksichiy?',
                options : ['Cuando el ahijado cumpla 1 año de edad','Cuando el ahijado cumpla 18 años','Cuando el ahijado cumpla el primer septenario de vida','Cuando el padrino cumpla 60 años'],
                answer : 'Cuando el ahijado cumpla el primer septenario de vida'
            },
            {
                questionNumber : 4,
                question : '¿Con que otro nombre se le conoce al Riksichiy?',
                options : ['Hatun Pucha','Wakcha Karay','Akcha Rutuchi','Waka Allichiy Puncha'],
                answer : 'Akcha Rutuchi'
            },
            {
                questionNumber : 5,
                question : '¿Cómo se llama al acto de presentar al hijo del padrino con su ahijado?',
                options : ['Markak wawki','Markak ñaña','Markak pani','Markak ushushi'],
                answer : 'Markak wawki'
            },
            {
                questionNumber : 6,
                question : '¿Qué es lo que los ahijados entregan en agradecimiento a sus padrinos?',
                options : ['Las semillas de la familia','Coronas de flores silvestres','El ganado de la familia','Productos cosechados de la chacra'],
                answer : 'Coronas de flores silvestres'
            },
        ]  
    },
    QUIZ_VELANAKUY : {
        id : 11,
        title : 'Prueba sobre el Velanakuy',
        questions : [
            {
                questionNumber : 1,
                question : '¿Qué es lo que se celebra en el ritual del Velanakuy?',
                options : ['A las mujeres por ser símbolo de fertilidad','A los niños por ser símbolo de inocencia','A los ancianos por su sabiduría','A los fallecidos por su legado'],
                answer : 'A los fallecidos por su legado'
            },
            {
                questionNumber : 2,
                question : '¿Con qué otro nombre se le conoce al Velanakuy?',
                options : ['Wakcha Karay','Tumarina','Sawarina','Akcha Rutuchi'],
                answer : 'Wakcha Karay'
            },
            {
                questionNumber : 3,
                question : '¿Qué reflexionan las familias durante las fiestas del Velanakuy?',
                options : ['La abundancia de las chacras','El ciclo natural de la vida','Los cambios climáticos en las zonas aledañas','La riqueza del patrimonio cultural'],
                answer : 'El ciclo natural de la vida'
            },
            {
                questionNumber : 4,
                question : '¿Qué no realizan las familias durante el Velanakuy?',
                options : ['Preparan alimentos como ofrenda al fallecido','Recuerdan las hazañas del fallecido','Bailan y cantan en honor al fallecido','Realizan vigías en la tumba del fallecido'],
                answer : 'Bailan y cantan en honor al fallecido'
            },
            {
                questionNumber : 5,
                question : '¿Qué significa que la comida haga el sonido “tus tus tus”?',
                options : ['Significa que al fallecido le gustó la comida','Significa que al fallecido le molesta las visitas','Significa que la comida está embrujada','Significa que la comida se enfrió'],
                answer : 'Significa que al fallecido le gustó la comida'
            },
            {
                questionNumber : 6,
                question : '¿Por qué es importante celebrar el Velanakuy?',
                options : ['Para no tener problemas en la vida','Para evitar contraer la mala suerte y el malaire','Para que las chacras no contraigan pestes','Para honrar la memoria de los fallecidos'],
                answer : 'Para honrar la memoria de los fallecidos'
            },
        ]  
    },
    QUIZ_KAPAK_RAYMI : {
        id : 12,
        title : 'Prueba sobre el Kapak Raymi',
        questions : [
            {
                questionNumber : 1,
                question : '¿Qué representa la fiesta del Kapak Raymi?',
                options : ['El tiempo de cosecha de los tuktus','El tiempo de siembra de los tuktus','El tiempo del primer deshierbe de las chakras','El último tiempo del último deshierbe de las chakras'],
                answer : 'El tiempo del primer deshierbe de las chakras'
            },
            {
                questionNumber : 2,
                question : '¿Quiénes son homenajeados durante el Kapak Raymi?',
                options : ['Las jatun mamas','Los ahijados','Los novios de boda','Los wawas'],
                answer : 'Los wawas'
            },
            {
                questionNumber : 3,
                question : '¿Durante el Kapak Raymi, cuál es el deber de los sabios ancestros?',
                options : ['Proteger a las criaturas de los peligros de la comunidad','Seleccionar el destino y profesión de los niños','Enseñarles a los niños el proceso de siembra de las chacras','Darles a los niños un día de juegos en las praderas'],
                answer : 'Seleccionar el destino y profesión de los niños'
            },
            {
                questionNumber : 4,
                question : '¿Cuándo es aconsejable realizar el deshierbe de las chakras?',
                options : ['Durante los primeros días del mes','Durante los últimos días del mes','Durante el cuarto creciente del mes','Durante el cuarto menguante del mes'],
                answer : 'Durante el cuarto menguante del mes'
            },
            {
                questionNumber : 5,
                question : '¿Qué es el wachu aysay?',
                options : ['Festival tradicional de cambio de semillas de maíz','Fiesta ancestral de cacería del zorro salvaje','Técnica para romper surcos','Baile típico celebrado durante el deshierbe'],
                answer : 'Técnica para romper surcos'
            },
            {
                questionNumber : 6,
                question : '¿Qué reciben los niños y niñas durante el Mushuk-Wara?',
                options : ['Los niños reciben la yunta y las niñas el mortero','Los niños reciben una cinta y las niñas un pantalón','Los niños reciben un sombrero y las niñas un collar','Los niños reciben un pantalón y las niñas una cinta'],
                answer : 'Los niños reciben un pantalón y las niñas una cinta'
            },
        ]  
    },
}