import ModuleScene from "./ModuleScene";
import { scenesKey } from "../utils/gameConstants";
import ToolsBox from "../GameObjects/commonObjects/toolsBox";
import SeedsBox from "../GameObjects/commonObjects/seedsBox";
import ChakraPlatform from "../GameObjects/commonObjects/chakraPlatform";
import { chakraDialogsList } from "../utils/information/assistant/assistantDialogs";
import { mainUIResources, chakraSoundResources } from "../utils/assetsPath";
import { toolsList } from "../utils/information/tools/toolsInformation";
import { seedsCollection } from "../utils/information/seeds/seedsInformation";
import { updatePlayerChakraRecords } from "../../../services/gameServices/chakraService";

export default class ChakraScene extends ModuleScene {
  constructor() {
    super({ key: scenesKey.CHAKRA_SCENE });
  }

  create() {
    this.createWindow("CHAKRA COMUNITARIA");
    this.displaySceneWindow();
    this.buildScene();
    this.setDefaultValues();

    this.listenUpcomingEvents();
    
    this.loadChakraData();

    // this.loadMockData();
    this.checkToolUnits();
    this.initialCheckToScene();
  }

  getToolsBox(){
    return this.toolsBox;
  }

  setToolsBox(toolsBox){
    this.toolsBox = toolsBox;
  }

  getSelectedTool(){ return this.selectedTool }
  setSelectedTool(tool){ this.selectedTool = tool }

  getSelectedChakra(){ return this.selectedChakra }
  setSelectedChakra(chakra){ this.selectedChakra = chakra }

  getSelectedSeed(){ return this.selectedSeed }
  setSelectedSeed(seed){ this.selectedSeed = seed }

  setCloudSfx(sfx){ this.cloudSfx = sfx }
  getCloudSfx(){ return this.cloudSfx }

  addCloudSoundEffect(){
    let soundConfig = {volume : 0.8, loop : true},
        cloudSound = this.sound.add(chakraSoundResources.CLOUD_EFFECT.key,soundConfig);
    this.setCloudSfx(cloudSound);
  }

  setDefaultValues(){
    this.setSelectedChakra(null);
    this.setSelectedTool(null);
    this.setSelectedSeed(null);
  }

  addToolsBox() {
    this.setToolsBox(new ToolsBox(this,0,0));
    this.windowScene.addElement(this.getToolsBox());
    this.getToolsBox().setPosition(-this.windowScene.width*0.34,-this.windowScene.height*0.055);
  }

  getSeedsBox(){
    return this.seedsBox;
  }

  setSeedsBox(seedsBox){
    this.seedsBox = seedsBox;
  }

  addSeedsBox(){
    this.setSeedsBox(new SeedsBox(this,0,0));
    this.windowScene.addElement(this.getSeedsBox());
    this.getSeedsBox().setPosition(-this.windowScene.width*0.0004,this.windowScene.height*0.34)
    .setDisplaySize(this.windowScene.width*0.935,this.windowScene.height*0.25);
  }


  getChakraPlatform(){
    return this.chakraPlatform;
  }

  setChakraPlatform(chakraPlatform){
    this.chakraPlatform = chakraPlatform;
  }

  addChakraPlatform(){
    this.setChakraPlatform(new ChakraPlatform(this,0,0));
    this.windowScene.addElement(this.getChakraPlatform());
    this.getChakraPlatform().setPosition(this.windowScene.width*0.13,-this.windowScene.height*0.055)
    .setDisplaySize(this.windowScene.width*0.675,this.windowScene.height*0.53);
  }

  buildScene(){
    this.addToolsBox();
    this.addSeedsBox();
    this.addChakraPlatform();
    this.addCloudSoundEffect();
  }

  loadChakraData(){
    this.loadEarnedSeeds();
    this.loadToolsUnits();
    this.loadPlayerChakras();
  }

  loadMockData(){
    this.loadMockTools();
    this.loadMockSeeds();
    this.loadPlayerChakras();
  }

  loadMockTools(){
    let toolsList = this.toolsBox.childrenList;
    toolsList.forEach(tool => {
      tool.unitLabel.setCounter(4,this);
    })
  }

  loadMockSeeds(){
    seedsCollection.forEach(seedInfo =>{
      this.seedsBox.findButton(seedInfo);
    })
  }

  loadToolsUnits(){
    if( localStorage.hasOwnProperty('loggedPlayer') &&
        localStorage.hasOwnProperty('toolRecords') ){
      let toolRecords = JSON.parse(localStorage.getItem('toolRecords'));  
      toolRecords.forEach((record)=>{
        let toolId = record.tool_id,
            quantity = record.quantity,
            toolInfo = toolsList.find((tool)=>{return tool.id === toolId}),
            toolType = toolInfo.type,
            addedTool = this.toolsBox.childrenList.find((tool)=>{return tool.type === toolType});
        addedTool.unitLabel.setCounter(quantity,this);
      })      
    }else{
      this.loadMockTools();
    }
    
    
    
  }

  loadEarnedSeeds(){
    if( localStorage.hasOwnProperty('loggedPlayer') && 
        localStorage.hasOwnProperty('seedRecords') ){
      let seedRecords = JSON.parse(localStorage.getItem('seedRecords'));
      let earnedSeedRecords = seedRecords.filter(record=>{return record.earned === 1});
      earnedSeedRecords.forEach((record)=>{
    
        let seedId = record.seed_id,
          seedData = seedsCollection.find((seedInfo)=>{return seedInfo.id === seedId});
          this.seedsBox.findButton(seedData);
      });
    }
  }

  initialCheckToScene(){
    let chakraPlatform = this.getChakraPlatform(),
        chakrasList = chakraPlatform.getChakrasList();

    if(chakrasList!==null || chakrasList !==[] || chakrasList!==undefined){
      let changedChakras = [];
      chakrasList.forEach(chakra => {
        let currentState = chakra.currentState,
            chakrasStates = chakra.getChakraStates();
        if(currentState !== chakrasStates[0]){
          changedChakras.push(chakra);
        }
      })
      if(changedChakras.length===0){
        this.showGuideArrow()
      }else{
        console.log('ya existen chakras alteradas!');
      }
    }else{
      console.log('no existen registros de chakras');
    }
  }

  updateChakraRecord(chakraId){
    if( localStorage.hasOwnProperty('loggedPlayer') && 
        localStorage.hasOwnProperty('chakraRecords')){
        let chakraRecords = JSON.parse(localStorage.getItem('chakraRecords')),
        loggedPlayer = JSON.parse(localStorage.getItem('loggedPlayer')),
        chakrasList = this.getChakraPlatform().getChakrasList(),
        chakra = chakrasList.find(chk => {return chk.getId()===chakraId}),
        currentState = chakra.getCurrentState(),
        oldRecord = chakraRecords.find(record =>{return record.chakra_id === chakraId}),
        index = chakraRecords.indexOf(oldRecord),
        updateData = {
          playerId:loggedPlayer.id,
          currentChakraStateId : currentState.getStateId(),
          status : chakra.getChakraStatus(),
          seedId : (chakra.getSeedData()!==null)?chakra.getSeedData().id:null
        };
  
      updatePlayerChakraRecords(chakraId,updateData).then(response => {
      let newRecord = response.data[0];
      chakraRecords[index]=newRecord;
      localStorage.setItem('chakraRecords',JSON.stringify(chakraRecords));
      })         
    }
  }


  loadPlayerChakras(){
    if( localStorage.hasOwnProperty('loggedPlayer') && 
        localStorage.hasOwnProperty('chakraRecords') ){
      let chakraRecords = JSON.parse(localStorage.getItem('chakraRecords'));
      let chakraPlatform = this.getChakraPlatform(),
          chakraList = chakraPlatform.getChakrasList(),
          emptyChakraRecords = chakraRecords.filter(record =>{return record.status === "empty"}),
          readyChakraRecords = chakraRecords.filter(record =>{return record.status === "ready"}),
          occupiedChakraRecords = chakraRecords.filter(record =>{return record.status === "occupied"}),
          completedChakraRecords = chakraRecords.filter(record =>{return record.status === "completed"});
  
      emptyChakraRecords.forEach(record => {
        let chakra = chakraList.find(chk => {return chk.getId()===record.chakra_id});
        let data = this.generateChakraData(record);    
        chakra.loadChakraData(data);
        chakraPlatform.addEmptyChakra(chakra);
      });

      readyChakraRecords.forEach(record => {
        let chakra = chakraList.find(chk => {return chk.getId()===record.chakra_id});
        let data = this.generateChakraData(record);   
        chakra.loadChakraData(data);
        chakraPlatform.addReadyChakra(chakra);
      });

      occupiedChakraRecords.forEach(record => {
        let chakra = chakraList.find(chk => {return chk.getId()===record.chakra_id});
        let data = this.generateChakraData(record);   
        chakra.loadChakraData(data);
        chakraPlatform.addOccupiedChakra(chakra);
        let seedId = chakra.getSeedData().id;
        this.checkSeedButton(seedId);
      });

      completedChakraRecords.forEach(record => {
        let chakra = chakraList.find(chk => {return chk.getId()===record.chakra_id});
        let data = this.generateChakraData(record);   
        chakra.loadChakraData(data);
        chakraPlatform.addCompletedChakra(chakra);
        let seedId = chakra.getSeedData().id;
        this.checkSeedButton(seedId);
      });
    }else{
      let chakraPlatform = this.getChakraPlatform();
      chakraPlatform.fillEmptyChakras();
    }
  }

  generateChakraData(record){
    let seedId = record.seed_id, seedData = null;
    if(seedId!==null){seedData = seedsCollection.find(data => {return data.id === seedId})}
    return { seedData, status : record.status, currentStateId : record.current_chakra_state_id}
  }


  listenUpcomingEvents(){
    this.events.on('addUnits',(data)=>{
      let addedTool = this.getToolsBox().childrenList.find((tool)=>{
        return tool.type === data[0];
      });
      addedTool.unitLabel.setCounter(data[1],this);
    });

    this.events.on('setSelectedTool',()=>{
      this.setSelectedTool(this.getToolsBox().getSelectedButton());
      this.clearSeedPart();
      this.hideReadyChakras();
    });

    this.events.on('setSelectedChakra',(chakraId)=>{
      let result = this.findChakra(chakraId);
      this.getChakraPlatform().setSelectedChakra(result.chakra);
      let selectedChakra = this.getChakraPlatform().getSelectedChakra();
      this.setSelectedChakra(selectedChakra);
    });

    this.events.on('setSelectedSeed',()=>{
      this.setSelectedSeed(this.getSeedsBox().getSelectedSeed());
      this.clearToolsPart();
      this.hideBubbles();
    });

    this.events.on('newTool',()=>{
      this.checkCorrectTool();
    });

    this.events.on('seedReady',()=>{
      this.wakeUpReadyChakras();
    });

    this.events.on('cleanSelectedValues',()=>{
      this.clearToolsPart();
      this.clearChakraPart();
      this.clearSeedPart();
      this.releaseLists();
      this.checkToolUnits();
    })


    this.events.on('check_chakra_status',()=>{
      let selectedTool = this.getSelectedTool();
      if(selectedTool!==null){selectedTool.playSoundEffect()}
      let selectedChakra = this.getSelectedChakra();
      if(selectedChakra!==null){
      selectedChakra.getCurrentState().hideChakraBubble();
      selectedChakra.checkCurrentStateStatus();
      this.checkSelectedChakraConditions(selectedChakra);
      }
    })

    this.events.on('reduceToolUnits',()=>{
      let toolsBox = this.getToolsBox();
      toolsBox.removeOneUnit(this);
      this.events.emit('cleanSelectedValues');
    });

    this.events.on('chakra_is_ready',(chakraId)=>{
      let results = this.findChakra(chakraId),
          chakra = results.chakra,
          fromType = results.listType,
          toType = 'ready';
      this.trassPassChakra(chakra,fromType,toType);
      this.time.addEvent({delay : 3000, callback : ()=>{this.emitToMainUI('chakra_is_ready')}});
    })

    this.events.on('getSeedData',() => {
      let selectedChakra = this.getSelectedChakra(),
          chakraId = selectedChakra.getId(),
          selectedSeed = this.getSelectedSeed(),
          seedData = selectedSeed.getSeedData();

      let results = this.findChakra(chakraId),
          chakra = results.chakra,
          fromType = results.listType,
          toType = 'occupied';

      chakra.addSeedStatesToChakraList(seedData);
      this.trassPassChakra(chakra,fromType,toType);
      this.markSeedButton();
      this.updateChakraRecord(chakraId);
      this.sound.play(chakraSoundResources.SEED_USED_EFFECT.key);
      this.events.emit('cleanSelectedValues');
      this.time.addEvent({delay : 1000, callback : ()=>{this.emitToMainUI('chakra_is_seeded')}});
    })

    this.events.on('chakraCompleted',(chakraId)=>{
      let foundChakra = this.findChakra(chakraId),
          chakra = foundChakra.chakra,
          fromType = foundChakra.listType,
          toType = 'completed';
      this.trassPassChakra(chakra,fromType,toType);
    })

    this.events.on('startClock',(chakraId)=>{
      let cloudSound = this.getCloudSfx();
      cloudSound.play();
      let chakraPlatform = this.getChakraPlatform(),
          chakrasList = chakraPlatform.getChakrasList(),
          foundChakra = chakrasList.find(chk => {return chk.getId() === chakraId});
      chakraPlatform.addBusyChakra(foundChakra);
      console.log('busy list: ',chakraPlatform.getBusyChakras())
      foundChakra.startTransformation();
      foundChakra.startClockCountdown();
    })

    this.events.on('clockCompleted',(chakraId)=>{
      let cloudSound = this.getCloudSfx();
      cloudSound.stop();
      let chakraPlatform = this.getChakraPlatform(),
          chakrasList = chakraPlatform.getChakrasList(),
          foundChakra = chakrasList.find(chk => {return chk.getId() === chakraId});
      foundChakra.finishTransformation();
      foundChakra.finishClockCountdown();
      chakraPlatform.removeBusyChakra(foundChakra);
      setTimeout(()=>{
        this.sound.play(chakraSoundResources.PROGRESS_BAR_EFFECT.key);
        this.updateChakraRecord(chakraId);
        this.checkChakraIsCompleted(foundChakra);
      },1000);
    })

    this.events.on('newSeed',(data)=>{
      this.getSeedsBox().findButton(data);
    });

    this.events.on('showRewardModal',(idTale)=>{
      if(idTale!==null){
        this.showRewardModal(5,idTale)}
    })

    this.events.on('cancelGuide',()=>{
      this.endGuide();
    })

  }

  reduceToolUnits(){
    let selectedTool = this.getSelectedSeed();
    if(selectedTool!==null){
      let toolsBox = this.getToolsBox();
      toolsBox.removeOneUnit(this);
    }
  }

  clearToolsPart(){
    let selectedTool = this.getSelectedTool();
    if(selectedTool!==null){
      let toolsBox = this.getToolsBox();
      toolsBox.cleanButtons();
      toolsBox.removeCursor(this);
      this.setSelectedTool(toolsBox.getSelectedButton());
    }
  }

  clearChakraPart(){
    let selectedChakra = this.getSelectedChakra();
    if(selectedChakra!==null){
      let chakraPlatform = this.getChakraPlatform();
      chakraPlatform.setSelectedChakra(null);
      this.setSelectedChakra(this.getChakraPlatform().getSelectedChakra());
    }
  }

  clearSeedPart(){
    let selectedSeed = this.getSelectedSeed();
    if(selectedSeed!==null){
      let seedsBox = this.getSeedsBox();
      seedsBox.cleanButtons();
      this.setSelectedSeed(seedsBox.getSelectedSeed());
    }
  }

  trassPassChakra(chakra,fromType,toType){
    let fromList = [], toList = [];
    switch(fromType){
      case 'empty':
        fromList = this.getChakraPlatform().getEmptyChakras();
        break;
      case 'ready':
        fromList = this.getChakraPlatform().getReadyChakras();
        break;
      case 'occupied':
        fromList = this.getChakraPlatform().getOccupiedChakras();
        break;
      default:
        break;
    }
    switch(toType){
      case 'ready':
        toList = this.getChakraPlatform().getReadyChakras();
        break;
      case 'occupied':
        toList = this.getChakraPlatform().getOccupiedChakras();
        break;
      case 'completed':
        toList = this.getChakraPlatform().getCompletedChakras();
        break;
      default:
        break;
    }

    //verify if exists in toList
    if(!toList.includes(chakra)){toList.push(chakra)}

    //eliminate chakra from fromList
    let index = fromList.indexOf(chakra);
    if(index>-1){ fromList.splice(index,1) }

    console.log('lista de empty: ',this.getChakraPlatform().getEmptyChakras());
    console.log('lista de ready: ',this.getChakraPlatform().getReadyChakras());
    console.log('lista de occupied: ',this.getChakraPlatform().getOccupiedChakras());
    console.log('lista de completed: ',this.getChakraPlatform().getCompletedChakras());
  }

  markSeedButton(){
    let seedsBox = this.getSeedsBox(),
        selectedSeed = this.getSelectedSeed();
    seedsBox.addSeedButtonToUsedList(selectedSeed);
    seedsBox.removeSeedButtonfromAvailableList(selectedSeed);
    selectedSeed.showIconContainer();
    
  }

  checkSeedButton(seedId){
    let seedsBox = this.getSeedsBox(),
        seedsList = seedsBox.getSeedsList(),
        searchedSeed = seedsList.find(seed => {return seed.getSeedData().id === seedId});
    seedsBox.addSeedButtonToUsedList(searchedSeed);
    seedsBox.removeSeedButtonfromAvailableList(searchedSeed);
    searchedSeed.showIconContainer();
  }

  checkSelectedChakraConditions(selectedChakra){
    if(this.getChakraPlatform().getEmptyChakras().includes(selectedChakra)){selectedChakra.checkChakraIsReady()}
    if(this.getChakraPlatform().getReadyChakras().includes(selectedChakra)){selectedChakra.checkChakraIsSeeded()}
    if(this.getChakraPlatform().getOccupiedChakras().includes(selectedChakra)){selectedChakra.checkChakraIsCompleted()}
  }

  checkChakraIsCompleted(chakra){
    if(chakra.getChakraStatus()==='completed'){
      let taleId = chakra.getSeedData().taleId;
      this.events.emit('showRewardModal',taleId);
    }
  }


  findChakra(chakraId){
    let chakraReference = this.getChakraPlatform().getChakrasList().find((chk)=>{return chk.getId()===chakraId});
    let emptyChakras = this.getChakraPlatform().getEmptyChakras(),
        readyChakras = this.getChakraPlatform().getReadyChakras(),
        occupiedChakras = this.getChakraPlatform().getOccupiedChakras(),
        completedChakras = this.getChakraPlatform().getCompletedChakras();

    let chakra = null, record = {};
    if(!emptyChakras.includes(chakraReference)){
      if(!readyChakras.includes(chakraReference)){
        if(!occupiedChakras.includes(chakraReference)){
          console.log('la chakra no se encuentra en la lista de ocupadas');
          if(!completedChakras.includes(chakraReference)){
            console.log('no encontre a tu chakra');
          }else{
            chakra = completedChakras.find((chk)=>{return chk.getId()===chakraId});
            record = {chakra, listType : 'completed'}
          }
        }else{
          chakra = occupiedChakras.find((chk)=>{return chk.getId()===chakraId});
          record = {chakra, listType : 'occupied'}
        }
      }else{
        chakra = readyChakras.find((chk)=>{return chk.getId()===chakraId});
        record = {chakra, listType : 'ready'}
      }
    }else{
      chakra = emptyChakras.find((chk)=>{return chk.getId()===chakraId});
      record = {chakra, listType : 'empty'}
    }
    return record;
  }

  checkCorrectTool(){
    let emptyChakras = this.getChakraPlatform().getEmptyChakras(),
        occupiedChakras = this.getChakraPlatform().getOccupiedChakras(),
        busyChakras = this.getChakraPlatform().getBusyChakras();

    //active all empty chakras
    emptyChakras.forEach((chakra)=>{
      if(chakra.getCurrentState().getTool()!==null&&!busyChakras.includes(chakra)){
        let chosenBtn = this.getToolsBox().getSelectedButton();
        let validTool = chakra.currentState.tool[0];
        if(chosenBtn!==null && chosenBtn.type===validTool.type){
          chakra.activateChakraElements();
        }else{
          chakra.disactivateChakraElements();
        }
      }
    });

    //active all occupied chakras
    occupiedChakras.forEach((chakra)=>{
      if(chakra.getCurrentState().getTool()!==null&&!busyChakras.includes(chakra)){
        let chosenBtn = this.getToolsBox().getSelectedButton();
        let validTool = chakra.currentState.tool[0];
        if(chosenBtn!==null && chosenBtn.type===validTool.type){
          chakra.activateChakraElements();
        }else{
          chakra.disactivateChakraElements();
        }
      }
    })
  }

  wakeUpReadyChakras(){
    let readyChakras = this.getChakraPlatform().getReadyChakras();
    readyChakras.forEach(chakra => { 
      console.log('la chakra (',chakra.getId(),') says: Im awake!');
      chakra.activateChakraElements()});
  }

  sleepReadyChakras(){
    let readyChakras = this.getChakraPlatform().getReadyChakras();
    readyChakras.forEach(chakra => {
      console.log('la chakra (',chakra.getId(),') went to sleep!');
      chakra.disactivateChakraElements()});
  }

  update(){
    this.blockLists();
  }

  checkChakras(){
    let chakrasList = this.getChakraPlatform().getChakrasList();
    chakrasList.forEach((chakra)=>{
      chakra.checkCurrentStateStatus(this);
    })
  }

  blockLists(){
    let selectedChakra = this.getSelectedChakra(),
        selectedTool = this.getSelectedTool(),
        selectedSeed = this.getSelectedSeed();
    if( (selectedChakra!==null && selectedTool!==null) || (selectedChakra!==null && selectedSeed!==null) ){
      let toolsList = this.getToolsBox().getToolsList(),
          seedsList = this.getSeedsBox().getSeedsList(),
          emptyChakrasList = this.getChakraPlatform().getEmptyChakras(),
          readyChakrasList = this.getChakraPlatform().getReadyChakras(),
          occupiedChakrasList = this.getChakraPlatform().getOccupiedChakras();
      toolsList.forEach(toolBtn => {toolBtn.freezeButton()});
      seedsList.forEach(seedBtn => {seedBtn.disableInteractive()});
      emptyChakrasList.forEach(chakra =>{ if(chakra!==selectedChakra){ chakra.disactivateChakraElements()}})
      if(readyChakrasList.length>0){
        readyChakrasList.forEach(chakra =>{ if(chakra!==selectedChakra){chakra.disactivateChakraElements()}})
      }
      if(occupiedChakrasList.length>0){
        occupiedChakrasList.forEach(chakra =>{ if(chakra!==selectedChakra){chakra.disactivateChakraElements()}})
      }
    }
  }



  checkToolUnits(){
    let toolBox = this.getToolsBox();
    toolBox.checkToolButtons();
  }

  releaseLists(){
    let toolsList = this.getToolsBox().getToolsList();
    toolsList.forEach(toolBtn => {toolBtn.setInteractive()});

    let seedsList = this.getSeedsBox().getSeedsList();
    seedsList.forEach(seedBtn => {seedBtn.setInteractive()})
  }

  






  createGuide(){
    this.startGuide();
    this.showGuidePart(chakraDialogsList.guide_dialogs.INTRO_DIALOG,
    500,()=>{this.showFirstGuidePart()})
    setTimeout(()=>{this.disableOmitButton()},1000);
  }

  showFirstGuidePart(){
    this.showGuideElements();
    this.showGuidePart(chakraDialogsList.guide_dialogs.DIALOG_01,
      500,()=>{this.showSecondGuidePart()})
  }

  showSecondGuidePart(){
    this.showGuidePlatform();
    this.showGuidePart(chakraDialogsList.guide_dialogs.DIALOG_02,
      500,()=>{this.showThirdGuidePart()})
  }

  showThirdGuidePart(){
    this.showGuideSeedBox();
    this.showGuidePart(chakraDialogsList.guide_dialogs.DIALOG_03,
      500,()=>{this.showFourthGuidePart()})
  }

  showFourthGuidePart(){
    this.showGuideToolsBox();
    this.showGuidePart(chakraDialogsList.guide_dialogs.DIALOG_04,
      500,()=>{this.showFifthGuidePart()})
  }

  showFifthGuidePart(){
    this.showGuideToolCounter();
    this.showGuidePart(chakraDialogsList.guide_dialogs.DIALOG_05,
      500,()=>{this.showLastGuidePart()})
  }

  showLastGuidePart(){
    this.hideGuideElements();
    this.showGuidePart(chakraDialogsList.guide_dialogs.FINAL_DIALOG,
      500,()=>{this.deleteGuideElements();
               this.quitGuide()})
    setTimeout(()=>{this.disableOmitButton()},1000);
  }

  startGuide(){
    this.emitToMainUI('changeVolume',0.2);
    //make sure this doesn't affect the real elements
    this.seedsBox.setVisible(false);
    this.chakraPlatform.setVisible(false);
    this.toolsBox.setVisible(false);

    this.createGuideElements();
  }

  quitGuide(){
    this.emitToMainUI('changeVolume',0.8);
    //recover the main elements
    this.seedsBox.setVisible(true);
    this.chakraPlatform.setVisible(true);
    this.toolsBox.setVisible(true);
    this.disableOmitButton();
  }

  endGuide(){
    this.deleteGuideElements();
    this.quitGuide();
  }

  createGuideElements(){
    this.guideElementsList = {};

    this.createGuideSeedBox();
    this.createGuideToolsBox();
    this.createGuideChakraPlatform();
    this.createGuideHand();

    // this.time.addEvent({delay:1000,callback:()=>{this.finishGuideAction()}});
  }

  createGuideSeedBox(){
    let guideSeedsBox = new SeedsBox(this,0,0);
    this.windowScene.addElement(guideSeedsBox);
    guideSeedsBox.setPosition(-this.windowScene.width*0.0004,this.windowScene.height*0.34)
    .setDisplaySize(this.windowScene.width*0.935,this.windowScene.height*0.25);

    guideSeedsBox.setAlpha(0.5);

    this.guideElementsList.guideSeedsBox = guideSeedsBox;
  }

  createGuideToolsBox() {
    let guideToolsBox = new ToolsBox(this,0, 0);
    this.windowScene.addElement(guideToolsBox);
    guideToolsBox.setPosition(-this.windowScene.width*0.34,-this.windowScene.height*0.055);
    guideToolsBox.childrenList.forEach(child => {child.disableInteractive()});

    guideToolsBox.setAlpha(0.5);

    this.guideElementsList.guideToolsBox = guideToolsBox;
  }

  createGuideChakraPlatform(){
    let guideChakraPlatform = new ChakraPlatform(this,0,0,'guide');
    this.windowScene.addElement(guideChakraPlatform);
    guideChakraPlatform.setPosition(this.windowScene.width*0.13,-this.windowScene.height*0.055)
    .setDisplaySize(this.windowScene.width*0.675,this.windowScene.height*0.53);

    guideChakraPlatform.setAlpha(0.5);

    this.guideElementsList.guideChakraPlatform = guideChakraPlatform;
  }  

  createGuideHand(){
    let hand = this.add.image(0,0,mainUIResources.HAND.key);
    this.windowScene.addElement(hand);
    hand.setVisible(false);

    this.guideElementsList.guideHand = hand;
  }

  deleteGuideElements(){
    let platform = this.guideElementsList.guideChakraPlatform,
        seedsBox = this.guideElementsList.guideSeedsBox,
        toolsBox = this.guideElementsList.guideToolsBox,
        hand = this.guideElementsList.guideHand;

        platform.destroy();
        seedsBox.destroy();
        toolsBox.destroy();
        hand.destroy();

    this.guideElementsList = {};
  }

  showGuideElements(){
    let platform = this.guideElementsList.guideChakraPlatform,
        seedsBox = this.guideElementsList.guideSeedsBox,
        toolsBox = this.guideElementsList.guideToolsBox;

    platform.setAlpha(1);
    seedsBox.setAlpha(1);
    toolsBox.setAlpha(1);

    // this.time.addEvent({delay:1000,callback:()=>{this.finishGuideAction()}});
  }

  hideGuideElements(){
    let platform = this.guideElementsList.guideChakraPlatform,
        seedsBox = this.guideElementsList.guideSeedsBox,
        toolsBox = this.guideElementsList.guideToolsBox;

    platform.setAlpha(0.5);
    seedsBox.setAlpha(0.5);
    toolsBox.setAlpha(0.5);

    // this.time.addEvent({delay:1000,callback:()=>{this.finishGuideAction()}});
  }

  showGuidePlatform(){
    let seedsBox = this.guideElementsList.guideSeedsBox,
        toolsBox = this.guideElementsList.guideToolsBox;

    seedsBox.setAlpha(0.5);
    toolsBox.setAlpha(0.5);
    // this.time.addEvent({delay:1000,callback:()=>{this.finishGuideAction()}});
  }

  showGuideSeedBox(){
    let platform = this.guideElementsList.guideChakraPlatform,
        seedsBox = this.guideElementsList.guideSeedsBox;

    platform.setAlpha(0.5);
    seedsBox.setAlpha(1);
    // this.time.addEvent({delay:1000,callback:()=>{this.finishGuideAction()}});
  }

  showGuideToolsBox(){
    let platform = this.guideElementsList.guideChakraPlatform,
        seedsBox = this.guideElementsList.guideSeedsBox,
        toolsBox = this.guideElementsList.guideToolsBox;

    platform.setAlpha(0.5);
    seedsBox.setAlpha(0.5);
    toolsBox.setAlpha(1);
    // this.time.addEvent({delay:1000,callback:()=>{this.finishGuideAction()}});
  }

  showGuideToolCounter(){
    let toolsBox = this.guideElementsList.guideToolsBox,
        compostBtn = toolsBox.compostBtn;

    toolsBox.childrenList.forEach((tool)=>{
      if(tool!==compostBtn){
        tool.setAlpha(0.5);
      }
    })
    
    // this.time.addEvent({delay:1000,callback:()=>{this.finishGuideAction()}});
  }

  hideBubbles(){
    let chakrasList = this.getChakraPlatform().getChakrasList();
    chakrasList.forEach(chakra => {
      let currentState = chakra.getCurrentState();
      if(currentState!==null){currentState.hideChakraBubble()}
    })
  }

  hideReadyChakras(){
    let chakraPlatform = this.getChakraPlatform(),
    readyChakrasList = chakraPlatform.getReadyChakras();

    readyChakrasList.forEach(chakra =>{chakra.getCurrentState().hideChakraBubble()});
  }


  


  
}
