import Phaser from 'phaser'
import { Image, createGroupElements, gridAlignGroupElements, addChildrenToContainer } from "../../utils/functions";

export default class Button extends Phaser.GameObjects.Container{
    constructor(scene,x,y,texture,icon,text){
        super(scene,x,y);
        this.text = text;
        this.background = new Image(scene,0,0,texture);
        this.icon = new Image(scene,0,0,icon);
        this.label=null;
        this.gridConfig = null;
        this.elementsList=[];
    }

    setContainer(){
        this.add(this.background).setSize(this.background.width,this.background.height).setInteractive();
    }

    buildButton(scene){
        let groupElements = createGroupElements(scene,this.elementsList);
        gridAlignGroupElements(scene,groupElements,this.gridConfig);
        addChildrenToContainer(this,groupElements.getChildren());
    }

    selectButton(style){
        this.background.setTint(style.backColor);
        this.label.text.setColor(style.fontColor).setStroke(style.fontColor,1);
    }

    deselectButton(){
        this.background.clearTint();
        this.label.text.setColor('#000').setStroke(0x000,1);
    }
}