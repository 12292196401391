import MenuScene from "../baseScenes/menuScene";
import { scenesKey } from "../../../utils/gameConstants";
import GameOptionsMenu from "../../../GameObjects/commonObjects/init-scene-components/welcome-page-components/gameOptionsMenu";

export default class IntroScene extends MenuScene{
    constructor(){
        super({key : scenesKey.WELCOME_SCENE})
    }

    create(){
        this.setInitialProperties();
        this.checkUploadedGameRecords();
        this.createTheScene();
        this.createOptionsMenu();
        this.redirectScenes();
    }

    setInitialProperties(){
        let gameWidth = this.getGameWidth(), gameHeight = this.getGameHeight();
        this.setMenuXPosition(gameWidth * 0.3);
        this.setMenuYPosition(gameHeight * 0.6);
        this.setMenuXScale(gameWidth*0.0005);
        this.setMenuYScale(gameHeight*0.0008);
    }

    createOptionsMenu(){
        let menu = new GameOptionsMenu(this,0,0);
        menu.createIntroMenu();
        this.setOptionsMenu(menu);
        this.modifyMenuOptionsAttributes();
        this.add.existing(this.getOptionsMenu());
    }

    redirectScenes(){
        let menu = this.getOptionsMenu(),
        signUpOption = menu.getSignUpOption(),
        logInOption = menu.getLogInOption(),
        fastGameOption = menu.getFastGameOption();
        signUpOption.on('pointerup',()=>{
            this.scene.start(scenesKey.SIGNUP_SCENE);
        })

        logInOption.on('pointerup',()=>{
            this.scene.start(scenesKey.LOGIN_SCENE);
        })

        fastGameOption.on('pointerup',() => {
            // this.scene.stop();
            this.scene.start(scenesKey.GAME_MENU_SCENE);
        })
        
    }

    checkUploadedGameRecords(){
        if(localStorage.hasOwnProperty('loggedPlayer') && 
           localStorage.hasOwnProperty('taleRecords') && 
           localStorage.hasOwnProperty('quizRecords') &&
           localStorage.hasOwnProperty('seedRecords') &&
           localStorage.hasOwnProperty('pieceRecords') &&
           localStorage.hasOwnProperty('toolRecords') &&
           localStorage.hasOwnProperty('chakraRecords')){
               this.scene.start(scenesKey.GAME_MENU_SCENE);
           }else{
               console.log('aun no están cargados las listas');
           }
    }


}