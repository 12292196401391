import Phaser from 'phaser';
import { mainUIResources, mainUISoundResources } from '../../utils/assetsPath';
import Ribbon from './Ribbon';
import Button from './Button';
import { scenesKey } from '../../utils/gameConstants';
import { gridAlignGroupElements, addChildrenToContainer } from '../../utils/functions';

export default class WindowModal extends Phaser.GameObjects.Container{
    constructor(scene,x,y){
        super(scene,x,y);
        this.scene = scene;
        this.body = null;
        this.ribbon = null;
        this.button = null;
        this.text = null;

        this.description = null;
        this.title = null;
    }

     setDescription(description){
        this.description = description;
    }

    setTitle(title){
        this.title = title;
    }

    blockButton(){
        this.button.setAlpha(0.5).disableInteractive();
    }

    unblockButton(){
        this.button.setAlpha(1).setInteractive();
    }

    setVictorySound(sound){ this.victorySound = sound }
    getVictorySound(){ return this.victorySound }

    setCheeringSound(sound){ this.cheeringSound = sound }
    getCheeringSound(){ return this.cheeringSound }

    setBody(scene){
        this.body = scene.add.image(0,0,mainUIResources.WINDOW_MODAL.key);
        this.add(this.body).setSize(this.body.width,this.body.height);
    }

    getBody(){
        return this.body;
    }

    setRibbon(scene,title){
        this.ribbon = new Ribbon(scene,0,0,mainUIResources.RIBBON_WINDOW_MODAL.key,title);
        this.add(this.ribbon);
        this.ribbon.createRibbon(scene);
        this.ribbon.text.setStyle({
            fontFamily : 'Helvetica',
            fontSize : '40px',
            color : '#000',
            align : 'center',
            stroke : '#000',
            strokeThickness : 2, 
        });

        this.ribbon.setPosition(0,-350);
    }

    setCongratulationRibbon(scene){
        this.ribbon = scene.add.image(0,-300,mainUIResources.RIBBON_CONGRATULATIONS.key);
        this.add(this.ribbon);
    }

    setCongratulationText(scene,message){
        let textStyle = {
            fontFamily:'Helvetica',
            fontSize:'70px',
            color:'#E15315',
            align:'center',
            stroke:'#7C040F',
            strokeThickness:5,
            lineSpacing : -15
        }
        this.text = scene.add.text(0,60,message,textStyle);
        this.text.setOrigin(0.5,1.7);
        this.add(this.text);
    }

    setButton(scene){
        this.button = new Button(scene,0,330,mainUIResources.CONTINUE_BUTTON.key,null,null);
        this.button.setContainer();
        this.add(this.button);
    }

    playMusic(){
        this.blockButton();
        this.getVictorySound().play();
        this.getVictorySound().once('complete',()=>{
            this.getCheeringSound().play();
            this.getCheeringSound().once('complete',()=>{
                this.unblockButton();
            })
        })
    }


    setButtonActions(scene,action){
        console.log()
        this.button.on('pointerdown',()=>{
            this.button.background.setTexture(mainUIResources.CONTINUE_BUTTON_SELECTED.key);
        });

        this.button.on('pointerup',()=>{
            scene.sound.play(mainUISoundResources.DIALOG_MODAL_BUTTON_CLIC.key);
            scene.events.emit('modalgone');
            let mainUI = scene.scene.get(scenesKey.MAINUI_SCENE);
            mainUI.events.emit('active_buttons');
            if(action!=null){
                action();
            }else{
                this.destroy();
            }
        });
    }

    setAuxButton(text){
        let container = this.scene.add.container(0,0),
            width = 300,
            height = 150,
            radius = 20,
            style = {
                fontFamily : 'Helvetica',
                fontSize : '40px',
                color : '#e92929',
                align : 'center',
                stroke : '#000',
                strokeThickness : 1, 
            },
            title = this.scene.add.text(0,0,text,style),
            board = this.scene.add.graphics(),
            body = this.scene.add.graphics();
        
        container.setSize(width,height);

        board.lineStyle(2,0x000,1).fillStyle(0x726330,1)
        .fillRoundedRect(-width/2,-height/2,width,height,radius)
        .strokeRoundedRect(-width/2,-height/2,width,height,radius);

        body.lineStyle(2,0x000,1).fillStyle(0x78D25E,1)
        .fillRoundedRect(-width/2+(radius/2),-height/2+(radius/2),width-radius,height-radius,radius)
        .strokeRoundedRect(-width/2+(radius/2),-height/2+(radius/2),width-radius,height-radius,radius);
        
        title.setOrigin(0.5);

        container.add([board,body,title])
        .setInteractive();

        return container;
    }

    createSounds(scene){
        let victoryConfig = {volume : 0.8},
            cheeringConfig = {volume : 0.7},
            victorySound = scene.sound.add(mainUISoundResources.VICTORY.key,victoryConfig),
            cheeringSound = scene.sound.add(mainUISoundResources.CHILDREN_CHEERING.key,cheeringConfig);
        this.setVictorySound(victorySound);
        this.setCheeringSound(cheeringSound);
    }

    addAuxButtons(){
        this.yesButton = this.setAuxButton('SI');
        this.noButton = this.setAuxButton('NO');

        let group = this.scene.add.group([this.noButton,this.yesButton]),
            width = this.yesButton.width,
            container = this.scene.add.container(-150,100),
            gridConfig = {
                width : 2,
                height : 1,
                cellWidth : width+20,
                position : 6
            };
        gridAlignGroupElements(this.scene,group,gridConfig);
        addChildrenToContainer(container,group.getChildren());

        this.add(container);
    }

}