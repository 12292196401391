import * as types from '../actions/actionTypes';
import initialState from './initial-state/initialState';

export default function seedReducer(state = initialState.seed_records,action){
    switch(action.type){

        case types.LOAD_SEED_RECORDS_SUCCESS:
            return state;

        case types.LOAD_SEED_LIST:
            return action.recordList;

        case types.CREATE_SEED_RECORDS_SUCCESS:
            return state;
        
        default :
            return state;
            
    }
}
