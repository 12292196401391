import ModuleScene from "./ModuleScene";
import { scenesKey } from "../utils/gameConstants";
import QuestionCard from "../GameObjects/commonObjects/tale-pages/game-objects/questionCard";
import ResultScene from "./resultScene";

export default class QuizScene extends ModuleScene{
    constructor(){
        super({key:scenesKey.QUIZ_SCENE});
        this.quizTitle = null;
        this.cardList = [];
        this.checkedQuestions = [];
        this.currentQuestionCard = null;
        this.testCounter = 0;
        this.quizIsCompleted = null;

        this.data = null;

        this.questionsList = null;
    }

    setQuestionsList(list){
        this.questionsList = list;
    }

    setQuizId(id){
        this.quizId = id;
    }

    setQuizTitle(title){
        this.quizTitle = title;
    }

    setPrice(price){
        this.price = price;
    }

    setQuizStatus(status){
        this.quizIsCompleted = status;
    }

    setQuizType(type){
        this.quizType = type;
    }

    init(){
        let data = JSON.parse(localStorage.getItem('selectedQuiz'));
        console.log('la información es la siguiente: ',data);
        this.setQuizId(data.id);
        this.setQuizTitle(data.title);
        this.setQuestionsList(data.questions);
        this.setPrice(data.price);
        this.setQuizStatus(data.isCompleted);
        this.setQuizType(data.type);
    }

    create(){
        this.createWindow(`TRIVIA CULTURAL\n"${this.quizTitle}"`);
        this.displaySceneWindow();

        this.setQuestionCards(this.questionsList);
        this.setInitialQuestionCard();
        this.listenUpcomingEvents();
    }

    createQuestionCard(data,totalQuestions){
        let example = new QuestionCard(0,0,this,data,totalQuestions,this.quizType);
        return example;
    }

    setQuestionCards(data){
        data.forEach((question)=>{
            let questionCard = this.createQuestionCard(question,data.length);
            this.cardList.push(questionCard);
        });

        this.cardList.forEach((card)=>{
            this.windowScene.addElement(card);
            card.setPosition(0,this.windowScene.height*0.083);
            card.setVisible(false);
        });
    }

    setCurrentCard(card){
        this.currentQuestionCard = card;
    }

    showCurrentCard(){
        this.currentQuestionCard.setVisible(true);
        this.cardList.forEach((card)=>{
            if(card!==this.currentQuestionCard){
                card.setVisible(false);
            }
        })
    }

    setInitialQuestionCard(){
        let initial = this.cardList[0];
        this.setCurrentCard(initial);
        this.showCurrentCard();
    }

    listenUpcomingEvents(){
        this.events.on('questionchecked',(checkedCard)=>{
            this.testCounter+=checkedCard.result;
            this.removeCard(checkedCard);
        })
    }

    removeCard(card){
        let index = this.cardList.indexOf(card);
        let mycard = this.cardList[index];
        this.checkedQuestions.push(mycard);
        index++;
        if(index<this.cardList.length){
            this.getNextCard(index);
        }
        this.checkQuizStatus();
    }

    getNextCard(index){
        let newCard = this.cardList[index];
        this.setCurrentCard(newCard);
        this.showCurrentCard();
    }

    checkQuizStatus(){
        if(this.checkedQuestions.length===this.questionsList.length){
            this.scene.add(scenesKey.RESULT_SCENE,ResultScene);
            this.scene.start(scenesKey.RESULT_SCENE,this.createPreviewFile());
        }
    }

    createPreviewFile(){
        return {
            id : this.quizId,
            title : this.quizTitle,
            isCompleted : this.quizIsCompleted,
            totalQuestions : this.cardList.length,
            correctAnswers : this.testCounter,
            price : this.price

        }
    }
}