import Phaser from 'phaser';

export default class RitualCard extends Phaser.GameObjects.Container{
    constructor(scene,x,y,data){
        super(scene,x,y);
        this.setData(data);
        this.setSize(450,450);

        this.buildCard(scene);
    }

    setData(data){
        this.taleData = data;
        this.description = this.taleData.description;
        this.photo = this.taleData.photo
    }

    setBody(scene){
        let body = scene.add.graphics();
        body.fillStyle(0x9b8465,1).lineStyle(3,0x000,1);
        body.fillRoundedRect(-225,-152,450,450,15)
        .strokeRoundedRect(-225,-152,450,450,15);
        this.add(body);
    }

    setInnerBody(scene){
        let innerBody = scene.add.graphics();
        innerBody.fillStyle(0xD2C75E,1).fillRoundedRect(-215,90,430,200,15)
        .lineStyle(3,0x000,1).strokeRoundedRect(-215,90,430,200,15);
        this.add(innerBody);
    }

    setParagraph(scene){
        let labelContainer = scene.add.container(0,0);
        labelContainer.setSize(400,170);
        let style = {
            fontFamily : 'Helvetica',
            fontSize : '20px',
            color : '#000',
            align : 'justify',
            wordWrap : {width: 390}
        };
        let label = scene.add.graphics();
        label.fillStyle(0xffffff,0.5).fillRoundedRect(-200,105,400,170,15)
        .lineStyle(3,0x000,0.5).strokeRoundedRect(-200,105,400,170,15);
        let paragraph = scene.add.text(0,190,this.description,style);
        paragraph.setOrigin(0.5);

        labelContainer.add([label,paragraph]);
        this.add(labelContainer);
    }

    setPhoto(scene){
        let photoContainer = scene.add.container(0,0);
        photoContainer.setSize(430,220);
        let label = scene.add.graphics();
        label.fillStyle(0xffffff,0.5).fillRoundedRect(-215,-140,430,220,15)
        .lineStyle(3,0x000,0.5).strokeRoundedRect(-215,-140,430,220,15);
        this.photo = scene.add.image(0,-30,this.photo);
        this.photo.setScale(0.9);
        photoContainer.add([label,this.photo]);
        this.add(photoContainer);
    }

    buildCard(scene){
        this.setBody(scene);
        this.setInnerBody(scene);
        this.setPhoto(scene);
        this.setParagraph(scene);
    }


}