import { toolsInformation } from "../tools/toolsInformation";
import { chakraStatesResources, cornSeedStatesResources, potatoSeedStatesResources, ocaSeedStatesResources, beanSeedStatesResources, lupineSeedStatesResources, zamboSeedStatesResources, mashuaSeedStatesResources, mellocoSeedStatesResources, jicamaSeedStatesResources, camoteSeedStatesResources, arracachaSeedStatesResources, zapalloSeedStatesResources, chakraBubbleResources } from "../../assetsPath";

export const chakraStatusInfo = {
    EMPTY : { id : 1, name : 'VACÍA', status : 'empty' },
    READY : { id : 2, name : 'LISTA', status : 'ready' },
    SEEDED : { id : 3, name : 'SEMBRADA', status : 'occupied' },
    COMPLETED : {id : 4, name : 'COMPLETA', status : 'completed'}
}

export const chakraStatusList = [
    ...Object.values(chakraStatusInfo)
]


export const chakraStates = {
    UNDERGROWTH : {
        id : 1,
        background : chakraStatesResources.BG_STATE_01.key,
        uniqueElement : chakraStatesResources.UNIQUE_ELEMENT_01.key,
        totalElements : 4,
        tool : [toolsInformation.SCISSORS_TOOL],
        duration : 8,
        action : 'destroy',
        scale : {x : 0.35, y : 0.35},
        gridConfig : { width : 1, height : 4, cellWidth : 29, cellHeight : 30, x : -130, y : -220}
    },

    TRIMMED : {
        id : 2,
        background : chakraStatesResources.BG_STATE_02.key,
        uniqueElement : chakraStatesResources.UNIQUE_ELEMENT_02.key,
        totalElements : 4,
        tool : [toolsInformation.SHOVEL_TOOL],
        duration : 6,
        action : 'destroy',
        scale : {x : 0.4, y : 0.4},
        gridConfig : { width : 1, height : 4, cellWidth : 34, cellHeight : 40, x : -155, y : -80}
    },

    DIGGED : {
        id : 3,
        background : chakraStatesResources.BG_STATE_03.key,
        uniqueElement : chakraStatesResources.UNIQUE_ELEMENT_03.key,
        totalElements : 4,
        tool : [toolsInformation.HOE_TOOL],
        duration : 6,
        action : 'destroy',
        scale : {x : 0.25, y : 0.35},
        gridConfig : { width : 1, height : 4, cellWidth : 22, cellHeight : 40, x : -200, y : -100}
    },

    FURROWED : {
        id : 4,
        background : chakraStatesResources.BG_STATE_04.key,
        uniqueElement : chakraStatesResources.UNIQUE_ELEMENT_04.key,
        totalElements : 4,
        tool : [toolsInformation.COMPOST_TOOL],
        duration : 6,
        action : 'destroy',
        scale : {x : 0.20, y : 0.35},
        gridConfig : { width : 1, height : 4, cellWidth : 34, cellHeight : 40, x : -218, y : -100}
    },

    POTTED : {
        id : 5,
        background : chakraStatesResources.BG_STATE_05.key,
        uniqueElement : chakraStatesResources.UNIQUE_ELEMENT_05.key,
        totalElements : 4,
        duration : 0,
        seedIcon : chakraBubbleResources.BUBBLE_ICON_SEED.key,
        action : 'change',
        scale : {x : 0.20, y : 0.35},
        gridConfig : { width : 1, height : 4, cellWidth : 34, cellHeight : 40, x : -225, y : -100}
    },
}

export const seedInitChakraState = {
    SEEDED : {
        id : 6,
        background : chakraStatesResources.BG_STATE_06.key,
        uniqueElement : chakraStatesResources.UNIQUE_ELEMENT_06.key,
        totalElements : 4,
        tool : [toolsInformation.SPRAY_TOOL],
        duration : 8,
        action : 'destroy',
        scale : {x : 0.20, y : 0.35},
        gridConfig : { width : 1, height : 4, cellWidth : 34, cellHeight : 40, x : -222, y : -100}
    },
}

export const cornSeedStatesInfo = {
    CORN_STATE_01 : {
        id : 7,
        background : chakraStatesResources.BG_STATE_07.key,
        uniqueElement : cornSeedStatesResources.CORN_STATE_01.key,
        totalElements : 8,
        tool : [toolsInformation.SCISSORS_TOOL],
        duration : 8,
        action : 'destroy',
        scale : {x : 0.4, y : 0.4},
        gridConfig : { width : 2, height : 4, cellWidth : 29, cellHeight : 40, x : -57, y : -115}
    },
    CORN_STATE_02 : {
        id : 8,
        background : chakraStatesResources.BG_STATE_08.key,
        uniqueElement : cornSeedStatesResources.CORN_STATE_02.key,
        totalElements : 8,
        tool : [toolsInformation.SHOVEL_TOOL],
        duration : 8,
        action : 'destroy',
        scale : {x : 0.32, y : 0.32},
        gridConfig : { width : 2, height : 4, cellWidth : 29, cellHeight : 35, x : -132, y : -177}
    },
    CORN_STATE_03 : {
        id : 9,
        background : chakraStatesResources.BG_STATE_09.key,
        uniqueElement : cornSeedStatesResources.CORN_STATE_03.key,
        totalElements : 8,
        tool : [toolsInformation.HOE_TOOL],
        duration : 8,
        action : 'destroy',
        scale : {x : 0.3, y : 0.18},
        gridConfig : { width : 2, height : 4, cellWidth : 29, cellHeight : 30, x : -235, y : -407}
    },
    CORN_STATE_04 : {
        id : 10,
        background : chakraStatesResources.BG_STATE_10.key,
        uniqueElement : cornSeedStatesResources.CORN_STATE_04.key,
        totalElements : 4,
        // tool : [],
        action : 'none',
        scale : {x : 0.3, y : 0.28},
        gridConfig : { width : 2, height : 2, cellWidth : 29, cellHeight : 20, x : -250, y : -385}
    },
}

export const potatoSeedStatesInfo = {
    POTATO_STATE_01 : {
        id : 11,
        background : chakraStatesResources.BG_STATE_07.key,
        uniqueElement : potatoSeedStatesResources.POTATO_STATE_01.key,
        totalElements : 4,
        tool : [toolsInformation.SCISSORS_TOOL],
        duration : 6,
        action : 'destroy',
        scale : {x : 0.18, y : 0.2},
        gridConfig : { width : 1, height : 4, cellHeight : 40, x : -250, y : -180}
    },
    POTATO_STATE_02 : {
        id : 12,
        background : chakraStatesResources.BG_STATE_08.key,
        uniqueElement : potatoSeedStatesResources.POTATO_STATE_02.key,
        totalElements : 4,
        tool : [toolsInformation.SHOVEL_TOOL],
        duration : 8,
        action : 'destroy',
        scale : {x : 0.18, y : 0.22},
        gridConfig : { width : 1, height : 4, cellHeight : 26, x : -268, y : -348}
    },
    POTATO_STATE_03 : {
        id : 13,
        background : chakraStatesResources.BG_STATE_09.key,
        uniqueElement : potatoSeedStatesResources.POTATO_STATE_03.key,
        totalElements : 4,
        tool : [toolsInformation.HOE_TOOL],
        duration : 12,
        action : 'destroy',
        scale : {x : 0.18, y : 0.22},
        gridConfig : { width : 1, height : 4, cellHeight : 24, x : -268, y : -368}
    },
    POTATO_STATE_04 : {
        id : 14,
        background : chakraStatesResources.BG_STATE_10.key,
        uniqueElement : potatoSeedStatesResources.POTATO_STATE_04.key,
        totalElements : 4,
        tool : [toolsInformation.SPRAY_TOOL],
        duration : 14,
        action : 'none',
        scale : {x : 0.18, y : 0.22},
        gridConfig : { width : 1, height : 4, cellHeight : 24, x : -268, y : -364}
    },
}

export const ocaSeedStatesInfo = {
    OCA_STATE_01 : {
        id : 15,
        background : chakraStatesResources.BG_STATE_07.key,
        uniqueElement : ocaSeedStatesResources.OCA_STATE_01.key,
        totalElements : 4,
        tool : [toolsInformation.SCISSORS_TOOL],
        duration : 6,
        action : 'destroy',
        scale : {x : 0.18, y : 0.2},
        gridConfig : { width : 1, height : 4, cellWidth : 20, cellHeight : 36, x : -265, y : -205}
    },
    OCA_STATE_02 : {
        id : 16,
        background : chakraStatesResources.BG_STATE_08.key,
        uniqueElement : ocaSeedStatesResources.OCA_STATE_02.key,
        totalElements : 4,
        tool : [toolsInformation.SHOVEL_TOOL],
        duration : 8,
        action : 'destroy',
        scale : {x : 0.18, y : 0.2},
        gridConfig : { width : 1, height : 4, cellWidth : 29, cellHeight : 25, x : -290, y : -350}
    },
    OCA_STATE_03 : {
        id : 17,
        background : chakraStatesResources.BG_STATE_09.key,
        uniqueElement : ocaSeedStatesResources.OCA_STATE_03.key,
        totalElements : 4,
        tool : [toolsInformation.HOE_TOOL],
        duration : 10,
        action : 'destroy',
        scale : {x : 0.2, y : 0.21},
        gridConfig : { width : 1, height : 4, cellWidth : 29, cellHeight : 23, x : -307, y : -345}
    },
    OCA_STATE_04 : {
        id : 18,
        background : chakraStatesResources.BG_STATE_10.key,
        uniqueElement : ocaSeedStatesResources.OCA_STATE_04.key,
        totalElements : 4,
        // tool : [toolsInformation.SPRAY_TOOL],
        duration : 14,
        action : 'none',
        scale : {x : 0.2, y : 0.21},
        gridConfig : { width : 1, height : 4, cellWidth : 29, cellHeight : 23, x : -340, y : -345}
    },
}

export const beanSeedStatesInfo = {
    BEAN_STATE_01 : {
        id : 19,
        background : chakraStatesResources.BG_STATE_07.key,
        uniqueElement : beanSeedStatesResources.BEAN_STATE_01.key,
        totalElements : 8,
        tool : [toolsInformation.SCISSORS_TOOL],
        duration : 6,
        action : 'destroy',
        scale : {x : 0.25, y : 0.2},
        gridConfig : { width : 2, height : 4, cellWidth : 30, cellHeight : 40, x : -122, y : -135}
    },
    BEAN_STATE_02 : {
        id : 20,
        background : chakraStatesResources.BG_STATE_08.key,
        uniqueElement : beanSeedStatesResources.BEAN_STATE_02.key,
        totalElements : 8,
        tool : [toolsInformation.SHOVEL_TOOL],
        duration : 8,
        action : 'destroy',
        scale : {x : 0.2, y : 0.2},
        gridConfig : { width : 2, height : 4, cellWidth : 30, cellHeight : 30, x : -188, y : -210}
    },
    BEAN_STATE_03 : {
        id : 21,
        background : chakraStatesResources.BG_STATE_09.key,
        uniqueElement : beanSeedStatesResources.BEAN_STATE_03.key,
        totalElements : 8,
        tool : [toolsInformation.HOE_TOOL],
        duration : 12,
        action : 'destroy',
        scale : {x : 0.2, y : 0.17},
        gridConfig : { width : 2, height : 4, cellWidth : 30, cellHeight : 18, x : -320, y : -500}
    },
    BEAN_STATE_04 : {
        id : 22,
        background : chakraStatesResources.BG_STATE_10.key,
        uniqueElement : beanSeedStatesResources.BEAN_STATE_04.key,
        totalElements : 4,
        // tool : [toolsInformation.SPRAY_TOOL],
        duration : 14,
        action : 'none',
        scale : {x : 0.18, y : 0.16},
        gridConfig : { width : 2, height : 2, cellWidth : 30, cellHeight : 18, x : -365, y : -625}
    },
}

export const lupineSeedStatesInfo = {
    LUPINE_STATE_01 : {
        id : 23,
        background : chakraStatesResources.BG_STATE_07.key,
        uniqueElement : lupineSeedStatesResources.LUPINE_STATE_01.key,
        totalElements : 8,
        tool : [toolsInformation.SCISSORS_TOOL],
        duration : 4,
        action : 'destroy',
        scale : {x : 0.2, y : 0.2},
        gridConfig : { width : 2, height : 4, cellWidth : 30, cellHeight : 40, x : -200, y : -265}
    },
    LUPINE_STATE_02 : {
        id : 24,
        background : chakraStatesResources.BG_STATE_08.key,
        uniqueElement : lupineSeedStatesResources.LUPINE_STATE_02.key,
        totalElements : 8,
        tool : [toolsInformation.SHOVEL_TOOL],
        duration : 8,
        action : 'destroy',
        scale : {x : 0.15, y : 0.15},
        gridConfig : { width : 2, height : 4, cellWidth : 30, cellHeight : 25, x : -330, y : -335}
    },
    LUPINE_STATE_03 : {
        id : 25,
        background : chakraStatesResources.BG_STATE_09.key,
        uniqueElement : lupineSeedStatesResources.LUPINE_STATE_03.key,
        totalElements : 8,
        tool : [toolsInformation.HOE_TOOL],
        duration : 12,
        action : 'destroy',
        scale : {x : 0.15, y : 0.15},
        gridConfig : { width : 2, height : 4, cellWidth : 33, cellHeight : 18, x : -320, y : -542}
    },
    LUPINE_STATE_04 : {
        id : 26,
        background : chakraStatesResources.BG_STATE_10.key,
        uniqueElement : lupineSeedStatesResources.LUPINE_STATE_04.key,
        totalElements : 4,
        // tool : [toolsInformation.SPRAY_TOOL],
        duration : 16,
        action : 'none',
        scale : {x : 0.2, y : 0.16},
        gridConfig : { width : 2, height : 2, cellWidth : 40, cellHeight : 20, x : -325, y : -600}
    },
}

export const zamboSeedStatesInfo = {
    ZAMBO_STATE_01 : {
        id : 27,
        background : chakraStatesResources.BG_STATE_07.key,
        uniqueElement : zamboSeedStatesResources.ZAMBO_STATE_01.key,
        totalElements : 4,
        tool : [toolsInformation.SCISSORS_TOOL],
        duration : 10,
        action : 'destroy',
        scale : {x : 0.3, y : 0.3},
        gridConfig : { width : 1, height : 4, cellWidth : 42, cellHeight : 40, x : -130, y : -85}
    },
    ZAMBO_STATE_02 : {
        id : 28,
        background : chakraStatesResources.BG_STATE_08.key,
        uniqueElement : zamboSeedStatesResources.ZAMBO_STATE_02.key,
        totalElements : 4,
        tool : [toolsInformation.SHOVEL_TOOL],
        duration : 10,
        action : 'destroy',
        scale : {x : 0.3, y : 0.3},
        gridConfig : { width : 1, height : 4, cellWidth : 20, cellHeight : 40, x : -170, y : -115}
    },
    ZAMBO_STATE_03 : {
        id : 29,
        background : chakraStatesResources.BG_STATE_09.key,
        uniqueElement : zamboSeedStatesResources.ZAMBO_STATE_03.key,
        totalElements : 4,
        tool : [toolsInformation.HOE_TOOL],
        duration : 10,
        action : 'destroy',
        scale : {x : 0.3, y : 0.3},
        gridConfig : { width : 1, height : 4, cellHeight : 45, x : -180, y : -165}
    },
    ZAMBO_STATE_04 : {
        id : 30,
        background : chakraStatesResources.BG_STATE_10.key,
        uniqueElement : zamboSeedStatesResources.ZAMBO_STATE_04.key,
        totalElements : 4,
        // tool : [toolsInformation.SPRAY_TOOL],
        duration : 10,
        action : 'none',
        scale : {x : 0.3, y : 0.3},
        gridConfig : { width : 1, height : 4, cellWidth : 40, cellHeight : 45, x : -160, y : -165}
    },
}

export const mashuaSeedStatesInfo = {
    MASHUA_STATE_01 : {
        id : 31,
        background : chakraStatesResources.BG_STATE_07.key,
        uniqueElement : mashuaSeedStatesResources.MASHUA_STATE_01.key,
        totalElements : 8,
        tool : [toolsInformation.SCISSORS_TOOL],
        duration : 30,
        action : 'destroy',
        scale : {x : 0.2, y : 0.2},
        gridConfig : { width : 2, height : 4, cellWidth : 30, cellHeight : 40, x : -165, y : -210}
    },
    MASHUA_STATE_02 : {
        id : 32,
        background : chakraStatesResources.BG_STATE_08.key,
        uniqueElement : mashuaSeedStatesResources.MASHUA_STATE_02.key,
        totalElements : 8,
        tool : [toolsInformation.SHOVEL_TOOL],
        duration : 45,
        action : 'destroy',
        scale : {x : 0.2, y : 0.2},
        gridConfig : { width : 2, height : 4, cellWidth : 30, cellHeight : 30, x : -248, y : -275}
    },
    MASHUA_STATE_03 : {
        id : 33,
        background : chakraStatesResources.BG_STATE_09.key,
        uniqueElement : mashuaSeedStatesResources.MASHUA_STATE_03.key,
        totalElements : 8,
        tool : [toolsInformation.HOE_TOOL],
        duration : 60,
        action : 'destroy',
        scale : {x : 0.2, y : 0.2},
        gridConfig : { width : 2, height : 4, cellWidth : 30, cellHeight : 18, x : -400, y : -385}
    },
    MASHUA_STATE_04 : {
        id : 34,
        background : chakraStatesResources.BG_STATE_10.key,
        uniqueElement : mashuaSeedStatesResources.MASHUA_STATE_04.key,
        totalElements : 8,
        // tool : [toolsInformation.SPRAY_TOOL],
        duration : 120,
        action : 'none',
        scale : {x : 0.2, y : 0.2},
        gridConfig : { width : 2, height : 4, cellWidth : 30, cellHeight : 18, x : -395, y : -382}
    },
}

export const mellocoSeedStatesInfo = {
    MELLOCO_STATE_01 : {
        id : 35,
        background : chakraStatesResources.BG_STATE_07.key,
        uniqueElement : mellocoSeedStatesResources.MELLOCO_STATE_01.key,
        totalElements : 8,
        tool : [toolsInformation.SCISSORS_TOOL],
        duration : 30,
        action : 'destroy',
        scale : {x : 0.2, y : 0.2},
        gridConfig : { width : 2, height : 4, cellWidth : 40, cellHeight : 40, x : -125, y : -220}
    },
    MELLOCO_STATE_02 : {
        id : 36,
        background : chakraStatesResources.BG_STATE_08.key,
        uniqueElement : mellocoSeedStatesResources.MELLOCO_STATE_02.key,
        totalElements : 8,
        tool : [toolsInformation.SHOVEL_TOOL],
        duration : 60,
        action : 'destroy',
        scale : {x : 0.2, y : 0.2},
        gridConfig : { width : 2, height : 4, cellWidth : 40, cellHeight : 30, x : -220, y : -300}
    },
    MELLOCO_STATE_03 : {
        id : 37,
        background : chakraStatesResources.BG_STATE_09.key,
        uniqueElement : mellocoSeedStatesResources.MELLOCO_STATE_03.key,
        totalElements : 120,
        tool : [toolsInformation.HOE_TOOL],
        duration : 120,
        action : 'destroy',
        scale : {x : 0.2, y : 0.2},
        gridConfig : { width : 2, height : 4, cellWidth : 40, cellHeight : 22, x : -250, y : -385}
    },
    MELLOCO_STATE_04 : {
        id : 38,
        background : chakraStatesResources.BG_STATE_10.key,
        uniqueElement : mellocoSeedStatesResources.MELLOCO_STATE_04.key,
        totalElements : 8,
        // tool : [toolsInformation.SPRAY_TOOL],
        duration : 10,
        action : 'none',
        scale : {x : 0.2, y : 0.2},
        gridConfig : { width : 2, height : 4, cellWidth : 42, cellHeight : 22, x : -250, y : -385}
    },
}

export const jicamaSeedStatesInfo = {
    JICAMA_STATE_01 : {
        id : 39,
        background : chakraStatesResources.BG_STATE_07.key,
        uniqueElement : jicamaSeedStatesResources.JICAMA_STATE_01.key,
        totalElements : 8,
        tool : [toolsInformation.SCISSORS_TOOL],
        duration : 15,
        action : 'destroy',
        scale : {x : 0.2, y : 0.2},
        gridConfig : { width : 2, height : 4, cellWidth : 40, cellHeight : 40, x : -155, y : -220}
    },
    JICAMA_STATE_02 : {
        id : 40,
        background : chakraStatesResources.BG_STATE_08.key,
        uniqueElement : jicamaSeedStatesResources.JICAMA_STATE_02.key,
        totalElements : 8,
        tool : [toolsInformation.SHOVEL_TOOL],
        duration : 30,
        action : 'destroy',
        scale : {x : 0.2, y : 0.2},
        gridConfig : { width : 2, height : 4, cellWidth : 40, cellHeight : 30, x : -265, y : -255}
    },
    JICAMA_STATE_03 : {
        id : 41,
        background : chakraStatesResources.BG_STATE_09.key,
        uniqueElement : jicamaSeedStatesResources.JICAMA_STATE_03.key,
        totalElements : 8,
        tool : [toolsInformation.HOE_TOOL],
        duration : 60,
        action : 'destroy',
        scale : {x : 0.2, y : 0.2},
        gridConfig : { width : 2, height : 4, cellWidth : 40, cellHeight : 22, x : -250, y : -380}
    },
    JICAMA_STATE_04 : {
        id : 42,
        background : chakraStatesResources.BG_STATE_10.key,
        uniqueElement : jicamaSeedStatesResources.JICAMA_STATE_04.key,
        totalElements : 8,
        duration : 10,
        action : 'none',
        scale : {x : 0.2, y : 0.2},
        gridConfig : { width : 2, height : 4, cellWidth : 42, cellHeight : 22, x : -250, y : -380}
    },
}

export const camoteSeedStatesInfo = {
    CAMOTE_STATE_01 : {
        id : 43,
        background : chakraStatesResources.BG_STATE_07.key,
        uniqueElement : camoteSeedStatesResources.CAMOTE_STATE_01.key,
        totalElements : 8,
        tool : [toolsInformation.SCISSORS_TOOL],
        duration : 10,
        action : 'destroy',
        scale : {x : 0.2, y : 0.2},
        gridConfig : { width : 2, height : 4, cellWidth : 45, cellHeight : 45, x : -120, y : -200}
    },
    CAMOTE_STATE_02 : {
        id : 44,
        background : chakraStatesResources.BG_STATE_08.key,
        uniqueElement : camoteSeedStatesResources.CAMOTE_STATE_02.key,
        totalElements : 8,
        tool : [toolsInformation.SHOVEL_TOOL],
        duration : 20,
        action : 'destroy',
        scale : {x : 0.2, y : 0.2},
        gridConfig : { width : 2, height : 4, cellWidth : 45, cellHeight : 30, x : -155, y : -220}
    },
    CAMOTE_STATE_03 : {
        id : 45,
        background : chakraStatesResources.BG_STATE_09.key,
        uniqueElement : camoteSeedStatesResources.CAMOTE_STATE_03.key,
        totalElements : 8,
        tool : [toolsInformation.HOE_TOOL],
        duration : 40,
        action : 'destroy',
        scale : {x : 0.2, y : 0.2},
        gridConfig : { width : 2, height : 4, cellWidth : 40, cellHeight : 21, x : -295, y : -377}
    },
    CAMOTE_STATE_04 : {
        id : 46,
        background : chakraStatesResources.BG_STATE_10.key,
        uniqueElement : camoteSeedStatesResources.CAMOTE_STATE_04.key,
        totalElements : 8,
        duration : 10,
        action : 'none',
        scale : {x : 0.2, y : 0.2},
        gridConfig : { width : 2, height : 4, cellWidth : 40, cellHeight : 21, x : -295, y : -377}
    },
}

export const arracachaSeedStatesInfo = {
    ARRACACHA_STATE_01 : {
        id : 47,
        background : chakraStatesResources.BG_STATE_07.key,
        uniqueElement : arracachaSeedStatesResources.ARRACACHA_STATE_01.key,
        totalElements : 8,
        tool : [toolsInformation.SCISSORS_TOOL],
        duration : 10,
        action : 'destroy',
        scale : {x : 0.2, y : 0.2},
        gridConfig : { width : 2, height : 4, cellWidth : 45, cellHeight : 45, x : -145, y : -210}
    },
    ARRACACHA_STATE_02 : {
        id : 48,
        background : chakraStatesResources.BG_STATE_08.key,
        uniqueElement : arracachaSeedStatesResources.ARRACACHA_STATE_02.key,
        totalElements : 8,
        tool : [toolsInformation.SHOVEL_TOOL],
        duration : 10,
        action : 'destroy',
        scale : {x : 0.2, y : 0.2},
        gridConfig : { width : 2, height : 4, cellWidth : 45, cellHeight : 28, x : -265, y : -380}
    },
    ARRACACHA_STATE_03 : {
        id : 49,
        background : chakraStatesResources.BG_STATE_09.key,
        uniqueElement : arracachaSeedStatesResources.ARRACACHA_STATE_03.key,
        totalElements : 8,
        tool : [toolsInformation.HOE_TOOL],
        duration : 10,
        action : 'destroy',
        scale : {x : 0.2, y : 0.2},
        gridConfig : { width : 2, height : 4, cellWidth : 40, cellHeight : 21, x : -355, y : -390}
    },
    ARRACACHA_STATE_04 : {
        id : 50,
        background : chakraStatesResources.BG_STATE_10.key,
        uniqueElement : arracachaSeedStatesResources.ARRACACHA_STATE_04.key,
        totalElements : 8,
        tool : [toolsInformation.SPRAY_TOOL],
        duration : 10,
        action : 'destroy',
        scale : {x : 0.2, y : 0.2},
        gridConfig : { width : 2, height : 4, cellWidth : 40, cellHeight : 19, x : -360, y : -420}
    },
}

export const zapalloSeedStatesInfo = {
    ZAPALLO_STATE_01 : {
        id : 51,
        background : chakraStatesResources.BG_STATE_07.key,
        uniqueElement : zapalloSeedStatesResources.ZAPALLO_STATE_01.key,
        totalElements : 8,
        tool : [toolsInformation.SCISSORS_TOOL],
        duration : 10,
        action : 'destroy',
        scale : {x : 0.2, y : 0.2},
        gridConfig : { width : 2, height : 4, cellWidth : 45, cellHeight : 45, x : -197, y : -235}
    },
    ZAPALLO_STATE_02 : {
        id : 52,
        background : chakraStatesResources.BG_STATE_08.key,
        uniqueElement : zapalloSeedStatesResources.ZAPALLO_STATE_02.key,
        totalElements : 8,
        tool : [toolsInformation.SHOVEL_TOOL],
        duration : 10,
        action : 'destroy',
        scale : {x : 0.2, y : 0.2},
        gridConfig : { width : 2, height : 4, cellWidth : 45, cellHeight : 28, x : -210, y : -325}
    },
    ZAPALLO_STATE_03 : {
        id : 53,
        background : chakraStatesResources.BG_STATE_09.key,
        uniqueElement : zapalloSeedStatesResources.ZAPALLO_STATE_03.key,
        totalElements : 8,
        tool : [toolsInformation.HOE_TOOL],
        duration : 10,
        action : 'destroy',
        scale : {x : 0.2, y : 0.2},
        gridConfig : { width : 2, height : 4, cellWidth : 40, cellHeight : 14, x : -368, y : -455}
    },
    ZAPALLO_STATE_04 : {
        id : 54,
        background : chakraStatesResources.BG_STATE_10.key,
        uniqueElement : zapalloSeedStatesResources.ZAPALLO_STATE_04.key,
        totalElements : 8,
        // tool : [toolsInformation.SPRAY_TOOL],
        duration : 10,
        action : 'none',
        scale : {x : 0.2, y : 0.2},
        gridConfig : { width : 2, height : 4, cellWidth : 40, cellHeight : 14, x : -387, y : -470}
    },
}

export const zapalloStatesList = [
    ...Object.values(zapalloSeedStatesInfo)
]

export const arracachaStatesList = [
    ...Object.values(arracachaSeedStatesInfo)
]

export const camoteStatesList = [
    ...Object.values(camoteSeedStatesInfo)
]

export const jicamaStatesList = [
    ...Object.values(jicamaSeedStatesInfo)
]

export const mellocoStatesList = [
    ...Object.values(mellocoSeedStatesInfo)
]

export const mashuaStatesList = [
    ...Object.values(mashuaSeedStatesInfo)
]

export const zamboStatesList = [
    ...Object.values(zamboSeedStatesInfo)
]

export const lupineStatesList = [
    ...Object.values(lupineSeedStatesInfo)
]

export const beanStatesList = [
    ...Object.values(beanSeedStatesInfo)
]

export const ocaStatesList = [
    ...Object.values(ocaSeedStatesInfo)
]

export const potatoStatesList = [
    ...Object.values(potatoSeedStatesInfo)
]

export const cornStatesList = [
    ...Object.values(cornSeedStatesInfo)
]

export const statesList = [
    ...Object.values(chakraStates)
]