import apiClient from "../configService";


export function generateChakraRecords(idPlayer){
    return apiClient.post('/chakra_records/player',idPlayer);
}

export function getPlayerChakraRecords(idPlayer){
    return apiClient.get('/chakra_records/player?playerId='+idPlayer);
}

export function updatePlayerChakraRecords(idChakra,params){
    return apiClient.put('/chakra_records/'+idChakra,params);
}

