import { sisayPachaResources, pawkarRaymiResources, allpaCruzResources, intiRaymiResources, pallayRaymiResources, sawariyResources, kullaRaymiResources, riksichiyResources, velanakuyResources, kapakRaymiResources, yumbadaResources, llulluMuruRaymiResources } from "./resources_path/festivities/festivitiesResources";
import { chuklluKamllaResources, milichakiResources, mishkiZamboResources, uchukutaResources, mutiLuciResources, champusResources, makiMachikaResources, mishkiKamllaResources, yamurAsuaResources, katzuKamllaResources, yanaApiResources, churuApiResources } from "./resources_path/food/foodResources";
import { secretsResourcesList } from "./resources_path/secrets/secretsResources";
import { familiarSignsResources, deathSignsResources, badLuckSignsResources, goodLuckSignsResources, weatherSignsResources } from "./resources_path/signs/signsResources";
import { festivityPricesResources, foodPricesResources, signsSecretsPriceResources, dialectsPriceResources } from "./resources_path/prices/pricesResources";

export const introAssets = {
  BACKGROUND: {
    key: "background",
    path: "./funcaracAssets/intro/landscape.jpg"
  },

  GAME_TITLE: {
    key: "gameTitle",
    path: "./funcaracAssets/intro/gameTitle.png"
  },
  PLAY_BUTTON: {
    key: "playButton",
    path: "./funcaracAssets/intro/playButton.png"
  },
  PLAY_BUTTON_PRESSED: {
    key: "playButtonPressed",
    path: "./funcaracAssets/intro/playButtonPressed.png"
  },

}

export const welcomeSceneResources = {
  GAME_LOGO : {
    key: "gameLogo",
    path: "./funcaracAssets/intro/logo/logo_juego.png"
  },
  GAME_CHARACTER: {
    key: "gameCharacter",
    path: "./funcaracAssets/gameAssistant/v1.0/mama-dulu.png"
  },

  BUTTON_FAST_GAME : {
    key: "buttonFastGame",
    path: "./funcaracAssets/intro/botones/normal/boton_juego_rapido.png"
  },
  BUTTON_SIGN_UP : {
    key: "buttonSignUp",
    path: "./funcaracAssets/intro/botones/normal/boton_registrate.png"
  },
  BUTTON_LOG_IN : {
    key: "buttonLogIn",
    path: "./funcaracAssets/intro/botones/normal/boton_iniciar_sesion.png"
  },
  BUTTON_INSTRUCTIONS : {
    key: "buttonInstructions",
    path: "./funcaracAssets/intro/botones/normal/boton_instrucciones.png"
  },
  BUTTON_CREDITS : {
    key: "buttonCredits",
    path: "./funcaracAssets/intro/botones/normal/boton_creditos.png"
  },
  BUTTON_RETURN : {
    key: "buttonReturn",
    path: "./funcaracAssets/intro/botones/normal/boton_regresar.png"
  },

  BUTTON_PLAY_GAME : {
    key: "buttonPlayGame",
    path: "./funcaracAssets/intro/botones/normal/boton_jugar.png"
  },

  BUTTON_EXIT : {
    key: "buttonExit",
    path: "./funcaracAssets/intro/botones/normal/boton_salir.png"
  },

  PRESSED_BUTTON_FAST_GAME : {
    key: "pressedButtonFastGame",
    path: "./funcaracAssets/intro/botones/presionados/boton_juego_rapido.png"
  },
  PRESSED_BUTTON_SIGN_UP : {
    key: "pressedButtonSignUp",
    path: "./funcaracAssets/intro/botones/presionados/boton_registrate.png"
  },
  PRESSED_BUTTON_LOG_IN : {
    key: "pressedButtonLogIn",
    path: "./funcaracAssets/intro/botones/presionados/boton_iniciar_sesion.png"
  },
  PRESSED_BUTTON_INSTRUCTIONS : {
    key: "pressedButtonInstructions",
    path: "./funcaracAssets/intro/botones/presionados/boton_instrucciones.png"
  },
  PRESSED_BUTTON_CREDITS : {
    key: "pressedButtonCredits",
    path: "./funcaracAssets/intro/botones/presionados/boton_creditos.png"
  },
  PRESSED_BUTTON_RETURN : {
    key: "pressedButtonReturn",
    path: "./funcaracAssets/intro/botones/presionados/boton_regresar.png"
  },
  PRESSED_BUTTON_PLAY_GAME : {
    key: "pressedButtonPlayGame",
    path: "./funcaracAssets/intro/botones/presionados/boton_jugar.png"
  },
  PRESSED_BUTTON_EXIT : {
    key: "pressedButtonExit",
    path: "./funcaracAssets/intro/botones/presionados/boton_salir.png"
  },

  ICON_BUTTON_FAST_GAME : {
    key: "iconButtonFastGame",
    path: "./funcaracAssets/intro/iconos/normal/icono_juego_rapido.png"
  },
  ICON_BUTTON_SIGN_UP : {
    key: "iconButtonSignUp",
    path: "./funcaracAssets/intro/iconos/normal/icono_registrate.png"
  },
  ICON_BUTTON_LOG_IN : {
    key: "iconButtonLogIn",
    path: "./funcaracAssets/intro/iconos/normal/icono_iniciar_sesion.png"
  },
  ICON_BUTTON_INSTRUCTIONS : {
    key: "iconButtonInstructions",
    path: "./funcaracAssets/intro/iconos/normal/icono_instrucciones.png"
  },
  ICON_BUTTON_CREDITS : {
    key: "iconButtonCredits",
    path: "./funcaracAssets/intro/iconos/normal/icono_creditos.png"
  },
  ICON_BUTTON_RETURN : {
    key: "iconButtonReturn",
    path: "./funcaracAssets/intro/iconos/normal/icono_regresar.png"
  },
  ICON_BUTTON_PLAY_GAME : {
    key: "iconButtonPlayGame",
    path: "./funcaracAssets/intro/iconos/normal/icono_jugar.png"
  },
  ICON_BUTTON_EXIT : {
    key: "iconButtonExit",
    path: "./funcaracAssets/intro/iconos/normal/icono_salir.png"
  },

  PRESSED_ICON_BUTTON_FAST_GAME : {
    key: "pressedIconButtonFastGame",
    path: "./funcaracAssets/intro/iconos/presionados/icono_juego_rapido.png"
  },
  PRESSED_ICON_BUTTON_SIGN_UP : {
    key: "pressedIconButtonSignUp",
    path: "./funcaracAssets/intro/iconos/presionados/icono_registrate.png"
  },
  PRESSED_ICON_BUTTON_LOG_IN : {
    key: "pressedIconButtonLogIn",
    path: "./funcaracAssets/intro/iconos/presionados/icono_iniciar_sesion.png"
  },
  PRESSED_ICON_BUTTON_INSTRUCTIONS : {
    key: "pressedIconButtonInstructions",
    path: "./funcaracAssets/intro/iconos/presionados/icono_instrucciones.png"
  },
  PRESSED_ICON_BUTTON_CREDITS : {
    key: "pressedIconButtonCredits",
    path: "./funcaracAssets/intro/iconos/presionados/icono_creditos.png"
  },
  PRESSED_ICON_BUTTON_RETURN : {
    key: "pressedIconButtonReturn",
    path: "./funcaracAssets/intro/iconos/presionados/icono_regresar.png"
  },
  PRESSED_ICON_BUTTON_PLAY_GAME : {
    key: "pressedIconButtonPlayGame",
    path: "./funcaracAssets/intro/iconos/presionados/icono_jugar.png"
  },
  PRESSED_ICON_BUTTON_EXIT : {
    key: "pressedIconButtonExit",
    path: "./funcaracAssets/intro/iconos/presionados/icono_salir.png"
  },

  //TITLES
  TITLE_CREDITS : {
    key: "titleCredits",
    path: "./funcaracAssets/intro/titulos/titulo_creditos.png"
  }

}

export const guidebookResources = {
  //main-ui guide
  GUIDE_ARROW : {
    key : "guideSceneArrow",
    path : "./funcaracAssets/intro/guidebook/mainUI/flecha-guia.png"
  },
  GUIDE_TITLE : {
    key : "guideTitle",
    path : "./funcaracAssets/intro/guidebook/mainUI/titulo-guia.png"
  },
  GUIDE_BOARD : {
    key : "guideBoard",
    path : "./funcaracAssets/intro/guidebook/mainUI/interfaz-guia.png"
  },
  GUIDE_PAGE_WINDOW : {
    key : "guidePageWindow",
    path : "./funcaracAssets/intro/guidebook/mainUI/visor-pagina.png"
  },
}

export const guideBookPagesResources = {
  GUIDE_PAGE_01 : {
    key : "guidePage01",
    path : "./funcaracAssets/intro/guidebook/manual/pagina01.jpg"
  },
  GUIDE_PAGE_02 : {
    key : "guidePage02",
    path : "./funcaracAssets/intro/guidebook/manual/pagina02.jpg"
  },
  GUIDE_PAGE_03 : {
    key : "guidePage03",
    path : "./funcaracAssets/intro/guidebook/manual/pagina03.jpg"
  },
  GUIDE_PAGE_04 : {
    key : "guidePage04",
    path : "./funcaracAssets/intro/guidebook/manual/pagina04.jpg"
  },
  GUIDE_PAGE_05 : {
    key : "guidePage05",
    path : "./funcaracAssets/intro/guidebook/manual/pagina05.jpg"
  },
  GUIDE_PAGE_06 : {
    key : "guidePage06",
    path : "./funcaracAssets/intro/guidebook/manual/pagina06.jpg"
  },
  GUIDE_PAGE_07 : {
    key : "guidePage07",
    path : "./funcaracAssets/intro/guidebook/manual/pagina07.jpg"
  },
  GUIDE_PAGE_08 : {
    key : "guidePage08",
    path : "./funcaracAssets/intro/guidebook/manual/pagina08.jpg"
  },
  GUIDE_PAGE_09 : {
    key : "guidePage09",
    path : "./funcaracAssets/intro/guidebook/manual/pagina09.jpg"
  },
  GUIDE_PAGE_10 : {
    key : "guidePage10",
    path : "./funcaracAssets/intro/guidebook/manual/pagina10.jpg"
  },
  GUIDE_PAGE_11 : {
    key : "guidePage11",
    path : "./funcaracAssets/intro/guidebook/manual/pagina11.jpg"
  },
  GUIDE_PAGE_12 : {
    key : "guidePage12",
    path : "./funcaracAssets/intro/guidebook/manual/pagina12.jpg"
  },
  GUIDE_PAGE_13 : {
    key : "guidePage13",
    path : "./funcaracAssets/intro/guidebook/manual/pagina13.jpg"
  },
  GUIDE_PAGE_14 : {
    key : "guidePage14",
    path : "./funcaracAssets/intro/guidebook/manual/pagina14.jpg"
  },
  
}


export const assistantMenuResources = {
  ASSISTANT_MENU_BOARD : {
    key: "assistantMenuBoard",
    path: "./funcaracAssets/mainUI/assistant_menu/resources/tablero/tablero_menu_asistente.png"
  },

  //ESTANDAR OPTION BUTTONS
  NEUTRAL_AUDIO_BTN : {
    key: "neutralAudioBtn",
    path: "./funcaracAssets/mainUI/assistant_menu/resources/botones/estandar/boton_audio.png"
  },
  NEUTRAL_HINT_BTN : {
    key: "neutralHintBtn",
    path: "./funcaracAssets/mainUI/assistant_menu/resources/botones/estandar/boton_ayuda.png"
  },
  NEUTRAL_GUIDE_BTN : {
    key: "neutralGuideBtn",
    path: "./funcaracAssets/mainUI/assistant_menu/resources/botones/estandar/boton_guia.png"
  },
  NEUTRAL_EXIT_BTN : {
    key: "neutralExitBtn",
    path: "./funcaracAssets/mainUI/assistant_menu/resources/botones/estandar/boton_salir.png"
  },

  //PRESSED OPTION BUTTONS
  PRESSED_AUDIO_BTN : {
    key: "pressedAudioBtn",
    path: "./funcaracAssets/mainUI/assistant_menu/resources/botones/presionados/boton_p_audio.png"
  },
  PRESSED_HINT_BTN : {
    key: "pressedHintBtn",
    path: "./funcaracAssets/mainUI/assistant_menu/resources/botones/presionados/boton_p_ayuda.png"
  },
  PRESSED_GUIDE_BTN : {
    key: "pressedGuideBtn",
    path: "./funcaracAssets/mainUI/assistant_menu/resources/botones/presionados/boton_p_guia.png"
  },
  PRESSED_EXIT_BTN : {
    key: "pressedExitBtn",
    path: "./funcaracAssets/mainUI/assistant_menu/resources/botones/presionados/boton_p_salir.png"
  },

  //BUTTON TITLES
  BTN_TITLE_AUDIO : {
    key: "btnTitleAudio",
    path: "./funcaracAssets/mainUI/assistant_menu/resources/titulos/botones/audio.png"
  },
  BTN_TITLE_HINT : {
    key: "btnTitleHint",
    path: "./funcaracAssets/mainUI/assistant_menu/resources/titulos/botones/ayuda.png"
  },
  BTN_TITLE_GUIDE : {
    key: "btnTitleGuide",
    path: "./funcaracAssets/mainUI/assistant_menu/resources/titulos/botones/guia.png"
  },
  BTN_TITLE_EXIT : {
    key: "btnTitleExit",
    path: "./funcaracAssets/mainUI/assistant_menu/resources/titulos/botones/salir.png"
  },

  //BRIGHTENING EFFECT
  BRIGHTENING_EFFECT : {
    key : "brigtheningEffect",
    path : "./funcaracAssets/mainUI/assistant_menu/resources/efecto/sombreado-boton.png"
  }
}

export const dialogBubbleResources = {
  BTN_CONTINUE : {
    key: "btnContinue",
    path: "./funcaracAssets/mainUI/assistant_menu/resources/cuadro_dialogo/normales/boton_continuar.png"
  },
  BTN_OMIT : {
    key: "btnOmit",
    path: "./funcaracAssets/mainUI/assistant_menu/resources/cuadro_dialogo/normales/boton_omitir.png"
  },
  PRESSED_BTN_CONTINUE : {
    key: "pressedBtnContinue",
    path: "./funcaracAssets/mainUI/assistant_menu/resources/cuadro_dialogo/presionados/boton_p_continuar.png"
  },
  PRESSED_BTN_OMIT : {
    key: "pressedBtnOmit",
    path: "./funcaracAssets/mainUI/assistant_menu/resources/cuadro_dialogo/presionados/boton_p_omitir.png"
  },

}


export const mainUIResources = {
  BOX_ASSISTANT_MENU: {
    key: "boxAssistantMenu",
    path: "./funcaracAssets/mainUI/assistant_menu/boxAssistantMenu.png"
  },
  BODY_BTN_ASSISTANT_MENU: {
    key: "bodyBtnAssistantMenu",
    path: "./funcaracAssets/mainUI/assistant_menu/bodyAssistantMenuBtn.png"
  },

  BODY_ICON_BTN_ASSISANT_MENU: {
    key: "iconBodyAssistantMenuBtn",
    path: "./funcaracAssets/mainUI/assistant_menu/iconBodyAssistantMenuBtn.png"
  },

  ICON_ARROW: {
    key: "iconArrow",
    path: "./funcaracAssets/mainUI/assistant_menu/iconArrow.png"
  },
  ICON_GUIDE: {
    key: "iconGuide",
    path: "./funcaracAssets/mainUI/assistant_menu/iconGuide.png"
  },
  ICON_HINT: {
    key: "iconHint",
    path: "./funcaracAssets/mainUI/assistant_menu/iconHint.png"
  },
  ICON_MUTE: {
    key: "iconMute",
    path: "./funcaracAssets/mainUI/assistant_menu/iconMute.png"
  },
  ICON_OMIT: {
    key: "iconOmit",
    path: "./funcaracAssets/mainUI/assistant_menu/iconOmit.png"
  },
  ICON_SOUND: {
    key: "iconSound",
    path: "./funcaracAssets/mainUI/assistant_menu/iconSound.png"
  },
  ICON_OK: {
    key: "iconOK",
    path: "./funcaracAssets/mainUI/assistant_menu/iconOK.png"
  },
  LABEL_ASSISTANT_MENU_BTN: {
    key: "labelAssistantMenuBtn",
    path: "./funcaracAssets/mainUI/assistant_menu/labelAssistantMenuBtnUI.png"
  },
  RIBBON_ASSISTANT: {
    key: "ribbonAssistant",
    path: "./funcaracAssets/mainUI/assistant_menu/ribbonAssistant.png"
  },
  WINDOW_ASSISTANT: {
    key: "windowAssistant",
    path: "./funcaracAssets/mainUI/assistant_menu/windowAssistant.png"
  },
  OK_BUTTON : {
    key:"okButton",
    path:'./funcaracAssets/mainUI/assistant_menu/boton_aceptar.png'
  },
  BODY_BTN_MAIN_MENU: {
    key: "bodyBtnMainMenu",
    path: "./funcaracAssets/mainUI/main_menu/bodyBtnMainMenu.png"
  },
  BTN_WINDOW_MAIN_MENU: {
    key: "btnWindowMainMenu",
    path: "./funcaracAssets/mainUI/main_menu/btnWindowMainMenu.png"
  },
  CARAC: {
    key: "carac",
    path: "./funcaracAssets/mainUI/main_menu/carac.png"
  },
  CHAKRA: {
    key: "chakra",
    path: "./funcaracAssets/mainUI/main_menu/chakra.png"
  },
  TALES: {
    key: "tales",
    path: "./funcaracAssets/mainUI/main_menu/tales.png"
  },
  LABEL_MAIN_MENU_BTN: {
    key: "labelMainMenuBtn",
    path: "./funcaracAssets/mainUI/main_menu/labelMainMenuBtn.png"
  },
  WINDOW_MAIN_MENU: {
    key: "windowMainMenu",
    path: "./funcaracAssets/mainUI/main_menu/windowMainMenu.png"
  },
  RIBBON_SCENE: {
    key: "ribbonScene",
    path: "./funcaracAssets/mainUI/scene/ribbonScene.png"
  },
  WINDOW_SCENE: {
    key: "windowScene",
    path: "./funcaracAssets/mainUI/scene/windowScene.png"
  },
  GAME_ASSISTANT: {
    key: "gameAssistant",
    // path: "./funcaracAssets/mainUI/assistant_menu/gameAssistant.png"
    path: "./funcaracAssets/gameAssistant/v1.0/mama-dulu.png"
  },
  WINDOW_MODAL: {
    key: "windowModal",
    path: "./funcaracAssets/mainUI/modal/windowModal.png"
  },
  RIBBON_CONGRATULATIONS: {
    key: "ribbonCongratulations",
    path: "./funcaracAssets/mainUI/modal/congratulations.png"
  },
  RIBBON_WINDOW_MODAL: {
    key: "ribbonWindowModal",
    path: "./funcaracAssets/mainUI/modal/ribbonWindowModal.png"
  },
  CONTINUE_BUTTON : {
    key:"continueButton",
    path:'./funcaracAssets/mainUI/modal/continueButton.png'
  },
  CONTINUE_BUTTON_SELECTED : {
    key:"continueButtonSelected",
    path:'./funcaracAssets/mainUI/modal/continueButtonSelected.png'
  },
  COMPOST_REWARD : {
    key:"compostReward",
    path:'./funcaracAssets/mainUI/rewards/compostReward.png'
  },
  HOE_REWARD : {
    key:"hoeReward",
    path:'./funcaracAssets/mainUI/rewards/hoeReward.png'
  },
  SCISSORS_REWARD : {
    key:"scissorsReward",
    path:'./funcaracAssets/mainUI/rewards/scissorsReward.png'
  },
  SHOVEL_REWARD : {
    key:"shovelReward",
    path:'./funcaracAssets/mainUI/rewards/shovelReward.png'
  },
  SPRAY_REWARD : {
    key:"sprayReward",
    path:'./funcaracAssets/mainUI/rewards/sprayReward.png'
  },
  WATER_REWARD : {
    key:"waterReward",
    path:'./funcaracAssets/mainUI/rewards/waterReward.png'
  },
  MULTIPLIER : {
    key:"multiplier",
    path:'./funcaracAssets/mainUI/rewards/multiplier.png'
  },
  NUM_0 : {
    key:"num0",
    path:'./funcaracAssets/mainUI/numbers/num0.png'
  },
  NUM_1 : {
    key:"num1",
    path:'./funcaracAssets/mainUI/numbers/num1.png'
  },
  NUM_2 : {
    key:"num2",
    path:'./funcaracAssets/mainUI/numbers/num2.png'
  },
  NUM_3 : {
    key:"num3",
    path:'./funcaracAssets/mainUI/numbers/num3.png'
  },
  NUM_4 : {
    key:"num4",
    path:'./funcaracAssets/mainUI/numbers/num4.png'
  },
  NUM_5 : {
    key:"num5",
    path:'./funcaracAssets/mainUI/numbers/num5.png'
  },
  NUM_6 : {
    key:"num6",
    path:'./funcaracAssets/mainUI/numbers/num6.png'
  },
  NUM_7 : {
    key:"num7",
    path:'./funcaracAssets/mainUI/numbers/num7.png'
  },
  NUM_8 : {
    key:"num8",
    path:'./funcaracAssets/mainUI/numbers/num8.png'
  },
  NUM_9 : {
    key:"num9",
    path:'./funcaracAssets/mainUI/numbers/num9.png'
  },
  STAR: {
    key:"star",
    path:'./funcaracAssets/mainUI/other/star.png'
  },
  HAND: {
    key:"hand",
    path:'./funcaracAssets/mainUI/other/manita.png'
  },
  REWARD : {
    key:"rewardMessage",
    path:'./funcaracAssets/mainUI/other/rewardMessage.png'
  },
  GUIDE_ARROW : {
    key : "guideArrow",
    path :  './funcaracAssets/mainUI/other/flecha.png'
  }


};

export const caracResources = {
  ARROW: {
    key: "arrow",
    path: "./funcaracAssets/carac/arrow.png"
  },
  BACKGROUND_BLUE: {
    key: "backgroundBlue",
    path: "./funcaracAssets/carac/backgroundBlue.png"
  },
  BACKGROUND_GREEN: {
    key: "backgroundGreen",
    path: "./funcaracAssets/carac/backgroundGreen.png"
  },
  BACKGROUND_RED: {
    key: "backgroundRed",
    path: "./funcaracAssets/carac/backgroundRed.png"
  },
  BACKGROUND_YELLOW: {
    key: "backgroundYellow",
    path: "./funcaracAssets/carac/backgroundYellow.png"
  },
  BOARD_BOX: {
    key: "boardBox",
    path: "./funcaracAssets/carac/boardBox.png"
  },
  BOARD_PLAQUE: {
    key: "boardPlaque",
    path: "./funcaracAssets/carac/boardPlaque.png"
  },
  PIECE_BLUE: {
    key: "pieceBlue",
    path: "./funcaracAssets/carac/pieceBlue.png"
  },
  PIECE_GREEN: {
    key: "pieceGreen",
    path: "./funcaracAssets/carac/pieceGreen.png"
  },
  PIECE_LABEL: {
    key: "pieceLabel",
    path: "./funcaracAssets/carac/pieceLabel.png"
  },
  PIECE_RED: {
    key: "pieceRed",
    path: "./funcaracAssets/carac/pieceRed.png"
  },
  PIECES_BOX: {
    key: "piecesBox",
    path: "./funcaracAssets/carac/piecesBox.png"
  },
  PIECES_PLAQUE: {
    key: "piecesPlaque",
    path: "./funcaracAssets/carac/piecesPlaque.png"
  },
  PIECE_YELLOW: {
    key: "pieceYellow",
    path: "./funcaracAssets/carac/pieceYellow.png"
  },
  SLOT_BODY: {
    key: "slotBody",
    path: "./funcaracAssets/carac/slotBody.png"
  }
};

export const caracMonthsResources = {
  JANUARY: {
    key: "january",
    path: "./funcaracAssets/carac/months/month01.png"
  },
  FEBRUARY: {
    key: "february",
    path: "./funcaracAssets/carac/months/month02.png"
  },
  MARCH: {
    key: "march",
    path: "./funcaracAssets/carac/months/month03.png"
  },
  APRIL: {
    key: "april",
    path: "./funcaracAssets/carac/months/month04.png"
  },
  MAY: {
    key: "may",
    path: "./funcaracAssets/carac/months/month05.png"
  },
  JUNE: {
    key: "june",
    path: "./funcaracAssets/carac/months/month06.png"
  },
  JULY: {
    key: "july",
    path: "./funcaracAssets/carac/months/month07.png"
  },
  AUGUST: {
    key: "august",
    path: "./funcaracAssets/carac/months/month08.png"
  },
  SEPTEMBER: {
    key: "september",
    path: "./funcaracAssets/carac/months/month09.png"
  },
  OCTOBER: {
    key: "october",
    path: "./funcaracAssets/carac/months/month10.png"
  },
  NOVEMBER: {
    key: "november",
    path: "./funcaracAssets/carac/months/month11.png"
  },
  DECEMBER: {
    key: "december",
    path: "./funcaracAssets/carac/months/month12.png"
  },
}

export const seedsResources = {
  SEED01: {
    key: "seed01",
    path: "./funcaracAssets/seeds/rewards/seed01.png"
  },
  SEED02: {
    key: "seed02",
    path: "./funcaracAssets/seeds/rewards/seed02.png"
  },
  SEED03: {
    key: "seed03",
    path: "./funcaracAssets/seeds/rewards/seed03.png"
  },
  SEED04: {
    key: "seed04",
    path: "./funcaracAssets/seeds/rewards/seed04.png"
  },
  SEED05: {
    key: "seed05",
    path: "./funcaracAssets/seeds/rewards/seed05.png"
  },
  SEED06: {
    key: "seed06",
    path: "./funcaracAssets/seeds/rewards/seed06.png"
  },
  SEED07: {
    key: "seed07",
    path: "./funcaracAssets/seeds/rewards/seed07.png"
  },
  SEED08: {
    key: "seed08",
    path: "./funcaracAssets/seeds/rewards/seed08.png"
  },
  SEED09: {
    key: "seed09",
    path: "./funcaracAssets/seeds/rewards/seed09.png"
  },
  SEED10: {
    key: "seed10",
    path: "./funcaracAssets/seeds/rewards/seed10.png"
  },
  SEED11: {
    key: "seed11",
    path: "./funcaracAssets/seeds/rewards/seed11.png"
  },
  SEED12: {
    key: "seed12",
    path: "./funcaracAssets/seeds/rewards/seed12.png"
  },

  SEED_ICON_01: {
    key: "seedIcon01",
    path: "./funcaracAssets/seeds/icons/seedIcon01.png"
  },
  SEED_ICON_02: {
    key: "seedIcon02",
    path: "./funcaracAssets/seeds/icons/seedIcon02.png"
  },
  SEED_ICON_03: {
    key: "seedIcon03",
    path: "./funcaracAssets/seeds/icons/seedIcon03.png"
  },
  SEED_ICON_04: {
    key: "seedIcon04",
    path: "./funcaracAssets/seeds/icons/seedIcon04.png"
  },
  SEED_ICON_05: {
    key: "seedIcon05",
    path: "./funcaracAssets/seeds/icons/seedIcon05.png"
  },
  SEED_ICON_06: {
    key: "seedIcon06",
    path: "./funcaracAssets/seeds/icons/seedIcon06.png"
  },
  SEED_ICON_07: {
    key: "seedIcon07",
    path: "./funcaracAssets/seeds/icons/seedIcon07.png"
  },
  SEED_ICON_08: {
    key: "seedIcon08",
    path: "./funcaracAssets/seeds/icons/seedIcon08.png"
  },
  SEED_ICON_09: {
    key: "seedIcon09",
    path: "./funcaracAssets/seeds/icons/seedIcon09.png"
  },
  SEED_ICON_10: {
    key: "seedIcon10",
    path: "./funcaracAssets/seeds/icons/seedIcon10.png"
  },
  SEED_ICON_11: {
    key: "seedIcon11",
    path: "./funcaracAssets/seeds/icons/seedIcon11.png"
  },
  SEED_ICON_12: {
    key: "seedIcon12",
    path: "./funcaracAssets/seeds/icons/seedIcon12.png"
  }
}

export const chakraResources = {
  CHAKRA_BACKGROUND: {
    key: "chakraBackground",
    path: "./funcaracAssets/chakra/anden/chakraBackground.png"
  },
  CHAKRA_PLATFORM: {
    key: "chakraPlatform",
    path: "./funcaracAssets/chakra/anden/platform.png"
  },
  PLATFORM_PLAQUE: {
    key: "plaquePlatform",
    path: "./funcaracAssets/chakra/anden/platformPlaque.png"
  },
  CHAKRA_PANEL_BODY: {
    key: "panelBody",
    path: "./funcaracAssets/chakra/panel/panelBody.png"
  },
  CHAKRA_CHECK_ICON: {
    key: "chakraCheckIcon",
    path: "./funcaracAssets/chakra/panel/panelCheck.png"
  },
  CHAKRA_CLOCK_ICON: {
    key: "chakraClockIcon",
    path: "./funcaracAssets/chakra/panel/panelClock.png"
  },
  CHAKRA_COMPOST_ICON: {
    key: "chakraCompostIcon",
    path: "./funcaracAssets/chakra/panel/panelCompost.png"
  },
  CHAKRA_PLANT_ICON: {
    key: "chakraPlantIcon",
    path: "./funcaracAssets/chakra/panel/panelIcon.png"
  },
  CHAKRA_WATER_ICON: {
    key: "chakraWaterIcon",
    path: "./funcaracAssets/chakra/panel/panelWater.png"
  },
  PANEL_STATUS_BAR: {
    key: "panelStatusBar",
    path: "./funcaracAssets/chakra/panel/panelStatusBar.png"
  },
  SEEDS_BOX: {
    key: "seedsBox",
    path: "./funcaracAssets/chakra/seeds/seedsBox.png"
  },
  SEEDS_BOX_PLAQUE: {
    key: "seedsBoxPlaque",
    path: "./funcaracAssets/chakra/seeds/boxPlaque.png"
  },
  SEED_BUTTON: {
    key: "seedButton",
    path: "./funcaracAssets/chakra/seeds/seedButton.png"
  },
  SEED_BASKET: {
    key: "seedBasket",
    path: "./funcaracAssets/chakra/seeds/seedBasket.png"
  },
  SEED_OK_ICON : {
    key: "seedOkButton",
    path: "./funcaracAssets/chakra/seeds/ok_icon.png"
  },
  TOOLS_BOX: {
    key: "toolsBox",
    path: "./funcaracAssets/chakra/tools/toolsBox.png"
  },
  TOOLS_BOX_PLAQUE: {
    key: "toolsBoxPlaque",
    path: "./funcaracAssets/chakra/tools/plaque.png"
  },
  TOOL_BUTTON: {
    key: "toolButton",
    path: "./funcaracAssets/chakra/tools/toolButton.png"
  },
  TOOL_LABEL: {
    key: "toolLabel",
    path: "./funcaracAssets/chakra/tools/toolLabel.png"
  },
  TOOL_COMPOST: {
    key: "toolCompost",
    path: "./funcaracAssets/chakra/tools/toolCompost.png"
  },
  TOOL_HOE: {
    key: "toolHoe",
    path: "./funcaracAssets/chakra/tools/toolHoe.png"
  },
  TOOL_SCISSORS: {
    key: "toolScissors",
    path: "./funcaracAssets/chakra/tools/toolScissors.png"
  },
  TOOL_SHOVEL: {
    key: "toolShovel",
    path: "./funcaracAssets/chakra/tools/toolShovel.png"
  },
  TOOL_SPRAY: {
    key: "toolSpray",
    path: "./funcaracAssets/chakra/tools/toolSpray.png"
  },
  TOOL_WATER: {
    key: "toolWater",
    path: "./funcaracAssets/chakra/tools/toolWater.png"
  },
  CHAKRA_GRASSED: {
    key: "chakraGrassed",
    path: "./funcaracAssets/chakra/states/chakraGrassed.png"
  },
  CHAKRA_CLEANED: {
    key: "chakraCleaned",
    path: "./funcaracAssets/chakra/states/chakraCleaned.png"
  },
  CHAKRA_LINED: {
    key: "chakraLined",
    path: "./funcaracAssets/chakra/states/chakraLined.png"
  },
  CHAKRA_READY: {
    key: "chakraReady",
    path: "./funcaracAssets/chakra/states/chakraReady.png"
  },
  GRASS: {
    key: "grass",
    path: "./funcaracAssets/chakra/content/grass.png"
  },
};

export const chakraStatesResources = {
  BG_STATE_01: {
    key: "bgState01",
    path: "./funcaracAssets/chakra/states/background/state01.png"
  },
  BG_STATE_02: {
    key: "bgState02",
    path: "./funcaracAssets/chakra/states/background/state02.png"
  },
  BG_STATE_03: {
    key: "bgState03",
    path: "./funcaracAssets/chakra/states/background/state03.png"
  },
  BG_STATE_04: {
    key: "bgState04",
    path: "./funcaracAssets/chakra/states/background/state04.png"
  },
  BG_STATE_05: {
    key: "bgState05",
    path: "./funcaracAssets/chakra/states/background/state05.png"
  },
  BG_STATE_06: {
    key: "bgState06",
    path: "./funcaracAssets/chakra/states/background/state06.png"
  },
  BG_STATE_07: {
    key: "bgState07",
    path: "./funcaracAssets/chakra/states/background/state07.png"
  },
  BG_STATE_08: {
    key: "bgState08",
    path: "./funcaracAssets/chakra/states/background/state08.png"
  },
  BG_STATE_09: {
    key: "bgState09",
    path: "./funcaracAssets/chakra/states/background/state09.png"
  },
  BG_STATE_10: {
    key: "bgState10",
    path: "./funcaracAssets/chakra/states/background/state10.png"
  },
  UNIQUE_ELEMENT_01 : {
    key : "uniqueElement01",
    path: "./funcaracAssets/chakra/states/uniqueElement/uniqueElement01.png"
  },
  UNIQUE_ELEMENT_02 : {
    key : "uniqueElement02",
    path: "./funcaracAssets/chakra/states/uniqueElement/uniqueElement02.png"
  },
  UNIQUE_ELEMENT_03 : {
    key : "uniqueElement03",
    path: "./funcaracAssets/chakra/states/uniqueElement/uniqueElement03.png"
  },
  UNIQUE_ELEMENT_04 : {
    key : "uniqueElement04",
    path: "./funcaracAssets/chakra/states/uniqueElement/uniqueElement04.png"
  },
  UNIQUE_ELEMENT_05 : {
    key : "uniqueElement05",
    path: "./funcaracAssets/chakra/states/uniqueElement/uniqueElement05.png"
  },
  UNIQUE_ELEMENT_06 : {
    key : "uniqueElement06",
    path: "./funcaracAssets/chakra/states/uniqueElement/uniqueElement06.png"
  },

}

export const cornSeedStatesResources = {
  CORN_STATE_01 : {
    key : "cornState01",
    path: "./funcaracAssets/chakra/states/seeds-uniqueElements/corn/corn_state_01.png"
  },

  CORN_STATE_02 : {
    key : "cornState02",
    path: "./funcaracAssets/chakra/states/seeds-uniqueElements/corn/corn_state_02.png"
  },

  CORN_STATE_03 : {
    key : "cornState03",
    path: "./funcaracAssets/chakra/states/seeds-uniqueElements/corn/corn_state_03.png"
  },

  CORN_STATE_04 : {
    key : "cornState04",
    path: "./funcaracAssets/chakra/states/seeds-uniqueElements/corn/corn_state_04.png"
  },
}

export const potatoSeedStatesResources = {
  POTATO_STATE_01 : {
    key : "potatoState01",
    path: "./funcaracAssets/chakra/states/seeds-uniqueElements/potato/potato_state_01.png"
  },

  POTATO_STATE_02 : {
    key : "potatoState02",
    path: "./funcaracAssets/chakra/states/seeds-uniqueElements/potato/potato_state_02.png"
  },

  POTATO_STATE_03 : {
    key : "potatoState03",
    path: "./funcaracAssets/chakra/states/seeds-uniqueElements/potato/potato_state_03.png"
  },

  POTATO_STATE_04 : {
    key : "potatoState04",
    path: "./funcaracAssets/chakra/states/seeds-uniqueElements/potato/potato_state_04.png"
  },
}

export const ocaSeedStatesResources = {
  OCA_STATE_01 : {
    key : "ocaState01",
    path: "./funcaracAssets/chakra/states/seeds-uniqueElements/oca/oca_state_01.png"
  },

  OCA_STATE_02 : {
    key : "ocaState02",
    path: "./funcaracAssets/chakra/states/seeds-uniqueElements/oca/oca_state_02.png"
  },

  OCA_STATE_03 : {
    key : "ocaState03",
    path: "./funcaracAssets/chakra/states/seeds-uniqueElements/oca/oca_state_03.png"
  },

  OCA_STATE_04 : {
    key : "ocaState04",
    path: "./funcaracAssets/chakra/states/seeds-uniqueElements/oca/oca_state_04.png"
  },
}

export const beanSeedStatesResources = {
  BEAN_STATE_01 : {
    key : "beanState01",
    path: "./funcaracAssets/chakra/states/seeds-uniqueElements/bean/bean_state_01.png"
  },

  BEAN_STATE_02 : {
    key : "beanState02",
    path: "./funcaracAssets/chakra/states/seeds-uniqueElements/bean/bean_state_02.png"
  },

  BEAN_STATE_03 : {
    key : "beanState03",
    path: "./funcaracAssets/chakra/states/seeds-uniqueElements/bean/bean_state_03.png"
  },

  BEAN_STATE_04 : {
    key : "beanState04",
    path: "./funcaracAssets/chakra/states/seeds-uniqueElements/bean/bean_state_04.png"
  },
}

export const lupineSeedStatesResources = {
  LUPINE_STATE_01 : {
    key : "lupineState01",
    path: "./funcaracAssets/chakra/states/seeds-uniqueElements/lupine/lupine_state_01.png"
  },

  LUPINE_STATE_02 : {
    key : "lupineState02",
    path: "./funcaracAssets/chakra/states/seeds-uniqueElements/lupine/lupine_state_02.png"
  },

  LUPINE_STATE_03 : {
    key : "lupineState03",
    path: "./funcaracAssets/chakra/states/seeds-uniqueElements/lupine/lupine_state_03.png"
  },

  LUPINE_STATE_04 : {
    key : "lupineState04",
    path: "./funcaracAssets/chakra/states/seeds-uniqueElements/lupine/lupine_state_04.png"
  },
}

export const zamboSeedStatesResources = {
  ZAMBO_STATE_01 : {
    key : "zamboState01",
    path: "./funcaracAssets/chakra/states/seeds-uniqueElements/zambo/zambo_state_01.png"
  },

  ZAMBO_STATE_02 : {
    key : "zamboState02",
    path: "./funcaracAssets/chakra/states/seeds-uniqueElements/zambo/zambo_state_02.png"
  },

  ZAMBO_STATE_03 : {
    key : "zamboState03",
    path: "./funcaracAssets/chakra/states/seeds-uniqueElements/zambo/zambo_state_03.png"
  },

  ZAMBO_STATE_04 : {
    key : "zamboState04",
    path: "./funcaracAssets/chakra/states/seeds-uniqueElements/zambo/zambo_state_04.png"
  },
}

export const mashuaSeedStatesResources = {
  MASHUA_STATE_01 : {
    key : "mashuaState01",
    path: "./funcaracAssets/chakra/states/seeds-uniqueElements/mashua/mashua_state_01.png"
  },

  MASHUA_STATE_02 : {
    key : "mashuaState02",
    path: "./funcaracAssets/chakra/states/seeds-uniqueElements/mashua/mashua_state_02.png"
  },

  MASHUA_STATE_03 : {
    key : "mashuaState03",
    path: "./funcaracAssets/chakra/states/seeds-uniqueElements/mashua/mashua_state_03.png"
  },

  MASHUA_STATE_04 : {
    key : "mashuaState04",
    path: "./funcaracAssets/chakra/states/seeds-uniqueElements/mashua/mashua_state_04.png"
  },
}

export const mellocoSeedStatesResources = {
  MELLOCO_STATE_01 : {
    key : "mellocoState01",
    path: "./funcaracAssets/chakra/states/seeds-uniqueElements/melloco/melloco_state_01.png"
  },

  MELLOCO_STATE_02 : {
    key : "mellocoState02",
    path: "./funcaracAssets/chakra/states/seeds-uniqueElements/melloco/melloco_state_02.png"
  },

  MELLOCO_STATE_03 : {
    key : "mellocoState03",
    path: "./funcaracAssets/chakra/states/seeds-uniqueElements/melloco/melloco_state_03.png"
  },

  MELLOCO_STATE_04 : {
    key : "mellocoState04",
    path: "./funcaracAssets/chakra/states/seeds-uniqueElements/melloco/melloco_state_04.png"
  },
}

export const jicamaSeedStatesResources = {
  JICAMA_STATE_01 : {
    key : "jicamaState01",
    path: "./funcaracAssets/chakra/states/seeds-uniqueElements/jicama/jicama_state_01.png"
  },

  JICAMA_STATE_02 : {
    key : "jicamaState02",
    path: "./funcaracAssets/chakra/states/seeds-uniqueElements/jicama/jicama_state_02.png"
  },

  JICAMA_STATE_03 : {
    key : "jicamaState03",
    path: "./funcaracAssets/chakra/states/seeds-uniqueElements/jicama/jicama_state_03.png"
  },

  JICAMA_STATE_04 : {
    key : "jicamaState04",
    path: "./funcaracAssets/chakra/states/seeds-uniqueElements/jicama/jicama_state_04.png"
  },
}

export const camoteSeedStatesResources = {
  CAMOTE_STATE_01 : {
    key : "camoteState01",
    path: "./funcaracAssets/chakra/states/seeds-uniqueElements/camote/camote_state_01.png"
  },

  CAMOTE_STATE_02 : {
    key : "camoteState02",
    path: "./funcaracAssets/chakra/states/seeds-uniqueElements/camote/camote_state_02.png"
  },

  CAMOTE_STATE_03 : {
    key : "camoteState03",
    path: "./funcaracAssets/chakra/states/seeds-uniqueElements/camote/camote_state_03.png"
  },

  CAMOTE_STATE_04 : {
    key : "camoteState04",
    path: "./funcaracAssets/chakra/states/seeds-uniqueElements/camote/camote_state_04.png"
  },
}

export const arracachaSeedStatesResources = {
  ARRACACHA_STATE_01 : {
    key : "arracachaState01",
    path: "./funcaracAssets/chakra/states/seeds-uniqueElements/arracacha/arracacha_state_01.png"
  },

  ARRACACHA_STATE_02 : {
    key : "arracachaState02",
    path: "./funcaracAssets/chakra/states/seeds-uniqueElements/arracacha/arracacha_state_02.png"
  },

  ARRACACHA_STATE_03 : {
    key : "arracachaState03",
    path: "./funcaracAssets/chakra/states/seeds-uniqueElements/arracacha/arracacha_state_03.png"
  },

  ARRACACHA_STATE_04 : {
    key : "arracachaState04",
    path: "./funcaracAssets/chakra/states/seeds-uniqueElements/arracacha/arracacha_state_04.png"
  },
}

export const zapalloSeedStatesResources = {
  ZAPALLO_STATE_01 : {
    key : "zapalloState01",
    path: "./funcaracAssets/chakra/states/seeds-uniqueElements/zapallo/zapallo_state_01.png"
  },

  ZAPALLO_STATE_02 : {
    key : "zapalloState02",
    path: "./funcaracAssets/chakra/states/seeds-uniqueElements/zapallo/zapallo_state_02.png"
  },

  ZAPALLO_STATE_03 : {
    key : "zapalloState03",
    path: "./funcaracAssets/chakra/states/seeds-uniqueElements/zapallo/zapallo_state_03.png"
  },

  ZAPALLO_STATE_04 : {
    key : "zapalloState04",
    path: "./funcaracAssets/chakra/states/seeds-uniqueElements/zapallo/zapallo_state_04.png"
  },
}



export const piecesPicsResources = {
  PIC_FOOD: {
    key: "picFood",
    path: "./funcaracAssets/content/puzzle/picFood.png"
  },
  PIC_TRADITION: {
    key: "picTradition",
    path: "./funcaracAssets/content/puzzle/picTradition.png"
  },
  PIC_SIGN: {
    key: "picSign",
    path: "./funcaracAssets/content/puzzle/picSign.png"
  },
  PIC_SPEECH: {
    key: "picSpeech",
    path: "./funcaracAssets/content/puzzle/picSpeech.png"
  }
};

export const libraryResources = {
  LIBRARY: {
    key: "library",
    path: "./funcaracAssets/tales/library/library.png"
  },
  TALE_BLOCKED: {
    key: "taleBlocked",
    path: "./funcaracAssets/tales/library/tale/icons/blocked.png"
  },
  TALE_COMPLETED: {
    key: "taleCompleted",
    path: "./funcaracAssets/tales/library/tale/icons/completed.png"
  },
  TALE_NEW: {
    key: "taleNew",
    path: "./funcaracAssets/tales/library/tale/icons/new.png"
  },
  TALE_BODY: {
    key: "taleBody",
    path: "./funcaracAssets/tales/library/tale/body.png"
  },
  TALE_BOOK: {
    key: "taleBook",
    path: "./funcaracAssets/tales/library/tale/book.png"
  },
  TALE_LABEL: {
    key: "taleLabel",
    path: "./funcaracAssets/tales/library/tale/label.png"
  },
  TALE_SEED: {
    key: "taleSeed",
    path: "./funcaracAssets/tales/library/tale/seed.png"
  }
};

export const talesTitleResources = {
  TITLE_01:{
    key:'taleCulla',
    path:'./funcaracAssets/tales/library/tale/titles/taleCulla.png'
  },
  TITLE_02:{
    key:'talePanchi',
    path:'./funcaracAssets/tales/library/tale/titles/talePanchi.png'
  },
  TITLE_03:{
    key:'talePaucar',
    path:'./funcaracAssets/tales/library/tale/titles/talePaucar.png'
  },
  TITLE_04:{
    key:'taleAirihua',
    path:'./funcaracAssets/tales/library/tale/titles/taleAirihua.png'
  },
  TITLE_05:{
    key:'taleAimurai',
    path:'./funcaracAssets/tales/library/tale/titles/taleAimurai.png'
  },
  TITLE_06:{
    key:'taleRaimi',
    path:'./funcaracAssets/tales/library/tale/titles/taleRaimi.png'
  },
  TITLE_07:{
    key:'taleSitua',
    path:'./funcaracAssets/tales/library/tale/titles/taleSitua.png'
  },
  TITLE_08:{
    key:'taleCarhua',
    path:'./funcaracAssets/tales/library/tale/titles/taleCarhua.png'
  },
  TITLE_09:{
    key:'taleCusqui',
    path:'./funcaracAssets/tales/library/tale/titles/taleCusqui.png'
  },
  TITLE_10:{
    key:'taleHuairu',
    path:'./funcaracAssets/tales/library/tale/titles/taleHuairu.png'
  },
  TITLE_11:{
    key:'taleSasi',
    path:'./funcaracAssets/tales/library/tale/titles/taleSasi.png'
  },
  TITLE_12:{
    key:'taleCamai',
    path:'./funcaracAssets/tales/library/tale/titles/taleCamai.png'
  },
  TITLE_UNKNOWN:{
    key:'taleUnknown',
    path:'./funcaracAssets/tales/library/tale/titles/taleUnknown.png'
  }
}

export const bookResources = {
  BOOK_PASTE:{
    key: "bookPaste",
    path: "./funcaracAssets/tales/book/book-paste.png"
  },
  BOOK_FIRST_PAGE:{
    key: "bookFirstPage",
    path: "./funcaracAssets/tales/book/bookFirstPage.png"
  },
  BOOK_LAST_PAGE:{
    key: "bookLastPage",
    path: "./funcaracAssets/tales/book/bookLastPage.png"
  },
  SEPARATOR_TALE:{
    key: "separatorTale",
    path: "./funcaracAssets/tales/book/separatorCh01.png"
  },
  SEPARATOR_FESTIVITY:{
    key: "separatorFestivity",
    path: "./funcaracAssets/tales/book/separatorCh02.png"
  },
  SEPARATOR_FOOD:{
    key: "separatorFood",
    path: "./funcaracAssets/tales/book/separatorCh03.png"
  },
  SEPARATOR_SIGNS:{
    key: "separatorSigns",
    path: "./funcaracAssets/tales/book/separatorCh04.png"
  },
  LISTON_BOOK : {
    key : "listonBook",
    path: "./funcaracAssets/tales/book/liston_book.png"
  }
}

export const pagesResources = {
  PAGE_BODY:{
    key: "pageBody",
    path: "./funcaracAssets/tales/page/body.png"
  },
  PAGE_CORNER:{
    key: "pageCorner",
    path: "./funcaracAssets/tales/page/corner.png"
  },
  PAGE_RIBBON:{
    key: "pageRibbon",
    path: "./funcaracAssets/tales/page/ribbon.png"
  }
}

export const coverPageResources = {
  PAGE_TITLE_LABEL:{
    key: "pageTitleLabel",
    path: "./funcaracAssets/tales/cover-page/label.png"
  },
  PAGE_PRICE:{
    key: "pagePrice",
    path: "./funcaracAssets/tales/cover-page/price.png"
  },
  PAGE_PRICE_BOX:{
    key: "pagePriceBox",
    path: "./funcaracAssets/tales/cover-page/priceBox.png"
  }
}

export const piecesPageResources = {
  FESTIVITY_LABEL:{
    key: "festivityLabel",
    path: "./funcaracAssets/tales/pieces-page/labelFestivity.png"
  },
  FOOD_LABEL:{
    key: "foodLabel",
    path: "./funcaracAssets/tales/pieces-page/labelFood.png"
  },
  SIGNS_LABEL:{
    key: "singsLabel",
    path: "./funcaracAssets/tales/pieces-page/labelSigns.png"
  },
  DIALECTS_LABEL:{
    key: "dialectsLabel",
    path: "./funcaracAssets/tales/pieces-page/labelDialects.png"
  },
  PIECE_BOX:{
    key: "pieceBox",
    path: "./funcaracAssets/tales/pieces-page/pieceBox.png"
  },
}

export const idCardPageResources = {
  PHOTO_BOX : {
    key : "photoBox",
    path : "./funcaracAssets/tales/idCard-page/photoBox/photoBox.png"
  },
  PORTRAIT : {
    key : "portrait",
    path : "./funcaracAssets/tales/idCard-page/photoBox/portrait.png"
  },
}

export const mashisResources = {
  MASHI_01 : {
    key : "mashi01",
    path : "./funcaracAssets/content/mashis/mashi laura.png"
  },
  MASHI_02 : {
    key : "mashi02",
    path : "./funcaracAssets/content/mashis/mashi fernando.png"
  },
  MASHI_03 : {
    key : "mashi03",
    path : "./funcaracAssets/content/mashis/mashi edelina.png"
  },
  MASHI_04 : {
    key : "mashi04",
    path : "./funcaracAssets/content/mashis/mashi rosita.png"
  },
  MASHI_05 : {
    key : "mashi05",
    path : "./funcaracAssets/content/mashis/mashi luis.png"
  },
  MASHI_06 : {
    key : "mashi06",
    path : "./funcaracAssets/content/mashis/mashi juan carlos.png"
  },
}

export const simbolsResources = {
  FESTIVITY_SIMBOL : {
    key : 'festivitySimbol',
    path : './funcaracAssets/simbols/festivity-simbol.png'
  },
  FOOD_SIMBOL : {
    key : 'foodSimbol',
    path : './funcaracAssets/simbols/food-simbol.png'
  },
  SIGNS_SIMBOL : {
    key : 'signsSimbol',
    path : './funcaracAssets/simbols/signs-simbol.png'
  },
  DIALECT_SIMBOL : {
    key : 'dialectSimbol',
    path : './funcaracAssets/simbols/dialect-simbol.png'
  },
  RIGHT_SIMBOL : {
    key : 'rightSimbol',
    path : './funcaracAssets/simbols/right-simbol.png'
  },
  WRONG_SIMBOL : {
    key : 'wrongSimbol',
    path : './funcaracAssets/simbols/wrong-simbol.png'
  },

  COVER_SIGNS : {
    key : 'coverSigns',
    path : './funcaracAssets/content/cover/portada_señas_secretos.png'
  },
  COVER_DIALECTS : {
    key : 'coverDialects',
    path : './funcaracAssets/content/cover/portada_dialectos.png'
  },
}

export const chakraBubbleResources = {
  CHAKRA_BUBBLE : {
    key : 'chakraBubble',
    path : './funcaracAssets/chakra/bubble/bocadillo.png'
  },
  BUBBLE_ICON_COMPOST : {
    key : 'bubbleIconCompost',
    path : './funcaracAssets/chakra/bubble/icono_abono.png'
  },
  BUBBLE_ICON_WATER : {
    key : 'bubbleIconWater',
    path : './funcaracAssets/chakra/bubble/icono_agua.png'
  },
  BUBBLE_ICON_HOE : {
    key : 'bubbleIconHoe',
    path : './funcaracAssets/chakra/bubble/icono_azadon.png'
  },
  BUBBLE_ICON_SHOVEL : {
    key : 'bubbleIconShovel',
    path : './funcaracAssets/chakra/bubble/icono_pala.png'
  },
  BUBBLE_ICON_SPRAY : {
    key : 'bubbleIconSpray',
    path : './funcaracAssets/chakra/bubble/icono_spray.png'
  },
  BUBBLE_ICON_SCISSORS : {
    key : 'bubbleIconScissors',
    path : './funcaracAssets/chakra/bubble/icono_tijeras.png'
  },
  BUBBLE_ICON_SEED : {
    key : 'bubbleIconSeed',
    path : './funcaracAssets/chakra/bubble/icono_semilla.png'
  },

}

export const spritesheetAnimations = {
  CHAKRA_SMOG : {
    key : 'chakraSmogAnim',
    path : './funcaracAssets/chakra/anims/chakra/chakra_smog.png',
    config : {
      frameWidth : 500,
      frameHeight : 500
    } 
  },
  CORNER_UP : {
    key : 'cornerUpAnim',
    path : './funcaracAssets/tales/anims/corner-up.png',
    config : {
      frameWidth : 119,
      frameHeight : 119,
      startFrame : 1,
    } 
  },
  CORNER_DOWN : {
    key : 'cornerDownAnim',
    path : './funcaracAssets/tales/anims/corner-down.png',
    config : {
      frameWidth : 119,
      frameHeight : 119,
      endFrame : 4

    } 
  },
}

export const taleSoundsResources = {
  PAGE_FLIPING : {
    key : 'pageFliping',
    path : './funcaracAssets/sounds/tales/page_fliping.wav'
  },
  SEPARATOR_FLIPING : {
    key : 'separatorFliping',
    path : './funcaracAssets/sounds/tales/separator_fliping.wav'
  },
  RIBBON_FLIPING : {
    key : 'ribbonFliping',
    path : './funcaracAssets/sounds/tales/ribbon_fliping.wav'
  }
}

export const mainUISoundResources = {
  BACKGROUNG_MUSIC : {
    key : 'backgroundMusic',
    path : './funcaracAssets/sounds/mainui/background01.wav'
  },
  MAIN_MENU_BUTTON_CLIC : {
    key : 'mainMenuButtonClic',
    path : './funcaracAssets/sounds/mainui/main_menu_button_clic.wav'
  },
  ASSISTANT_MENU_BUTTON_CLIC : {
    key : 'assistantMenuButtonClic',
    path : './funcaracAssets/sounds/mainui/assistant_menu_button_clic.wav'
  },
  DIALOG_MODAL_BUTTON_CLIC : {
    key : 'dialogModalButtonClic',
    path : './funcaracAssets/sounds/mainui/ok_button_clic.wav'
  },
  CHILDREN_CHEERING : {
    key : 'childrenCheering',
    path : './funcaracAssets/sounds/mainui/children_cheering.wav'
  },
  VICTORY : {
    key : 'victory',
    path : './funcaracAssets/sounds/mainui/victory.wav'
  }
}

export const librarySoundResources = {
  PADLOCK_CLING : {
    key : 'padlockCling',
    path : './funcaracAssets/sounds/library/padlock_sound_effect.wav'
  },
  PADLOCK_ACCESS_DENIED : {
    key : 'padlockAccessDenied',
    path : './funcaracAssets/sounds/library/padlock_access_denied.wav'
  },
  TALE_SELECTION : {
    key : 'taleSelection',
    path : './funcaracAssets/sounds/library/tale_selection_sound_effect.wav'
  }
}

export const chakraSoundResources = {
  TOOL_CLICK : {
    key : 'toolClick',
    path : './funcaracAssets/sounds/chakra/tool_button_selection.wav'
  },
  SEED_CLICK : {
    key : 'seedClick',
    path : './funcaracAssets/sounds/chakra/seed_button_selection.wav'
  },

  CLOUD_EFFECT : {
    key : 'cloudEffect',
    path : './funcaracAssets/sounds/chakra/cloud_sound_effect.wav'
  },
  PROGRESS_BAR_EFFECT : {
    key : 'progressBarEffect',
    path : './funcaracAssets/sounds/chakra/progress_bar_effect.wav'
  },
  SEED_USED_EFFECT : {
    key : 'seedUsedEffect',
    path : './funcaracAssets/sounds/chakra/seed_used_effect.wav'
  },
  //SCISSORS EFFECTS
  SCISSORS_EFFECT_01 : {
    key : 'scissorsEffect01',
    path : './funcaracAssets/sounds/chakra/scissors_effect_01.wav'
  },
  SCISSORS_EFFECT_02 : {
    key : 'scissorsEffect02',
    path : './funcaracAssets/sounds/chakra/scissors_effect_02.wav'
  },
  //SHOVEL EFFECTS
  SHOVEL_EFFECT : {
    key : 'shovelEffect',
    path : './funcaracAssets/sounds/chakra/shovel_effect.wav'
  },
  //HOE EFFECTS
  HOE_EFFECT : {
    key : 'hoeEffect',
    path : './funcaracAssets/sounds/chakra/hoe_effect.wav'
  },
  //WATER EFFECTS
  WATER_EFFECT : {
    key : 'waterEffect',
    path : './funcaracAssets/sounds/chakra/water_effect.wav'
  },
  //COMPOST EFFECTS
  COMPOST_EFFECT : {
    key : 'compostEffect',
    path : './funcaracAssets/sounds/chakra/compost_effect.wav'
  },
  //SPRAY EFFECTS
  SPRAY_EFFECT : {
    key : 'sprayEffect',
    path : './funcaracAssets/sounds/chakra/spray_effect.wav'
  }

}

export const caracSoundResources = {
  DROP_PUZZLE_PIECE_EFFECT : {
    key : 'dropPuzzlePieceEffect',
    path : './funcaracAssets/sounds/carac/drop_puzzle_piece.wav'
  },
  DROP_WRONG_EFFECT : {
    key : 'dropWrongEffect',
    path : './funcaracAssets/sounds/carac/drop_wrong_effect.wav'
  },
  CARRUSEL_SOUND_EFFECT : {
    key : 'carruselSoundEffect',
    path : './funcaracAssets/sounds/carac/carrusel_sound_effect.wav'
  }

}

export const quizSoundResources = {
  CORRECT_ANSWER_EFFECT : {
    key : 'correctAnswerEffect',
    path : './funcaracAssets/sounds/quiz/correct_answer_effect.wav'
  },
  WRONG_ANSWER_EFFECT : {
    key : 'wrongAnswerEffect',
    path : './funcaracAssets/sounds/quiz/wrong_answer_effect.wav'
  },
  STAR_WINK_EFFECT : {
    key : 'starWinkEffect',
    path : './funcaracAssets/sounds/quiz/star_wink_effect.wav'
  },
  NO_POINTS_SCORE_EFFECT : {
    key : 'noPointsScoreEffect',
    path : './funcaracAssets/sounds/quiz/no_points_score_effect.wav'
  }
}

export const domResources = {
  FORM_SIGNUP : {
    key : 'formSignUp',
    path : './funcaracAssets/http-files/forms/signUpForm.html'
  },
  FORM_LOGIN : {
    key : 'formLogIn',
    path : './funcaracAssets/http-files/forms/logInForm.html'
  }
}

export const htmlResources = [
  ...Object.values(domResources)
]

export const introResources = [
  ...Object.values(introAssets),
  ...Object.values(welcomeSceneResources),
  ...Object.values(guidebookResources),
  ...Object.values(guideBookPagesResources)
];

export const gameResources = [
  ...Object.values(assistantMenuResources),
  ...Object.values(dialogBubbleResources),
  ...Object.values(mainUIResources),
  ...Object.values(caracResources),
  ...Object.values(caracMonthsResources),
  ...Object.values(piecesPicsResources),
  ...Object.values(chakraResources),
  ...Object.values(chakraStatesResources),
  ...Object.values(seedsResources),
  ...Object.values(libraryResources),
  ...Object.values(talesTitleResources),
  ...Object.values(mashisResources),
  ...Object.values(bookResources),
  ...Object.values(pagesResources),
  ...Object.values(coverPageResources),
  ...Object.values(piecesPageResources),
  ...Object.values(idCardPageResources),
  ...Object.values(simbolsResources),
  ...Object.values(chakraBubbleResources),
  
  //loading the festivities resources
  ...Object.values(sisayPachaResources),
  ...Object.values(pawkarRaymiResources),
  ...Object.values(allpaCruzResources),
  ...Object.values(intiRaymiResources),
  ...Object.values(pallayRaymiResources),
  ...Object.values(sawariyResources),
  ...Object.values(kullaRaymiResources),
  ...Object.values(riksichiyResources),
  ...Object.values(velanakuyResources),
  ...Object.values(kapakRaymiResources),
  ...Object.values(yumbadaResources),
  ...Object.values(llulluMuruRaymiResources),
  
  //loading the food resources
  ...Object.values(chuklluKamllaResources),
  ...Object.values(milichakiResources),
  ...Object.values(mishkiZamboResources),
  ...Object.values(uchukutaResources),
  ...Object.values(mutiLuciResources),
  ...Object.values(champusResources),
  ...Object.values(makiMachikaResources),
  ...Object.values(mishkiKamllaResources),
  ...Object.values(yamurAsuaResources),
  ...Object.values(katzuKamllaResources),
  ...Object.values(yanaApiResources),
  ...Object.values(churuApiResources),

  //loading the signs resources
  ...Object.values(familiarSignsResources),
  ...Object.values(deathSignsResources),
  ...Object.values(goodLuckSignsResources),
  ...Object.values(badLuckSignsResources),
  ...Object.values(weatherSignsResources),

  //loading the secrets resources
  ...Object.values(secretsResourcesList),

  //loading the festivities prices resources
  ...Object.values(festivityPricesResources),

  //loading the food prices resources
  ...Object.values(foodPricesResources),
 
  //loading the signs and secrets prices resources
  ...Object.values(signsSecretsPriceResources),

  //loading the dialects prics resources
  ...Object.values(dialectsPriceResources),

  //loading the corn states resources
  ...Object.values(cornSeedStatesResources),

  //loading the potato states resources
  ...Object.values(potatoSeedStatesResources),

  //loading the oca states resources
  ...Object.values(ocaSeedStatesResources),

  //loading the bean states resources
  ...Object.values(beanSeedStatesResources),

  //loading the lupine states resources
  ...Object.values(lupineSeedStatesResources),

  //loading the zambo states resources
  ...Object.values(zamboSeedStatesResources),

  //loading the mashua states resources
  ...Object.values(mashuaSeedStatesResources),

  //loading the melloco states resources
  ...Object.values(mellocoSeedStatesResources),

  //loading the jicama states resources
  ...Object.values(jicamaSeedStatesResources),

  //loading the camote states resources
  ...Object.values(camoteSeedStatesResources),

  //loading the arracacha states resources
  ...Object.values(arracachaSeedStatesResources),

  //loading the zapallo states resources
  ...Object.values(zapalloSeedStatesResources),

];

export const sfxResources = [
  { key: "sans", url: "./funcaracSFX/mainUI/sans_01.mp3" }
];

export const animResources = [
  ...Object.values(spritesheetAnimations)
]

export const audioResources = [
  ...Object.values(taleSoundsResources),
  ...Object.values(mainUISoundResources),
  ...Object.values(librarySoundResources),
  ...Object.values(chakraSoundResources),
  ...Object.values(caracSoundResources),
  ...Object.values(quizSoundResources)
]
