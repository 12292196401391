import Phaser from 'phaser'
import GameOptionButton from './gameOptionButton';
import { optionButtonsInformation } from '../../../../utils/information/buttons/welcome-menu-butn/buttonsInformation';
import { gridAlignGroupElements, addChildrenToContainer } from '../../../../utils/functions';
import { scenesKey } from '../../../../utils/gameConstants';

export default class GameOptionsMenu extends Phaser.GameObjects.Container{
    constructor(scene,x,y){
        super(scene,x,y);
        this.setScene(scene);
        this.setInitialProperties();
        // this.redirectScenes();
    }

    setScene(scene){ this.scene = scene }
    getScene(){ return this.scene }

    setFastGameOption(option){ this.fastGameOption = option }
    getFastGameOption(){ return this.fastGameOption }

    setSignUpOption(option){ this.signUpOption = option }
    getSignUpOption(){ return this.signUpOption }

    setLogInOption(option){ this.logInOption = option }
    getLogInOption(){ return this.logInOption }

    setInstructionsOption(option){ this.instructionsOption = option }
    getInstructionsOption(){ return this.instructionsOption }

    setCreditsOption(option){ this.creditsOption = option }
    getCreditsOption(){ return this.creditsOption }

    setPlayGameOption(option){ this.playGameOption = option }
    getPlayGameOption(){ return this.playGameOption }

    setExitOption(option){ this.exitOption = option }
    getExitOption(){ return this.exitOption }

    setSelectedOption(option){ this.selectedOption = option }
    getSelectedOption(){ return this.selectedOption }

    setOptionsList(list){ this.optionsList = list }
    getOptionsList(){ return this.optionsList }

    setGridConfig(config){ this.gridConfig = config }
    getGridConfig(){ return this.gridConfig }

    setInitialProperties(){
        this.setOptionsList([]);
        this.setSelectedOption(null);
        this.setGridConfig({width : 2, height : 3, cellWidth : 700, cellHeight : 180, position : 6 })
    }

    addOptionToList(option){
        this.getOptionsList().push(option);
    }

    createFastGameOption(){
        let scene = this.getScene(),
            option = new GameOptionButton(scene,0,0,optionButtonsInformation.OPTION_FAST_GAME);
        this.setFastGameOption(option);

        this.addOptionToList(this.getFastGameOption());

    }

    createSignUpOption(){
        let scene = this.getScene(),
            option = new GameOptionButton(scene,0,0,optionButtonsInformation.OPTION_SIGN_UP);
        this.setSignUpOption(option);

        this.addOptionToList(this.getSignUpOption());
    }

    createLogInOption(){
        let scene = this.getScene(),
            option = new GameOptionButton(scene,0,0,optionButtonsInformation.OPTION_LOG_IN);
        this.setLogInOption(option);

        this.addOptionToList(this.getLogInOption());
    }

    createInstructionsOption(){
        let scene = this.getScene(),
            option = new GameOptionButton(scene,0,0,optionButtonsInformation.OPTION_INSTRUCTIONS);
        this.setInstructionsOption(option);

        this.addOptionToList(this.getInstructionsOption());
    }

    createCreditsOption(){
        let scene = this.getScene(),
            option = new GameOptionButton(scene,0,0,optionButtonsInformation.OPTION_CREDITS);
        this.setCreditsOption(option);

        this.addOptionToList(this.getCreditsOption());
    }

    createPlayGameOption(){
        let scene = this.getScene(),
            option = new GameOptionButton(scene,0,0,optionButtonsInformation.OPTION_PLAY_GAME);
        this.setPlayGameOption(option);

        this.addOptionToList(this.getPlayGameOption());
    }

    createExitOption(){
        let scene = this.getScene(),
            option = new GameOptionButton(scene,0,0,optionButtonsInformation.OPTION_EXIT);
        this.setExitOption(option);

        this.addOptionToList(this.getExitOption());
    }

    createIntroOptionButtons(){
        this.createSignUpOption();
        this.createLogInOption();
        this.createFastGameOption();
    }

    createGameMenuOptionButtons(){
        this.createPlayGameOption();
        this.createCreditsOption();
        this.createInstructionsOption();
        this.createExitOption();
    }

    createOptionButtons(){
        this.createFastGameOption();
        this.createSignUpOption();
        this.createLogInOption();
        this.createInstructionsOption();
        this.createCreditsOption();
    }

    createMenu(){
        this.createOptionButtons();
        let scene = this.getScene(), optionsList = this.getOptionsList(),
        gridConfig = this.getGridConfig(), group = scene.add.group(optionsList);

        gridAlignGroupElements(scene,group,gridConfig);
        addChildrenToContainer(this,group.getChildren());
    }

    createIntroMenu(){
        this.createIntroOptionButtons();
        let scene = this.getScene(), optionsList = this.getOptionsList(),
        gridConfig = this.getGridConfig(), group = scene.add.group(optionsList);

        gridAlignGroupElements(scene,group,gridConfig);
        addChildrenToContainer(this,group.getChildren());
    }

    createGameMenu(){
        this.createGameMenuOptionButtons();
        let scene = this.getScene(), optionsList = this.getOptionsList(),
        gridConfig = this.getGridConfig(), group = scene.add.group(optionsList);

        gridAlignGroupElements(scene,group,gridConfig);
        addChildrenToContainer(this,group.getChildren());
    }

    redirectScenes(){
       let scene = this.getScene(),
        signUpOption = this.getSignUpOption();

       signUpOption.on('pointerup',()=>{ scene.scene.switch(scenesKey.SIGNUP_SCENE)})
    }

}