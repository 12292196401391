import React,{ Component } from "react";
import Phaser from "phaser";
import { connect } from "react-redux";

import CaracScene from "./GameScenes/CaracScene";
import BootLoader from "./GameScenes/BootLoader";
import MainUIScene from "./GameScenes/MainUIScene";
import ChakraScene from "./GameScenes/ChakraScene";
import LibraryScene from "./GameScenes/LibraryScene";
// import IntroScene from "./GameScenes/introScene";
import IntroScene from "./GameScenes/initScenes/mainScenes/introScene";
import GameMenuScene from "./GameScenes/initScenes/mainScenes/gameMenuScene";
import SignUpScene from "./GameScenes/initScenes/mainScenes/signUpScene";
import LogInScene from "./GameScenes/initScenes/mainScenes/logInScene";
import CreditsScene from "./GameScenes/initScenes/mainScenes/creditScene";
import GuideScene from "./GameScenes/initScenes/mainScenes/guideScene";


export default class FuncaracGame extends Component{
    componentDidMount(){
        const gameConfig = {
            type:Phaser.AUTO,
            backgroundColor:'#2d2d2d',   
            // scene:[IntroScene,SignUpScene,LogInScene,GameMenuScene,CreditsScene,GuideScene,BootLoader,MainUIScene,CaracScene, ChakraScene, LibraryScene],
            scene:[IntroScene,SignUpScene,LogInScene,GameMenuScene,CreditsScene,GuideScene],
            scale:{
                parent:'funcarac-game',
                mode:Phaser.Scale.FIT,
                height:window.innerHeight * window.devicePixelRatio,
                width: window.innerWidth * window.devicePixelRatio
            },
            dom: {
                createContainer: true
            },
        };
        new Phaser.Game(gameConfig);
    }

    shouldComponentUpdate(){
        return false;
    }

    render(){
        return <div style={{ height : '100vh'}} id="funcarac-game"/>;
    }

}

