import Chapter from "../../../baseClasses/chapter";

export default class FoodChapter extends Chapter{
    constructor(scene,data, monthName){
        super(data);
        this.setMonth(monthName);
        this.addFoodChapter(scene);
    }

    
    addFoodChapter(scene){
        this.setIntroPage(scene,this.type);
        this.setAuthorPage(scene);
        this.setFactPages(scene,this.description.mainFacts);
        this.setIngredientsPages(scene,this.description.ingredients);
        this.setDishPreparationPages(scene,this.description.preparation);
        this.setQuizPage(scene,this.quiz,this.puzzlePieceInfo);
    }
}