import { chakraResources, chakraSoundResources } from "../../utils/assetsPath";
import Box from "../baseClasses/Box";
import SeedButton from "./seedButton";
import { addChildrenToContainer, gridAlignGroupElements } from "../../utils/functions";
import Label from "../baseClasses/Label";
import { seedsCollection } from "../../utils/information/seeds/seedsInformation";

export default class SeedsBox extends Box{
    constructor(scene,x,y){
        super(scene,x,y,chakraResources.SEEDS_BOX.key);
        this.setInitialSeedBoxProperties(scene);
        this.buildSeedsBox();
    }

    buildSeedsBox(){
        let scene = this.getScene();
        this.addBox(scene);
        this.addPlaque();
        this.displayBox(scene);
        this.addSeedButtons();
    }

    setInitialSeedBoxProperties(scene){
        let gridConfig = {width:6,height:2,cellWidth:130,cellHeight:105,x:-160,y:-50};
        this.setScene(scene);
        this.setSeedsGridConfig(gridConfig);
        this.setSelectedSeed(null);
        this.setSeedsList([]);
        this.setAvailableSeedsList([]);
        this.setUsedSeedsList([]);
    }
    
    getScene(){ return this.scene }
    setScene(scene){ this.scene = scene }

    getSelectedSeed(){  return this.selectedSeed }
    setSelectedSeed(seed){ this.selectedSeed = seed }

    getSeedsList(){  return this.seedsList }
    setSeedsList(list){ this.seedsList = list }

    getAvailableSeedsList(){ return this.availableSeedsList }
    setAvailableSeedsList(list){ this.availableSeedsList = list }

    getUsedSeedsList(){ return this.usedSeedsList }
    setUsedSeedsList( list ){ this.usedSeedsList = list }

    getSeedsGridConfig(){ return this.seedsGridConfig }
    setSeedsGridConfig(config){ this.seedsGridConfig = config }

    
    getPlaque(){ return this.plaque }
    setPlaque(plaque){ this.plaque = plaque }

    getBox(){ return this.box }

    addPlaque(){
        let scene = this.getScene();
        this.setPlaque(new Label(scene,0,0,chakraResources.SEEDS_BOX_PLAQUE.key,'COLECCIÓN\n DE SEMILLAS'));
        this.getPlaque().setTextStyle({fontFamily : 'Franklin Gothic', fontSize : '44px', stroke : "#33034E", color : '#9557B8', strokeThickness : 5})
        let plaque = this.getPlaque();
        plaque.setPosition(-this.getBox().width*0.35,0);
        this.add(plaque);
    }

    addSeedBtnToList(seedBtn){
        this.getSeedsList().push(seedBtn);
    }

    alignSeedElements(){
        let scene = this.getScene(),
            seedsList = this.getSeedsList(),
            gridConfig = this.getSeedsGridConfig(),
            group = scene.add.group(seedsList);
        gridAlignGroupElements(this,group,gridConfig);
        addChildrenToContainer(this,group.getChildren());
    }

    addSeedButtons(){
        let scene = this.getScene();
        for(let i=0;i<12;i++){
            let seedBtn = new SeedButton(scene,0,0,chakraResources.SEED_BASKET.key,seedsCollection[i]);
            seedBtn.setAlpha(0.5);
            this.addSeedBtnToList(seedBtn);
        }
        this.alignSeedElements();
    }

    unlockButton(button){
        button.icon.clearTint();
        button.setIcon();
        button.icon.setScale(0.45,0.39);
        button.setAlpha(1);
        button.setInteractive();
        this.setButtonActions(button);
    }

    setButtonActions(button){
        button.on('pointerdown',()=>{
            if(this.getAvailableSeedsList().includes(button)){
                this.getScene().sound.play(chakraSoundResources.SEED_CLICK.key);
                this.setSelectedSeed(button);
                button.icon.setTint(0x2d2d2d);
                this.getScene().events.emit('setSelectedSeed');
                this.getScene().events.emit('seedReady');
            }
        });
        button.on('pointerup',()=>{
            this.seedsList.forEach(child => {
                if(child!==this.getSelectedSeed()){
                    child.icon.clearTint();
                }
            })
        });
    }

    findButton(data){
        //TODO cambiar esta validación con un id
        let seedButton = this.getSeedsList().find((btn)=>{
            return btn.seedData.name === data.name;
        });
        this.unlockButton(seedButton);
        this.addSeedButtonToAvailableList(seedButton);
    }

    freezeButtons(){
        this.getSeedsList().forEach((button)=>{
            button.disableInteractive();
        })
    }

    callBackButtons(){
        this.getSeedsList().forEach((button)=>{
            button.setInteractive();
        })
    }

    cleanButtons(){
        this.setSelectedSeed(null);
        this.discardButtons();
    }

    discardButtons(){
        this.getSeedsList().forEach((btn)=>{
            if(btn!==this.selectedButton){
                btn.icon.clearTint();
            }
        })
    }

    addSeedButtonToAvailableList(btn){
        this.getAvailableSeedsList().push(btn);
    }

    removeSeedButtonfromAvailableList(btn){
        let list = this.getAvailableSeedsList(),
            index = list.indexOf(btn);
        if(index>-1){
            list.splice(index,1);
        }
        list  = this.getAvailableSeedsList();
    }

    addSeedButtonToUsedList(btn){
        this.getUsedSeedsList().push(btn);
    }

    
}