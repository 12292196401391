import Phaser from 'phaser';
import Ribbon from './Ribbon';
import { pagesResources } from '../../utils/assetsPath';
import { gridAlignGroupElements, addChildrenToContainer } from '../../utils/functions';

export default class Page extends Phaser.GameObjects.Container{
    constructor(scene,x,y,pageName){
        super(scene,x,y);

        this.body = null;
        this.leftCorner = null;
        this.rightCorner = null;
        this.title = pageName;
    }

    setBody(scene,texture){
        this.body = scene.add.image(0,0,texture);
        this.add(this.body).setSize(this.body.width,this.body.height);
    }

    setCorners(scene,texture){
        // this.leftCorner = scene.add.image(0,0,texture);
        // this.rightCorner = scene.add.image(0,0,texture);

        this.leftCorner = this.createCornerAnim(scene);
        this.rightCorner = this.createCornerAnim(scene);

        this.leftCorner.setFlipX(180);
        let group = scene.add.group([this.leftCorner,this.rightCorner]);
        let gridConfig = {width:2,height:1,cellWidth:440,cellHeight:0,position:6,x:-220,y:320};
        gridAlignGroupElements(scene,group,gridConfig);
        addChildrenToContainer(this,group.getChildren());
    }

    createCornerAnim(scene){
        let upAnim = scene.anims.create({
            key : 'cornerUp',
            frames : scene.anims.generateFrameNumbers('cornerUpAnim'),
            frameRate : 15
        });
        let downAnim = scene.anims.create({
            key : 'cornerDown',
            frames : scene.anims.generateFrameNumbers('cornerDownAnim'),
            frameRate : 15
        });
        let corner = scene.add.sprite(0,0,'cornerUpAnim');
        corner.anims.load('cornerDown');
        corner.anims.load('cornerUp');
        
        corner.setScale(0.8,0.85);

        return corner;
    }

    setRibbon(scene,texture){
        this.ribbon = new Ribbon(scene,0,-275,texture,this.title);
        let ribbonStyle = {
            fontSize:'30px',
            fontFamily:'Franklin Gothic',
            color:'#E6EF78',
            // color:'#EFBD78',
            align:'center',
            stroke:'#320806',
            strokeThickness:4,
            padding:{
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
            }
        };
        this.ribbon.setStyle(ribbonStyle);
        this.ribbon.createRibbon(scene);

        this.add(this.ribbon);
    }

    buildPage(scene){
        this.setBody(scene,pagesResources.PAGE_BODY.key);
        this.setRibbon(scene,pagesResources.PAGE_RIBBON.key);
        this.setCorners(scene,pagesResources.PAGE_CORNER.key);
        this.activeCorners();
    }

    disableCorners(){
        this.leftCorner.disableInteractive();
        this.rightCorner.disableInteractive();
    }

    activeCorners(){
        this.leftCorner.setInteractive({useHandCursor : true});
        this.rightCorner.setInteractive({useHandCursor : true});

        this.leftCorner.on('pointerover',()=>{
            this.leftCorner.anims.play('cornerUp');
        });

        this.leftCorner.on('pointerout',()=>{
            this.leftCorner.anims.play('cornerDown');
        });

        this.rightCorner.on('pointerover',()=>{
            this.rightCorner.anims.play('cornerUp');
        });

        this.rightCorner.on('pointerout',()=>{
            this.rightCorner.anims.play('cornerDown');
        })
    }

    
}