import DescriptionPage from "./descriptionPage";
import MeaningCard from "../game-objects/meaningCard";

export default class MeaningPage extends DescriptionPage{
    constructor(scene,x,y,data){
        super(scene,x,y,'SIGNIFICADO DE LA FESTIVIDAD',data);
        this.addPageContent(scene,data);
    }


    addPageContent(scene,data){
        let card = new MeaningCard(scene,0,0,data,'Esta festividad es realizada en honor a:');
        this.add(card);
    }
}