import * as types from '../actions/actionTypes';
import initialState from "./initial-state/initialState";


export default function chakraReducer (state = initialState.chakra_records, action){
    switch(action.type){
        case types.CREATE_CHAKRA_RECORDS_SUCCESS:
            return state;
        
        case types.LOAD_CHAKRA_LIST:
            return action.recordList;
        
        case types.LOAD_CHAKRA_RECORDS_SUCCESS:
            return state;
        
        default:
            return state;
    }
}