import React,{Component} from 'react';
import { Form } from 'react-bootstrap';
import LoginButton from '../buttons/loginButton';


export default class LoginForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            CI : '',
            password : '',
            loggedIn : false
        };

        this.handleInputChange = this.handleInputChange.bind(this);
    }
    
    render(){
        return this.createForm();
    }

    handleInputChange(event){
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({[name]:value});

        this.setState((state)=>{
            this.ciValue = state.CI;
            this.passwordValue = state.password;
        })
    }

    createForm(){
        return(<Form onSubmit = {this.handleSubmit}>
            <Form.Group controlId = "formCI" style = {{padding:"0px 15px 0px 15px"}}>
            <Form.Label>Identity Card</Form.Label>
            <Form.Control name = 'CI'
                          value = {this.state.CI}  
                          onChange = {this.handleInputChange} 
                          placeholder="Insert your identity card"/>
            </Form.Group>

            <Form.Group controlId = "formPassword" style = {{padding:"0px 15px 0px 15px"}}>
            <Form.Label>Password</Form.Label>
            <Form.Control name = 'password'
                          value = {this.state.password}
                          onChange = {this.handleInputChange}
                          placeholder="Insert your password"/>
            </Form.Group>

            <Form.Group controlId = "formButton" style = {{padding:"0px 15px 10px 15px"}}>
                <LoginButton givenCI = {this.state.CI}
                             givenPassword = {this.state.password}/>
            </Form.Group>
        </Form>)
    }
}