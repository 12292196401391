import Phaser from 'phaser';

import { gridAlignGroupElements, addChildrenToContainer } from '../../utils/functions'

export default class Box extends Phaser.GameObjects.Container{
    constructor(scene,x,y,texture){
        super(scene,x,y);
        this.texture = texture;
        this.listElements = [];
        this.groupElements = null;
        this.gridConfig = null;
    }

    addBox(scene){
        this.box = scene.add.image(0,0,this.texture);
        this.add(this.box).setSize(this.box.width,this.box.height);    
    }

    setListElements(list){
        this.listElement=list;
    }

    setElementGridAlign(gridConfig){
        this.gridConfig = gridConfig;
    }

    setGroupElements(scene){
        this.groupElements = scene.add.group(this.listElements);
    }

    alignListElements(scene){
        gridAlignGroupElements(scene,this.groupElements,this.gridConfig);
    }

    buildContainer(){
        addChildrenToContainer(this,this.groupElements.getChildren());
    }

    displayBox(scene){
        scene.add.existing(this);
    }

    setBoxDisplaySize(width,height){
        this.box.setDisplaySize(width,height);
    }
}