import * as types from '../actions/actionTypes';
import initialState from './initial-state/initialState';


export default function taleReducer (state = initialState.tale_records,action) {
    switch(action.type){
        case types.CREATE_TALE_RECORDS_SUCCESS:
            return state;

        case types.LOAD_TALE_RECORDS_SUCCESS: 
            return state;

        case types.LOAD_TALES_LIST:
            return action.recordList;

        default :
            return state;
    }
};