import { assistantMenuResources, dialogBubbleResources } from "../../../assetsPath";

export const assistantButtonsInformation = {
    HINT_BUTTON : {
        buttonTexture : assistantMenuResources.NEUTRAL_HINT_BTN.key,
        pressedButtonTexture : assistantMenuResources.PRESSED_HINT_BTN.key,
        titleTexture : assistantMenuResources.BTN_TITLE_HINT.key
    },
    GUIDE_BUTTON : {
        buttonTexture : assistantMenuResources.NEUTRAL_GUIDE_BTN.key,
        pressedButtonTexture : assistantMenuResources.PRESSED_GUIDE_BTN.key,
        titleTexture : assistantMenuResources.BTN_TITLE_GUIDE.key
    },
    AUDIO_BUTTON : {
        buttonTexture : assistantMenuResources.NEUTRAL_AUDIO_BTN.key,
        pressedButtonTexture : assistantMenuResources.PRESSED_AUDIO_BTN.key,
        titleTexture : assistantMenuResources.BTN_TITLE_AUDIO.key
    },
    EXIT_BUTTON : {
        buttonTexture : assistantMenuResources.NEUTRAL_EXIT_BTN.key,
        pressedButtonTexture : assistantMenuResources.PRESSED_EXIT_BTN.key,
        titleTexture : assistantMenuResources.BTN_TITLE_EXIT.key
    },
}

export const assistantDialogButtonsInformation = {
    CONTINUE_BUTTON : {
        buttonTexture : dialogBubbleResources.BTN_CONTINUE.key,
        pressedButtonTexture : dialogBubbleResources.PRESSED_BTN_CONTINUE.key
    },

    OMIT_BUTTON : {
        buttonTexture : dialogBubbleResources.BTN_OMIT.key,
        pressedButtonTexture : dialogBubbleResources.PRESSED_BTN_OMIT.key
    }
}