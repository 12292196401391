import { caracMonthsResources } from "../../assetsPath";
import { seedsInfo } from "../seeds/seedsInformation";

export const caracMonthsInfo = {
    JANUARY : {
        id : 1,
        name : 'enero',
        keyName : caracMonthsResources.JANUARY.key,
        seed : seedsInfo.ZAMBO,
        slotPiecesIdList : { festivity : 1, food : 13, signs : 25, dialects : 37} 
    },
    FEBRUARY : {
        id : 2,
        name : 'febrero',
        keyName : caracMonthsResources.FEBRUARY.key,
        seed : seedsInfo.PAPA,
        slotPiecesIdList : { festivity : 2, food : 14, signs : 26, dialects : 38} 
    },
    MARCH : {
        id : 3,
        name : 'marzo',
        keyName : caracMonthsResources.MARCH.key,
        seed : seedsInfo.MAIZ,
        slotPiecesIdList : { festivity : 3, food : 15, signs : 27, dialects : 39} 
    },
    APRIL : {
        id : 4,
        name : 'abril',
        keyName : caracMonthsResources.APRIL.key,
        seed : seedsInfo.SAPALLO,
        slotPiecesIdList : { festivity : 4, food : 16, signs : 28, dialects : 40} 
    },
    MAY : {
        id : 5,
        name : 'mayo',
        keyName : caracMonthsResources.MAY.key,
        seed : seedsInfo.CAMOTE,
        slotPiecesIdList : { festivity : 5, food : 17, signs : 29, dialects : 41}
    },
    JUNE : {
        id : 6,
        name : 'junio',
        keyName : caracMonthsResources.JUNE.key,
        seed : seedsInfo.ARRACACHA,
        slotPiecesIdList : { festivity : 6, food : 18, signs : 30, dialects : 42}
    },
    JULY : {
        id : 7,
        name : 'julio',
        keyName : caracMonthsResources.JULY.key,
        seed : seedsInfo.JICAMA,
        slotPiecesIdList : { festivity : 7, food : 19, signs : 31, dialects : 43}
    },
    AUGUST : {
        id : 8,
        name : 'agosto',
        keyName : caracMonthsResources.AUGUST.key,
        seed : seedsInfo.MELLOCO,
        slotPiecesIdList : { festivity : 8, food : 20, signs : 32, dialects : 44}
    },
    SEPTEMBER : {
        id : 9,
        name : 'septiembre',
        keyName : caracMonthsResources.SEPTEMBER.key,
        seed : seedsInfo.MASHUA,
        slotPiecesIdList : { festivity : 9, food : 21, signs : 33, dialects : 45}
    },
    OCTOBER : {
        id : 10,
        name : 'octubre',
        keyName : caracMonthsResources.OCTOBER.key,
        seed : seedsInfo.OCA,
        slotPiecesIdList : { festivity : 10, food : 22, signs : 34, dialects : 46}
    },
    NOVEMBER : {
        id : 11,
        name : 'noviembre',
        keyName : caracMonthsResources.NOVEMBER.key,
        seed : seedsInfo.CHOCHO,
        slotPiecesIdList : { festivity : 11, food : 23, signs : 35, dialects : 47}
    },
    DECEMBER : {
        id : 12,
        name : 'diciembre',
        keyName : caracMonthsResources.DECEMBER.key,
        seed : seedsInfo.FREJOL,
        slotPiecesIdList : { festivity : 12, food : 24, signs : 36, dialects : 48}
    }
}

export const caracMonthsList = [
    ...Object.values(caracMonthsInfo)
]