import MainMenuBtn from './MainMenuBtn';
import { mainUIResources, mainUISoundResources } from '../../utils/assetsPath';
import Menu from '../baseClasses/Menu';
export default class MainMenu extends Menu{

    constructor(scene,x,y){
        super(scene,x,y,mainUIResources.WINDOW_MAIN_MENU.key);
        this.gridConfig= {height:3,width:1,cellHeight:138,position:6,y:-138};
        this.createButtons(scene);
        scene.add.existing(this);
        this.changeSize(scene.width*0.3,scene.height*0.335);
        this.styleConfig = {backColor:0xae091a,fontColor:'#ffffff'}
        
        this.setActionsToButtons(scene);
    }

    createButtons(scene){
        this.caracBtn = new MainMenuBtn(scene,0,0,mainUIResources.CARAC.key,'CALENDARIO\nAGROFESTIVO');
        this.talesBtn = new MainMenuBtn(scene,0,0,mainUIResources.TALES.key,'RELATOS\nVIVENCIALES');
        this.chakraBtn = new MainMenuBtn(scene,0,0,mainUIResources.CHAKRA.key,'CHAKRA\nCOMUNITARIA');

        this.childrenList=[this.caracBtn,this.talesBtn,this.chakraBtn];
        this.createMenu(scene);
    }

    showCaracScene(scene){
        this.caracBtn.on('pointerdown',()=>{
            scene.sound.play(mainUISoundResources.MAIN_MENU_BUTTON_CLIC.key);
            this.chooseButton(this.caracBtn,this.styleConfig);
            scene.events.emit('showcaracscene');
        });

        this.caracBtn.on('pointerup',()=>{
            this.discardButton();
        });
    }

    showChakraScene(scene){
        this.chakraBtn.on('pointerdown',()=>{
            scene.sound.play(mainUISoundResources.MAIN_MENU_BUTTON_CLIC.key);
            this.chooseButton(this.chakraBtn,this.styleConfig);
            scene.events.emit('showchakrascene');
        });

        this.chakraBtn.on('pointerup',()=>{
            this.discardButton();
        });
    }

    showLibraryScene(scene){
        this.talesBtn.on('pointerdown',()=>{
            scene.sound.play(mainUISoundResources.MAIN_MENU_BUTTON_CLIC.key);
            this.chooseButton(this.talesBtn,this.styleConfig);
            scene.events.emit('showlibraryscene');
        });

        this.talesBtn.on('pointerup',()=>{
            this.discardButton();
        });
    }

    setActionsToButtons(scene){
        this.showCaracScene(scene);
        this.showChakraScene(scene);
        this.showLibraryScene(scene);
    }

}