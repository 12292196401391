import apiClient from "../configService";

export function generateSeedRecords(idPlayer){
    return apiClient.post('/seed_records/player',idPlayer);
}

export function getPlayerSeedRecords(idPlayer){
    return apiClient.get('/seed_records/player?playerId='+idPlayer);
}

export function updatePlayerSeedEarnedStatus( idSeed, params ){
    return apiClient.put('/seed_records/seed/earned/'+idSeed, params);
}

export function updatePlayerSeedUsedStatus( idSeed, params ){
    return apiClient.put('/seed_records/seed/used/'+idSeed, params);
}