import { festivityList } from "../information/festivities/festivityInformation";
import { foodList } from "../information/food/foodInformation";
import { signsGroupList } from "../information/signs/signsInformation";
import { secretsGroupList } from "../information/secrets/secretsInformation";
import { dialectsPuzzlePriceInformation } from "../information/prices/pricesInformation";

export const booksList = {
    BOOK_01 : {
        key : 'BOOK_01',
        title : 'Relato Vivencial - 01',
        month : 'Culla',
        festivity : festivityList.FESTIVITY01,
        food : foodList.FOOD01,
        signs :  signsGroupList.GROUP_01,
        secrets : secretsGroupList.GROUP_01,
        dialect_quiz_detail : {
            quiz_id : 37,
            dialect_price:dialectsPuzzlePriceInformation.PUZZLE_PRICE_DIALECTS_01
        }
        
    },
    BOOK_02 : {
        key : 'BOOK_02',
        title : 'Relato Vivencial - 02',
        month : 'Panchi',
        festivity : festivityList.FESTIVITY02,
        food : foodList.FOOD02,
        signs :  signsGroupList.GROUP_02,
        secrets : secretsGroupList.GROUP_02,
        dialect_quiz_detail : {
            quiz_id : 38,
            dialect_price:dialectsPuzzlePriceInformation.PUZZLE_PRICE_DIALECTS_02
        }
    },
    BOOK_03 : {
        key : 'BOOK_03',
        title : 'Relato Vivencial - 03',
        month : 'Paucar',
        festivity : festivityList.FESTIVITY03,
        food : foodList.FOOD03,
        signs :  signsGroupList.GROUP_03,
        secrets : secretsGroupList.GROUP_03,
        dialect_quiz_detail : {
            quiz_id : 39,
            dialect_price:dialectsPuzzlePriceInformation.PUZZLE_PRICE_DIALECTS_03
        }
    },
    BOOK_04 : {
        key : 'BOOK_04',
        title : 'Relato Vivencial - 04',
        month : 'Airihua',
        festivity : festivityList.FESTIVITY04,
        food : foodList.FOOD04,
        signs :  signsGroupList.GROUP_04,
        secrets : secretsGroupList.GROUP_04,
        dialect_quiz_detail : {
            quiz_id : 40,
            dialect_price:dialectsPuzzlePriceInformation.PUZZLE_PRICE_DIALECTS_04
        }
    },
    BOOK_05 : {
        key : 'BOOK_05',
        title : 'Relato Vivencial - 05',
        month : 'Aimurai',
        festivity : festivityList.FESTIVITY05,
        food : foodList.FOOD05,
        signs :  signsGroupList.GROUP_05,
        secrets : secretsGroupList.GROUP_05,
        dialect_quiz_detail : {
            quiz_id : 41,
            dialect_price:dialectsPuzzlePriceInformation.PUZZLE_PRICE_DIALECTS_05
        }
    },
    BOOK_06 : {
        key : 'BOOK_06',
        title : 'Relato Vivencial - 06',
        month : 'Raimi',
        festivity : festivityList.FESTIVITY06,
        food : foodList.FOOD06,
        signs :  signsGroupList.GROUP_06,
        secrets : secretsGroupList.GROUP_06,
        dialect_quiz_detail : {
            quiz_id : 42,
            dialect_price:dialectsPuzzlePriceInformation.PUZZLE_PRICE_DIALECTS_06
        }
    },
    BOOK_07 : {
        key : 'BOOK_07',
        title : 'Relato Vivencial - 07',
        month : 'Situa',
        festivity : festivityList.FESTIVITY07,
        food : foodList.FOOD07,
        signs :  signsGroupList.GROUP_07,
        secrets : secretsGroupList.GROUP_07,
        dialect_quiz_detail : {
            quiz_id : 43,
            dialect_price:dialectsPuzzlePriceInformation.PUZZLE_PRICE_DIALECTS_07
        }
    },
    BOOK_08 : {
        key : 'BOOK_08',
        title : 'Relato Vivencial - 08',
        month : 'Carhua',
        festivity : festivityList.FESTIVITY08,
        food : foodList.FOOD08,
        signs :  signsGroupList.GROUP_08,
        secrets : secretsGroupList.GROUP_08,
        dialect_quiz_detail : {
            quiz_id : 44,
            dialect_price:dialectsPuzzlePriceInformation.PUZZLE_PRICE_DIALECTS_08
        }
    },
    BOOK_09 : {
        key : 'BOOK_09',
        title : 'Relato Vivencial - 09',
        month : 'Cusqui',
        festivity : festivityList.FESTIVITY09,
        food : foodList.FOOD09,
        signs :  signsGroupList.GROUP_09,
        secrets : secretsGroupList.GROUP_09,
        dialect_quiz_detail : {
            quiz_id : 45,
            dialect_price:dialectsPuzzlePriceInformation.PUZZLE_PRICE_DIALECTS_09
        }
    },
    BOOK_10 : {
        key : 'BOOK_10',
        title : 'Relato Vivencial - 10',
        month : 'Huairu',
        festivity : festivityList.FESTIVITY10,
        food : foodList.FOOD10,
        signs :  signsGroupList.GROUP_10,
        secrets : secretsGroupList.GROUP_10,
        dialect_quiz_detail : {
            quiz_id : 46,
            dialect_price:dialectsPuzzlePriceInformation.PUZZLE_PRICE_DIALECTS_10
        }
    },
    BOOK_11 : {
        key : 'BOOK_11',
        title : 'Relato Vivencial - 11',
        month : 'Sasi',
        festivity : festivityList.FESTIVITY11,
        food : foodList.FOOD11,
        signs :  signsGroupList.GROUP_11,
        secrets : secretsGroupList.GROUP_11,
        dialect_quiz_detail : {
            quiz_id : 47,
            dialect_price:dialectsPuzzlePriceInformation.PUZZLE_PRICE_DIALECTS_11
        }
    },
    BOOK_12 : {
        key : 'BOOK_12',
        title : 'Relato Vivencial - 12',
        month : 'Camai',
        festivity : festivityList.FESTIVITY12,
        food : foodList.FOOD12,
        signs :  signsGroupList.GROUP_12,
        secrets : secretsGroupList.GROUP_12,
        dialect_quiz_detail : {
            quiz_id : 48,
            dialect_price:dialectsPuzzlePriceInformation.PUZZLE_PRICE_DIALECTS_12
        }
        
    }
}