export default {
    tales : [],
    newPlayer : {},
    loggedPlayer : {},
    tale_records : [],
    quiz_records : [],
    seed_records : [],
    piece_records : [],
    tool_records : [],
    chakra_records : [],
    loggingIn : false
}