import { idCardPageResources, mashisResources } from "../../assetsPath";

export const authorsList = {
    AUTHOR_01 : {
        name : 'Laura Santillán',
        community : 'Agato, Otavalo',
        state : 'Imbabura',
        photo : mashisResources.MASHI_01.key
    },
    AUTHOR_02 : {
        name : 'Fernando Chimba',
        community : 'Tañiloma',
        state : 'Cotopaxi',
        photo : mashisResources.MASHI_02.key
    },
    AUTHOR_03 : {
        name : 'Edelina Ortiz',
        community : 'Agato, Otavalo',
        state : 'Imbabura',
        photo : mashisResources.MASHI_03.key
    },
    AUTHOR_04 : {
        name : 'Rosa Gualán',
        community : 'Saraguro',
        state : 'Loja',
        photo : mashisResources.MASHI_04.key
    },
    AUTHOR_05 : {
        name : 'Luis Muñoz',
        community : 'Salasaca',
        state : 'Tungurahua',
        photo : mashisResources.MASHI_05.key
    },
    AUTHOR_06 : {
        name : 'Juan Carlos Ilicachi',
        community : 'Colta-Guamote',
        state : 'Chimborazo',
        photo : mashisResources.MASHI_06.key
    },

}