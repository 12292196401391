import Box from "../baseClasses/Box";
import { chakraResources } from "../../utils/assetsPath";
import { gridAlignGroupElements, addChildrenToContainer } from "../../utils/functions";

export default class ChakraPanel extends Box{
    constructor(scene,x,y){
        super(scene,x,y,chakraResources.CHAKRA_PANEL_BODY.key);
        this.setInitialPanelProperties(scene);
        this.buildPanel();    
        // this.addIcons(scene);

        // this.hideElements();
    }

    getScene(){ return this.scene }
    setScene(scene){ this.scene = scene }

    getPlantName(){ return this.plantName }
    setPlantName( plantName ){ this.plantName = plantName }

    getBox(){ return this.box }
    
    getChakraBarBox(){ return this.chakraBarBox }
    setChakraBarBox(barBox){ this.chakraBarBox = barBox }

    getTimeBarBox(){ return this.timeBarBox }
    setTimeBarBox(barBox){ this.timeBarBox = barBox }

    getStatusBar(){ return this.statusBar }
    setStatusBar(statusBar){ this.statusBar = statusBar }

    getTimeBar(){ return this.timeBar }
    setTimeBar(bar){ this.timeBar = bar }
    
    getPlantIcon(){ return this.plantIcon }
    setPlantIcon(plantIcon){ this.plantIcon = plantIcon }

    getClockIcon(){ return this.clockIcon }
    setClockIcon(clockIcon){ this.clockIcon = clockIcon }

    setProgressStatusBarContainer(container){ this.progressStatusBarContainer = container }
    getProgressStatusBarContainer(){ return this.progressStatusBarContainer }

    getTimeBarContainer(){ return this.timeBarContainer }
    setTimeBarContainer(container){ this.timeBarContainer = container }
    
    getPlantLabelContainer(){ return this.plantLabelContainer }
    setPlantLabelContainer(container){ this.plantLabelContainer = container }
    

    setInitialPanelProperties(scene){
        this.setScene(scene);
        this.setPlantName('PLANTITA');
    }

    buildPanel(){
        let scene = this.getScene();
        this.addBox(scene);
        this.displayBox(scene);
        this.addStatusBar(scene);
        this.createPlantLabelContainer();
        this.addTimeBar(scene);
        // setTimeout(()=>{this.startCountdown()},5000);
    }

    createProgressStatusBarContainer(){
        let scene = this.getScene(),
            barBox = this.getChakraBarBox(),
            statusBar = this.getStatusBar(),
            plantIcon = this.getPlantIcon(),
            container = scene.add.container(0,15,[barBox,statusBar, plantIcon]);
        this.setProgressStatusBarContainer(container);
        this.getProgressStatusBarContainer().setSize((barBox.width),(barBox.height));
        this.add(this.getProgressStatusBarContainer());
    }

    createTimeBarContainer(){
        let scene = this.getScene(),
            timeBarBox = this.getTimeBarBox(),
            timeBar = this.getTimeBar(),
            clockIcon = this.getClockIcon(),
            container = scene.add.container(0,15,[timeBarBox,timeBar, clockIcon]);
        this.setTimeBarContainer(container);
        this.getTimeBarContainer().setSize((timeBarBox.width),(timeBarBox.height));
        this.add(this.getTimeBarContainer());
    }

    

    createPlantLabelContainer(){
        let scene = this.getScene(),
            plantName = this.getPlantName(),
            style = {fontFamily: 'Helvetica', fontSize : '18px', color: '#fef310'},
            text = scene.add.text(0,0,plantName,style),
            container = scene.add.container(0,0,[text]);
        text.setOrigin(0.5,1.2);
        this.setPlantLabelContainer(container);
        this.getPlantLabelContainer().setSize(text.width,text.height);
        this.add(this.getPlantLabelContainer());
    }

    changePlantLabel(){
        let plantLabelContainer = this.getPlantLabelContainer(),
            text = plantLabelContainer.getAll()[0],
            plantName = this.getPlantName();
        text.setText(plantName);
    }

    addStatusBar(scene){
        let barBox = scene.add.image(0,0,chakraResources.PANEL_STATUS_BAR.key);
        this.setChakraBarBox(barBox);
        let statusBar = scene.add.graphics();
        this.setStatusBar(statusBar);
        let plantIcon = scene.add.image(-38,-5,chakraResources.CHAKRA_PLANT_ICON.key);
        plantIcon.setScale(1.4);
        this.setPlantIcon(plantIcon);
        this.createProgressStatusBarContainer();
    }

    addTimeBar(scene){
        let timeBar = scene.add.graphics(),
            timeBarBox = scene.add.image(0,0,chakraResources.PANEL_STATUS_BAR.key);
        this.setTimeBarBox(timeBarBox);
        this.setTimeBar(timeBar);
        let clockIcon = scene.add.image(-35,-2,chakraResources.CHAKRA_CLOCK_ICON.key);
        clockIcon.setScale(1.5);
        this.setClockIcon(clockIcon);
        this.createTimeBarContainer();

        this.hideTimeBar();
    }

    hideTimeBar(){
        let index = this.getAll().indexOf(this.getTimeBarContainer());
        let timeBarContainer = this.getAll()[index];
        timeBarContainer.setVisible(false);
    }

    showTimeBar(){
        let index = this.getAll().indexOf(this.getTimeBarContainer()),
            timeBarContainer = this.getAll()[index];
        this.fillCompleteTimeBar();
        timeBarContainer.setVisible(true);
    }

    hideChakraStatusBar(){
        let index = this.getAll().indexOf(this.getProgressStatusBarContainer()),
            chakraBarContainer = this.getAll()[index];
        chakraBarContainer.setVisible(false);
    }

    showChakraStatusBar(){
        let index = this.getAll().indexOf(this.getProgressStatusBarContainer()),
            chakraBarContainer = this.getAll()[index];
        chakraBarContainer.setVisible(true);
    }

    fillStatusBar(completedNum, total){
        let statusBar = this.getStatusBar(),
            barBox = this.getChakraBarBox(),
            panelBox = this.getBox();
        statusBar.clear();
        statusBar.lineStyle(0,0x000,1).fillStyle(0x7DFF32,1);
        let percentage = completedNum/total, value;

        if(percentage === 1){
            value = barBox.width * percentage - 3;

        }else{
            value = barBox.width*percentage;
        }
        statusBar.fillRoundedRect(-barBox.width/2+2,-barBox.height/2+2,value,panelBox.height*0.12,3);
    }

    fillCompleteTimeBar(){
        let timeBar = this.getTimeBar(),
            barBox = this.getChakraBarBox(),
            panelBox = this.getBox();
        timeBar.clear();
        timeBar.lineStyle(0,0x000,1).fillStyle(0x10F3E9,1);
        timeBar.fillRoundedRect(-barBox.width/2+2,-barBox.height/2+2,(barBox.width-3),panelBox.height*0.12,3);
    }

    fillTimeBar(counter, total){
        let timeBar = this.getTimeBar(),
            barBox = this.getChakraBarBox(),
            panelBox = this.getBox();
        
        timeBar.clear();
        timeBar.lineStyle(0,0x000,1).fillStyle(0x10f3e9,1);
        let percentage = counter/total, value;
        if(percentage>=0){
            value = barBox.width * percentage - 3;
            timeBar.fillRoundedRect(-barBox.width/2+2,-barBox.height/2+2,value,panelBox.height*0.12,3);
        } 
    }

    startCountdown(duration, chakraId){
        let timeLeft = duration, total = duration,
        countdownTimer = setInterval(() => {
            if(timeLeft>0){
                this.fillTimeBar(timeLeft,total);
            }else{
                clearInterval(countdownTimer);
                let scene = this.getScene();
                scene.events.emit('clockCompleted', chakraId);
            }
            timeLeft -= 1;
        },1000);
    }

    addIcons(scene){
        this.compostIcon = scene.add.image(0,0,chakraResources.CHAKRA_COMPOST_ICON.key);
        this.waterIcon = scene.add.image(0,0,chakraResources.CHAKRA_WATER_ICON.key);
        this.clockIcon = scene.add.image(0,0,chakraResources.CHAKRA_CLOCK_ICON.key);

        this.iconsList = [this.compostIcon,this.waterIcon,this.clockIcon];
        let group = scene.add.group(this.iconsList);
        let gridConfig = {width:3,height:1,cellWidth:22,position:6,x:10,y:-12};
        gridAlignGroupElements(scene,group,gridConfig);
        addChildrenToContainer(this,group.getChildren());
    }

    hideElements(){
        this.getAll().forEach((child)=>{
            if(child!==this.getFirst())
            child.setVisible(true);
        });
    }

    hideProgressStatusBarContainer(){
        this.getProgressStatusBarContainer().setVisible(false);
    }


}