//TALES TYPES
export const LOAD_TALES_SUCCESS = 'LOAD_TALES_SUCCESS';

export const CREATE_TALE_RECORDS_SUCCESS = 'CREATE_TALE_RECORDS_SUCCESS';
export const LOAD_TALES_LIST = 'LOAD_TALES_LIST';
export const LOAD_TALE_RECORDS_SUCCESS = 'LOAD_TALE_RECORDS_SUCCESS';

//TOOL TYPES
export const CREATE_TOOL_RECORDS_SUCCESS = 'CREATE_TOOL_RECORDS_SUCCESS';
export const LOAD_TOOL_LIST = 'LOAD_TOOL_LIST';
export const LOAD_TOOL_RECORDS_SUCCESS = 'LOAD_TOOL_RECORDS_SUCCESS';

//QUIZ TYPES
export const CREATE_QUIZ_RECORDS_SUCCESS = 'CREATE_QUIZ_RECORDS_SUCCESS';
export const LOAD_QUIZ_LIST = 'LOAD_QUIZ_LIST';
export const LOAD_QUIZ_RECORDS_SUCCESS = 'LOAD_QUIZ_RECORDS_SUCCESS';

//PUZZLE PIECES TYPES
export const CREATE_PIECE_RECORDS_SUCCESS = 'CREATE_PIECE_RECORDS_SUCCESS';
export const LOAD_PIECE_LIST = 'LOAD_PIECE_LIST';
export const LOAD_PIECE_RECORDS_SUCCESS = 'LOAD_PIECE_RECORDS_SUCCESS';

//SEED TYPES
export const CREATE_SEED_RECORDS_SUCCESS = 'CREATE_SEED_RECORDS_SUCCESS';
export const LOAD_SEED_LIST = 'LOAD_SEED_LIST';
export const LOAD_SEED_RECORDS_SUCCESS = 'LOAD_SEED_RECORDS_SUCCESS';

//CHAKRA TYPES
export const CREATE_CHAKRA_RECORDS_SUCCESS = 'CREATE_CHAKRA_RECORDS_SUCCESS';
export const LOAD_CHAKRA_LIST = 'LOAD_CHAKRA_LIST';
export const LOAD_CHAKRA_RECORDS_SUCCESS = 'LOAD_CHAKRA_RECORDS_SUCCESS';


//PLAYER TYPES

export const LOAD_PLAYER_SUCCESS = 'LOAD_PLAYER_SUCCESS';
export const LOAD_PLAYER_FAILURE = 'LOAD_PLAYER_FAILURE';

export const SET_NEW_PLAYER = 'SET_NEW_PLAYER';
export const LOAD_NEW_PLAYER = 'LOAD_NEW_PLAYER';
export const SIGN_PLAYER_REFUSED = 'SIGN_PLAYER_REFUSED';
export const SIGN_PLAYER_SUCCESS = 'SIGN_PLAYER_SUCCESS';



