import React, {Component} from 'react';
import { Card } from 'react-bootstrap';
import LoginForm from '../forms/loginForm';

const CARD_STYLE = { 
    width : '18rem', position: 'absolute', 
    left: '50%', top: '50%', transform: 'translate(-50%, -50%)'
};

export default class LoginPage extends Component{
    render(){
        return this.addCard();
    }

    addLoginForm(){
        return(<LoginForm/>)
    }

    addCard(){
        return(
        <Card style = {CARD_STYLE}>
            <Card.Body style = {{padding:"20px 20px 10px 60px"}}>
            <Card.Title>¡Página de Logeo!</Card.Title>
            </Card.Body>
            {this.addLoginForm()}
        </Card>)
    }
}