import Page from "../../../baseClasses/page";
import { mainUIResources, mainUISoundResources } from "../../../../utils/assetsPath";
import { gridAlignGroupElements, addChildrenToContainer } from "../../../../utils/functions";
import { scenesKey } from "../../../../utils/gameConstants";
import QuizScene from "../../../../GameScenes/quizScene";

export default class QuizPage extends Page{
    constructor(scene,x,y,quizInfo,priceInfo){
        super(scene,x,y,'TRIVIA\nANCESTRAL');
        this.quizList = null;
        this.earnedStars = null;
        this.setSelectedScene(scene);
        this.setQuizPrice(priceInfo);
        this.setData(quizInfo);
        this.buildPage(scene);

        this.addPageContent(scene);
    }

    setData(data){
        this.setQuizId(data.id);
        this.setQuizTitle(data.title);
        this.setQuizList(data.questions);
        this.setQuizType(data.type);
        this.setQuizStatus(false);
    }

    setSelectedScene(scene){
        this.selectedScene = scene;
    }

    setQuizId(id){
        this.quizId = id;
    }

    setQuizTitle(title){
        this.quizTitle = title;
    }

    setQuizType(type){
        this.quizType = type;
    }

    setQuizList(list){
        this.quizList = list;
    }

    setQuizPrice(info){
        this.quizPrice = info;
    }

    setQuizStatus(status){
        this.quizStatus = status;
    }

    setBoard(scene){
        this.boardContainer = scene.add.container(0,40);
        this.boardContainer.setSize(450,470);
        
        let board = scene.add.graphics();
        board.fillStyle(0x9B8465,1).lineStyle(2,0x000,1)
        .fillRoundedRect(-225,-235,450,470,15).strokeRoundedRect(-225,-235,450,470,15);

        let body = scene.add.graphics();
        body.fillStyle(0xD2C75E,1).lineStyle(2,0x000,1)
        .fillRoundedRect(-215,-225,430,450,15).strokeRoundedRect(-215,-225,430,450,15);

        this.boardContainer.add([board,body]);
        this.add(this.boardContainer);
    }

    setTitle(scene,title){
        let titleContainer = scene.add.container(0,0);
        titleContainer.setSize(420,50);

        let body = scene.add.graphics();
        body.fillStyle(0x1D978E,1).lineStyle(2,0x000,1)
        .fillRoundedRect(-210,-25,420,50,15).strokeRoundedRect(-210,-25,420,50,15);

        let style = {fontFamily:'Helvetica',fontSize:'24px',align:'justify',color:'#000'}
        let text = scene.add.text(0,0,title,style);
        text.setOrigin(0.5);

        titleContainer.add([body,text]);
        return titleContainer;
    }

    setPuntuationBoard(scene){
        this.puntuationContainer = scene.add.container(0,0);
        this.puntuationContainer.setSize(420,150);

        let board = scene.add.graphics();
        board.fillStyle(0x9B8465,1).lineStyle(2,0x000,1)
        .fillRoundedRect(-210,-75,420,150,15).strokeRoundedRect(-210,-75,420,150,15);

        let body = scene.add.graphics();
        body.fillStyle(0xAFCADC,1).lineStyle(2,0x000,1)
        .fillRoundedRect(-205,-70,410,140,15).strokeRoundedRect(-205,-70,410,140,15);

        let style = {fontFamily:'Arial Black',fontSize:'32px',align:'justify',color:'#E92929',stroke:1,strokeThickness:2}
        let puntuationText = scene.add.text(0,0,'CALIFICACIÓN',style);
        puntuationText.setOrigin(0.5,1.5)

        this.puntuationContainer.add([board,body,puntuationText]);

        this.add(this.puntuationContainer);
    }

    setStars(scene){
        this.starsList = [];
        for(let i=0;i<3;i++){
            let star = scene.add.image(0,0,mainUIResources.STAR.key);
            this.starsList.push(star);
            star.setTint(0x000);
        }
        let group = scene.add.group(this.starsList);
        let gridConfig = {width : 3,height : 1, cellWidth : 100, position : 6,x : -100, y : 20};
        gridAlignGroupElements(scene,group,gridConfig);
        addChildrenToContainer(this.puntuationContainer,group.getChildren());
    }

    setButton(scene){
        let buttonContainer = scene.add.container(0,100);
        buttonContainer.setSize(240,170);

        let board = scene.add.graphics();
        board.fillStyle(0x726330,1).lineStyle(2,0x000,1)
        .fillRoundedRect(-120,-85,240,170,15)
        .strokeRoundedRect(-120,-85,240,170,15);

        let body = scene.add.graphics();
        body.fillStyle(0x78D25E,1).lineStyle(2,0x000,1)
        .fillRoundedRect(-115,-80,230,160,15)
        .strokeRoundedRect(-115,-80,230,160,15);

        let style = {fontFamily:'Arial Black',fontSize:'18px',align:'center',color:'#E92929',stroke:1,strokeThickness:1}
        let description = scene.add.text(0,0,'COMENZAR\nLA TRIVIA ANCESTRAL',style);
        description.setOrigin(0.5);

        buttonContainer.add([board,body,description]);

        return buttonContainer;
    }

    addTitle(scene,title){
        this.titleContainer = this.setTitle(scene,title);
        this.boardContainer.add(this.titleContainer);
        this.titleContainer.setPosition(0,-170);
    }

    addPuntuacionBoard(scene){
        this.setPuntuationBoard(scene);
        this.setStars(scene);
    }

    addButton(scene){
        this.button = this.setButton(scene);
        this.button.setPosition(0,130);
        this.boardContainer.add(this.button);
        this.activeButton(this.button,scene);
    }

    activeButton(button,scene){
        button.setInteractive({useHandCursor : true});
        button.on('pointerdown',()=>{
            scene.sound.play(mainUISoundResources.ASSISTANT_MENU_BUTTON_CLIC.key);
            button.list[1].clear();
            button.list[1].fillStyle(0x2d2d2d,1).lineStyle(2,0x000,1)
            .fillRoundedRect(-115,-80,230,160,15)
            .strokeRoundedRect(-115,-80,230,160,15);
        });

        button.on('pointerup',()=>{
            button.list[1].clear();
            button.list[1].fillStyle(0x78D25E,1).lineStyle(2,0x000,1)
            .fillRoundedRect(-115,-80,230,160,15)
            .strokeRoundedRect(-115,-80,230,160,15);
            this.launchQuiz(scene);
        });

    }

    createQuizFile(){
        return {
            id : this.quizId,
            title : this.quizTitle,
            type : this.quizType,
            questions : this.quizList,
            price : this.quizPrice,
            isCompleted : this.quizStatus
        }
    }

    launchQuiz(scene){
        scene.emitToMainUI('freeze_buttons');
        localStorage.setItem('selectedQuiz',JSON.stringify(this.createQuizFile()));
        scene.scene.add(scenesKey.QUIZ_SCENE,QuizScene);
        scene.scene.switch(scenesKey.QUIZ_SCENE);  
    }
    
    addPageContent(scene){
        this.setBoard(scene);
        this.addTitle(scene,this.quizTitle);
        this.addPuntuacionBoard(scene);
        this.addButton(scene);
        this.checkQuizStatus();
    }

    unlockStar(star){
        star.clearTint();
    }

    checkQuizStatus(){
        if( localStorage.hasOwnProperty('loggedPlayer') &&
            localStorage.hasOwnProperty('quizRecords')){
            let quizRecords = JSON.parse(localStorage.getItem('quizRecords'));
            let quizRecord = quizRecords.find(record =>{return record.quiz_id === this.quizId});
            if(quizRecord){
                let status = (quizRecord.completed===1) ? true : false;
                let score = quizRecord.score;
                this.setQuizStatus(status);
                this.setStarsScore(score);
            }
        }
    }

    setStarsScore(score){
        this.starsList.forEach((star)=>{
            star.setTint(0x000);
        })
        let number = 0;
        if( score < 9 ){
            number = 0;
        }else if( score >= 9 && score < 18){
            number = 1;
        }else if( score >= 18 && score < 30 ){
            number = 2;
        }else if( score === 30 ){
            number = 3;
            this.setQuizStatus(true);
        }

        for(let i = 0;i<number;i++){
                let star = this.starsList[i];
                this.unlockStar(star);
        }
    }

    showStars(){
        this.starsList.forEach((star)=>{
            star.clearTint(); 
        })
    }





}