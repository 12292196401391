import Phaser from 'phaser';
import { welcomeSceneResources, introAssets, introResources } from '../../../utils/assetsPath';

export default class MenuScene extends Phaser.Scene{

    preload(){
        introResources.forEach((asset)=>{
            this.load.image(asset.key,asset.path);
        });
        this.setGameWidth(this.scale.width);
        this.setGameHeight(this.scale.height);
    }
    
    setGameWidth(width){ this.gameWidth = width }
    getGameWidth(){ return this.gameWidth }

    setGameHeight(height){ this.gameHeight = height }
    getGameHeight(){ return this.gameHeight }

    setMenuXPosition(position){ this.menuXPosition = position }
    getMenuXPosition(){ return this.menuXPosition }

    setMenuYPosition(position){ this.menuYPosition = position }
    getMenuYPosition(){ return this.menuYPosition }

    setMenuXScale(scale){ this.menuXScale = scale }
    getMenuXScale(){ return this.menuXScale }

    setMenuYScale(scale){ this.menuYScale = scale }
    getMenuYScale(){ return this.menuYScale }

    setBackground(background){ this.background = background }
    getBackground(){ return this.background }

    setGameTitle(title){ this.gameTitle = title }
    getGameTitle(){ return this.gameTitle }

    setGameCharacter(character){ this.gameCharacter = character }
    getGameCharacter(){ return this.gameCharacter }

    setOptionsMenu(menu){ this.optionsMenu = menu }
    getOptionsMenu(){  return this.optionsMenu}

    addBackground(){
        let gameWidth = this.getGameWidth(), gameHeight = this.getGameHeight();
        let background = this.add.image(gameWidth / 2, gameHeight / 2,introAssets.BACKGROUND.key);
        this.setBackground(background);
        this.getBackground().setDisplaySize(gameWidth,gameHeight);
        this.zoomInBackground();
    }

    zoomInBackground(){
        let background = this.getBackground();
        this.tweens.add({
            targets:background,
            displayWidth:{
                from:background.displayWidth, 
                to: background.displayWidth*1.1
            },
            displayHeight:{
                from:background.displayHeight,
                to:background.displayHeight*1.1
            }, 
            duration:10000, 
            yoyo:true, loop:-1, 
        });
    }

    addGameTitle(){
        let gameWidth = this.getGameWidth(), gameHeight = this.getGameHeight();
        let gameTitle = this.add.image(gameWidth / 2, gameHeight / 2, welcomeSceneResources.GAME_LOGO.key);
        this.setGameTitle(gameTitle);
        let myTitle = this.getGameTitle();
        myTitle.setOrigin(0.5,1);
        myTitle.setDisplaySize(gameWidth*0.6,gameHeight*0.3);
    }

    addGameCharacter(){
        let gameWidth = this.getGameWidth(), gameHeight = this.getGameHeight();
        let gameCharacter = this.add.image(gameWidth / 2, gameHeight / 2, welcomeSceneResources.GAME_CHARACTER.key);
        this.setGameCharacter(gameCharacter);
        let myCharacter = this.getGameCharacter();
        myCharacter.setOrigin(2.2,0.4);
        myCharacter.setDisplaySize(gameWidth*0.2,gameHeight*0.8);
    }

    createTheScene(){
        this.addBackground();
        this.addGameTitle();
        this.addGameCharacter();
    }

    modifyMenuOptionsAttributes(){
        let xPosition = this.getMenuXPosition(), yPosition = this.getMenuYPosition(),
        xScale = this.getMenuXScale(), yScale = this.getMenuYScale();
        this.getOptionsMenu().setScale(xScale,yScale);
        this.getOptionsMenu().setPosition(xPosition,yPosition);
    }

}