import ModuleScene from "./ModuleScene";
import ResultWindow from "../GameObjects/commonObjects/tale-pages/game-objects/resultWindow";
import { scenesKey } from "../utils/gameConstants";
import RedPiece from "../GameObjects/commonObjects/RedPiece";
import YellowPiece from "../GameObjects/commonObjects/YellowPiece";
import GreenPiece from "../GameObjects/commonObjects/GreenPiece";
import BluePiece from "../GameObjects/commonObjects/BluePiece";
import { changeQuizRecord } from "../utils/functions/save-record-functions";


export default class ResultScene extends ModuleScene{
    constructor(){
        super({key:scenesKey.RESULT_SCENE});
        this.resultsData = null;
    }

    setResultData(data){
        this.resultsData = data;
    }

    setPrice(){
        this.priceInfo = this.resultsData.price;
    }

    init(data){
        this.scene.remove(scenesKey.QUIZ_SCENE);
        this.setResultData(data);
    }

    create(){
        this.createWindow('RESULTADOS DE LA TRIVIA CULTURAL');
        this.displaySceneWindow();

        this.addResultWindow();
        this.listenIncomingEvents();
    }

    addResultWindow(){
        this.resultWindow = new ResultWindow(this,0,0,this.resultsData);
        this.windowScene.addElement(this.resultWindow);
        this.resultWindow.setPosition(0,this.windowScene.height*0.083);
    }

    listenIncomingEvents(){
        this.events.on('result_window_done',(results)=>{
            this.checkScore(results);
        })
    }

    addConfirmationModal(results){
        this.windowScene.hideRibbon();
        this.showConfirmationModal(1,results);
    }

    checkScore(results){
        let score = results.score,
            isCompleted = results.isCompleted;   
        
        if(score<30 && isCompleted===false){
            this.emitToMainUI('ask_new_quiz_try');
            this.addConfirmationModal(results);
        }

        if(score===30 && isCompleted===false){
            changeQuizRecord(results);
            this.setPrice();
            let newPiece = this.createPuzzlePiece(this.priceInfo);
            this.windowScene.hideRibbon();
            this.resultWindow.destroy();

            let data = {
                results : results,
                piecedata : this.priceInfo,
                piece : newPiece
            }
            // this.emitToMainUI('new_puzzle_earned');
            this.showRewardModal(3,data);  
        }

        if(score === 30 && isCompleted === true){
            this.quitScene();
        }

        if(score< 30 && isCompleted===true){
            this.quitScene();
        }
    }

    createPuzzlePiece(pieceInfo){
        let piece = null,
            pieceType = pieceInfo.type;
        switch(pieceType){
            case 'FESTIVIDADES' :
                piece = new RedPiece(this,0,0,pieceInfo);    
            break;
            case 'COMIDAS' :
                piece = new YellowPiece(this,0,0,pieceInfo);    
                break;
            case 'SEÑAS-SECRETOS' :
                piece = new GreenPiece(this,0,0,pieceInfo);    
                break;
            case 'DIALECTOS':
                piece = new BluePiece(this,0,0,pieceInfo);    
                break;
            default :
                break;
        }
        return piece;
    }

    quitScene(){
        let selectedTaleScene = this.scene.get(localStorage.getItem('selectedTale'));
        selectedTaleScene.events.emit('result_modal_closed');
        this.scene.switch(localStorage.getItem('selectedTale'));
        let mainMenuUI = this.scene.get(scenesKey.MAINUI_SCENE);
        mainMenuUI.activeButtons();
        localStorage.removeItem('selectedQuiz');
    }




}