import Phaser from 'phaser';
import { libraryResources } from '../../utils/assetsPath';
import { talesTitle, mainTalesList } from '../../utils/information/tales/talesInformation';


export default class Tale extends Phaser.GameObjects.Container{
    constructor(scene,x,y,record, taleType){
        super(scene,x,y);
        this.createTale(record, taleType, scene);
    }


    createTale(record, taleType, scene){
        switch(taleType){
            case 'guide':
                this.setGuideData(record);
                break;
            case 'tale':
                this.setTaleData(record);
                break;
            default :
                break;  
        }
        this.buildTale(scene);
    }

    setTaleData(record){
        let taleId = record.tales_id,
            status = record.status,
            data = mainTalesList.find((tale)=>{
                return tale.id === taleId;
            });
        this.setId(taleId);
        this.setMainKey(data.key);
        this.setMonth(data.month);
        this.setTitleData(data.title);
        this.setSeedData(data.seed);
        this.setBookData(data.book);
        this.setStatusList();
        this.setStatus(status);
    }

    setGuideData(record){
        this.setTitleData(record.title);
        this.setSeedData(record.seed);
        this.setStatusList();
        this.setStatus('blocked');
    }

    changeGuideStatus(status){
        this.setStatus(status);
        this.checkStatus();
    }

    setId(id){
        this.id = id;
    }

    getId(){ return this.id }

    setMainKey(key){
        this.mainKey = key;
    }

    setMonth(month){
        this.month = month;
    }

    setTitleData(titleData){
        this.titleData = titleData;
    }

    setBookData(bookData){
        this.bookData = bookData;
    }

    setSeedData(seedData){
        this.seedData = seedData;
    }

    setStatus(status){
        this.status = status;
    }

    getStatus(){ return this.status }

    setStatusList(){
        this.statusList = ['blocked','new','seen','completed'];
    }

    checkStatus(){
        switch(this.status){
            case 'blocked':
                this.changeTitleLabel();
                this.changeStatusIcon(true,libraryResources.TALE_BLOCKED.key,0.8,40,-55);
                break;
            case 'new':
                this.changeTitleLabel();
                this.changeStatusIcon(true,libraryResources.TALE_NEW.key,1,50,-68);
                break;
            case 'completed':
                this.changeTitleLabel();
                this.changeStatusIcon(true,libraryResources.TALE_COMPLETED.key,0.8,48,-50);
                break;
            case 'seen':
                this.changeTitleLabel();
                this.changeStatusIcon(false);
                break;
            default:
                break;
        }
    }

    changeStatusIcon(isVisible, texture,scale,x,y){
        this.statusIcon.setVisible(isVisible);
        if(isVisible===true){
            this.statusIcon.setTexture(texture)
            .setScale(scale,scale)
            .setPosition(x,y);
        }
    }

    changeTitleLabel(){
        if(this.status==='blocked'){
            this.titleLabel.setTexture(talesTitle.BLOCKED.label);
        }else{
            this.titleLabel.setTexture(this.titleData.label);
        }   
    }

    changeStatus(newStatus){
        this.setStatus(newStatus);
        this.checkStatus();
    }

    setStatusIcon(scene){
        this.statusIcon = scene.add.image(50,-50,null);
        this.add(this.statusIcon);
    }

    setBody(scene,texture){
        this.body = scene.add.image(0,0,texture); 
        this.add(this.body).setSize(this.body.width,this.body.height);
    }

    setBookImage(scene,texture){
        this.bookImage = scene.add.image(0,-20,texture);
        this.bookImage.setScale(1.1,1.25);
        this.add(this.bookImage);
    }

    setSeedIcon(scene,texture){
        this.seedIcon = scene.add.image(-8,-15,texture);
        this.add(this.seedIcon);
    }

    setTitleLabel(scene,texture){
        this.titleLabel = scene.add.image(0,75,texture);
        this.add(this.titleLabel);
    }

    buildTale(scene){
        this.setBody(scene,libraryResources.TALE_BODY.key);
        this.setBookImage(scene,libraryResources.TALE_BOOK.key);
        this.setTitleLabel(scene,this.titleData);
        if(this.seedData!==null) this.setSeedIcon(scene,this.seedData.iconKey);
        this.setStatusIcon(scene);
        // this.setStatus(this.statusList[0]);
        this.checkStatus();
    }

    isBlocked(){
        let flag;
        (this.status===this.statusList[0])? flag=true:flag=false;
        return flag;
    }

    pumpCircle(scene){
        let newTaleAnimation = scene.tweens.add({
            targets:this.statusIcon,
            props:{
                scaleX:{from : 0.8, to : 1.1},
                scaleY:{from : 0.8, to : 1.1},
            },
            duration:600,
            yoyo:true,
            repeat:-1,
            pause : true
        });
        return newTaleAnimation;
    }

    stopPumpCircle(tween){
        tween.stop()
    }

    pumpPrice(scene){
        let newTaleAnimation = scene.tweens.add({
            targets:this.statusIcon,
            props:{
                scaleX:{from : 0.8, to : 1.1},
                scaleY:{from : 0.8, to : 1.1},
            },
            duration:600,
            yoyo:true,
            paused:true
        });
        return newTaleAnimation;
    }

    shakePadlock(scene){
        let shakeLeft = scene.tweens.add({
            targets:this.statusIcon,
            props:{
                    x:{from :40,to:30},
            },
            duration:50,
            ease:'Linear',
            yoyo:true,
            onComplete:()=>{
                scene.tweens.add({
                    targets:this.statusIcon,
                    props:{ x:{ from : 40, to : 55}},
                    duration : 50,
                    ease : 'Linear',
                    yoyo : true});
            }
        });
        
        return shakeLeft;
    }

    addAnimations(scene){
        this.pumpAnim = this.pumpCircle(scene);
    }

    stopAnimation(scene,animation){
       let i = scene._tweens.indexOf(animation);
       if(i!==-1){
           scene._tweens[i].pendingDelete = true;
       }else{
           let j = scene._add.indexOf(animation);
           if(j!==-1){
               scene._add[j].pendingDelete = true;
           }
       }
    }




}