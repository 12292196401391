import Chapter from "../../../baseClasses/chapter";

export default class DialectChapter extends Chapter{
    
    constructor(scene,data, monthName){
        super(data);
        this.setMonth(monthName);
        this.addDialectChapter(scene);
    }

    setBaseData(data){
        this.setTitle(data.title);
        this.setCoverPhoto(data.cover);
        this.setType(data.type);
        this.setDialectsInformation(data.list);
        this.setPuzzlePieceInfo(data.price);
        this.setQuizId(data.quiz_id);
    }

    setDialectsInformation(info){
        this.dialectsList = info;
        this.spanishWordsList = [];
        this.kichwaWordsList = [];
        this.dialectsList.forEach((record)=>{
            this.spanishWordsList.push(record.spanishWord);
            this.kichwaWordsList.push(record.kichwaWord);
        });
    }

    addDialectChapter(scene){
        this.setIntroPage(scene,this.type);
        this.setDialectPages(scene,this.dialectsList);
        this.generateQuiz();
        this.setQuiz(this.quizInfo);
        this.addTypeToQuizInfo();
        this.setQuizPage(scene,this.quizInfo,this.puzzlePieceInfo);
    }

    generateQuiz(){
        this.questionsList = [];
        let count = 1;
        do{
            let randomNumber = Math.ceil(Math.random()*2),
                word,json,rndNumber;
            switch(randomNumber){
                case 1:
                    rndNumber = Math.ceil(Math.random()*this.spanishWordsList.length);
                    word = this.spanishWordsList[rndNumber];   
                break;

                case 2:
                    rndNumber = Math.ceil(Math.random()*this.kichwaWordsList.length);
                    word = this.kichwaWordsList[rndNumber];
                break;
                default:
                break;
            }
            json = this.generateQuestion(word);
            if(json.question!==undefined&&json.answer!==undefined&&json.options!==undefined){
                json.questionNumber = count;
                count++;
                if(!this.questionsList.includes(json)){
                    this.questionsList.push(json);
                }
            }
        }while(this.questionsList.length<6);

        this.quizInfo = {
            id : this.quizId,
            title : 'Trivia sobre los dialectos del relato',
            questions : this.questionsList,
            type: this.type
        }
    }

    generateQuestion(word){
        let question, record, options,answer;
        if(this.spanishWordsList.includes(word)){
            record = this.dialectsList.find((dialect)=>{
                return dialect.spanishWord === word;
            });
            question = `¿Qué significa la palabra "${word}" en kichwa?`;
            answer = record.kichwaWord;
            options = this.generateOptions(answer,this.kichwaWordsList);      
        }
        if(this.kichwaWordsList.includes(word)){
            record = this.dialectsList.find((dialect)=>{
                return dialect.kichwaWord === word;
            });
            question = `¿Qué significa la palabra "${word}" en español?`;
            answer = record.spanishWord;
            options = this.generateOptions(answer,this.spanishWordsList);
        }
        return {
            question,
            answer,
            options
        }
    }

    generateOptions(answerOption, wordsList){
        let tempList = [],
            optionsList = [];
        do{
            let randomNumber = Math.ceil(Math.random()*wordsList.length),
                kWord = wordsList[randomNumber];
            if(tempList.includes(kWord)===false&&kWord!==answerOption&&kWord!==undefined){
                tempList.push(kWord);
            }
        }while(tempList.length<3);
        tempList.push(answerOption);
        optionsList = this.shuffleList(tempList);
        return optionsList;
    }

    shuffleList(list){
        return list.sort(() => Math.random() - 0.5);
    }
}