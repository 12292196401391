export const creditsLines = [
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'JUEGO FUNCARAC',
    '',
    '',
    '',
    'Creado por : ............................. Andrés Salazar',
    '',
    'Desarrollado por : ................... Andrés Salazar',
    '',
    'Ilustraciones : ......................... Andrés Salazar',
    '',
    '',
    'El proyecto Funcarac es un Juego Serio de Aprendizaje social y cultural para enseñar los saberes tradicionales más importantes de las comunidades agrícolas indígenas del Ecuador.',
    '',
    '',
    'Es una herramienta educativa que rescata, recopila y difunde el conocimiento ancestral de las diferentes zonas de la serranía ecuatoriana.',
    '',
    '',
    'Este proyecto fue creado por Andrés Fernando Salazar Duque, estudiante de la Facultad de Ingeniería de Sistemas de la Escuela Politécnica Nacional del Ecuador bajo la dirección de Dr. Marco Santórum y MSc. Mayra Carrión.',
    '',
    '',
    // 'Creado por: --------------------- Andrés Salazar',
    // 'Desarrollado por : ---------- Andrés Salazar',
    // 'Ilustraciones: -------------- Andrés Salazar',
    '',
    '',
]

// export const creditsLines = [
//     'JUEGO FUNCARAC',
//     'Creado por : ............................. Andrés Salazar',
//     'Desarrollado por : ................... Andrés Salazar',
//     'Ilustraciones : ......................... Andrés Salazar',
//     'El proyecto Funcarac es un Juego Serio de Aprendizaje social y cultural para enseñar los saberes tradicionales más importantes de las comunidades agrícolas indígenas del Ecuador.',
//     'Es una herramienta educativa que rescata, recopila y difunde el conocimiento ancestral de las diferentes zonas de la serranía ecuatoriana.',
//     'Este proyecto fue creado por Andrés Fernando Salazar Duque, estudiante de la Facultad de Ingeniería de Sistemas de la Escuela Politécnica Nacional del Ecuador bajo la dirección de Dr. Marco Santórum y MSc. Mayra Carrión.',
// ]