import { chakraResources, chakraBubbleResources, chakraSoundResources } from "../../assetsPath";
import { cursorsList } from "../../definitions";

export const toolsInformation = {
    COMPOST_TOOL : {
        id : 1,
        name : 'ABONO',
        icon : chakraResources.TOOL_COMPOST.key,
        type : 'compostTool',
        cursor : cursorsList.COMPOST_CURSOR,
        bubbleIcon : chakraBubbleResources.BUBBLE_ICON_COMPOST.key,
        sfx : chakraSoundResources.COMPOST_EFFECT.key
    },
    HOE_TOOL : {
        id : 2,
        name: 'AZADÓN',
        icon : chakraResources.TOOL_HOE.key,
        type: 'hoeTool',
        cursor: cursorsList.HOE_CURSOR,
        bubbleIcon : chakraBubbleResources.BUBBLE_ICON_HOE.key,
        sfx : chakraSoundResources.HOE_EFFECT.key
    },
    SCISSORS_TOOL : {
        id : 3,
        name: 'TIJERAS',
        icon : chakraResources.TOOL_SCISSORS.key,
        type: 'scissorsTool',
        cursor: cursorsList.SCISSORS_CURSOR,
        bubbleIcon : chakraBubbleResources.BUBBLE_ICON_SCISSORS.key,
        sfx : chakraSoundResources.SCISSORS_EFFECT_02.key
    },
    SHOVEL_TOOL : {
        id : 4,
        name: 'PALA',
        icon : chakraResources.TOOL_SHOVEL.key,
        type: 'shovelTool',
        cursor: cursorsList.SHOVEL_CURSOR,
        bubbleIcon : chakraBubbleResources.BUBBLE_ICON_SHOVEL.key,
        sfx : chakraSoundResources.SHOVEL_EFFECT.key
    },
    SPRAY_TOOL : {
        id : 5,
        name: 'PESTICIDA',
        icon : chakraResources.TOOL_SPRAY.key,
        type: 'sprayTool',
        cursor: cursorsList.SPRAY_CURSOR,
        bubbleIcon : chakraBubbleResources.BUBBLE_ICON_SPRAY.key,
        sfx : chakraSoundResources.SPRAY_EFFECT.key
    },
    WATER_TOOL : {
        id : 6,
        name: 'AGUA',
        icon : chakraResources.TOOL_WATER.key,
        type: 'waterTool',
        cursor: cursorsList.WATER_CURSOR,
        bubbleIcon : chakraBubbleResources.BUBBLE_ICON_WATER.key,
        sfx : chakraSoundResources.WATER_EFFECT.key
    }
}

export const toolsList = [
    ...Object.values(toolsInformation)
]