import Phaser from 'phaser'
import { textStyles } from './gameConstants';
import { ROTATION_VALUE, STEP_VALUE } from './definitions';

export const sqrResize = (scene,currentWidth,currentHeight) =>{
    let sceneWidth = getSceneMaxWidth(scene);
    let width = sceneWidth * currentWidth / scene.defaultWidth;
    let height = currentHeight * width / currentWidth;
    return {width, height};
}

export const getSceneMaxWidth = (scene) =>{
    if((scene.width / scene.height) <= (16 / 9)){
        return scene.width;
    }else{
        return (scene.height * 16 / 9);
    }
}

export const linearResize = (scene, size) => size * getSceneMaxWidth(scene) / scene.defaultWidth;

export const vGrid = (scene, x, y, children, padding) =>
    grid(scene, x, y, children, padding, 'setY', 'displayHeight');

export const hGrid = (scene, x, y, children, padding) =>
    grid(scene, x, y, children, padding, 'setX', 'displayWidth');

const grid = (scene, x, y, children, padding, setCoord, displaySize) => {
    children.forEach((child, index) => child[setCoord](index * (child[displaySize] + linearResize(scene, padding))));
    return new Phaser.GameObjects.Container(scene, x, y, children)
};

export const getTextStyle = (textStyle)=>{
    return textStyles.textStyle;
}

export const Image = Phaser.GameObjects.Image;
export const Text = Phaser.GameObjects.Text;

export const createGroupElements = (scene,elementList)=>{
    return scene.add.group(elementList);
}

export const gridAlignGroupElements = (scene,groupElements,gridConfig)=>{
    Phaser.Actions.GridAlign(groupElements.getChildren(),gridConfig);
}

export const ringShapeAlign = (x,y,groupElements,radius)=>{
    Phaser.Actions.SetRotation(groupElements.getChildren(),ROTATION_VALUE,STEP_VALUE);
    let ringShape = new Phaser.Geom.Circle(x,y,radius);
    Phaser.Actions.PlaceOnCircle(groupElements.getChildren(),ringShape);
}

export const addChildrenToContainer = (container,childrenList)=>{
    childrenList.forEach((child)=>{
        container.add(child);
    })
}

export const changeBtnAppereance = ( button, fillColor, textColor )=>{
    let body = button.getAll()[1],
        text = button.getAll()[2],
        width = button.width,
        height = button.height,
        radius = 20;
            
    body.clear();
    body.fillStyle(fillColor,1).lineStyle(2,0x000,1);
    body.fillRoundedRect(-width/2+(radius/2),-height/2+(radius/2),width-(radius),height-(radius),radius)
    .strokeRoundedRect(-width/2+(radius/2),-height/2+(radius/2),width-(radius),height-(radius),radius);

    text.setColor(textColor);
}

