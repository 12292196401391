import Phaser from 'phaser';
import { introAssets, welcomeSceneResources } from '../utils/assetsPath';

export class BaseScene extends Phaser.Scene{
    preload(){
        let {width,height} = this.game.canvas;
        this.width = width;
        this.height = height;

        this.setGameWidth(this.scale.width);
        this.setGameHeight(this.scale.height);
    }

    getGameWidth(){ return this.gameWidth }
    setGameWidth(gameWidth){ this.gameWidth = gameWidth }

    getGameHeight(){ return this.gameHeight }
    setGameHeight(gameHeight){ this.gameHeight = gameHeight }

    setBackground(background){ this.background = background }
    getBackground(){ return this.background }

    setGameTitle(title){ this.gameTitle = title }
    getGameTitle(){ return this.gameTitle }

    createBackground(){
        let gameWidth = this.getGameWidth(), gameHeight = this.getGameHeight(), 
        background = this.add.image(gameWidth/2,gameHeight/2,introAssets.BACKGROUND.key);
        this.setBackground(background);
        this.getBackground().setDisplaySize(gameWidth,gameHeight);
        this.zoomInBackground();
    }

    zoomInBackground(){
        let background = this.getBackground();
        this.tweens.add({
            targets:background,
            displayWidth:{
                from:background.displayWidth, 
                to: background.displayWidth*1.1
            },
            displayHeight:{
                from:background.displayHeight,
                to:background.displayHeight*1.1
            }, 
            duration:10000, 
            yoyo:true, loop:-1, 
        });
    }

    createGameTitle(){
        let gameWidth = this.getGameWidth(),gameHeight = this.getGameHeight();
        let gameTitle = this.add.image(gameWidth/2,gameHeight/2,welcomeSceneResources.GAME_LOGO.key);
        this.setGameTitle(gameTitle);
        this.getGameTitle().setOrigin(0.5,1);
        this.getGameTitle().setDisplaySize(gameWidth*0.6,gameHeight*0.3);
    }

}