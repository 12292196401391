import Phaser from 'phaser';
// import GameOptionButton from '../../GameObjects/commonObjects/init-scene-components/welcome-page-components/gameOptionButton';
import { scenesKey } from '../../../utils/gameConstants';
import GameOptionButton from '../../../GameObjects/commonObjects/init-scene-components/welcome-page-components/gameOptionButton';
import { optionButtonsInformation } from '../../../utils/information/buttons/welcome-menu-butn/buttonsInformation';
import { introAssets } from '../../../utils/assetsPath';


export default class ContentScene extends Phaser.Scene{
    preload(){
        this.setGameWidth(this.scale.width);
        this.setGameHeight(this.scale.height);
    }
    
    setGameWidth(gameWidth){ this.gameWidth = gameWidth }
    getGameWidth(){ return this.gameWidth }

    setGameHeight(gameHeight){ this.gameHeight = gameHeight }
    getGameHeight(){ return this.gameHeight }
    
    setBackGround(background){ this.background = background }
    getBackGround(){ return this.background }

    setReturnOption(option){ this.returnOption = option }
    getReturnOption(){ return this.returnOption }

    addBackground(){
        let gameWidth = this.getGameWidth(), gameHeight = this.getGameHeight();
        let background = this.add.image(gameWidth / 2, gameHeight / 2,introAssets.BACKGROUND.key);
        this.setBackGround(background);
        this.getBackGround().setDisplaySize(gameWidth,gameHeight);
        this.zoomInBackground();
    }

    zoomInBackground(){
        this.tweens.add({
            targets:this.background,
            displayWidth:{
                from:this.background.displayWidth, 
                to: this.background.displayWidth*1.1
            },
            displayHeight:{
                from:this.background.displayHeight,
                to:this.background.displayHeight*1.1
            }, 
            duration:10000, 
            yoyo:true, loop:-1, 
        });
    }

    createReturnOption(){
        let gameWidth = this.getGameWidth(), gameHeight = this.getGameHeight();
        let buttonContainer = this.add.container(),
        returnOption = new GameOptionButton(this,0,0,optionButtonsInformation.OPTION_RETURN),
        xPosition = gameWidth*0.05, yPosition = gameHeight*0.1,
        xScale = gameWidth*0.0004, yScale = gameHeight*0.0008;
        buttonContainer.add(returnOption);
        this.setReturnOption(buttonContainer);
        this.add.existing(this.getReturnOption());
        this.getReturnOption().setPosition(xPosition,yPosition);
        this.getReturnOption().setScale(xScale,yScale);
    }

    returnToIntroScene(){
      let buttonContainer = this.getReturnOption(),
        optionButton = buttonContainer.getAll()[0];
        //TODO change to gameMenuScene
        optionButton.on('pointerup',()=>{ this.scene.switch(scenesKey.GAME_MENU_SCENE) });
    }

    buildScene(){
        this.addBackground();
        this.createReturnOption();
        this.returnToIntroScene();
    }
}