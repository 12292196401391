import Phaser from 'phaser'
import { mainUIResources } from '../../utils/assetsPath';
import { numbers } from '../../utils/definitions';

export default class UnitsLabel extends Phaser.GameObjects.Container{
    constructor(scene,x,y){
        super(scene,x,y);

        this.body = null;
        this.leftValue = null;
        this.rightValue = null;
        this.counter = 0;

        this.buildLabel(scene);
    }

    addBody(scene){
        this.body = scene.add.image(0,0,mainUIResources.BODY_ICON_BTN_ASSISANT_MENU.key);
        this.body.setScale(2,2);
        this.add(this.body).setSize(this.body.width,this.body.height);
    }

    addNumber(scene){
        let sNumber = this.counter.toString().split('');

        if(sNumber.length===1){
            this.leftValue = scene.add.image(0,0,numbers[0].key);
            this.rightValue = scene.add.image(0,0,numbers[sNumber[0]].key);        

        }else{
            this.leftValue = scene.add.image(0,0,numbers[sNumber[0]].key);
            this.rightValue = scene.add.image(0,0,numbers[sNumber[1]].key);
        }
        this.leftValue.setScale(0.4).setPosition(-22.5,0);
        this.rightValue.setScale(0.4).setPosition(22.5,0);

        this.add([this.leftValue,this.rightValue]);
    }

    buildLabel(scene){
        this.addBody(scene);
        this.addNumber(scene);
        scene.add.existing(this);
    }

    addCounter(scene){
        if(this.counter<=98){
            this.counter++;
            this.remove([this.leftValue,this.rightValue]);
            this.addNumber(scene)
        }
    }

    substractCounter(scene){
        if(this.counter>=0){
            this.counter--;
            this.remove([this.leftValue,this.rightValue]);
            this.addNumber(scene);
        }
    }

    setCounter(value,scene){
        if(this.counter<=98){
            this.counter+= value;
            this.remove([this.leftValue,this.rightValue]);
            this.addNumber(scene)
        }
    }

    getCounter(){
        return this.counter;
    }


}