import ModuleScene from "./ModuleScene";
import { scenesKey } from "../utils/gameConstants";
import Book from "../GameObjects/baseClasses/book";
import { selectedTaleDialogsList } from "../utils/information/assistant/assistantDialogs";
import { mainUIResources } from "../utils/assetsPath";
import { booksList } from "../utils/books/booksInformation";

export default class SelectedTaleScene extends ModuleScene{
    constructor(taleKey){
        super({key:taleKey});
    }

    init(data){
        this.scene.sleep(scenesKey.LIBRARY_SCENE);
        this.setBookData(data);
    }

    setBookData(data){
        this.bookData = data;
    }

    showContent(){
        this.createWindow(`RELATO VIVENCIAL SELECCIONADO\n${this.bookData.title}`);
        this.displaySceneWindow();
        this.addBook();
    }


    create(){
        this.listenUpcomingEvents();
        this.showContent();

    }

    createMockReward(){
        setTimeout(()=>{
            let data = this.book.festivityInfo.price
            let piece = this.createPuzzlePiece(data)
            let resultsData = {
                results : 0,
                pieceData : data,
                piece
            }
            this.showRewardModal(3,resultsData);
        },2000);
    }

    addBook(){
        this.book = new Book(this,0,0,this.bookData);
        this.book.buildBook(this);
        this.windowScene.addElement(this.book);
        this.book.setPosition(-this.windowScene.width*0.0004,this.windowScene.height*0.083)
        .setDisplaySize(this.windowScene.width*0.92,this.windowScene.height*0.77);
    }

    setBookCompleted(){
        let libraryScene = this.scene.get(scenesKey.LIBRARY_SCENE);
        libraryScene.events.emit('tale_completed',this.bookData);
    }

    listenUpcomingEvents(){
        this.events.on('loadedAssets',()=>{
            console.log('im ready');
            this.showContent();
        })

        this.events.on('result_modal_closed',() => {
            this.time.addEvent({delay : 200,callback : () => {
                this.scene.remove(scenesKey.RESULT_SCENE)}});
        });

        this.events.on('update_quiz_score',() => {
            let score = JSON.parse(localStorage.getItem('newScore'));
            localStorage.removeItem('newScore');
            this.book.changeQuizRecord(score,this);
        });

        this.events.on('all_quizzes_completed',() => {
            this.book.showBookPrice();
            this.emitToMainUI('freeze_buttons');
            this.showRewardModal(4,null);

            this.setBookCompleted();
        });

        this.events.on('pieces_earned',() => {
            this.book.showBookPrice();
        });

        this.events.on('changeSeparator',(separator)=>{
            this.book.hideBelowSeparator(separator);
        })

    }






    createGuide(){
        this.startGuide();
        this.showGuidePart(selectedTaleDialogsList.guide_dialogs.INTRO_DIALOG,
        500,()=>{this.showFirstGuidePart()});
        setTimeout(()=>{this.disableOmitButton()},1000);
    }

    showFirstGuidePart(){
        this.revealGuideBook();
        this.showGuidePart(selectedTaleDialogsList.guide_dialogs.DIALOG_01,
        500,()=>{this.showSecondGuidePart()});
    }

    showSecondGuidePart(){
        // this.time.addEvent({delay:1000,callback:()=>{this.finishGuideAction()}})
        this.showGuidePart(selectedTaleDialogsList.guide_dialogs.DIALOG_02,
        500,()=>{this.showThirdGuidePart()});
    }

    showThirdGuidePart(){
        this.showGuideBookCover();
        this.showGuidePart(selectedTaleDialogsList.guide_dialogs.DIALOG_03,
        500,()=>{this.showFourthGuidePart()});
    }

    showFourthGuidePart(){
        this.showGuideBookPiecesList();
        this.showGuidePart(selectedTaleDialogsList.guide_dialogs.DIALOG_04,
        500,()=>{this.showFifthGuidePart()});
    }

    showFifthGuidePart(){
        // this.time.addEvent({delay:1000,callback:()=>{this.finishGuideAction()}})
        this.showGuidePart(selectedTaleDialogsList.guide_dialogs.DIALOG_05,
        500,()=>{this.showSixthGuidePart()});
    }

    showSixthGuidePart(){
        this.showGuideBookSeparators();
        this.showGuidePart(selectedTaleDialogsList.guide_dialogs.DIALOG_06,
        500,()=>{this.showSeventhGuidePart()});
    }

    showSeventhGuidePart(){
        this.showGuideFestivitySeparator();
        this.showGuidePart(selectedTaleDialogsList.guide_dialogs.DIALOG_07,
        500,()=>{this.showEighthGuidePart()});
    }

    showEighthGuidePart(){
        this.showGuideFoodSeparator();
        this.showGuidePart(selectedTaleDialogsList.guide_dialogs.DIALOG_08,
        500,()=>{this.showNinethGuidePart()});
    }

    showNinethGuidePart(){
        this.showGuideSignsSeparator();
        this.showGuidePart(selectedTaleDialogsList.guide_dialogs.DIALOG_09,
        500,()=>{this.showTenthGuidePart()});
    }

    showTenthGuidePart(){
        this.showGuideDialectsSeparator();
        this.showGuidePart(selectedTaleDialogsList.guide_dialogs.DIALOG_10,
        500,()=>{this.showEleventhGuidePart()});
    }

    showEleventhGuidePart(){
        this.showGuidePageCorners();
        this.showGuidePart(selectedTaleDialogsList.guide_dialogs.DIALOG_11,
        500,()=>{this.showTwelvethGuidePart()});
    }

    showTwelvethGuidePart(){
        // this.time.addEvent({delay:1000,callback:()=>{this.finishGuideAction()}});
        this.showGuidePart(selectedTaleDialogsList.guide_dialogs.DIALOG_12,
        500,()=>{this.showThirdteenthGuidePart()});
    }

    showThirdteenthGuidePart(){
        this.showGuideStars();
        this.showGuidePart(selectedTaleDialogsList.guide_dialogs.DIALOG_13,
        500,()=>{this.showFourteenthGuidePart()});
    }

    showFourteenthGuidePart(){
        this.navigateToFirstGuidePage();
        this.showGuidePart(selectedTaleDialogsList.guide_dialogs.DIALOG_14,
        500,()=>{this.showFifteenthGuidePart()});
    }

    showFifteenthGuidePart(){
        this.showGuideNewPiece();
        this.showGuidePart(selectedTaleDialogsList.guide_dialogs.DIALOG_15,
        500,()=>{this.showLastGuidePart()});
    }

    showSixthTeenthGuidePart(){
        this.showGuideCompletedBook();;
        this.showGuidePart(selectedTaleDialogsList.guide_dialogs.DIALOG_16,
        500,()=>{this.showLastGuidePart()});
    }

    showLastGuidePart(){
        this.hideGuideBook();
        this.showGuidePart(selectedTaleDialogsList.guide_dialogs.FINAL_DIALOG,
        500,()=>{this.deleteGuideElements();
                 this.quitGuide()});
        setTimeout(()=>{this.disableOmitButton()},1000);    
    }

    startGuide(){
        this.emitToMainUI('changeVolume',0.2);
        //make sure this doesn't affect the real elements
        this.book.setVisible(false);

        //create guide book
        this.createGuideElements();

        // this.time.addEvent({delay:1000,callback:()=>{this.finishGuideAction()}})
    }

    quitGuide(){
        this.emitToMainUI('changeVolume',0.8);
        //make sure the elements return safe and sound
        this.book.setVisible(true);
    }

    createGuideElements(){
        this.guideElementsList = {};

        //create guide book
        this.createGuideBook();

        //create guide hand
        this.createGuideHand();
    }

    deleteGuideElements(){
        let guideBook = this.guideElementsList.guideBook,
            hand = this.guideElementsList.hand;
        guideBook.destroy();
        hand.destroy();
        
        this.guideElementsList = {};
    }

    createGuideBook(){
        // let guideBook = new Book(this,0,0,mainTalesList.SASI.book);
        let guideBook = new Book(this,0,0,booksList.BOOK_11);
        guideBook.buildBook(this);
        this.windowScene.addElement(guideBook);
        guideBook.setPosition(-this.windowScene.width*0.0004,this.windowScene.height*0.083)
        .setDisplaySize(this.windowScene.width*0.92,this.windowScene.height*0.77);

        guideBook.disableSeparators();
        guideBook.disablePages();
        guideBook.disableBookRibbon();

        guideBook.setAlpha(0.5);

        this.guideElementsList.guideBook = guideBook;
    }

    createGuideHand(){
        let hand = this.add.image(0,0,mainUIResources.HAND.key);
        this.windowScene.addElement(hand);
        hand.setVisible(false);
        this.guideElementsList.hand = hand;

    }

    revealGuideBook(){
        let guideBook = this.guideElementsList.guideBook;
        guideBook.setAlpha(1);
        // this.time.addEvent({delay:1000,callback:()=>{this.finishGuideAction()}})
    }

    hideGuideBook(){
        let guideBook = this.guideElementsList.guideBook;
        guideBook.setAlpha(0.5);
        // this.time.addEvent({delay:1000,callback:()=>{this.finishGuideAction()}})
    }

    showGuideBookCover(){
        let guideBook = this.guideElementsList.guideBook,
            coverPage = guideBook.coverPage,
            pagesList = guideBook.pagesList;

        pagesList.forEach((page)=>{
            if(page!==coverPage){
                page.setAlpha(0.5);
            }
        });
        // this.time.addEvent({delay:1000,callback:()=>{this.finishGuideAction()}})
    }

    showGuideBookPiecesList(){
        let guideBook = this.guideElementsList.guideBook,
            piecesPage = guideBook.piecesPage,
            pagesList = guideBook.pagesList;

        pagesList.forEach((page)=>{
            page.setAlpha(1);
            if(page!==piecesPage){
                page.setAlpha(0.5);
            }
        });
        // this.time.addEvent({delay:1000,callback:()=>{this.finishGuideAction()}})
    }

    showGuideBookSeparators(){
        let guideBook = this.guideElementsList.guideBook,
            pagesList = guideBook.pagesList;

        pagesList.forEach((page)=>{
            page.setAlpha(0.5);
        });
        // this.time.addEvent({delay:1000,callback:()=>{this.finishGuideAction()}})
    }

    showGuideFestivitySeparator(){
        let guideBook = this.guideElementsList.guideBook,
            pagesList = guideBook.pagesList,
            hand = this.guideElementsList.hand,
            festivitySeparator = guideBook.festivitySeparator,
            rightPage = guideBook.rightPage,
            leftPage = guideBook.leftPage;

        pagesList.forEach((page)=>{
            page.setAlpha(1);
        });

        hand.setVisible(true);

        this.tweens.add({
            targets : hand,
            x : {from : hand.x, to : festivitySeparator.x+90},
            y : {from : hand.y, to : festivitySeparator.y+100},
            onComplete : () => {
                this.time.addEvent({delay:500,callback:()=>{
                    let festivityChapterPages = guideBook.festivityChapter.pagesList;
                    let rPage = festivityChapterPages[1],
                    lPage = festivityChapterPages[0];
                    lPage.rightCorner.setVisible(false);
                    rPage.leftCorner.setVisible(false);                
                    rPage.setPosition(rightPage.x,rightPage.y).setVisible(true);
                    lPage.setPosition(leftPage.x,leftPage.y).setVisible(true);
                    // this.time.addEvent({delay:1000,callback:()=>{this.finishGuideAction()}});
                }})
            }})
    }

    showGuideFoodSeparator(){
        let guideBook = this.guideElementsList.guideBook,
            pagesList = guideBook.pagesList,
            hand = this.guideElementsList.hand,
            foodSeparator = guideBook.foodSeparator,
            rightPage = guideBook.rightPage,
            leftPage = guideBook.leftPage;

        this.tweens.add({
            targets : hand,
            x : {from : hand.x, to : foodSeparator.x+90},
            y : {from : hand.y, to : foodSeparator.y+100},
            duration : 2000,
            onComplete : () => {
                this.time.addEvent({delay:500,callback:()=>{
                    let foodChapterPages = guideBook.foodChapter.pagesList;
                    let lPage = foodChapterPages[0],
                        rPage = pagesList[1];
                    lPage.rightCorner.setVisible(false);
                    rPage.leftCorner.setVisible(false);
                    rPage.setPosition(rightPage.x,rightPage.y).setVisible(true);
                    lPage.setPosition(leftPage.x,leftPage.y).setVisible(true);
                    // this.time.addEvent({delay:1000,callback:()=>{this.finishGuideAction()}});
                }})
            }})
    }

    showGuideSignsSeparator(){
        let guideBook = this.guideElementsList.guideBook,
            pagesList = guideBook.pagesList,
            hand = this.guideElementsList.hand,
            signsSeparator = guideBook.signsSeparator,
            rightPage = guideBook.rightPage,
            leftPage = guideBook.leftPage;

        this.tweens.add({
            targets : hand,
            x : {from : hand.x, to : signsSeparator.x+90},
            y : {from : hand.y, to : signsSeparator.y+100},
            duration : 2000,
            onComplete : () => {
                this.time.addEvent({delay:500,callback:()=>{
                    let signsPages = guideBook.signChapter.pagesList;
                    let lPage = signsPages[0],
                        rPage = signsPages[1];
                    lPage.rightCorner.setVisible(false);
                    rPage.leftCorner.setVisible(false);                
                    rPage.setPosition(rightPage.x,rightPage.y).setVisible(true);
                    lPage.setPosition(leftPage.x,leftPage.y).setVisible(true);
                    // this.time.addEvent({delay:1000,callback:()=>{this.finishGuideAction()}});
                }})
            }})
    }

    showGuideDialectsSeparator(){
        let guideBook = this.guideElementsList.guideBook,
            pagesList = guideBook.pagesList,
            hand = this.guideElementsList.hand,
            dialectSeparator = guideBook.dialectSeparator,
            rightPage = guideBook.rightPage,
            leftPage = guideBook.leftPage;

        this.tweens.add({
            targets : hand,
            x : {from : hand.x, to : dialectSeparator.x+90},
            y : {from : hand.y, to : dialectSeparator.y+150},
            duration : 2000,
            onComplete : () => {
                this.time.addEvent({delay:500,callback:()=>{
                    let dialectPages = guideBook.dialectChapter.pagesList;
                    let lPage = dialectPages[0],
                        rPage = dialectPages[1];
                    lPage.rightCorner.setVisible(false);
                    rPage.leftCorner.setVisible(false);
                    rPage.setPosition(rightPage.x,rightPage.y).setVisible(true);
                    lPage.setPosition(leftPage.x,leftPage.y).setVisible(true);
                    // this.time.addEvent({delay:1000,callback:()=>{this.finishGuideAction()}});
                }})
            }})
    }

    showGuidePageCorners(){
        let guideBook = this.guideElementsList.guideBook,
            pagesList = guideBook.pagesList,
            hand = this.guideElementsList.hand,
            rightPage = guideBook.rightPage,
            leftPage = guideBook.leftPage;

        this.tweens.add({
            targets : hand,
            x : {from : hand.x, to : hand.x-50},
            y : {from : hand.y, to : hand.y+150},
            duration : 2000,
            onComplete : () => {
                this.time.addEvent({delay:500,callback:()=>{
                    let dialectPages = guideBook.dialectChapter.pagesList;
                    let lPage = dialectPages[dialectPages.length-2],
                        rPage = dialectPages[dialectPages.length-1];
                    lPage.rightCorner.setVisible(false);
                    rPage.leftCorner.setVisible(false);                
                    rPage.setPosition(rightPage.x,rightPage.y).setVisible(true);
                    lPage.setPosition(leftPage.x,leftPage.y).setVisible(true);
                    // this.time.addEvent({delay:1000,callback:()=>{this.finishGuideAction()}});
                }})
            }})
    }

    showGuideStars(){
        let guideBook = this.guideElementsList.guideBook,
            dialectPages = guideBook.dialectChapter.pagesList,
            rightPage = dialectPages[dialectPages.length-1],
            pagesList = guideBook.pagesList,
            hand = this.guideElementsList.hand,
            quizPage = pagesList[51];
            // quizPage.showStars();
            rightPage.showStars();
            hand.setVisible(false);

            // this.time.addEvent({delay:1000,callback:()=>{this.finishGuideAction()}});
    }

    navigateToFirstGuidePage(){
        let hand = this.guideElementsList.hand,
            guideBook = this.guideElementsList.guideBook,
            bookRibbon = guideBook.bookRibbon;
        hand.setVisible(true);
        this.tweens.add({
            targets: hand,
            x : {from : hand.x, to : bookRibbon.x + 100},
            y : {from : hand.y, to : bookRibbon.y + 50},
            duration : 2000,
            onComplete : () => {
                let pagesList = guideBook.pagesList,
                lPage = pagesList[0],
                rPage = pagesList[1];

                pagesList.forEach(page => {
                    page.setVisible(false);
                });

                lPage.setVisible(true);
                rPage.setVisible(true);
            }
        })
    }

    showGuideNewPiece(){
        let guideBook = this.guideElementsList.guideBook,
            pagesList = guideBook.pagesList,
            rPage = pagesList[1];
        rPage.boxesList[3].highlightBackground();
        
        // this.time.addEvent({delay:1000,callback:()=>{this.finishGuideAction()}});
    }

    showGuideCompletedBook(){
        let guideBook = this.guideElementsList.guideBook,
        pagesList = guideBook.pagesList,
        lPage = pagesList[0],
        rPage = pagesList[1];

        lPage.highlightPrice();

        rPage.boxesList.forEach((box)=>{
            box.highlightBackground();
        });
        
        // this.time.addEvent({delay:1000,callback:()=>{this.finishGuideAction()}});
    }

    endGuide(){
        this.deleteGuideElements();
        this.quitGuide();
    }

}