import Phaser from 'phaser';
import { introAssets, introResources, domResources, htmlResources, welcomeSceneResources } from '../../../utils/assetsPath';
import GameOptionButton from '../../../GameObjects/commonObjects/init-scene-components/welcome-page-components/gameOptionButton';
import { optionButtonsInformation } from '../../../utils/information/buttons/welcome-menu-butn/buttonsInformation';

export default class FormScene extends Phaser.Scene{
    
    preload(){
        //TEMPORAL
        // introResources.forEach((asset)=>{
        //     this.load.image(asset.key,asset.path);
        // });
        htmlResources.forEach((resource)=>{
            this.load.html(resource.key,resource.path);
        })
        this.setGameWidth(this.scale.width);
        this.setGameHeight(this.scale.height);
    }
    
    setGameWidth(width){ this.gameWidth = width }
    getGameWidth(){ return this.gameWidth }

    setGameHeight(height){ this.gameHeight = height }
    getGameHeight(){ return this.gameHeight }

    setBackground(background){ this.background = background }
    getBackground(){ return this.background }

    setGameTitle(title){ this.gameTitle = title }
    getGameTitle(){ return this.gameTitle }

    setReturnOption(option){ this.returnOption = option }
    getReturnOption(){ return this.returnOption }

    setForm(form){this.form = form }
    getForm(){ return this.form }

    setFormData(formData){ this.formData = formData }
    getFormData(){ return this.formData }

    loadHtmlElement(element){
        this.load.html(element.key,element.path);
    }

    addBackground(){
        let gameWidth = this.getGameWidth(), gameHeight = this.getGameHeight();
        let background = this.add.image(gameWidth / 2, gameHeight / 2,introAssets.BACKGROUND.key);
        this.setBackground(background);
        this.getBackground().setDisplaySize(gameWidth,gameHeight);
    }

    addForm(){
        let gameWidth = this.getGameWidth(), gameHeight = this.getGameHeight();
        let formData = this.getFormData();
        let form = this.add.dom(gameWidth/2,gameHeight/2).createFromCache(formData);
        this.setForm(form);
        this.getForm().setScale(gameWidth*0.0007);
        form.setPerspective(800);
    }

    addGameTitle(){
        let gameWidth = this.getGameWidth(),gameHeight = this.getGameHeight();
        let gameTitle = this.add.image(0,0,welcomeSceneResources.GAME_LOGO.key),
            container = this.add.container(gameWidth*0.18,gameHeight*0.1,[gameTitle]);
        container.setScale(0.4);
        this.setGameTitle(container);
        this.add.existing(this.getGameTitle());
    }

    zoomInBackground(){
        let background = this.getBackground();
        this.tweens.add({
            targets:background,
            displayWidth:{
                from:background.displayWidth, 
                to: background.displayWidth*1.1
            },
            displayHeight:{
                from:background.displayHeight,
                to:background.displayHeight*1.1
            }, 
            duration:10000, 
            yoyo:true, loop:-1, 
        });
    }

    createReturnOption(){
        let gameWidth = this.getGameWidth(), gameHeight = this.getGameHeight();
        let buttonContainer = this.add.container(),
        returnOption = new GameOptionButton(this,0,0,optionButtonsInformation.OPTION_RETURN),
        xPosition = gameWidth*0.05, yPosition = gameHeight*0.25,
        xScale = gameWidth*0.00035, yScale = gameHeight*0.00075;
        buttonContainer.add(returnOption);
        this.setReturnOption(buttonContainer);
        this.add.existing(this.getReturnOption());
        this.getReturnOption().setPosition(xPosition,yPosition);
        this.getReturnOption().setScale(xScale,yScale);
    }

    checkFieldIsEmpty(fieldInput){
        let isEmpty;
        if(fieldInput.value.trim().length === 0){
            isEmpty = true
        }else{
            isEmpty = false
        }
        return isEmpty;
    }

    clearField(fieldInput){
        fieldInput.value = "";
    }
}