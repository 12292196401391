import { BaseScene } from "./BaseScene";
import AssistantWindow from "../GameObjects/commonObjects/AssistantWindow";
import MainMenu from "../GameObjects/commonObjects/MainMenu";
import { mainUIResources, mainUISoundResources } from "../utils/assetsPath";
import { scenesKey } from "../utils/gameConstants";
import QuizScene from "./quizScene";
import { caracDialogsList, libraryDialogsList, chakraDialogsList, assistantDialogs, selectedTaleDialogsList } from "../utils/information/assistant/assistantDialogs";
import CaracScene from "./CaracScene";
import LibraryScene from "./LibraryScene";
import ChakraScene from "./ChakraScene";

export default class MainUIScene extends BaseScene{
    constructor(){
        super({key:scenesKey.MAINUI_SCENE});
    }

    init(){
        this.scene.remove(scenesKey.BOOTLOADER_SCENE);
        this.addGameScenes();
    }

    create(){
        this.createMainMenu();
        this.setInitialScene();
        this.createAssistantWindow();
        this.createBackgroundMusic();
        this.listenUpcomingEvents();
        setTimeout(()=>{this.introduceGameAssistant()},500);
    }

    setModuleDialogs(dialogs){
        this.moduleDialogs = dialogs;
    }

    setSelectedScene(scene){
        this.selectedScene = scene;
    }

    getSelectedScene(){
        return this.selectedScene;
    }

    setSoundON(status){ this.soundON = status }
    getSoundON(){ return this.soundON }

    setBackgroundMusic(music){ this.backgroundMusic = music }
    getBackgroundMusic(){ return this.backgroundMusic }

    createMainUI(){
        this.window = new AssistantWindow(this,0,0);
        this.window.setPosition(this.width*0.150,this.height/3);
        this.mainMenu = new MainMenu(this,0,0,mainUIResources.WINDOW_MAIN_MENU.key);
        this.mainMenu.setPosition(this.width*0.150,this.height*0.83);
    }

    createMainMenu(){
        this.mainMenu = new MainMenu(this,0,0,mainUIResources.WINDOW_MAIN_MENU.key);
        this.mainMenu.setPosition(this.width*0.150,this.height*0.83);
    }

    createAssistantWindow(){
        this.window = new AssistantWindow(this,0,0);
        this.window.setPosition(this.width*0.150,this.height/3);
        this.updateWindowScene();
    }

    createBackgroundMusic(){
        let musicConfig = {volume : 0.8, loop : true},
            music = this.sound.add(mainUISoundResources.BACKGROUNG_MUSIC.key,musicConfig);
        this.setBackgroundMusic(music);
        this.setSoundON(true);
        this.checkSoundStatus();
    }

    checkSoundStatus(){
        if(this.getSoundON()===true){
            this.getBackgroundMusic().play();
        }else{
            this.getBackgroundMusic().stop();
        }
    }

    shutdownBackgroundMusic(){
        this.getBackgroundMusic().stop();
    }

    playBackgroundMusic(){
        this.getBackgroundMusic().play();
    }

    update(){
    }


    addGameScenes(){
        this.scenesList = [];
        this.scene.add(scenesKey.CARAC_SCENE,CaracScene);
        this.scene.add(scenesKey.LIBRARY_SCENE,LibraryScene);
        this.scene.add(scenesKey.CHAKRA_SCENE,ChakraScene);
        this.scenesList = [scenesKey.CARAC_SCENE,scenesKey.LIBRARY_SCENE,scenesKey.CHAKRA_SCENE];
    }

    introduceGameAssistant(){
        // this.window.showFirstPart(this);
        // this.window.showCompleteDialog(this,assistantDialogs.intro_dialogs);
        this.blockButtons();
        this.window.introduceAssistantToGame(this);

    }

    setInitialScene(){
        this.scenesList.forEach((scene)=>{
            this.scene.launch(scene);
            this.scene.sleep(scene);
        });
        this.scene.wake(scenesKey.CARAC_SCENE);
        this.setSelectedScene(this.scene.get(scenesKey.CARAC_SCENE));
        this.setModuleDialogs(caracDialogsList.randomDialogs);
        this.mainMenu.chooseButton(this.mainMenu.caracBtn,this.mainMenu.styleConfig);
    }

    addNewScene(scene){
        this.scenesList.push(scene);
    }

    getScenes(){
        return this.game.scene.scenes;
    }

    showScene(sceneKey){
        this.scenesList.forEach((sc)=>{
            if(sc!==sceneKey){
                this.scene.sleep(sc)
            }else{
                this.scene.wake(sc);
                this.cleanChakraTools(sc);
                this.cleanChakraSeeds(sc);
                this.cleanChakraBubbles(sc);
                this.setSelectedScene(this.scene.get(sceneKey));
            }
        });
        this.input.setDefaultCursor('url(default.cur),auto');
    }

    closeScenes(){
        this.getBackgroundMusic().stop();
        this.scenesList.forEach(scene => {
            this.scene.sleep(scene);
            this.scene.remove(scene);
        })
        let gameMenuScene = this.scene.get(scenesKey.GAME_MENU_SCENE);
        gameMenuScene.events.emit('game-closed');
        this.scene.start(scenesKey.GAME_MENU_SCENE);
    }

    cleanChakraTools(sceneKey){
        if(sceneKey === scenesKey.CHAKRA_SCENE){
            let chakraScene = this.scene.get(sceneKey);
                if(chakraScene.toolsBox.getSelectedButton()){
                    chakraScene.toolsBox.cleanButtons();
                }
        }
    }

    cleanChakraSeeds(sceneKey){
        if(sceneKey === scenesKey.CHAKRA_SCENE){
            let chakraScene = this.scene.get(sceneKey);
            chakraScene.clearSeedPart();
        }
    }

    cleanChakraBubbles(sceneKey){
        if(sceneKey === scenesKey.CHAKRA_SCENE){
            let chakraScene = this.scene.get(sceneKey);
            chakraScene.hideBubbles();
        }
    }

    blockButtons(){
        this.window.assistantMenu.sleepButtons();
        this.mainMenu.sleepButtons();
    }

    activeButtons(){
        this.window.assistantMenu.wakeButtons(true);
        this.mainMenu.wakeButtons(false);
    }

    repeatAQuiz(){
        this.scene.remove(scenesKey.RESULT_SCENE);
        this.scene.add(scenesKey.QUIZ_SCENE,QuizScene);
        this.scene.launch(scenesKey.QUIZ_SCENE);
    }

    returnToSelectedTale(){
        this.scene.remove(scenesKey.RESULT_SCENE);
        this.scene.launch(scenesKey.SELECTED_TALE_SCENE);
    }

    congratsForPuzzlePlaced(){
        let puzzlePlacedDialogs = caracDialogsList.puzzle_pieces_dialogs;
        let random = Math.floor(Math.random()*puzzlePlacedDialogs.length);
        this.window.congratulateThePlayer(this,puzzlePlacedDialogs[random]);
    }

    congratsForPuzzleEarned(){
        let puzzleEarnedDialogs = selectedTaleDialogsList.new_puzzle_piece_dialogs;
        let random = Math.floor(Math.random()*puzzleEarnedDialogs.length);
        this.window.congratulateThePlayer(this,puzzleEarnedDialogs[random]);
    }

    congratsForUnlockNewTale(){
        let unlockTaleDialogs = chakraDialogsList.unlock_tale_dialogs,
            random = Math.floor(Math.random()*unlockTaleDialogs.length);
        this.window.congratulateThePlayer(this,unlockTaleDialogs[random]);
    }

    congratsForNewSeedEarned(){
        let seedEarnedDialogs = caracDialogsList.new_seed_earned_dialogs;
        let random = Math.floor(Math.random()*seedEarnedDialogs.length);
        this.window.congratulateThePlayer(this,seedEarnedDialogs[random]);
    }

    commentOnChakra(status){
        let dialoglist = [];
        switch(status){
            case 'ready':
                dialoglist = chakraDialogsList.ready_chakra_dialogs;
            break;

            case 'occupied':
                dialoglist = chakraDialogsList.occupied_chakra_dialogs;
            break;

            case 'completed':
                dialoglist = chakraDialogsList.completed_chakra_dialogs;
            break;
            
            default:
            break;
        }

        let random = Math.floor(Math.random()*dialoglist.length);
        this.window.congratulateThePlayer(this,dialoglist[random]);
    }

    askForNewQuizTry(){
        let newQuizTryDialogs = selectedTaleDialogsList.incomplete_quiz_dialogs;
        let random = Math.floor(Math.random()*newQuizTryDialogs.length);
        this.window.showCongratulationMessage(this,newQuizTryDialogs[random],true);
    }


    listenUpcomingEvents(){
        this.events.on('modal_shutdown',()=>{
            this.window.moveRightAssistant(this);
            this.window.dialogBox.destroy();
            this.activeButtons();
        },this);

        this.events.on('cancelGuide',()=>{
            let selectedScene = this.selectedScene;
            selectedScene.endGuide();
        })

        this.events.on('modal_gone',()=>{
            this.window.moveRightAssistant(this);
            this.window.dialogBox.destroy();
        },this);

        this.events.on('freeze_buttons',()=>{
            this.blockButtons();
        })

        this.events.on('active_buttons',()=>{
            this.activeButtons();
        })

        this.events.on('changeVolume',(value)=>{
            this.getBackgroundMusic().setVolume(value);
        })

        this.events.on('completedMonth',(data)=>{
            console.log('felicidades! terminaste el mes',data[0]);
        });

        this.events.on('showcaracscene',()=>{
            this.showScene(scenesKey.CARAC_SCENE);
            this.setModuleDialogs(caracDialogsList.randomDialogs);
            this.updateWindowScene();
        });
        this.events.on('showlibraryscene',()=>{
            this.showScene(scenesKey.LIBRARY_SCENE);
            this.setModuleDialogs(libraryDialogsList.randomDialogs);
            this.updateWindowScene();
        });
        this.events.on('showchakrascene',()=>{
            this.showScene(scenesKey.CHAKRA_SCENE);
            this.setModuleDialogs(chakraDialogsList.randomDialogs);
            this.updateWindowScene();
        });

        this.events.on('ask_new_quiz_try',()=>{
            this.askForNewQuizTry();
        })

        this.events.on('new_quiz_try',()=>{
            this.repeatAQuiz();

            this.window.moveRightAssistant(this);
            this.window.dialogBox.destroy();
        });

        this.events.on('return_selected_tale',()=>{
            this.returnToSelectedTale();
        }); 
        
        this.events.on('puzzle_placed',()=>{
            this.congratsForPuzzlePlaced();
        });

        this.events.on('new_seed_earned',()=>{
            this.congratsForNewSeedEarned();
        })

        this.events.on('new_puzzle_earned',()=>{
            this.congratsForPuzzleEarned();
        });

        this.events.on('tale_unlocked',()=>{
            this.congratsForUnlockNewTale();
        });

        this.events.on('chakra_is_ready',() => {
            this.commentOnChakra('ready');
        });

        this.events.on('chakra_is_seeded',() => {
            this.commentOnChakra('occupied');
        });

        // this.events.on('chakra_is_completed',() => {
        //     this.commentOnChakra('completed');
        // });


        this.events.on('changeSoundStatus',()=>{
            // let status = this.getSoundON();
            this.setSoundON(!this.getSoundON());
            this.checkSoundStatus();
        })

        this.events.on('shutdown-game',()=>{
            this.closeScenes();
        })


    }

    passingAnAction(dialog,confirmAction){
        //this.window.showGuideDialog(this,dialog,action);
        this.window.showDialogGuide(this,dialog,confirmAction);
    }

    changeInstruction(dialog,action){
        this.window.changeGuideDialog(this,dialog,action);
    }

    showDialogButton(){
        this.window.unlockOkButton();
    }

    cleanAssistantDialog(){
        this.window.dialogBox.cleanModal(this);
    }

    updateWindowScene(){
        this.window.setSelectedScene(this.selectedScene);
    }

    

}