import Phaser from 'phaser';
import AssistantRibbon from './AssistantRibbon';
import { mainUIResources, mainUISoundResources } from '../../utils/assetsPath';
import AssistantMenu from './AssistantMenu';
import DialogModal from './dialogModal';
import { assistantDialogs, soundDialogs, confirmationDialogList } from '../../utils/information/assistant/assistantDialogs';
import { scenesKey } from '../../utils/gameConstants';

export default class AssistantWindow extends Phaser.GameObjects.Container{
    constructor(scene,x,y,texture=mainUIResources.WINDOW_ASSISTANT.key){
        super(scene,x,y);
        this.setScene(scene);
        this.texture = texture;
        this.createAssistantWindow(scene);
        this.setButtonsActions(scene);
    }
    setScene(scene){this.scene = scene}
    getScene(){ return this.scene }

    getAssistantMenu(){ return this.assistantMenu }
    setAssistantMenu(menu){ this.assistantMenu = menu }

    addWindow(scene){
        this.window = scene.add.image(0,0,this.texture);
        this.gameAssistant = scene.add.image(0,0,mainUIResources.GAME_ASSISTANT.key);
        // this.gameAssistant.setDisplaySize(this.window.width*0.5,this.window.height*0.6);
        // this.gameAssistant.setDisplaySize(this.window.width*0.25,this.window.height*0.587);

        this.gameAssistant.setScale(0.47);
        this.add(this.window).setSize(this.window.width,this.window.height);
        this.add(this.gameAssistant);
    }

    setSelectedScene(scene){
        this.selectedScene = scene;
    }

    addRibbon(scene){
        this.ribbon = new AssistantRibbon(scene,0,0);
        this.ribbon.setPosition(0,-this.window.height*0.36);
        this.add(this.ribbon);
    }

    addAssistantMenu(scene){
        this.setAssistantMenu(new AssistantMenu(scene,0,0));
        this.getAssistantMenu().setPosition(0,this.window.height*0.37);
        this.add(this.getAssistantMenu());
        this.getAssistantMenu().setScale(0.2);
    }

    changeDisplaySize(){
        this.assistantMenu.setDisplaySize(this.window.width*0.85,this.window.height*0.18);
        // this.assistantMenu.setScale(this.window.width*0.3,this.window.height*0.01);
    }

    addDialogModal(scene){
        this.dialogBox = new DialogModal(scene,0,0);
        this.add(this.dialogBox);
    }

    createAssistantWindow(scene){
        this.addWindow(scene);
        this.addRibbon(scene);
        this.addAssistantMenu(scene);
        scene.add.existing(this);
        this.setDisplaySize(scene.width*0.3,scene.height*0.665);
    }
    
    showRandomDialog(scene,dialogArray,random){
        let dialog = null;
        if(random){
            dialog = dialogArray[Math.floor(Math.random() * dialogArray.length)];
        }else{
            dialog = dialogArray[0];
        }
        this.speakDialog(dialog);
        this.dialogBox.addTextDialog(scene,dialog,false);
        // this.dialogBox.hideOkButton();
        this.dialogBox.hideContinueButton();
    }

    showDialog(scene,dialog){
        this.dialogBox.addTextDialog(scene,dialog,false);
    }

    createModal(scene){
        this.dialogBox = new DialogModal(scene,0,0);
        this.add(this.dialogBox);
    }

    showFirstPart(scene){
        scene.events.emit('changeVolume',0.2);
        scene.events.emit('freeze_buttons');
        this.moveLeftAssistant(scene,()=>{
        this.createModal(scene);
        let dialog = assistantDialogs.intro_dialogs[0];
        this.speakDialog(dialog);
        this.dialogBox.addTextDialog(scene,dialog,false)
        this.dialogBox.setOkActions(scene,()=>{
        scene.time.addEvent({delay:900,callback:()=>{this.showSecondPart(scene)}});
        })
        this.dialogBox.hideOkButton();
       });
    }

    congratulateThePlayer(scene, dialog){
        scene.events.emit('changeVolume',0.2);
        scene.events.emit('freeze_buttons');
        this.moveLeftAssistant(scene,()=>{
            this.createModal(scene);
            this.dialogBox.addTextDialog(scene,dialog,false);
            this.speakAutoDialog(dialog,scene);
            this.dialogBox.hideContinueButton();
        })
    }

    showSecondPart(scene){
        scene.events.emit('freeze_buttons');
        this.moveLeftAssistant(scene,() => {
            this.createModal(scene);
            let dialog = assistantDialogs.intro_dialogs[1];
            this.speakDialog(dialog);
            this.dialogBox.addTextDialog(scene,dialog,false);
            this.dialogBox.setOkActions(scene,()=>{
            scene.time.addEvent({delay:900,callback:()=>{this.showThirdPart(scene)}});
            });
            this.dialogBox.hideOkButton();
        })
    }

    showThirdPart(scene){
        scene.events.emit('freeze_buttons');
        this.moveLeftAssistant(scene,() => {
            this.createModal(scene);
            let dialog = assistantDialogs.intro_dialogs[2];
            this.speakDialog(dialog);
            this.dialogBox.addTextDialog(scene,dialog,false);
            this.dialogBox.setOkActions(scene,()=>{scene.events.emit('changeVolume',0.8)});
            this.dialogBox.hideOkButton();
        });
    }

    speakDialog(dialog){
        let tts = window.speechSynthesis;
        let toSpeak = new SpeechSynthesisUtterance(dialog);
        toSpeak.voice = tts.getVoices()[0];
        tts.speak(toSpeak);
        toSpeak.onend = ()=>{this.dialogBox.showContinueButton()}
    }

    speakAutoDialog(dialog,scene){
        let tts = window.speechSynthesis;
        let toSpeak = new SpeechSynthesisUtterance(dialog);
        toSpeak.voice = tts.getVoices()[0];
        tts.speak(toSpeak);
        toSpeak.onend = ()=>{
            scene.events.emit('changeVolume',0.8);
            this.dialogBox.destroyModal(scene)}
    }

    speakGuideDialog(dialog){
        let tts = window.speechSynthesis,
            toSpeak = new SpeechSynthesisUtterance(dialog);
        toSpeak.voice = tts.getVoices()[0];
        tts.speak(toSpeak);
        toSpeak.onend = ()=>{ this.dialogBox.showGroupButtons()}
    }

    onlySpeakDialog(dialog){
        let tts = window.speechSynthesis;
        let toSpeak = new SpeechSynthesisUtterance(dialog);
        toSpeak.voice = tts.getVoices[0];
        tts.speak(toSpeak);
    }

    introduceAssistantToGame(scene){
        let introductionList = assistantDialogs.intro_dialogs;
        this.moveLeftAssistant(scene,() => {
            this.createModal(scene);
            for(let i=0;i<introductionList.length;i++){
                setTimeout(()=>{
                    let tts = window.speechSynthesis;
                    let toSpeak = new SpeechSynthesisUtterance(introductionList[i]);
                    toSpeak.voice = tts.getVoices[0];
                    tts.speak(toSpeak);
                    this.dialogBox.generateText(scene,introductionList[i]);
                    if(i===introductionList.length-1){
                        setTimeout(()=>{
                            this.dialogBox.destroyModal(scene);
                            scene.activeButtons();
                        },8000);
                    }
                },i*(8500-i*500));
            }
        })
    }



    showDialogGuide(scene,dialog,confirmAction){
        scene.events.emit('freeze_buttons');
        this.moveLeftAssistant(scene,() => {
            this.createModal(scene);
            this.speakGuideDialog(dialog);
            this.dialogBox.generateText(scene,dialog);
            this.dialogBox.createGroupButtons(scene);
            this.dialogBox.shutDownGroupModal(scene,
            ()=>{if(confirmAction) confirmAction()});
            this.dialogBox.hideGroupButtons();
        })
    }

    changeGuideDialog(scene,dialog,confirmAction,omitAction){
        this.speakGuideDialog(dialog);
        this.dialogBox.changeText(dialog);
        this.dialogBox.shutDownGroupModal(scene,
        ()=>{if(confirmAction) confirmAction()},()=>{if(omitAction) omitAction()});
        this.dialogBox.hideGroupButtons();
    }

    showCompleteDialog(scene,dialogList){
        scene.events.emit('freeze_buttons');
        this.moveLeftAssistant(scene,() => {
            this.createModal(scene);
            for(let i = 0; i<dialogList.length;i++){
                scene.time.addEvent({delay:i*3000,callback:()=>{
                this.dialogBox.generateText(scene,dialogList[i]);
                this.speakDialog(dialogList[i]);
                    if(i===dialogList.length-1){
                        scene.time.addEvent({delay:1000,callback:()=>{this.dialogBox.cleanModal(scene)}})
                    }
                }});
            }
        })
    }

    unlockOkButton(){
        this.dialogBox.showOkButton();
    }

    showCongratulationMessage(scene,message,freeze){
        scene.events.emit('freeze_buttons');
        this.moveLeftAssistant(scene,()=>{
            this.createModal(scene);
            this.dialogBox.generateText(scene,message);
            this.onlySpeakDialog(message);
            if(freeze === false) scene.time.addEvent({delay:1000,callback:()=>{this.dialogBox.cleanModal(scene)}});
            
        })
    }

    showHint(scene){
        let assistantMenu = this.getAssistantMenu(),
            hintButton = assistantMenu.getHintBtn();
        hintButton.on('pointerdown',()=>{
            scene.sound.play(mainUISoundResources.ASSISTANT_MENU_BUTTON_CLIC.key);
            assistantMenu.chooseAssistantButton(hintButton);
            this.moveLeftAssistant(scene,()=>{
                this.addDialogModal(scene);
                this.showRandomDialog(scene,scene.moduleDialogs,true)});
            scene.time.addEvent({delay:300,callback:()=>{scene.events.emit('freeze_buttons')}});
        });

        hintButton.on('pointerup',()=>{
            assistantMenu.discardAssistantButton();
        })
    }

    showGuide(){
        let assistantMenu = this.getAssistantMenu(),
            guideButton = assistantMenu.getGuideBtn();
        guideButton.on('pointerdown',()=>{
            this.getScene().sound.play(mainUISoundResources.ASSISTANT_MENU_BUTTON_CLIC.key);
            assistantMenu.chooseAssistantButton(guideButton);
        });
        guideButton.on('pointerup',()=>{
            if(this.selectedScene.guideArrow)this.selectedScene.destroyGuideArrow();
            this.selectedScene.createGuide();
            assistantMenu.discardAssistantButton();
        })
    }

    turnOffSound(scene){
        let assistantMenu = this.getAssistantMenu(),
            soundButton = assistantMenu.getSoundBtn();
        soundButton.on('pointerdown',()=>{
            scene.sound.play(mainUISoundResources.ASSISTANT_MENU_BUTTON_CLIC.key);
            assistantMenu.chooseAssistantButton(soundButton);            
            this.moveLeftAssistant(scene,()=>{
                this.addDialogModal(scene);
                this.showDialog(scene,this.displaySoundStatus(scene));
                scene.events.emit('changeSoundStatus');
                scene.time.addEvent({delay:300,callback:()=>{scene.events.emit('freeze_buttons')}});
            });
        });
        soundButton.on('pointerup',()=>{
            assistantMenu.discardAssistantButton();
        });
    }

    displaySoundStatus(scene){
        let dialog = null;
        if(scene.getSoundON()){
            dialog = soundDialogs.SOUND_BTN_DIALOGS;
        }else{
            dialog = soundDialogs.MUTE_BTN_DIALOGS;
        }
        return dialog;
    }

    exitGame(scene){
        let assistantMenu = this.getAssistantMenu(),
            exitButton = assistantMenu.getExitBtn();
        exitButton.on('pointerdown',()=>{
            scene.sound.play(mainUISoundResources.ASSISTANT_MENU_BUTTON_CLIC.key);
            assistantMenu.chooseAssistantButton(exitButton);
        })
        exitButton.on('pointerup',()=>{
            assistantMenu.discardAssistantButton();
            scene.events.emit('shutdown-game');
        })
    }


    turnOnSound(scene){
        this.assistantMenu.muteBtn.on('pointerdown',()=>{
            scene.sound.play(mainUISoundResources.ASSISTANT_MENU_BUTTON_CLIC.key);
            this.assistantMenu.hideMuteButton();
            this.assistantMenu.chooseButton(this.assistantMenu.soundBtn,this.assistantMenu.styleConfig);            
            this.moveLeftAssistant(scene,()=>{
                this.addDialogModal(scene);
                this.showDialog(scene,soundDialogs.MUTE_BTN_DIALOGS);
                scene.time.addEvent({delay:300,callback:()=>{scene.events.emit('freeze_buttons')}});
            });
        });
        this.assistantMenu.soundBtn.on('pointerup',()=>{
            this.assistantMenu.discardButton();
        });
    }

    setButtonsActions(scene){
        this.showHint(scene);
        this.showGuide();
        this.turnOffSound(scene);
        // this.turnOnSound(scene);
        this.exitGame(scene);
    }


    moveLeftAssistant(scene,action){
        scene.tweens.add(
            {
                targets:this.gameAssistant,
                x:{from:0,to:-170},
                duration:500,
                ease:'Cubic'
            }).on('complete',action);
    }

    moveRightAssistant(scene){
        scene.tweens.add(
        {
            targets:this.gameAssistant,
            x:{from:this.gameAssistant.x,to:0},
            duration:500,
            ease:'Cubic'
        });
    }

    



}