import Phaser from 'phaser';


//this card is used for a 15-letters-sentence
export default class FactCard extends Phaser.GameObjects.Container{
    constructor(scene,x,y,data){
        super(scene,x,y);
        this.setSize(450,140);
        this.setData(data);
        
        this.buildCard(scene);
    }

    setData(data){
        this.taleData = data;
    }

    setParagraph(scene){
        let textStyle = {
            x : 0,
            y : 0,
            text:this.taleData,
            style : {
                fontFamily : 'Helvetica',
                fontSize : '22px',
                color : '#000',
                align : 'justify',
                wordWrap : {width:300, useAdvancedWrap: true} 
            }
        }
        this.paragraph = scene.make.text(textStyle);
    }

    setBody(scene){
        this.body = scene.add.graphics();
        this.body.fillStyle(0x9b8465,1).lineStyle(3,0x000,1);
        this.body.fillRoundedRect(-225,-70,450,140,15)
        .strokeRoundedRect(-225,-70,450,140,15);
        this.add(this.body);
    }

    setInnerBody(scene){
        this.innerBody = scene.add.graphics();
        this.innerBody.fillStyle(0xD2C75E,1).lineStyle(1,0x000,1);
        this.innerBody.fillRoundedRect(-215,-60,430,120,15)
        .strokeRoundedRect(-215,-60,430,120,15);
        this.add(this.innerBody);
    }

    setCircle(scene){
        this.circle = scene.add.graphics();
        this.circle.fillStyle(0x2A2A5B,1).lineStyle(1,0xfff,1);
        this.circle.fillCircle(-170,0,30)
        .strokeCircle(-170,0,30);
        this.add(this.circle);
    }

    putParagraph(){
        this.paragraph.setOrigin(0.39,0.5);
        this.add(this.paragraph);
    }

    buildCard(scene){
        this.setBody(scene);
        this.setInnerBody(scene);
        this.setCircle(scene);
        this.setParagraph(scene);
        this.putParagraph();
    }


}