import Box from "../baseClasses/Box";
import { chakraResources } from "../../utils/assetsPath";
import Label from "../baseClasses/Label";
import Chakra from "../baseClasses/chakra";
import { gridAlignGroupElements, addChildrenToContainer } from "../../utils/functions";

export default class ChakraPlatform extends Box{
    constructor(scene,x,y,guide){
        super(scene,x,y,chakraResources.CHAKRA_PLATFORM.key);
        this.setInitialPlatformProperties(scene);
        (!guide)?this.buildPlatform():this.buildGuidePlatform();
    }

    getScene(){ return this.scene }
    setScene(scene){ this.scene = scene }

    getChakrasGridConfig(){ return this.chakrasGridConfig }
    setChakrasGridConfig(config){ this.chakrasGridConfig = config }

    getChakrasList(){ return this.chakrasList }
    setChakrasList(chakrasList){ this.chakrasList = chakrasList }

    getEmptyChakras(){ return this.emptyChakras }
    setEmptyChakras(list){ this.emptyChakras = list }

    getReadyChakras(){ return this.readyChakras }
    setReadyChakras(list){ this.readyChakras = list }

    getOccupiedChakras(){ return this.occupiedChakras }
    setOccupiedChakras(list) { this.occupiedChakras = list }

    getCompletedChakras(){ return this.completedChakras }
    setCompletedChakras(list) { this.completedChakras = list }

    getSelectedChakra(){ return this.selectedChakra }
    setSelectedChakra(chakra){ this.selectedChakra = chakra }

    getBusyChakras(){ return this.busyChakras }
    setBusyChakras(list){ this.busyChakras = list }


    setInitialPlatformProperties(scene){
        this.setScene(scene);
        this.setChakrasList([]);
        this.setEmptyChakras([]);
        this.setReadyChakras([]);
        this.setOccupiedChakras([]);
        this.setCompletedChakras([]);
        this.setBusyChakras([]);
        this.setSelectedChakra(null);
        this.setChakrasGridConfig({ width:6,height:2,cellWidth:120,
            cellHeight:270, position:6, x:-300,y:-115});
    }

    setPlaque(plaque){ this.plaque = plaque }
    getPlaque(){ return this.plaque }

    addPlatformPlaque(){
        let scene = this.getScene();
        this.setPlaque(new Label(scene,0,0,chakraResources.PLATFORM_PLAQUE.key,'ANDÉN TRADICIONAL'));
        this.getPlaque().setTextStyle({fontFamily : 'Franklin Gothic', fontSize : '45px', stroke : "#33034E", color : '#9557B8', strokeThickness : 5})
        let plaque = this.getPlaque();
        plaque.setPosition(0,-this.box.height*0.4);
        this.add(plaque);
    }

    addOneChakra(chakra){
        this.getChakrasList().push(chakra);
    }

    addChakras(){
        let scene = this.getScene();
        for(let id=1;id<=12;id++){
            this.addOneChakra(new Chakra(scene,0,0,id));
        }
        let group = scene.add.group(this.getChakrasList());
        gridAlignGroupElements(scene,group,this.getChakrasGridConfig());
        addChildrenToContainer(this,group.getChildren());
    }

    addGuideChakras(){
        let scene = this.getScene();
        let guideList = [];
        for(let i=1;i<=12;i++){
            let chakra = new Chakra(scene,0,0);
            guideList.push(chakra);
        }
        let group = scene.add.group(guideList);
        gridAlignGroupElements(scene,group,this.getChakrasGridConfig());
        addChildrenToContainer(this,group.getChildren());
    }

    buildPlatform(){
        let scene = this.getScene();
        this.addBox(scene);
        this.addPlatformPlaque();
        this.addChakras();
        this.displayBox(scene);
    }

    buildGuidePlatform(){
        let scene = this.getScene();
        this.addBox(scene);
        this.addPlatformPlaque();
        this.addGuideChakras();
        this.displayBox(scene);
    }


    addEmptyChakra(chakra){
        let emptyChakrasList = this.getEmptyChakras();
        if(!emptyChakrasList.includes(chakra)){emptyChakrasList.push(chakra)}
    }

    addReadyChakra(chakra){
        let readyChakrasList = this.getReadyChakras();
        if(!readyChakrasList.includes(chakra)){readyChakrasList.push(chakra)}
    }

    addOccupiedChakra(chakra){
        let occupiedChakrasList = this.getOccupiedChakras();
        if(!occupiedChakrasList.includes(chakra)){occupiedChakrasList.push(chakra)}
    }

    addCompletedChakra(chakra){
        let completedChakrasList = this.getCompletedChakras();
        if(!completedChakrasList.includes(chakra)){completedChakrasList.push(chakra)}
    }

    addBusyChakra(chakra){
        let busyChakrasList = this.getBusyChakras();
        if(!busyChakrasList.includes(chakra)){}{busyChakrasList.push(chakra)}
    }

    removeBusyChakra(chakra){
        let busyChakrasList = this.getBusyChakras(),
            index = busyChakrasList.indexOf(chakra);
        if(index>-1){busyChakrasList.splice(index,1)}
    }

    



    changeChakra(chakra){
        if(this.readyChakras.length>0){
            this.addOccupiedChakra(chakra);
            let index = this.readyChakras.indexOf(chakra);
            if(index>-1){
                this.readyChakras.splice(index,1);
                console.log('ahora la lista de chakras listas es: ',this.getReadyChakras());
    
            }
        }else{
            console.log('lista de chakras listas vacía');
        }
    }

    fillEmptyChakras(){
        let list = [];
        this.getChakrasList().forEach(chakra=>{list.push(chakra)});
        this.setEmptyChakras(list);
    }
}