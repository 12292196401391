import Box from "../baseClasses/Box";
import { caracResources } from "../../utils/assetsPath";

export default class BoardBox extends Box{
    constructor(scene,x,y){
        super(scene,x,y,caracResources.BOARD_BOX.key);
        this.addBox(scene);
        this.displayBox(scene);
    }

    setBoardGame(scene){
        this.box.setDisplaySize(scene.width*0.53,scene.height*1.02);
    }
}