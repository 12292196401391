import { scenesKey } from "../../../utils/gameConstants";
import GameOptionsMenu from "../../../GameObjects/commonObjects/init-scene-components/welcome-page-components/gameOptionsMenu";
import MenuScene from "../baseScenes/menuScene";
import BootLoader from "../../BootLoader";

export default class GameMenuScene extends MenuScene{
    constructor(){
        super({key : scenesKey.GAME_MENU_SCENE})
    }

    create(){
        this.setInitialProperties();
        this.setSceneEvents();
        this.createTheScene();
        this.createOptionsMenu();
        this.redirectScenes();
    }

    setInitialProperties(){
        let gameWidth = this.getGameWidth(), gameHeight = this.getGameHeight();
        this.setMenuXPosition(gameWidth * 0.3);
        this.setMenuYPosition(gameHeight * 0.6);
        this.setMenuXScale(gameWidth*0.0005);
        this.setMenuYScale(gameHeight*0.0008);
    }

    setSceneEvents(){
        this.events.on('game-closed',()=>{
            setTimeout(()=>{this.scene.remove(scenesKey.MAINUI_SCENE)},1000);
        })
    }

    createOptionsMenu(){
        let menu = new GameOptionsMenu(this,0,0);
        menu.createGameMenu();
        this.setOptionsMenu(menu);
        this.modifyMenuOptionsAttributes();
        this.add.existing(this.getOptionsMenu());
    }

    redirectScenes(){
        let menu = this.getOptionsMenu(),
            exitOption = menu.getExitOption(),
            creditsOption = menu.getCreditsOption(),
            playGameOption = menu.getPlayGameOption(),
            instructionsOption = menu.getInstructionsOption();

        exitOption.on('pointerup',()=>{
            this.exitGame();
        })

        creditsOption.on('pointerup',()=>{
            this.scene.start(scenesKey.CREDITS_SCENE);
        })

        instructionsOption.on('pointerup',()=>{
            this.scene.start(scenesKey.GUIDE_SCENE);
        })

        playGameOption.on('pointerup',() => {
            this.scene.add(scenesKey.BOOTLOADER_SCENE,BootLoader);
            this.scene.start(scenesKey.BOOTLOADER_SCENE);
        })

    }

    exitGame(){
        this.cleanLocalStorage();
        this.goToIntroScene();
    }

    cleanLocalStorage(){
        localStorage.clear();
    }

    goToIntroScene(){
        this.scene.start(scenesKey.WELCOME_SCENE);
    }
}