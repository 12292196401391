import Phaser from 'phaser';
import { gridAlignGroupElements, addChildrenToContainer } from '../../../../utils/functions';

export default class MeaningCard extends Phaser.GameObjects.Container{
    constructor(scene,x,y,data,legend){
        super(scene,x,y);
        this.setSize(450,450);
        this.setData(data);

        this.buildCard(scene,legend);
    }

    setData(data){
        this.taleData = data;
    }

    setBody(scene){
        this.body = scene.add.graphics();
        this.body.fillStyle(0x9b8465,1).lineStyle(3,0x000,1);
        this.body.fillRoundedRect(-225,-152,450,450,15)
        .strokeRoundedRect(-225,-152,450,450,15);
        this.add(this.body);
    }

    setInnerBody(scene){
        this.body = scene.add.graphics();
        this.body.fillStyle(0xD2C75E,1).lineStyle(1,0x000,1);
        this.body.fillRoundedRect(-215,-142,430,430,15)
        .strokeRoundedRect(-215,-142,430,430,15);
        this.add(this.body);
    }

    setField(scene,data){
        let fieldContainer = scene.add.container(0,0);
        fieldContainer.setSize(410,40);

        let style = {
            fontFamily : 'Helvetica',
            fontSize : '20px',
            color : '#000',
            align : 'justify'}
        let text = scene.add.text(0,0,data,style);
        text.setOrigin(0.45);

        let circle = scene.add.graphics();
        circle.fillStyle(0x2A2A5B,1).lineStyle(1,0xfff,1)
        .fillCircle(-205,0,20).strokeCircle(-205,0,20);

        let field = scene.add.graphics();
        field.fillStyle(0xffffff,0.5).lineStyle(2,0x000,0.5)
        .fillRoundedRect(-165,-20,350,40,15)
        .strokeRoundedRect(-165,-20,350,40,15);

        fieldContainer.add([circle,field,text]);
        return fieldContainer;
    }

    buildCard(scene,legend){
        this.setBody(scene);
        this.setInnerBody(scene);
        this.setDescriptionText(scene,legend);
        this.addFields(scene);
    }

    setDescriptionText(scene,legend){
        let descriptionContainer = scene.add.container(0,-90);
        descriptionContainer.setSize(420,50);
        let label = scene.add.graphics();

        label.fillStyle(0xffffff,0.5)
        .fillRoundedRect(-210,-25,420,50,15);

        let style = {
            fontFamily : 'Helvetica',
            fontSize : '23px',
            color : '#000',
            align : 'justify'}
        let text = scene.add.text(0,0,legend,style);
        text.setOrigin(0.5);

        descriptionContainer.add([label,text]);
        this.add(descriptionContainer);
    }

    addFields(scene){
        let group = scene.add.group()
        this.taleData.forEach((data)=>{
            let field = this.setField(scene,data);
            group.add(field);
        });
        gridAlignGroupElements(scene,group,{width:1,height:6,cellHeight:50,position:6,x:20});
        addChildrenToContainer(this,group.getChildren());
    }

}