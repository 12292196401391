import { generateQuizRecords, getPlayerQuizRecords } from "../services/gameServices/quizService"
import { CREATE_QUIZ_RECORDS_SUCCESS, LOAD_QUIZ_RECORDS_SUCCESS, LOAD_QUIZ_LIST } from "./actionTypes";


export function createNewQuizRecords(idPlayer){
    return dispatch => {
        return generateQuizRecords(idPlayer).then(response => {
            dispatch(createQuizRecordsSuccess())
        }).catch(error => {throw error})
    }
}

export function loadPlayerQuizRecords(idPlayer){
    return dispatch => {
        return getPlayerQuizRecords(idPlayer).then(response => {
            dispatch(loadQuizList(response.data));
            dispatch(loadQuizRecordsSuccess())
        }).catch(error => { throw error })
    }
}

function createQuizRecordsSuccess(){
    return { type : CREATE_QUIZ_RECORDS_SUCCESS };
}

function loadQuizList(recordList){
    return { type : LOAD_QUIZ_LIST, recordList };
}

function loadQuizRecordsSuccess(){
    return { type: LOAD_QUIZ_RECORDS_SUCCESS };
}