import Phaser from 'phaser';
import ChakraPanel from '../commonObjects/chakraPanel';
import ChakraState from './chakraState';
import { statesList, seedInitChakraState, chakraStatusInfo, chakraStatusList } from '../../utils/information/chakra-states/statesInformation';
export default class Chakra extends Phaser.GameObjects.Container{
    constructor(scene,x,y, chakraId){
        super(scene, x,y);
        this.setInitialChakraProperties(scene,chakraId);
        (chakraId)?this.buildChakra():this.buildGuideChakra();
    }

    getScene(){ return this.scene }
    setScene(scene){ this.scene = scene }

    getId(){ return this.chakraId }
    setId(id){ this.chakraId = id }

    getTotalStates(){ return this.totalStates }
    setTotalStates(number){ this.totalStates = number }

    getChakraStates(){ return this.chakraStates }
    setChakraStates(statesList){ this.chakraStates = statesList }

    getCompletedStates(){ return this.completedStates }
    setCompletedStates(statesList){ this.completedStates = statesList }

    getSeedData(){ return this.seedData }
    setSeedData(seedData){ this.seedData = seedData }

    getCurrentState(){ return this.currentState }
    setCurrentState(state){ this.currentState = state }
    
    getChakraPanel(){  return this.chakraPanel }
    setChakraPanel(panel){ this.chakraPanel = panel }

    getChakraIsReadyStatus(){ return this.isReady }
    setChakraIsReadyStatus(status){ this.isReady = status }

    
    getStatusData(){ return this.statusData }
    setStatusData(data){ this.statusData = data }

    getChakraStatus(){ return this.chakraStatus }
    setChakraStatus(status){ this.chakraStatus = status }

    getSmogAnim(){ return this.smogAnim }
    setSmogAnim(anim){ this.smogAnim = anim }
    
    getChakraSmog(){ return this.chakraSmog }
    setChakraSmog( sprite ){ this.chakraSmog = sprite }

    getAnimContainer(){ return this.animContainer }
    setAnimContainer(container){ this.animContainer = container }



    getChakraStateElement(index){ return this.getChakraStates()[index] }

    getChakraState(chakraStateId){return this.getChakraStates().find(state => {return state.getStateId() === chakraStateId})}


    setInitialChakraProperties(scene, chakraId){
        this.setScene(scene);
        this.setId(chakraId);
        this.setTotalStates(9);
        this.setChakraStates([]);
        this.setCompletedStates([]);
        this.setStatusData(chakraStatusInfo.EMPTY);
        this.setChakraStatus(this.getStatusData().status);
        this.setSeedData(null);
    }

    

    


    addChakraBody(){
        // this.fillInitialChakraStates(this.getScene());
        this.add(this.getCurrentState())
        .setSize(this.getCurrentState().width,this.getCurrentState().height);
        this.moveTo(this.getCurrentState(),0);
    }

    addChakraPanel(){
        this.setChakraPanel(new ChakraPanel(this.getScene(),0,125));
        this.add(this.getChakraPanel());
        this.hideChakraPanel();
    }

    hideChakraPanel(){
        this.getChakraPanel().setVisible(false);
    }

    showChakraPanel(){
        this.getChakraPanel().setVisible(true);
    }

    buildChakra(){
        if( localStorage.hasOwnProperty('loggedPlayer') &&
            localStorage.hasOwnProperty('chakraRecords') ){
                this.setCurrentState(null);
                this.fillInitialChakraStates(this.getScene());
                this.addChakraPanel();
                this.createChakraAnim();
                this.createAnimContainer();
        }else{
            this.setStandardChakraData();
            this.createChakraAnim();
            this.createAnimContainer();
        }   
    }

    buildGuideChakra(){
        this.setStandardChakraData();
    }

    setStandardChakraData(){
        this.setCurrentState(null);
        this.fillInitialChakraStates(this.getScene());
        this.setCurrentState(this.getChakraStateElement(0));
        this.addChakraBody();
        this.addChakraPanel();
        this.showChakraPanel();
        this.loadPanelName();   
    }

    createChakraState( record ){
        return new ChakraState(this.getScene(),0,0,record,this.getId());
    }

    addOneChakraState(chakraState){
        this.getChakraStates().push(chakraState);
    }

    completeOneChakraState(chakraState){
        this.getCompletedStates().push(chakraState);
    }

    fillInitialChakraStates(){
        statesList.forEach((record)=>{
            this.addOneChakraState(this.createChakraState(record));
        })
    }

    loadCurrentChakraState(chakraStateId){
        let chakraState = this.getChakraState(chakraStateId),
            statesList = this.getChakraStates();
        this.setCurrentState(chakraState);
        let index = statesList.indexOf(this.getCurrentState());
        for(let init = 0, finish = index;init<finish;init++){
            let chakraState = statesList[init];
            this.completeOneChakraState(chakraState);
            this.fillChakraProgress();
        }
        this.addChakraBody();
    }

    loadChakraStatus(status){
        let statusData = chakraStatusList.find(info=>{return info.status === status});
        this.setStatusData(statusData);
        this.setChakraStatus(this.getStatusData().status);
    }

    loadChakraData(data){
        this.loadChakraStatus(data.status);
        this.showChakraPanel();
        if(data.seedData!==null){
            this.addSeededState();
            this.fillChakraProgress();
            this.addSeedStatesToChakraList(data.seedData);
        }
        this.loadCurrentChakraState(data.currentStateId);
        this.loadPanelName();   
    }

    loadPanelName(){
        let status = this.getStatusData().status;
        switch(status){
            case 'occupied':
                let seedData = this.getSeedData();    
                this.renamePanelName(seedData.name);
            break;
            case 'completed':
                let seedInfo = this.getSeedData();    
                this.renamePanelName(seedInfo.name);
            break;
            default : 
                this.renamePanelName(this.getStatusData().name);
            break;

        }
    }

    activateChakraElements(){
        let currentState = this.getCurrentState();
        currentState.unlockElements();
        currentState.elementsList.forEach((element)=>{
            currentState.setElementBehavior(element);
        });
    }

    disactivateChakraElements(){
        let currentState = this.getCurrentState();
        currentState.lockElements();
    }

    checkCurrentStateStatus(){
        let currentState = this.getCurrentState(),
            scene = this.getScene(),
            chakraId = this.getId();
        if (currentState.isReady === true){
            this.completeState();
            scene.events.emit('startClock',chakraId);
        }
    }

    startClockCountdown(){
        let panel = this.getChakraPanel(),
            currentState = this.getCurrentState(),
            duration = currentState.getDuration(),
            chakraId = this.getId();
        panel.hideChakraStatusBar();
        panel.showTimeBar();
        panel.startCountdown(duration, chakraId);
    }

    finishClockCountdown(){
        let panel = this.getChakraPanel();
        panel.hideTimeBar();
        panel.showChakraStatusBar();
    }

    completeState(){
        let currentState = this.getCurrentState();
        this.completeOneChakraState(currentState);
        this.fillChakraProgress();
        this.passToTheNextState('reduceToolUnits');
    }

    passToTheNextState(code){
        let statesList = this.getChakraStates(),
            currentState = this.getCurrentState(),
            totalStates = this.getTotalStates(),
            index = (statesList.indexOf(currentState)) + 1;
            if(index<=totalStates){
                let newState = this.getChakraStateElement(index);
                this.setCurrentState(newState);
                currentState = this.getCurrentState();
                this.add(currentState);
                this.remove(this.getChakraStateElement(index-1));
                if(code){this.scene.events.emit(code)}
            }
    }

    checkChakraIsReady(){
        let completedStatesCounter = this.getCompletedStates().length;
        if(completedStatesCounter===4){
            console.log('chakra ',this.getId(),' says: "Im ready!"');
            this.setChakraIsReadyStatus(true);
            this.setStatusData(chakraStatusInfo.READY);
            this.setChakraStatus(this.getStatusData().status);
            this.renamePanelName(this.getStatusData().name);
            this.emitEvent('chakra_is_ready',this.getId());
        }
    }

    checkChakraIsSeeded(){
        let currentState = this.getCurrentState();
        if(currentState.isSeeded === true){
            this.setStatusData(chakraStatusInfo.SEEDED);
            this.setChakraStatus(this.getStatusData().status);
            this.completeOneChakraState(currentState);
            this.fillChakraProgress();
            this.addSeededState();
            this.passToTheNextState('getSeedData');
        }
    }

    checkChakraIsCompleted(){
        let completedStatesCounter = this.getCompletedStates().length,
            totalStates = this.getTotalStates();
        if(completedStatesCounter===totalStates){
            this.setStatusData(chakraStatusInfo.COMPLETED);
            this.setChakraStatus(this.getStatusData().status);
            console.log('chakra ',this.getId(),' is ',this.getChakraStatus());
            this.emitEvent('chakraCompleted',this.getId());
        }
    }

    fillChakraProgress(){
        let panel = this.getChakraPanel(),
            counter = this.getCompletedStates().length,
            totalStates = this.getTotalStates();
        panel.fillStatusBar(counter,totalStates);
        // console.log('counter: ',counter,'\ntotal: ',totalStates);
    }

    emitEvent(event,data){
        let scene = this.getScene();
        (data)?scene.events.emit(event,data):scene.events.emit(event);
    }

    addSeededState(){
        let scene = this.getScene(),
            stateData = seedInitChakraState.SEEDED,
            state = new ChakraState(scene,0,0,stateData,this.getId());
        this.addOneChakraState(state);
    }


    addSeedStatesToChakraList(seedData){
        this.setSeedData(seedData);
        let mySeedData = this.getSeedData();
        mySeedData.states.forEach(state=>{
            this.addOneChakraState(this.createChakraState(state));
        });
        this.renamePanelName(mySeedData.name);
    }

    renamePanelName(name){
        let panel = this.getChakraPanel();
        panel.setPlantName(name);
        panel.changePlantLabel();
    }

    createChakraAnim(){
        let scene = this.getScene(),
            anim = scene.anims.create({
                key : 'blow',
                frames : scene.anims.generateFrameNumbers('chakraSmogAnim'),
                frameRate : 12,
                repeat : -1
            }),
            smogSprite = scene.add.sprite(0,0,'chakraSmogAnim');
        this.setSmogAnim(anim);
        this.setChakraSmog(smogSprite);            
        smogSprite.anims.load('blow');
    }

    createAnimContainer(){
        let scene = this.getScene(),
            smogSprite = this.getChakraSmog(),
            container = scene.add.container(0,0,[smogSprite]);
        container.setSize(smogSprite.width,smogSprite.height)
        .setScale(0.5);
        this.setAnimContainer(container);
        this.add(this.getAnimContainer());

        this.hideAnimContainer();

        // smogSprite.anims.play('blow');
    }

    showAnimContainer(){
        let animContainer = this.getAnimContainer();
        animContainer.setVisible(true);
    }

    hideAnimContainer(){
        let animContainer = this.getAnimContainer();
        animContainer.setVisible(false);
    }

    startSmogAnim(){
        let smogSprite = this.getChakraSmog();
        smogSprite.anims.play('blow');
    }

    stopSmogAnim(){
        let smogSprite = this.getChakraSmog();
        smogSprite.anims.stop();
    }

    startTransformation(){
        this.hideChakraElements();
        this.showAnimContainer();
        this.bringToTop(this.getAnimContainer());
        this.startSmogAnim();
    }

    finishTransformation(){
        this.showChakraElements();
        this.hideAnimContainer();
        this.stopSmogAnim();
    }

    showChakraElements(){
        let currentState = this.getCurrentState();
        currentState.showElements();
    }

    hideChakraElements(){
        let currentState = this.getCurrentState();
        currentState.hideElements();
    }
}