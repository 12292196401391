import Phaser from 'phaser';
import { chakraBubbleResources } from '../../utils/assetsPath';

export default class ChakraBubble extends Phaser.GameObjects.Container{
    constructor(scene,x,y, toolResource){
        super(scene,x,y);
        this.setInitialProperties(scene,toolResource);
        this.createChakraBubble();
    }

    setScene(scene){ this.scene = scene }
    getScene(){ return this.scene }

    setIconData(iconData){ this.iconData = iconData }
    getIconData(){ return this.iconData }

    setChakraBubble(bubble){ this.chakraBubble = bubble }
    getChakraBubble(){ return this.chakraBubble }

    setToolIcon(icon){ this.bubbleIcon = icon }
    getToolIcon(){ return this.bubbleIcon }

    setInitialProperties(scene, data){
        this.setScene(scene);
        this.setIconData(data);
    }


    addBubble(){
        let scene = this.getScene(),
            bubble = scene.add.image(0,0,chakraBubbleResources.CHAKRA_BUBBLE.key);
        this.setChakraBubble(bubble);
        this.add(this.getChakraBubble());
    }

    addToolIcon(){
        let scene = this.getScene(),
        iconData = this.getIconData(),
        icon = scene.add.image(0,-60,iconData);
        this.setToolIcon(icon);
        this.add(this.getToolIcon());
    }

    createChakraBubble(){
        this.addBubble();
        this.addToolIcon();
    }



}