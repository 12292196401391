import {combineReducers} from 'redux';
import taleRecords from '../taleReducer';
import quizRecords from '../quizReducer';
import seedRecords from '../seedReducer';
import toolRecords from '../toolReducer';
import pieceRecords from '../pieceReducer';
import chakraRecords from '../chakraReducer';
import loggedPlayer from '../playerReducer';
import newPlayer from '../newPlayerReducer';

const rootReducer = combineReducers({
    taleRecords,
    quizRecords,
    seedRecords,
    toolRecords,
    pieceRecords,
    chakraRecords,
    loggedPlayer,
    newPlayer,

});

export default rootReducer;