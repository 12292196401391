import Phaser from 'phaser';
import { Image, Text } from '../../utils/functions';
import { mainTextStyle } from '../../utils/gameConstants';
export default class Label extends Phaser.GameObjects.Container{
    constructor(scene,x,y,texture,text){
        super(scene,x,y);
        this.body = new Image(scene,0,0,texture);
        this.text = new Text(scene,0,0,text,mainTextStyle);
        this.text.setOrigin(0.5);
        this.add([this.body,this.text]).setSize(this.body.width,this.body.height);
        scene.add.existing(this);
    }

    setTextSize(){
        this.text.displayWidth = this.body.width*0.8;
    }

    setTextStyle(style){
        this.text.setStyle(style);
    }
}