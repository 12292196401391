import apiClient from "../configService";


export function generatePuzzlePieceRecords(idPlayer){
    return apiClient.post('puzzle_piece_records/player',idPlayer);
}

export function getPlayerPuzzlePiecesRecords(idPlayer){
    return apiClient.get('puzzle_piece_records/player?playerId='+idPlayer);
}

export function updatePuzzlePieceEarnedStatus(pieceid, params){
    return apiClient.put('puzzle_piece_records/piece_record/earned/'+pieceid, params);
}

export function updatePuzzlePiecePlacedStatus(pieceid, params){
    return apiClient.put('puzzle_piece_records/piece_record/placed/'+pieceid, params);
}