import Box from "../baseClasses/Box";
import { libraryResources, taleSoundsResources, librarySoundResources } from "../../utils/assetsPath";
import Tale from "../baseClasses/tale";
import { gridAlignGroupElements, addChildrenToContainer } from "../../utils/functions";
import { scenesKey } from "../../utils/gameConstants";
import SelectedTaleScene from "../../GameScenes/selectedTaleScene";
import { selectedTaleDialogsList } from "../../utils/information/assistant/assistantDialogs";
import { updatePlayerTaleRecords } from "../../../../services/gameServices/talesService";
import { changeTaleRecordStatus } from "../../utils/functions/save-record-functions";
import { quickGameTalesList } from "../../utils/information/tales/talesInformation";

export default class TaleLibrary extends Box{
    constructor(scene,x,y){
        super(scene,x,y,libraryResources.LIBRARY.key);
        this.scene = scene;
        this.completedTales = [];
        this.talesList = [];
        
        this.addBox(scene);
        this.box.setScale(1.4,1.12);
        
        this.buildLibrary(scene);
    }

    createNewTale(scene,taleRecord){
        let tale = new Tale(scene,0,0,taleRecord,'tale');
        this.talesList.push(tale);
    }

    displayTales(scene){
        let gridConfig = { width:4, height:3, cellWidth:220,
            cellHeight:303, position:6, x:-330,y:-300};
        let group = scene.add.group(this.talesList);
        gridAlignGroupElements(scene,group,gridConfig);
        addChildrenToContainer(this,group.getChildren());
    }

    buildLibrary(scene){
        let list = [];
        if(localStorage.hasOwnProperty('loggedPlayer') &&
            localStorage.hasOwnProperty('taleRecords')){
                list = JSON.parse(localStorage.getItem('taleRecords'));
        }else{ 
            list = quickGameTalesList

        }
        list.forEach((record)=>{
            this.createNewTale(scene,record);
        });
        this.displayTales(scene);
    }


    setTaleActions(scene){
        this.shownTales = this.talesList;
        this.shownTales.forEach((tale)=>{
            tale.setInteractive({useHandCursor : true});
            tale.on('pointerdown',()=>{
                tale.body.setTint(0x2d2d2d);
            });
            console.log('tale ',tale.getId() ,'is blocked? ',tale.isBlocked());
            switch(tale.isBlocked()){
                case true:
                    tale.on('pointerup',()=>{
                        scene.sound.play(librarySoundResources.PADLOCK_CLING.key);
                        tale.body.clearTint();
                        tale.shakePadlock(scene);
                    });    
                break;
                case false:
                    if (tale.status==="new") { tale.addAnimations(scene)}
                    tale.on('pointerup',()=>{
                        scene.sound.play(librarySoundResources.TALE_SELECTION.key);
                        tale.body.clearTint();
                        switch(tale.status){
                            case 'new':
                                tale.pumpAnim.remove();
                                changeTaleRecordStatus(tale.id,'seen',this.talesList);
                                this.selectTale(scene,tale);
                            break;
                            default :
                                this.selectTale(scene,tale);
                            break;
                        }
                    });
                break;
                default :
                break;
            }
        })
    }

    selectTale(scene,tale){
        let taleId = tale.id,
            bookData = tale.bookData;

        let newTaleKey = scenesKey.SELECTED_TALE_SCENE+'_'+taleId;
        let aux = scene.scene.get(newTaleKey);
        if(aux===null){
            localStorage.setItem('selectedTale',newTaleKey);
            this.createNewScene(scene,newTaleKey,bookData);
            //loadData
            this.changeScene(scene,newTaleKey);
            
        }else{
            localStorage.setItem('selectedTale',newTaleKey);
            scene.scene.switch(newTaleKey);
            //loadData
            this.changeScene(scene,newTaleKey);
        }
    }

    changeTaleRecordStatus(idTale, status){
        let loggedPlayer = JSON.parse(localStorage.getItem('loggedPlayer')),
            params = {playerid : loggedPlayer.id, status};

        updatePlayerTaleRecords(idTale,params).then(response => {
            let talesRecords = JSON.parse(localStorage.getItem('taleRecords')),
                oldRecord = talesRecords.find((tale)=>{return tale.tales_id === idTale}),
                newRecord = response.data[0],
                index = talesRecords.indexOf(oldRecord);
            talesRecords[index] = newRecord;
            localStorage.setItem('taleRecords',JSON.stringify(talesRecords));
        });

        let choosenTale = this.talesList.find((tale)=>{return tale.id === idTale});
        choosenTale.changeStatus(status);
    }

    createNewScene(scene,key,data){
        let mainUIScene = scene.scene.get(scenesKey.MAINUI_SCENE);
        mainUIScene.addNewScene(key);
        scene.scene.add(key,new SelectedTaleScene(key),true,data);
    }

    changeTaleStatus(tale){
        let index = tale.statusList.indexOf(tale.status);
        let newIndex = index+1;
        if(index===tale.statusList.length-1){
            newIndex = 0;
        }
        tale.changeStatus(tale.statusList[newIndex]);
    }

    completeATale(bookData){
        let completedTale = this.talesList.find((tale)=>{
            return tale.bookData === bookData;
        });
        changeTaleRecordStatus(completedTale.id,'completed',this.talesList);
    }

    changeScene(scene,sceneKey){
        let mainUIScene = scene.scene.get(scenesKey.MAINUI_SCENE);
        mainUIScene.setModuleDialogs(selectedTaleDialogsList.random_dialogs);
        mainUIScene.setSelectedScene(scene.scene.get(sceneKey));
        mainUIScene.updateWindowScene();
    }
}