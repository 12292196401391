import { chakraResources, chakraSoundResources } from "../../utils/assetsPath";
import ToolButton from "./toolButton";
import Label from "../baseClasses/Label";
import Menu from "../baseClasses/Menu";
import { toolsInformation, toolsList } from "../../utils/information/tools/toolsInformation";
import { updatePlayerToolRecord } from "../../../../services/gameServices/toolsService";


export default class ToolsBox extends Menu {
  constructor(scene, x, y) {
    super(scene, x, y, chakraResources.TOOLS_BOX.key);
    this.gridConfig= {width:2,height:3,cellWidth:150,cellHeight:170,x:-75,y:-120,position:1};
    this.styleConfig = {backColor:0xae091a,fontColor:'#ffffff'};
    this.addPlaque(scene);
    this.addToolButtons(scene);

    this.activeButtons(scene);
    this.setIsFreezed(false);
  }

  getIsFreezed(){ return this.isFreezed}
  setIsFreezed(value){ this.isFreezed = value }


  addPlaque(scene){
    this.plaque = new Label(scene,0,0,chakraResources.TOOLS_BOX_PLAQUE.key,'CAJA\nDE HERRAMIENTAS');
    this.plaque.setTextStyle({fontFamily : 'Franklin Gothic', fontSize : '34px', stroke : "#33034E", color : '#9557B8', strokeThickness : 4.5})
    this.plaque.setPosition(0,-this.background.height*0.4);
    this.add(this.plaque);
  }

  addToolButtons(scene){
    this.compostBtn = new ToolButton(scene,0,0,toolsInformation.COMPOST_TOOL);
    this.hoeBtn = new ToolButton(scene,0,0,toolsInformation.HOE_TOOL);
    this.scissorsBtn = new ToolButton(scene,0,0,toolsInformation.SCISSORS_TOOL);
    this.shovelBtn = new ToolButton(scene,0,0,toolsInformation.SHOVEL_TOOL);
    this.sprayBtn = new ToolButton(scene,0,0,toolsInformation.SPRAY_TOOL);
    this.waterBtn = new ToolButton(scene,0,0,toolsInformation.WATER_TOOL);
    this.childrenList = [this.compostBtn,this.hoeBtn,this.scissorsBtn,this.shovelBtn,this.sprayBtn,this.waterBtn];
    this.createMenu(scene);
  }

  activeButtons(scene){
    this.childrenList.forEach((tool)=>{
      tool.on('pointerdown',()=>{
        scene.sound.play(chakraSoundResources.TOOL_CLICK.key);
        this.chooseButton(tool,this.styleConfig);
        this.changeCursor(scene);
        scene.events.emit('setSelectedTool');
        scene.events.emit('newTool');
      });
      tool.on('pointerup',()=>{
        this.discardButton();
      });
    });
  }

  cleanButtons(){
    this.selectedButton = null;
    this.discardButton();
  }

  removeOneUnit(scene){
    this.selectedButton.unitLabel.substractCounter(scene);
    let quantity = this.selectedButton.unitLabel.getCounter();
    let toolInfo = this.getSelectedToolInfo();
    this.changeToolRecord(toolInfo.id, quantity);
  }

  getSelectedToolInfo(){
    return toolsList.find((tool)=>{return tool.type === this.selectedButton.type});
    
  }

  changeToolRecord(toolId, quantity){
    if( localStorage.hasOwnProperty('loggedPlayer') && 
        localStorage.hasOwnProperty('toolRecords')){
      let toolRecords = JSON.parse(localStorage.getItem('toolRecords')),
      oldRecord = toolRecords.find((record)=>{return record.tool_id === toolId}),
      index = toolRecords.indexOf(oldRecord),
      loggedPlayer = JSON.parse(localStorage.getItem('loggedPlayer')),
      params = {playerid : loggedPlayer.id, quantity};

      updatePlayerToolRecord(toolId, params).then(response => {
          let newRecord = response.data[0];
          toolRecords[index] = newRecord;
          localStorage.setItem('toolRecords',JSON.stringify(toolRecords));
      })
    }
}

  changeHandCursor(scene){
    scene.input.setDefaultCursor('url(default.cur), pointer');
  }

  changeCursor(scene){
    scene.input.setDefaultCursor(`url(${this.selectedButton.cursor.path}),${this.selectedButton.cursor.type}`);
  }

  removeCursor(scene){
    scene.input.setDefaultCursor('url(default.cur),auto');
  }

  checkToolButtons(){
    this.childrenList.forEach((tool)=>{
      tool.checkUnits();
    });
  }

  getSelectedButton(){
    return this.selectedButton;
  }

  getToolsList(){
    return this.childrenList;
  }

  freezeButtons(){
    this.childrenList.forEach((button)=>{
      button.disableInteractive();
    })
    this.setIsFreezed(true);
    console.log('done');
  }

  comeBackButtons(){
    this.childrenList.forEach((button)=>{
      button.setInteractive();
    })
    this.setIsFreezed(false);
  }

}
