import Button from "../baseClasses/Button";
import { mainUIResources } from "../../utils/assetsPath";
import Label from "../baseClasses/Label";

export default class AssistantMenuBtn extends Button{
    constructor(scene,x,y,icon,title){
        super(scene,x,y,mainUIResources.BODY_BTN_ASSISTANT_MENU.key,icon,title);
        this.label = new Label(scene,0,0,mainUIResources.LABEL_ASSISTANT_MENU_BTN.key,this.text);
        this.circle = null;
        this.createIconContainer(scene);
        this.gridConfig={width:1};
        this.label.text.setStroke(0x000,1);
        this.buildButton(scene);
        this.setSize(this.btnContainer.width,this.btnContainer.height);
        scene.add.existing(this);
    }

    createIconContainer(scene){
        this.circle = scene.add.image(0,0,mainUIResources.BODY_ICON_BTN_ASSISANT_MENU.key);
        let iconContainer = scene.add.container(0,-20,[this.circle,this.icon]);
        iconContainer.setSize(this.circle.width,this.circle.height);
        this.label.setPosition(0,30);
        this.btnContainer = scene.add.container(0,0,[this.background,iconContainer,this.label]);
        this.btnContainer.setSize(this.background.width,this.background.height);
        this.elementsList.push(this.btnContainer);
    }


}