import { welcomeSceneResources } from "../../../assetsPath";

export const optionButtonsInformation = {
    OPTION_FAST_GAME : {
        optionTexture : welcomeSceneResources.BUTTON_FAST_GAME.key,
        pressedOptionTexture : welcomeSceneResources.PRESSED_BUTTON_FAST_GAME.key,
        iconTexture : welcomeSceneResources.ICON_BUTTON_FAST_GAME.key,
        pressedIconTexture : welcomeSceneResources.PRESSED_ICON_BUTTON_FAST_GAME.key
    },
    OPTION_SIGN_UP : {
        optionTexture : welcomeSceneResources.BUTTON_SIGN_UP.key,
        pressedOptionTexture : welcomeSceneResources.PRESSED_BUTTON_SIGN_UP.key,
        iconTexture : welcomeSceneResources.ICON_BUTTON_SIGN_UP.key,
        pressedIconTexture : welcomeSceneResources.PRESSED_ICON_BUTTON_SIGN_UP.key
    },
    OPTION_LOG_IN : {
        optionTexture : welcomeSceneResources.BUTTON_LOG_IN.key,
        pressedOptionTexture : welcomeSceneResources.PRESSED_BUTTON_LOG_IN.key,
        iconTexture : welcomeSceneResources.ICON_BUTTON_LOG_IN.key,
        pressedIconTexture : welcomeSceneResources.PRESSED_ICON_BUTTON_LOG_IN.key
    },
    OPTION_INSTRUCTIONS : {
        optionTexture : welcomeSceneResources.BUTTON_INSTRUCTIONS.key,
        pressedOptionTexture : welcomeSceneResources.PRESSED_BUTTON_INSTRUCTIONS.key,
        iconTexture : welcomeSceneResources.ICON_BUTTON_INSTRUCTIONS.key,
        pressedIconTexture : welcomeSceneResources.PRESSED_ICON_BUTTON_INSTRUCTIONS.key
    },
    OPTION_CREDITS : {
        optionTexture : welcomeSceneResources.BUTTON_CREDITS.key,
        pressedOptionTexture : welcomeSceneResources.PRESSED_BUTTON_CREDITS.key,
        iconTexture : welcomeSceneResources.ICON_BUTTON_CREDITS.key,
        pressedIconTexture : welcomeSceneResources.PRESSED_ICON_BUTTON_CREDITS.key
    },
    OPTION_RETURN : {
        optionTexture : welcomeSceneResources.BUTTON_RETURN.key,
        pressedOptionTexture : welcomeSceneResources.PRESSED_BUTTON_RETURN.key,
        iconTexture : welcomeSceneResources.ICON_BUTTON_RETURN.key,
        pressedIconTexture : welcomeSceneResources.PRESSED_ICON_BUTTON_RETURN.key
    },
    OPTION_PLAY_GAME : {
        optionTexture : welcomeSceneResources.BUTTON_PLAY_GAME.key,
        pressedOptionTexture : welcomeSceneResources.PRESSED_BUTTON_PLAY_GAME.key,
        iconTexture : welcomeSceneResources.ICON_BUTTON_PLAY_GAME.key,
        pressedIconTexture : welcomeSceneResources.PRESSED_ICON_BUTTON_PLAY_GAME.key
    },
    OPTION_EXIT : {
        optionTexture : welcomeSceneResources.BUTTON_EXIT.key,
        pressedOptionTexture : welcomeSceneResources.PRESSED_BUTTON_EXIT.key,
        iconTexture : welcomeSceneResources.ICON_BUTTON_EXIT.key,
        pressedIconTexture : welcomeSceneResources.PRESSED_ICON_BUTTON_EXIT.key
    },

}