export const secretsQuizList = {
    QUESTION_SECRET_PAPA : {
        question : '¿Cuál es el secreto acerca de la siembra de papas?',
        options : ['Sentir con las manos si están sudando','Colocar tres granitos en referencia al padre, hijo y espíritu','Colocar chicha en cada esquina de la chacra','Conversar con la allpa mama para que pueda sembrar'],
        answer : 'Sentir con las manos si están sudando'
    },
    QUESTION_SECRET_MAIZ : {
        question : '¿Cuál es el secreto para sembrar el maíz?',
        options : ['Levantarse de mañana y atrapar algunos catzos','Colocar tres granitos en referencia al padre, hijo y espíritu','Sentir con las manos si están sudando','Colocar chicha en cada esquina de la chacra'],
        answer : 'Colocar tres granitos en referencia al padre, hijo y espíritu'
    },
    QUESTION_SECRET_CHACARERO : {
        question : '¿Qué secreto debe hacer un chacarero para tener una buena producción?',
        options : ['Ponerse a conversar con los cuates','Ponerse a labrar la tierra','Ponerse a conversar con la allpamama','Ponerse de acuerdo en sembrar todas sus semillas'],
        answer : 'Ponerse a conversar con la allpamama'
    },
    QUESTION_SECRET_LADRONES : {
        question : '¿Cuál es el secreto para alejar a los ladrones de una casa?',
        options : ['Colocar un espantapájaros afuera de la casa','Poner candado en toda las puertas y ventanas','Vigilar las casas durante las noches','Enterrar los huesos de los difuntos cerca de la casa'],
        answer : 'Enterrar los huesos de los difuntos cerca de la casa'
    },
    QUESTION_SECRET_DEUDA : {
        question : '¿Cuál es el secreto para que una persona tenga suerte?',
        options : ['Pagar una deuda durante la madrugada','Pagar una deuda durante la tarde','Pagar una deuda durante la noche','Pagar una deuda durante el anochecer'],
        answer : 'Pagar una deuda durante la madrugada'
    },
    QUESTION_SECRET_PLATITA : {
        question : '¿Cuál es el secreto para que a una persona no le falte platita?',
        options : ['Colocar chicha en las cuatro\nesquinas de la chacra','Colocar unos centavitos sobre la chacra','Salir a rezar a los cerros\ny montañas todo el día','Sembrar algunas moneditas en la chakrita'],
        answer : 'Colocar unos centavitos sobre la chacra'
    },
    QUESTION_SECRET_PLANTA_MEDICINAL : {
        question : '¿Cuál es el secreto para recoger plantas medicinales en la noche?',
        options : ['Taparse ojos y oídos para no alterar a los sembríos','Caminar despacito para no levantar a los apus y familiares','Cerrar los ojos mientras se reza al mundo sagrado','Arrancar lo más rápido posible a la plantita para que no muera'],
        answer : 'Cerrar los ojos mientras se reza al mundo sagrado'
    },
    QUESTION_SECRET_RECUPERAR_SALUD : {
        question : '¿Cuál es el secreto para recuperar el estado de salud?',
        options : ['Tomar mucho antibiótico recetado por el doctor','Hacer gárgaras con agua mezclada con sal','Tomar mucho aguardiente para matar a los microbios','Cerrar los ojos mientras se reza al mundo sagrado'],
        answer : 'Cerrar los ojos mientras se reza al mundo sagrado'
    },
    QUESTION_SECRET_ESPIRITU : {
        question : '¿Cuál es el secreto para sanar el espíritu?',
        options : ['Tomar té de rumishungu durante tres días','Comer mucha fritada y contentar a la barriga','Trabajar con mucho esfuerzo en las chacras','Dormir durante una semana completa'],
        answer : 'Tomar té de rumishungu durante tres días'
    },
    QUESTION_SECRET_SAL : {
        question : '¿Cuál es el secreto para evitar tener mala suerte?',
        options : ['No saludar a los mayores','No coger sal con la mano','No hacerle caso a una hatun mama o madre sabia','No molestar a las garzas y gaviotas'],
        answer : 'No coger sal con la mano'
    },
    QUESTION_SECRET_BEBE_ARMONIA : {
        question : '¿Cuál es el secreto para que un bebe crezca en armonía?',
        options : ['Sacarlo a pasear durante el invierno','Darle de comer puras golosinas para endurecer su barriguita','Poner pisca de sal y de dulce en agua y untarle en la frente','Darle té de rumishungu durante tres días'],
        answer : 'Poner pisca de sal y de dulce en agua y untarle en la frente'
    },
    QUESTION_SECRET_BEBE_ABUNDANCIA : {
        question : '¿Cuál es secreto para que un bebé tenga abundancia?',
        options : ['Llenar adornar su camita con un montón de flores silvestres','Darle de comer mucha máchica y que gane fuerza','Darle de tomar sangre de toro y que gane valentía','Hacerle cargar bolsita de machica y granos en su cuello'],
        answer : 'Hacerle cargar bolsita de machica y granos en su cuello'
    },
    QUESTION_SECRET_BEBE_FUERTE : {
        question : '¿Cuál es el secreto para que un bebé crezca fuerte?',
        options : ['Hacerle un Maito en todo el cuerpo usando telas','Hacerle una rica supalata y un platito de machica','Darle unas palmaditas en la espalda','Untarle sal y dulce con agua en su frente'],
        answer : 'Hacerle un Maito en todo el cuerpo usando telas'
    },
    QUESTION_SECRET_SALIVA : {
        question : '¿Cuál es el secreto para endurecer los piecitos de un niño?',
        options : ['Ponerle ungüento en la planta del pie','Darle masajes con la saliva de la abuela','Ponerle un curita en el pie y darle kukayu','Frotarle con las hierbitas de eucalipto'],
        answer : 'Darle masajes con la saliva de la abuela'
    },
    QUESTION_SECRET_TORO : {
        question : '¿Cuál es el secreto para repeler la vaguería?',
        options : ['Tomar té de rimushungu durante tres días','Conversar con los apús y pedir su bendición','Beber la sangre del toro','Comer mucha máchica para endurecer el estómago'],
        answer : 'Beber la sangre del toro'
    },
    QUESTION_SECRET_ASUSTADA : {
        question : '¿Cuál es el secreto para curar a una persona asustada?',
        options : ['Beber un tecito de corazón\nde piedra y tomarlo caliente','Frotarse una vela para que los\napus atrapen los malos aires','Contarle un chiste para que\nsonría y suelte una risa','Preparar waspiti con plantas\nmedicinales y frotarle con el'],
        answer : 'Preparar waspiti con plantas\nmedicinales y frotarle con el'
    },
    QUESTION_SECRET_PELEA_PERROS : {
        question : '¿Cuál es el secreto para no tener conflictos familiares tras una pelea de perros?',
        options : ['Espantarlos con agua de lluvia','Amenazarlos con una piedra invisible','Lanzarles un poco de kukayu','Hacer sonar un ruido que los espante'],
        answer : 'Espantarlos con agua de lluvia'
    },
    QUESTION_SECRET_MAL_OJO : {
        question : '¿Cuál es el secreto para no contraer mal de ojo?',
        options : ['Envolver con hierbas silvestres los ojos','Cargar en la mano derecha semillas rojas','Untar sal y dulce remojados en los ojos','Coger sal con la mano derecha'],
        answer : 'Cargar en la mano derecha semillas rojas'
    },
    QUESTION_SECRET_HOJA_CULANTRO : {
        question : '¿Cuál es el secreto para tranquilizar a un niño que no puede dormir?',
        options : ['Envolverle con sábanas calientes en todo el cuerpo','Cantarle una canción alegre y enternecedora','Poner una hojita de culantro debajo de la almohada','Contarle una historia aburrida para que le dé sueño'],
        answer : 'Poner una hojita de culantro debajo de la almohada'
    },
    QUESTION_SECRET_BEBE_HABLA : {
        question : '¿Cuál es el secreto para que un bebé comience a hablar?',
        options : ['Hacerle escuchar una canción antigua tradicional','Hacerle reír mediante el canto y el baile','Conversarle durante toda una tarde','Coger una flor silvestre y ponerle en la boquita'],
        answer : 'Coger una flor silvestre y ponerle en la boquita'
    },
    QUESTION_SECRET_PEPINO : {
        question : '¿Cuál es el secreto para que un bebé no sea enfermizo?',
        options : ['Colgarle una miniatura de pepino dulce','Envolverle en sábanas en todo el cuerpo','Colgarle una bolsita de máchica y granos en su cuello','Darle de comer mucha alfalfa durante una semana'],
        answer : 'Colgarle una miniatura de pepino dulce'
    },
    QUESTION_SECRET_ARENA : {
        question : '¿Cuál es el secreto para que un niño no tenga problemas de huesos?',
        options : ['Frotarle hierba luisa con mucha fuerza','Revolcarlo en la arena del río caliente','Ponerle ungüento en todo su cuerpo','Darle de comer cuy para que agarre fuerza'],
        answer : 'Revolcarlo en la arena del río caliente'
    },
    QUESTION_SECRET_MOJAR_CAMA : {
        question : '¿Cuál es el secreto para que un niño deje de mojar la cama?',
        options : ['Enviarlo al baño antes de ir a dormir','Cantarle una canción de cuna relajante','Obligarle a pedir sal al vecino y que le de vergüenza','Cubrir su cama con hierbas silvestres'],
        answer : 'Obligarle a pedir sal al vecino y que le de vergüenza'
    },
    QUESTION_SECRET_PARTO : {
        question : '¿Cuál es el secreto para ver si el parto está cerca?',
        options : ['Ver si la figura del vientre es un huevito','Ver si la figura del vientre es una pelota','Preguntarle a la mamá cómo se siente','Sentir muy caliente la coronilla de la mamá'],
        answer : 'Sentir muy caliente la coronilla de la mamá'
    },
}