import Phaser from 'phaser';
import { caracResources } from '../../utils/assetsPath';
import { slotsInfo } from '../../utils/definitions';

export default class PuzzleSlot extends Phaser.GameObjects.Container{
    constructor(scene,x,y){
        super(scene,x,y);
        this.setSlotInitData();
    }

    setSlotInitData(){
        this.setPuzzlePiece(null);
        this.setParent(null);
        this.setSlotStatus(true);
    }


    setSlotStatus(status){
        this.isAvailable = status;
    }

    getSlotStatus(){return this.isAvailable}

    setSlotID(id){
        this.slotID= id;
    }
    
    setSlotType(slotType){
        this.slotType = slotType;
    }

    setParent(parent){
        this.monthParent = parent;
    }

    setPuzzlePieceId(id){
        this.puzzlePieceId = id;
    }

    setPuzzlePiece (piece){
        this.puzzlePiece = piece;
    }

    setSlotXScale(xScale){
        this.xScale = xScale;
    }

    setSlotYScale(yScale){
        this.yScale = yScale;
    }

    setSlotScale(){
        this.setScale(this.xScale,this.yScale);
    }

    setSlotProperties(id){
        this.slotProperties = slotsInfo.find((slot)=>{
            return slot.id === id; 
        });

        this.setSlotXScale(this.slotProperties.xScale);
        this.setSlotYScale(this.slotProperties.yScale);
        this.setSlotType(this.slotProperties.type);
    }

    

    

    

    blockSlot(){
        if (this.puzzlePiece!==null) this.isAvailable=false;
    }

    checkSlotStatus(){
        if(this.isAvailable===true){
            this.setInteractive({dropZone:true});
        } else{
            this.disableInteractive();
            this.monthParent.checkSlot(this);
        }
    }

    addPuzzleBoard(scene){
        this.board = scene.add.image(0,0,caracResources.SLOT_BODY.key);
        this.add(this.board).setSize(this.board.width,this.board.height);
    }

    addBodyColor(scene,bodyColor){
        this.bodyColor = scene.add.image(0,0,bodyColor);
        this.bodyColor.setScale(0.9);
        this.add(this.bodyColor);
    }

    addNewPuzzlePiece(piece){
        this.setPuzzlePiece(piece);
        this.blockSlot();
        this.checkSlotStatus();
    }

    displaySlot(scene){
        scene.add.existing(this);
        this.checkSlotStatus();
    }

    checkPieceType(piece,type){
        console.log('pieza adquirida: ',piece);
        if(piece.type===type){
            this.setPuzzlePiece(piece);
        }
    }

    // checkPieceId(piece, id){
    //     if(piece.id===id){
    //         return true;
    //     }else {
    //         return false;
    //     }
    // }

    buildSlot( scene , data ){
        this.setSlotProperties(data.type);
        this.addPuzzleBoard(scene);
        this.addBodyColor(scene,data.bodyColor);
        this.displaySlot(scene);
        this.setSlotScale();
    }
}