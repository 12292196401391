export const assistantDialogs = {
    intro_dialogs : [
    'Hola mi querido niño.\nMi nombre es Mama Dulu.  Bienvenido al juego de aprendizaje cultural "FUNCARAC".',
    'Preparate para conocer las costumbres y tradiciones más importantes de la serranía ecuatoriana.',
    'Para empezar a jugar, te recomiendo hacerle caso a la flecha y presionar el botón GUÍA para conocer cómo se juega.'
    ]
}

export const soundDialogs = {
    SOUND_BTN_DIALOGS: 'La música de fondo está apagada',
    MUTE_BTN_DIALOGS: 'La música de fondo está encendida'
}

export const caracDialogsList = {
    randomDialogs : [
        'Si te sientes perdido, haz clic en el botón "GUÍA" para que te pueda explicar cómo funciona el juego del Calendario Agrofestivo.',
        'Para armar el tablero del calendario agrofestivo, selecciona y arrastra una de las piezas disponibles en el carrusel "Piezas de Rompecabezas Disponibles".',
        'Para ganar una nueva pieza de rompecabezas, dirígete a la pestaña "Relatos Vivenciales", selecciona un relato disponible, y contesta una de sus trivias correctamente.',
        'Recuerda que solo podrás colocar las piezas de rompecabezas en las casillas del mismo color.',
        'Si colocas una pieza de rompecabezas en la casilla correcta, podrás ganar herramientas que te ayudarán a críar a tus chakras.',
        'Si colocas una pieza de rompecabezas en una casilla de un color incorrecto no se colocará en el tablero y regresará al carrusel "Piezas de Rompecabezas Disponibles".',
        'Cuando hayas ganado más de tres piezas de rompecabezas, en el carrusel "Piezas de Rompecabezas Disponibles" aparecerán dos flechas de navegación.',
        'Haz clic en las flechas de navegación, arriba o abajo, que aparecen en el carrusel "Piezas de Rompecabezas Disponibles", para desplazarte por todas las piezas de rompecabezas que hayas ganado.',
        'Recuerda que al completar un mes con sus cuatro piezas de rompecabezas, podrás ganar una nueva semilla como recompensa.'
    ],
    guide_dialogs : {
        INTRO_DIALOG : 'Hola y bienvenido a la guia de instrucciones del calendario agrofestivo.\nPresta mucha atención porque te enseñaré cómo se debe jugar.',
        DIALOG_01 : 'Este es el tablero principal!\nRepresenta un auténtico calendario cultural tradicional. Para jugar, deberás completar cada una de sus casillas con las piezas disponibles en el carrusel que lo acompaña.',
        DIALOG_02 : 'El calendario está dividido en doce meses y cada mes tiene cuatro casillas de colores (Rojo, Amarillo, Verde y Azul).',
        DIALOG_03 : 'Las casillas rojas, representan a la festividad más importante que se celebra en ese mes.\nÚnicamente podrás colocar piezas rojas en este tipo de casillas.',
        DIALOG_04 : 'Las casillas amarillas, representan a la comida más importante que se consume en ese mes.\nÚnicamente podrás colocar piezas amarillas en este tipo de casillas.',
        DIALOG_05 : 'Las casillas verdes, representan a las señas y secretos comunes que son practicados durante ese mes.\nÚnicamente podrás colocar piezas verdes en este tipo de casillas.',
        DIALOG_06 : 'Las casillas azules, representan los dialectos practicados y aprendidos en ese mes.\nÚnicamente podrás colocar piezas azules en este tipo de casillas.',
        DIALOG_07 : 'Este es el carrusel de piezas de rompecabezas disponibles!\nAquí encontrarás todas las piezas de rompecabezas que hayas ganado cuando superes las trivias ancestrales.',
        DIALOG_08 : 'Puedes desplazarte por todas tus piezas conseguidas en el carrusel, si presionas la flecha que indica hacia abajo.',
        DIALOG_09 : 'También puedes retroceder y desplazarte por las piezas conseguidas, si presionas la flecha que indica hacia arriba.',
        DIALOG_10 : 'Para jugar, simplemente selecciona una de las piezas que se muestran en la Caja de Piezas Disponibles, y arrástrala hacia cualquier casilla del Calendario Agrofestivo.\nPara colocar la pieza, simplemente suéltala.',
        DIALOG_11 : 'Al completar una casilla, se vuelve a ordenar las piezas del carrusel.\nLas flechas del carrusel únicamente se mostrarán cuando haya más de tres piezas en él.',
        DIALOG_12 : 'Si colocas una pieza en una casilla, cuyo color o mes sean incorrectos, la pieza no se colocará en el calendario, y volverá automáticamente al carrusel de piezas disponibles.',
        DIALOG_13 : 'Cuando hayas completado las cuatro casillas de un mes con sus respectivas piezas, habrás ganado una recompensa.\nMuy bien! Ahora ya conoces las instrucciones del juego.\nHaz clic en el botón para salir de la guía.',
    },

    puzzle_pieces_dialogs : [
        '¡Felicidades pequeño niño!\nHaz Logrado colocar correctamente una pieza del rompecabezas en el calendario agrofestivo.',
        '¡Muy bien querido amigo!\nLograste colocar una pieza en la casilla correcta del calendario agrofestivo.\n\n¡Sigue así!',
        '¿Pusiste un pieza en el calendario agrofestivo?\nGuau, me impresionas amigo mío.\n¡Vas muy bien!',
        '¡Excelente!\nHaz puesto una nueva pieza en el calendario agrofestivo!\n',
        '¡Nueva pieza colocada!\n¡Muy bien!\nSigue así y muy pronto el calendario agrofestivo estará terminado!'
    ],

    new_seed_earned_dialogs : [
        '¡Excelente!\nHaz completado todas las piezas de un mes del calendario y conseguiste una semilla nueva.\n¡Muy buen trabajo!'
    ]
}

export const chakraDialogsList = {
    randomDialogs : [
        'Si te sientes perdido, haz clic en el botón "GUÍA" para que te pueda explicar cómo funciona el juego de la Chacra Comunitaria.',
        'Cuando selecciones una herramienta, podrás ver todas las chacras que pueden ser modificadas por esa herramienta',
        'Cuando selecciones una semilla, podrás ver todas las chacras listas para ser sembradas.',
        'Cuando hayas sembrado una semilla, se marcará una palomilla indicando que ya ha sido usada.',
        'Solo podrás sembrar una sola vez cada semilla coleccionable en este juego.',
        'Mientras tu chacra este cambiando, prueba a seguir criando otras chacras.',
        'Mientras ocurra una transformación de estado, la chacra no podrá ser utilizada.',
        'La transformación de estado durará hasta que el temporizador de la chacra haya terminado.',
        'Cada vez que modifiques una chacra, su barra de progreso aumentará.',
        'Cuando completes toda la barra de progreso de una chacra, desbloquearás un nuevo relato vivencial.'
    ],

    guide_dialogs : {
        INTRO_DIALOG : 'Hola y bienvenido a la guia de instrucciones de la Chakra Comunitaria.\nPresta mucha atención porque te enseñaré cómo se debe jugar.',
        DIALOG_01 : 'Esta es la pantalla de la Chakra Comunitaria!\nAquí podrás plantar las semillas que vayas recolectando a lo largo del juego, y recrear el proceso de crianza de las chakras tradicionales de la sierra ecuatoriana.',
        DIALOG_02 : 'Esta es la zona de chakras que dispones para plantar tus semillitas recolectadas.\nSolo basta con seleccionar una de sus chakras, y plantar sobre ella una semillita',
        DIALOG_03 : 'Esta es la caja de semillas coleccionables.\nEstará vacía al inicio del juego. Se llenará de nuevas semillas a medida que vayas completando los meses del calendario agrofestivo.',
        DIALOG_04 : 'Esta es la caja de herramientas. Aquí encontrarás las herramientas necesarias para criar a las chakras.',
        DIALOG_05 : 'Cada una de estas herramientas lleva un contador de unidades.\nPuedes conseguir nuevas unidades al poner una pieza de rompecabezas en su casilla correcta del calendario agrofestivo.',
        FINAL_DIALOG : 'Muy bien!\n\nAhora ya conoces cómo funciona la Chakra Comunitaria.\n\nPulsa en el botón para salir de la guía.'
    },

    unlock_tale_dialogs : [
        '¡Felicidades!\nHas logrado completar el proceso de crianza de tu chakra y haz desbloqueado un nuevo relato vivencial.',
        '¡Enhorabuena campeón!\nTu chakra ha producido alimentos. Como recompensa te otorgaré un nuevo relato vivencial!',
        '¡Muy buen trabajo!\nCriaste por completo a tu chakra y produjo alimentos! Desbloqueaste un nuevo relato vivencial!',
        '¡Excelente trabajo con esa chakra!\nPor tu dedicación a la crianza de tu chakrita, se desbloqueó un nuevo relato vivencial en la biblioteca de relatos.',
    ],

    ready_chakra_dialogs : [
        '¡Genial!\nTu chakra está lista para ser sembrada!'
    ],
    
    occupied_chakra_dialogs : [
        '¡Muy Bien!\nHaz sembrado la semilla en tu chakra!'
    ],

    completed_chakra_dialogs : [
        '¡Buen trabajo!\nLograste criar por completo tu semillita.\nComo recompensa haz ganado un nuevo relato vivencial.'
    ]
}

export const libraryDialogsList = {
    randomDialogs : [
        'Esta es la libreria de los relatos, selecciona un relato y mira su contenido',
        'Únicamente podrás seleccionar los relatos desbloqueados',
        'Recuerda que los relatos nuevos tienen un círculo rojo que parpadea en su esquina',
        'Recuerda que los relatos bloqueados tienen un candado en su esquina',
        'Recuerda.  No puedes seleccionar los relatos bloqueados y no puedes ver su contenido.'
    ],
    guide_dialogs : {
        INTRO_DIALOG : 'Hola y bienvenido a la guia de instrucciones de la biblioteca de Relatos Vivenciales.\nPresta mucha atención porque te enseñaré cómo se debe jugar.',
        DIALOG_01 : 'Esta es la librería de relatos vivenciales.  Está compuesta por doce libros de relatos vivenciales.\nEn cada libro se narran los saberes tradicionales más importantes celebrados en un cierto mes del año.',
        DIALOG_02 : '¿Puedes ver el punto rojo sobre el relato vivencial?\nSignifica que este es un nuevo relato que aún no ha sido leído y revisado.',
        DIALOG_03 : '¿A donde se fue el punto rojo?\nSi no ves el punto rojo sobre el relato vivencial, tranquilo.\nEsto significa que ya has revisado previamente su contenido.',
        DIALOG_04 : '¡VAYA! ¿Qué es esto?\n\n¿Un listón?\n\nPonte contento porque significa que has completado todas las trivias del relato vivencial.',
        DIALOG_05 : '¡Oh no! ¡Hay un candado sobre mi relato vivencial!\n\nBueno, si ves un candado sobre el relato vivencial, significa que todavía no puedes ver su contenido, por este momento.',
        DIALOG_06 : '¿De seguro habrás notado una imagen debajo del relato vivencial, eh?\n\nBueno, significa que deberás producir el alimento de la imagen en la chakra comunitaria para desbloquear su respectivo relato vivencial.',
        FINAL_DIALOG : 'Muy bien!\n\nAhora ya conoces cómo funciona la Librería de Relatos Vivenciales.\n\nPulsa en el botón para salir de la guía.'
    },
}

export const selectedTaleDialogsList = {
    random_dialogs : [
        'Esta es la pantalla del relato vivencial seleccionado.  Aquí encontrarás la información a detalle de las principales tradiciones culturales de la sierra ecuatoriana.',
        'No te olvides de ganar y completar las cuatro piezas de rompecabezas que tiene cada relato vivencial',
        'Cuando hayas completado las cuatro piezas de rompecabezas de este relato, ganarás un listón de recompensa'
    ],
    
    new_puzzle_piece_dialogs : [
        '¡Felicidades mi querido amigo!\n Haz conseguido una nueva pieza de rompecabezas!',
        '¡Muy bien!\nHaz superado una trivia ancestral y como recompensa haz conseguido una nueva pieza de rompecabezas!',
        '¡Genial amigo mio!\nContestaste correctamente toda la trivia ancestral!\nTe mereces una nueva pieza de rompecabezas!'
    ],

    post_piece_earned_dialogs : [
        '¡Buen trabajo! Si tu quieres, puedes dirigirte al calendario agrofestivo a ver la pieza que haz conseguido.'
    ],

    incomplete_quiz_dialogs : [
        '¿No lograste superar la trivia ancestral?\nNo te preocupes. Puedes volver a reintentarlo si quieres.',
        'Veo que no contestaste todas las preguntas correctamente.\nTal vez quieras volver a intentarlo!',
        '¡Vamos concentrate!\nSi quieres conseguir esa pieza de rompecabezas, vuelve a intentar la trivia.',
    ],

    guide_dialogs : {
        INTRO_DIALOG : 'Hola y bienvenido a la guia de instrucciones del Relato Vivencial Seleccionado.\nPresta mucha atención porque te enseñaré cómo se debe jugar.',
        DIALOG_01 : 'Este es el Relato Vivencial!  Es un libro que colecciona la información más importante, relacionada a las principales tradiciones culturales de los pueblos indígenas del Ecuador.',
        DIALOG_02 : 'Está compuesto por cuatro capítulos que mostrarán en detalle cómo se celebran las fiestas, las comidas, las señas y secretos, y los dialectos de algunas zonas de la sierra ecuatoriana.',
        DIALOG_03 : 'La página inicial del relato es la portada.\nAquí encontrarás el nombre del relato seleccionado y una caja con un listón bloqueado.\nPara desbloquear el listón deberás conseguir las cuatro piezas de rompecabezas que tiene este relato.',
        DIALOG_04 : 'Esta es la página con la lista de piezas que tiene este relato.\nAl inicio estará vacía, y para avanzar en el juego deberás completarlas.',
        DIALOG_05 : 'Las piezas se consiguen al contestar correctamente las trivias ancestrales que aparecen en la última página de cada capítulo del relato vivencial.',
        DIALOG_06 : 'Estas son las viñetas que integran el relato vivencial.\nCada una de ellas te permitirá avanzar directamente hacia un capítulo determinado del relato vivencial.',
        DIALOG_07 : 'Al presionar la viñeta festividades, automáticamente te dirigirás al inicio del capítulo Festividades con el título de la festividad correspondiente.',
        DIALOG_08 : 'Al presionar la viñeta comidas, automáticamente te dirigirás al inicio del capítulo Comidas con el título de la comida correspondiente.',
        DIALOG_09 : 'Al presionar la viñeta señas/secretos, automáticamente te dirigirás al capítulo Señas y Secretos del relato vivencial.',
        DIALOG_10 : 'Al presionar la viñeta dialectos, automáticamente te dirigirás al capítulo Dialectos del relato vivencial.',
        DIALOG_11 : 'También puedes navegar entre las páginas del relato al hacer clic en las esquinas inferiores derecha o izquierda de las páginas que se muestran en pantalla',
        DIALOG_12 : 'Cuando llegues al final de un capítulo verás la página de la trivia ancestral. Aquí se muestra su calificación junto con un botón para iniciar la trivia.',
        DIALOG_13 : 'Si la presionas, comenzará una ronda de preguntas acerca del tema tratado en ese capítulo.\nPara superar una trivia deberás responder correctamente las preguntas y obtener 3 estrellas.',
        DIALOG_14 : 'Para ver tu pieza ganada en el libro, presiona el listón que está ubicado en la esquina superior derecha.\nEste te llevará directamente a la página inicial del libro.',
        DIALOG_15 : 'Al superar una trivia ganarás una nueva pieza de rompecabezas y se colocará en la página de piezas de acuerdo al tipo de saber tradicional al que pertenece la trivia.',
        DIALOG_16 : 'Cuando hayas conseguido todas las piezas de rompecabezas de este relato, se desbloqueará un lazo de recompensa y el relato estará completo.',
        FINAL_DIALOG : 'Muy bien!\n\nAhora ya conoces cómo funciona el Relato Vivencial Seleccionado.\n\nPulsa en el botón para salir de la guía.'
    }
}

export const confirmationDialogList = {
    CANCEL_GUIDE_DIALOG : "¿Estás seguro que deseas salir de la guía de instrucciones?"
}