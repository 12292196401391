import ContentScene from "../baseScenes/contentScene";
import { scenesKey } from "../../../utils/gameConstants";
import { guidebookResources, guideBookPagesResources } from "../../../utils/assetsPath";
import { gridAlignGroupElements, addChildrenToContainer } from "../../../utils/functions";

export default class GuideScene extends ContentScene{
    constructor(){
        super({key : scenesKey.GUIDE_SCENE });
    }

    create(){
        this.buildScene();
        this.createGuideBookList();
        this.alignGuideElements(); 
        this.hideGuidePages();
        
        this.createGuideUI();

        this.activeButtons();
    }

    update(){
        this.showArrows();
    }

    setGuideBookList(list){ this.guideBookList = list }
    getGuideBookList(){ return this.guideBookList }

    setGuideBoard(board){ this.guideBoard = board }
    getGuideBoard(){ return this.guideBoard }

    setLeftArrow(arrow){ this.leftArrow = arrow }
    getLeftArrow(){ return this.leftArrow }

    setRightArrow(arrow){ this.rightArrow = arrow }
    getRightArrow(){ return this.rightArrow }

    setArrowScale(scale){ this.arrowScale = scale }
    getArrowScale(){ return this.arrowScale }

    setPageWindow(window){ this.pageWindow = window }
    getPageWindow(){ return this.pageWindow }

    setPageCounter(counter){ this.pageCounter = counter }
    getPageCounter(){ return this.pageCounter }

    setTotalPages(total){ this.totalPages = total }
    getTotalPages(){ return this.totalPages }

    setSelectedGuide(guide){this.selectedGuide = guide}
    getSelectedGuide(){ return this.selectedGuide }

    setSceneTitle(title){this.sceneTitle = title}
    getSceneTitle(){  return this.sceneTitle }

    createGuideBookList(){
        let list = [...Object.values(guideBookPagesResources)],
            images = list.map((element)=>{return element.key}),
            guideBookList = [];
        images.forEach((element)=>{
            let pic = this.add.image(0,0,element);
            guideBookList.push(pic);
        });
        this.setGuideBookList(guideBookList);
        this.setTotalPages(guideBookList.length);
    }

    alignGuideElements(){
        let guideBookList = this.getGuideBookList(),
            gameWidth = this.getGameWidth(), gameHeight = this.getGameHeight();

        guideBookList.forEach(guide => {
            guide.setPosition(gameWidth/2,gameHeight/2+gameHeight/28);
            guide.setDisplaySize(gameWidth*0.75,gameHeight*0.75);
        })
        this.setDefaultGuidePage(); 
        this.createSceneTitle();
    }

    hideGuidePages(){
        let guideBookList = this.getGuideBookList();
        guideBookList.forEach(guide => {
            if(guide!==this.getSelectedGuide()){
                guide.setVisible(false);
            } else {
                guide.setVisible(true);
            }
        }) 
    }

    setDefaultGuidePage(){
        let guideBookList = this.getGuideBookList();
        this.setSelectedGuide(guideBookList[0]);
        this.setPageCounter(guideBookList.indexOf(this.getSelectedGuide()) + 1);
    }

    changeNextGuidePage(){
        let guideBookList = this.getGuideBookList(),
            selectedGuide = this.getSelectedGuide(),
            index = guideBookList.indexOf(selectedGuide);

        if(index<guideBookList.length-1){
            index += 1;
            this.setSelectedGuide(guideBookList[index]);
            this.hideGuidePages();
            this.changePageCounter(index+1);
        }
    }

    changePrevGuidePage(){
        let guideBookList = this.getGuideBookList(),
        selectedGuide = this.getSelectedGuide(),
        index = guideBookList.indexOf(selectedGuide);

        if(index>0){
            index -= 1;
            this.setSelectedGuide(guideBookList[index]);
            this.hideGuidePages();
            this.changePageCounter(index+1);
        }
    }


    createGuideBoard(){
        let board = this.add.image(0,0,guidebookResources.GUIDE_BOARD.key);
        let gameWidth = this.getGameWidth(), gameHeight = this.getGameHeight();
        board.setDisplaySize(gameWidth*0.75,gameHeight*0.08);
        this.setGuideBoard(board);
    }

    createLeftArrow(){
        let arrow = this.add.image(-470,0,guidebookResources.GUIDE_ARROW.key),
            gameWidth = this.getGameWidth(), gameHeight = this.getGameHeight();
        arrow.setDisplaySize(gameHeight*0.08,gameHeight*0.08);
        this.setLeftArrow(arrow);
        this.setArrowScale(arrow.scale);
    }

    createRightArrow(){
        let arrow = this.add.image(470,0,guidebookResources.GUIDE_ARROW.key),
        gameWidth = this.getGameWidth(), gameHeight = this.getGameHeight();
        arrow.setDisplaySize(gameHeight*0.08,gameHeight*0.08);
        arrow.flipX = 180;
        this.setRightArrow(arrow);
    }

    createPageWindow(){
        let window = this.add.image(0,0,guidebookResources.GUIDE_PAGE_WINDOW.key),
        gameWidth = this.getGameWidth(), gameHeight = this.getGameHeight();
        window.setDisplaySize(gameWidth*0.1, gameHeight*0.08);
        let actualPage = this.getPageCounter(), 
            totalPages = this.getTotalPages();

        let text = this.add.text(0,0,`${actualPage}/${totalPages}`,{color: '#000', fontSize : '30px'});
        text.setOrigin(0.5);


        let container = this.add.container(0,0,[window,text]);
        this.setPageWindow(container);
    }

    changePageCounter(newCounter){
        let pageWindow = this.getPageWindow(),
            text = pageWindow.getAll()[1],
            totalPages = this.getTotalPages();
        text.setText(`${newCounter}/${totalPages}`);
    }

    createSceneTitle(){
        let title = this.add.image(0,0,guidebookResources.GUIDE_TITLE.key),
        gameWidth = this.getGameWidth(), gameHeight = this.getGameHeight();
        title.setPosition(gameWidth/2+gameWidth/12,gameHeight/3-gameHeight/4.5);
        title.setDisplaySize(gameWidth*0.6,gameHeight*0.15);
        this.setSceneTitle(title);
    }

    createGuideUI(){
        this.createGuideBoard()
        this.createLeftArrow();
        this.createRightArrow();
        this.createPageWindow();

        let container = this.add.container(0,0,[this.getGuideBoard(), this.getLeftArrow(), this.getPageWindow(), this.getRightArrow()]),
            gameWidth = this.getGameWidth(), gameHeight = this.getGameHeight();
        container.setPosition(gameWidth/2,gameHeight/2+gameHeight/2.2);
        container.setSize(this.getGuideBoard().width,this.getGuideBoard().height);

        // let arrowsGroup = this.add.group([this.getLeftArrow(),this.getPageWindow(),this.getRightArrow()]),
        //     // gridConfig = {width : 2, height : 1, cellWidth : container.width-container.width/10, position : 6, x : -container.width/2+container.width/30};
        //     gridConfig = {width : 3, height : 1, cellWidth : 300, position : 4, x : -40};
        // gridAlignGroupElements(this,arrowsGroup,gridConfig);
        // addChildrenToContainer(container,arrowsGroup.getChildren());
    }

    activeButtons(){
        let leftArrow = this.getLeftArrow(), rightArrow = this.getRightArrow();
        leftArrow.setInteractive({useHandCursor : true});
        rightArrow.setInteractive({useHandCursor : true});

        leftArrow.on('pointerover',()=>{ this.popUpArrow(leftArrow) });
        leftArrow.on('pointerout',()=>{this.popDownArrow(leftArrow) });
        leftArrow.on('pointerup',()=>{
            this.changePrevGuidePage()});

        rightArrow.on('pointerover',()=>{this.popUpArrow(rightArrow)});
        rightArrow.on('pointerout',()=>{this.popDownArrow(rightArrow)});
        rightArrow.on('pointerup',()=>{ 
            this.changeNextGuidePage()});
    }

    popUpArrow(arrow){
        let scale = this.getArrowScale();
        this.tweens.add({targets : arrow, scale : {from : scale, to : scale + 0.1}, duration : 200});
    }

    popDownArrow(arrow){
        let scale = this.getArrowScale();
        this.tweens.add({targets : arrow, scale : {from : scale + 0.1, to: scale}, duration : 200});
    }

    hideArrow(arrow){ arrow.setVisible(false) }
    showArrow(arrow){ arrow.setVisible(true) }

    showLeftArrow(){
        let leftArrow = this.getLeftArrow(),
            index = this.getGuideBookList().indexOf(this.getSelectedGuide());
        if(index===0){
            this.hideArrow(leftArrow);
        }else{
            this.showArrow(leftArrow);
        }
    }

    showRightArrow(){
        let rightArrow = this.getRightArrow(),
            index = this.getGuideBookList().indexOf(this.getSelectedGuide());
        if(index===this.getGuideBookList().length-1){
            this.hideArrow(rightArrow);
        }else{
            this.showArrow(rightArrow);
        }
    }

    showArrows(){
        this.showLeftArrow();
        this.showRightArrow();
    }



    

   

}