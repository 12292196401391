import DescriptionPage from "./descriptionPage";
import DialectField from "../game-objects/dialectField";

export default class DialectsPage extends DescriptionPage{
    constructor(scene,x,y,data){
        super(scene,x,y,'Lista de los dialectos más importantes\nnombrados en este relato',data);

        this.addPageContent(scene);
    }

    addPageContent(scene){
        this.infoList.forEach((info) => {
            if(info !== undefined){
                this.addDialectField(scene,info);
            }
        })
        this.deployCards(scene,{width : 1,height : this.cardsList.length,cellHeight : 200, position : 6, y : -40});
    }

    addDialectField(scene,info){
        let field = new DialectField(scene,0,0,info);
        this.cardsList.push(field);
    }
}