import React,{Component} from 'react';
import FuncaracGame from '../../FuncaracGame/FuncaracGame';

export default class GamePage extends Component{

    render(){
        return this.addFuncaracGame();
    }

    addFuncaracGame(){
        return (<FuncaracGame/>);
    }


}