import { generateSeedRecords, getPlayerSeedRecords } from "../services/gameServices/seedService"
import { CREATE_SEED_RECORDS_SUCCESS, LOAD_SEED_RECORDS_SUCCESS, LOAD_SEED_LIST } from "./actionTypes";


export function createNewSeedRecords(idPlayer){
    return dispatch => { 
        return generateSeedRecords(idPlayer).then(response => {
            dispatch(createSeedRecordsSuccess());
        }).catch(error => {throw error});
    }
}

export function loadPlayerSeedRecords(idPlayer){
    return dispatch => {
        return getPlayerSeedRecords(idPlayer).then(response => {
            dispatch(loadSeedList(response.data));
            dispatch(loadSeedRecordsSuccess());
        }).catch(error => {throw error})
    }
}

function loadSeedList(recordList){
    return { type: LOAD_SEED_LIST, recordList };
}

function createSeedRecordsSuccess(){
    return {type : CREATE_SEED_RECORDS_SUCCESS};
}

function loadSeedRecordsSuccess(){
    return { type : LOAD_SEED_RECORDS_SUCCESS };
}