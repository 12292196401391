import Page from "../../../baseClasses/page";
import { gridAlignGroupElements, addChildrenToContainer } from "../../../../utils/functions";

export default class SecretsPage extends Page{
    constructor(scene,x,y,storyParagraph,style){
        super(scene,x,y,'LECTURA DE RELATO');
        this.buildPage(scene);

        this.setText(storyParagraph);

        this.addPageContent(scene);
    }

    setText(text){
        this.text = text;
        console.log(this.text);
    }

    setParagraph(scene,text){
        let container = scene.add.container(0,0);
        container.setSize(450,100);

        let graphics = scene.add.graphics();
        graphics.lineStyle(1,0x000,0.5)
        .fillStyle(0xffffff,0.5)
        .fillRoundedRect(-225,-50,450,100)
        .strokeRoundedRect(-225,-50,450,100);

        let x = 0,
            y = 0,
            origin = { x: 0.5, y: 0.5 },
            color = '0x000',
            wordWrap = {width: 400},
            font = '20px Helvetica',
            align = 'justify';
        
        let paragraph = scene.make.text({x, y, text, origin, style: { font, wordWrap, color, align }});

        container.add([graphics,paragraph]);
        return container;
    }

    addParagraphs(scene){
        let group = scene.add.group();
        this.text.forEach((sentence)=>{
            let paragraphBox = this.setParagraph(scene,sentence);
            group.add(paragraphBox);
        })
        console.log(group.getChildren());
        let gridConfig = {width:1,height:4,cellHeight:120,position:6,y:-150};
        gridAlignGroupElements(scene,group,gridConfig);
        addChildrenToContainer(this,group.getChildren());
    }

    addPageContent(scene){
        this.addParagraphs(scene);
    }
}