import ModuleScene from './ModuleScene';
import PiecesBox from '../GameObjects/commonObjects/PiecesBox';
import BoardBox from '../GameObjects/commonObjects/BoardBox';
import { ringShapeAlign } from '../utils/functions';
import RedPiece from '../GameObjects/commonObjects/RedPiece';
import YellowPiece from '../GameObjects/commonObjects/YellowPiece';
import GreenPiece from '../GameObjects/commonObjects/GreenPiece';
import BluePiece from '../GameObjects/commonObjects/BluePiece';
import CaracMonth from '../GameObjects/baseClasses/caracMonth';
import { scenesKey} from '../utils/gameConstants';
import { signsSecretsPuzzlePricesInformation, foodPuzzlePricesInformation, dialectsPuzzlePriceInformation,festivitiesPuzzlePricesInformation, puzzlePiecesList } from '../utils/information/prices/pricesInformation';
import { mainUIResources, caracResources, caracSoundResources } from '../utils/assetsPath';
import { caracDialogsList } from '../utils/information/assistant/assistantDialogs';
import { caracMonthsList} from '../utils/information/months/caracMonthsInformation';
import { changePieceRecordEarnedStatus, changePieceRecordPlacedStatus } from '../utils/functions/save-record-functions';

export default class CaracScene extends ModuleScene{    
    constructor(){
        super(
            {key:scenesKey.CARAC_SCENE}
        );
        this.piecesList = [];
        this.monthsList = [];
        this.completedMonths = [];

    }

    create(){
        this.createWindow('CALENDARIO AGROFESTIVO');
        this.listenUpcomingEvents();
        this.displaySceneWindow();
        this.piecesGroup = this.add.group();
        this.displayBoardGame();
        this.setArrowFunctions();
        this.checkStorage();

        this.initialCheckScene();
        this.checkPiecesList();
    }

    checkStorage(){
        if( localStorage.hasOwnProperty('loggedPlayer') && 
            localStorage.hasOwnProperty('pieceRecords') ){
            this.loadPuzzlePieces();
        }
    }

    initialCheckScene(){
        let piecesList = this.piecesList,
            monthsList = this.monthsList,
            occupiedSlots = [];

        monthsList.forEach(month=>{
            let slotList = month.slotsList;
            slotList.forEach(slot => {
                if (slot.getSlotStatus() !== true){
                    occupiedSlots.push(slot);
                }
            })
        })

        if(piecesList.length === 0 && occupiedSlots.length === 0){
            this.showGuideArrow();
        }
    }

    displayBoardGame(){
        this.addBoardBox();
        this.addPiecesBox();
        this.addCaracMonths();

        // //add debug pieces
        // this.addPieces();
    }

    addPiecesBox(){
        this.piecesBox = new PiecesBox(this,-this.windowScene.width*0.37,this.windowScene.height*0.065);
        this.piecesBox.setBoxDisplaySize(this.windowScene.width*0.2,this.windowScene.height*0.8);
        this.windowScene.addElement(this.piecesBox);
        this.setPuzzlePosition();
    }

    addBoardBox(){
        this.boardBox = new BoardBox(this,this.windowScene.width*0.1,this.windowScene.height*0.065);
        this.boardBox.setBoxDisplaySize(this.windowScene.width*0.74,this.windowScene.height*0.8)
        this.windowScene.addElement(this.boardBox);
    }


    addCaracMonths(){
        let boardRadius = 212;
        
        caracMonthsList.forEach((monthKey)=>{
            let month = new CaracMonth(this,0,0,monthKey);
            this.monthsList.push(month);
            this.windowScene.addElement(month);
        });
        let monthsGroup = this.add.group(this.monthsList);
        ringShapeAlign(this.boardBox.x,this.boardBox.y,monthsGroup,boardRadius);
    }

    addPieces(){
        this.piecesGroup = this.add.group();
        for(let i = 0;i<=6;i++){
            // let value = Math.floor(Math.random()*8)+1;
            let piece = this.generatePiece(i+1);
            this.piecesGroup.add(piece);
            this.piecesList.push(piece);
            this.setMainActions(piece);
        }
        this.piecesGroup.getChildren().forEach((child)=>{this.windowScene.addElement(child)});

        this.setMainPosition(0);
    }

    addEarnedPuzzlePiece(puzzlePieceInfo){
        let newPuzzlePiece = this.generatePuzzlePiece(puzzlePieceInfo);
        this.piecesGroup.add(newPuzzlePiece);
        this.piecesList.push(newPuzzlePiece);
        this.windowScene.addElement(newPuzzlePiece);

        this.showLastPiece(newPuzzlePiece);

        this.checkPiecesList();
    }

    addPlacedPuzzlePiece(puzzlePieceInfo){
        let newPuzzlePiece = this.generatePuzzlePiece(puzzlePieceInfo);
        this.windowScene.addElement(newPuzzlePiece);
        return newPuzzlePiece;
    }

    loadPuzzlePieces(){
        let pieceRecords = JSON.parse(localStorage.getItem('pieceRecords'));
        if(pieceRecords.length>0){
            let piecesEarned = pieceRecords.filter(record => {return record.earned === 1 && record.placed === 0});
            let piecesPlaced = pieceRecords.filter(record => {return record.earned === 1 && record.placed === 1});
            piecesEarned.forEach((record)=>{
                let idPiece = record.puzzle_piece_id;
                this.addEarnedPuzzlePiece(this.getPieceInfo(idPiece));
            });

            piecesPlaced.forEach((record)=>{
                let idPiece = record.puzzle_piece_id,
                puzzlePiece = this.addPlacedPuzzlePiece(this.getPieceInfo(idPiece)),
                puzzleSlot = this.getPuzzleSlot(idPiece);
                puzzlePiece.setVisible(true);
                this.fillSlotWithPiece(puzzleSlot,puzzlePiece);
            })
        }
    }

    getPieceInfo(idPiece){
        return puzzlePiecesList.find(pieceInfo => {return pieceInfo.id === idPiece});
    }

    getPiece(idPiece){
        return this.piecesList.find(piece => {return piece.pieceId === idPiece});
    }

    getPuzzleSlot(idPiece){        
        let caracMonth = this.monthsList.find(month=>{
            return month.list.find(slot => {return slot.puzzlePieceId === idPiece});
        });
        return caracMonth.list.find(slot => { return slot.puzzlePieceId === idPiece});
    }

    showLastPiece(piece){
        let index = this.piecesList.indexOf(piece);
        this.setMainPosition(index);
    }

    setPuzzlePosition(){
        this.YMTOP = -this.piecesBox.y*4;
        this.YTOP = -this.piecesBox.y+60;
        this.YPRIME = this.piecesBox.y+80;
        this.YDOWN = this.piecesBox.y*4.2;
        this.YMDOWN = this.piecesBox.y*7;
    }

    generatePuzzlePiece(data){
        let puzzlePiece = null,type = data.type;
        switch(type){
            case 'FESTIVIDADES':
                puzzlePiece = new RedPiece(this,this.piecesBox.x,this.YMTOP,data);
            break;
            case 'COMIDAS':
                puzzlePiece = new YellowPiece(this,this.piecesBox.x,this.YMTOP,data);
                break;
            case 'SEÑAS-SECRETOS':
                puzzlePiece = new GreenPiece(this,this.piecesBox.x,this.YMTOP,data);
                break;
            case 'DIALECTOS':
                puzzlePiece = new BluePiece(this,this.piecesBox.x,this.YMTOP,data);
                break;
            default:
                break;
        }
        puzzlePiece.displayPiece(this);
        puzzlePiece.setVisible(false);
        this.setMainActions(puzzlePiece);
        return puzzlePiece;
    }

    generatePiece(value){
        let puzzlePiece=null;
        let data;
        switch(value){
            case 1:
                data = festivitiesPuzzlePricesInformation.PUZZLE_PRICE_YUMBADA;
                puzzlePiece = new RedPiece(this,this.piecesBox.x,this.YPRIME,data);
            break;
            case 2:
                data = foodPuzzlePricesInformation.PUZZLE_PRICE_CHUKLLU_KAMLLA;
                puzzlePiece = new YellowPiece(this,this.piecesBox.x,this.YTOP,data);
                break;
            case 3:
                data = signsSecretsPuzzlePricesInformation.PUZZLE_PRICE_GROUP_01;
                puzzlePiece = new GreenPiece(this,this.piecesBox.x,this.YDOWN,data);
                break;
            case 4:
                data = dialectsPuzzlePriceInformation.PUZZLE_PRICE_DIALECTS_01;
                puzzlePiece = new BluePiece(this,this.piecesBox.x,this.YMTOP,data);
                puzzlePiece.setVisible(false);
                break;
            case 5:
                data = festivitiesPuzzlePricesInformation.PUZZLE_PRICE_INTI_RAYMI;
                puzzlePiece = new RedPiece(this,this.piecesBox.x,this.YPRIME,data);
            break;
            case 6:
                data = foodPuzzlePricesInformation.PUZZLE_PRICE_CHAMPUS;
                puzzlePiece = new YellowPiece(this,this.piecesBox.x,this.YTOP,data);
                break;
            case 7:
                data = signsSecretsPuzzlePricesInformation.PUZZLE_PRICE_GROUP_06;
                puzzlePiece = new GreenPiece(this,this.piecesBox.x,this.YDOWN,data);
                break;
            case 8:
                data = dialectsPuzzlePriceInformation.PUZZLE_PRICE_DIALECTS_06;
                puzzlePiece = new BluePiece(this,this.piecesBox.x,this.YMTOP,data);
                puzzlePiece.setVisible(false);
                break;

            default:
                break;
        }
        puzzlePiece.displayPiece(this);
        return puzzlePiece;
    }

    setMainActions(puzzlePiece){
        puzzlePiece.on('drag',(pointer,dragX,dragY) => {
            this.windowScene.bringToTop(puzzlePiece);
            puzzlePiece.setPosition(dragX,dragY);
        });

        puzzlePiece.on('dragenter',(pointer,puzzleSlot) => {
            puzzleSlot.bodyColor.setTint(0x2d2d2d);
        });

        puzzlePiece.on('dragleave',(pointer,puzzleSlot) => {
            puzzleSlot.bodyColor.clearTint();
        })

        puzzlePiece.on('drop', (pointer, puzzleSlot) => {
            puzzleSlot.bodyColor.clearTint();
            this.dropPuzzlePiece(puzzlePiece,puzzleSlot);
        })
    
        this.input.on('dragend',(pointer,piece,dropped) => {
            if(!dropped){
                piece.setPosition(piece.input.dragStartX,piece.input.dragStartY);
            }
        })
    }

    dropPuzzlePiece(puzzlePiece,puzzleSlot){
        if(puzzlePiece.type===puzzleSlot.slotType && 
           puzzlePiece.pieceId === puzzleSlot.puzzlePieceId){
            this.sound.play(caracSoundResources.DROP_PUZZLE_PIECE_EFFECT.key);
            this.time.addEvent({delay:300,callback:()=>{
                this.freezePieces();
                this.showCaracPrice(1,null)}});
            this.selectAPiece(puzzlePiece);
            this.fillSlotWithPiece(puzzleSlot,puzzlePiece);
            changePieceRecordPlacedStatus(puzzlePiece.pieceId);
            // this.emitToMainUI('puzzle_placed');
        }else{
            this.sound.play(caracSoundResources.DROP_WRONG_EFFECT.key);
            puzzlePiece.setPosition(puzzlePiece.input.dragStartX,puzzlePiece.input.dragStartY);
        }

    }

    
    fillSlotWithPiece( puzzleSlot, puzzlePiece){
        puzzleSlot.addNewPuzzlePiece(puzzlePiece);
        puzzlePiece.placeInPuzzleSlot(puzzleSlot);
    }


    listenUpcomingEvents(){
        this.events.on('modalgone',()=>{
            this.comeBackPieces();
            this.time.addEvent({delay:300, callback:()=>{
                this.checkMonthsList();
            }});
        });

        this.events.on('new_puzzle_piece',(data)=>{
            this.addEarnedPuzzlePiece(data);
        })

        this.events.on('start_guide',()=>{
            this.startGuide();
        })        
    }

    freezePieces(){
        this.piecesList.forEach((piece)=>{piece.disableInteractive()});
        this.piecesBox.upArrow.disableInteractive();
        this.piecesBox.downArrow.disableInteractive();
    }

    comeBackPieces(){
        this.piecesList.forEach((piece)=>{piece.setInteractive()});
        this.piecesBox.upArrow.setInteractive();
        this.piecesBox.downArrow.setInteractive();
    }

    showCaracPrice(priceType,data){
        //use this to emit events between scenes
        this.emitToMainUI('freeze_buttons');
        this.showRewardModal(priceType,data);
    }

    setMainPosition(index){
        let totalItems = this.piecesList.length;
        
        //setting the center piece
        this.primePiece = this.piecesList[index];
        this.primePiece.setVisible(true);
        this.piecesList[index].y = this.YPRIME;

        //index is in between 0 and list.length-1
        if(index<(totalItems-1)&&index>0){
            
            if(totalItems===2){
                //setting down piece to next piece on list
                this.nextPiece=this.piecesList[index + 1];
                this.nextPiece.setVisible(true);
                this.piecesList[index + 1].y = this.YDOWN; 
            }

            if(totalItems>=3){
                //setting down piece to next piece on list
                this.nextPiece=this.piecesList[index + 1];
                this.nextPiece.setVisible(true);
                this.piecesList[index + 1].y = this.YDOWN; 

                //setting up piece to prev piece on list
                this.previousPiece = this.piecesList[index - 1];
                this.previousPiece.setVisible(true);
                this.piecesList[index - 1].y = this.YTOP;
            }
        
        }

        //index is the first element of list
        if(index===0){

            if(totalItems===2){
                //setting down piece to next piece on list
                this.nextPiece=this.piecesList[index + 1];
                this.nextPiece.setVisible(true);
                this.piecesList[index + 1].y = this.YDOWN; 
            }

            if(totalItems>=3){
                //setting down piece to next piece on list
                this.nextPiece=this.piecesList[index + 1];
                this.nextPiece.setVisible(true);
                this.piecesList[index + 1].y = this.YDOWN; 

                //setting up piece to the last item on list
                this.previousPiece = this.piecesList[totalItems-1];
                this.previousPiece.setVisible(true);
                this.piecesList[totalItems-1].y = this.YTOP;
            }

        }

        //index is the last element of the list
        if(index===totalItems-1){

            if(totalItems===2){

                //setting down piece to the first element of the list
                this.nextPiece = this.piecesList[0];
                this.nextPiece.setVisible(true);
                this.piecesList[0].y = this.YDOWN;
            }

            if(totalItems>=3){

                //setting down piece to the first element of the list
                this.nextPiece = this.piecesList[0];
                this.nextPiece.setVisible(true);
                this.piecesList[0].y = this.YDOWN;

                //setting up piece to prev piece on list
                this.previousPiece = this.piecesList[index - 1];
                this.previousPiece.setVisible(true);
                this.piecesList[index - 1].y = this.YTOP;
            }
        }
        // console.log('resultado: \nprime index: ',this.piecesList.indexOf(this.primePiece),'\n next index: ',this.piecesList.indexOf(this.nextPiece),'\n prev index: ',this.piecesList.indexOf(this.previousPiece));
    }

    getNextPiece(){
        let duration=300;
        this.tweens.add({targets:this.primePiece,y:{from:this.YPRIME, to:this.YDOWN},duration,ease:'Cubic'});
        this.tweens.add({targets:this.previousPiece,y:{from:this.YTOP, to:this.YPRIME},duration,ease:'Cubic'});
        this.tweens.add({targets:this.nextPiece,y:{from:this.YDOWN, to:this.YMDOWN},duration,ease:'Cubic'});
        this.nextPiece.setVisible(false);

        //setting the new primeIndex
        let primeIndex = this.piecesList.indexOf(this.previousPiece);
        this.setMainPosition(primeIndex);
    }

    getPreviousPiece(){
        let duration=300;
        this.tweens.add({targets:this.primePiece,y:{from:this.YPRIME, to:this.YTOP},duration,ease:'Cubic'});
        this.tweens.add({targets:this.nextPiece,y:{from:this.YDOWN, to:this.YPRIME},duration,ease:'Cubic'});    
        this.tweens.add({targets:this.previousPiece,y:{from:this.YTOP, to:this.YMTOP},duration,ease:'Cubic'});
        this.previousPiece.setVisible(false);

        //setting the new primeIndex
        let primeIndex = this.piecesList.indexOf(this.nextPiece);
        this.setMainPosition(primeIndex);
    }

    selectAPiece(piece){
        let duration = 100;
        this.selectedPiece = piece;
        let index = this.piecesList.indexOf(this.selectedPiece);

        if(this.piecesList.length>0&&this.piecesList.length<=3){
            switch(this.selectedPiece){
                case this.primePiece:
                    this.primePiece = '';
                    break;
                case this.previousPiece:
                    this.previousPiece = '';
                    break;
                case this.nextPiece:
                    this.nextPiece = '';
                    break;
                default:
                    break;
            }
            if(index>-1){
                this.piecesList.splice(index,1);
            }
        }else{
            switch(this.selectedPiece){
                case this.primePiece:
                    this.primePiece = this.previousPiece;
                    this.tweens.add({targets:this.previousPiece,y:{from:this.YTOP, to:this.YPRIME},duration});
                    break;
                case this.previousPiece:
                    this.previousPiece = this.piecesList[index-1];
                    this.tweens.add({targets:this.previousPiece,y:{from:this.YMTOP, to:this.YTOP},duration});
                    break;
                case this.nextPiece:
                    this.nextPiece = this.piecesList[index+1];
                    this.tweens.add({targets:this.nextPiece,y:{from:this.YMDOWN, to:this.YDOWN},duration});
                    break;
                default:
                    break;
            }
            if(index>-1){
                this.piecesList.splice(index,1);
            }    
            index = this.piecesList.indexOf(this.primePiece);
            this.setMainPosition(index);
        }  
        this.checkPiecesList();  
    }

    setArrowFunctions(){
        this.piecesBox.upArrow.on('pointerdown',(event,index)=>{
            this.sound.play(caracSoundResources.CARRUSEL_SOUND_EFFECT.key);
            this.piecesBox.upArrow.setTint(0x2d2d2d);
            this.getPreviousPiece(index);
        });
        this.piecesBox.upArrow.on('pointerup',(event,index)=>{
            this.piecesBox.upArrow.clearTint();
        });
        this.piecesBox.downArrow.on('pointerdown',(event,index)=>{
            this.sound.play(caracSoundResources.CARRUSEL_SOUND_EFFECT.key);
            this.piecesBox.downArrow.setTint(0x2d2d2d);
            this.getNextPiece(index);
        });
        this.piecesBox.downArrow.on('pointerup',(event,index)=>{
            this.piecesBox.downArrow.clearTint();
        });
    }

    checkPiecesList(){
        if(this.piecesList.length<=3){
            this.piecesBox.hideArrows();
        }else{
            this.piecesBox.showArrows();
        }
    }

    checkMonthsList(){
        this.monthsList.forEach((month)=>{
            month.checkAvailableSlots();
            if(month.completed === true){
                this.freezePieces();
                this.showCaracPrice(2,month.seed);
                this.completedMonths.push(month);
                let index = this.monthsList.indexOf(month);
                if(index>-1){
                    this.monthsList.splice(index,1);
                }
            }
        })
    }

    checkMonthsStatus(){
        let isMonthComplete = false;
        for(let i = 0;i<this.monthsList.length;i++){
            let month = this.monthsList[i];
            month.checkAvailableSlots();
            if(month.completed === true){
                isMonthComplete = true;
                break;
            }
        }
        return isMonthComplete;
    }

    startGuide(){
        this.emitToMainUI('changeVolume',0.2);
        this.piecesBox.setAlpha(0.5);
        this.boardBox.setAlpha(0.5);

        //make sure this doesnt affect the real elements
        this.monthsList.forEach((month)=>{
            month.setVisible(false);
            month.disableInteractive();
        });

        if(this.piecesList.length>0){
            if (this.primePiece) this.primePiece.setVisible(false)
            if (this.previousPiece) this.previousPiece.setVisible(false);
            if (this.nextPiece) this.nextPiece.setVisible(false);

            if(this.piecesList.length>3){
                this.piecesBox.hideArrows();
            }
        }

        this.createGuideElements();
    }

    createGuideElements(){
        this.guideElementsList = {};

        //create exampleMonths
        let exampleMonths = [];
        caracMonthsList.forEach((monthKey)=>{
            let month = new CaracMonth(this,0,0,monthKey);
            month.setAlpha(0.5);
            exampleMonths.push(month);
            this.windowScene.addElement(month);
        });
        let group = this.add.group(exampleMonths);
        ringShapeAlign(this.boardBox.x,this.boardBox.y,group,212);
    
        this.guideElementsList.exampleMonths = exampleMonths;

        //create puzzle pieces examples
        let pieceA = this.generatePiece(1);
        let pieceB = this.generatePiece(2);
        let pieceC = this.generatePiece(3);
        let pieceD = this.generatePiece(4);
        let examplePieces = [pieceA,pieceB,pieceC,pieceD];

        examplePieces.forEach((piece)=>{
            this.windowScene.addElement(piece);
            piece.setAlpha(0.5);
        });

        this.guideElementsList.examplePieces = examplePieces;

        //create arrows
        let uparrow = this.add.image(-500,this.YTOP- 120,caracResources.ARROW.key),
            downArrow = this.add.image(-500,this.YDOWN + 120,caracResources.ARROW.key);
        downArrow.setFlipY(180);
        let arrows = [uparrow,downArrow];
        arrows.forEach((arrow)=>{
            this.windowScene.addElement(arrow);
            arrow.setAlpha(0.5);
        })
        this.guideElementsList.arrows = arrows;

        //create hand
        let hand = this.add.image(0,0,mainUIResources.HAND.key);
        this.windowScene.addElement(hand);
        hand.setVisible(false);
        
        this.guideElementsList.hand = hand;
        this.guideElementsList.anims = [];

        //create reward Window
        let window = this.add.image(0,0,mainUIResources.REWARD.key);
        this.windowScene.addElement(window);
        window.setVisible(false);
        this.guideElementsList.window = window;
    }

    showPiecesBox(){
        this.hideAllGuideElements();
        this.piecesBox.setAlpha(1);
        let examplePieces = this.guideElementsList.examplePieces;
        examplePieces.forEach((piece)=>{
            piece.setAlpha(1);
        })
    }

    showBoard(){
        //only show the boardGame
        this.boardBox.setAlpha(1);
        let exampleMonths = this.guideElementsList.exampleMonths; 
        exampleMonths.forEach((month)=>{
            month.setAlpha(1);
        });
    }

    showOneMonth(){
        this.boardBox.setAlpha(0.5);
        let exampleMonths = this.guideElementsList.exampleMonths,
            exampleMonth = exampleMonths[9];
        exampleMonths.forEach((month)=>{
            if(month!==exampleMonth){
                month.setAlpha(0.5);
            }
        })
    }

    showRedSlot(){
        let exampleMonths = this.guideElementsList.exampleMonths,
            month = exampleMonths[9],
            primeSlot = month.festivitySlot;
        month.slotsList.forEach((slot)=>{
            if(slot!==primeSlot){
                slot.setAlpha(0.5);
            }else{
                slot.setAlpha(1);
            }
        })
    }

    showYellowSlot(){
        let exampleMonths = this.guideElementsList.exampleMonths,
            month = exampleMonths[9],
            primeSlot = month.foodSlot;
        month.slotsList.forEach((slot)=>{
            if(slot!==primeSlot){
                slot.setAlpha(0.5);
            }else{
                slot.setAlpha(1);
            }
        })
    }

    showGreenSlot(){
        let exampleMonths = this.guideElementsList.exampleMonths,
            month = exampleMonths[9],
            primeSlot = month.signSlot;
        month.slotsList.forEach((slot)=>{
            if(slot!==primeSlot){
                slot.setAlpha(0.5);
            }else{
                slot.setAlpha(1);
            }
        })
    }

    showBlueSlot(){
        let exampleMonths = this.guideElementsList.exampleMonths,
            month = exampleMonths[9],
            primeSlot = month.dialectSlot;
        month.slotsList.forEach((slot)=>{
            if(slot!==primeSlot){
                slot.setAlpha(0.5);
            }else{
                slot.setAlpha(1);
            }
        })
    }

    showRewardWindow(){
        let window = this.guideElementsList.window;
        window.setVisible(true).setAlpha(0);
        this.tweens.add({targets: window, alpha:{from:0,to:1},duration:1000,onComplete:()=>{
        }});
    }

    hideAllGuideElements(){
        let exampleMonths = this.guideElementsList.exampleMonths,
            examplePieces = this.guideElementsList.examplePieces,
            arrows = this.guideElementsList.arrows;
        
        this.piecesBox.setAlpha(0.5);
        this.boardBox.setAlpha(0.5);
        
        exampleMonths.forEach((month)=>{
            month.setAlpha(0.5);
            month.slotsList.forEach((slot)=>{
                slot.setAlpha(0.5);
            })
        });
        examplePieces.forEach((piece)=>{
            piece.setAlpha(0.5);
        });
        arrows.forEach((arrow)=>{
            arrow.setAlpha(0.5);
        })
    }

    showAllGuideElements(){
        let exampleMonths = this.guideElementsList.exampleMonths,
            examplePieces = this.guideElementsList.examplePieces,
            arrows = this.guideElementsList.arrows;
        
        this.piecesBox.setAlpha(1);
        this.boardBox.setAlpha(1);
        
        exampleMonths.forEach((month)=>{
            month.setAlpha(1);
            month.slotsList.forEach((slot)=>{
                slot.setAlpha(1);
            })
        });
        examplePieces.forEach((piece)=>{
            piece.setAlpha(1);
        });
        arrows.forEach((arrow)=>{
            arrow.setAlpha(1);
        })
    }

    explainCarruselDown(){
        let hand = this.guideElementsList.hand,
            arrows = this.guideElementsList.arrows,
            examplePieces = this.guideElementsList.examplePieces;
        arrows.forEach((arrow)=>{
            arrow.setAlpha(1);
        });
        hand.setVisible(true);
        this.playClickDownArrow(hand,arrows[1],examplePieces);
    }

    explainCarruselUp(){
        let hand = this.guideElementsList.hand,
            arrows = this.guideElementsList.arrows,
            examplePieces = this.guideElementsList.examplePieces;
        this.playClickUpArrow(hand,arrows[0],examplePieces);
    }

    playClickDownArrow(hand,arrow,piecesList){
        let redPiece = piecesList[0],
            yellowPiece = piecesList[1],
            greenPiece = piecesList[2],
            bluePiece = piecesList[3];
        this.tweens.add({
            targets : hand,
            x : {from : hand.x, to : arrow.x},
            y : {from : hand.y, to : arrow.y},
            duration : 2000,
            onComplete : ()=>{
                this.playMoveDownPiecesList(bluePiece,redPiece,yellowPiece,greenPiece);
            }
        })
    }

    playClickUpArrow(hand,arrow,piecesList){
        let redPiece = piecesList[0],
            yellowPiece = piecesList[1],
            greenPiece = piecesList[2],
            bluePiece = piecesList[3];
        this.tweens.add({
            targets : hand,
            x : {from : hand.x, to : arrow.x},
            y : {from : hand.y, to : arrow.y},
            duration : 2000,
            onComplete : ()=>{
                this.playMoveUpPiecesList(greenPiece,yellowPiece,bluePiece,redPiece);
            }
        })
    }

    playMoveDownPiecesList(hiddenPuzzle,primePuzzle,upPuzzle,downPuzzle){
        hiddenPuzzle.setVisible(true).setAlpha(0);
        this.tweens.add({
            targets : hiddenPuzzle,
            y : {from : hiddenPuzzle.y, to: this.YTOP},
            alpha : {from : 0, to: 1}
        })
        this.tweens.add({
            targets : upPuzzle,
            y : {from : upPuzzle.y, to : this.YPRIME},
        })
        this.tweens.add({
            targets : primePuzzle,
            y : {from : primePuzzle.y, to : this.YDOWN},
        })
        this.tweens.add({
            targets : downPuzzle,
            y : {from : downPuzzle.y, to : this.YMDOWN},
            alpha: {from: 1, to: 0},
            onComplete:()=>{
                downPuzzle.setVisible(false);
            }
        })
    }

    playMoveUpPiecesList(hiddenPuzzle,primePuzzle,upPuzzle,downPuzzle){
        hiddenPuzzle.setVisible(true).setAlpha(0);
        //green piece
        this.tweens.add({
            targets : hiddenPuzzle,
            y : {from : hiddenPuzzle.y, to: this.YDOWN},
            alpha : {from : 0, to: 1}
        })
        //yellow piece
        this.tweens.add({
            targets : primePuzzle,
            y : {from : primePuzzle.y, to : this.YTOP},
        })
        //red piece        
        this.tweens.add({
            targets : downPuzzle,
            y : {from : downPuzzle.y, to : this.YPRIME},
        })
        //blue piece
        this.tweens.add({
            targets : upPuzzle,
            y : {from : upPuzzle.y, to : this.YMTOP},
            alpha: {from: 1, to: 0},
            onComplete:()=>{
                upPuzzle.setVisible(false);
            }
        })
    }

    playMoveRemainingPieces(hiddenPuzzle,upPuzzle){
        hiddenPuzzle.setVisible(true).setAlpha(0);
        //blue piece
        this.tweens.add({
            targets : hiddenPuzzle,
            y : {from : hiddenPuzzle.y, to: this.YTOP},
            alpha : {from : 0, to: 1}
        })
        //yellow piece
        this.tweens.add({
            targets : upPuzzle,
            y : {from : upPuzzle.y, to : this.YPRIME},
            onComplete : ()=>{
            }
        })
    }

    explainWrongPlacement(){
        let puzzle = this.guideElementsList.examplePieces[1],
            hand = this.guideElementsList.hand,
            slot = this.guideElementsList.exampleMonths[3].slotsList[3];

        this.tweens.add({
            targets: hand,
            x : {from : hand.x,to : puzzle.x},
            y : {from : hand.y,to : puzzle.y},
            duration : 2000,
            onComplete : ()=>{
                this.tweens.add({
                targets: [hand,puzzle],
                x : {from : puzzle.x,to: slot.x},
                y : {from : puzzle.y,to: slot.y},
                duration : 2000,
                onComplete : ()=>{
                    this.time.addEvent({delay:500,callback:()=>{
                    this.tweens.add({
                    targets : puzzle,
                    x : { from : puzzle.x , to : this.piecesBox.x},
                    y : { from : puzzle.y , to : this.YPRIME},
                    duration : 2000,
                    onComplete : ()=>{
                        }})
                    }})
                }
                })
            }
        });
    }

    explainCompleteAMonth(){
        let yellowPuzzle = this.guideElementsList.examplePieces[1],
            greenPuzzle = this.guideElementsList.examplePieces[2],
            bluePuzzle = this.guideElementsList.examplePieces[3],
            hand = this.guideElementsList.hand,
            yellowSlot = this.guideElementsList.exampleMonths[9].slotsList[1],
            greenSlot = this.guideElementsList.exampleMonths[9].slotsList[2],
            blueSlot = this.guideElementsList.exampleMonths[9].slotsList[3];
        
        //move the hand to the yellow piece
        this.tweens.add({targets : hand, x : {from : hand.x, to : yellowPuzzle.x},
        y : {from : hand.y, to : yellowPuzzle.y}, duration : 1000, onComplete : () => {
            //grab the yellow piece
            this.tweens.add({targets : [hand,yellowPuzzle],x : {from : hand.x, to: yellowSlot.x + 133},
            y : {from : hand.y, to: yellowSlot.y - 130},duration : 1000, onComplete : () => {
                //place the yellow piece    
                this.tweens.add({ targets : yellowPuzzle, scaleX : {from : 1, to : 1},
                scaleY : {from : 1, to : 0.76},duration : 1000, onComplete : () => {
                    //move the hand to the green piece
                    this.tweens.add({ targets : hand, x : {from : hand.x, to : greenPuzzle.x}, 
                    y : {from : hand.y, to : greenPuzzle.y}, duration : 1000, onComplete : () => {
                        //grab the green piece
                        this.tweens.add({ targets : [hand,greenPuzzle],x : {from : hand.x, to: greenSlot.x + 136},
                        y : {from : hand.y, to: greenSlot.y - 130},duration : 1000, onComplete : () => {
                            //place the green piece
                            this.tweens.add({ targets : greenPuzzle, scaleX : {from : 1, to : 0.75}, 
                            scaleY : {from : 1, to : 0.6}, duration : 1000, onComplete : () =>{
                                //move the hand to the blue piece
                                this.tweens.add({ targets : hand, x : {from : hand.x, to : bluePuzzle.x}, 
                                y : {from : hand.y, to : bluePuzzle.y}, duration : 1000, onComplete : () => {
                                    //grab the blue piece
                                    this.tweens.add({targets : [hand,bluePuzzle],x : {from : hand.x, to: blueSlot.x + 136},
                                    y : {from : hand.y, to: blueSlot.y - 130},duration : 1000, onComplete : ()=>{
                                        //place the blue piece
                                        this.tweens.add({targets : bluePuzzle,scaleX:{from: 1,to:0.55},
                                        scaleY:{from: 1,to:0.45},duration:1000,onComplete:()=>{
                                            this.time.addEvent({delay:500,callback:()=>{
                                                this.showRewardWindow();
                                            }})
                                        }});
                                        //move the hand
                                        this.tweens.add({ targets : hand, x : {from: hand.x, to: hand.x + 100},
                                        y : {from: hand.y, to: hand.y + 100},duration: 1000});

                                    }})
                                }})
                            }})
                        }})
                    }})
                }}) 
            }})
        }})

    }

    

    explainPiecesBoxAdjustment(){
        let examplePieces = this.guideElementsList.examplePieces,
            bluePiece = examplePieces[3],
            yellowPiece = examplePieces[1];
        this.playMoveRemainingPieces(bluePiece,yellowPiece);
        this.time.addEvent({delay:1000,callback:()=>{
            let arrows = this.guideElementsList.arrows;
            arrows.forEach((arrow)=>{
                arrow.setVisible(false);
            });
        }})
    }

    explainPieceGrab(){
        this.showAllGuideElements();
        let hand = this.guideElementsList.hand,
            puzzle = this.guideElementsList.examplePieces[0],
            slot = this.guideElementsList.exampleMonths[0].festivitySlot;
        this.playMoveHand(hand,puzzle,slot);
    }

    playMoveHand(hand, puzzle,slot){
        this.tweens.add({
            targets : hand,
            x : {from : hand.x, to : puzzle.x},
            y : {from : hand.y, to : puzzle.y},
            duration : 2000,
            onComplete : () => {
            this.time.addEvent({ delay : 300, callback : () => {
                this.playGrabPiece(hand,puzzle,slot);
            }})
            }
        });
    }

    playGrabPiece(hand, puzzle,slot){
        this.tweens.add({
            targets : [hand,puzzle],
            x : {from : hand.x, to: slot.x + 130},
            y : {from : hand.y, to: slot.y - 130},
            duration : 2000,
            onComplete : () => {
            this.time.addEvent({delay : 300,callback : () => {
                this.playPlacePuzzle(puzzle,hand);
            }})
            } 
        });
    }

    playPlacePuzzle(puzzle,hand){
        //move the hand a little bit
        this.tweens.add({
            targets : hand,
            x : {from: hand.x, to: hand.x + 100},
            y : {from: hand.y, to: hand.y + 100},
            duration: 1000
        });

        //place the piece in slot
        this.tweens.add({
            targets : puzzle,
            scaleX : {from : 1, to : 1.32},
            duration : 1000,
            onComplete : ()=>{
            }
        });
    }

    quitGuide(){
        this.monthsList.forEach((month)=>{
            month.setVisible(true);
            month.setInteractive();
        });

        this.piecesBox.setAlpha(1);
        this.boardBox.setAlpha(1);

        if(this.piecesList.length>0){
            if (this.primePiece) this.primePiece.setVisible(true)
            if (this.previousPiece) this.previousPiece.setVisible(true);
            if (this.nextPiece) this.nextPiece.setVisible(true);
            this.checkPiecesList();
        }
    }

    endGuide(){
        this.deleteGuideListElements();
        this.quitGuide();
    }

    

    createGuide(){
        this.startGuide();
        this.showGuidePart(caracDialogsList.guide_dialogs.INTRO_DIALOG,
        500,()=>{this.showFirstGuidePart()});
        setTimeout(()=>{this.disableOmitButton()},1000);
    }

    showFirstGuidePart(){
        this.showBoard();
        this.showGuidePart(caracDialogsList.guide_dialogs.DIALOG_01,
        500,()=>{this.showSecondGuidePart()});
    }

    showSecondGuidePart(){
        this.showOneMonth()
        this.showGuidePart(caracDialogsList.guide_dialogs.DIALOG_02,
        500,()=>{this.showThirdGuidePart()});
    }

    showThirdGuidePart(){
        this.showRedSlot();
        this.showGuidePart(caracDialogsList.guide_dialogs.DIALOG_03,
        500,()=>{this.showForthGuidePart()});
    }

    showForthGuidePart(){
        this.showYellowSlot();
        this.showGuidePart(caracDialogsList.guide_dialogs.DIALOG_04,
        500,()=>{this.showFifthGuidePart()});
    }

    showFifthGuidePart(){
        this.showGreenSlot();
        this.showGuidePart(caracDialogsList.guide_dialogs.DIALOG_05,
        500,()=>{this.showSixthGuidePart()});
    }

    showSixthGuidePart(){
        this.showBlueSlot();
        this.showGuidePart(caracDialogsList.guide_dialogs.DIALOG_06,
        500,()=>{this.showSeventhGuidePart()});
    }

    showSeventhGuidePart(){
        this.showPiecesBox();
        this.showGuidePart(caracDialogsList.guide_dialogs.DIALOG_07,
        500,()=>{this.showEighthGuidePart()});
    }

    showEighthGuidePart(){
        this.explainCarruselDown();
        this.showGuidePart(caracDialogsList.guide_dialogs.DIALOG_08,
        500,()=>{this.showNinthGuidePart()});
    }

    showNinthGuidePart(){
        this.explainCarruselUp();
        this.showGuidePart(caracDialogsList.guide_dialogs.DIALOG_09,
        500,()=>{this.showTenthGuidePart()});
    }

    showTenthGuidePart(){
        this.explainPieceGrab();
        this.showGuidePart(caracDialogsList.guide_dialogs.DIALOG_10,
        500,()=>{this.showEleventhGuidePart()});
    }

    showEleventhGuidePart(){
        this.explainPiecesBoxAdjustment();
        this.showGuidePart(caracDialogsList.guide_dialogs.DIALOG_11,
        500,()=>{this.showTwelvethGuidePart()});
    }

    showTwelvethGuidePart(){
        this.explainWrongPlacement();
        this.showGuidePart(caracDialogsList.guide_dialogs.DIALOG_12,
        500,()=>{this.showThirdteenthGuidePart()});
    }

    showThirdteenthGuidePart(){
        this.explainCompleteAMonth();
        this.showGuidePart(caracDialogsList.guide_dialogs.DIALOG_13,
        500,()=>{this.deleteGuideListElements();
                    this.quitGuide()});
        setTimeout(()=>{this.disableOmitButton()},1000);
    }

    deleteGuideListElements(){
        this.emitToMainUI('changeVolume',0.8);
        let exampleMonths = this.guideElementsList.exampleMonths,
            examplePieces = this.guideElementsList.examplePieces,
            hand = this.guideElementsList.hand,
            arrows = this.guideElementsList.arrows,
            window = this.guideElementsList.window;

        //destroy the example months
        exampleMonths.forEach((month)=>{
            month.destroy();
        });

        //destroy the example pieces
        examplePieces.forEach((piece)=>{
            piece.destroy();
        });

        //destroy the hand
        hand.destroy();

        //destroy the arrow
        arrows.forEach((arrow)=>{
            arrow.destroy();
        })

        window.destroy();

        this.guideElementsList = {};
    }

}
