export const familiarSignsQuestions = {
    QUESTION_ANGAS : {
        question : '¿Qué seña pronostica el próximo matrimonio entre un hombre y una mujer?',
        options : ['Una pulga sobre el uñero','Un par de angas volando sobre la montaña','Un arco iris elevado','Una invasión de hormigas en la casa'],
        answer : 'Un par de angas volando sobre la montaña'
    },
    QUESTION_PALOMAS : {
        question : '¿Qué grupo de animales asomados en la cornisa señalan que alguien se va a casar?',
        options : ['Dos palomas','Una jauría de perros','Varias moscas verdes','Cuatro búhos'],
        answer : 'Dos palomas'
    },
    QUESTION_PERRO_DESCONOCIDO : {
        question : '¿Qué animal predice la llegada de un nuevo familiar?',
        options : ['Un katzo alcalde blanco','Unos tuktus frondosos','Un perro desconocido','Una mariposa negra'],
        answer : 'Un perro desconocido'
    },
    QUESTION_SUEÑO_ORINA : {
        question : '¿Cómo una persona puede prever un nuevo nacimiento en la familia?',
        options : ['Cuando frota la pansita con hierbas silvestres','Cuando ve la pansita en forma de un huevo','Cuando sueña que orina en grandes cantidades','Cuando ve que una paloma lagrimea en la ventana'],
        answer : 'Cuando sueña que orina en grandes cantidades'
    },
    QUESTION_VIENTRE_HUEVO : {
        question : 'Si el vientre de la barriga de la mamá tiene forma de huevito es seña de que…',
        options : ['Tendrá un hijo varón','Tendrá gemelos','Tendrá una mujer','Tendrá trillizos'],
        answer : 'Tendrá un hijo varón'
    },
    QUESTION_VIENTRE_PELOTA : {
        question : 'Si el vientre de la barriga de la mamá tiene forma de pelota es seña de que…',
        options : ['Tendrá un hijo varón','Tendrá gemelos','Tendrá una mujer','Tendrá trillizos'],
        answer : 'Tendrá una mujer'
    },
    QUESTION_PIOJO_NIÑO : {
        question : '¿Cómo una persona puede saber si un niño sufre de violencia doméstica?',
        options : ['Si ve que el niño no deja de mojar la cama','Si ve que el niño no quiere ir al baño','Si ve que el niño tiene las uñas muy largas','Si ve que el niño tiene muchos piojos en su cabeza'],
        answer : 'Si ve que el niño tiene muchos piojos en su cabeza'
    },
    QUESTION_VARIOS_PERROS : {
        question : '¿Qué significa el hecho de que muchos perros persigan constantemente a una persona?',
        options : ['Quieren ser alimentados por esa persona','Quieren acompañar a esa persona a su casa','Esa persona se quedará vaga por el resto del día','Quieren ayudar a esa persona a encontrar a un familiar perdido'],
        answer : 'Esa persona se quedará vaga por el resto del día'
    },
    QUESTION_CUYES_MURMURADORES : {
        question : '¿Qué seña indica que los cuyes murmuren constantemente?',
        options : ['Anuncian que un gato quiere devorarlos','Anuncian que pronto llegarán visitas','Anuncian que están muertos del hambre','Anuncian que planean huir de la casa'],
        answer : 'Anuncian que pronto llegarán visitas'
    },
    QUESTION_FOGON : {
        question : '¿Cuál es la seña que indica que alguien llegará enojado a la casa?',
        options : ['Un fogón encandilado','Una araña cargando su kipi','Unos cuyes murmurando','Un gavilán revoloteando la colina'],
        answer : 'Un fogón encandilado'
    },
}

export const deathSignsQuestions = {
    QUESTION_ALLA_CABALLO : {
        question : '¿Qué significa que un allá caballo arrastra y no suelta a una tarántula?',
        options : ['Que alguien recibirá una oferta de trabajo','Que una persona llegará enojada a su casa','Que una persona fallecerá y cargará su ataúd','Que una persona hizo enojar a los apus'],
        answer : 'Que una persona fallecerá y cargará su ataúd'
    },
    QUESTION_MARIPOSA_NEGRA : {
        question : '¿Qué animal te predice la muerte o robo?',
        options : ['Un sapito jambato','Un cuy murmurador','Una mariposa negra','Un gavilán pollero'],
        answer : 'Una mariposa negra'
    },
    QUESTION_ALWICU : {
        question : '¿Quién suele resonar en el viento el sonido “alwicu”?',
        options : ['Un gallinazo','Un pájaro','Un gato','Un ratón'],
        answer : 'Un pájaro'
    },
    QUESTION_PALOMA_LLORANDO : {
        question : '¿Qué le sucederá a una persona si se encuentra con una paloma lagrimeando?',
        options : ['Su casa será robada','Su chacra sufrirá de sequía','Su muerte está anunciada','Su suerte estará a punto de cambiar'],
        answer : 'Su muerte está anunciada'
    },
    QUESTION_CUSCUNGO : {
        question : '¿Quién debe chillar fuera de la casa para saber que alguien eventualmente morirá?',
        options : ['Un búho','Una paloma mensajera','Un pastón alemán','Un sapito jambato'],
        answer : 'Un búho'
    },
    QUESTION_AULLIDO_PERRO : {
        question : 'El aullido descontrolado de un perro solo puede indicar una cosa…',
        options : ['Un gato ha devorado una paloma','Una persona contraerá matrimonio pronto','La lluvia estará a punto de comenzar','Una persona está a punto de morir'],
        answer : 'Una persona está a punto de morir'
    },
    QUESTION_PULGA_UÑERO : {
        question : '¿Qué señal indica la presencia de una pulga en el uñero de un pie?',
        options : ['Que esa persona estará enferma durante días','Que esa persona se encontrará mucho dinero','Que esa persona morirá en los próximos días','Que esa persona necesita cambiarse de zapatos'],
        answer : 'Que esa persona morirá en los próximos días'
    },
    QUESTION_MOSCA_VERDE : {
        question : '¿La invasión de qué animal indica la muerte de una persona?',
        options : ['Un búho','Un gato','Una lagartija','Una mosca verde'],
        answer : 'Una mosca verde'
    },
    QUESTION_MUELAS_JUICIO : {
        question : '¿Qué significa que una persona sueñe con sacarse las muelas del juicio?',
        options : ['Tendrá mucho dinero del hada de los dientes','Tendrá la posibilidad de pedir un deseo','Tendrá una muerte oportuna','Tendrá que lavarse los dientes con cuidado'],
        answer : 'Tendrá una muerte oportuna'
    },
    QUESTION_DIENTE_DERECHO : {
        question : '¿Si una persona sueña que se le sale el diente delantero de la derecha, se sucederá?',
        options : ['Que muy pronto se le caerá ese diente','Que algún familiar contraerá matrimonio','Que algún familiar lejano le visitará muy pronto','Que algún niño que conoce pronto morirá'],
        answer : 'Que algún niño que conoce pronto morirá'
    },
    QUESTION_PIOJO_BLANCO : {
        question : '¿Cuándo un piojo blanco se vuelve una señal de muerte?',
        options : ['Cuando se aloje en las uñas de una persona','Cuando invada toda la piel y la ropa de una persona','Cuando llene por completo la cabeza de un niño','Cuando esté en la comida preparada'],
        answer : 'Cuando invada toda la piel y la ropa de una persona'
    },
}

export const goodLuckSignsQuestions = {
    QUESTION_ARAÑA_KIPI : {
        question : '¿Qué animal pronostica una buena\ntemporada de producción a los chacareros?',
        options : ['Una rana cantora','Un perro vagabundo','Una araña con su kipi','Una salamandra'],
        answer : 'Una araña con su kipi'
    },
    QUESTION_DURAZNO : {
        question : '¿Los árboles de que fruta señalan buena producción de la siembra?',
        options : ['Durazno','Manzana','Naranja','Pera'],
        answer : 'Durazno'
    },
    QUESTION_CATZO : {
        question : '¿El vuelo de qué animal te predice abundancia?',
        options : ['Gavilán','Mariposa Negra','Mosca Verde','Catzo'],
        answer : 'Catzo'
    },
    QUESTION_FREJOL : {
        question : '¿Qué grano tiene una buena producción por la abundancia de catzos?',
        options : ['El haba','La cebada','El fréjol','La arveja'],
        answer : 'El fréjol'
    },
    QUESTION_SALTO_HABAS : {
        question : '¿Qué significado tiene el salto de las habas dentro del tiesto al ser tostadas?',
        options : ['Que habrá escasez de haba','Que les faltan sal','Que habrá buena cosecha','Que falta poco para que estén listas'],
        answer : 'Que habrá buena cosecha'
    },
    QUESTION_CERRO_FRUTOS : {
        question : '¿Cómo sabemos que una montaña tendrá muchos frutos y animales?',
        options : ['Cuando se escucha “con con” y se ven muchos rayos de luz','Cuando se habla con los apus y se prende una fogata','Cuando se corta una rama en su cima','Cuando se realiza un ritual ceremonial tradicional en su cima'],
        answer : 'Cuando se escucha “con con” y se ven muchos rayos de luz'
    },
    QUESTION_PICAFLOR : {
        question : '¿Qué señal indica la entrada de un picaflor en la casa?',
        options : ['Miseria y pobreza','Esperanza y justicia','Prosperidad y abundancia','Calma y hambruna'],
        answer : 'Prosperidad y abundancia'
    },
    QUESTION_FLORES_TUNAS : {
        question : '¿Qué significa que las flores de las tunas miren hacia la mama Cotopaxi?',
        options : ['Que pronto las tunas estarán listas para la cosecha','Que pronto habrá sequía','Que pronto anochecerá','Que pronto comenzará a llover'],
        answer : 'Que pronto comenzará a llover'
    },
}

export const badLuckSignsQuestions = {
    QUESTION_HORMIGAS : {
        question : '¿Cómo podemos ver que va a ocurrir un robo en una casa?',
        options : ['Si una golondrina se posa en la cornisa','Si un perro desconocido entra a la casa','Si un sapito comienza a cantar en el patio','Si un grupo de hormigas invaden el patio'],
        answer : 'Si un grupo de hormigas invaden el patio'
    },
    QUESTION_HABAS_TIESTO : {
        question : '¿Qué significado tiene el salto de las habas fuera del tiesto al ser tostadas?',
        options : ['Que habrá escasez de haba','Que les faltan sal','Que habrá buena cosecha','Que falta poco para que estén listas'],
        answer : 'Que habrá escasez de haba'
    },
    QUESTION_ZAPALLO : {
        question : '¿Qué significa que un chacarero haya tenido una sobreproducción de zapallo?',
        options : ['Que ha trabajado muy duro durante semanas','Que ha sido bendecido por los apus','Que se quedará solo o sapallo','Que sus chacras son muy productivas'],
        answer : 'Que se quedará solo o sapallo'
    },
    QUESTION_RATAS : {
        question : 'Si una persona que sueña con varias ratas es seña de que…',
        options : ['Será víctima de un robo seguro','La chacra será víctima de plagas','Los alimentos se echarán a perder','La persona se morirá'],
        answer : 'Será víctima de un robo seguro'
    },
    QUESTION_SUEÑO_GRITO : {
        question : '¿Cuándo una persona pronostica que será víctima de un robo seguro?',
        options : ['Si ha dormido contando ovejas','Si ha dormido gritando descontroladamente','Si ve dos angas volando sobre la montaña','Si ve una araña cargando su kipi'],
        answer : 'Si ha dormido gritando descontroladamente'
    },
    QUESTION_ZAMBO : {
        question : '¿Qué le pasa a una persona si no regala el sobrante de la producción de zambo?',
        options : ['Se quedará pobrete o sin dinero','No recibirá suficiente dinero','Podrá hacer dulce de zambo','Se quedará calvo'],
        answer : 'Se quedará pobrete o sin dinero'
    },
    QUESTION_GARZA : {
        question : '¿La visita en manada de qué animal indica mala producción sobre las chacras?',
        options : ['Ardilla','Gato','Coyote','Garza'],
        answer : 'Garza'
    },
    QUESTION_LAGARTIJA : {
        question : '¿El cruce de qué animal en el camino de una persona es señal de vaguería?',
        options : ['Gavilán','Lagartija','Caballo','Buey'],
        answer : 'Lagartija'
    },
    QUESTION_PAJARO_MASHU : {
        question : 'Si una persona ve descansar al pájaro mashu, ¿Qué le pasará a esa persona?',
        options : ['Le dará de comer de su kukayu','Le espantará para que se aleje de la chacra','Le dará pereza de continuar haciendo su trabajo','Le dará hambre y lo intentará cazar para la cena'],
        answer : 'Le dará pereza de continuar haciendo su trabajo'
    },
    QUESTION_YUGO : {
        question : 'Si una pareja llega a romper el yugo mientras están arando, ¿Qué les sucederá?',
        options : ['Tendrán que comprar un nuevo yugo','Tratarán de repáralo usando cordones','Seguirán con el arado a pesar de este inconveniente','Su matrimonio pronto se terminará'],
        answer : 'Su matrimonio pronto se terminará'
    },
}

export const weatherSignsQuestions = {
    QUESTION_ARCO_IRIS_ORIENTE : {
        question : '¿Qué seña ocurrirá si el arco iris sale al oriente?',
        options : ['El verano estará a punto de llegar','El invierno estará a punto de llegar','La lluvia estará a punto de llegar','El anochecer estará a punto de llegar'],
        answer : 'El verano estará a punto de llegar'
    },
    QUESTION_ARCO_IRIS_OCCIDENTE : {
        question : '¿Qué seña ocurrirá si el arco iris sale al occidente?',
        options : ['El verano estará a punto de llegar','El invierno estará a punto de llegar','La lluvia estará a punto de llegar','El anochecer estará a punto de llegar'],
        answer : 'La lluvia estará a punto de llegar'
    },
    QUESTION_ESTRELLAS_DESPEJADAS : {
        question : '¿Qué significa que la noche este muy estrellada y despejada?',
        options : ['Que los astros vigilarán las chacras','Que al día siguiente habrá mucho sol','Que al día siguiente comenzará a llover','Que al día siguiente vendrán los catzos voladores'],
        answer : 'Que al día siguiente habrá mucho sol'
    },
    QUESTION_MORTIÑO : {
        question : '¿Qué le ocurrirá a una persona al arrancar las ramas del mortiño en el páramo?',
        options : ['Será golpeado por los fuertes vientos','Será calentado por un ardiente sol','Será mojado por un fuerte aguacero y granizo','Será encontrará muchos mullus del mortiño'],
        answer : 'Será mojado por un fuerte aguacero y granizo'
    },
    QUESTION_APU_SAL : {
        question : '¿Cuándo se recienten los apus con una persona?',
        options : ['Cuando haya descansado todo el día','Cuando haya comido sal en medio del páramo','Cuando haya olvidado un par de vacas en la colina','Cuando haya juntado muchas semillitas'],
        answer : 'Cuando haya comido sal en medio del páramo'
    },
    QUESTION_RELAMPAGOS : {
        question : 'Si se ven muchos relámpagos blancos entonces la lluvia…',
        options : ['Parará','Continuará','Será más fuerte','Se detendrá y caerá granizo'],
        answer : 'Continuará'
    },
    QUESTION_HURACAN : {
        question : '¿Qué pasa cuando un huracán hace torbellinos?',
        options : ['Será un periodo de larga sequía en la chakra','Será un buen momento para sembrar las semillitas','Será un buen momento para cosechar los granitos','Será un periodo de lluvia fuerte'],
        answer : 'Será un periodo de larga sequía en la chakra'
    },
    QUESTION_ARCO_IRIS_AIRE : {
        question : '¿Qué pasa cuando un arco iris no topa la tierra?',
        options : ['Es una señal de que paró el invierno y comenzó el verano','Es una señal de que paró el verano y comenzó el invierno','Es una señal de que las lluvias continuarán','Es una señal de que se ocultó el sol'],
        answer : 'Es una señal de que paró el invierno y comenzó el verano'
    },
    QUESTION_ARCO_IRIS_TIERRA : {
        question : '¿Qué pasa cuando un arco iris está por tierra?',
        options : ['Es una señal de que paró el invierno y comenzó el verano','Es una señal de que paró el verano y comenzó el invierno','Es una señal de que las lluvias continuarán','Es una señal de que se ocultó el sol'],
        answer : 'Es una señal de que las lluvias continuarán'
    },
    QUESTION_JAMBATOS : {
        question : '¿Qué anuncian los sapitos jambatos?',
        options : ['La llegada de la época de sequía','La llegada de la época de fuertes vientos','La llegada de la época lluviosa','La llegada de la época soleada'],
        answer : 'La llegada de la época lluviosa'
    },
    QUESTION_WIRAKCHURUS : {
        question : '¿Cuál es la señal que indica que los primeros choclos de la chacra están listos?',
        options : ['Cuando los catzos revolotean sobre la chacra','Cuando los cuyes empiezan a murmurar','Cuando las palomas visitan las cornisas de la casa','Cuando las aves cantoras comienzan a cantar'],
        answer : 'Cuando las aves cantoras comienzan a cantar'
    },
}
