import Button from "../baseClasses/Button";
import { chakraResources } from "../../utils/assetsPath";

export default class SeedButton extends Button{
    constructor(scene,x,y,seedIcon,data){
        super(scene,x,y,chakraResources.SEED_BUTTON.key,seedIcon,null);
        this.isLocked = true;
        this.buildButton(scene);
        this.createSeedIconContainer();
        this.hideIconContainer();
        this.setSeedData(data);
    }

    getScene(){ return this.scene }
    setScene(scene){ this.scene = scene }

    getSeedIconContainer(){ return this.seedIconContainer }
    setSeedIconContainer(container){ this.seedIconContainer = container }
    

    buildButton(scene){
        this.setContainer();
        this.add(this.icon);
        scene.add.existing(this);
    }

    createSeedIconContainer(){
        let scene = this.getScene(),
            iconImage = scene.add.image(0,0,chakraResources.SEED_OK_ICON.key),
            container = scene.add.container(30,-30,[iconImage]);
            container.setSize(iconImage.width,iconImage.height);
            container.setScale(0.09);
            this.setSeedIconContainer(container);
        this.add(this.getSeedIconContainer());
    }

    hideIconContainer(){
        this.getSeedIconContainer().setVisible(false);
    }

    showIconContainer(){
        this.getSeedIconContainer().setVisible(true);
    }

    setSeedData(seedInfo){
        this.seedData = seedInfo;
    }

    getSeedData(){
        return this.seedData;
    }

    setIcon(){
        this.icon.setTexture(this.seedData.rewardKey);
    }
}