import IntroPage from "../commonObjects/tale-pages/pages/introPage";
import IdCardPage from "../commonObjects/tale-pages/pages/idCardPage";
import FactPage from "../commonObjects/tale-pages/pages/factPage";
import MeaningPage from "../commonObjects/tale-pages/pages/meaningPage";
import RitualPage from "../commonObjects/tale-pages/pages/ritualPage";
import QuizPage from "../commonObjects/tale-pages/pages/quizPage";
import IngredientsPage from "../commonObjects/tale-pages/pages/ingredientsPage";
import DishPreparationPage from "../commonObjects/tale-pages/pages/dishPreparationPage";
import SecretsPage from "../commonObjects/tale-pages/pages/secretsPage";
import SignsPage from "../commonObjects/tale-pages/pages/signsPage";
import RedPiece from "../commonObjects/RedPiece";
import YellowPiece from "../commonObjects/YellowPiece";
import GreenPiece from "../commonObjects/GreenPiece";
import BluePiece from "../commonObjects/BluePiece";
import DialectsPage from "../commonObjects/tale-pages/pages/dialectsPage";
import { scenesKey } from "../../utils/gameConstants";


export default class Chapter {
    constructor(data){
        this.title = null;
        this.type = null;
        this.author = null;
        this.quiz = null;
        this.description = null;

        this.pagesList = [];

        this.quizScore = null;

        this.setBaseData(data);

        this.puzzlePiece = null;

    }

    setBaseData(data){
        this.setContent(data.content);
        this.setType(data.type);
        this.setAuthor(data.author);
        this.setQuiz(data.quiz);
        this.setPuzzlePieceInfo(data.price);

        this.setTitle(this.content.title);
        this.setCoverPhoto(this.content.coverPhoto);
        this.setDescription(this.content.description);

        this.addTypeToQuizInfo();
    }

    setContent(content){
        this.content = content;
    }

    setTitle(title){
        this.title = title;
    }

    setType(type){
        this.type = type;
    }

    setAuthor(author){
        this.author = author;
    }

    setCoverPhoto(photo){
        this.photo = photo;
    }

    setQuiz(quiz){
        this.quiz = quiz;
    }

    setQuizId(id){
        this.quizId = id;
    }

    setDescription(content){
        this.description = content;
    }

    setQuizScore(score){
        this.quizScore = score;
    }

    setPiece(piece){
        this.puzzlePiece = piece; 
    }

    setPuzzlePieceInfo(puzzleInfo){
        this.puzzlePieceInfo = puzzleInfo;
    }

    setMonth(month){ this.month = month }
    getMonth(){ return this.month }

    setIntroPage(scene,data){
        let type = data;
        this.introPage = new IntroPage(scene,0,0,type,{title:this.title,photo:this.photo,monthName : this.getMonth()});
        this.pagesList.push(this.introPage);
    }

    setAuthorPage(scene){
        this.authorPage = new IdCardPage(scene,0,0,this.author);
        this.pagesList.push(this.authorPage);
    }

    setFactPages(scene,data){
        let mainFacts = data;
        let dataArrays = this.parseData(mainFacts,3);
        dataArrays.forEach((list)=>{
            let factPage = new FactPage(scene,0,0,list);
            this.pagesList.push(factPage);
        });
    }

    setDialectPages(scene, data){
        let mainList = data;
        let dataArrays = this.parseData(mainList,2);
        dataArrays.forEach((list)=>{
            let dialectPage = new DialectsPage(scene,0,0,list);
            this.pagesList.push(dialectPage);
        });
    }

    setMeaningPages(scene,data){
        let meaningList = data;
        let dataArrays = this.parseData(meaningList,4);

        dataArrays.forEach((list)=>{
            let meaningPage = new MeaningPage(scene,0,0,list);
            this.pagesList.push(meaningPage);
        });
    }

    setIngredientsPages(scene,data){
        let ingredientsData = data;
        let dataArrays = this.parseData(ingredientsData,6);

        dataArrays.forEach((list)=>{
            let ingredientPage = new IngredientsPage(scene,0,0,list,this.title);
            this.pagesList.push(ingredientPage);
        });
    }

    setRitualPages(scene,data){
        let ritualDescription = data;
        ritualDescription.forEach((description)=>{
            let ritualPage = new RitualPage(scene,0,0,description);
            this.pagesList.push(ritualPage);
        })
    }

    setDishPreparationPages(scene,data){
        let preparationDescription = data;

        preparationDescription.forEach((preparation)=>{
            let dishPage = new DishPreparationPage(scene,0,0,preparation);
            this.pagesList.push(dishPage);
        });
    }

    setStoryPages(scene,data){
        let initParagraph = data;
        let dataArrays = this.parseData(initParagraph,4);

        dataArrays.forEach((list)=>{
            let storyPage = new SecretsPage(scene,0,0,list);
        this.pagesList.push(storyPage);
        });
    }

    setSignsPages(scene,data){
        let signsData = data;

        signsData.forEach((sign)=>{
            let title = sign.title,
                community = sign.community,
                state = sign.state,
                text = null;
                    
            if(state!=null&&community!=null){
                text = `${title}\t(${community},${state})`;
            }else{
                text = `${title}\t(serranía ecuatoriana)`;
            }

            let signPage = new SignsPage(scene,0,0,text,sign.content);
            this.pagesList.push(signPage);
        })
    }

    setQuizPage(scene,data, priceData){
        let quizPage = new QuizPage(scene,0,0,data,priceData);
        this.pagesList.push(quizPage)
    }

    

    parseData(list,quantity){
        let baseList = list;
        let totalLists = [];

        for(let i=0;i<baseList.length;i+=quantity){
            let auxList = baseList.slice(i,i+quantity);
            totalLists.push(auxList);
        }
        return totalLists;
    }

    putPagesTogether(scene){
        this.setIntroPage(scene);
        this.setAuthorPage(scene);
    }

    getQuizData(){
        return this.quiz;
    }

    getPieceInfo(){
        return this.puzzlePieceInfo;
    }
    
    getType(){
        return this.type;
    }

    updateQuizCardScore(){
        let quizPage = this.pagesList.find((page)=>{
            return page.constructor === QuizPage;
        });
        quizPage.setStarsScore(this.quizScore);
    }

    createPuzzlePiece(scene,pieceInfo){
        let piece = null,
            pieceType = pieceInfo.type;
        switch(pieceType){
            case 'FESTIVIDADES' :
                piece = new RedPiece(scene,0,0,pieceInfo);    
            break;
            case 'COMIDAS' :
                piece = new YellowPiece(scene,0,0,pieceInfo);    
                break;
            case 'SEÑAS-SECRETOS' :
                piece = new GreenPiece(scene,0,0,pieceInfo);    
                break;
            case 'DIALECTOS':
                piece = new BluePiece(scene,0,0,pieceInfo);    
                break;
            default :
                break;
        }
        return piece;
    }

    generatePuzzlePiece(scene){
        this.setPiece(this.createPuzzlePiece(scene,this.puzzlePieceInfo));
    }

    addTypeToQuizInfo(){
        this.quiz.type = this.type;
        // console.log('mi quiz: ',this.quiz);
    }

    sendPieceToCaracScene(scene){
        let caracScene = scene.scene.get(scenesKey.CARAC_SCENE);
        caracScene.events.emit('new_puzzle_piece',this.puzzlePieceInfo);
    }


}               