import Phaser from 'phaser';

export default class Ribbon extends Phaser.GameObjects.Container{
    constructor(scene,x,y,texture,title){
        super(scene,x,y);
        this.texture = texture;
        (title) ? this.title=title:this.title=null;
        this.style = null;


    }
    createRibbon(scene){
        this.ribbon = scene.add.image(0,0,this.texture);
        this.ribbon.setScale(0.96,1);
        this.text = scene.add.text(0,2,this.title);
        this.setTextStyle();
        this.add([this.ribbon,this.text]).setSize(this.ribbon.width,this.ribbon.height);
    }

    setRibbonTitle(text){
        this.title = text;
    }

    setTextStyle(){
        this.text.setOrigin(0.5,0.7);
        this.text.setStyle(this.style);
    }

    setStyle(config){
        this.style = config;

    }

    changeBodyScale(scaleX,scaleY){
        this.ribbon.setScale(scaleX,scaleY);
    }
    
}