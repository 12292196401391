import DescriptionPage from "./descriptionPage";
import FactCard from "../game-objects/factCard";

export default class FactPage extends DescriptionPage{
    constructor(scene,x,y,data){
        super(scene,x,y,'DESCRIPCIÓN GENERAL',data);

        this.addPageContent(scene);
    }
    
    addPageContent(scene){
        this.infoList.forEach((info)=>{
            if(info!==undefined){
                this.addDescriptionCard(scene,info);
            }
        })
        this.deployCards(scene,{width:1,height:this.cardsList.length,cellHeight:148, position:6,y:-73});
    }

    addDescriptionCard(scene,info){
        let card = new FactCard(scene,0,0,info);
        this.cardsList.push(card);
    }




}