export const foodQuizzesList = {
    QUIZ_CHUKLLU_KAMLLA : {
        id : 13,
        title : 'Prueba sobre el Chukllu Kamlla',
        questions : [
            {
                questionNumber : 1,
                question : '¿De qué otra manera se le denomina al Chukllu Kamlla?',
                options : ['Choclo maduro','Choclo frito','Choclo tierno','Choclo molido'],
                answer : 'Choclo frito'
            },
            {
                questionNumber : 2,
                question : '¿Por qué razón es preferible comer el Chukllu Kamlla caliente?',
                options : ['Porque se enfría','Porque se pegan las moscas','Porque así dicta la tradición','Porque se endurece al enfriar'],
                answer : 'Porque así dicta la tradición'
            },
            {
                questionNumber : 3,
                question : '¿Cuáles son los ingredientes para poder preparar el Chukllu Kamlla?',
                options : ['Choclo tierno, agua, aceite y cebolla','Choclo tierno, aceite, manteca y sal','Choclo tierno, aceite, cebolla blanca y sal','Choclo tierno, aceite, cebolla y sal'],
                answer : 'Choclo tierno, aceite, cebolla blanca y sal'
            },
            {
                questionNumber : 4,
                question : '¿Con qué alimento no se debe acompañar al Chukllu Kamlla?',
                options : ['Arroz','Papas','Carne','Azucar'],
                answer : 'Azucar'
            },
            {
                questionNumber : 5,
                question : '¿Por qué razón se prepara el Chukllu Kamlla en la chacra?',
                options : ['Para contentar a las abuelitas','Porque se debe preparar con\nlos primeros choclos cosechados','Porque indica el tiempo\ndel deshierbe en la chacra','Porque los apus se enojarán\nsi no se la prepara'],
                answer : 'Porque se debe preparar con\nlos primeros choclos cosechados'
            },
            {
                questionNumber : 6,
                question : '¿Cuál es la diferencia de utilizar aceite o\nmanteca de animal en la preparación del Chukllu Kamlla?',
                options : ['El aceite cocina mejor el choclo\ntierno que la manteca de animal','La manteca de animal cocina\nmejor el choclo tierno que el aceite','La manteca de animal\ncontamina menos que el aceite','No existe ninguna diferencia,\nambas se pueden utilizar'],
                answer : 'No existe ninguna diferencia,\nambas se pueden utilizar'
            }
        ]
    },
    QUIZ_MILICHAKI : {
        id : 14,
        title : 'Prueba sobre el Milichaki',
        questions : [
            {
                questionNumber : 1,
                question : '¿En qué mes se prepara la Supalata?',
                options : ['Marzo','Febrero','Enero','Abril'],
                answer : 'Febrero'
            },
            {
                questionNumber : 2,
                question : '¿Qué representa la preparación del tamal Supalata?',
                options : ['Las buenas siembras','El buen humor','La caída del granizo','El inicio de clases escolares'],
                answer : 'Las buenas siembras'
            },
            {
                questionNumber : 3,
                question : '¿Cuáles son los ingredientes para poder preparar el tamal Supalata?',
                options : ['Mote pelado, agua, aceite y ají','Mote pelado, aceite, quesillo y sal','Mote pelado, pepa de zambo, quesillo y ají','Mote pelado, pepa de zambo, quesillo de hoja y ají'],
                answer : 'Mote pelado, pepa de zambo, quesillo y ají'
            },
            {
                questionNumber : 4,
                question : '¿En las fiestas de Supalata de que van disfrazados los hombres y las mujeres?',
                options : ['Hombres y mujeres de animales','Hombres de Inti y las mujeres de Pachamama','Hombres y mujeres de montañas','Hombres de mujeres y mujeres de hombres'],
                answer : 'Hombres de mujeres y mujeres de hombres'
            },
            {
                questionNumber : 5,
                question : '¿Qué deben dar los dueños de casa a los disfrazados durante el festejo de la fiesta Supalata?',
                options : ['Las bendiciones por parte de los dioses','Los dueños de casa no deben darles nada','Las comidas realizadas con las cosechas','Las cosechas que obtuvieron durante la siembra'],
                answer : 'Las comidas realizadas con las cosechas'
            },
            {
                questionNumber : 6,
                question : '¿Qué les lanzan los disfrazados a aquellos que nos les dan ofrendas?',
                options : ['Piedras','Pepas de zambo','Hojas de eucalipto','Hojas espinosas'],
                answer : 'Hojas espinosas'
            }
        ]
    },
    QUIZ_MISHKI_ZAMBU : {
        id : 15,
        title : 'Prueba sobre el Mishki Zambo',
        questions : [
            {
                questionNumber : 1,
                question : '¿En base a qué está preparado el Mishki Zambu?',
                options : ['Mote molido','Zambo maduro','Zambo tierno','Choclo tierno'],
                answer : 'Zambo maduro'
            },
            {
                questionNumber : 2,
                question : '¿Qué color debe tener el zambo para poder preparar es el Mishki Zambu?',
                options : ['Amarillo anaranjado','Amarillo verdoso','Amarillo patito','Amarillo rojizo'],
                answer : 'Amarillo anaranjado'
            },
            {
                questionNumber : 3,
                question : '¿Cuáles son los ingredientes para poder preparar un Mishki Zambu?',
                options : ['Zambo, agua, harina, canela, mashua y ají','Zambo, aceite, quesillo, sal, canela y mashua','Zambo, harina de maíz, panela, canela, mashua y leche','Zambo, harina, panela, canela, mashua y leche'],
                answer : 'Zambo, harina de maíz, panela, canela, mashua y leche'
            },
            {
                questionNumber : 4,
                question : '¿Con qué se debe servir el mishki zambu?',
                options : ['Arroz','Pan','Huevos','Pan Integral'],
                answer : 'Pan Integral'
            },
            {
                questionNumber : 5,
                question : '¿Cuántos tipos de zambo existen?',
                options : ['Existen 3 tipos (de corteza verde, corteza verde con blanco y blanco)','Existen 2 tipos (de corteza verde y corteza verde con blanco)','Existe 1 tipo (de corteza verde con blanco)','Existen 3 tipos (de corteza verde, corteza blanca y corteza verde con amarillo)'],
                answer : 'Existen 3 tipos (de corteza verde, corteza verde con blanco y blanco)'
            },
            {
                questionNumber : 6,
                question : '¿Qué es el zambo?',
                options : ['Es un vegetal','Es una fruta','Es una planta curativa','Es una planta trepadora'],
                answer : 'Es una planta trepadora'
            }
        ]
    },
    QUIZ_UCHUKUTA : {
        id : 16,
        title : 'Prueba sobre la Uchukuta',
        questions : [
            {
                questionNumber : 1,
                question : '¿En dónde se prepara la Uchukuta?',
                options : ['En las zonas bajas de las provincias de Pichincha e Imbabura','En las zonas bajas de las provincias de Pichincha y Cotopaxi','En las zonas bajas de las provincias de Cotopaxi y Tungurahua','En las zonas bajas de las provincias de Azuay y Loja'],
                answer : 'En las zonas bajas de las provincias de Pichincha y Cotopaxi'
            },
            {
                questionNumber : 2,
                question : '¿Qué indica el preparado de la Uchukuta?',
                options : ['Estatus comunal alto','Estatus comunal bajo','Estatus espiritual','Estatus comunal intermedio'],
                answer : 'Estatus comunal alto'
            },
            {
                questionNumber : 3,
                question : '¿Qué tipo de harina se necesita para poder preparar una Uchukuta?',
                options : ['Harina de trigo','Harina de arveja','Harina de avilla','Harina morada'],
                answer : 'Harina de avilla'
            },
            {
                questionNumber : 4,
                question : '¿Con qué no se debe servir la Uchukuta?',
                options : ['Huevo duro cocido','Picadillo','Cuy asado','Carne de res'],
                answer : 'Carne de res'
            },
            {
                questionNumber : 5,
                question : '¿En qué tipo de fiestas y ceremonias no se come la Uchukuta?',
                options : ['Matrimonios','Bautizos','Velorios','Cumpleaños'],
                answer : 'Cumpleaños'
            },
            {
                questionNumber : 6,
                question : 'La Uchukuta es considerada un tipo de comida…',
                options : ['Nutritiva','Curativa','Nutracéutica','Nativa'],
                answer : 'Nutracéutica'
            }
        ]
    },
    QUIZ_MUTI_LUCI : {
        id : 17,
        title : 'Prueba sobre el Muti Luci',
        questions : [
            {
                questionNumber : 1,
                question : '¿En dónde se prepara el Muti Luci?',
                options : ['En las comunidades de Pichincha','En las comunidades de Imbabura','En las comunidades de Tungurahua','En las comunidades de Cotopaxi'],
                answer : 'En las comunidades de Imbabura'
            },
            {
                questionNumber : 2,
                question : '¿En qué meses se prepara el Muti Luci?',
                options : ['Abril y mayo','Junio y julio','Marzo y abril','Mayo y junio'],
                answer : 'Abril y mayo'
            },
            {
                questionNumber : 3,
                question : '¿Cuál de estos ingredientes es necesario para poder preparar el Muti Luci?',
                options : ['Queso','Hierba luisa','Hierba buena','Cebolla paiteña'],
                answer : 'Hierba buena'
            },
            {
                questionNumber : 4,
                question : '¿Por qué razón está desapareciendo el Muti Luci?',
                options : ['Por la reconstrucción de las costumbres ancestrales','Por la introducción de las costumbres modernas','Por la poca importancia que se la da a las comidas típicas','Por la introducción de comidas modernas'],
                answer : 'Por la introducción de comidas modernas'
            },
            {
                questionNumber : 5,
                question : '¿Con qué fiestas coincide la preparación del Muti Luci?',
                options : ['Con las fiestas patronales','Con las fiestas de los muertos','Con las fiestas de las cruces','Con las fiestas de los wawas'],
                answer : 'Con las fiestas de las cruces'
            },
            {
                questionNumber : 6,
                question : '¿Con qué tipo de choclos no se prepara el Muti Luci?',
                options : ['Con choclos blancos','Con choclos amarillos','Con choclos tiernos','Con choclos negros'],
                answer : 'Con choclos negros'
            }
        ]
    },
    QUIZ_CHAMPUS : {
        id : 18,
        title : 'Prueba sobre el Chámpus',
        questions : [
            {
                questionNumber : 1,
                question : '¿Con qué otro nombre se le conoce al Champús?',
                options : ['Sopa de maíz','Yogurt de maíz','Yogurt de choclo','Sopa de choclo'],
                answer : 'Yogurt de maíz'
            },
            {
                questionNumber : 2,
                question : '¿Qué comunidad prepara el Chámpus?',
                options : ['La comunidad Shuar','La comunidad Salasaca','La comunidad Zumbahua','La comunidad Quitu y Kayambi'],
                answer : 'La comunidad Zumbahua'
            },
            {
                questionNumber : 3,
                question : '¿Qué ingrediente falta para poder preparar un Chámpus?\nHarina de maíz, agua fría y caliente, hojas de naranja y arrayán',
                options : ['Canela','Azúcar','Panela','Wila'],
                answer : 'Panela'
            },
            {
                questionNumber : 4,
                question : 'El Chámpus es considerado una comida...',
                options : ['Nutritiva','Curativa','Ancestral','Nutracéutica'],
                answer : 'Nutracéutica'
            },
            {
                questionNumber : 5,
                question : '¿En qué tipo de fiestas se suele comer el Chámpus?',
                options : ['En fiestas grandes como matrimonios','En fiestas pequeñas como nacimientos','En fiestas escolares','En fiestas de compadres'],
                answer : 'En fiestas grandes como matrimonios'
            },
            {
                questionNumber : 6,
                question : 'En la provincia de Cotopaxi, ¿Qué tipo de maíz se utiliza para la preparación del Chámpus?',
                options : ['Maíz blanco','Maíz amarillo','Maíz negro','Maíz tierno'],
                answer : 'Maíz negro'
            }
        ]
    },
    
    QUIZ_MAKI_MACHIKA : {
        id : 19,
        title : 'Prueba sobre el Maki Machika',
        questions : [
            {
                questionNumber : 1,
                question : '¿Con qué otro nombre se le conoce a la Machika?',
                options : ['Mishki','Kamlla','Mashka','Wila'],
                answer : 'Mashka'
            },
            {
                questionNumber : 2,
                question : '¿A qué región pertenece la preparación de la Machika?',
                options : ['Sierra','Costa','Amazonía','Península'],
                answer : 'Sierra'
            },
            {
                questionNumber : 3,
                question : '¿Qué indica esta bebida?',
                options : ['Poder, sabiduría y valentía','Fortaleza, fuerza y poder','Sabiduría, fortaleza y poder','Fuerza, destreza e inteligencia'],
                answer : 'Fortaleza, fuerza y poder'
            },
            {
                questionNumber : 4,
                question : '¿Qué tipo de Machika no es correcta?',
                options : ['Machika de haba','Machika de cebada','Machika de maíz','Todas están correctas'],
                answer : 'Todas están correctas'
            },
            {
                questionNumber : 5,
                question : '¿Qué es kutana rumi?',
                options : ['Piedra de moler','Reverbero','Piedra de cocinar','Piedra de tallar'],
                answer : 'Piedra de moler'
            },
            {
                questionNumber : 6,
                question : '¿Qué es shushuna?',
                options : ['Cuchara','Mortero','Cedazo','Cucharón'],
                answer : 'Cedazo'
            }
        ]
    },
    QUIZ_MISHKI_KAMLLA : {
        id : 20,
        title : 'Prueba sobre el Mishki Kamlla',
        questions : [
            {
                questionNumber : 1,
                question : '¿Con qué otro nombre se le conoce al Mishki Kamlla?',
                options : ['Tostado dulce','Arroz con leche','Tostado picante','Tostado molido'],
                answer : 'Tostado dulce'
            },
            {
                questionNumber : 2,
                question : '¿Qué significa yarjay pacha?',
                options : ['Época de bonanza','Época de cosecha','Época de hambruna','Época de siembra'],
                answer : 'Época de hambruna'
            },
            {
                questionNumber : 3,
                question : '¿En qué meses se come el Mishki Kamlla?',
                options : ['Abril y mayo','Junio y julio','Agosto y septiembre','Julio y agosto'],
                answer : 'Julio y agosto'
            },
            {
                questionNumber : 4,
                question : '¿En qué ocasiones no se come el Mishki Kamlla?',
                options : ['En el momento de la siembra','En el momento de la cosecha','En la época de deshierbe','En momentos de la bonanza'],
                answer : 'En la época de deshierbe'
            },
            {
                questionNumber : 5,
                question : '¿Por qué razón se les da Mishki Kamlla a los wawas?',
                options : ['Para premiarles por hacer un buen trabajo','Para que mejoren la concentración en la escuela','Para cuando sientan hambre','Para castigarles cuando se portan mal'],
                answer : 'Para premiarles por hacer un buen trabajo'
            },
            {
                questionNumber : 6,
                question : '¿Qué ingrediente falta para la preparación del Mishki Kamlla?\nMaíz, agua, canela y aceite',
                options : ['Azúcar','Panela','Leche','Sal'],
                answer : 'Panela'
            }
        ]
    },
    QUIZ_YAMUR_ASUA : {
        id : 21,
        title : 'Prueba sobre el Yamur Asua',
        questions : [
            {
                questionNumber : 1,
                question : '¿Cómo también es conocido el Yamur Asua?',
                options : ['Dulce del yamor','Bebida del yamor','Chicha del yamor','Trago del yamor'],
                answer : 'Chicha del yamor'
            },
            {
                questionNumber : 2,
                question : '¿Cuándo no se debe preparar el Yamur Asua?',
                options : ['Durante la celebración de la fecundidad','Durante el Kulla Raymi','Durante el comienzo de la siembra','Durante el Inti Raymi'],
                answer : 'Durante el Inti Raymi'
            },
            {
                questionNumber : 3,
                question : '¿Con qué no está relacionada la preparación del Yamur Asua?',
                options : ['Con la crianza de la biodiversidad','Con la crianza de los maíces','Con la crianza del ganado','Con la crianza del humano'],
                answer : 'Con la crianza del ganado'
            },
            {
                questionNumber : 4,
                question : '¿Cuál de estos ingredientes es necesario para preparar el Yamur Asua?',
                options : ['Maíz amarillo','Hierba buena','Pasta de tomate','Toronjil'],
                answer : 'Maíz amarillo'
            },
            {
                questionNumber : 5,
                question : '¿Durante cuánto tiempo se debe hacer fermentar el Yamur Asua?',
                options : ['Durante 5 días','Durante 3 días','Durante 4 días','Durante 6 días'],
                answer : 'Durante 4 días'
            },
            {
                questionNumber : 6,
                question : '¿Qué significa pundu?',
                options : ['Olla de barro','Piedra de moler','Cedazo','Cuchara de palo'],
                answer : 'Olla de barro'
            }
        ]
    },
    QUIZ_KAZTU_KAMLLA : {
        id : 22,
        title : 'Prueba sobre el Katzu Kamlla',
        questions : [
            {
                questionNumber : 1,
                question : '¿En qué provincias se prepara el Katzu Kamlla?',
                options : ['Cotopaxi, Azuay y Tungurahua','Pichincha, Imbabura y Carchi','Pichincha, Imbabura y Cotopaxi','Pichincha, Cotopaxi y Carchi'],
                answer : 'Pichincha, Imbabura y Carchi'
            },
            {
                questionNumber : 2,
                question : '¿Cuál no es un tipo de katzu?',
                options : ['Kaztu blanco','Kaztu café','Kaztu verde','Kaztu amarillo'],
                answer : 'Kaztu amarillo'
            },
            {
                questionNumber : 3,
                question : '¿Cuáles son los katzus más exquisitos?',
                options : ['Katzu verde y café','Katzu blanco y negro','Katzu blanco y café','Katzu verde y negro'],
                answer : 'Katzu blanco y café'
            },
            {
                questionNumber : 4,
                question : '¿Durante qué época se encuentran los katzus más deliciosos?',
                options : ['Durante la época lluviosa','Durante la época de invierno','Durante la época seca','Durante la época de cosecha'],
                answer : 'Durante la época lluviosa'
            },
            {
                questionNumber : 5,
                question : '¿Por qué razón no se debe recolectar los katzus cuando hay sol?',
                options : ['Porque dicen que los katzu pierden su sabor','Porque dicen que se pierden la cabecita','Porque dicen que así dicta la tradición','Porque dicen que caso contrario pueden causar daño'],
                answer : 'Porque dicen que se pierden la cabecita'
            },
            {
                questionNumber : 6,
                question : '¿En qué provincias se comercializa el Katzu Kamlla en los mercados?',
                options : ['En la provincia de Pichincha','En la provincia de Cotopaxi','En la provincia de Imbabura','En la provincia de Carchi'],
                answer : 'En la provincia de Imbabura'
            }
        ]
    },
    QUIZ_YANA_API : {
        id : 23,
        title : 'Prueba sobre el Yana Api',
        questions : [
            {
                questionNumber : 1,
                question : '¿Qué significa Yana Api?',
                options : ['Mote con chicharrones','Fanesca','Máchica','Colada morada'],
                answer : 'Yana Api'
            },
            {
                questionNumber : 2,
                question : '¿Durante qué mes se empiezan han desgranar para preparar el Yana Api?',
                options : ['Enero','Marzo','Octubre','Noviembre'],
                answer : 'Octubre'
            },
            {
                questionNumber : 3,
                question : '¿Con qué se acompaña el consumo del Yana Api?',
                options : ['Wawa tanda','Catzos con sal','Hornado','Chicha morada'],
                answer : 'Wawa tanda'
            },
            {
                questionNumber : 4,
                question : '¿Cuándo se acostumbra a comer el Yana Api?',
                options : ['El 31 de octubre','El 2 de noviembre','El 24 de diciembre','El 31 de diciembre'],
                answer : 'El 2 de noviembre'
            },
            {
                questionNumber : 5,
                question : '¿Cuál de estas hierbas silvestres es importante para la preparación de la colada morada?',
                options : ['Hojas de hierba buena','Hojas de cedrón','Clavos de olor','Manzanilla'],
                answer : 'Clavos de olor'
            },
            {
                questionNumber : 6,
                question : '¿Por qué se cuelgan los maíces en las wayungas?',
                options : ['Para que se sequen por completo','Para que se le caiga toda la tierra','Para que agarren fuerza','Para que se caigan todos los insectos'],
                answer : 'Para que agarren fuerza'
            }
        ]
    },
    QUIZ_CHURU_API : {
        id : 24,
        title : 'Prueba sobre el Churu Api',
        questions : [
            {
                questionNumber : 1,
                question : '¿Durante qué mes se come más se come el Churu Api?',
                options : ['Durante el mes de abril','Durante el mes de julio','Durante el mes de noviembre','Durante el mes de agosto'],
                answer : 'Durante el mes de noviembre'
            },
            {
                questionNumber : 2,
                question : '¿Durante qué festividad se consume mucho el Churu Api?',
                options : ['Durante el día de los difuntos','Durante la semana santa','Durante la navidad','Durante el carnaval'],
                answer : 'Durante la semana santa'
            },
            {
                questionNumber : 3,
                question : '¿De dónde proviene el Churu Api?',
                options : ['Del valle del Chota','Del valle del amanecer','Del valle de Pastocalle','Del valle de Guano'],
                answer : 'Del valle del Chota'
            },
            {
                questionNumber : 4,
                question : '¿Con qué se elabora el Churi Api?',
                options : ['Con pushgay','Con katzu','Con wila','Con uchujaku'],
                answer : 'Con uchujaku'
            },
            {
                questionNumber : 5,
                question : '¿Cuál de estos granos se utiliza para la preparación del uchujaku?',
                options : ['Garbanzo','Fréjol','Lenteja','Quinua'],
                answer : 'Lenteja'
            },
            {
                questionNumber : 6,
                question : '¿Cuál es la preparación del uchujaku?',
                options : ['Se tuestan los granos por separados molidos con ajo y comino','Se tuestan los granos todos juntos molidos con ajo y comino','Se tuestan los granos de dos en dos molidos con ajo y comino','Se tuestan los granos todos juntos molidos con ajo picado y comino'],
                answer : 'Se tuestan los granos por separados molidos con ajo y comino'
            },
        ]
    },
}