import { BaseScene } from "./BaseScene"
import ModuleWindow from "../GameObjects/commonObjects/ModuleWindow"
import RewardModal from "../GameObjects/commonObjects/rewardModal";
import { scenesKey } from "../utils/gameConstants";
import ConfirmationModal from "../GameObjects/commonObjects/confirmationModal";
import { updatePuzzlePiecePlacedStatus } from "../../../services/gameServices/piecesService";
import { changeQuizRecord } from "../utils/functions/save-record-functions";
import { mainUIResources, mainUISoundResources } from "../utils/assetsPath";



export default class ModuleScene extends BaseScene{

    createWindow(title){
        this.windowScene = new ModuleWindow(this,0,0);
        this.windowScene.setPosition(this.width*0.65,this.height/2);
        this.windowScene.addSceneWindow(this);
        this.windowScene.addWindowRibbon(this,title);
        this.windowScene.createSceneWindow(this);
    }

    
    displaySceneWindow(){
        this.add.existing(this.windowScene);
    }

    showRewardModal(type,data){
        let info = data;
        let modal = new RewardModal(this,this.width*0.65,this.height*0.5);
        switch(type){
            case 1:
                modal.newToolReward(this,'¡ganaste una herramienta\npara las chakras!');
                break;
            case 2:
                modal.newSeedReward(this,'¡ganaste una nueva\nsemilla de colección!',info)
                break;
            case 3:
                modal.newPuzzlePieceReward(this,'¡ganaste una nueva pieza\ndel calendario agrofestivo!',info);
                break;
            case 4:
                modal.newBookPriceReward(this,'¡superaste todas las\ntrivias del relato vivencial!');
                break;
            case 5:
                modal.unlockTaleReward(this,'¡desbloqueaste un nuevo\nrelato vivencial!', info);
                break;
            default:
                break;
        }  
        modal.setDisplaySize(this.width*0.5,this.height*0.9);
        modal.setAlpha(0);
        this.animModal(modal);
    }

    showConfirmationModal(type,result){
        let modal = new ConfirmationModal(this,this.width*0.65,this.height*0.5);
        switch(type){
            case 1 :
                modal.setValues('TRIVIA ANCESTRAL','¿QUIERES VOLVER A REPETIR\nLA TRIVIA ANCESTRAL?');
                modal.buildModal(this);
                modal.activeButtons(()=>{
                    this.emitToMainUI('new_quiz_try');
                },()=>{
                    changeQuizRecord(result);
                    // this.saveQuizRecord(result);
                    modal.cancelNewQuizTry(result);
                    this.emitToMainUI('modal_gone');
                });
                break;
            case 2 :
                modal.setValues('CONFIRME SU SALIDA','¿QUIERES REGRESAR A LA PANTALLA ANTERIOR?');
                break;
            default : 
                break;
        }
        modal.setAlpha(0).setDisplaySize(this.width*0.5,this.height*0.9);
        this.animModal(modal);
    }

    emitToMainUI(message, data){
        let mainUI = this.scene.get(scenesKey.MAINUI_SCENE);
        if(data){
            mainUI.events.emit(message,data)
        }else{
            mainUI.events.emit(message)
        }
    }



    animModal(modal){
        this.tweens.add({targets:modal,alpha:{from:0,to:1}, duration:400});
    }

    showGuidePart(dialog,delay,confirmAction){
        let mainUIScene = this.scene.get(scenesKey.MAINUI_SCENE);
        mainUIScene.passingAnAction(dialog,()=>{
            this.time.addEvent({delay,callback:()=>{confirmAction()}});
        })
    }

    showInstruction(dialog,delay,action){
        let mainUIScene = this.scene.get(scenesKey.MAINUI_SCENE);
        mainUIScene.changeInstruction(dialog,()=>{
            this.time.addEvent({delay,callback:()=>{action()}});
        })
    }

    finishGuideAction(){
        let mainUIScene = this.scene.get(scenesKey.MAINUI_SCENE);
        mainUIScene.showDialogButton();
    }

    disableOmitButton(){
        let mainUIScene = this.scene.get(scenesKey.MAINUI_SCENE);
        let dialogBox = mainUIScene.window.dialogBox;
        dialogBox.blockOmitButton();
    }

    enableOmitButton(){
        let mainUIScene = this.scene.get(scenesKey.MAINUI_SCENE);
        let dialogBox = mainUIScene.window.dialogBox;
        dialogBox.unlockOmitButton();
    }

    // saveQuizRecord(results){
    //     let loggedPlayer = JSON.parse(localStorage.getItem('loggedPlayer')),
    //         playerId = loggedPlayer.id,
    //         quizId = results.quizId,
    //         quizRecords = JSON.parse(localStorage.getItem('quizRecords')),
    //         oldRecord = quizRecords.find((record)=>{return record.quiz_id === quizId}),
    //         index = quizRecords.indexOf(oldRecord),
    //         params = {
    //             playerid : playerId,
    //             hits : results.correctAnswers,
    //             fails : results.wrongAnswers,
    //             score : results.score,
    //         };

    //         updatePlayerQuizRecord(quizId, params).then((response)=>{
    //             let newRecord = response.data[0];
    //             quizRecords[index] = newRecord;
    //             localStorage.setItem('quizRecords',JSON.stringify(quizRecords));
    //         })    
    // }

    changePieceRecordStatus(puzzlePieceId){
        let pieceRecords = JSON.parse(localStorage.getItem('pieceRecords')),
            oldRecord = pieceRecords.find((record)=>{return record.puzzle_piece_id === puzzlePieceId}),
            index = pieceRecords.indexOf(oldRecord),
            loggedPlayer = JSON.parse(localStorage.getItem('loggedPlayer')),
            params = {playerid : loggedPlayer.id, placed : 1};

        updatePuzzlePiecePlacedStatus(puzzlePieceId,params).then(response => {
            let newRecord = response.data[0];
            console.log('registro cambiado! ',newRecord);
            pieceRecords[index] = newRecord;
            localStorage.setItem('pieceRecords',JSON.stringify(pieceRecords));
        })
    }

    showGuideArrow(){
        let x = this.width*0.121, yInit = this.height*0.67;
        this.guideArrow = this.add.image(x,yInit,mainUIResources.GUIDE_ARROW.key);
        this.guideArrow.setScale(this.width*0.00006).setAngle(90);
        
        this.arrowAnim = this.tweens.add({
            targets : this.guideArrow,
            y : {from : yInit, to : yInit - 10},
            yoyo : true,
            repeat : -1,
            duration : 600
        })
    }

    destroyGuideArrow(){
        this.arrowAnim.remove();
        this.guideArrow.destroy();
    }

}