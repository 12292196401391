import Phaser from 'phaser';
import PhotoBox from './photoBox';

export default class SmallInfoCard extends Phaser.GameObjects.Container{
    constructor(scene,x,y,data){
        super(scene,x,y);
        this.setSize(450,140);
        this.loadData(data);
        this.buildCard(scene);
    }

    setCommunity(community){
        this.community = community;
    }

    setState(state){
        this.state = state;
    }

    setDescription(description){
        this.description = description;
    }

    setTitle(title){
        this.title = title;
    }

    setPhoto(photo){
        this.photo = photo;
    }

    loadData(data){
        this.setTitle(data.title);
        this.setDescription(data.description);
        this.setPhoto(data.photo);
        this.setCommunity(data.community);
        this.setState(data.state);
    }

    addInfoPhoto(scene){
        let photoBox = new PhotoBox(scene,-150,0,this.photo);
        photoBox.setScale(0.6);
        this.add(photoBox);
    }

    createCardBody(scene){
        let container = scene.add.container(0,0);
        container.setSize(450,200);

        let body = scene.add.graphics();
        body.fillStyle(0x9B8465,1)
        .lineStyle(3,0x000,1)
        .fillRoundedRect(-225,-100,450,200,5)
        .strokeRoundedRect(-225,-100,450,200,5);

        container.add(body);

        return container;
    }

    addBody(scene){
        this.card = this.createCardBody(scene);
        this.add(this.card);
    }

    addDescription(scene){
        this.description = this.createDescriptionBody(scene);
        // this.description.setPosition(0,40);
        this.card.add(this.description);
    }

    

    addPhotoBox(scene){
        this.photoBox = this.createPhotoBox(scene);
        this.photoBox.setPosition(-180,-55);
        this.add(this.photoBox);
    }

    createDescriptionBody(scene){
        let container = scene.add.container(0,0);
        container.setSize(430,180);
        let body = scene.add.graphics();
        body.fillStyle(0xD2C75E,1)
        .lineStyle(2,0x000,1)
        .fillRoundedRect(-215,-90,430,180,5)
        .strokeRoundedRect(-215,-90,430,180,5);

        let paragraph = this.createTextDescription(scene,this.description,0,-10);
        paragraph.setOrigin(0.55,0.2);
        container.add([body,paragraph]);
        return container;
    }

    createPhotoBox(scene){
        let container = scene.add.container(0,0);
        container.setSize(100,100);

        let text = scene.add.text(-20,-10,this.title,{fontFamily:'Helvetica',fontSize:'22px',color:'0x000'});
        
        let body = scene.add.graphics();
        body.fillStyle(0x1D978E,1).lineStyle(2,0x000,1)
        .fillRoundedRect(-25,-25,text.width+10,50,5)
        .strokeRoundedRect(-25,-25,text.width+10,50,5);

        container.add([body,text]);

        return container;
    }

    createLegend(scene){
        let container = scene.add.container(0,0);
        container.setSize(330,100);

        let legend = scene.add.text(0,0,`saber proveniente de`,{fontFamily:'Helvetica',fontSize:'16px',color:'#000',align:'justify'});
        let text;
        if(this.community!==null&&this.state!==null){
            text = `${this.community},${this.state}`
        }else{
            text = 'serranía ecuatoriana';
        }
        
        let description = scene.add.text(0,5,text,{fontFamily:'Helvetica',fontSize:'18px',color:'#000',align:'justify'});
        legend.setOrigin(0.5,0.9);
        description.setOrigin(0.5,0.1);
        container.add([legend,description]);
        return container;
    }

    createTextDescription(scene,text,x,y){
        let textConfig = {
            x,
            y,
            padding: {
                left: 64,
                right: 16,
                top: 20,
                bottom: 40
            },
            text,
            style: {
                fontSize: '20px',
                fontFamily: 'Helvetica',
                color: '#000',
                align: 'justify',
                wordWrap: {width:430, useAdvancedWrap: true}
            }
        }
        let paragraph = scene.make.text(textConfig);
        return paragraph;
    }

    createDescriptionLabel(scene,title){
        let label = scene.add.graphics();
        let container = scene.add.container(30,-40);
        container.setSize(180,30);

        label.fillStyle(0x1D978E,1).lineStyle(2,0x000,1);
        let text = scene.add.text(0,0,title,{fontFamily:'Helvetica',fontSize:'16px',color:'#000'});
        label.fillRoundedRect(-5,-5,text.width + 10,text.height + 10,15)
        .strokeRoundedRect(-5,-5,text.width + 10,text.height + 10,15);

        container.add([label,text]);
        return container;
    }

    addLegend(scene){
            this.legend = this.createLegend(scene);
            this.legend.setPosition(100,-50);
            this.description.add(this.legend);
    }

    buildCard(scene){
        this.addBody(scene);
        this.addDescription(scene);
        this.addPhotoBox(scene);
        this.addLegend(scene);
    }


}