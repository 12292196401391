import Phaser from 'phaser';
import { mainUIResources, mainUISoundResources } from '../../utils/assetsPath';
import BubbleDialogButton from './assistant-dialog-components/bubbleDialogButton';
import { assistantDialogButtonsInformation } from '../../utils/information/buttons/assistant-menu-btn/buttonsInformation';
import { gridAlignGroupElements, addChildrenToContainer } from '../../utils/functions';
import { confirmationDialogList } from '../../utils/information/assistant/assistantDialogs';

export default class DialogModal extends Phaser.GameObjects.Container{
    constructor(scene,x,y){
        super(scene,x,y);
        this.graphics = scene.add.graphics();
        this.dialogSpeed = 3;
        this.createModal();
        this.setInteractive();
        scene.add.existing(this);
    }

    setDialog(dialog){
        this.dialog = dialog;
    }

    setContinueButton(button){ this.continueButton = button }
    getContinueButton(){ return this.continueButton }

    setOmitButton(button){ this.omitButton = button }
    getOmitButton(){ return this.omitButton }


    drawDialogBox(){
        this.graphics.lineStyle(2,0x2d2d2d,0.5)
        .fillStyle(0xbcddff,0.5);
        this.graphics.beginPath();
        this.graphics.moveTo(-105,-100);
        this.graphics.lineTo(-90,-100);
        this.graphics.lineTo(-90,-210);
        this.graphics.lineTo(230,-210);
        this.graphics.lineTo(230,180);
        this.graphics.lineTo(-90,180);
        this.graphics.lineTo(-90,-50);
        this.graphics.lineTo(-90,-50);
        this.graphics.lineTo(-105,-100);
        this.graphics.closePath();
        this.graphics.fillPath();
        this.graphics.strokePath();
        this.add(this.graphics);
    }

    createModal(){
        this.drawDialogBox();
        this.add(this.graphics).setSize(500,500);
    }


    createModalWithButton(scene,dialogList){
        this.drawDialogBox();
        this.add(this.graphics).setSize(500,500);
        for(let i=0;i<dialogList.length;i++){
            this.addTextDialog(scene,dialogList[i],false);
            this.eraseDialog(()=>{this.addTextDialog(scene,dialogList[i+1],false)})
        }
    }

    createOkBtn(scene){
        let okButtonTexture = scene.add.image(0,0,mainUIResources.OK_BUTTON.key);
        this.okButton = scene.add.container(70,140,[okButtonTexture]);
        this.okButton.setSize(okButtonTexture.width,okButtonTexture.height)
        .setInteractive().setScale(0.35);
        this.add(this.okButton);

        this.selectOkButton(scene);
    }

    createContinueButton(scene){
        let continueButton = new BubbleDialogButton(scene,70,130,assistantDialogButtonsInformation.CONTINUE_BUTTON);
        this.setContinueButton(continueButton);
        this.add(this.getContinueButton());
        this.getContinueButton().setScale(0.28);

        this.shutDownModal(scene);
    }

    createGroupButtons(scene){
        let continueButton = new BubbleDialogButton(scene,0,0,assistantDialogButtonsInformation.CONTINUE_BUTTON);
        this.setContinueButton(continueButton);
        let omitButton = new BubbleDialogButton(scene,0,0,assistantDialogButtonsInformation.OMIT_BUTTON);
        this.setOmitButton(omitButton);

        continueButton = this.getContinueButton();
        omitButton = this.getOmitButton();
        continueButton.setScale(0.28);
        omitButton.setScale(0.28);

        let group = scene.add.group([continueButton,omitButton]);
        let gridConfig = { width : 2, height : 1, cellWidth : 160, position : 1, y : 80, x : -15 };
        gridAlignGroupElements(scene,group,gridConfig);
        addChildrenToContainer(this,group.getChildren());
    }




    shutDownModal(scene,action){
        let continueButton = this.getContinueButton();
        continueButton.on('pointerup',()=>{
            scene.sound.play(mainUISoundResources.DIALOG_MODAL_BUTTON_CLIC.key);
            this.graphics.clear();
            scene.events.emit('modal_shutdown');
            if(action) action();
        })
    }

    shutDownGroupModal(scene,confirmAction){
        let continueButton = this.getContinueButton(),
            omitButton = this.getOmitButton();
        continueButton.on('pointerup',()=>{
            scene.sound.play(mainUISoundResources.DIALOG_MODAL_BUTTON_CLIC.key);
            this.graphics.clear();
            scene.events.emit('modal_shutdown');
            if(confirmAction) confirmAction();
        })
        omitButton.on('pointerup',()=>{
            scene.sound.play(mainUISoundResources.DIALOG_MODAL_BUTTON_CLIC.key);
            this.graphics.clear();
            scene.events.emit('modal_shutdown');
            scene.events.emit('cancelGuide');
        })
    }

    showConfirmationGuideDialog(){
        let dialog = confirmationDialogList.CANCEL_GUIDE_DIALOG;
        this.changeText(dialog);
    }

    selectOkButton(scene,action){
        this.okButton.on('pointerup',()=>{
            scene.sound.play(mainUISoundResources.DIALOG_MODAL_BUTTON_CLIC.key);
            this.graphics.clear();
            scene.events.emit('modal_shutdown');
            if(action) action();
        });
    }

    cleanModal(scene){
        this.graphics.clear();
        scene.events.emit('modal_shutdown');
    }

    changeText(newText){
        this.text.setText(newText);
    }

    eraseDialog(action){
        this.okButton.on('pointerdown',()=>{
            this.okButton.setTint(0x2d2d2d);
        })

        this.okButton.on('pointerup',()=>{
            this.okButton.clearTint();
            this.text.destroy();
            action();
        })
    }

    setOkActions(scene,action){
        this.okButton.on('pointerup',()=>{
            scene.sound.play(mainUISoundResources.DIALOG_MODAL_BUTTON_CLIC.key);
            this.graphics.clear();
            scene.events.emit('modal_shutdown');
            if(action) action();
        });
    }

    destroyModal(scene){
        this.graphics.clear();
        scene.events.emit('modal_shutdown');
    }

    setText(scene,text){
        if (this.text) this.text.destroy();
        
        let x = this.x-this.width/6;
        let y = this.y-this.height*0.37;

        this.text = scene.make.text({
            x,
            y,
            text,
            style:{
                fontSize:'25px',
                fontFamily:'Segoe UI',
                color:'#000',
                align:'justify',
                wordWrap:{width:this.width-200}
            }
        });

        this.add(this.text);
    }

    addTextDialog(scene,text,animate){
        this.eventCounter = 0;
        this.dialog = text.split('');
        if(this.timedEvent) 
            this.timedEvent.remove();

        
        let tempText = animate?'':text;
        this.setText(scene,tempText);

        if(animate===true){
            this.timedEvent = scene.time.addEvent({
                delay:150 - (this.dialogSpeed * 30),
                callback:()=>{
                    this.eventCounter++;
                    scene.sound.play('sans');
                    this.text.setText(this.text.text + this.dialog[this.eventCounter - 1]);
                    if(this.eventCounter === this.dialog.length){
                        this.timedEvent.remove();
                        // this.createOkBtn(scene);
                        this.createContinueButton(scene);
                    }
                },
                callbackScope:scene,
                loop:true
            });
        }else{
            // this.createOkBtn(scene);
            this.createContinueButton(scene);
        }
    }

    generateText(scene,text){
        this.setText(scene,text);
    }

    hideOkButton(){
        this.okButton.setVisible(false);
    }

    hideContinueButton(){
        let continueButton = this.getContinueButton();
        continueButton.setVisible(false);
    }

    showContinueButton(){
        let continueButton = this.getContinueButton();
        continueButton.setVisible(true);
    }

    hideOmitButton(){
        let omitButton = this.getOmitButton();
        omitButton.setVisible(false);
    }

    showOmitButton(){
        let omitButton = this.getOmitButton();
        omitButton.setVisible(true);
    }

    hideGroupButtons(){
        this.hideContinueButton();
        this.hideOmitButton();
    }

    showGroupButtons(){
        this.showContinueButton();
        this.showOmitButton();
    }

    blockOmitButton(){
        let omitButton = this.getOmitButton();
        omitButton.setAlpha(0.5);
        omitButton.disableInteractive();
    }

    showOkButton(){
        this.okButton.setVisible(true);
    }
}