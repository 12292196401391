import { getPlayer } from '../services/reactServices/playerService';
import { LOAD_PLAYER_FAILURE, LOAD_PLAYER_SUCCESS } from './actionTypes';
import { loadPlayerTaleRecords } from './talesActions';
import { loadPlayerToolRecords } from './toolActions';
import { loadPlayerSeedRecords } from './seedActions';
import { loadPlayerPieceRecords } from './pieceActions';
import { loadPlayerQuizRecords } from './quizActions';
import { loadPlayerChakraRecords } from './chakraActions';



export function login(credentials){
    return (dispatch)=>{
        return getPlayer(credentials).then((response)=>{
            if(response.data.length>0){
                let player = response.data[0];
                let playerId = player.id;
                dispatch(loadPlayerSuccess(player));
                dispatch(loadPlayerTaleRecords(playerId));
                dispatch(loadPlayerToolRecords(playerId));
                dispatch(loadPlayerSeedRecords(playerId));
                dispatch(loadPlayerPieceRecords(playerId));
                dispatch(loadPlayerQuizRecords(playerId));
                dispatch(loadPlayerChakraRecords(playerId));
                
            }else{
                console.log('no existe este usuario en la base de datos!');
                dispatch(loadPlayerFailure());
            }
        }).catch(error => {throw(error)});
    }
}

function loadPlayerSuccess(player){
    return ({type : LOAD_PLAYER_SUCCESS, player});
}

function loadPlayerFailure(){
    return { type: LOAD_PLAYER_FAILURE }
}