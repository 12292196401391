import * as types from '../actions/actionTypes';
import initialState from './initial-state/initialState';

export default function quizReducer (state = initialState.quiz_records,action){
    switch(action.type){
        case types.CREATE_QUIZ_RECORDS_SUCCESS : 
            return state;

        case types.LOAD_QUIZ_RECORDS_SUCCESS : 
            return state;
            
        case types.LOAD_QUIZ_LIST : 
            return action.recordList;

        default : 
        return state;
    }
}

