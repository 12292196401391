import React, { Component } from 'react';

import {connect} from 'react-redux';
import { Form } from 'react-bootstrap';
import SignInButton from '../buttons/signInButton';

class SignInForm extends Component{
    constructor (props){
        super(props);
        this.state = {
            givenName : "",
            givenCI : "",
            givenPassword : "",
        }
        this.handleOnChange = this.handleOnChange.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
    }

    render(){
        return this.createForm();
    }

    handleOnChange(event){
        let target = event.target,
            name = target.name,
            value = target.value;
        this.setState({[name] : value});
    }

    createForm(){
        return (
        <Form>
            <Form.Group controlId = "formName" style = {{padding:"0px 15px 0px 15px"}}>
            <Form.Label>Player's name</Form.Label>
            <Form.Control 
                name = 'givenName'
                value = {this.state.givenName || ""}
                onChange = {this.handleOnChange}
                placeholder = "Register your first and last name"/>
            </Form.Group>

            <Form.Group controlId = "formCI" style = {{padding:"0px 15px 0px 15px"}}>
            <Form.Label>Player's CI</Form.Label>
            <Form.Control
                name = 'givenCI'
                value = {this.state.givenCI || ""}
                onChange = {this.handleOnChange}
                placeholder = "Register your identity card"/>
            </Form.Group>

            <Form.Group controlId = "formPassword" style = {{padding:"0px 15px 0px 15px"}}>
            <Form.Label>Player's password</Form.Label>
            <Form.Control
                name = 'givenPassword'
                value = {this.state.givenPassword || ""}
                onChange = {this.handleOnChange}
                placeholder = "Register your password"/>
            </Form.Group>

            <Form.Group controlId = "formButton" style = {{padding:"25px 15px 10px 15px"}}>
                <SignInButton givenName = {this.state.givenName}
                givenCI = {this.state.givenCI}
                givenPassword = {this.state.givenPassword}
                />
            </Form.Group>
            </Form>
        )
    }

    componentDidUpdate(prevProps){
        console.log('antes tenia esto: ',prevProps);
        console.log('ahora el jugador es : ', this.props.newPlayer);

    }


}

function mapStateToProps(state){
    return {
        newPlayer : state.newPlayer
    }
}

function mapDispatchToProps(action){
    return {
        
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(SignInForm);
