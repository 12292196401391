import Ribbon from "../baseClasses/Ribbon";
import { mainUIResources } from "../../utils/assetsPath";

export default class AssistantRibbon extends Ribbon{
    constructor(scene,x,y){
        super(scene,x,y,mainUIResources.RIBBON_ASSISTANT.key,'MAMA DULU');
        this.style = {
            fontSize:'40px',
            fontFamily:'Franklin Gothic Heavy',
            color:'#D6BD31',
            align:'center',
            stroke:'#4C1802',
            strokeThickness:5,
            padding:{
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
            }
        };

        this.createRibbon(scene);
        scene.add.existing(this);
    }


}