export const scenesKey={
    INTRO_SCENE : 'IntroScene',
    WELCOME_SCENE : 'WelcomeScene',
    GAME_MENU_SCENE : 'GameMenuScene',
    SIGNUP_SCENE : 'SignUpScene',
    LOGIN_SCENE : 'LogInScene',
    CREDITS_SCENE : 'CreditsScene',
    GUIDE_SCENE : 'GuideScene',
    BOOTLOADER_SCENE : 'BootLoaderScene',
    MAINUI_SCENE : 'MainUIScene',
    CARAC_SCENE : 'CaracScene',
    CHAKRA_SCENE : 'ChakraScene',
    LIBRARY_SCENE : 'LibraryScene',
    MODAL_SCENE : 'ModalScene',
    SELECTED_TALE_SCENE: 'SelectedTaleScene',
    QUIZ_SCENE : 'QuizScene',
    RESULT_SCENE : 'ResultScene'
}

export const mainTextStyle={
    fontFamily:'Helvetica',
    color:'#000',
    align:'center'
}

export const textStyles={
    LABEL_MAIN_MENU_BTN:{
        fontFamily:'Helvetica',
        fontSize:'20px',
        color:'#000',
        align:'center'
    },
    LABEL_ASSISTANT_MENU_BTN:{
        fontFamily:'Helvetica',
        fontSize:'20px',
        color:'#000',
        align:'center'
    }
}

