export const sisayPachaResources = {
    COVER : {
        'key': 'SISAY_PACHA_P',
        'path' : './funcaracAssets/content/festivities/sisay pacha/SISAY_PACHA_P.png'
    },
    SISAY_PACHA_01 : {
        'key': 'SISAY_PACHA_01',
        'path' : './funcaracAssets/content/festivities/sisay pacha/SISAY_PACHA_01.png'
    },
    SISAY_PACHA_02 : {
        'key': 'SISAY_PACHA_02',
        'path' : './funcaracAssets/content/festivities/sisay pacha/SISAY_PACHA_02.png'
    },
    SISAY_PACHA_03 : {
        'key': 'SISAY_PACHA_03',
        'path' : './funcaracAssets/content/festivities/sisay pacha/SISAY_PACHA_03.png'
    },
    SISAY_PACHA_04 : {
        'key': 'SISAY_PACHA_04',
        'path' : './funcaracAssets/content/festivities/sisay pacha/SISAY_PACHA_04.png'
    },
    SISAY_PACHA_05 : {
        'key': 'SISAY_PACHA_05',
        'path' : './funcaracAssets/content/festivities/sisay pacha/SISAY_PACHA_05.png'
    },
    SISAY_PACHA_06 : {
        'key': 'SISAY_PACHA_06',
        'path' : './funcaracAssets/content/festivities/sisay pacha/SISAY_PACHA_06.png'
    },
    SISAY_PACHA_07 : {
        'key': 'SISAY_PACHA_07',
        'path' : './funcaracAssets/content/festivities/sisay pacha/SISAY_PACHA_07.png'
    },
    SISAY_PACHA_08 : {
        'key': 'SISAY_PACHA_08',
        'path' : './funcaracAssets/content/festivities/sisay pacha/SISAY_PACHA_08.png'
    },
    SISAY_PACHA_09 : {
        'key': 'SISAY_PACHA_09',
        'path' : './funcaracAssets/content/festivities/sisay pacha/SISAY_PACHA_09.png'
    },
    SISAY_PACHA_10 : {
        'key': 'SISAY_PACHA_10',
        'path' : './funcaracAssets/content/festivities/sisay pacha/SISAY_PACHA_10.png'
    }
}

export const pawkarRaymiResources = {
    COVER : {
        'key': 'PAWKAR_RAYMI_P',
        'path' : './funcaracAssets/content/festivities/pawkar raymi/PAWKAR_RAYMI_P.png'
    },
    PAWKAR_RAYMI_01 : {
        'key': 'PAWKAR_RAYMI_01',
        'path' : './funcaracAssets/content/festivities/pawkar raymi/PAWKAR_RAYMI_01.png'
    },
    PAWKAR_RAYMI_02 : {
        'key': 'PAWKAR_RAYMI_02',
        'path' : './funcaracAssets/content/festivities/pawkar raymi/PAWKAR_RAYMI_02.png'
    },
    PAWKAR_RAYMI_03 : {
        'key': 'PAWKAR_RAYMI_03',
        'path' : './funcaracAssets/content/festivities/pawkar raymi/PAWKAR_RAYMI_03.png'
    },
    PAWKAR_RAYMI_04 : {
        'key': 'PAWKAR_RAYMI_04',
        'path' : './funcaracAssets/content/festivities/pawkar raymi/PAWKAR_RAYMI_04.png'
    },
    PAWKAR_RAYMI_05 : {
        'key': 'PAWKAR_RAYMI_05',
        'path' : './funcaracAssets/content/festivities/pawkar raymi/PAWKAR_RAYMI_05.png'
    },
    PAWKAR_RAYMI_06 : {
        'key': 'PAWKAR_RAYMI_06',
        'path' : './funcaracAssets/content/festivities/pawkar raymi/PAWKAR_RAYMI_06.png'
    },
    PAWKAR_RAYMI_07 : {
        'key': 'PAWKAR_RAYMI_07',
        'path' : './funcaracAssets/content/festivities/pawkar raymi/PAWKAR_RAYMI_07.png'
    },
    PAWKAR_RAYMI_08 : {
        'key': 'PAWKAR_RAYMI_08',
        'path' : './funcaracAssets/content/festivities/pawkar raymi/PAWKAR_RAYMI_08.png'
    },
    PAWKAR_RAYMI_09 : {
        'key': 'PAWKAR_RAYMI_09',
        'path' : './funcaracAssets/content/festivities/pawkar raymi/PAWKAR_RAYMI_09.png'
    }
}

export const allpaCruzResources = {
    COVER : {
        'key': 'ALLPA_CRUZ_P',
        'path' : './funcaracAssets/content/festivities/allpa cruz/ALLPA_CRUZ_P.png'
    },
    ALLPA_CRUZ_01 : {
        'key': 'ALLPA_CRUZ_01',
        'path' : './funcaracAssets/content/festivities/allpa cruz/ALLPA_CRUZ_01.png'
    },
    ALLPA_CRUZ_02 : {
        'key': 'ALLPA_CRUZ_02',
        'path' : './funcaracAssets/content/festivities/allpa cruz/ALLPA_CRUZ_02.png'
    },
    ALLPA_CRUZ_03 : {
        'key': 'ALLPA_CRUZ_03',
        'path' : './funcaracAssets/content/festivities/allpa cruz/ALLPA_CRUZ_03.png'
    },
    ALLPA_CRUZ_04 : {
        'key': 'ALLPA_CRUZ_04',
        'path' : './funcaracAssets/content/festivities/allpa cruz/ALLPA_CRUZ_04.png'
    },
    ALLPA_CRUZ_05 : {
        'key': 'ALLPA_CRUZ_05',
        'path' : './funcaracAssets/content/festivities/allpa cruz/ALLPA_CRUZ_05.png'
    },
    ALLPA_CRUZ_06 : {
        'key': 'ALLPA_CRUZ_06',
        'path' : './funcaracAssets/content/festivities/allpa cruz/ALLPA_CRUZ_06.png'
    },
    ALLPA_CRUZ_07 : {
        'key': 'ALLPA_CRUZ_07',
        'path' : './funcaracAssets/content/festivities/allpa cruz/ALLPA_CRUZ_07.png'
    }
}

export const intiRaymiResources = {
    COVER : {
        'key': 'INTI_RAYMI_P',
        'path' : './funcaracAssets/content/festivities/inti raymi/INTI_RAYMI_P.png'
    },
    INTI_RAYMI_01 : {
        'key': 'INTI_RAYMI_01',
        'path' : './funcaracAssets/content/festivities/inti raymi/INTI_RAYMI_01.png'
    }, 
    INTI_RAYMI_02 : {
        'key': 'INTI_RAYMI_02',
        'path' : './funcaracAssets/content/festivities/inti raymi/INTI_RAYMI_02.png'
    }, 
    INTI_RAYMI_03 : {
        'key': 'INTI_RAYMI_03',
        'path' : './funcaracAssets/content/festivities/inti raymi/INTI_RAYMI_03.png'
    },
    INTI_RAYMI_04 : {
        'key': 'INTI_RAYMI_04',
        'path' : './funcaracAssets/content/festivities/inti raymi/INTI_RAYMI_04.png'
    }, 
    INTI_RAYMI_05 : {
        'key': 'INTI_RAYMI_05',
        'path' : './funcaracAssets/content/festivities/inti raymi/INTI_RAYMI_05.png'
    },
    INTI_RAYMI_06 : {
        'key': 'INTI_RAYMI_06',
        'path' : './funcaracAssets/content/festivities/inti raymi/INTI_RAYMI_06.png'
    },
    INTI_RAYMI_07 : {
        'key': 'INTI_RAYMI_07',
        'path' : './funcaracAssets/content/festivities/inti raymi/INTI_RAYMI_07.png'
    }
}

export const pallayRaymiResources = {
    COVER : {
        'key': 'PALLAY_RAYMI_P',
        'path' : './funcaracAssets/content/festivities/pallay raymi/PALLAY_RAYMI_P.png'
    },
    PALLAY_RAYMI_01 : {
        'key': 'PALLAY_RAYMI_01',
        'path' : './funcaracAssets/content/festivities/pallay raymi/PALLAY_RAYMI_01.png'
    },
    PALLAY_RAYMI_02 : {
        'key': 'PALLAY_RAYMI_02',
        'path' : './funcaracAssets/content/festivities/pallay raymi/PALLAY_RAYMI_02.png'
    },
    PALLAY_RAYMI_03 : {
        'key': 'PALLAY_RAYMI_03',
        'path' : './funcaracAssets/content/festivities/pallay raymi/PALLAY_RAYMI_03.png'
    },
    PALLAY_RAYMI_04 : {
        'key': 'PALLAY_RAYMI_04',
        'path' : './funcaracAssets/content/festivities/pallay raymi/PALLAY_RAYMI_04.png'
    },
    PALLAY_RAYMI_05 : {
        'key': 'PALLAY_RAYMI_05',
        'path' : './funcaracAssets/content/festivities/pallay raymi/PALLAY_RAYMI_05.png'
    },
    PALLAY_RAYMI_06 : {
        'key': 'PALLAY_RAYMI_06',
        'path' : './funcaracAssets/content/festivities/pallay raymi/PALLAY_RAYMI_06.png'
    },
    PALLAY_RAYMI_07 : {
        'key': 'PALLAY_RAYMI_07',
        'path' : './funcaracAssets/content/festivities/pallay raymi/PALLAY_RAYMI_07.png'
    },
    PALLAY_RAYMI_08 : {
        'key': 'PALLAY_RAYMI_08',
        'path' : './funcaracAssets/content/festivities/pallay raymi/PALLAY_RAYMI_08.png'
    },
    PALLAY_RAYMI_09 : {
        'key': 'PALLAY_RAYMI_09',
        'path' : './funcaracAssets/content/festivities/pallay raymi/PALLAY_RAYMI_09.png'
    }
}

export const sawariyResources = {
    COVER : {
        'key': 'SAWARIY_P',
        'path' : './funcaracAssets/content/festivities/sawariy/SAWARIY_P.png'
    },
    SAWARIY_01 : {
        'key': 'SAWARIY_01',
        'path' : './funcaracAssets/content/festivities/sawariy/SAWARIY_01.png'
    },
    SAWARIY_02 : {
        'key': 'SAWARIY_02',
        'path' : './funcaracAssets/content/festivities/sawariy/SAWARIY_02.png'
    },
    SAWARIY_03 : {
        'key': 'SAWARIY_03',
        'path' : './funcaracAssets/content/festivities/sawariy/SAWARIY_03.png'
    },
    SAWARIY_04 : {
        'key': 'SAWARIY_04',
        'path' : './funcaracAssets/content/festivities/sawariy/SAWARIY_04.png'
    },
    SAWARIY_05 : {
        'key': 'SAWARIY_05',
        'path' : './funcaracAssets/content/festivities/sawariy/SAWARIY_05.png'
    },
    SAWARIY_06 : {
        'key': 'SAWARIY_06',
        'path' : './funcaracAssets/content/festivities/sawariy/SAWARIY_06.png'
    },
    SAWARIY_07 : {
        'key': 'SAWARIY_07',
        'path' : './funcaracAssets/content/festivities/sawariy/SAWARIY_07.png'
    },
    SAWARIY_08 : {
        'key': 'SAWARIY_08',
        'path' : './funcaracAssets/content/festivities/sawariy/SAWARIY_08.png'
    },
    SAWARIY_09 : {
        'key': 'SAWARIY_09',
        'path' : './funcaracAssets/content/festivities/sawariy/SAWARIY_09.png'
    },
    SAWARIY_10 : {
        'key': 'SAWARIY_10',
        'path' : './funcaracAssets/content/festivities/sawariy/SAWARIY_10.png'
    }
}

export const kullaRaymiResources = {
    COVER : {
        'key' : 'KULLA_RAYMI_P',
        'path' : './funcaracAssets/content/festivities/kulla raymi/KULLA_RAYMI_P.png'
    },
    KULLA_RAYMI_01 : {
        'key' : 'KULLA_RAYMI_01',
        'path' : './funcaracAssets/content/festivities/kulla raymi/KULLA_RAYMI_01.png'
    },
    KULLA_RAYMI_02 : {
        'key' : 'KULLA_RAYMI_02',
        'path' : './funcaracAssets/content/festivities/kulla raymi/KULLA_RAYMI_02.png'
    },
    KULLA_RAYMI_03 : {
        'key' : 'KULLA_RAYMI_03',
        'path' : './funcaracAssets/content/festivities/kulla raymi/KULLA_RAYMI_03.png'
    },
    KULLA_RAYMI_04 : {
        'key' : 'KULLA_RAYMI_04',
        'path' : './funcaracAssets/content/festivities/kulla raymi/KULLA_RAYMI_04.png'
    },
    KULLA_RAYMI_05 : {
        'key' : 'KULLA_RAYMI_05',
        'path' : './funcaracAssets/content/festivities/kulla raymi/KULLA_RAYMI_05.png'
    },
    KULLA_RAYMI_06 : {
        'key' : 'KULLA_RAYMI_06',
        'path' : './funcaracAssets/content/festivities/kulla raymi/KULLA_RAYMI_06.png'
    },
    KULLA_RAYMI_07 : {
        'key' : 'KULLA_RAYMI_07',
        'path' : './funcaracAssets/content/festivities/kulla raymi/KULLA_RAYMI_07.png'
    },
    KULLA_RAYMI_08 : {
        'key' : 'KULLA_RAYMI_08',
        'path' : './funcaracAssets/content/festivities/kulla raymi/KULLA_RAYMI_08.png'
    },
    KULLA_RAYMI_09 : {
        'key' : 'KULLA_RAYMI_09',
        'path' : './funcaracAssets/content/festivities/kulla raymi/KULLA_RAYMI_09.png'
    }
}

export const riksichiyResources = {
    COVER : {
        'key' : 'RIKSICHIY_P',
        'path' : './funcaracAssets/content/festivities/riksichiy/RIKSICHIY_P.png'
    },
    RIKSICHIY_01 : {
        'key' : 'RIKSICHIY_01',
        'path' : './funcaracAssets/content/festivities/riksichiy/RIKSICHIY_01.png'
    },
    RIKSICHIY_02 : {
        'key' : 'RIKSICHIY_02',
        'path' : './funcaracAssets/content/festivities/riksichiy/RIKSICHIY_02.png'
    },
    RIKSICHIY_03 : {
        'key' : 'RIKSICHIY_03',
        'path' : './funcaracAssets/content/festivities/riksichiy/RIKSICHIY_03.png'
    },
    RIKSICHIY_04 : {
        'key' : 'RIKSICHIY_04',
        'path' : './funcaracAssets/content/festivities/riksichiy/RIKSICHIY_04.png'
    },
    RIKSICHIY_05 : {
        'key' : 'RIKSICHIY_05',
        'path' : './funcaracAssets/content/festivities/riksichiy/RIKSICHIY_05.png'
    },
    RIKSICHIY_06 : {
        'key' : 'RIKSICHIY_06',
        'path' : './funcaracAssets/content/festivities/riksichiy/RIKSICHIY_06.png'
    },
    RIKSICHIY_07 : {
        'key' : 'RIKSICHIY_07',
        'path' : './funcaracAssets/content/festivities/riksichiy/RIKSICHIY_07.png'
    },
    RIKSICHIY_08 : {
        'key' : 'RIKSICHIY_08',
        'path' : './funcaracAssets/content/festivities/riksichiy/RIKSICHIY_08.png'
    },
    RIKSICHIY_09 : {
        'key' : 'RIKSICHIY_09',
        'path' : './funcaracAssets/content/festivities/riksichiy/RIKSICHIY_09.png'
    },
    RIKSICHIY_10 : {
        'key' : 'RIKSICHIY_10',
        'path' : './funcaracAssets/content/festivities/riksichiy/RIKSICHIY_10.png'
    }
}

export const velanakuyResources = {
    COVER : {
        'key' : 'VELANAKUY_P',
        'path' : './funcaracAssets/content/festivities/velanakuy/VELANAKUY_P.png'
    },
    VELANAKUY_01 : {
        'key' : 'VELANAKUY_01',
        'path' : './funcaracAssets/content/festivities/velanakuy/VELANAKUY_01.png'
    },
    VELANAKUY_02 : {
        'key' : 'VELANAKUY_02',
        'path' : './funcaracAssets/content/festivities/velanakuy/VELANAKUY_02.png'
    },
    VELANAKUY_03 : {
        'key' : 'VELANAKUY_03',
        'path' : './funcaracAssets/content/festivities/velanakuy/VELANAKUY_03.png'
    },
    VELANAKUY_04 : {
        'key' : 'VELANAKUY_04',
        'path' : './funcaracAssets/content/festivities/velanakuy/VELANAKUY_04.png'
    },
    VELANAKUY_05 : {
        'key' : 'VELANAKUY_05',
        'path' : './funcaracAssets/content/festivities/velanakuy/VELANAKUY_05.png'
    },
    VELANAKUY_06 : {
        'key' : 'VELANAKUY_06',
        'path' : './funcaracAssets/content/festivities/velanakuy/VELANAKUY_06.png'
    }
}

export const kapakRaymiResources = {
    COVER : {
        'key' : 'KAPAK_RAYMI_P',
        'path' : './funcaracAssets/content/festivities/kapak raymi/KAPAK_RAYMI_P.png'
    },
    KAPAK_RAYMI_01 : {
        'key' : 'KAPAK_RAYMI_01',
        'path' : './funcaracAssets/content/festivities/kapak raymi/KAPAK_RAYMI_01.png'
    },
    KAPAK_RAYMI_02 : {
        'key' : 'KAPAK_RAYMI_02',
        'path' : './funcaracAssets/content/festivities/kapak raymi/KAPAK_RAYMI_02.png'
    },
    KAPAK_RAYMI_03 : {
        'key' : 'KAPAK_RAYMI_03',
        'path' : './funcaracAssets/content/festivities/kapak raymi/KAPAK_RAYMI_03.png'
    },
    KAPAK_RAYMI_04 : {
        'key' : 'KAPAK_RAYMI_04',
        'path' : './funcaracAssets/content/festivities/kapak raymi/KAPAK_RAYMI_04.png'
    },
    KAPAK_RAYMI_05 : {
        'key' : 'KAPAK_RAYMI_05',
        'path' : './funcaracAssets/content/festivities/kapak raymi/KAPAK_RAYMI_05.png'
    },
    KAPAK_RAYMI_06 : {
        'key' : 'KAPAK_RAYMI_06',
        'path' : './funcaracAssets/content/festivities/kapak raymi/KAPAK_RAYMI_06.png'
    },
    KAPAK_RAYMI_07 : {
        'key' : 'KAPAK_RAYMI_07',
        'path' : './funcaracAssets/content/festivities/kapak raymi/KAPAK_RAYMI_07.png'
    },
    KAPAK_RAYMI_08 : {
        'key' : 'KAPAK_RAYMI_08',
        'path' : './funcaracAssets/content/festivities/kapak raymi/KAPAK_RAYMI_08.png'
    }
}

export const yumbadaResources = {
    COVER : {
        'key' : 'YUMBADA_P',
        'path' : './funcaracAssets/content/festivities/yumbada/YUMBADA_P.png'
    },
    YUMBADA_01 : {
        'key' : 'YUMBADA_01',
        'path' : './funcaracAssets/content/festivities/yumbada/YUMBADA_01.png'
    },
    YUMBADA_02 : {
        'key' : 'YUMBADA_02',
        'path' : './funcaracAssets/content/festivities/yumbada/YUMBADA_02.png'
    },  
    YUMBADA_03 : {
        'key' : 'YUMBADA_03',
        'path' : './funcaracAssets/content/festivities/yumbada/YUMBADA_03.png'
    },
    YUMBADA_04 : {
        'key' : 'YUMBADA_04',
        'path' : './funcaracAssets/content/festivities/yumbada/YUMBADA_04.png'
    },
    YUMBADA_05 : {
        'key' : 'YUMBADA_05',
        'path' : './funcaracAssets/content/festivities/yumbada/YUMBADA_05.png'
    },
    YUMBADA_06 : {
        'key' : 'YUMBADA_06',
        'path' : './funcaracAssets/content/festivities/yumbada/YUMBADA_06.png'
    },
    YUMBADA_07 : {
        'key' : 'YUMBADA_07',
        'path' : './funcaracAssets/content/festivities/yumbada/YUMBADA_07.png'
    },
    YUMBADA_08 : {
        'key' : 'YUMBADA_08',
        'path' : './funcaracAssets/content/festivities/yumbada/YUMBADA_08.png'
    },
    YUMBADA_09 : {
        'key' : 'YUMBADA_09',
        'path' : './funcaracAssets/content/festivities/yumbada/YUMBADA_09.png'
    },
    YUMBADA_10 : {
        'key' : 'YUMBADA_10',
        'path' : './funcaracAssets/content/festivities/yumbada/YUMBADA_10.png'
    },
}

export const llulluMuruRaymiResources = {
    COVER : {
        'key' : 'LLULLU_MURU_RAYMI_P',
        'path' : './funcaracAssets/content/festivities/llullu muru raymi/LLULLU_MURU_RAYMI_P.png'
    },
    LLULLU_MURU_RAYMI_01 : {
        'key' : 'LLULLU_MURU_RAYMI_01',
        'path' : './funcaracAssets/content/festivities/llullu muru raymi/LLULLU_MURU_RAYMI_01.png'
    },
    LLULLU_MURU_RAYMI_02 : {
        'key' : 'LLULLU_MURU_RAYMI_02',
        'path' : './funcaracAssets/content/festivities/llullu muru raymi/LLULLU_MURU_RAYMI_02.png'
    },  
    LLULLU_MURU_RAYMI_03 : {
        'key' : 'LLULLU_MURU_RAYMI_03',
        'path' : './funcaracAssets/content/festivities/llullu muru raymi/LLULLU_MURU_RAYMI_03.png'
    },
    LLULLU_MURU_RAYMI_04 : {
        'key' : 'LLULLU_MURU_RAYMI_04',
        'path' : './funcaracAssets/content/festivities/llullu muru raymi/LLULLU_MURU_RAYMI_04.png'
    },
    LLULLU_MURU_RAYMI_05 : {
        'key' : 'LLULLU_MURU_RAYMI_05',
        'path' : './funcaracAssets/content/festivities/llullu muru raymi/LLULLU_MURU_RAYMI_05.png'
    },
    LLULLU_MURU_RAYMI_06 : {
        'key' : 'LLULLU_MURU_RAYMI_06',
        'path' : './funcaracAssets/content/festivities/llullu muru raymi/LLULLU_MURU_RAYMI_06.png'
    },
    LLULLU_MURU_RAYMI_07 : {
        'key' : 'LLULLU_MURU_RAYMI_07',
        'path' : './funcaracAssets/content/festivities/llullu muru raymi/LLULLU_MURU_RAYMI_07.png'
    },
}