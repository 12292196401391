import Page from "../../../baseClasses/page";
import { coverPageResources, mainUIResources } from "../../../../utils/assetsPath";
import Label from "../../../baseClasses/Label";
import { gridAlignGroupElements } from "../../../../utils/functions";

export default class IntroPage extends Page{
    constructor(scene,x,y,knowledgeType,data){
        super(scene,x,y,`SABER TRADICIONAL\n${knowledgeType}`);
        this.buildPage(scene);
        this.setTitle(data.title);
        this.setPhoto(data.photo);
        this.setMonthName(data.monthName);
        this.addPageContent(scene);
    }

    setTitle(title){
        this.title = title;
    }

    setPhoto(photo){
        this.photo =  photo;
    }

    setMonthName(month){ this.monthName = month }
    getMonthName(){ return this.monthName }

    setIntroPicture(scene){
        let coverPhoto = scene.add.image(0,-40,this.photo);
        this.add(coverPhoto);
    }

    setKnowledgeTitle(scene){
        this.titleLabel = new Label(scene,0,180,coverPageResources.PAGE_TITLE_LABEL.key,this.title);
        this.titleLabel.body.setScale(0.9,0.9);
        this.titleLabel.text.setLineSpacing(-15);
        this.titleLabel.setTextStyle({fontFamily:'Franklin Gothic Heavy', fontSize:'48px',color:'#EA3F39',stroke:'#320806',strokeThickness:5});
        this.add(this.titleLabel);
    }

    setMonthCard(scene){
        let firstText = scene.add.text(0,0,'Saber celebrado en el mes: ',{fontFamily : 'Franklin Gothic Heavy', fontSize : '24px', color : '#000'});
        let monthName = scene.add.text(0,0,`${this.getMonthName()}`,{fontFamily : 'Franklin Gothic Heavy', fontSize : '40px', color : '#fff', strokeThickness : 5, stroke: '#000'});
        let group  = scene.add.group([firstText,monthName]);
        let gridAlign = {width : 2, height : 1 , cellWidth : 235 , position : 6, x : -75}
        gridAlignGroupElements(scene,group,gridAlign);
        let container = scene.add.container(0,280,group.getChildren());
        this.add(container);
    }

    addPageContent(scene){
        this.setIntroPicture(scene);
        this.setKnowledgeTitle(scene);
        this.setMonthCard(scene);
    }
}