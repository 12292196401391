import React,{Component} from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import IntroPage from '../pages/introPage';
import LoginPage from '../pages/loginPage';
import SignInPage from '../pages/signinPage';
import GamePage from '../pages/gamePage';

export default class AppRouter extends Component{
    render(){
        return this.defineRoutes();
    }

    defineRoutes(){
        return(
        <BrowserRouter>
        <div>
            <Switch>
                <Route exact path="/" render={() => (
                    <Redirect to="/intro"/>
                )}/>
                <Route path="/intro" component = {IntroPage}/>
                <Route path="/login" component = {LoginPage}/>
                <Route path="/signin" component = {SignInPage}/>
                <Route path="/game" component = {GamePage}/>
            </Switch>
        </div>
        </BrowserRouter>);
    }
}