import Phaser from 'phaser';
import { gameResources, sfxResources, animResources, audioResources } from '../utils/assetsPath';
import { scenesKey } from '../utils/gameConstants';
import MainUIScene from './MainUIScene';

export default class BootLoader extends Phaser.Scene{

    constructor(){
        super({key:scenesKey.BOOTLOADER_SCENE});

    }

    preload(){
        this.width = this.game.config.width;
        this.height = this.game.config.height;
        this.progressBar = this.add.graphics();
        this.progressBox = this.add.graphics();
        this.createProgressBox();
        this.setLoadingText();
        this.setPercentText();
        this.setAssetText();
        
        gameResources.forEach((el)=>{this.load.image(el.key,el.path)});
        animResources.forEach((el) => {this.load.spritesheet(el.key,el.path,el.config)});
        audioResources.forEach((el) => {this.load.audio(el.key,el.path)});
        sfxResources.forEach((resource)=>{this.load.audio(resource.key,resource.url)})

        this.fillProgressBar();
        this.showLoadedAssets();
        this.destroyElements();
    }
    create(){
        // this.scene.start(scenesKey.MAINUI_SCENE);
    }

    createProgressBox(){
        this.xPosition = 0;
        this.yPosition = this.height/2*0.9;
        this.progressBox.fillStyle(0x222222,0.8);
        this.progressBox.fillRect(this.xPosition,this.yPosition,this.width,50);
    }

    setLoadingText(){
        this.loadingText = this.make.text({
            x: this.width/2,
            y: this.height / 2 - 50,
            text:'loading...',
            style:{
                font:'20px monospace',
                fill:'#ffffff'
            } 
        });
        this.loadingText.setOrigin(0.5,0.5);
    }

    setPercentText(){
        this.percentText = this.make.text({
            x: this.width/2,
            y: this.height / 2 - 5,
            text:'0%',
            style:{
                font:'18px monospace',
                fill:'#ffffff'
            } 
        });
        this.percentText.setOrigin(0.5,0.5);
    }

    setAssetText(){
        this.assetText = this.make.text({
            x: this.width/2,
            y: this.height / 2 + 50,
            text:'',
            style:{
                font:'18px monospace',
                fill:'#ffffff'
            } 
        });
        this.assetText.setOrigin(0.5,0.5);
    }

    fillProgressBar(){
        this.load.on('progress',(value)=>{
            this.progressBar.clear();
            this.progressBar.fillStyle(0xffffff,1);
            this.progressBar.fillRect(this.xPosition+10,this.yPosition+10,value*this.width,30);
            this.percentText.setText(parseInt(value*100)+'%');
        });
    }

    showLoadedAssets(){
        this.load.on('fileprogress',(file)=>{
            this.assetText.setText('Loading asset: '+file.key);
        });
    }
    
    destroyElements(){
        this.load.on('complete',()=>{
            this.progressBar.destroy();
            this.progressBox.destroy();
            this.loadingText.destroy();
            this.percentText.destroy();
            this.assetText.destroy();

            this.scene.add(scenesKey.MAINUI_SCENE,MainUIScene);
            this.scene.start(scenesKey.MAINUI_SCENE);
        });
    }

    // loadLocalStorage(){
    //     if(localStorage.getItem('talesList')===null){
    //         localStorage.setItem('talesList',JSON.stringify(taleRecordsList));
    //     }       
    // }

}