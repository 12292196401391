import { generateToolRecords, getPlayerToolRecords } from "../services/gameServices/toolsService"
import { CREATE_TOOL_RECORDS_SUCCESS, LOAD_TOOL_RECORDS_SUCCESS, LOAD_TOOL_LIST } from "./actionTypes"


export function createNewToolRecords(idPlayer){
    return (dispatch)=>{
        return generateToolRecords(idPlayer).then(response =>{
            dispatch(createToolRecordsSuccess())
        }).catch(error => {throw error});
    }
}

export function loadPlayerToolRecords(idPlayer){
    return dispatch => {
        return getPlayerToolRecords(idPlayer).then(response => {
            dispatch(loadToolList(response.data));
            dispatch(loadToolRecordsSuccess());
        }).catch( error => { throw error });
    }
}


function createToolRecordsSuccess(){
    return {type : CREATE_TOOL_RECORDS_SUCCESS}
}

function loadToolList( recordList ){
    return { type : LOAD_TOOL_LIST, recordList }
}

function loadToolRecordsSuccess(){
    return { type : LOAD_TOOL_RECORDS_SUCCESS }
}