import { updatePlayerTaleRecords } from "../../../../services/gameServices/talesService";
import { updatePuzzlePieceEarnedStatus, updatePuzzlePiecePlacedStatus } from "../../../../services/gameServices/piecesService";
import { updatePlayerQuizRecord } from "../../../../services/gameServices/quizService";
import { updatePlayerSeedEarnedStatus, updatePlayerSeedUsedStatus } from "../../../../services/gameServices/seedService";
import { updatePlayerToolRecord } from "../../../../services/gameServices/toolsService";

//change tale record status
export function changeTaleRecordStatus(idTale, status, talesList){
    if( localStorage.hasOwnProperty('loggedPlayer') && 
        localStorage.hasOwnProperty('taleRecords') ){
        let loggedPlayer = JSON.parse(localStorage.getItem('loggedPlayer')),
        params = {playerid : loggedPlayer.id, status};
    
        updatePlayerTaleRecords(idTale,params).then(response => {
            let talesRecords = JSON.parse(localStorage.getItem('taleRecords')),
            oldRecord = talesRecords.find((tale)=>{return tale.tales_id === idTale}),
            newRecord = response.data[0], index = talesRecords.indexOf(oldRecord);
            talesRecords[index] = newRecord;
            localStorage.setItem('taleRecords',JSON.stringify(talesRecords));
        });
    }
    let choosenTale = talesList.find((tale)=>{return tale.id === idTale});
    choosenTale.changeStatus(status);
}

//change quiz record 
export function changeQuizRecord(results){
    if ( localStorage.hasOwnProperty('loggedPlayer') && 
         localStorage.hasOwnProperty('quizRecords') ){
            let loggedPlayer = JSON.parse(localStorage.getItem('loggedPlayer')),
            playerId = loggedPlayer.id, quizId = results.quizId,
            quizRecords = JSON.parse(localStorage.getItem('quizRecords')),
            oldRecord = quizRecords.find((record)=>{return record.quiz_id === quizId}),
            index = quizRecords.indexOf(oldRecord),
            params = {
                playerid : playerId,
                hits : results.correctAnswers,
                fails : results.wrongAnswers,
                score : results.score,
            };
        
            updatePlayerQuizRecord(quizId, params).then((response)=>{
                let newRecord = response.data[0];
                quizRecords[index] = newRecord;
                localStorage.setItem('quizRecords',JSON.stringify(quizRecords));
            })           
         }
}


//change puzzle piece record earned status
export function changePieceRecordEarnedStatus(puzzlePieceId){
    if( localStorage.hasOwnProperty('loggedPlayer') && 
        localStorage.hasOwnProperty('pieceRecords')){
        let pieceRecords = JSON.parse(localStorage.getItem('pieceRecords')),
        oldRecord = pieceRecords.find((record)=>{return record.puzzle_piece_id === puzzlePieceId}),
        index = pieceRecords.indexOf(oldRecord),
        loggedPlayer = JSON.parse(localStorage.getItem('loggedPlayer')),
        params = {playerid : loggedPlayer.id, earned : 1};
    
        updatePuzzlePieceEarnedStatus(puzzlePieceId,params).then(response => {
            let newRecord = response.data[0];
            pieceRecords[index] = newRecord;
            localStorage.setItem('pieceRecords',JSON.stringify(pieceRecords));
        })
    }
}

//change puzzle piece record placed status
export function changePieceRecordPlacedStatus(puzzlePieceId){
    if( localStorage.hasOwnProperty('loggedPlayer') && 
        localStorage.hasOwnProperty('pieceRecords')){
        let pieceRecords = JSON.parse(localStorage.getItem('pieceRecords')),
        oldRecord = pieceRecords.find((record)=>{return record.puzzle_piece_id === puzzlePieceId}),
        index = pieceRecords.indexOf(oldRecord),
        loggedPlayer = JSON.parse(localStorage.getItem('loggedPlayer')),
        params = {playerid : loggedPlayer.id, placed : 1};
    
        updatePuzzlePiecePlacedStatus(puzzlePieceId,params).then(response => {
            let newRecord = response.data[0];
            pieceRecords[index] = newRecord;
            localStorage.setItem('pieceRecords',JSON.stringify(pieceRecords));
        })
    } 
}


//change seed record earned status
export function changeSeedRecordEarnedStatus(seedId, status){
    if( localStorage.hasOwnProperty('loggedPlayer') && 
        localStorage.hasOwnProperty('seedRecords')){
        let seedRecords = JSON.parse(localStorage.getItem('seedRecords')),
        oldRecord = seedRecords.find((record)=>{return record.seed_id === seedId}),
        index = seedRecords.indexOf(oldRecord),
        loggedPlayer = JSON.parse(localStorage.getItem('loggedPlayer')),
        params = {playerid : loggedPlayer.id, earned : status};

        if(oldRecord.earned === 0){
            updatePlayerSeedEarnedStatus(seedId,params).then(response => {
                let newRecord = response.data[0];
                seedRecords[index]=newRecord;
                localStorage.setItem('seedRecords',JSON.stringify(seedRecords));
            })
        }
    }
}

//change seed record used status
export function changeSeedRecordUsedStatus(seedId, status){
    if( localStorage.hasOwnProperty('loggedPlayer') && 
        localStorage.hasOwnProperty('seedRecords') ){
        let seedRecords = JSON.parse(localStorage.getItem('seedRecords')),
        oldRecord = seedRecords.find((record)=>{return record.seed_id === seedId}),
        index = seedRecords.indexOf(oldRecord),
        loggedPlayer = JSON.parse(localStorage.getItem('loggedPlayer')),
        params = {playerid : loggedPlayer.id, used : status};

        if(oldRecord.earned === 0){
            updatePlayerSeedUsedStatus(seedId,params).then(response => {
            let newRecord = response.data[0];
            seedRecords[index]=newRecord;
            localStorage.setItem('seedRecords',JSON.stringify(seedRecords));
            })
        }
    }
}

//update tool record
export function changeToolRecord(toolId, quantity){
    if( localStorage.hasOwnProperty('loggedPlayer') && 
        localStorage.hasOwnProperty('toolRecords')){
        let toolRecords = JSON.parse(localStorage.getItem('toolRecords')),
        oldRecord = toolRecords.find((record)=>{return record.tool_id === toolId}),
        oldQuantity = oldRecord.quantity,
        index = toolRecords.indexOf(oldRecord),
        loggedPlayer = JSON.parse(localStorage.getItem('loggedPlayer')),
        params = {playerid : loggedPlayer.id, quantity: (oldQuantity + quantity)};

        updatePlayerToolRecord(toolId, params).then(response => {
            let newRecord = response.data[0];
            toolRecords[index] = newRecord;
            localStorage.setItem('toolRecords',JSON.stringify(toolRecords));
        })
    }
}

