import React,{Component} from 'react';
import {connect} from 'react-redux';
import { Card } from 'react-bootstrap';

import SignInForm  from '../forms/signInForm';


const CARD_STYLE = { 
    width : '18rem', position: 'absolute', 
    left: '50%', top: '50%', transform: 'translate(-50%, -50%)'
};

class SignInPage extends Component{

    render(){
        return this.addCard();
    }

    addSignInForm(){
      return <SignInForm/>
    }

    addCard(){
        return(
        <Card style = {CARD_STYLE}>
            <Card.Body>
                <Card.Title>¡Sign In Page!</Card.Title>
            </Card.Body>
            {this.addSignInForm()}
        </Card>);
    }

    componentWillReceiveProps(){
        
    }
}

function mapStateToProps (state){
    return {}
}

function mapDispatchToProps(){
    return {};
}

export default connect(mapStateToProps,mapDispatchToProps)(SignInPage);