import Phaser from 'phaser';
import { gridAlignGroupElements, addChildrenToContainer } from "../../../../../utils/functions";
import { caracResources } from '../../../../../utils/assetsPath';

export default class QuizPiecesBox extends Phaser.GameObjects.Container{
    constructor(scene,x,y,type){
        super(scene,x,y);

        this.scene = null;
        this.label = null;
        this.box = null;
        this.background = null;
        this.puzzlePiece = null;

        this.quiz = null;
        this.isCompleted = false;
        this.labelText = null;


        this.setValues(scene,type);
        this.buildQuizBox();
    }

    setValues(scene,type){
        this.setScene(scene);
        this.setLabelType(type);
        this.setBox();
        this.setLabel();
    }

    setScene(scene){
        this.scene = scene;
    }

    setLabelText(text){
        this.labelText = text;
    }

    setPuzzlePiece(piece){
        this.puzzlePiece = piece;
    }

    setBackground(background){
        this.background = background;
    }

    setBox(){
        this.box = this.createBoxContainer();
    }

    setLabel(){
        this.label = this.createLabelContainer();
    }

    completeBox(){
        this.isCompleted = true;
    }

    getStatusValue(){
        return this.isCompleted;
    }

    setLabelType(type){
        switch(type){
            case 'red':
                this.setLabelText('FESTIVIDADES');
                this.setBackground(this.scene.add.image(0,0,caracResources.PIECE_RED.key));
            break;
            case 'yellow':
                this.setLabelText('COMIDAS');
                this.setBackground(this.scene.add.image(0,0,caracResources.PIECE_YELLOW.key));
            break;
            case 'green':
                this.setLabelText('SEÑAS-SECRETOS');
                this.setBackground(this.scene.add.image(0,0,caracResources.PIECE_GREEN.key));
            break;
            case 'blue':
                this.setLabelText('DIALECTOS');
                this.setBackground(this.scene.add.image(0,0,caracResources.PIECE_BLUE.key));
            break;
            default:
            break;
        }
    }

    createBoxContainer(){
        let container = this.scene.add.container(0,0),
            width = 200,
            height = 120,
            radius = 10,
            graphics = this.scene.add.graphics();
        container.setSize(width,height);
        graphics.fillStyle(0xE8AF14,1).lineStyle(2,0xE8AF14,1)
        .fillRoundedRect(-width/2,-height/2,width,height,radius)
        .strokeRoundedRect(-width/2,-height/2,width,height,radius);

        this.background.setAlpha(0.5);

        container.add([graphics,this.background]);
        return container;    
    }

    createLabelContainer(){
        let container = this.scene.add.container(0,0),
            width = 350,
            height = 120,
            radius = 10,
            graphics = this.scene.add.graphics(),
            style = {
                fontFamily : 'Helvetica',
                fontSize : '30px',
                align : 'center',
                color : '#F6FA0E',
                stroke : '#F6FA0E',
                strokeThickness : 1
            },
            text = this.scene.add.text(0,0,`SABER TRADICIONAL\n${this.labelText}`,style);
            text.setOrigin(0.5);
        container.setSize(width,height);
        graphics.fillStyle(0x3C90CB,1).lineStyle(2,0x022E4D,1)
        .fillRoundedRect(-width/2,-height/2,width,height,radius)
        .strokeRoundedRect(-width/2,-height/2,width,height,radius);

        container.add([graphics,text]);
        return container;    
    }

    buildQuizBox(){
        let group = this.scene.add.group([this.label,this.box]),
            gridConfig = {
                width : 2,
                height : 1,
                cellWidth : 300,
                position : 6
            };
        gridAlignGroupElements(this.scene,group,gridConfig);
        addChildrenToContainer(this,group.getChildren());
        this.scene.add.existing(this);
        this.setSize(this.box.width+this.label.width,this.box.height);
    }

    addPiece(piece){
        this.setPuzzlePiece(piece);
        this.box.remove(this.background).add(this.puzzlePiece);
        this.completeBox();
    }

    highlightBackground(){
        this.background.setAlpha(1);
    }
}