import { secretsResourcesList } from "../../resources_path/secrets/secretsResources"
import { secretsQuizList } from "../../quizzes/secrets/secretQuizzes"

export const secretsList = {
    SECRET_PAPA : {
        title : 'Siembra de papas',
        community : 'Guamote',
        state : 'Chimborazo',
        content : {
            photo : secretsResourcesList.PHOTO_SECRET_PAPA.key,
            description : 'El secreto para ver si las papas están listas para la siembra(tarpuy) consiste en:\nMeter la mano dentro del hueco tapado con paja donde se almacenan las papas (putza), si las papitas están sudando, están listas para la siembra.'
        },
        dialects : [
            {kichwaWord : 'tarpuy', spanishWord : 'siembra'},
            {kichwaWord : 'putza', spanishWord : 'papas'},
        ],
        question : secretsQuizList.QUESTION_SECRET_PAPA
    },
    SECRET_MAIZ : {
        title : 'Siembra del maíz',
        community : 'Columbe',
        state : 'Chimborazo',
        content : {
            photo : secretsResourcesList.PHOTO_SECRET_MAIZ.key,
            description : 'El secreto para sembrar el maíz(killu sara) consiste en:\nColocar tres granitos(murus) de maíz simbolizando al padre, al hijo y al espiritu santo.'
        },
        dialects : [
            {kichwaWord : 'killu sara', spanishWord : 'maíz'},
            {kichwaWord : 'muru(s)', spanishWord : 'grano(s)'},
        ],
        question : secretsQuizList.QUESTION_SECRET_MAIZ
    },
    SECRET_CHACARERO : {
        title : 'Charla con la chakra',
        community : 'Columbe',
        state : 'Chimborazo',
        content : {
            photo : secretsResourcesList.PHOTO_SECRET_CHACARERO.key,
            description : 'Al momento de sembrar la persona conversa con la chakra y le conversa con la tierra(allpamamita) y menciona "chusku wawakunami charini kay yarikikunaman karawanki" con la final de que al final sea buena la producción y no tener escasez de alimentos(mikuna).'
        },
        dialects : [
            {kichwaWord : 'allpamama', spanishWord : 'tierra'},
            {kichwaWord : 'mikuna', spanishWord : 'alimentos/comida'},
        ],
        question : secretsQuizList.QUESTION_SECRET_CHACARERO
    },
   SECRET_LADRONES : {
        title : 'Secreto sobre la familia',
        community : null,
        state : null,
        content : {
            photo : secretsResourcesList.PHOTO_SECRET_LADRONES.key,
            description : 'Enterrar los huesos(tullus) de los difuntos(wañushka) alrededor de la casa, en las cuatro esquinas de la casa para que no entren los ladrones o dueños de lo ajeno. En algunos casos se le coloca una calavera afuera de la casa para que proteja el lugar.'
        },
        dialects : [
            {kichwaWord : 'tullus', spanishWord : 'huesos'},
            {kichwaWord : 'wañushka', spanishWord : 'difunto/cadaver/muerto'},
        ],
        question : secretsQuizList.QUESTION_SECRET_LADRONES
    },
    SECRET_DEUDA : {
        title : 'Secreto sobre las deudas',
        community : null,
        state : null,
        content : {
            photo : secretsResourcesList.PHOTO_SECRET_DEUDA.key,
            description : 'Si quiere que alguien tenga buena suerte(racha) se debe pagar una deuda(manu) durante la mañana(tutamanta) porque si lo hace en la noche(tuta), la persona tendrá mala suerte con el dinero(kullki).'
        },
        dialects : [
            {kichwaWord : 'racha', spanishWord : 'buena suerte'},
            {kichwaWord : 'manu', spanishWord : 'deuda'},
            {kichwaWord : 'tuta', spanishWord : 'noche'},
            {kichwaWord : 'tutamanta', spanishWord : 'madrugada'},
        ],
        question : secretsQuizList.QUESTION_SECRET_DEUDA
    },
    SECRET_PLATITA : {
        title : 'Secreto sobre el dinero',
        community : null,
        state : null,
        content : {
            photo : secretsResourcesList.PHOTO_SECRET_PLATITA.key,
            description : 'Para que no le falte la platita o dinerito(kullki) a una persona hay que tener los centavitos junto a las semillitas(muyu).'
        },
        dialects : [
            {kichwaWord : 'kullki', spanishWord : 'dinero'},
            {kichwaWord : 'muyu(s)', spanishWord : 'semilla(s)'},
        ],
        question : secretsQuizList.QUESTION_SECRET_PLATITA
    },
    SECRET_PLANTA_MEDICINAL : {
        title : 'Secreto para las cosechas',
        community : 'Agato',
        state : 'Imbabura',
        content : {
            photo : secretsResourcesList.PHOTO_SECRET_PLANTA_MEDICINAL.key,
            description : 'Cuando se va a ir a coger una planta medicinal(chukllu) en la noche(tuta) se tiene que decir "juanchumikani" para que la plantita no se recienta y no se muera.'
        },
        dialects : [
            {kichwaWord : 'chukllu', spanishWord : 'planta medicinal de sabor ácido'},
            {kichwaWord : 'tuta', spanishWord : 'noche'},
        ],
        question : secretsQuizList.QUESTION_SECRET_PLANTA_MEDICINAL
    },
    SECRET_RECUPERAR_SALUD : {
        title : 'Salvado por los ancestros',
        community : 'Salache',
        state : 'Cotopaxi',
        content : {
            photo : secretsResourcesList.PHOTO_SECRET_RECUPERAR_SALUD.key,
            description : 'Para restablecer la salud(allikana) se debe ir a la chacra (chakra) cerrando los ojos, pedir al mundo sagrado(apus) que le ayude a escoger bien las plantitas.'
        },
        dialects : [
            {kichwaWord : 'allikana', spanishWord : 'salud/bienestar'},
            {kichwaWord : 'chakra', spanishWord : 'chacra'},
            {kichwaWord : 'apus', spanishWord : 'deidades/mundo'},
        ],
        question : secretsQuizList.QUESTION_SECRET_RECUPERAR_SALUD
    },
    SECRET_ESPIRITU : {
        title : 'Un té reparador',
        community : 'Salache',
        state : 'Cotopaxi',
        content : {
            photo : secretsResourcesList.PHOTO_SECRET_ESPIRITU.key,
            description : 'Para reestablecer el espíritu(aya) de la persona hay que tomar tecito de corazón de la piedra(rumishungu) durante tres días en luna llena.'
        },
        dialects : [
            {kichwaWord : 'aya', spanishWord : 'espíritu'},
            {kichwaWord : 'rumishungu', spanishWord : 'corazón de piedra'},
        ],
        question : secretsQuizList.QUESTION_SECRET_ESPIRITU
    },
    SECRET_SAL : {
        title : 'Una suerte salada',
        community : 'Columbe',
        state : 'Chimborazo',
        content : {
            photo : secretsResourcesList.PHOTO_SECRET_SAL.key,
            description : 'No se debe coger la sal(kachi) con la mano(maki) porque la persona tendrá mala suerte cuando vaya a realizar una actividad.'
        },
        dialects : [
            {kichwaWord : 'kachi', spanishWord : 'sal'},
            {kichwaWord : 'maki', spanishWord : 'mano'},
        ],
        question :  secretsQuizList.QUESTION_SECRET_SAL
    },
    SECRET_BEBE_ARMONIA : {
        title : 'Buenos padrinos',
        community : 'Zumbahua',
        state : 'Cotopaxi',
        content : {
            photo : secretsResourcesList.PHOTO_SECRET_BEBE_ARMONIA.key,
            description : 'Poner una pisquita de sal(kachi) y una pisquita de dulce(mishki) en un poquitito de agua y los futuros padrinos(achikyayas) le untan en la frente, en la chona y en las orejitas del bebe para que el bebé crezca en armonía.'
        },
        dialects : [
            {kichwaWord : 'kachi', spanishWord : 'sal'},
            {kichwaWord : 'mishki', spanishWord : 'rayadura de dulce'},
            {kichwaWord : 'achikyaya(s)', spanishWord : 'padrino(s)'},
        ],
        question : secretsQuizList.QUESTION_SECRET_BEBE_ARMONIA
    },
    SECRET_BEBE_ABUNDANCIA : {
        title : 'Secreto para la abundancia',
        community : 'Columbe',
        state : 'Chimborazo',
        content : {
            photo : secretsResourcesList.PHOTO_SECRET_BEBE_ABUNDANCIA.key,
            description : 'Para que tenga abundancia en su vida al bebé(llullu wawa) le hacen cargar una bolsita de machika, granos (kukayu) alrededor del cuello.'
        },
        dialects : [
            {kichwaWord : 'llullu wawa', spanishWord : 'bebé'},
            {kichwaWord : 'kukayu', spanishWord : 'machika/granos'},
        ],
        question : secretsQuizList.QUESTION_SECRET_BEBE_ABUNDANCIA
    },
    SECRET_BEBE_FUERTE : {
        title : 'Envoltorio de Maitos',
        community : null,
        state : null,
        content : {
            photo : secretsResourcesList.PHOTO_SECRET_BEBE_FUERTE.key,
            description : 'Para que el bebé sea fuerte se le hace envoltura (maitu) con telas en forma triangular, le envuelven todo el cuerpo, desde el cuello(kunka) hasta la punta de los pies para que sus huesitos(tullus) vayan formándose bien.'
        },
        dialects : [
            {kichwaWord : 'maitu', spanishWord : 'envoltura'},
            {kichwaWord : 'kunka', spanishWord : 'cuello'},
            {kichwaWord : 'tullus', spanishWord : 'huesos'},
        ],
        question : secretsQuizList.QUESTION_SECRET_BEBE_FUERTE
    },
   SECRET_SALIVA : {
        title : 'Saliva de la abuela',
        community : 'Salache',
        state : 'Cotopaxi',
        content : {
            photo : secretsResourcesList.PHOTO_SECRET_SALIVA.key,
            description : 'A un bebé que nació con deformidad en la planta del pie se le suele dar masajes con la saliva de la abuela y haciendo envoltura(maitu) esa parte afectada para enderezarle la deformidad.'
        },
        dialects : [
            {kichwaWord : 'maitu', spanishWord : 'envoltura'},
        ],
        question : secretsQuizList.QUESTION_SECRET_SALIVA
    },
    SECRET_TORO : {
        title : 'Fuerte como toro',
        community : null,
        state : null,
        content : {
            photo : secretsResourcesList.PHOTO_SECRET_TORO.key,
            description : 'Si una persona se toma la sangre de toro, se cree que le otorgará fortaleza a la chakra y al humano y puede repeler la vaguería y evitar contraer el malaire (wayrana).'
        },
        dialects : [
            {kichwaWord : 'wayrana', spanishWord : 'contraer el malaire'},
        ],
        question : secretsQuizList.QUESTION_SECRET_TORO
    },
    SECRET_ASUSTADA : {
        title : 'Trago medicinal',
        community : null,
        state : null,
        content : {
            photo : secretsResourcesList.PHOTO_SECRET_ASUSTADA.key,
            description : 'Para reestablecer el anima de la persona asustada se debe tener preparada un trago(waspiti) hecho con plantas medicinales de limpia y/o plantas divinas como la cebolla blanca y frotarlo a la persona que esté agonizando.'
        },
        dialects : [
            {kichwaWord : 'waspiti', spanishWord : 'trago'},
        ],
        question : secretsQuizList.QUESTION_SECRET_ASUSTADA
    },
    SECRET_PELEA_PERROS : {
        title : 'Pelea de perros',
        community : null,
        state : null,
        content : {
            photo : secretsResourcesList.PHOTO_SECRET_PELEA_PERROS.key,
            description : 'Si en una fiesta hay una pelea de perros(allkus), se debe botarles un balde de agua lluvia(yaku chirapa) para que no haya conflicto o pelea en la familia(ayllu).'
        },
        dialects : [
            {kichwaWord : 'allku(s)', spanishWord : 'perro(s)'},
            {kichwaWord : 'ayllu', spanishWord : 'familia'},
            {kichwaWord : 'yaku chirapa', spanishWord : 'agua lluvia'},
        ],
        question : secretsQuizList.QUESTION_SECRET_PELEA_PERROS
    },
    SECRET_MAL_OJO : {
        title : 'Evitar el mal de ojo',
        community : null,
        state : null,
        content : {
            photo : secretsResourcesList.PHOTO_SECRET_MAL_OJO.key,
            description : 'Para que a una persona no le dé mal de ojo hay que cargar en la mano derecha semillas (mullus) rojos.'
        },
        dialects : [
            {kichwaWord : 'mullu(s)', spanishWord : 'semilla(s)'},
        ],
        question : secretsQuizList.QUESTION_SECRET_MAL_OJO
    },
    SECRET_HOJA_CULANTRO : {
        title : 'Hojitas de culantro',
        community : 'Columbe',
        state : 'Chimborazo',
        content : {
            photo : secretsResourcesList.PHOTO_SECRET_HOJA_CULANTRO.key,
            description : 'Cuando un niño(a)(wawa) está llorando incontroladamente y no puede dormir se le pone una hojita de culantro debajo de la almohada para que duerma plácidamente.'
        },
        dialects : [
            {kichwaWord : 'wawa', spanishWord : 'niño(a)'},
        ],
        question : secretsQuizList.QUESTION_SECRET_HOJA_CULANTRO
    },
    SECRET_BEBE_HABLA : {
        title : 'Secreto de la chachaluisa',
        community : null,
        state : null,
        content : {
            photo : secretsResourcesList.PHOTO_SECRET_BEBE_HABLA.key,
            description : 'Cuando un bebé no quiere hablar, se coge una flor silvestre (chachaluisa) y se le toca en la boquita del bebe diciendo "chachalu chachalu chachalu" hasta que el bebé empiece a balbucear y comience a hablar.'
        },
        dialects : [
            {kichwaWord : 'chachaluisa', spanishWord : 'variedad de flor silvestre'},
        ],
        question : secretsQuizList.QUESTION_SECRET_BEBE_HABLA
    },
    SECRET_PEPINO : {
        title : 'Chimbalu milagroso',
        community : null,
        state : null,
        content : {
            photo : secretsResourcesList.PHOTO_SECRET_PEPINO.key,
            description : 'Para que un bebé no sea muy enfermizo se le cuelga un collar de una miniatura de pepino silvestre dulce (chimbalu).'
        },
        dialects : [
            {kichwaWord : 'chimbalu', spanishWord : 'miniatura de pepino silvestre'},
        ],
        question : secretsQuizList.QUESTION_SECRET_PEPINO
    },
    SECRET_ARENA : {
        title : 'Corrigiendo huesitos',
        community : null,
        state : null,
        content : {
            photo : secretsResourcesList.PHOTO_SECRET_ARENA.key,
            description : 'Para que el bebé no tenga problemas con sus huesitos(tullus) hay que hacerle revolcar en la arena del rio caliente(hatun yaku). Se lo tiene que hacer durante la mañana.'
        },
        dialects : [
            {kichwaWord : 'tullu(s)', spanishWord : 'hueso(s)'},
            {kichwaWord : 'hatun yaku', spanishWord : 'rio'},
        ],
        question : secretsQuizList.QUESTION_SECRET_ARENA
    },
    SECRET_MOJAR_CAMA : {
        title : 'Corrigiendo modales',
        community : null,
        state : null,
        content : {
            photo : secretsResourcesList.PHOTO_SECRET_MOJAR_CAMA.key,
            description : 'Cuando un niño(a)(wawa) grande moja la cama por la noche al día siguiente la mamá le manda a pedir sal(kachi) al vecino para que sienta vergüenza y deje de orinar en la cama.'
        },
        dialects : [
            {kichwaWord : 'wawa(s)', spanishWord : 'niños(as)'},
            {kichwaWord : 'kachi', spanishWord : 'sal'},
        ],
        question : secretsQuizList.QUESTION_SECRET_MOJAR_CAMA
    },
    SECRET_PARTO : {
        title : 'Predicción de parto',
        community : null,
        state : null,
        content : {
            photo : secretsResourcesList.PHOTO_SECRET_PARTO.key,
            description : 'Para ver si el parto está cerca se le debe tocar la coronilla(chona) a la mujer embarazada, si está bien caliente, está a minutos de que nazca el bebé.'
        },
        dialects : [
            {kichwaWord : 'chona', spanishWord : 'coronilla'}
        ],
        question : secretsQuizList.QUESTION_SECRET_PARTO
    },
}

export const secretsGroupList = {
    GROUP_01 : {
        month : 'ENERO',
        secrets : [
            secretsList.SECRET_ASUSTADA,
            secretsList.SECRET_PLATITA
        ]
    },
    GROUP_02 : {
        month : 'FEBRERO',
        secrets : [
            secretsList.SECRET_TORO,
            secretsList.SECRET_MAL_OJO
        ]
    },
    GROUP_03 : {
        month : 'MARZO',
        secrets : [
            secretsList.SECRET_PELEA_PERROS,
            secretsList.SECRET_MOJAR_CAMA
        ]
    },
    GROUP_04 : {
        month : 'ABRIL',
        secrets : [
            secretsList.SECRET_BEBE_FUERTE,
            secretsList.SECRET_BEBE_HABLA
        ]
    },
    GROUP_05 : {
        month : 'MAYO',
        secrets : [
            secretsList.SECRET_RECUPERAR_SALUD,
            secretsList.SECRET_ESPIRITU
        ]
    },
    GROUP_06 : {
        month : 'JUNIO',
        secrets : [
            secretsList.SECRET_PLANTA_MEDICINAL,
            secretsList.SECRET_BEBE_ABUNDANCIA
        ]
    },
    GROUP_07 : {
        month : 'JULIO',
        secrets : [
            secretsList.SECRET_PAPA,
            secretsList.SECRET_CHACARERO
        ]
    },
    GROUP_08 : {
        month : 'AGOSTO',
        secrets : [
            secretsList.SECRET_BEBE_ARMONIA,
            secretsList.SECRET_MAIZ
        ]
    },
    GROUP_09 : {
        month : 'SEPTIEMBRE',
        secrets : [
            secretsList.SECRET_SALIVA,
            secretsList.SECRET_PARTO
        ]
    },
    GROUP_10 : {
        month : 'OCTUBRE',
        secrets : [
            secretsList.SECRET_LADRONES,
            secretsList.SECRET_DEUDA
        ]
    },
    GROUP_11 : {
        month : 'NOVIEMBRE',
        secrets : [
            secretsList.SECRET_SAL,
            secretsList.SECRET_PEPINO
        ]
    },
    GROUP_12 : {
        month : 'DICIEMBRE',
        secrets : [
            secretsList.SECRET_HOJA_CULANTRO,
            secretsList.SECRET_ARENA
        ]
    },
}