import Phaser from 'phaser';
import { bookResources, simbolsResources } from '../../utils/assetsPath';
import { gridAlignGroupElements, addChildrenToContainer, setHandCursor, resetCursor} from '../../utils/functions';
import FestivityChapter from '../commonObjects/tale-pages/chapters/festivityChapter';
import FoodChapter from '../commonObjects/tale-pages/chapters/foodChapter';
import CoverPage from '../commonObjects/tale-pages/pages/coverPage';
import PiecesPage from '../commonObjects/tale-pages/pages/piecesPage';
import Page from './page';
import SignsChapter from '../commonObjects/tale-pages/chapters/signsChapter';
import DialectChapter from '../commonObjects/tale-pages/chapters/dialectChapter';


export default class Book extends Phaser.GameObjects.Container{
    constructor(scene,x,y,bookData){
        super(scene,x,y);

        this.leftPage = null;
        this.rightPage = null;
        this.pagesList = [];
        this.quizzesList = [];
        this.piecesList = [];

        this.setInitialProperties(scene);

        this.loadData(bookData);

    }

    loadData(bookData){
        console.log('mi información: ',bookData);
        this.setBookData(bookData);
    }

    setBookData(data){
        this.setBookKey(data.key);
        this.setTitle(data.title);
        this.setBookMonth(data.month);
        this.setFestivityInfo(data.festivity);
        this.setFoodInfo(data.food);
        this.setSignsInfo(data.signs);
        this.setSecretsInfo(data.secrets);
        this.setDialectQuizDetail(data.dialect_quiz_detail);
    }

    setInitialProperties(scene){
        this.setScene(scene);
        this.setRightSeparatorsList([]);
        this.setLeftSeparatorsList([]);
        this.setRightGroupGridConfig({width:1,height:4,cellHeight:150,x:475,y:-200});
        this.setLeftGroupGridConfig({width:1,height:4,cellHeight:150,x:-620,y:-200});
    }

    setScene(scene){ this.scene = scene }
    getScene(){ return this.scene }

    setBookKey(key){
        this.bookKey = key;
    }

    setBookMonth(month){this.bookMonth = month}
    getBookMonth(){return this.bookMonth}

    setTitle(title){
        this.title = title;
    }

    setFestivityInfo(info){
        this.festivityInfo = info;
    }

    setFoodInfo(info){
        this.foodInfo = info;
    }

    setSignsInfo(info){
        this.signsInfo = info;
    }

    setSecretsInfo(info){
        this.secretsInfo = info;
    }

    setDialectsInfo(info){
        this.dialectsInfo = info;
    }

    setDialectQuizDetail(info){
        this.dialectQuizDetail = info;
    }

    setRightGroupGridConfig(config){ this.rightGridConfig = config }
    getRightGroupGridConfig(){ return this.rightGridConfig }

    setLeftGroupGridConfig(config){ this.leftGridConfig = config }
    getLeftGroupGridConfig(){ return this.leftGridConfig }


    setRightSeparatorsList(list){ this.rightSeparatorsList = list }
    getRightSeparatorsList(){ return this.rightSeparatorsList }

    setLeftSeparatorsList(list){ this.leftSeparatorsList = list }
    getLeftSeparatorsList(){ return this.leftSeparatorsList }

    joinLists(){
        let signsSecretsList = [];
        let signsList = this.signsInfo.signs,
            secretsList = this.secretsInfo.secrets,
            jsonFile = null,
            questionsList = [],
            count = 1;
        
        signsList.forEach((sign)=>{
            sign.question.questionNumber = count;
            count++;
            signsSecretsList.push(sign);
            questionsList.push(sign.question);
        });

        secretsList.forEach((secret)=>{
            secret.question.questionNumber = count;
            count++;
            signsSecretsList.push(secret);
            questionsList.push(secret.question);
        });
        jsonFile = {
            title : 'Señas y\nsecretos',
            cover : simbolsResources.COVER_SIGNS.key,
            type : 'SEÑAS-SECRETOS',
            list : signsSecretsList,
            quiz: {
                id : this.signsInfo.quiz_id,
                title : 'trivia de señas y secretos',
                questions : questionsList
            },
            price : this.signsInfo.price 
        };
        return jsonFile;
    }

    getDialectsList(information){
        let list = information.content.dialects;
        list.forEach((dialect)=>{
            this.dialectList.push(dialect);
        });
    }

    getSignsDialectList(information){
        let list = information.signs;
        list.forEach((content)=>{
            let aux = content.dialects;
            aux.forEach((dialect)=>{
                this.dialectList.push(dialect);
            })
        })
    }

    getSecretsDialectList(information){
        let list = information.secrets;
        list.forEach((content)=>{
            let aux = content.dialects;
            aux.forEach((dialect)=>{
                this.dialectList.push(dialect);
            })
        })
    }

    fillDialectList(){
        this.dialectList = [];
        let jsonFile = null;

        this.getDialectsList(this.festivityInfo);
        this.getDialectsList(this.foodInfo);
        this.getSignsDialectList(this.signsInfo);
        this.getSecretsDialectList(this.secretsInfo);

        jsonFile = {
            title : 'Dialectos\nPrincipales',
            cover : simbolsResources.COVER_DIALECTS.key,
            type : 'DIALECTOS',
            list : this.dialectList,
            quiz_id : this.dialectQuizDetail.quiz_id,
            price : this.dialectQuizDetail.dialect_price
        }
        this.setDialectsInfo(jsonFile);
    }
    
    setBody(scene,texture){
        this.body = scene.add.image(0,0,texture);
        this.add(this.body).setSize(this.body.width,this.body.height);
    }

    buildBook(scene){
        this.setBody(scene,bookResources.BOOK_PASTE.key);
        this.addCoverPage(scene);
        this.addPiecesPage(scene);
        this.addFestivityChapter(scene);
        this.addFoodChapter(scene);
        this.addSignChapter(scene);
        this.addDialectChapter(scene);
        this.addBlankPage(scene);
        this.addRings(scene);

        this.addSeparators(scene);
        this.addBookRibbon();
        this.activeBookRibbon();
        this.leftPage = this.pagesList[0];
        this.rightPage = this.pagesList[1];
        this.showCurrentPages(scene);


        this.passAPage(scene);        

        this.activeSeparators(scene);
        this.showAllLists();
        this.checkPiecesStatus(scene);
    }

    addCoverPage(scene){
        this.coverPage = new CoverPage(scene,0,0,this.title);
        this.add(this.coverPage);
        this.coverPage.setVisible(false);
        this.pagesList.push(this.coverPage);
    }

    addPiecesPage(scene){
        this.piecesPage = new PiecesPage(scene,0,0);
        this.add(this.piecesPage);
        this.piecesPage.setVisible(false);
        this.pagesList.push(this.piecesPage);
    }

    addBlankPage(scene){
        if(this.pagesList.length%2!==0){
            let blankPage = this.createBlankPage(scene);
            this.addToPageslist(blankPage);
        }
    }

    createBlankPage(scene){
        let blankPage = new Page(scene,0,0,null);
        blankPage.buildPage(scene);
        blankPage.ribbon.setVisible(false);
        return blankPage;
    }

    addToPageslist(page){
        this.pagesList.push(page);
        this.add(page);
        page.setVisible(false);
    }

    showCurrentPages(scene){
        let leftIndex = this.pagesList.indexOf(this.leftPage),
            rightIndex = this.pagesList.indexOf(this.rightPage);

        this.pagesList.forEach(page => {
            let pageIndex = this.pagesList.indexOf(page);
            if(pageIndex === leftIndex || pageIndex === rightIndex){
                page.setVisible(true);
            }else{
                page.setVisible(false);
            }
        })

        this.leftPage.rightCorner.setVisible(false).disableInteractive();
        this.rightPage.leftCorner.setVisible(false).disableInteractive();

        this.leftPage.leftCorner.setInteractive();
        this.rightPage.rightCorner.setInteractive();

        let group = scene.add.group([this.leftPage,this.rightPage]);
        let gridConfig = {width:2,height:1,cellWidth:this.leftPage.width, cellHeight:0,position:6,x:-255};
        gridAlignGroupElements(scene,group,gridConfig);

        this.changeBookAppearance();

        this.checkCurrentPage();
    }

    checkCurrentPage(){
        if(this.festivityChapter.pagesList[0]===this.leftPage){
            this.hideBelowSeparator(this.festivityLeftSp);
        } else if(this.festivityChapter.pagesList[2]===this.leftPage){
            this.hideBelowSeparator(this.foodSeparator);
        } else if(this.foodChapter.pagesList[0]===this.leftPage){
            this.hideBelowSeparator(this.foodLeftSp);
        } else if(this.foodChapter.pagesList[2]===this.leftPage){
            this.hideBelowSeparator(this.signsSeparator);
        } else if(this.signChapter.pagesList[0]===this.leftPage){
            this.hideBelowSeparator(this.signsLeftSp);
        } else if(this.signChapter.pagesList[2]===this.leftPage){
            this.hideBelowSeparator(this.dialectSeparator);
        } else if(this.dialectChapter.pagesList[0]===this.leftPage){
            this.hideBelowSeparator(this.dialectsLeftSp);
        } else if(this.dialectChapter.pagesList[2]===this.leftPage){
            this.hideAllRightSeparators();
        }
    }

    addQuiztoList(chapter){
        let quizInfo = chapter.getQuizData(),
            quizId = quizInfo.id,
            quizField = {};

            if( localStorage.hasOwnProperty('loggedPlayer') &&
                localStorage.hasOwnProperty('quizRecords') ){
            let quizRecords = JSON.parse(localStorage.getItem('quizRecords')),
                ownRecord = quizRecords.find((record)=>{return record.quiz_id === quizId});
                quizField.id = quizId;
                quizField.title = quizInfo.title;
                quizField.chapterType = chapter.getType();
                quizField.hits = ownRecord.hits;
                quizField.fails = ownRecord.fails;
                quizField.score = ownRecord.score;
                quizField.completed = (ownRecord.completed === 1)? true : false;
            }else{
                quizField.id = quizId;
                quizField.title = quizInfo.title;
                quizField.chapterType = chapter.getType();
                quizField.hits = 0;
                quizField.fails = 0;
                quizField.score = 0;
                quizField.completed = false;
            }
             
        this.quizzesList.push(quizField);    
    }
    

    addPieceInfoToList(chapter){
        if( localStorage.hasOwnProperty('loggedPlayer') && 
            localStorage.hasOwnProperty('pieceRecords')) {
            let pieceInfo = chapter.getPieceInfo(),
                pieceRecords = JSON.parse(localStorage.getItem('pieceRecords')),
                pieceRecord = pieceRecords.find((record)=>{return record.puzzle_piece_id === pieceInfo.id}),
                listField = {
                puzzle_id : pieceRecord.puzzle_piece_id,
                type : pieceInfo.type,
                earned : pieceRecord.earned
            }    
            this.piecesList.push(listField);
        }
    }

    addFestivityChapter(scene){
        this.festivityChapter = new FestivityChapter(scene,this.festivityInfo, this.getBookMonth());
        this.addQuiztoList(this.festivityChapter);
        this.addPieceInfoToList(this.festivityChapter);

        this.festivityChapter.pagesList.forEach((page)=>{
            this.addToPageslist(page);
        });
        if(this.festivityChapter.pagesList.length%2!==0){
            let blankPage = this.createBlankPage(scene);
            this.addToPageslist(blankPage);
        }    
    }

    addFoodChapter(scene){
        this.foodChapter = new FoodChapter(scene,this.foodInfo,this.getBookMonth());
        this.foodChapter.setMonth(this.getBookMonth());
        this.addQuiztoList(this.foodChapter);
        this.addPieceInfoToList(this.foodChapter);
        this.foodChapter.pagesList.forEach((page)=>{
            this.addToPageslist(page);
        });
        if(this.foodChapter.pagesList.length%2!==0){
            let blankPage = this.createBlankPage(scene);
            this.addToPageslist(blankPage);
        }            
    }

    addSignChapter(scene){
        let signsSecretsInfo = this.joinLists();
        this.signChapter = new SignsChapter(scene,signsSecretsInfo, this.getBookMonth());
        this.signChapter.setMonth(this.getBookMonth());
        this.addQuiztoList(this.signChapter);
        this.addPieceInfoToList(this.signChapter);
        this.signChapter.pagesList.forEach((page)=>{
            this.addToPageslist(page);
        });
        if(this.signChapter.pagesList.length%2!==0){
            let blankPage = this.createBlankPage(scene);
            this.addToPageslist(blankPage);
        };    
    }

    addDialectChapter(scene){
        this.fillDialectList();
        this.dialectChapter = new DialectChapter(scene,this.dialectsInfo, this.getBookMonth());
        this.dialectChapter.setMonth(this.getBookMonth());
        this.addQuiztoList(this.dialectChapter);
        this.addPieceInfoToList(this.dialectChapter);
        this.dialectChapter.pagesList.forEach((page)=>{
            this.addToPageslist(page);
        });
        if(this.dialectChapter.pagesList.length%2!==0){
            let blankPage = this.createBlankPage(scene);
            this.addToPageslist(blankPage);
        }
    }

    addSeparators(scene){
        //rightSeparators
        this.dialectSeparator = scene.add.image(0,0,bookResources.SEPARATOR_TALE.key);
        this.festivitySeparator = scene.add.image(0,0,bookResources.SEPARATOR_FESTIVITY.key);
        this.foodSeparator = scene.add.image(0,0,bookResources.SEPARATOR_FOOD.key);
        this.signsSeparator = scene.add.image(0,0,bookResources.SEPARATOR_SIGNS.key);

        this.festivityLeftSp = scene.add.image(0,0,bookResources.SEPARATOR_FESTIVITY.key);
        this.foodLeftSp = scene.add.image(0,0,bookResources.SEPARATOR_FOOD.key);
        this.signsLeftSp = scene.add.image(0,0,bookResources.SEPARATOR_SIGNS.key);
        this.dialectsLeftSp = scene.add.image(0,0,bookResources.SEPARATOR_TALE.key);

        this.separatorsList = [this.festivitySeparator,this.foodSeparator,this.signsSeparator,this.dialectSeparator];
        this.setLeftSeparatorsList([this.festivityLeftSp,this.foodLeftSp,this.signsLeftSp,this.dialectsLeftSp]);

        this.separatorsList.forEach(separator =>{
            this.addSeparatorToRightList(separator);
        });

        this.rightGroup = scene.add.group(this.getRightSeparatorsList());
        this.leftGroup = scene.add.group(this.getLeftSeparatorsList());  

        this.rightGroup.rotate(1.57);
        this.leftGroup.rotate(-1.57);
        gridAlignGroupElements(scene,this.rightGroup,this.getRightGroupGridConfig());
        gridAlignGroupElements(scene,this.leftGroup,this.getLeftGroupGridConfig());
        
        addChildrenToContainer(this,this.rightGroup.getChildren());
        addChildrenToContainer(this,this.leftGroup.getChildren());

        this.getLeftSeparatorsList().forEach(sp =>{sp.setVisible(false)});
    }

    addBookRibbon(){
        this.bookRibbon = this.getScene().add.image(470,-300,bookResources.LISTON_BOOK.key);
        this.add(this.bookRibbon);
        this.bookRibbon.setScale(0.35);
    }

    activeBookRibbon(){
        this.bookRibbon.setInteractive({useHandCursor : true});

        this.bookRibbon.on('pointerup',()=>{
            this.getScene().sound.play('ribbonFliping');
            this.goToFirstPage();
            this.hideBelowSeparator(this.festivitySeparator);
            this.hideBelowSeparator(this.festivityLeftSp);
        })

        this.bookRibbon.on('pointerover',()=>{
            this.animRibbonInit();
        });
        this.bookRibbon.on('pointerout',()=>{
            this.animRibbonReturn();
        });
    }

    animRibbonInit(){
        this.getScene().tweens.add({targets : this.bookRibbon, scale :{ from : 0.35, to : 0.37},
             y : {from : -300,to : -295},duration : 200})
    }

    animRibbonReturn(){
        this.getScene().tweens.add({targets : this.bookRibbon, scale : { from : 0.37, to : 0.35},
             y : {from : -295,to : -300}, duration : 200})
    }

    goToFirstPage(){
        let scene = this.getScene();
        this.leftPage = this.pagesList[0];
        this.rightPage = this.pagesList[1];
        this.showCurrentPages(scene); 
    }

    addSeparatorToRightList(separator){ this.getRightSeparatorsList().push(separator) }
    addSeparatorToLeftList(separator){ this.getLeftSeparatorsList().push(separator) }

    hideBelowSeparator(separator){
        let rightList = this.getRightSeparatorsList(),
            leftList = this.getLeftSeparatorsList();
       if(rightList.includes(separator)){
           let index = rightList.indexOf(separator);
        for(let i = 0;i<index;i++){
            rightList[i].setVisible(false);
            leftList[i].setVisible(true);
        }

       }else if(leftList.includes(separator)){
        let index = leftList.indexOf(separator);
        for(let i = index;i<leftList.length;i++){
            rightList[i].setVisible(true);
            leftList[i].setVisible(false);
        }
       }
    }

    hideAllRightSeparators(){
        let rightList = this.getRightSeparatorsList(),
            leftList = this.getLeftSeparatorsList();

        rightList.forEach(sp => {sp.setVisible(false)})
        leftList.forEach(sp => {sp.setVisible(true)})
    }

    disableSeparators(){
        this.separatorsList.forEach((separator)=>{
            separator.disableInteractive();
        })
    }

    disablePages(){
        this.pagesList.forEach((page)=>{
            page.disableCorners();
        })
    }

    disableBookRibbon(){
        this.bookRibbon.disableInteractive();
    }

    activeSeparators(scene){
        this.separatorsList.forEach((separator)=>{
            separator.setInteractive({useHandCursor : true});
            let index = 0;

            separator.on('pointerover',()=>{this.separatorInitAnim(scene,separator)});
            separator.on('pointerout',()=>{this.separatorReturnAnim(scene,separator)});

            separator.on('pointerup',()=>{
                scene.sound.play('separatorFliping');
                this.hideBelowSeparator(separator);
                switch(separator){
                    case this.festivitySeparator:
                        this.leftPage = this.pagesList.find((page)=>{
                            return page === this.festivityChapter.pagesList[index];
                        });
                        this.rightPage = this.pagesList.find((page)=>{
                            return page === this.festivityChapter.pagesList[index+1];
                        });
                    break;

                    case this.foodSeparator:
                        this.leftPage = this.pagesList.find((page)=>{
                            return page === this.foodChapter.pagesList[index];
                        });
                        this.rightPage = this.pagesList.find((page)=>{
                            return page === this.foodChapter.pagesList[index+1];
                        });
                    break;

                    case this.signsSeparator:
                        this.leftPage = this.pagesList.find(page => {
                            return page === this.signChapter.pagesList[index];
                        });
                        this.rightPage = this.pagesList.find(page => {
                            return page === this.signChapter.pagesList[index + 1];
                        });
                    break;

                    case this.dialectSeparator:
                        this.leftPage = this.pagesList.find(page => {
                            return page === this.dialectChapter.pagesList[index];
                        });
                        this.rightPage = this.pagesList.find(page => {
                            return page === this.dialectChapter.pagesList[index + 1];
                        })
                    break;

                    default:
                    break;
                }
                this.showCurrentPages(scene);
            })
        });


        this.getLeftSeparatorsList().forEach(separator =>{
            separator.setInteractive({useHandCursor : true});
            let index = 0;

            separator.on('pointerover',()=>{this.separatorInitAnim(scene,separator)});
            separator.on('pointerout',()=>{this.separatorReturnAnim(scene,separator)});

            separator.on('pointerup',()=>{
                scene.sound.play('separatorFliping');
                this.hideBelowSeparator(separator);
                switch(separator){
                    case this.festivityLeftSp:
                        this.leftPage = this.pagesList.find((page)=>{
                            return page === this.festivityChapter.pagesList[index];
                        });
                        this.rightPage = this.pagesList.find((page)=>{
                            return page === this.festivityChapter.pagesList[index+1];
                        });
                    break;

                    case this.foodLeftSp:
                        this.leftPage = this.pagesList.find((page)=>{
                            return page === this.foodChapter.pagesList[index];
                        });
                        this.rightPage = this.pagesList.find((page)=>{
                            return page === this.foodChapter.pagesList[index+1];
                        });
                    break;

                    case this.signsLeftSp:
                        this.leftPage = this.pagesList.find(page => {
                            return page === this.signChapter.pagesList[index];
                        });
                        this.rightPage = this.pagesList.find(page => {
                            return page === this.signChapter.pagesList[index + 1];
                        });
                    break;

                    case this.dialectsLeftSp:
                        this.leftPage = this.pagesList.find(page => {
                            return page === this.dialectChapter.pagesList[index];
                        });
                        this.rightPage = this.pagesList.find(page => {
                            return page === this.dialectChapter.pagesList[index + 1];
                        })
                    break;

                    default:
                    break;
                }
                this.showCurrentPages(scene);
            })


        })
    }

    separatorInitAnim(scene,separator){
        scene.tweens.add({ targets : separator, scale : {from : 1, to : 1.07}, duration : 200})
    }

    separatorReturnAnim(scene,separator){
        scene.tweens.add({ targets : separator, scale: {from : 1.07, to : 1}, duration : 200})
    }


    passAPage(scene){
        this.pagesList.forEach((page)=>{
            let index = this.pagesList.indexOf(page);
            if(index===0 || index===this.pagesList.length-1){
                page.leftCorner.setVisible(false).disableInteractive();
                page.rightCorner.setVisible(false).disableInteractive();
            }else{
                page.leftCorner.on('pointerup',()=>{
                    this.changePage(scene,1);
                });
        
                page.rightCorner.on('pointerup',()=>{
                    this.changePage(scene,2);
                });   
            }
        }) 
    }

    changePage(scene,orientation){
        scene.sound.play('pageFliping');
        let leftIndex = this.pagesList.indexOf(this.leftPage),
            rightIndex = this.pagesList.indexOf(this.rightPage),
            newLeft,
            newRight;
        switch(orientation){
            case 1:
                newLeft = leftIndex-2;
                newRight = rightIndex-2;
            break;

            case 2:
                newLeft = leftIndex+2;
                newRight = rightIndex+2;
            break;
            default:
            break;
        }

        if( newLeft>=0 || newRight<=this.pagesList.length ){
            this.pagesList[leftIndex].setVisible(false);
            this.pagesList[rightIndex].setVisible(false);
            this.rightPage = this.pagesList[newRight];
            this.leftPage =this.pagesList[newLeft];
            this.showCurrentPages(scene);
        }
    }

    changeBookAppearance(){
        if(this.leftPage===this.pagesList[0]){
            this.body.setTexture(bookResources.BOOK_FIRST_PAGE.key);
        }else if(this.rightPage===this.pagesList[this.pagesList.length-1]){
            this.body.setTexture(bookResources.BOOK_LAST_PAGE.key);
        }else{
            this.body.setTexture(bookResources.BOOK_PASTE.key);
        }
    }

    addRings(scene){
        let group = scene.add.group();
        let upperGroup = scene.add.group();
        let lowerGroup = scene.add.group();
        
        for(let i = 0 ; i < 6; i ++){
            let ring = this.createRing(scene);
            group.add(ring);
        }

        for(let i = 0 ; i < 3; i ++){
            let ring = this.createRing(scene);
            upperGroup.add(ring);
        }

        for(let i = 0 ; i < 3; i ++){
            let ring = this.createRing(scene);
            lowerGroup.add(ring);
        }

        gridAlignGroupElements(scene,upperGroup,{width:1,height:3,cellHeight:50,x:-1,y:-230,position:6});
        addChildrenToContainer(this,upperGroup.getChildren());

        gridAlignGroupElements(scene,lowerGroup,{width:1,height:3,cellHeight:50,x:-1,y:160,position:6});
        addChildrenToContainer(this,lowerGroup.getChildren());
    }

    createRing(scene){
        let container = scene.add.container(0,0);
        container.setSize(50,20);

        let circleLeft = scene.add.graphics();
        circleLeft.fillStyle(0x2d2d2d,0.5)
        .fillCircle(-14,-10,12);

        let circleRight = scene.add.graphics();
        circleRight.fillStyle(0x2d2d2d,0.5)
        .fillCircle(17,-10,12);

        let ring = scene.add.graphics();
        ring.fillStyle(0xB7AEAE,1).lineStyle(2,0x000,1);
        ring.fillRoundedRect(-19,-17,40,15,2).strokeRoundedRect(-19,-17,40,15,2);

        container.add([circleLeft,circleRight,ring]);
        return container;
    }

    showAllLists(){
        console.log('lista de quizzes: ',this.quizzesList);
        console.log('lista de piezas: ',this.piecesList);
    }


    //metodo temporal.
    checkPiecesStatus(scene){
        this.piecesList.forEach((pieceRecord)=>{
            let earned = (pieceRecord.earned === 1)? true:false;
            if( earned === true ){
                this.fillEarnedPuzzlePieces(pieceRecord,scene);
                this.piecesPage.checkBoxes(scene,'loading');
            }
        });
    }


     changeQuizRecord(data,scene){
        let quizRecord = this.quizzesList.find((quizR)=>{
            return quizR.title === data.quizTitle
        });

        if(data.score>quizRecord.score){
            quizRecord.score = data.score;
            this.updateChapterQuizScore(quizRecord);
        }

        if(data.score===30 && quizRecord.completed===false){
            this.earnNewPuzzlePiece(quizRecord,scene);
            quizRecord.completed = true;
            
            setTimeout(()=> scene.emitToMainUI('new_puzzle_earned'), 500);
        }

        console.log('mi lista de puntuaciones de este libro es: ',this.quizzesList);

        this.piecesPage.checkBoxes(scene, 'game-playing');
    }

    updateChapterQuizScore(quizRecord){
        let chapterType = quizRecord.chapterType;
        let score = quizRecord.score;
        switch(chapterType){
            case 'FESTIVIDADES':
                this.festivityChapter.setQuizScore(score);
                this.festivityChapter.updateQuizCardScore();
                break;
            case 'COMIDAS':
                this.foodChapter.setQuizScore(score);
                this.foodChapter.updateQuizCardScore();
                break;
            case 'SEÑAS-SECRETOS':
                this.signChapter.setQuizScore(score);
                this.signChapter.updateQuizCardScore();
                break;
            case 'DIALECTOS':
                this.dialectChapter.setQuizScore(score);
                this.dialectChapter.updateQuizCardScore();
                break;
            default :
                break;
        }
    }

    earnNewPuzzlePiece(quizRecord,scene){
        let chapterType = quizRecord.chapterType;
            switch(chapterType){
                case 'FESTIVIDADES':
                    this.festivityChapter.generatePuzzlePiece(scene);
                    this.fillPiecePage(this.festivityChapter,chapterType);
                    this.festivityChapter.sendPieceToCaracScene(scene);
                    break;
                case 'COMIDAS':
                    this.foodChapter.generatePuzzlePiece(scene);
                    this.fillPiecePage(this.foodChapter,chapterType);
                    this.foodChapter.sendPieceToCaracScene(scene);
                    break;
                case 'SEÑAS-SECRETOS':
                    this.signChapter.generatePuzzlePiece(scene);
                    this.fillPiecePage(this.signChapter,chapterType);
                    this.signChapter.sendPieceToCaracScene(scene);
                    break;
                case 'DIALECTOS':
                    this.dialectChapter.generatePuzzlePiece(scene);
                    this.fillPiecePage(this.dialectChapter,chapterType);
                    this.dialectChapter.sendPieceToCaracScene(scene);
                    break;
                default :
                    break;
            }
    }

    fillEarnedPuzzlePieces(pieceRecord,scene){
        let chapterType = pieceRecord.type;
            switch(chapterType){
                case 'FESTIVIDADES':
                    this.festivityChapter.generatePuzzlePiece(scene);
                    this.fillPiecePage(this.festivityChapter,chapterType);
                    break;
                case 'COMIDAS':
                    this.foodChapter.generatePuzzlePiece(scene);
                    this.fillPiecePage(this.foodChapter,chapterType);
                    break;
                case 'SEÑAS-SECRETOS':
                    this.signChapter.generatePuzzlePiece(scene);
                    this.fillPiecePage(this.signChapter,chapterType);
                    break;
                case 'DIALECTOS':
                    this.dialectChapter.generatePuzzlePiece(scene);
                    this.fillPiecePage(this.dialectChapter,chapterType);
                    break;
                default :
                    break;
            }
    }
    

    fillPiecePage(chapter,type){
        let piece = chapter.puzzlePiece;
        this.piecesPage.addNewPuzzlePiece(piece,type);
    }

    showBookPrice(){
        this.coverPage.unlockPrice();
    }
}