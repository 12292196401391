import Chapter from "../../../baseClasses/chapter";

export default class SignsChapter extends Chapter{
    constructor(scene,data, monthName){
        super(data);
        this.setMonth(monthName);
        this.addSignsChapter(scene);
    }

    setBaseData(data){
        this.setTitle(data.title);
        this.setCoverPhoto(data.cover);
        this.setType(data.type);
        this.setInformation(data.list);        
        this.setQuiz(data.quiz);
        this.setPuzzlePieceInfo(data.price);
        this.addTypeToQuizInfo();
    }

    setInformation(signs){
        this.signsInfo = signs;
    }

    addSignsChapter(scene){
        this.setIntroPage(scene,this.type);
        this.setSignsPages(scene,this.signsInfo);
        this.setQuizPage(scene,this.quiz,this.puzzlePieceInfo);
    }
}