import { caracResources, mainUIResources} from "./assetsPath";

export const piecesInfo=[
    {id:1,name:'FESTIVIDADES',type:'red',xScale:0.8,yScale:0.8},
    {id:2,name:'COMIDAS',type:'yellow',xScale:0.6,yScale:0.6},
    {id:3,name:'SEÑAS/SECRETOS',type:'green',xScale:0.5,yScale:0.5},
    {id:4,name:'DIALECTOS',type:'blue',xScale:0.4,yScale:0.4}
]

export const toolRewardData =[
    { toolId : 1, name:'COMPOST',texture:mainUIResources.COMPOST_REWARD.key,tool:'compostTool'},
    { toolId : 2, name:'HOE',texture:mainUIResources.HOE_REWARD.key,tool:'hoeTool'},
    { toolId : 3, name:'SCISSORS',texture:mainUIResources.SCISSORS_REWARD.key,tool:'scissorsTool'},
    { toolId : 4, name:'SHOVEL',texture:mainUIResources.SHOVEL_REWARD.key,tool:'shovelTool'},
    { toolId : 5, name:'SPRAY',texture:mainUIResources.SPRAY_REWARD.key,tool:'sprayTool'},
    { toolId : 6, name:'WATER',texture:mainUIResources.WATER_REWARD.key,tool:'waterTool'},
]

export const cursorsList = {
    HAND_CURSOR : {
        path:'./funcaracAssets/cursors/mainui/handCursor.cur',
        type:'pointer'
    },
    COMPOST_CURSOR : {
        path:'./funcaracAssets/cursors/tools/compostCursor.cur',
        type:'pointer'
    },
    HOE_CURSOR : {
        path:'./funcaracAssets/cursors/tools/hoeCursor.cur',
        type:'pointer'
    },
    SCISSORS_CURSOR : {
        path:'./funcaracAssets/cursors/tools/scissorsCursor.cur',
        type:'pointer'
    },
    SHOVEL_CURSOR : {
        path:'./funcaracAssets/cursors/tools/shovelCursor.cur',
        type:'pointer'
    },
    SPRAY_CURSOR : {
        path:'./funcaracAssets/cursors/tools/sprayCursor.cur',
        type:'pointer'
    },
    WATER_CURSOR : {
        path:'./funcaracAssets/cursors/tools/waterCursor.cur',
        type:'pointer'
    }
}

export const numbers = [
    {key:mainUIResources.NUM_0.key,value:0},
    {key:mainUIResources.NUM_1.key,value:1},
    {key:mainUIResources.NUM_2.key,value:2},
    {key:mainUIResources.NUM_3.key,value:3},
    {key:mainUIResources.NUM_4.key,value:4},
    {key:mainUIResources.NUM_5.key,value:5},
    {key:mainUIResources.NUM_6.key,value:6},
    {key:mainUIResources.NUM_7.key,value:7},
    {key:mainUIResources.NUM_8.key,value:8},
    {key:mainUIResources.NUM_9.key,value:9}
]


export const slotsInfo=[
    {id:1,background:caracResources.BACKGROUND_RED.key,xScale:1.4,yScale:1.05, type:'red'},
    {id:2,background:caracResources.BACKGROUND_YELLOW.key,xScale:1.06,yScale:0.8, type:'yellow'},
    {id:3,background:caracResources.BACKGROUND_GREEN.key,xScale:0.8,yScale:0.65,type:'green'},
    {id:4,background:caracResources.BACKGROUND_BLUE.key,xScale:0.6,yScale:0.5,type:'blue'},
]

export const ROTATION_VALUE = 26.72;
export const STEP_VALUE = 0.52;



