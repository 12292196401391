export const familiarSignsResources = {
    PHOTO_ANGAS : {
        key : 'SEÑA_F_01',
        path : './funcaracAssets/content/signs/familia/SEÑA_F_01.png'
    },
    PHOTO_PALOMAS : {
        key : 'SEÑA_F_02',
        path : './funcaracAssets/content/signs/familia/SEÑA_F_02.png'
    },
    PHOTO_PERRO_DESCONOCIDO : {
        key : 'SEÑA_F_03',
        path : './funcaracAssets/content/signs/familia/SEÑA_F_03.png'
    },
    PHOTO_SUEÑO_ORINA : {
        key : 'SEÑA_F_04',
        path : './funcaracAssets/content/signs/familia/SEÑA_F_04.png'
    },
    PHOTO_VIENTRE : {
        key : 'SEÑA_F_05',
        path : './funcaracAssets/content/signs/familia/SEÑA_F_05.png'
    },
    PHOTO_PIOJO_NIÑO : {
        key : 'SEÑA_F_06',
        path : './funcaracAssets/content/signs/familia/SEÑA_F_06.png'
    },
    PHOTO_VARIOS_PERROS : {
        key : 'SEÑA_F_07',
        path : './funcaracAssets/content/signs/familia/SEÑA_F_07.png'
    },
    PHOTO_CUYES_MURMURADORES : {
        key : 'SEÑA_F_08',
        path : './funcaracAssets/content/signs/familia/SEÑA_F_08.png'
    },
    PHOTO_FOGON : {
        key : 'SEÑA_F_09',
        path : './funcaracAssets/content/signs/familia/SEÑA_F_09.png'
    }
}

export const deathSignsResources = {
    PHOTO_ALLA_CABALLO : {
        key : 'SEÑA_M_01',
        path : './funcaracAssets/content/signs/muerte/SEÑA_M_01.png'
    },
    PHOTO_MARIPOSA_NEGRA : {
        key : 'SEÑA_M_02',
        path : './funcaracAssets/content/signs/muerte/SEÑA_M_02.png'
    },
    PHOTO_ALWICU : {
        key : 'SEÑA_M_03',
        path : './funcaracAssets/content/signs/muerte/SEÑA_M_03.png'
    },
    PHOTO_PALOMA_LLORANDO : {
        key : 'SEÑA_M_04',
        path : './funcaracAssets/content/signs/muerte/SEÑA_M_04.png'
    },
    PHOTO_AULLIDO_PERRO : {
        key : 'SEÑA_M_05',
        path : './funcaracAssets/content/signs/muerte/SEÑA_M_05.png'
    },
    PHOTO_PULGA_UÑERO : {
        key : 'SEÑA_M_06',
        path : './funcaracAssets/content/signs/muerte/SEÑA_M_06.png'
    },
    PHOTO_MOSCA_VERDE : {
        key : 'SEÑA_M_07',
        path : './funcaracAssets/content/signs/muerte/SEÑA_M_07.png'
    },
    PHOTO_MUELAS_JUICIO : {
        key : 'SEÑA_M_08',
        path : './funcaracAssets/content/signs/muerte/SEÑA_M_08.png'
    },
    PHOTO_DIENTE_FRONTAL : {
        key : 'SEÑA_M_09',
        path : './funcaracAssets/content/signs/muerte/SEÑA_M_09.png'
    },
    PHOTO_PIOJO_BLANCO : {
        key : 'SEÑA_M_10',
        path : './funcaracAssets/content/signs/muerte/SEÑA_M_10.png'
    },
    PHOTO_CUSCUNGO : {
        key : 'SEÑA_M_11',
        path : './funcaracAssets/content/signs/muerte/SEÑA_M_11.png'
    },
}

export const goodLuckSignsResources = {
    PHOTO_ARAÑA_KIPI : {
        key : 'SEÑA_BS_01',
        path : './funcaracAssets/content/signs/buena suerte/SEÑA_BS_01.png'
    },
    PHOTO_DURAZNO : {
        key : 'SEÑA_BS_02',
        path : './funcaracAssets/content/signs/buena suerte/SEÑA_BS_02.png'
    },
    PHOTO_CATZO : {
        key : 'SEÑA_BS_03',
        path : './funcaracAssets/content/signs/buena suerte/SEÑA_BS_03.png'
    },
    PHOTO_FREJOL : {
        key : 'SEÑA_BS_04',
        path : './funcaracAssets/content/signs/buena suerte/SEÑA_BS_04.png'
    },
    PHOTO_SALTO_HABAS : {
        key : 'SEÑA_BS_05',
        path : './funcaracAssets/content/signs/buena suerte/SEÑA_BS_05.png'
    },
    PHOTO_CERRO_FRUTOS : {
        key : 'SEÑA_BS_06',
        path : './funcaracAssets/content/signs/buena suerte/SEÑA_BS_06.png'
    },
    PHOTO_PICAFLOR : {
        key : 'SEÑA_BS_07',
        path : './funcaracAssets/content/signs/buena suerte/SEÑA_BS_07.png'
    },
    PHOTO_FLORES_TUNAS : {
        key : 'SEÑA_BS_08',
        path : './funcaracAssets/content/signs/buena suerte/SEÑA_BS_08.png'
    }
}

export const badLuckSignsResources = {
    PHOTO_HORMIGAS : {
        key : 'SEÑA_MS_01',
        path : './funcaracAssets/content/signs/mala suerte/SEÑA_MS_01.png'
    },
    PHOTO_HABAS_TIESTO : {
        key : 'SEÑA_MS_02',
        path : './funcaracAssets/content/signs/mala suerte/SEÑA_MS_02.png'
    },
    PHOTO_ZAPALLO : {
        key : 'SEÑA_MS_03',
        path : './funcaracAssets/content/signs/mala suerte/SEÑA_MS_03.png'
    },
    PHOTO_RATAS : {
        key : 'SEÑA_MS_04',
        path : './funcaracAssets/content/signs/mala suerte/SEÑA_MS_04.png'
    },
    PHOTO_SUEÑO_GRITO : {
        key : 'SEÑA_MS_05',
        path : './funcaracAssets/content/signs/mala suerte/SEÑA_MS_05.png'
    },
    PHOTO_ZAMBO : {
        key : 'SEÑA_MS_06',
        path : './funcaracAssets/content/signs/mala suerte/SEÑA_MS_06.png'
    },
    PHOTO_GARZA : {
        key : 'SEÑA_MS_07',
        path : './funcaracAssets/content/signs/mala suerte/SEÑA_MS_07.png'
    },
    PHOTO_LAGARTIJA : {
        key : 'SEÑA_MS_08',
        path : './funcaracAssets/content/signs/mala suerte/SEÑA_MS_08.png'
    },
    PHOTO_PAJARO_MUSHU : {
        key : 'SEÑA_MS_09',
        path : './funcaracAssets/content/signs/mala suerte/SEÑA_MS_09.png'
    },
    PHOTO_YUGO : {
        key : 'SEÑA_MS_10',
        path : './funcaracAssets/content/signs/mala suerte/SEÑA_MS_10.png'
    }
}

export const weatherSignsResources = {
    PHOTO_ARCO_IRIS_ORIENTE : {
        key : 'SEÑA_T_01',
        path : './funcaracAssets/content/signs/tiempo/SEÑA_T_01.png'
    },
    PHOTO_ESTRELLAS_DESPEJADAS : {
        key : 'SEÑA_T_02',
        path : './funcaracAssets/content/signs/tiempo/SEÑA_T_02.png'
    },
    PHOTO_MORTIÑO : {
        key : 'SEÑA_T_03',
        path : './funcaracAssets/content/signs/tiempo/SEÑA_T_03.png'
    },
    PHOTO_APU_SAL : {
        key : 'SEÑA_T_04',
        path : './funcaracAssets/content/signs/tiempo/SEÑA_T_04.png'
    },
    PHOTO_RELAMPAGOS : {
        key : 'SEÑA_T_05',
        path : './funcaracAssets/content/signs/tiempo/SEÑA_T_05.png'
    },
    PHOTO_HURACAN : {
        key : 'SEÑA_T_06',
        path : './funcaracAssets/content/signs/tiempo/SEÑA_T_06.png'
    },
    PHOTO_ARCO_IRIS_AIRE : {
        key : 'SEÑA_T_07',
        path : './funcaracAssets/content/signs/tiempo/SEÑA_T_07.png'
    },
    PHOTO_ARCO_IRIS_TIERRA : {
        key : 'SEÑA_T_08',
        path : './funcaracAssets/content/signs/tiempo/SEÑA_T_08.png'
    },
    PHOTO_JAMBATOS : {
        key : 'SEÑA_T_09',
        path : './funcaracAssets/content/signs/tiempo/SEÑA_T_09.png'
    },
    PHOTO_WIRAKCHURUS : {
        key : 'SEÑA_T_10',
        path : './funcaracAssets/content/signs/tiempo/SEÑA_T_10.png'
    },
}