import React,{Component} from 'react';
import { Button, Card } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import { connect } from 'react-redux';

const CARD_STYLE = { 
    width : '18rem', position: 'absolute', 
    left: '50%', top: '50%', transform: 'translate(-50%, -50%)'
};

class IntroPage extends Component{  
    
    constructor(props){
        super(props);
        this.state = {
            tales : this.props.tales
        }
    }
    
    render(){
        return this.createPage();
    }

    createPage(){
        return (<div style={CARD_STYLE}>
          {this.addButtonsCard()}
        </div>)
    }

    addSignInButton(){
        return (
            <NavLink to = '/signin'>
            <Button variant = "primary" style={{width:'100%'}}>¡Registrate!</Button>
            </NavLink>
        );
    }

    addLogInButton(){
        return(
            <NavLink to = '/login'>
            <Button variant = "secondary" style={{width:'100%'}}>¡Inicia sesión!</Button>
            </NavLink>
        );
    }

    addButtonsCard(){
        return(
            <Card style = {{width : '18rem' }}>
                <Card.Body style = {{padding: "30px 10px 20px 20px"}}>
                    <Card.Title style = {{padding: "0px 10px 0px 50px"}}>Juego Funcarac</Card.Title>
                </Card.Body>
                <Card.Body style = {{padding:"0px 10px 0px 10px"}}>
                    {this.addSignInButton()}
                </Card.Body>
                <Card.Body style = {{padding:"10px 10px 15px 10px"}}>
                    {this.addLogInButton()}
                </Card.Body>
            </Card>
        );
    }

    showList(){
        let {tales} = this.props;
        return(
        <div className = 'talesList'>
            <h1>Tales</h1>
            <div>
                {this.showTale(tales)}
            </div>
        </div>)
    }

    showTale(tales){
        return (
        <ul className = 'listGroup'>
            {
                tales.map(tale =>
                <li className = 'group-list-item' key = {tale.id}>
                    {tale.title}
                </li>)
            }  
        </ul>
        );
    }
}

function mapStateToProps (state ,ownProps){
    return {
    }
}

// IntroPage.propTypes = {
//     tales : PropTypes.array.isRequired
// };

export default connect(mapStateToProps,{})(IntroPage);