import Box from "../baseClasses/Box";
import { caracResources, chakraResources } from "../../utils/assetsPath";
import { gridAlignGroupElements, addChildrenToContainer } from "../../utils/functions";
import Label from "../baseClasses/Label";

export default class PiecesBox extends Box{
    constructor(scene,x,y){
        super(scene,x,y,caracResources.PIECES_BOX.key);
        this.addBox(scene);
        this.addPlaque(scene);
        this.addCarrouselArrows(scene);
        this.hideArrows();
        this.displayBox(scene);
    }

    addCarrouselArrows (scene) {
        this.upArrow = scene.add.image(0,0,caracResources.ARROW.key);
        this.upArrow.setInteractive({useHandCursor: true});
        this.downArrow = scene.add.image(0,0,caracResources.ARROW.key);
        this.downArrow.setFlipY(180).setInteractive({useHandCursor: true});
        let group = scene.add.group([this.upArrow,this.downArrow]);
        gridAlignGroupElements(scene,group,{width:1,height:2,cellHeight:630, position:6,y:-240});
        addChildrenToContainer(this,group.getChildren());
        this.animateArrows(scene);
    }

    addPlaque(scene){
        this.plaque = new Label(scene,0,0,chakraResources.TOOLS_BOX_PLAQUE.key,'PIEZAS DEL CALENDARIO\nDISPONIBLES');
        this.plaque.body.setDisplaySize(this.box.width*0.7,this.box.height*0.17);
        this.plaque.text.setStroke(0x000,1).setFontSize(17);
        this.plaque.setPosition(0,-this.box.height*0.39);
        this.add(this.plaque);
      }

    hideArrows(){
        this.upArrow.setVisible(false).disableInteractive();
        this.downArrow.setVisible(false).disableInteractive();
    }

    showArrows(){
        this.upArrow.setVisible(true).setInteractive();
        this.downArrow.setVisible(true).setInteractive();
    }

    arrowScaleUp(scene,arrow){
        scene.tweens.add({targets : arrow, scale : {from: 1, to : 1.1}, duration : 200});
    }

    arrowScaleDown(scene,arrow){
        scene.tweens.add({targets : arrow, scale : {from : 1.1, to : 1}, duration : 200});
    }

    animateArrows(scene){
        this.upArrow.on('pointerover',()=>{this.arrowScaleUp(scene,this.upArrow)});
        this.upArrow.on('pointerout',()=>{this.arrowScaleDown(scene,this.upArrow)});

        this.downArrow.on('pointerover',()=>{this.arrowScaleUp(scene,this.downArrow)});
        this.downArrow.on('pointerout',()=>{this.arrowScaleDown(scene,this.downArrow)});
    }

}