import WindowModal from "../baseClasses/windowModal";
import { numbers, toolRewardData } from "../../utils/definitions";
import { mainUIResources, coverPageResources } from "../../utils/assetsPath";
import { gridAlignGroupElements, addChildrenToContainer } from "../../utils/functions";
import { scenesKey } from "../../utils/gameConstants";
import { updatePuzzlePieceEarnedStatus } from "../../../../services/gameServices/piecesService";
import { updatePlayerToolRecord } from "../../../../services/gameServices/toolsService";
import { updatePlayerSeedEarnedStatus } from "../../../../services/gameServices/seedService";
import Tale from "../baseClasses/tale";
import { mainTalesList } from "../../utils/information/tales/talesInformation";
import { changePieceRecordEarnedStatus, changeSeedRecordEarnedStatus, changeToolRecord, changeTaleRecordStatus } from "../../utils/functions/save-record-functions";

export default class RewardModal extends WindowModal{
    constructor(scene,x,y){
        super(scene,x,y);
        this.rewardPic = null;
        this.multiplier = null;
        this.quantity = null;
        this.buildModal(scene);
    }

    buildModal(scene){
        this.setBody(scene);
        this.setCongratulationRibbon(scene);
        this.setButton(scene);
        this.createSounds(scene);
        scene.add.existing(this);
    }

    newToolReward(scene, message){
        this.playMusic();
        this.setCongratulationText(scene,message);
        let reward = toolRewardData[Math.floor(Math.random() * toolRewardData.length)];
        this.rewardPic = scene.add.image(0,0,reward.texture);
        this.rewardPic.setScale(0.8);
        this.multiplier = scene.add.image(0,0,mainUIResources.MULTIPLIER.key);
        let randomNum = Math.floor(Math.random() * 6 ) + 1;
        this.quantity = scene.add.image(0,0,numbers[randomNum].key);

        let group  = scene.add.group([this.rewardPic,this.multiplier,this.quantity]);
        let gridConfig = {
            width:3,
            height:1,
            cellWidth:180,
            position:6,
            x:-160,
            y:80
        }
        gridAlignGroupElements(scene,group,gridConfig);
        addChildrenToContainer(this,group.getChildren());

        changeToolRecord(reward.toolId,randomNum);
        this.passToolsUnits(scene,reward,randomNum);

        this.setButtonActions(scene,()=>{this.emitMessage(scene)});
    }

    emitMessage(scene){
        this.destroy();
        let caracScene = scene.scene.get(scenesKey.CARAC_SCENE);
        if(!caracScene.checkMonthsStatus()){
            caracScene.emitToMainUI('puzzle_placed')
        }
    }

    newSeedReward(scene, message, seed){
        this.setButtonActions(scene,()=>{
            this.destroy();
            let caracScene = scene.scene.get(scenesKey.CARAC_SCENE);
            caracScene.emitToMainUI('new_seed_earned');
        });
        this.playMusic();
        this.setCongratulationText(scene,message);
        this.rewardPic = scene.add.image(0,100,seed.rewardKey);
        this.rewardPic.setScale(1.1);
        this.add(this.rewardPic);
        this.passSeed(scene,seed);
    }

    newPuzzlePieceReward( scene , message , data){
        changePieceRecordEarnedStatus(data.piecedata.id);
        this.setCongratulationText(scene,message);
        this.text.setFontSize(68);
        this.puzzlePiece = data.piece;
        this.puzzlePiece.setScale(1.6).setPosition(0,50);
        this.add(this.puzzlePiece);

        this.setButtonActions(scene,()=>{this.passPuzzlePiece(scene,data.results)});
        this.playMusic();
    }

    newBookPriceReward (scene, message){
        this.setCongratulationText(scene,message);
        this.text.setFontSize(68);
        this.rewardPic = scene.add.image(0,100,coverPageResources.PAGE_PRICE.key);
        this.add(this.rewardPic);
        this.setButtonActions(scene);
        this.playMusic();
    }

    unlockTaleReward(scene,message,data){
        this.setButtonActions(scene,()=>{
            this.destroy();
            let chakraScene = scene.scene.get(scenesKey.CHAKRA_SCENE);
            chakraScene.emitToMainUI('tale_unlocked');
        });
        this.playMusic();
        this.setCongratulationText(scene,message);
        this.createTaleReward(scene,data);
        this.changeTaleRecord(scene,data);
    }


    passToolsUnits(scene,reward,number){
        let chakraScene = scene.scene.get(scenesKey.CHAKRA_SCENE);
        chakraScene.events.emit('addUnits',[reward.tool,number]);
    }

    passSeed(scene,seed){
        changeSeedRecordEarnedStatus(seed.id,true);
        let chakraScene = scene.scene.get(scenesKey.CHAKRA_SCENE);
        chakraScene.events.emit('newSeed',seed);
    }

    passPuzzlePiece(scene, result){
        this.remove(this.puzzlePiece);
        let selectedTaleScene = scene.scene.get(localStorage.getItem('selectedTale'));
        selectedTaleScene.events.emit('result_modal_closed');
        scene.scene.switch(localStorage.getItem('selectedTale'));

        localStorage.removeItem('selectedQuiz');
        localStorage.setItem('newScore',JSON.stringify(result));

        selectedTaleScene.events.emit('update_quiz_score');
    }


    changePieceRecordStatus(puzzlePieceId){
        let pieceRecords = JSON.parse(localStorage.getItem('pieceRecords')),
            oldRecord = pieceRecords.find((record)=>{return record.puzzle_piece_id === puzzlePieceId}),
            index = pieceRecords.indexOf(oldRecord),
            loggedPlayer = JSON.parse(localStorage.getItem('loggedPlayer')),
            params = {playerid : loggedPlayer.id, earned : 1};

        updatePuzzlePieceEarnedStatus(puzzlePieceId,params).then(response => {
            let newRecord = response.data[0];
            pieceRecords[index] = newRecord;
            localStorage.setItem('pieceRecords',JSON.stringify(pieceRecords));
        })
    }

    changeToolRecord(toolId, quantity){
        let toolRecords = JSON.parse(localStorage.getItem('toolRecords')),
            oldRecord = toolRecords.find((record)=>{return record.tool_id === toolId}),
            oldQuantity = oldRecord.quantity,
            index = toolRecords.indexOf(oldRecord),
            loggedPlayer = JSON.parse(localStorage.getItem('loggedPlayer')),
            params = {playerid : loggedPlayer.id, quantity: (oldQuantity + quantity)};

        updatePlayerToolRecord(toolId, params).then(response => {
            let newRecord = response.data[0];
            console.log('registro cambiado: ',newRecord);
            toolRecords[index] = newRecord;
            localStorage.setItem('toolRecords',JSON.stringify(toolRecords));
        })
    }

    changeSeedRecord(seedId, status){
        let seedRecords = JSON.parse(localStorage.getItem('seedRecords')),
            oldRecord = seedRecords.find((record)=>{return record.seed_id === seedId}),
            index = seedRecords.indexOf(oldRecord),
            loggedPlayer = JSON.parse(localStorage.getItem('loggedPlayer')),
            params = {playerid : loggedPlayer.id, earned : status};

        if(oldRecord.earned === 0){
            updatePlayerSeedEarnedStatus(seedId,params).then(response => {
                let newRecord = response.data[0];
                console.log('registro cambiado: ',newRecord);
                seedRecords[index]=newRecord;
                localStorage.setItem('seedRecords',JSON.stringify(seedRecords));
            })
        }
    }

    createTaleReward(scene, idTale){
        let taleData = mainTalesList.find(taleInfo =>{ return taleInfo.id === idTale} ),
            unlockTale = new Tale(scene,0,0,taleData,'guide'),
            container = scene.add.container(0,100,[unlockTale]);
        unlockTale.setStatus('seen');
        unlockTale.checkStatus();
        container.setSize(unlockTale.width, unlockTale.height).setScale(1.3);
        this.add(container);
    }

    createPuzzleTitleLabel(scene,title){
        let container = scene.add.container(0,0),
            text = scene.add.text(0,0,title,{fontFamily:'Helvetica',
            fontSize:'30px',
            color:'#000000',
            align:'center'});
        container.setSize(text.width,text.height);
        text.setOrigin(0.5);
        container.add([text]);
        this.add(container);
    }

    changeTaleRecord(scene, idTale){
        let libraryScene = scene.scene.get(scenesKey.LIBRARY_SCENE);
        changeTaleRecordStatus(idTale,"new",libraryScene.getLibrary().talesList);
        libraryScene.getLibrary().setTaleActions(scene);
    }
}