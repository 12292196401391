import FormScene from "../baseScenes/formScene";
import { scenesKey } from "../../../utils/gameConstants";
import { domResources } from "../../../utils/assetsPath";
import { getPlayer } from "../../../../../services/reactServices/playerService";
import { getPlayerTaleRecords } from "../../../../../services/gameServices/talesService";
import { getPlayerToolRecords } from "../../../../../services/gameServices/toolsService";
import { getPlayerSeedRecords } from "../../../../../services/gameServices/seedService";
import { getPlayerPuzzlePiecesRecords } from "../../../../../services/gameServices/piecesService";
import { getPlayerQuizRecords } from "../../../../../services/gameServices/quizService";
import { getPlayerChakraRecords } from "../../../../../services/gameServices/chakraService";

const inputFields = {
    usuario : { name : 'grupo__usuario', status : false },
    password : { name : 'grupo__password', status : false }
}

const expresiones = {
    usuario : /^[a-zA-Z0-9\_\-]{4,16}$/, // Letras, numeros, guion y guion_bajo,
    password : /^.{4,12}$/ // 4 a 12 digitos.
}

export default class LogInScene extends FormScene{
    constructor(){
        super({key : scenesKey.LOGIN_SCENE});
        this.setFormData(domResources.FORM_LOGIN.key);
        this.setInitialProperties();
    }

    setUserNickName(nickname){ this.userNickName = nickname }
    getUserNickName(){ return this.userNickName }

    setUserPassword(password){ this.userPassword = password }
    getUserPassword(){ return this.userPassword }


    setInitialProperties(){
        this.setUserNickName(null);
        this.setUserPassword(null);
    }

    create(){
        this.buildScene();
        this.activeForm();
        this.activeInputs();
    }

    buildScene(){
        this.addBackground();
        this.addForm();
        this.addGameTitle();
        this.zoomInBackground();
        this.createReturnOption();
        this.redirectScene();
    }

    redirectScene(){
        let returnOption = this.getReturnOption().getAll()[0];
        returnOption.on('pointerup',()=>{
            this.scene.start(scenesKey.WELCOME_SCENE);
        })
    }

    activeForm(){
        let form = this.getForm();
        form.addListener("submit");
        form.on("submit",(event)=>{
            event.preventDefault();
            if(inputFields.usuario.status && inputFields.password.status){
                let credentials = this.setInputValues();
                this.sendToDatabase(credentials);
               this.resetForm();
               document.getElementById('formulario__mensaje-exito').classList.add('formulario__mensaje-exito-activo');
               document.getElementById('formulario__mensaje').classList.remove('formulario__mensaje-activo');
            //    setTimeout(()=>{document.getElementById('formulario__mensaje-exito').classList.remove('formulario__mensaje-exito-activo')},2000);   
            }else{
                document.getElementById('formulario__mensaje').classList.add('formulario__mensaje-activo');
            }
        })
    }

    activeInputs(){
        let inputsList = document.querySelectorAll('#formulario input');

        inputsList.forEach(input => {
            input.addEventListener('keyup',()=>{
                this.validateInput(input);
            })

            input.addEventListener('blur',()=>{
                this.validateInput(input);
            })
        })
    }

    validateInput(input){
        let inputName = input.name, inputValue = input.value;

        switch(inputName){
            case 'usuario':
                this.validateField(expresiones.usuario,inputValue,inputFields.usuario);
            break;

            case 'password':
                this.validateField(expresiones.password,inputValue,inputFields.password);
            break;

            default:
            break;
        }
    }

    validateField(expression, inputValue, inputField){
        let inputName = inputField.name;
        if(expression.test(inputValue)){
            inputField.status = true;
            document.getElementById(inputName).classList.add('formulario__grupo-correcto');
            document.getElementById(inputName).classList.remove('formulario__grupo-incorrecto');

            document.querySelector('#'+inputName+' i').classList.add('fa-check-circle');
            document.querySelector('#'+inputName+' i').classList.remove('fa-times-circle');

            document.querySelector('#'+inputName+' .formulario__input-error').classList.remove('formulario__input-error-activo');

        }else{
            inputField.status = false;
            document.getElementById(inputName).classList.add('formulario__grupo-incorrecto');
            document.getElementById(inputName).classList.remove('formulario__grupo-correcto');

            document.querySelector('#'+inputName+' i').classList.add('fa-times-circle');
            document.querySelector('#'+inputName+' i').classList.remove('fa-check-circle');

            document.querySelector('#'+inputName+' .formulario__input-error').classList.add('formulario__input-error-activo');
        }
    }

    setInputValues(){
        let inputsList = document.querySelectorAll('#formulario input');
        inputsList.forEach(input => {
            switch(input.name){
                case 'usuario':
                    this.setUserNickName(input.value);
                break;

                case 'password':
                    this.setUserPassword(input.value);
                break;

                default :
                break;
            }
        })

        return {nickname : this.getUserNickName(), password : this.getUserPassword()};
    }

    sendToDatabase(credentials){
        getPlayer(credentials).then(response => {
            if(response.data.length>0){
                let player = response.data[0],
                    playerId = player.id;
                localStorage.setItem('loggedPlayer',JSON.stringify(player));
                this.loadGameRecords(playerId);
                this.checkUploadedGameRecords();
            }else{
                alert('Usuario no encontrado');
            }
        })
    }

    resetForm(){
        let formulario = document.getElementById('formulario');
        formulario.reset();

        //clean inputs status
        let inputsData = [Object.values(inputFields)];
        inputsData.forEach(input => {input.status = false});

        document.querySelectorAll('.formulario__grupo-correcto').forEach(icono => {
            icono.classList.remove('formulario__grupo-correcto');
        })

        this.resetFormValues();
    }

    resetFormValues(){
        this.setUserNickName(null);
        this.setUserPassword(null);
    }

    loadPlayerTaleRecords(idPlayer){
        getPlayerTaleRecords(idPlayer).then(response => {
            let talesRecords = response.data;
            localStorage.setItem('taleRecords',JSON.stringify(talesRecords));
        })
    }

    loadPlayerToolRecords(idPlayer){
        getPlayerToolRecords(idPlayer).then(response => {
            let toolRecords = response.data;
            localStorage.setItem('toolRecords',JSON.stringify(toolRecords));
        })
    }

    loadPlayerSeedRecords(idPlayer){
        getPlayerSeedRecords(idPlayer).then(response => {
            let seedRecords = response.data;
            localStorage.setItem('seedRecords',JSON.stringify(seedRecords));
        })
    }

    loadPlayerPieceRecords(idPlayer){
        getPlayerPuzzlePiecesRecords(idPlayer).then(response => {
            let pieceRecords = response.data;
            localStorage.setItem('pieceRecords',JSON.stringify(pieceRecords));
        })
    }

    loadPlayerQuizRecords(idPlayer){
        getPlayerQuizRecords(idPlayer).then(response =>{
            let quizRecords = response.data;
            localStorage.setItem('quizRecords',JSON.stringify(quizRecords));
        })
    }

    loadPlayerChakraRecords(idPlayer){
        getPlayerChakraRecords(idPlayer).then(response => {
            let chakraRecords = response.data;
            localStorage.setItem('chakraRecords',JSON.stringify(chakraRecords));
        })
    }

    loadGameRecords(idPlayer){
        this.loadPlayerTaleRecords(idPlayer);
        this.loadPlayerToolRecords(idPlayer);
        this.loadPlayerSeedRecords(idPlayer);
        this.loadPlayerPieceRecords(idPlayer);
        this.loadPlayerQuizRecords(idPlayer);
        this.loadPlayerChakraRecords(idPlayer);
    }

    checkUploadedGameRecords(){
        if(localStorage.hasOwnProperty('loggedPlayer') && 
           localStorage.hasOwnProperty('taleRecords') && 
           localStorage.hasOwnProperty('quizRecords') &&
           localStorage.hasOwnProperty('seedRecords') &&
           localStorage.hasOwnProperty('pieceRecords') &&
           localStorage.hasOwnProperty('toolRecords') &&
           localStorage.hasOwnProperty('chakraRecords')){
               this.goToGameMenu();
           }else{
               console.log('aun no están cargados las listas');
               setTimeout(()=>{this.checkUploadedGameRecords()},500);
           }
    }

    goToGameMenu(){
        this.scene.start(scenesKey.GAME_MENU_SCENE);
    }
}