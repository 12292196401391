export const chuklluKamllaResources = {
    COVER : {
        'key' : 'CHUKLLU_KAMLLA_P',
        'path' : './funcaracAssets/content/food/chukllu kamlla/CHUKLLA_KAMLLA_P.png'
    },
    CHUKLLU_KAMLLA_01 : {
        'key' : 'CHUKLLU_KAMLLA_01',
        'path' : './funcaracAssets/content/food/chukllu kamlla/CHUKLLA_KAMLLA_01.png'
    },
    CHUKLLU_KAMLLA_02 : {
        'key' : 'CHUKLLU_KAMLLA_02',
        'path' : './funcaracAssets/content/food/chukllu kamlla/CHUKLLA_KAMLLA_02.png'
    },
    CHUKLLU_KAMLLA_03 : {
        'key' : 'CHUKLLU_KAMLLA_03',
        'path' : './funcaracAssets/content/food/chukllu kamlla/CHUKLLA_KAMLLA_03.png'
    },
    CHUKLLU_KAMLLA_04 : {
        'key' : 'CHUKLLU_KAMLLA_04',
        'path' : './funcaracAssets/content/food/chukllu kamlla/CHUKLLA_KAMLLA_04.png'
    }
}

export const milichakiResources = {
    COVER : {
        'key' : 'MILICHAKI_P',
        'path' : './funcaracAssets/content/food/milichaki/MILICHAKI_P.png'
    },
    MILICHAKI_01 : {
        'key' : 'MILICHAKI_01',
        'path' : './funcaracAssets/content/food/milichaki/MILICHAKI_01.png'
    },
    MILICHAKI_02 : {
        'key' : 'MILICHAKI_02',
        'path' : './funcaracAssets/content/food/milichaki/MILICHAKI_02.png'
    },
    MILICHAKI_03 : {
        'key' : 'MILICHAKI_03',
        'path' : './funcaracAssets/content/food/milichaki/MILICHAKI_03.png'
    },
    MILICHAKI_04 : {
        'key' : 'MILICHAKI_04',
        'path' : './funcaracAssets/content/food/milichaki/MILICHAKI_04.png'
    },
    MILICHAKI_05 : {
        'key' : 'MILICHAKI_05',
        'path' : './funcaracAssets/content/food/milichaki/MILICHAKI_05.png'
    },
    MILICHAKI_06 : {
        'key' : 'MILICHAKI_06',
        'path' : './funcaracAssets/content/food/milichaki/MILICHAKI_07.png'
    },
    MILICHAKI_07 : {
        'key' : 'MILICHAKI_07',
        'path' : './funcaracAssets/content/food/milichaki/MILICHAKI_07.png'
    },
    MILICHAKI_08 : {
        'key' : 'MILICHAKI_08',
        'path' : './funcaracAssets/content/food/milichaki/MILICHAKI_08.png'
    }
}

export const mishkiZamboResources = {
    COVER : {
        'key' : 'MISHKI_ZAMBU_P',
        'path' : './funcaracAssets/content/food/mishki zambo/MISHKI_ZAMBU_P.png'
    },
    MISHKI_ZAMBU_01 : {
        'key' : 'MISHKI_ZAMBU_01',
        'path' : './funcaracAssets/content/food/mishki zambo/MISHKI_ZAMBU_01.png'
    },
    MISHKI_ZAMBU_02 : {
        'key' : 'MISHKI_ZAMBU_02',
        'path' : './funcaracAssets/content/food/mishki zambo/MISHKI_ZAMBU_02.png'
    },
    MISHKI_ZAMBU_03 : {
        'key' : 'MISHKI_ZAMBU_03',
        'path' : './funcaracAssets/content/food/mishki zambo/MISHKI_ZAMBU_03.png'
    },
    MISHKI_ZAMBU_04 : {
        'key' : 'MISHKI_ZAMBU_04',
        'path' : './funcaracAssets/content/food/mishki zambo/MISHKI_ZAMBU_04.png'
    },
    MISHKI_ZAMBU_05 : {
        'key' : 'MISHKI_ZAMBU_05',
        'path' : './funcaracAssets/content/food/mishki zambo/MISHKI_ZAMBU_05.png'
    }
}

export const uchukutaResources = {
    COVER : {
        'key' : 'UCHUKUTA_P',
        'path' : './funcaracAssets/content/food/uchukuta/UCHUKUTA_P.png'
    },
    UCHUKUTA_01 : {
        'key' : 'UCHUKUTA_01',
        'path' : './funcaracAssets/content/food/uchukuta/UCHUKUTA_01.png'
    },
    UCHUKUTA_02 : {
        'key' : 'UCHUKUTA_02',
        'path' : './funcaracAssets/content/food/uchukuta/UCHUKUTA_02.png'
    },
    UCHUKUTA_03 : {
        'key' : 'UCHUKUTA_03',
        'path' : './funcaracAssets/content/food/uchukuta/UCHUKUTA_03.png'
    },
    UCHUKUTA_04 : {
        'key' : 'UCHUKUTA_04',
        'path' : './funcaracAssets/content/food/uchukuta/UCHUKUTA_04.png'
    },
    UCHUKUTA_05 : {
        'key' : 'UCHUKUTA_05',
        'path' : './funcaracAssets/content/food/uchukuta/UCHUKUTA_05.png'
    }
}

export const mutiLuciResources = {
    COVER : {
        'key' : 'MUTI_LUCI_P',
        'path' : './funcaracAssets/content/food/muti luci/MUTI_LUCI_P.png'
    },
    MUTI_LUCI_01 : {
        'key' : 'MUTI_LUCI_01',
        'path' : './funcaracAssets/content/food/muti luci/MUTI_LUCI_01.png'
    },
    MUTI_LUCI_02 : {
        'key' : 'MUTI_LUCI_02',
        'path' : './funcaracAssets/content/food/muti luci/MUTI_LUCI_02.png'
    },
    MUTI_LUCI_03 : {
        'key' : 'MUTI_LUCI_03',
        'path' : './funcaracAssets/content/food/muti luci/MUTI_LUCI_03.png'
    },
    MUTI_LUCI_04 : {
        'key' : 'MUTI_LUCI_04',
        'path' : './funcaracAssets/content/food/muti luci/MUTI_LUCI_04.png'
    },
}

export const champusResources = {
    COVER : {
        'key' : 'CHAMPUS_P',
        'path' : './funcaracAssets/content/food/champus/CHAMPUS_P.png'
    },
    CHAMPUS_01 : {
        'key' : 'CHAMPUS_01',
        'path' : './funcaracAssets/content/food/champus/CHAMPUS_01.png'
    },
    CHAMPUS_02 : {
        'key' : 'CHAMPUS_02',
        'path' : './funcaracAssets/content/food/champus/CHAMPUS_02.png'
    },
    CHAMPUS_03 : {
        'key' : 'CHAMPUS_03',
        'path' : './funcaracAssets/content/food/champus/CHAMPUS_03.png'
    },
    CHAMPUS_04 : {
        'key' : 'CHAMPUS_04',
        'path' : './funcaracAssets/content/food/champus/CHAMPUS_04.png'
    },
    CHAMPUS_05 : {
        'key' : 'CHAMPUS_05',
        'path' : './funcaracAssets/content/food/champus/CHAMPUS_05.png'
    },
    CHAMPUS_06 : {
        'key' : 'CHAMPUS_06',
        'path' : './funcaracAssets/content/food/champus/CHAMPUS_06.png'
    },
    CHAMPUS_07 : {
        'key' : 'CHAMPUS_07',
        'path' : './funcaracAssets/content/food/champus/CHAMPUS_07.png'
    }
}

export const makiMachikaResources = {
    COVER : {
        'key' : 'MAKI_MACHIKA_P',
        'path' : './funcaracAssets/content/food/maki machika/MAKI_MACHIKA_P.png'
    },
    MAKI_MACHIKA_01 : {
        'key' : 'MAKI_MACHIKA_01',
        'path' : './funcaracAssets/content/food/maki machika/MAKI_MACHIKA_01.png'
    },
    MAKI_MACHIKA_02 : {
        'key' : 'MAKI_MACHIKA_02',
        'path' : './funcaracAssets/content/food/maki machika/MAKI_MACHIKA_02.png'
    },
    MAKI_MACHIKA_03 : {
        'key' : 'MAKI_MACHIKA_03',
        'path' : './funcaracAssets/content/food/maki machika/MAKI_MACHIKA_03.png'
    },
    MAKI_MACHIKA_04 : {
        'key' : 'MAKI_MACHIKA_04',
        'path' : './funcaracAssets/content/food/maki machika/MAKI_MACHIKA_04.png'
    },
    MAKI_MACHIKA_05 : {
        'key' : 'MAKI_MACHIKA_05',
        'path' : './funcaracAssets/content/food/maki machika/MAKI_MACHIKA_05.png'
    },
    MAKI_MACHIKA_06 : {
        'key' : 'MAKI_MACHIKA_06',
        'path' : './funcaracAssets/content/food/maki machika/MAKI_MACHIKA_06.png'
    },
    MAKI_MACHIKA_07 : {
        'key' : 'MAKI_MACHIKA_07',
        'path' : './funcaracAssets/content/food/maki machika/MAKI_MACHIKA_07.png'
    },
    MAKI_MACHIKA_08 : {
        'key' : 'MAKI_MACHIKA_08',
        'path' : './funcaracAssets/content/food/maki machika/MAKI_MACHIKA_08.png'
    }
}

export const mishkiKamllaResources = {
    COVER : {
        'key' : 'MISHKI_KAMLLA_P',
        'path' : './funcaracAssets/content/food/mishki kamlla/MISHKI_KAMLLA_P.png'
    },
    MISHKI_KAMLLA_01 : {
        'key' : 'MISHKI_KAMLLA_01',
        'path' : './funcaracAssets/content/food/mishki kamlla/MISHKI_KAMLLA_01.png'
    },
    MISHKI_KAMLLA_02 : {
        'key' : 'MISHKI_KAMLLA_02',
        'path' : './funcaracAssets/content/food/mishki kamlla/MISHKI_KAMLLA_02.png'
    },
    MISHKI_KAMLLA_03 : {
        'key' : 'MISHKI_KAMLLA_03',
        'path' : './funcaracAssets/content/food/mishki kamlla/MISHKI_KAMLLA_03.png'
    },
    MISHKI_KAMLLA_04 : {
        'key' : 'MISHKI_KAMLLA_04',
        'path' : './funcaracAssets/content/food/mishki kamlla/MISHKI_KAMLLA_04.png'
    },
    MISHKI_KAMLLA_05 : {
        'key' : 'MISHKI_KAMLLA_05',
        'path' : './funcaracAssets/content/food/mishki kamlla/MISHKI_KAMLLA_05.png'
    }
}

export const yamurAsuaResources = {
    COVER : {
        'key' : 'YAMUR_ASUA_P',
        'path' : './funcaracAssets/content/food/yamur asua/YAMUR_ASUA_P.png'
    },
    YAMUR_ASUA_01 : {
        'key' : 'YAMUR_ASUA_01',
        'path' : './funcaracAssets/content/food/yamur asua/YAMUR_ASUA_01.png'
    },
    YAMUR_ASUA_02 : {
        'key' : 'YAMUR_ASUA_02',
        'path' : './funcaracAssets/content/food/yamur asua/YAMUR_ASUA_02.png'
    },  
    YAMUR_ASUA_03 : {
        'key' : 'YAMUR_ASUA_03',
        'path' : './funcaracAssets/content/food/yamur asua/YAMUR_ASUA_03.png'
    },
    YAMUR_ASUA_04 : {
        'key' : 'YAMUR_ASUA_04',
        'path' : './funcaracAssets/content/food/yamur asua/YAMUR_ASUA_04.png'
    },
    YAMUR_ASUA_05 : {
        'key' : 'YAMUR_ASUA_05',
        'path' : './funcaracAssets/content/food/yamur asua/YAMUR_ASUA_05.png'
    }
}

export const katzuKamllaResources = {
    COVER : {
        'key' : 'KATZU_KAMLLA_P',
        'path' : './funcaracAssets/content/food/katzu kamlla/KATZU_KAMLLA_P.png'
    },
    KATZU_KAMLLA_01 : {
        'key' : 'KATZU_KAMLLA_01',
        'path' : './funcaracAssets/content/food/katzu kamlla/KATZU_KAMLLA_01.png'
    },
    KATZU_KAMLLA_02 : {
        'key' : 'KATZU_KAMLLA_02',
        'path' : './funcaracAssets/content/food/katzu kamlla/KATZU_KAMLLA_02.png'
    },
    KATZU_KAMLLA_03 : {
        'key' : 'KATZU_KAMLLA_03',
        'path' : './funcaracAssets/content/food/katzu kamlla/KATZU_KAMLLA_03.png'
    },
    KATZU_KAMLLA_04 : {
        'key' : 'KATZU_KAMLLA_04',
        'path' : './funcaracAssets/content/food/katzu kamlla/KATZU_KAMLLA_04.png'
    }
}

export const yanaApiResources = {
    COVER : {
        'key' : 'YANA_API_P',
        'path' : './funcaracAssets/content/food/yana api/YANA_API_P.png'
    },
    YANA_API_01 : {
        'key' : 'YANA_API_01',
        'path' : './funcaracAssets/content/food/yana api/YANA_API_01.png'
    },
    YANA_API_02 : {
        'key' : 'YANA_API_02',
        'path' : './funcaracAssets/content/food/yana api/YANA_API_02.png'
    },
    YANA_API_03 : {
        'key' : 'YANA_API_03',
        'path' : './funcaracAssets/content/food/yana api/YANA_API_03.png'
    },
    YANA_API_04 : {
        'key' : 'YANA_API_04',
        'path' : './funcaracAssets/content/food/yana api/YANA_API_04.png'
    },
    YANA_API_05 : {
        'key' : 'YANA_API_05',
        'path' : './funcaracAssets/content/food/yana api/YANA_API_05.png'
    },
    YANA_API_06 : {
        'key' : 'YANA_API_06',
        'path' : './funcaracAssets/content/food/yana api/YANA_API_06.png'
    },
    YANA_API_07 : {
        'key' : 'YANA_API_07',
        'path' : './funcaracAssets/content/food/yana api/YANA_API_07.png'
    }
}

export const churuApiResources = {
    COVER : {
        'key' : 'CHURU_API_P',
        'path' : './funcaracAssets/content/food/churu api/CHURU_API_P.png'
    },
    CHURU_API_01 : {
        'key' : 'CHURU_API_01',
        'path' : './funcaracAssets/content/food/churu api/CHURU_API_01.png'
    },
    CHURU_API_02 : {
        'key' : 'CHURU_API_02',
        'path' : './funcaracAssets/content/food/churu api/CHURU_API_02.png'
    },
    CHURU_API_03 : {
        'key' : 'CHURU_API_03',
        'path' : './funcaracAssets/content/food/churu api/CHURU_API_03.png'
    },
    CHURU_API_04 : {
        'key' : 'CHURU_API_04',
        'path' : './funcaracAssets/content/food/churu api/CHURU_API_04.png'
    }
}
