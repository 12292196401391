export const secretsResourcesList = {
    PHOTO_SECRET_PAPA : {
        'key' : 'SECRETO_01',
        'path' : './funcaracAssets/content/secrets/SECRETO_01.png'
    },
    PHOTO_SECRET_MAIZ : {
        'key' : 'SECRETO_02',
        'path' : './funcaracAssets/content/secrets/SECRETO_02.png'
    },
    PHOTO_SECRET_CHACARERO : {
        'key' : 'SECRETO_03',
        'path' : './funcaracAssets/content/secrets/SECRETO_03.png'
    },
    PHOTO_SECRET_LADRONES : {
        'key' : 'SECRETO_04',
        'path' : './funcaracAssets/content/secrets/SECRETO_04.png'
    },
    PHOTO_SECRET_DEUDA : {
        'key' : 'SECRETO_05',
        'path' : './funcaracAssets/content/secrets/SECRETO_05.png'
    },
    PHOTO_SECRET_PLATITA : {
        'key' : 'SECRETO_06',
        'path' : './funcaracAssets/content/secrets/SECRETO_06.png'
    },
    PHOTO_SECRET_PLANTA_MEDICINAL : {
        'key' : 'SECRETO_07',
        'path' : './funcaracAssets/content/secrets/SECRETO_07.png'
    },
    PHOTO_SECRET_RECUPERAR_SALUD : {
        'key' : 'SECRETO_08',
        'path' : './funcaracAssets/content/secrets/SECRETO_08.png'
    },
    PHOTO_SECRET_ESPIRITU : {
        'key' : 'SECRETO_09',
        'path' : './funcaracAssets/content/secrets/SECRETO_09.png'
    },
    PHOTO_SECRET_SAL : {
        'key' : 'SECRETO_10',
        'path' : './funcaracAssets/content/secrets/SECRETO_10.png'
    },
    PHOTO_SECRET_BEBE_ARMONIA : {
        'key' : 'SECRETO_11',
        'path' : './funcaracAssets/content/secrets/SECRETO_11.png'
    },
    PHOTO_SECRET_BEBE_ABUNDANCIA : {
        'key' : 'SECRETO_12',
        'path' : './funcaracAssets/content/secrets/SECRETO_12.png'
    },
    PHOTO_SECRET_BEBE_FUERTE : {
        'key' : 'SECRETO_13',
        'path' : './funcaracAssets/content/secrets/SECRETO_13.png'
    },
    PHOTO_SECRET_SALIVA : {
        'key' : 'SECRETO_14',
        'path' : './funcaracAssets/content/secrets/SECRETO_14.png'
    },
    PHOTO_SECRET_TORO : {
        'key' : 'SECRETO_15',
        'path' : './funcaracAssets/content/secrets/SECRETO_15.png'
    },
    PHOTO_SECRET_ASUSTADA : {
        'key' : 'SECRETO_16',
        'path' : './funcaracAssets/content/secrets/SECRETO_16.png'
    },
    PHOTO_SECRET_PELEA_PERROS : {
        'key' : 'SECRETO_17',
        'path' : './funcaracAssets/content/secrets/SECRETO_17.png'
    },
    PHOTO_SECRET_MAL_OJO : {
        'key' : 'SECRETO_18',
        'path' : './funcaracAssets/content/secrets/SECRETO_18.png'
    },
    PHOTO_SECRET_HOJA_CULANTRO : {
        'key' : 'SECRETO_19',
        'path' : './funcaracAssets/content/secrets/SECRETO_19.png'
    },
    PHOTO_SECRET_BEBE_HABLA : {
        'key' : 'SECRETO_20',
        'path' : './funcaracAssets/content/secrets/SECRETO_20.png'
    },
    PHOTO_SECRET_PEPINO : {
        'key' : 'SECRETO_21',
        'path' : './funcaracAssets/content/secrets/SECRETO_21.png'
    },
    PHOTO_SECRET_ARENA : {
        'key' : 'SECRETO_22',
        'path' : './funcaracAssets/content/secrets/SECRETO_22.png'
    },
    PHOTO_SECRET_MOJAR_CAMA : {
        'key' : 'SECRETO_23',
        'path' : './funcaracAssets/content/secrets/SECRETO_23.png'
    },
    PHOTO_SECRET_PARTO : {
        'key' : 'SECRETO_24',
        'path' : './funcaracAssets/content/secrets/SECRETO_24.png'
    },

}