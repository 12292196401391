import Page from "../../../baseClasses/page";
import { gridAlignGroupElements, addChildrenToContainer } from "../../../../utils/functions";
import QuizPiecesBox from "../game-objects/quiz-pieces-box/quizPieceBox";

export default class PiecesPage extends Page{
    constructor(scene,x,y){
        super(scene,x,y,'PIEZAS\nCONSEGUIDAS');
        this.completedList = [];
        this.buildPage(scene);
        this.addPageContent(scene);
    }

    addPieceBoxes(scene){
        this.festivityBox = new QuizPiecesBox(scene,0,0,'red');
        this.foodBox  = new QuizPiecesBox(scene,0,0,'yellow');
        this.signsBox = new QuizPiecesBox(scene,0,0,'green');
        this.dialectsBox = new QuizPiecesBox(scene,0,0,'blue');
        this.boxesList = [this.festivityBox,this.foodBox,this.signsBox,this.dialectsBox];
        let group = scene.add.group(this.boxesList);
        group.getChildren().forEach((box)=>{
            box.setScale(0.8);
            box.on('pointerdown',()=>{
                box.changeStatus();
                box.checkPieceStatus();
            })
        });

        gridAlignGroupElements(scene,group,{width:1,height:group.getChildren().length,cellWidth:0,cellHeight:120,position:6,x:-80,y:-130});
        addChildrenToContainer(this,group.getChildren());
    }

    checkBoxes(scene, situation){
        this.boxesList.forEach((box)=>{
            this.isBoxReady(box);
            this.checkCompletedList(scene, situation);
        })
    }

    checkCompletedList(scene, situation){
        if(this.completedList.length===this.boxesList.length){
            let selectedTaleScene = scene.scene.get(localStorage.getItem('selectedTale'));
            let code;
            console.log(selectedTaleScene);
            switch(situation){
                case 'loading':
                    code = 'pieces_earned';
                break;

                case 'game-playing':
                    code = 'all_quizzes_completed';
                break;

                default :
                break;
            }
            selectedTaleScene.events.emit(code);
        }
    }

    


    addNewPuzzlePiece(piece,type){
        switch(type){
            case 'FESTIVIDADES':
                this.festivityBox.addPiece(piece);
                break;
            case 'COMIDAS':
                this.foodBox.addPiece(piece);
                break;
            case 'SEÑAS-SECRETOS':
                this.signsBox.addPiece(piece);
                break;
            case 'DIALECTOS':
                this.dialectsBox.addPiece(piece);
                break;
            default :
                break;
        }
    }

    addPageContent(scene){
        this.addPieceBoxes(scene);
    }

    isBoxReady(box){
        let boxValue = box.getStatusValue();
        if(boxValue===true&&!this.completedList.includes(box)){
            this.completedList.push(box);
            console.log('pieza guardada con éxito!');
        }
    }
}