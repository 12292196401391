import Phaser from 'phaser';
import { idCardPageResources } from '../../../../utils/assetsPath';

export default class PhotoBox extends Phaser.GameObjects.Container{
    constructor(scene,x,y, photo){
        super(scene,x,y);

        this.setPhoto(photo);
        this.buildBox(scene);
    }

    setBody(scene){
        this.setSize(200,200);
        this.body = scene.add.graphics();
        this.body.fillStyle(0x1D978E,1).lineStyle(3,0x000,1);
        this.body.strokeRoundedRect(-100,-100,200,200,5)
        .fillRoundedRect(-100,-100,200,200,5);

        this.add(this.body);
    }

    setPhoto(photo){
        this.photoTexture = photo;
    }

    addPhoto(scene){
        if(this.photoTexture===null) this.photoTexture = idCardPageResources.PORTRAIT.key
        this.photo = scene.add.image(0,0,this.photoTexture);
        this.photo.setOrigin(0.5);
        this.add(this.photo);
    }

    buildBox(scene){
        this.setBody(scene);
        this.addPhoto(scene);
    }
}