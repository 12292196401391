import { generateChakraRecords, getPlayerChakraRecords } from "../services/gameServices/chakraService"
import { CREATE_CHAKRA_RECORDS_SUCCESS, LOAD_CHAKRA_LIST, LOAD_CHAKRA_RECORDS_SUCCESS } from "./actionTypes"

export function createNewChakraRecords(idPlayer){
    return(dispatch)=>{
        return generateChakraRecords(idPlayer).then(response => {
            dispatch(createChakraRecordsSuccess())
        }).catch(error =>{ throw error})
    }
}

export function loadPlayerChakraRecords(idPlayer){
    return(dispatch)=>{
        return getPlayerChakraRecords(idPlayer).then(response => {
            let chakraList = response.data;
            dispatch(loadChakraList(chakraList));
            dispatch(loadChakraRecordsSuccess());
        }).catch(error => {throw error});
    }
}

function createChakraRecordsSuccess(){
    return {type : CREATE_CHAKRA_RECORDS_SUCCESS}
}

function loadChakraList(recordList){
    return {type: LOAD_CHAKRA_LIST, recordList}
}

function loadChakraRecordsSuccess(){
    return {type : LOAD_CHAKRA_RECORDS_SUCCESS}
}