import * as types from '../actions/actionTypes';
import initialState from './initial-state/initialState';


export default function playerReducer (state = initialState.loggedPlayer, action){
    switch(action.type){
        case types.LOAD_PLAYER_SUCCESS :
            return action.player;

        case types.LOAD_PLAYER_FAILURE : 
            return state;

        default:
            return state; 
    }
}