import Button from "../baseClasses/Button";
import { chakraResources } from "../../utils/assetsPath";
import Label from "../baseClasses/Label";
import UnitsLabel from "../baseClasses/unitsLabel";

export default class ToolButton extends Button {
  constructor(scene, x, y, toolInfo) {
    super(scene, x, y, chakraResources.TOOL_BUTTON.key, toolInfo.icon,toolInfo.name);
    this.setScene(scene);
    this.setToolSfx(null);
    this.addSoundEffect(toolInfo.sfx);
    this.label = new Label(scene,0,0,chakraResources.TOOL_LABEL.key,this.text);
    this.type = toolInfo.type;
    this.cursor = toolInfo.cursor;

    this.setContainer();
    this.buildButton(scene);
    this.addButtonAnim(scene);
    this.addUnitsLabel(scene);
  }

  setScene(scene){this.scene = scene}
  getScene(){ return this.scene}

  setToolSfx(sfx){this.toolSfx = sfx}
  getToolSfx(){ return this.toolSfx }

  addSoundEffect(sfx){
    if(sfx!==null){
      let sound = this.getScene().sound.add(sfx);
      this.setToolSfx(sound);
    }
  }

  playSoundEffect(){ 
    let sound = this.getToolSfx();
    if(sound!==null){ this.getToolSfx().play() }
  }

  buildButton(scene) {
    this.icon.setPosition(0, -30);
    this.label.setPosition(0, 45);
    this.label.text.setStroke(0x000,1);
    this.add([this.icon, this.label]);
    scene.add.existing(this);
  }

  shakeTool(scene){
    scene.tweens.add({
      targets : this.icon,
      x : {from : 0, to : -5},
      duration : 50,
      yoyo : true,
      onComplete : ()=>{
        scene.tweens.add({
          targets : this.icon,
          x : {from : 0, to : 5},
          duration : 50,
          yoyo : true
        })
      }
    })
  }

  addButtonAnim(scene){
    this.on('pointerover',()=>{ 
      let units = this.unitLabel.getCounter();
      if(units>0){ this.shakeTool(scene) } })
  }

  addUnitsLabel(scene){
    this.unitLabel = new UnitsLabel(scene,0,0);
    this.unitLabel.setScale(0.5);
    this.unitLabel.setPosition(40,-45);
    this.add(this.unitLabel);
  }

  checkUnits(){
     let units = this.unitLabel.getCounter();
     if(units===0){
       this.disableInteractive();
     }else if(units>0){
       this.setInteractive();
     }
  }

  freezeButton(){
    this.disableInteractive();
  }

}
