import { seedsResources } from "../../assetsPath";
import { cornStatesList, potatoStatesList, ocaStatesList, beanStatesList, lupineStatesList, zamboStatesList, mashuaStatesList, mellocoStatesList, jicamaStatesList, camoteStatesList, arracachaStatesList, zapalloStatesList } from "../chakra-states/statesInformation";

export const seedsInfo = {
    MAIZ : {
        id : 1, 
        // name : 'SARA', 
        name : 'MAIZ', 
        rewardKey : seedsResources.SEED01.key,
        iconKey:seedsResources.SEED_ICON_01.key,
        states : cornStatesList,
        taleId : 4
    },
    PAPA : {
        id : 2, 
        name : 'PAPA', 
        rewardKey : seedsResources.SEED02.key,
        iconKey:seedsResources.SEED_ICON_02.key,
        states : potatoStatesList,
        taleId : 3,
    },
    ZAMBO : {
        id : 3, 
        // name : 'ZAMPU',
        name : 'ZAMBO',
        rewardKey : seedsResources.SEED03.key,
        iconKey:seedsResources.SEED_ICON_03.key,
        states : zamboStatesList,
        taleId : 2
    },
    FREJOL : {
        id : 4, 
        // name : 'PURUTU', 
        name : 'FRÉJOL',
        rewardKey : seedsResources.SEED04.key,
        iconKey:seedsResources.SEED_ICON_04.key,
        states : beanStatesList,
        taleId : null 
    },
    CHOCHO : {
        id : 5, 
        // name : 'TAWRI',
        name : 'CHOCHO', 
        rewardKey : seedsResources.SEED05.key,
        iconKey:seedsResources.SEED_ICON_05.key,
        states : lupineStatesList,
        taleId : 12 
    },
    OCA : {
        id : 6, 
        // name : 'UKA', 
        name : 'OCA',
        rewardKey : seedsResources.SEED06.key,
        iconKey:seedsResources.SEED_ICON_06.key,
        states : ocaStatesList,
        taleId : 11 
    },
    MASHUA : {
        id : 7, 
        name : 'MASHUA', 
        rewardKey : seedsResources.SEED07.key,
        iconKey:seedsResources.SEED_ICON_07.key,
        states : mashuaStatesList,
        taleId : 10 
    },
    MELLOCO : {
        id : 8, 
        // name : 'MILLUKU', 
        name : 'MELLOCO',
        rewardKey : seedsResources.SEED08.key,
        iconKey:seedsResources.SEED_ICON_08.key,
        states : mellocoStatesList,
        taleId : 9 
    },
    JICAMA : {
        id : 9, 
        // name : 'HIKAMA', 
        name : 'JICAMA',
        rewardKey : seedsResources.SEED09.key,
        iconKey:seedsResources.SEED_ICON_09.key,
        states : jicamaStatesList,
        taleId : 8
    },
    ARRACACHA : {
        id : 10, 
        name : 'ARRACACHA', 
        rewardKey : seedsResources.SEED10.key,
        iconKey:seedsResources.SEED_ICON_10.key,
        states : arracachaStatesList,
        taleId : 7 
    },
    CAMOTE : {
        id : 11, 
        // name : 'KAMUTI', 
        name : 'CAMOTE',
        rewardKey : seedsResources.SEED11.key,
        iconKey:seedsResources.SEED_ICON_11.key,
        states : camoteStatesList,
        taleId : 6
    },
    SAPALLO : {
        id : 12, 
        // name : 'SAPALLU', 
        name : 'ZAPALLO',
        rewardKey : seedsResources.SEED12.key,
        iconKey:seedsResources.SEED_ICON_12.key,
        states : zapalloStatesList,
        taleId : 5 
    },
}

export const seedsCollection = [
    ...Object.values(seedsInfo),
]