import { familiarSignsResources, deathSignsResources, goodLuckSignsResources, badLuckSignsResources, weatherSignsResources } from "../../resources_path/signs/signsResources"
import { familiarSignsQuestions, deathSignsQuestions, goodLuckSignsQuestions, badLuckSignsQuestions, weatherSignsQuestions } from "../../quizzes/signs/signsQuizzes"
import { signsSecretsPuzzlePricesInformation } from "../prices/pricesInformation"

export const familiarList = {
    SIGN_ANGAS : {
        title : 'Angas Revoloteadoras',
        community : 'Columbe',
        state : 'Chimborazo',
        content : {
            photo : familiarSignsResources.PHOTO_ANGAS.key, 
            description : 'Cuando 2 palomas(angas) vuelan sobre la montaña/loma (urku), se pronostica que dentro de la comunidad (ayllu llakta) habrá un próximo matrimonio entre un hombre y una mujer.'
        },
        dialects : [
            {kichwaWord : 'anga(s)', spanishWord : 'paloma(s)'},
            {kichwaWord : 'urku', spanishWord : 'montaña/loma'},
            {kichwaWord : 'ayllu llakta', spanishWord : 'comunidad'}
        ],
        question : familiarSignsQuestions.QUESTION_ANGAS 
    },
    SIGN_PALOMAS : {
        title : 'Visita de 2 tórtolas',
        community : 'Agato',
        state : 'Imbabura',
        content : {
            photo : familiarSignsResources.PHOTO_PALOMAS.key, 
            description : 'Cuando 2 palomas se posan sobre la cornisa de una ventana en una vivienda o en el árbol que la acompaña, se pronostica que un miembro de la familia contraerá matrimonio (sawarina).'
        },
        dialects : [
            {kichwaWord : 'sawarina', spanishWord : 'contraer matrimonio'},
        ],
        question : familiarSignsQuestions.QUESTION_PALOMAS 
    },
    SIGN_PERRO_DESCONOCIDO : {
        title : 'Visita de un perro desconocido',
        community : 'Salache',
        state : 'Cotopaxi',
        content : {
            photo : familiarSignsResources.PHOTO_PERRO_DESCONOCIDO.key,
            description : 'Cuando la vivienda tiene la visita de un perro(allku) desconocido y viene a quedarse en la casa, es seña de la incorporación de un nuevo integrante en la familia(ayllu).'
        },
        dialects : [
            {kichwaWord : 'allku(s)', spanishWord : 'perro(s)'},
            {kichwaWord : 'ayllu', spanishWord : 'familia'},
        ],
        question : familiarSignsQuestions.QUESTION_PERRO_DESCONOCIDO 
    },
    SIGN_SUEÑO_ORINA : {
        title : 'Un Sueño Mojado',
        community : null,
        state : null,
        content : {
            photo : familiarSignsResources.PHOTO_SUEÑO_ORINA.key,
            description : 'Cuando una persona sueña que orina en grandes cantidades (jatu ishpa) es seña de que alguien en la comunidad va a tener un nacimiento.'
        },
        dialects : [
            {kichwaWord : 'jatu ishpa', spanishWord : 'grandes cantidades'},
        ],
        question : familiarSignsQuestions.QUESTION_SUEÑO_ORINA 
    },
    SIGN_VIENTRE : {
        title : 'Forma del vientre femenino',
        community : null,
        state : null,
        content : {
            photo : familiarSignsResources.PHOTO_VIENTRE.key,
            description : 'Cuando el vientre de una mujer embarazada tiene la forma de huevo(lulun), se prevé que saldrá un varón niño y si el vientre tiene forma circular, pelota, será una niña.'
        },
        dialects : [
            {kichwaWord : 'lulun', spanishWord : 'huevo'},
        ],
        question : familiarSignsQuestions.QUESTION_VIENTRE_PELOTA
    },
    SIGN_PIOJO_NIÑO : {
        title : 'Piojos Peligrosos',
        community : null,
        state : null,
        content : {
            photo : familiarSignsResources.PHOTO_PIOJO_NIÑO.key,
            description : 'Cuando un niño(wawa) se llena de piojos en su cabeza(uma), es probable de que sea víctima o sea parte de violencia o maltrato en su entorno familiar.'
        },
        dialects : [
            {kichwaWord : 'wawa', spanishWord : 'niño(a)'},
            {kichwaWord : 'uma', spanishWord : 'cabeza'},
        ],
        question : familiarSignsQuestions.QUESTION_PIOJO_NIÑO 
    },
    SIGN_VARIOS_PERROS : {
        title : 'Perro Vagabundo',
        community : null,
        state : null,
        content : {
            photo : familiarSignsResources.PHOTO_VARIOS_PERROS.key,
            description : 'Cuando una persona se encuentra con uno o varios perros y lo comienzan a seguir, se prevé que la persona va a andar como un vago(killa) y no va a lograr algún objetivo propuesto.'
        },
        dialects : [
            {kichwaWord : 'killa', spanishWord : 'vago(a)'},
        ],
        question : familiarSignsQuestions.QUESTION_VARIOS_PERROS 
    },
    SIGN_CUYES_MURMURADORES : {
        title : 'Cuyes Murmuradores',
        community : null,
        state : null,
        content : {
            photo : familiarSignsResources.PHOTO_CUYES_MURMURADORES.key,
            description : 'Cuando los cuyes(kuyes) empiezan a murmurar ztuk ztuk ztuk anuncian  al dueño de que van a llegar visitas a la vivienda muy pronto.'
        },
        dialects : [
            {kichwaWord : 'kuy', spanishWord : 'cuy'},
        ],
        question : familiarSignsQuestions.QUESTION_CUYES_MURMURADORES 
    },
    SIGN_FOGON : {
        title : 'Fogón encandilado',
        community : null,
        state : null,
        content : {
            photo : familiarSignsResources.PHOTO_FOGON.key,
            description : 'Cuando en la vivienda(wasi) alguien se encuentre cocinando en el fogón (tullpa) y la leña con fuego (inta) comience a hacer shhh shhh, significa que alguien va a llegar enojado (piñashka) o se pondra morirse de las iras(wañurina).'
        },
        dialects : [
            {kichwaWord : 'wasi', spanishWord : 'vivienda'},
            {kichwaWord : 'tullpa', spanishWord : 'fogón'},
            {kichwaWord : 'inta', spanishWord : 'leña con fuego'},
            {kichwaWord : 'piñashka', spanishWord : 'enojado(a)'},
            {kichwaWord : 'wañurina', spanishWord : 'morirse de las iras'},
        ],
        question : familiarSignsQuestions.QUESTION_FOGON 
    }
}

export const deathList = {
    SIGN_MARIPOSA_NEGRA : {
        title : 'Mariposa Negra',
        community : null,
        state : null,
        content : {
            photo : deathSignsResources.PHOTO_MARIPOSA_NEGRA.key, 
            description : 'Cuando se posa una mariposa negra (pillpintu yana) grandota sobre la vivienda, alguien allegado a la familia estará a punto de morir(wañuna) o será víctima de una robo(shuwana).'
        },
        dialects : [
            {kichwaWord : 'pillpintu yana', spanishWord : 'mariposa negra'},
            {kichwaWord : 'shuwana', spanishWord : 'robo'},
            {kichwaWord : 'wañuna', spanishWord : 'morir'},
        ],
        question : deathSignsQuestions.QUESTION_MARIPOSA_NEGRA 
    },
    SIGN_ALLA_CABALLO : {
        title : 'Caballo no amistoso',
        community : null,
        state : null,
        content : {
            photo : deathSignsResources.PHOTO_ALLA_CABALLO.key, 
            description : 'Cuando una persona presencia que un escarabajo (alla caballo) le arrastra a una tarántula y no la suelta es señal de muerte para una persona allegada y que dicha persona va a ir cargando su ataúd.'
        },
        dialects : [
            {kichwaWord : 'alla caballo', spanishWord : 'escorpión'},
        ],
        question : deathSignsQuestions.QUESTION_ALLA_CABALLO 
    },
    SIGN_ALWICU : {
        title : 'Al hueco',
        community : null,
        state : null,
        content : {
            photo : deathSignsResources.PHOTO_ALWICU.key, 
            description : 'Cuando un pájaro(pishku) comienza a silvar: "alwicu" es señal de muerte para alguna persona.'
        },
        dialects : [
            {kichwaWord : 'pishku', spanishWord : 'pájaro/ave'},
        ],
        question : deathSignsQuestions.QUESTION_ALWICU 
    },
    SIGN_PALOMA_LLORANDO : {
        title : 'Tórtola llorona',
        community : null,
        state : null,
        content : {
            photo : deathSignsResources.PHOTO_PALOMA_LLORANDO.key, 
            description : 'Cuando una paloma (kukulli) comienza a lamentarse es señal de mala suerte o la muerte inminente de alguna persona.'
        },
        dialects : [
            {kichwaWord : 'kukulli', spanishWord : 'paloma'},
        ],
        question : deathSignsQuestions.QUESTION_PALOMA_LLORANDO 
    },
    SIGN_CUSCUNGO : {
        title : 'Búho chillón',
        community : null,
        state : null,
        content : {
            photo : deathSignsResources.PHOTO_CUSCUNGO.key, 
            description : 'Cuando un búho (kuskungu) comienza a chillar cerca de la casa de una persona es seña de muerte para el o alguien cercano a la familia.'
        },
        dialects : [
            {kichwaWord : 'kuskungu', spanishWord : 'búho'},
        ],
        question : deathSignsQuestions.QUESTION_CUSCUNGO 
    },
    SIGN_AULLIDO_PERRO : {
        title : 'Perro aullando',
        community : null,
        state : null,
        content : {
            photo : deathSignsResources.PHOTO_AULLIDO_PERRO.key, 
            description : 'Cuando un perro comienza a aullar descontroladamente (piñarina) cerca de la casa de alguien es seña de muerte para él o alguien cercano a la familia.'
        },
        dialects : [
            {kichwaWord : 'piñarina', spanishWord : 'descontroladamente'},
        ],
        question : deathSignsQuestions.QUESTION_AULLIDO_PERRO 
    },
    SIGN_PULGA_UÑERO : {
        title : 'Pulga entrometida',
        community : null,
        state : null,
        content : {
            photo : deathSignsResources.PHOTO_PULGA_UÑERO.key, 
            description : 'Cuando una pulga(piki) entra al uñero del dedo del pie de una persona significa que dicha persona perecerá en cualquier instante.'
        },
        dialects : [
            {kichwaWord : 'piki', spanishWord : 'pulga'},
        ],
        question : deathSignsQuestions.QUESTION_PULGA_UÑERO 
    },
    SIGN_MOSCA_VERDE : {
        title : 'Moscas verdosas',
        community : null,
        state : null,
        content : {
            photo : deathSignsResources.PHOTO_MOSCA_VERDE.key, 
            description : 'Cuando aparecen de repente las moscas (kurumamas o pinkala waylla) e invaden una casa, es señal de muerte para algún miembro de ella.'
        },
        dialects : [
            {kichwaWord : 'kurumama/pinkala', spanishWord : 'mosca grande que no pica'},
            {kichwaWord : 'waylla', spanishWord : 'color verde'},
        ],
        question : deathSignsQuestions.QUESTION_MOSCA_VERDE 
    },
    SIGN_MUELAS_JUICIO : {
        title : 'Muela del juicio final',
        community : null,
        state : null,
        content : {
            photo : deathSignsResources.PHOTO_MUELAS_JUICIO.key, 
            description : 'Cuando una persona sueña que se saca las muelas del juicio(mamakiru) es señal de que muere una persona adulta conocida para él.'
        },
        dialects : [
            {kichwaWord : 'mamakiru', spanishWord : 'muelas del juicio'},
        ],
        question : deathSignsQuestions.QUESTION_MUELAS_JUICIO 
    },
    SIGN_DIENTE_FRONTAL : {
        title : 'Dientes frontales',
        community : null,
        state : null,
        content : {
            photo : deathSignsResources.PHOTO_DIENTE_FRONTAL.key, 
            description : 'Cuando una persona sueña que se le salen los dientes(kirus) de adelante es señal de que muere un niño que él conoce. Si el kiru frontal es el izquierdo, se morirá una niña, y si es el derecho morirá un niño).'
        },
        dialects : [
            {kichwaWord : 'kiru(s)', spanishWord : 'diente(s)'},
        ],
        question : deathSignsQuestions.QUESTION_DIENTE_DERECHO

    },
    SIGN_PIOJO_BLANCO : {
        title : 'Piojos mortales',
        community : null,
        state : null,
        content : {
            photo : deathSignsResources.PHOTO_PIOJO_BLANCO.key, 
            description : 'Cuando una persona enferma empieza a tener piojo blanco(pilis) en la piel, en la ropa, en todo lado, es seña de que se acerca su hora de morir.'
        },
        dialects : [
            {kichwaWord : 'pilis', spanishWord : 'piojo blanco'},
        ],
        question : deathSignsQuestions.QUESTION_PIOJO_BLANCO 
    }
}

export const goodLuckList = {
    SIGN_ARAÑA_KIPI : {
        title : 'Arañita laboriosa',
        community : null,
        state : null,
        content : {
            photo : goodLuckSignsResources.PHOTO_ARAÑA_KIPI.key, 
            description : 'Cuando los chakareros contemplan la presencia de una pequeña araña(uru) cargando su maleta(kipi), se pronostica una buena temporada de producción en las chacras(chakras).'
        },
        dialects : [
            {kichwaWord : 'uru', spanishWord : 'araña'},
            {kichwaWord : 'kipi', spanishWord : 'maleta'},
            {kichwaWord : 'chakra(s)', spanishWord : 'chacra(s)'}
        ],
        question : goodLuckSignsQuestions.QUESTION_ARAÑA_KIPI 
    },
    SIGN_DURAZNO : {
        title : 'Fruta de la abundancia',
        community : null,
        state : null,
        content : {
            photo : goodLuckSignsResources.PHOTO_DURAZNO.key, 
            description : 'Cuando los árboles del durazno(waytampu) están con mucha floración, es señal de buena producción de la siembra.'
        },
        dialects : [
            {kichwaWord : 'waytampu', spanishWord : 'variedad de durazno'},
        ],
        question : goodLuckSignsQuestions.QUESTION_DURAZNO 
    },
    SIGN_CATZO : {
        title : 'Vuelo resonado',
        community : null,
        state : null,
        content : {
            photo : goodLuckSignsResources.PHOTO_CATZO.key, 
            description : 'En la temporada de escarabajos(katsus), si estos animales vuelan en abundancia(kamana) sobre los alimentos sembrados en las chakras, se avecina buenos tiempos de cosecha.'
        },
        dialects : [
            {kichwaWord : 'katsu(s)', spanishWord : 'escarabajo(s)'},
            {kichwaWord : 'kamana', spanishWord : 'abundar/abundancia'},
        ],
        question : goodLuckSignsQuestions.QUESTION_CATZO 
    },
    SIGN_FREJOL : {
        title : 'Fréjoles mágicos',
        community : null,
        state : null,
        content : {
            photo : goodLuckSignsResources.PHOTO_FREJOL.key, 
            description : 'Cuando hay mucha cantidad de cachos(fiachu) sobre las chakras, es señal de buena producción de fréjol(purutu).'
        },
        dialects : [
            {kichwaWord : 'fiachu', spanishWord : 'escarabajo café pequeño'},
            {kichwaWord : 'purutu', spanishWord : 'fréjol'},
        ],
        question : goodLuckSignsQuestions.QUESTION_FREJOL 
    },
    SIGN_SALTO_HABAS : {
        title : 'Habas saltarinas',
        community : null,
        state : null,
        content : {
            photo : goodLuckSignsResources.PHOTO_SALTO_HABAS.key, 
            description : 'Cuando una persona se encuentra tostando las habas y estas comienzan a reventar y luego quedan en la tiesto(kallana) es señal de que dicha persona tendrá una buena cosecha.'
        },
        dialects : [
            {kichwaWord : 'kallana', spanishWord : 'tiesto'}
        ],
        question : goodLuckSignsQuestions.QUESTION_SALTO_HABAS 
    },
    SIGN_CERRO_FRUTOS : {
        title : 'Montañas brillantes',
        community : null,
        state : null,
        content : {
            photo : goodLuckSignsResources.PHOTO_CERRO_FRUTOS.key, 
            description : 'Cuando se escucha "con con" y se ve muchos rayos de luz en una montaña significa que esa montaña tendrá frutos(wayus) y animales(wiwas allpa)y ese año será de buena producción para la comunidad.'
        },
        dialects : [
            {kichwaWord : 'wayu(s)', spanishWord : 'fruto(s)'},
            {kichwaWord : 'wiwa(s)', spanishWord : 'animal(es)'},
            {kichwaWord : 'allpa', spanishWord : 'silvestres'},
        ],
        question : goodLuckSignsQuestions.QUESTION_CERRO_FRUTOS 
    },
    SIGN_PICAFLOR : {
        title : 'Colibrí merodeador',
        community : null,
        state : null,
        content : {
            photo : goodLuckSignsResources.PHOTO_PICAFLOR.key, 
            description : 'Cuando entra a la casa un picaflor(kindi) a la casa es señal de buena prosperidad y abundancia para la familia.'
        },
        dialects : [
            {kichwaWord : 'kindi', spanishWord : 'picaflor'},
        ],
        question : goodLuckSignsQuestions.QUESTION_PICAFLOR 
    },
    SIGN_FLORES_TUNAS : {
        title : 'Flores de tunas',
        community : 'Salache',
        state : 'Cotopaxi',
        content : {
            photo : goodLuckSignsResources.PHOTO_FLORES_TUNAS.key, 
            description : 'Cuando las flores de tunas están mirando hacia la mama Cotopaxi, es señal de buena producción porque habrá lluvia(tamya).'
        },
        dialects : [
            {kichwaWord : 'tamya', spanishWord : 'lluvia'},
        ],
        question : goodLuckSignsQuestions.QUESTION_FLORES_TUNAS 
    }
}

export const badLuckList = {
    SIGN_HORMIGAS : {
        title : 'Hormigas bandidas',
        community : null,
        state : null,
        content : {
            photo : badLuckSignsResources.PHOTO_HORMIGAS.key, 
            description : 'Cuando las hormigas(añankus) del patio invaden el hogar de una persona es señal de que va a ocurrir un robo.'
        },
        dialects : [
            {kichwaWord : 'añanku(s)', spanishWord : 'hormiga(s)'},
        ],
        question : badLuckSignsQuestions.QUESTION_HORMIGAS 
    },
    SIGN_HABAS_TIESTO : {
        title : 'Estallido inevitable',
        community : null,
        state : null,
        content : {
            photo : badLuckSignsResources.PHOTO_HABAS_TIESTO.key, 
            description : 'Cuando las habitas revientan(tukyanan) y caen al suelo del tiesto es que va a haber escasez de haba ese año.'
        },
        dialects : [
            {kichwaWord : 'tukyanan', spanishWord : 'reventar'},
        ],
        question : badLuckSignsQuestions.QUESTION_HABAS_TIESTO 
    },
    SIGN_ZAPALLO : {
        title : 'Zapallos abundantes',
        community : null,
        state : null,
        content : {
            photo : badLuckSignsResources.PHOTO_ZAPALLO.key, 
            description : 'Cuando hay sobreproducción de zapallo(sapallu) es seña de muerte y alguien quedará solo(sapalla).'
        },
        dialects : [
            {kichwaWord : 'sapallu', spanishWord : 'sapallo'},
            {kichwaWord : 'sapalla', spanishWord : 'solo(a)'},
        ],
        question : badLuckSignsQuestions.QUESTION_ZAPALLO 
    },
    SIGN_RATAS : {
        title : 'Ratas bien ratas',
        community : null,
        state : null,
        content : {
            photo : badLuckSignsResources.PHOTO_RATAS.key, 
            description : 'Cuando una persona sueña con abundantes ratas(ukuchas) es seña de que le robarán eventualmente.'
        },
        dialects : [
            {kichwaWord : 'ukucha(s)', spanishWord : 'rata(s)'}
        ],
        question : badLuckSignsQuestions.QUESTION_RATAS 
    },
    SIGN_SUEÑO_GRITO : {
        title : 'Grito desesperado',
        community : null,
        state : null,
        content : {
            photo : badLuckSignsResources.PHOTO_SUEÑO_GRITO.key, 
            description : 'Cuando una persona sueña(muskuna) gritando de manera desesperada, se pronostica que será víctima de un robo seguro.'
        },
        dialects : [
            {kichwaWord : 'muskuna', spanishWord : 'soñar'}
        ],
        question : badLuckSignsQuestions.QUESTION_SUEÑO_GRITO 
    },
    SIGN_ZAMBO : {
        title : 'Sobreproducción de zambos',
        community : null,
        state : null,
        content : {
            photo : badLuckSignsResources.PHOTO_ZAMBO.key, 
            description : 'Cuando haya sobreproducción de zambo(zambu) en la chakra de una persona y tiene todavía un sobrante de la venta deberá regalarlo sino quedará pobrete (lluchu).'
        },
        dialects : [
            {kichwaWord : 'zambu', spanishWord : 'zambo'},
            {kichwaWord : 'lluchu', spanishWord : 'desnudo/pobre/sin nada'}
        ],
        question : badLuckSignsQuestions.QUESTION_ZAMBO 
    },
    SIGN_GARZA : {
        title : 'Garzas intrusas',
        community : 'Saraguro, San Lucas',
        state : 'Loja',
        content : {
            photo : badLuckSignsResources.PHOTO_GARZA.key, 
            description : 'La visita de manada de garza(wakar o mashalli) en la comunidad es señal de mala suerte para las chakras.'
        },
        dialects : [
            {kichwaWord : 'wakar/mashalli', spanishWord : 'garza'}
        ],
        question : badLuckSignsQuestions.QUESTION_GARZA 
    },
    SIGN_LAGARTIJA : {
        title : 'Lagartija malagüera',
        community : null,
        state : null,
        content : {
            photo : badLuckSignsResources.PHOTO_LAGARTIJA.key, 
            description : 'Cuando se cruza una lagartija(sacharuna) en el camino de una persona, se interpreta que dicha persona no va a realizar alguna actividad en ese día.'
        },
        dialects : [
            {kichwaWord : 'sacharuna', spanishWord : 'lagartija'}
        ],
        question : badLuckSignsQuestions.QUESTION_LAGARTIJA 
    },
    SIGN_PAJARO_MASHU : {
        title : 'Pájaro Mashu',
        community : null,
        state : null,
        content : {
            photo : badLuckSignsResources.PHOTO_PAJARO_MUSHU.key, 
            description : 'Cuando una persona se vuelve vago (yanka purik) cuando descansa en el medio del camino porque pudo haber descansado el pájaro mashu.'
        },
        dialects : [
            {kichwaWord : 'yanka purik', spanishWord : 'vago/vagabundo'}
        ],
        question : badLuckSignsQuestions.QUESTION_PAJARO_MASHU 
    },
    SIGN_YUGO : {
        title : 'Yugo fragmentado',
        community : null,
        state : null,
        content : {
            photo : badLuckSignsResources.PHOTO_YUGO.key, 
            description : 'Cuando se rompe el yugo es seña que se rompe el matrimonio de la familia que está arando.'
        },
        dialects : [
            {kichwaWord : 'yugo', spanishWord : 'jugo'}
        ],
        question : badLuckSignsQuestions.QUESTION_YUGO 
    }
}

export const weatherList = {
    SIGN_ARCO_IRIS_ORIENTE : {
        title : 'Arco Iris',
        community : null,
        state : null,
        content : {
            photo : weatherSignsResources.PHOTO_ARCO_IRIS_ORIENTE.key, 
            description : 'Cuando el arco iris(kuychi) sale al oriente(intillukshina) es seña de que llega el verano(intipacha) y si sale al occidente(inti yaykuna) es seña de que va a llover.'
        },
        dialects : [
            {kichwaWord : 'kuychi', spanishWord : 'arco iris'},
            {kichwaWord : 'intillukshina', spanishWord : 'oriente'},
            {kichwaWord : 'intipacha', spanishWord : 'verano'},
            {kichwaWord : 'inti yaykuna', spanishWord : 'occidente'},
            {kichwaWord : 'kuychi', spanishWord : 'arco iris'},
        ],
        question : weatherSignsQuestions.QUESTION_ARCO_IRIS_ORIENTE 
    },
    SIGN_ESTRELLAS_DESPEJADAS : {
        title : 'Cielo estrellado',
        community : null,
        state : null,
        content : {
            photo : weatherSignsResources.PHOTO_ESTRELLAS_DESPEJADAS.key, 
            description : 'Cuando la noche esta estrellada es seña de que el día siguiente será soleado.'
        },
        dialects : [
        ],
        question : weatherSignsQuestions.QUESTION_ESTRELLAS_DESPEJADAS 
    },
    SIGN_MORTIÑO : {
        title : 'Apus vigilantes',
        community : null,
        state : null,
        content : {
            photo : weatherSignsResources.PHOTO_MORTIÑO.key, 
            description : 'Cuando una persona arranca con ramas al mortiño en el páramo se recienten los apus, le cae un aguacero(chirapa) y granizo (runtu) como castigo.'
        },
        dialects : [
            {kichwaWord : 'apu(s)', spanishWord : 'espíritus/sabios de la naturaleza'},
            {kichwaWord : 'runtu', spanishWord : 'granizo'},
        ],
        question : weatherSignsQuestions.QUESTION_MORTIÑO 
    },
    SIGN_APU_SAL : {
        title : 'Páramo salado',
        community : null,
        state : null,
        content : {
            photo : weatherSignsResources.PHOTO_APU_SAL.key, 
            description : 'Cuando una persona come sal(kachi) en medio del páramo(sallka), los apus se recienten.'
        },
        dialects : [
            {kichwaWord : 'kachi', spanishWord : 'sal'},
            {kichwaWord : 'sallka', spanishWord : 'páramo'},
        ],
        question : weatherSignsQuestions.QUESTION_APU_SAL 
    },
    SIGN_RELAMPAGOS : {
        title : 'Relámpagos blancos',
        community : null,
        state : null,
        content : {
            photo : weatherSignsResources.PHOTO_RELAMPAGOS.key, 
            description : 'Cuando hay relámpagos blancos(kulun yurak), la lluvia va a continuar.'
        },
        dialects : [
            {kichwaWord : 'kulun yurak', spanishWord : 'relámpagos blancos'},
        ],
        question : weatherSignsQuestions.QUESTION_RELAMPAGOS 
    },
    SIGN_HURACAN : {
        title : 'Torbellinos temibles',
        community : null,
        state : null,
        content : {
            photo : weatherSignsResources.PHOTO_HURACAN.key, 
            description : 'Cuando el huracán viene haciendo torbellino o remolino(akapana), es señal de que viene una larga sequía.'
        },
        dialects : [
            {kichwaWord : 'akapana', spanishWord : 'torbellino/remolino'},
        ],
        question : weatherSignsQuestions.QUESTION_HURACAN 
    },
    SIGN_ARCO_IRIS_AIRE : {
        title : 'Arco iris elevado',
        community : null,
        state : null,
        content : {
            photo : weatherSignsResources.PHOTO_ARCO_IRIS_AIRE.key, 
            description : 'Cuando el arco iris no topa la tierra, se pronostica la llegada del verano.'
        },
        dialects : [
        ],
        question : weatherSignsQuestions.QUESTION_ARCO_IRIS_AIRE 
    },
    SIGN_ARCO_IRIS_TIERRA : {
        title : 'Arco iris sobre la tierra',
        community : null,
        state : null,
        content : {
            photo : weatherSignsResources.PHOTO_ARCO_IRIS_TIERRA.key, 
            description : 'Cuando el arco iris está por tierra, la lluvia todavía continua.'
        },
        dialects : [
        ],
        question : weatherSignsQuestions.QUESTION_ARCO_IRIS_TIERRA 
    },
    SIGN_JAMBATOS : {
        title : 'Sapitos cantores',
        community : null,
        state : null,
        content : {
            photo : weatherSignsResources.PHOTO_JAMBATOS.key, 
            description : 'Cuando empiezan a cantar los sapitos(hampatus) es seña de que se acerca la lluvia.'
        },
        dialects : [
            {kichwaWord : 'hampatus', spanishWord : 'sapos'},
        ],
        question : weatherSignsQuestions.QUESTION_JAMBATOS 
    },
    SIGN_WIRAKCHURUS : {
        title : 'Sonata de wirakchurus',
        community : null,
        state : null,
        content : {
            photo : weatherSignsResources.PHOTO_WIRAKCHURUS.key, 
            description : 'Cuando empiezan a cantar las aves cantoras (wirakchurus), es seña de que ya existen los primeros choclos(chukllus) en la chakra.'
        },
        dialects : [
            {kichwaWord : 'wirakchurus', spanishWord : 'variedad de aves cantoras'},
            {kichwaWord : 'chukllu(s)', spanishWord : 'choclo(s)'},
        ],
        question : weatherSignsQuestions.QUESTION_WIRAKCHURUS 
    }
}

export const signsGroupList = {
    GROUP_01 : {
        quiz_id : 25,
        month : 'ENERO',
        signs : [
            goodLuckList.SIGN_ARAÑA_KIPI,
            badLuckList.SIGN_HORMIGAS,
            weatherList.SIGN_ARCO_IRIS_ORIENTE,
            deathList.SIGN_AULLIDO_PERRO
        ],
        price : signsSecretsPuzzlePricesInformation.PUZZLE_PRICE_GROUP_01
    },
    GROUP_02 : {
        quiz_id : 26,
        month : 'FEBRERO',
        signs : [ 
            goodLuckList.SIGN_DURAZNO,
            badLuckList.SIGN_HABAS_TIESTO,
            weatherList.SIGN_ESTRELLAS_DESPEJADAS,
            deathList.SIGN_PULGA_UÑERO
        ],
        price : signsSecretsPuzzlePricesInformation.PUZZLE_PRICE_GROUP_02
    },
    GROUP_03 : {
        quiz_id : 27,
        month : 'MARZO',
        signs : [
            goodLuckList.SIGN_CATZO,
            badLuckList.SIGN_ZAPALLO,
            weatherList.SIGN_MORTIÑO,
            deathList.SIGN_MOSCA_VERDE
        ],
        price : signsSecretsPuzzlePricesInformation.PUZZLE_PRICE_GROUP_03
    },
    GROUP_04 : {
        quiz_id : 28,
        month : 'ABRIL',
        signs : [
            deathList.SIGN_MARIPOSA_NEGRA,
            goodLuckList.SIGN_FREJOL,
            weatherList.SIGN_APU_SAL,
            weatherList.SIGN_ARCO_IRIS_TIERRA
        ],
        price : signsSecretsPuzzlePricesInformation.PUZZLE_PRICE_GROUP_04
    },
    GROUP_05 : {
        quiz_id : 29,
        month : 'MAYO',
        signs : [
            deathList.SIGN_MUELAS_JUICIO,
            goodLuckList.SIGN_SALTO_HABAS,
            badLuckList.SIGN_RATAS,
            weatherList.SIGN_JAMBATOS
        ],
        price : signsSecretsPuzzlePricesInformation.PUZZLE_PRICE_GROUP_05
    },
    GROUP_06 : {
        quiz_id : 30,
        month : 'JUNIO',
        signs : [
            familiarList.SIGN_PIOJO_NIÑO,
            deathList.SIGN_ALWICU,
            weatherList.SIGN_RELAMPAGOS,
            badLuckList.SIGN_ZAMBO
        ],
        price : signsSecretsPuzzlePricesInformation.PUZZLE_PRICE_GROUP_06
    },
    GROUP_07 : {
        quiz_id : 31,
        month : 'JULIO',
        signs : [
            deathList.SIGN_ALLA_CABALLO,
            goodLuckList.SIGN_PICAFLOR,
            weatherList.SIGN_HURACAN,
            familiarList.SIGN_PALOMAS,
        ],
        price : signsSecretsPuzzlePricesInformation.PUZZLE_PRICE_GROUP_07
    },
    GROUP_08 : {
        quiz_id : 32,
        month : 'AGOSTO',
        signs : [
            familiarList.SIGN_ANGAS,
            badLuckList.SIGN_GARZA,
            familiarList.SIGN_PERRO_DESCONOCIDO,
            badLuckList.SIGN_YUGO
        ],
        price : signsSecretsPuzzlePricesInformation.PUZZLE_PRICE_GROUP_08
    },
    GROUP_09 : {
        quiz_id : 33,
        month : 'SEPTIEMBRE',
        signs : [
            familiarList.SIGN_SUEÑO_ORINA,
            familiarList.SIGN_VIENTRE,
            goodLuckList.SIGN_CERRO_FRUTOS,
            badLuckList.SIGN_LAGARTIJA
        ],
        price : signsSecretsPuzzlePricesInformation.PUZZLE_PRICE_GROUP_09
    },
    GROUP_10 : {
        quiz_id : 34,
        month : 'OCTUBRE',
        signs : [
            familiarList.SIGN_VARIOS_PERROS,
            familiarList.SIGN_CUYES_MURMURADORES,
            familiarList.SIGN_FOGON,
            deathList.SIGN_DIENTE_FRONTAL
        ],
        price : signsSecretsPuzzlePricesInformation.PUZZLE_PRICE_GROUP_10
    },
    GROUP_11 : {
        quiz_id : 35,
        month : 'NOVIEMBRE',
        signs : [
            deathList.SIGN_PALOMA_LLORANDO,
            goodLuckList.SIGN_FLORES_TUNAS,
            badLuckList.SIGN_PAJARO_MASHU,
            weatherList.SIGN_WIRAKCHURUS
        ],
        price : signsSecretsPuzzlePricesInformation.PUZZLE_PRICE_GROUP_11
    },
    GROUP_12 : {
        quiz_id : 36,
        month : 'DICIEMBRE',
        signs : [
           deathList.SIGN_CUSCUNGO,
           badLuckList.SIGN_SUEÑO_GRITO,
           weatherList.SIGN_ARCO_IRIS_AIRE,
           deathList.SIGN_PIOJO_BLANCO 
        ],
        price : signsSecretsPuzzlePricesInformation.PUZZLE_PRICE_GROUP_12
    },
}