import Phaser from 'phaser';
import { piecesInfo } from '../../utils/definitions';
import { caracResources } from '../../utils/assetsPath';
import { findAllByLabelText } from '@testing-library/react';
import { gridAlignGroupElements, addChildrenToContainer } from '../../utils/functions';

export default class PuzzlePiece extends Phaser.GameObjects.Container{
    constructor(scene,x,y,puzzleData){
        super(scene,x,y);

        this.puzzleSlot = null;
        this.isPlaced = false;
        this.isUnlocked = null;

        this.setInitialProperties();

        this.setScene(scene);
        this.setPuzzlePieceData(puzzleData);

    }

    setScene(scene){this.scene = scene}
    getScene(){ return this.scene }

    setTitle(title){ this.title = title }
    getTitle(){ return this.title }

    setPieceType(type){
        this.pieceType = type;
    }

    setPieceId(id){
        this.pieceId = id;
    }

    setBackground(background){
        this.background = background;
    }

    setPicData(picData){ this.picData = picData}
    getPicData(){ return this.picData }



    setPicPhoto(picPhoto){ this.picPhoto = picPhoto }
    getPicPhoto(){ return this.picPhoto }

    setLabelContainer(container){ this.labelContainer = container}
    getLabelContainer(){ return this.labelContainer }

    setLabelBody(body){ this.labelBody = body }
    getLabelBody(){ return this.labelBody }

    setContentList(list){ this.contentList = list }
    getContentList(){ return this.contentList }

    setInitialProperties(){
        this.setContentList([]);
    }



    setPuzzlePieceData(puzzleData){
        this.setPieceId(puzzleData.id);
        this.setTitle(puzzleData.title);
        this.setPieceType(puzzleData.type);
        this.chooseBackground();
        this.setPicData(puzzleData.icon);
    }

    chooseBackground(){
        switch(this.pieceType){
            case 'FESTIVIDADES':
            this.setBackground(caracResources.PIECE_RED.key);
            break;
            case 'COMIDAS':
            this.setBackground(caracResources.PIECE_YELLOW.key);
            break;
            case 'SEÑAS-SECRETOS':
            this.setBackground(caracResources.PIECE_GREEN.key);
            break;
            case 'DIALECTOS':
            this.setBackground(caracResources.PIECE_BLUE.key);
            break;
            default:
            break;
        }
    }


    createPuzzlePiece(idBody){
        this.setOwnProps(idBody);
        this.addBody();
        this.addContent();
        this.displayPiece();
    }

    setOwnProps(idBody){
        this.puzzleData = piecesInfo.find((piece)=>{
            return piece.id === idBody;
        });
        this.type = this.puzzleData.type;
    }

    lockPiece(){
        this.isUnlocked = false;
    }

    setSlotScale(){
        this.setScale(this.puzzleData.xScale,this.puzzleData.yScale);
    }

    setPuzzleSlot(slot){
        this.puzzleSlot = slot;
    }

    blockPiece(){
        if (this.puzzleSlot!==null) this.isPlaced=true;
    }

    checkPieceStatus(){
        if(this.isPlaced===false){
            this.setInteractive();
        } else{
            this.disableInteractive();
        }
    }

    checkPieceLockStatus(){
        if(this.isUnlocked===false){
            this.setVisible(false);
        }else{
            this.setVisible(true);
        }
    }

    displayPiece(){
        this.scene.add.existing(this);
        this.checkPieceStatus();
        if(this.isPlaced===false)this.scene.input.setDraggable(this);
    }

    addBody(){
        this.body = this.scene.add.image(0,0,this.background);
        this.add(this.body).setSize(this.body.width,this.body.height); 
    }

    addPuzzlePic(){
        let scene = this.getScene();
        this.setPicPhoto(scene.add.image(0,0,this.getPicData()));
        this.getContentList().push(this.getPicPhoto());
    }

    placeInPuzzleSlot(slot){
        this.setPuzzleSlot(slot);
        this.blockPiece();
        this.checkPieceStatus();
        let slotParent = slot.monthParent;
        slotParent.add(this);
        this.setPosition(slot.x,slot.y)
        .setScale(slot._scaleX-0.05,slot._scaleY-0.02)
        .setRotation(slot.rotation);
    }

    killPiece(){
        this.destroy();
    }

    addTitleLabelContainer(){
        let scene = this.getScene(),
            width = 140, height = 25, 
            title = this.getTitle(),
            style = {fontFamily : 'Helvetica', color : '#ffffff', 
                     stroke : '#ffffff', strokeThickness : 0.5};
        let body = scene.add.graphics();
        let container = scene.add.container(0,0);
        let text = scene.add.text(0,0,title,style);
        text.setFontSize(20).setOrigin(0.5);
        container.setSize(width,height);
        body.fillStyle(0x7410B2,1).fillRoundedRect(-width/2,-height/2,width,height,height);
        this.setLabelBody(body);
        container.add([this.getLabelBody(),text]);
        this.setLabelContainer(container);
        this.getContentList().push(this.getLabelContainer());
    }

    addContent(){
        this.addTitleLabelContainer();
        this.addPuzzlePic();
        let gridConfig = {width : 1, height : 2, cellHeight : 45,position : 6, y : -32};
        let group = this.getScene().add.group(this.getContentList());
        gridAlignGroupElements(this.getScene(),group,gridConfig);
        addChildrenToContainer(this,group.getChildren());
    }

    
}