import Phaser from 'phaser';
import Ribbon from '../../../baseClasses/Ribbon';
import { mainUIResources, simbolsResources, chakraSoundResources, quizSoundResources } from '../../../../utils/assetsPath';
import { gridAlignGroupElements, addChildrenToContainer } from '../../../../utils/functions';

export default class QuestionCard extends Phaser.GameObjects.Container{
    
    constructor(x,y,scene,data,totalNumber,quizType){
        super(scene,x,y);
        this.number = null;
        this.questionsTotalNumber = totalNumber;
        this.question = null;
        this.options = [];
        this.answer = null;
        this.selectedOption = null;
        this.result = 0;

        this.setQuizType(quizType);
        this.setData(data);
        this.buildCard(scene);

        this.lightSimbol();
        this.animateOptions(scene);
    }

    setData(data){
        this.setNumber(data.questionNumber);
        this.setQuestion(data.question);
        this.setOptions(data.options);
        this.setAnswer(data.answer);
    }

    setNumber(number){
        this.number = number;
    }

    setQuestion(question){
        this.question = question;
    }

    setOptions(options){
        this.options = options;
    }

    setAnswer(answer){
        this.answer = answer;
    }

    setSelectedOption(option){
        this.selectedOption = option;
    }

    setQuizType(type){
        this.quizType = type;
    }

    setSelectionSFX(sfx){ this.selectionSFX = sfx }
    getSelectionSFX(){ return this.selectionSFX }

    setBoardCard(scene){
        let container = scene.add.container(0,0);
        container.setSize(1200,960);
        let board = scene.add.graphics();
        board.lineStyle(3,0x000,1).fillStyle(0x726330,1);
        board.fillRoundedRect(-600,-480,1200,960,20)
        .strokeRoundedRect(-600,-480,1200,960,20);

        let innerBody = scene.add.graphics();
        innerBody.fillStyle(0xA5DFA7,1);
        innerBody.fillRoundedRect(-570,-450,1140,900,20);

        container.add([board,innerBody]);
        return container;
    }

    setRibbon(scene){
        let ribbon = new Ribbon(scene,0,0,mainUIResources.RIBBON_WINDOW_MODAL.key,`PREGUNTA\t${this.number}`);
        ribbon.createRibbon(scene);
        ribbon.text.setStyle({
            fontFamily : 'Helvetica',
            fontSize : '40px',
            color : '#000',
            align : 'center',
            stroke : '#000',
            strokeThickness : 2, 
        });
        return ribbon;
    }

    setQuestionBoard(scene){
        let container = scene.add.container(0,0);
        container.setSize(1100,150);

        let board = scene.add.graphics();
        board.fillStyle(0xEAF95F,1).lineStyle(2,0x000,1);
        board.fillRoundedRect(-550,-75,1100,150,20)
        .strokeRoundedRect(-550,-75,1100,150,20);

        let sentence = scene.add.text(0,0,this.question,{
            fontFamily : 'Arial Black',
            fontSize : '29px',
            color : '#0C1B7E',
            align : 'center',
            stroke : '#0C1B7E',
            strokeThickness : 1});
        sentence.setOrigin(0.5,0.5);
        
        container.add([board,sentence]);
        return container;
    }

    createSelectionSound(scene){
        let sound = scene.sound.add(chakraSoundResources.TOOL_CLICK.key);
        this.setSelectionSFX(sound);
    }

    createOptionLabel(scene,word){
        let container = scene.add.container(0,0),
            width = 1000,
            height = 100,
            radius = 20;
        
        container.setSize(width,height);

        let body = scene.add.graphics();
        body.fillStyle(0xffffff,0.5);
        body.fillRoundedRect(-width/2,-height/2,width,height,radius);

        let text = scene.add.text(0,0,word,{
            fontFamily : 'Arial Black',
            fontSize : '40px',
            color : '#000',
            align : 'center',
            stroke : '#000',
            strokeThickness : 1});

        text.setOrigin(0.5,0.5);

        container.add([body,text]);
        return container;    
    }

    addOptions(scene){
        let container = scene.add.container(0,0);
        container.setSize(500,100);
        let group = scene.add.group(), 
            gridConfig = {
                width : 1,
                height : 4,
                cellHeight : 110,
                position : 6,
                x : 0,
                // y : -80
                y : 0
            };
        this.options.forEach((word)=>{
            let option = this.createOptionLabel(scene,word);
            group.add(option);
        });

        gridAlignGroupElements(scene,group,gridConfig);
        addChildrenToContainer(container,group.getChildren());
        return container;
    }

    setSimbols(scene){
        let group = scene.add.group(),
            container = scene.add.container(0,0);
            container.setSize(500,100);
        for(let i=0;i<this.questionsTotalNumber;i++){
            let simbol;
            switch(this.quizType){
                case 'FESTIVIDADES':
                    simbol = scene.add.image(0,0,simbolsResources.FESTIVITY_SIMBOL.key);
                break;
            
                case 'COMIDAS':
                    simbol = scene.add.image(0,0,simbolsResources.FOOD_SIMBOL.key);
                break;

                case 'SEÑAS-SECRETOS':
                    simbol = scene.add.image(0,0,simbolsResources.SIGNS_SIMBOL.key);
                break;

                case 'DIALECTOS':
                    simbol = scene.add.image(0,0,simbolsResources.DIALECT_SIMBOL.key);
                break;
                default:
                break;
            }
            group.add(simbol);
        }
        let gridConfig = {
            width : this.questionsTotalNumber,
            height : 1,
            cellWidth : 110,
            position : 6,
            x : 0,
            y : 0
        };
        gridAlignGroupElements(scene,group,gridConfig);
        addChildrenToContainer(container,group.getChildren());
        return container;
    }

    addQuizSimbols(scene){
        let quizSimbols = this.setSimbols(scene);
        quizSimbols.setPosition(-this.width*0.25,this.height*0.38);
        this.add(quizSimbols);
    }

    buildCard(scene){
        this.boardCard = this.setBoardCard(scene);
        this.questionBoard = this.setQuestionBoard(scene);
        this.ribbon = this.setRibbon(scene);
        this.optionsLayers = this.addOptions(scene);
        this.quizSimbols = this.setSimbols(scene);
        this.createSelectionSound(scene);
        this.add([this.boardCard,this.ribbon,this.questionBoard,this.optionsLayers,this.quizSimbols]);

        this.addResultIcon(scene);

        this.boardCard.setPosition(0,-20);
        this.ribbon.setPosition(0,-385);
        this.questionBoard.setPosition(0,-245);
        this.optionsLayers.setPosition(0,-100);
        this.quizSimbols.setPosition(-250,350);
    }

    lightSimbol(){
        for(let i=this.number;i<this.quizSimbols.getAll().length;i++){
            this.quizSimbols.getAll()[i].setAlpha(0.5);
        }
    }

    cleanOptionLabels(){
        let chosenOne = this.optionsLayers.getAll().find((label)=>{
            return label.getAll()[1]._text === this.selectedOption;
        });
        this.optionsLayers.getAll().forEach((label)=>{
            if(label!==chosenOne){
                let body = label.getAll()[0];
                let text = label.getAll()[1];
                body.clear();
                body.fillStyle(0xffffff,0.5)
                .fillRoundedRect(-label.width/2,-label.height/2,label.width,label.height,20);
                text.setColor('#000');
            }    
        })
    }

    animateOptions(scene){
        this.optionsLayers.getAll().forEach((label)=>{
            label.setInteractive();
            let body = label.getFirst();
            let text = label.getAll()[1];

            label.on('pointerover',()=>{
                scene.tweens.add({targets:label, scale : {from : 1, to : 1.1}, duration : 200});
            })

            label.on('pointerout',()=>{
                scene.tweens.add({targets:label, scale : {from : 1.1, to : 1}, duration : 200});
            })

            label.on('pointerdown',()=>{
                body.clear();
                body.fillStyle(0x2d2d2d,1)
                .fillRoundedRect(-label.width/2,-label.height/2,label.width,label.height,20);
                text.setColor('#ffffff');
            });
            label.on('pointerup',()=>{
                this.setSelectedOption(text._text);
                this.cleanOptionLabels();
                this.getSelectionSFX().play();
                this.getSelectionSFX().once('complete',()=>{this.showResultIcon(scene)});
            });
        })
    }

    lowLayers(){
        this.optionsLayers.getAll().forEach((label)=>{
            label.disableInteractive();
            let text = label.getAll()[1];
            text.setAlpha(0.5);
        })
    }

    brightLayers(){
        this.optionsLayers.getAll().forEach((label)=>{
            label.setInteractive();
            let text = label.getAll()[1];
            text.setAlpha(1);
        })
    }

    checkAnswer(){
        if(this.selectedOption===this.answer){
            return true;
        }else{
            return false;
        }
    }

    addResultIcon(scene){
        this.icon = scene.add.image(0,0,null);
        this.add(this.icon);
        this.icon.setAlpha(0);
    }

    showResultIcon(scene){
        if(!this.checkAnswer()){
            this.icon.setTexture(simbolsResources.WRONG_SIMBOL.key);
            scene.sound.play(quizSoundResources.WRONG_ANSWER_EFFECT.key);
        }else{
            this.icon.setTexture(simbolsResources.RIGHT_SIMBOL.key);
            this.result++;
            scene.sound.play(quizSoundResources.CORRECT_ANSWER_EFFECT.key);
        }
        this.lowLayers();
        scene.tweens.add({
            targets:this.icon,
            alpha : {from : 0, to : 1},
            duration : 1000,
            ease : 'Linear',
            onComplete : ()=>{
                scene.time.addEvent({
                    delay : 1000, 
                    callback : ()=>{
                        scene.tweens.add({
                            targets : this.icon,
                            alpha : { from : 1,to:0},
                            duration : 1000,
                            ease : 'Linear',
                            onComplete : ()=>{
                                scene.events.emit('questionchecked',this);
                                this.brightLayers()}
                        })
                    }})
            }
        });


    }




}