 import DescriptionPage from "./descriptionPage";
import RitualCard from "../game-objects/ritualCard";

export default class SignsPage extends DescriptionPage{
    constructor(scene,x,y,title,data){
        super(scene,x,y,title,data);

        this.addPageContent(scene,data);
    }

    addPageContent(scene,data){
        let card = new RitualCard(scene,0,0,data);
        this.add(card);
    }
}