import Window from "../baseClasses/Window";
import { mainUIResources } from "../../utils/assetsPath";
import ModuleRibbon from "./ModuleRibbon";

export default class ModuleWindow extends Window{
    constructor(scene,x,y){
        super(scene,x,y,mainUIResources.WINDOW_SCENE.key);
        // this.createSceneWindow(scene);
    }

    addSceneWindow(scene){
        this.createModuleWindow(scene);
    }

    addWindowRibbon(scene,title){
        this.sceneRibbon = new ModuleRibbon(scene, 0, 0, title);
        this.sceneRibbon.setPosition(0,-this.moduleWindow.height * 0.4);
        this.sceneRibbon.ribbon.setDisplaySize(this.moduleWindow.width * 0.9,this.moduleWindow.height * 0.11);
    }


    createSceneWindow(scene){
        this.add([this.moduleWindow,this.sceneRibbon]).setSize(this.moduleWindow.width,this.moduleWindow.height);
        this.setDisplaySize(scene.width * 0.7, scene.height);
    }

    addElement(element){
        this.add(element);
    }

    hideRibbon(){
        this.sceneRibbon.setVisible(false);
    }
    
}