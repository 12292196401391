import DescriptionPage from "./descriptionPage";
import FactCard from "../game-objects/factCard";
import RitualCard from "../game-objects/ritualCard";

export default class DishPreparationPage extends DescriptionPage{
    constructor(scene,x,y,data){
        super(scene,x,y,'PREPARACIÓN\nDEL PLATO TÍPICO',data);

        this.addPageContent(scene,data);

    }

    addPageContent(scene,data){
        let card = new RitualCard(scene,0,0,data);
        this.add(card);
    }

    addDescriptionCard(scene,info){
        let card = new FactCard(scene,0,0,info);
        this.cardsList.push(card);
    }
}