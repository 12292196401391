import Menu from "../baseClasses/Menu";
import { mainUIResources, assistantMenuResources } from "../../utils/assetsPath";
import AssistantMenuBtn from "./AssistantMenuBtn";
import AssistantMenuButton from "./assistant-menu-components/assistantMenuButton";
import { assistantButtonsInformation } from "../../utils/information/buttons/assistant-menu-btn/buttonsInformation";

export default class AssistantMenu extends Menu{
    constructor(scene,x,y){
        // super(scene,x,y,mainUIResources.BOX_ASSISTANT_MENU.key);
        super(scene,x,y,assistantMenuResources.ASSISTANT_MENU_BOARD.key);
        this.addAssistantButtons(scene);
        this.setGridConfig({width:4,cellWidth:530,position:6,x:-this.background.width*0.45, y:-40})
        this.createMenu(scene);
        this.changeBackgroundScale();
        this.styleConfig = {backColor:0xae091a,fontColor:'#ffffff'};
    }

    setGridConfig(config){ this.gridConfig = config }
    getGridConfig(){ return this.gridConfig }

    setHintBtn(button){ this.hintBtn = button }
    getHintBtn(){ return this.hintBtn }

    setGuideBtn(button){ this.guideBtn = button }
    getGuideBtn(){ return this.guideBtn }

    setSoundBtn(button){ this.soundBtn = button }
    getSoundBtn(){ return this.soundBtn }

    setExitBtn(button){ this.exitBtn = button }
    getExitBtn(){ return this.exitBtn }

    addButtons(scene){
        this.hintBtn = new AssistantMenuBtn(scene,0,0,mainUIResources.ICON_HINT.key,'SUGERENCIA');
        this.guideBtn = new AssistantMenuBtn(scene,0,0,mainUIResources.ICON_GUIDE.key,'GUIA');
        this.soundBtn = new AssistantMenuBtn(scene,0,0,mainUIResources.ICON_SOUND.key,'ENCENDIDO');
        this.guideBtn.label.text.displayWidth = this.guideBtn.label.body.width*0.5;
        this.childrenList = [this.hintBtn,this.guideBtn,this.soundBtn];

        this.activateButtons();
    }

    changeBackgroundScale(){
        this.background.setScale(1.3,0.8);
    }


    addAssistantButtons(scene){
        let hintBtn = new AssistantMenuButton(scene,0,0,assistantButtonsInformation.HINT_BUTTON);
        this.setHintBtn(hintBtn);

        let guideBtn = new AssistantMenuButton(scene,0,0,assistantButtonsInformation.GUIDE_BUTTON);
        this.setGuideBtn(guideBtn);

        let soundBtn = new AssistantMenuButton(scene,0,0,assistantButtonsInformation.AUDIO_BUTTON);
        this.setSoundBtn(soundBtn);

        let exitBtn = new AssistantMenuButton(scene,0,0,assistantButtonsInformation.EXIT_BUTTON);
        this.setExitBtn(exitBtn);

        this.childrenList = [this.getHintBtn(),this.getGuideBtn(),this.getSoundBtn(),this.getExitBtn()];

        this.activateButtons();
    }

    populateList(){
        let hintBtn = this.getHintBtn(), guideBtn = this.getGuideBtn(),
            soundBtn = this.getSoundBtn(), exitBtn = this.getExitBtn();
        this.childrenList = [hintBtn,guideBtn,soundBtn,exitBtn];
    }

    activateButtons(){
        this.childrenList.forEach((btn)=>{
            btn.activeButton();
            btn.setScale(1.1);
            btn.setInteractive();
        });
    }

    addMuteButton(scene){
        this.muteBtn = new AssistantMenuBtn(scene,0,0,mainUIResources.ICON_MUTE.key,'APAGADO');
        this.muteBtn.setPosition(this.soundBtn.x,this.soundBtn.y);
        this.muteBtn.setVisible(false);
        this.childrenList.push(this.muteBtn);
        this.add(this.muteBtn);
    }

    showMuteButton(){
        this.soundBtn.setVisible(false);
        this.muteBtn.setVisible(true);
    }

    hideMuteButton(){
        this.soundBtn.setVisible(true);
        this.muteBtn.setVisible(false);
    }
}