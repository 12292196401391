import apiClient from '../configService';


export function getTales(){
    return apiClient.get('/tales');
}

export function generateTaleRecords(idPlayer){
    return apiClient.post('/tale_records/player',idPlayer);
}

export function getPlayerTaleRecords(idPlayer){
    return apiClient.get('/tale_records/player?playerId='+idPlayer);
}

export function updatePlayerTaleRecords(idTale,params){
    return apiClient.put('/tale_records/'+idTale,params)
}

