import Chapter from "../../../baseClasses/chapter";

export default class FestivityChapter extends Chapter{
    constructor(scene,data,month){
        super(data);
        this.ancestralKnowlegde = null;
        this.setMonth(month);
        this.addFestivityChapter(scene);
    }

    setAncestralKnowledge(knowledge){
        this.ancestralKnowlegde = knowledge;
    }

    addFestivityChapter(scene){
        this.setAncestralKnowledge(this.description);
        this.setIntroPage(scene,this.type);
        this.setAuthorPage(scene);
        this.setFactPages(scene,this.ancestralKnowlegde.mainFacts);
        this.setMeaningPages(scene,this.ancestralKnowlegde.meaning);
        this.setRitualPages(scene,this.ancestralKnowlegde.ritual);
        this.setQuizPage(scene,this.quiz,this.puzzlePieceInfo);
    }

}