import { festivityPricesResources, foodPricesResources, signsSecretsPriceResources, dialectsPriceResources } from "../../resources_path/prices/pricesResources";

export const festivitiesPuzzlePricesInformation = {
    PUZZLE_PRICE_YUMBADA : {
        id : 1,
        title : 'Yumbada',
        type : 'FESTIVIDADES',
        icon : festivityPricesResources.PRICE_YUMBADA.key,
    },
    PUZZLE_PRICE_SISAY_PACHA : {
        id : 2,
        title : 'Sisay Pacha',
        type : 'FESTIVIDADES',
        icon : festivityPricesResources.PRICE_SISAY_PACHA.key,
    },
    PUZZLE_PRICE_PAWKAR_RAYMI : {
        id : 3,
        title : 'Pawkar Raymi',
        type : 'FESTIVIDADES',
        icon : festivityPricesResources.PRICE_PAWKAR_RAYMI.key,
    },
    PUZZLE_PRICE_LLULLU_MURU_RAYMI : {
        id : 4,
        title : 'Muru Raymi',
        type : 'FESTIVIDADES',
        icon : festivityPricesResources.PRICE_LLULLU_MURU_RAYMI.key,
    },
    PUZZLE_PRICE_ALLPA_CRUZ : {
        id : 5,
        title : 'Allpa Cruz',
        type : 'FESTIVIDADES',
        icon : festivityPricesResources.PRICE_ALLPA_CRUZ.key,
    },
    PUZZLE_PRICE_INTI_RAYMI : {
        id : 6,
        title : 'Inti Raymi',
        type : 'FESTIVIDADES',
        icon : festivityPricesResources.PRICE_INTI_RAYMI.key,
    },
    PUZZLE_PRICE_PALLAY_RAYMI : {
        id : 7,
        title : 'Pallay Raymi',
        type : 'FESTIVIDADES',
        icon : festivityPricesResources.PRICE_PALLAY_RAYMI.key,
    },
    PUZZLE_PRICE_SAWARIY : {
        id : 8,
        title : 'Sawariy',
        type : 'FESTIVIDADES',
        icon : festivityPricesResources.PRICE_SAWARIY.key,
    },
    PUZZLE_PRICE_KULLA_RAYMI : {
        id : 9,
        title : 'Kulla Raymi',
        type : 'FESTIVIDADES',
        icon : festivityPricesResources.PRICE_KULLA_RAYMI.key,
    },
    PUZZLE_PRICE_RIKSICHIY : {
        id : 10,
        title : 'Riksichiy',
        type : 'FESTIVIDADES',
        icon : festivityPricesResources.PRICE_RIKSICHIY.key,
    },
    PUZZLE_PRICE_VELANAKUY : {
        id : 11,
        title : 'Velanakuy',
        type : 'FESTIVIDADES',
        icon : festivityPricesResources.PRICE_VELANAKUY.key,
    },
    PUZZLE_PRICE_KAPAK_RAYMI : {
        id : 12,
        title : 'Kapak Raymi',
        type : 'FESTIVIDADES',
        icon : festivityPricesResources.PRICE_KAPAK_RAYMI.key,
    },
}

export const foodPuzzlePricesInformation = {
    PUZZLE_PRICE_CHUKLLU_KAMLLA : {
        id : 13,
        title : 'Chukllu Kamlla',
        type : 'COMIDAS',
        icon : foodPricesResources.PRICE_CHUKLLU_KAMLLA.key,
    },
    PUZZLE_PRICE_MILICHAKI : {
        id : 14,
        title : 'Milichaki',
        type : 'COMIDAS',
        icon : foodPricesResources.PRICE_MILICHAKI.key,
    },
    PUZZLE_PRICE_MISHKI_ZAMBO : {
        id : 15,
        title : 'Mishki Zambo',
        type : 'COMIDAS',
        icon : foodPricesResources.PRICE_MISHKI_ZAMBO.key,
    },
    PUZZLE_PRICE_UCHUKUTA : {
        id : 16,
        title : 'Uchukuta',
        type : 'COMIDAS',
        icon : foodPricesResources.PRICE_UCHUKUTA.key,
    },
    PUZZLE_PRICE_MUTI_LUCI : {
        id : 17,
        title : 'Muti Luci',
        type : 'COMIDAS',
        icon : foodPricesResources.PRICE_MUTI_LUCI.key,
    },
    PUZZLE_PRICE_CHAMPUS : {
        id : 18,
        title : 'Champus',
        type : 'COMIDAS',
        icon : foodPricesResources.PRICE_CHAMPUS.key,
    },
    PUZZLE_PRICE_MAKI_MACHIKA : {
        id : 19,
        title : 'Maki Machika',
        type : 'COMIDAS',
        icon : foodPricesResources.PRICE_MAKI_MACHIKA.key,
    },
    PUZZLE_PRICE_MISHKI_KAMLLA : {
        id : 20,
        title : 'Mishki Kamlla',
        type : 'COMIDAS',
        icon : foodPricesResources.PRICE_MISHKI_KAMLLA.key,
    },
    PUZZLE_PRICE_YAMUR_ASUA : {
        id : 21,
        title : 'Yamur Asua',
        type : 'COMIDAS',
        icon : foodPricesResources.PRICE_YAMUR_ASUA.key,
    },
    PUZZLE_PRICE_KATZU_KAMLLA : {
        id : 22,
        title : 'Katzu Kamlla',
        type : 'COMIDAS',
        icon : foodPricesResources.PRICE_KATZU_KAMLLA.key,
    },
    PUZZLE_PRICE_YANA_API : {
        id : 23,
        title : 'Yana Api',
        type : 'COMIDAS',
        icon : foodPricesResources.PRICE_YANA_API.key,
    },
    PUZZLE_PRICE_CHURU_API : {
        id : 24,
        title : 'Churu Api',
        type : 'COMIDAS',
        icon : foodPricesResources.PRICE_CHURU_API.key,
    }
}

export const signsSecretsPuzzlePricesInformation = {
    PUZZLE_PRICE_GROUP_01 : {
        id : 25,
        title : 'Perro Aullando',
        type : 'SEÑAS-SECRETOS',
        icon : signsSecretsPriceResources.PRICE_GROUP_01.key,
    },
    PUZZLE_PRICE_GROUP_02 : {
        id : 26,
        title : 'Durazno frondoso',
        type : 'SEÑAS-SECRETOS',
        icon : signsSecretsPriceResources.PRICE_GROUP_02.key,
    },
    PUZZLE_PRICE_GROUP_03 : {
        id : 27,
        title : 'Catzos Blancos',
        type : 'SEÑAS-SECRETOS',
        icon : signsSecretsPriceResources.PRICE_GROUP_03.key,
    },
    PUZZLE_PRICE_GROUP_04 : {
        id : 28,
        title : 'Mariposa Negra',
        type : 'SEÑAS-SECRETOS',
        icon : signsSecretsPriceResources.PRICE_GROUP_04.key,
    },
    PUZZLE_PRICE_GROUP_05 : {
        id : 29,
        title : 'Sapito Jambato',
        type : 'SEÑAS-SECRETOS',
        icon : signsSecretsPriceResources.PRICE_GROUP_05.key,
    },
    PUZZLE_PRICE_GROUP_06 : {
        id : 30,
        title : 'Pájaro Mashu',
        type : 'SEÑAS-SECRETOS',
        icon : signsSecretsPriceResources.PRICE_GROUP_06.key,
    },
    PUZZLE_PRICE_GROUP_07 : {
        id : 31,
        title : 'Quinde Azul',
        type : 'SEÑAS-SECRETOS',
        icon : signsSecretsPriceResources.PRICE_GROUP_07.key,
    },
    PUZZLE_PRICE_GROUP_08 : {
        id : 32,
        title : 'Angas Voladoras',
        type : 'SEÑAS-SECRETOS',
        icon : signsSecretsPriceResources.PRICE_GROUP_08.key,
    },
    PUZZLE_PRICE_GROUP_09 : {
        id : 33,
        title : 'Vientre Materno',
        type : 'SEÑAS-SECRETOS',
        icon : signsSecretsPriceResources.PRICE_GROUP_09.key,
    },
    PUZZLE_PRICE_GROUP_10 : {
        id : 34,
        title : 'Fogón Encandilado',
        type : 'SEÑAS-SECRETOS',
        icon : signsSecretsPriceResources.PRICE_GROUP_10.key,
    },
    PUZZLE_PRICE_GROUP_11 : {
        id : 35,
        title : 'Wirachuro',
        type : 'SEÑAS-SECRETOS',
        icon : signsSecretsPriceResources.PRICE_GROUP_11.key,
    },
    PUZZLE_PRICE_GROUP_12 : {
        id : 36,
        title : 'Cuscungo',
        type : 'SEÑAS-SECRETOS',
        icon : signsSecretsPriceResources.PRICE_GROUP_12.key,
    }
}

export const dialectsPuzzlePriceInformation = {
    PUZZLE_PRICE_DIALECTS_01 : {
        id : 37,
        title : 'Dialectos 01',
        type : 'DIALECTOS',
        icon : dialectsPriceResources.PRICE_DIALECT.key,
    },
    PUZZLE_PRICE_DIALECTS_02 : {
        id : 38,
        title : 'Dialectos 02',
        type : 'DIALECTOS',
        icon : dialectsPriceResources.PRICE_DIALECT.key,
    },
    PUZZLE_PRICE_DIALECTS_03 : {
        id : 39,
        title : 'Dialectos 03',
        type : 'DIALECTOS',
        icon : dialectsPriceResources.PRICE_DIALECT.key,
    },
    PUZZLE_PRICE_DIALECTS_04 : {
        id : 40,
        title : 'Dialectos 04',
        type : 'DIALECTOS',
        icon : dialectsPriceResources.PRICE_DIALECT.key,
    },
    PUZZLE_PRICE_DIALECTS_05 : {
        id : 41,
        title : 'Dialectos 05',
        type : 'DIALECTOS',
        icon : dialectsPriceResources.PRICE_DIALECT.key,
    },
    PUZZLE_PRICE_DIALECTS_06 : {
        id : 42,
        title : 'Dialectos 06',
        type : 'DIALECTOS',
        icon : dialectsPriceResources.PRICE_DIALECT.key,
    },
    PUZZLE_PRICE_DIALECTS_07 : {
        id : 43,
        title : 'Dialectos 07',
        type : 'DIALECTOS',
        icon : dialectsPriceResources.PRICE_DIALECT.key,
    },
    PUZZLE_PRICE_DIALECTS_08 : {
        id : 44,
        title : 'Dialectos 08',
        type : 'DIALECTOS',
        icon : dialectsPriceResources.PRICE_DIALECT.key,
    },
    PUZZLE_PRICE_DIALECTS_09 : {
        id : 45,
        title : 'Dialectos 09',
        type : 'DIALECTOS',
        icon : dialectsPriceResources.PRICE_DIALECT.key,
    },
    PUZZLE_PRICE_DIALECTS_10 : {
        id : 46,
        title : 'Dialectos 10',
        type : 'DIALECTOS',
        icon : dialectsPriceResources.PRICE_DIALECT.key,
    },
    PUZZLE_PRICE_DIALECTS_11 : {
        id : 47,
        title : 'Dialectos 11',
        type : 'DIALECTOS',
        icon : dialectsPriceResources.PRICE_DIALECT.key,
    },
    PUZZLE_PRICE_DIALECTS_12 : {
        id : 48,
        title : 'Dialectos 12',
        type : 'DIALECTOS',
        icon : dialectsPriceResources.PRICE_DIALECT.key,
    }
}

export const puzzlePiecesList = [
    ...Object.values(festivitiesPuzzlePricesInformation),
    ...Object.values(foodPuzzlePricesInformation),
    ...Object.values(signsSecretsPuzzlePricesInformation),
    ...Object.values(dialectsPuzzlePriceInformation)
]