import Phaser from 'phaser'
import { gridAlignGroupElements, addChildrenToContainer } from '../../../utils/functions';
import { assistantMenuResources } from '../../../utils/assetsPath';

export default class AssistantMenuButton extends Phaser.GameObjects.Container{
    constructor(scene,x,y,data){
        super(scene,x,y);
        this.setScene(scene);
        this.setInitialProperties(data);

        this.addComponents();
        this.buildAssistantButton();
    }

    setScene(scene){ this.scene = scene }
    getScene(){ return this.scene }

    setButtonTexture(texture){ this.buttonTexture = texture }
    getButtonTexture(){ return this.buttonTexture }

    setBrighteningEffect(effect){ this.brigtheningEffect = effect }
    getBrighteningEffect(){ return this.brigtheningEffect }

    setPressedButtonTexture(texture){ this.pressedButtonTexture = texture }
    getPressedButtonTexture(){ return this.pressedButtonTexture }

    setTitleTexture(texture){ this.titleTexture = texture }
    getTitleTexture(){ return this.titleTexture }

    setGridConfig(config){ this.gridConfig = config }
    getGridConfig(){ return this.gridConfig }

    setButton(button){ this.button = button }
    getButton(){ return this.button }

    setPressedButton(button){ this.pressedButton = button }
    getPressedButton(){ return this.pressedButton }

    setButtonTitle(title){ this.buttonTitle = title }
    getButtonTitle(){ return this.buttonTitle }


    setInitialProperties(data){
        this.setButtonTexture(data.buttonTexture);
        this.setPressedButtonTexture(data.pressedButtonTexture);
        this.setTitleTexture(data.titleTexture);

        this.setGridConfig({width : 1, height : 2, cellHeight : 230, position : 6});
    }

    createButtonComponent(){
        let scene = this.getScene(),
            texture = this.getButtonTexture(),
            button = scene.add.image(0,0,texture);
        this.setButton(button);
    }

    createPressedButtonComponent(){
        let scene = this.getScene(),
            texture = this.getPressedButtonTexture(),
            button = scene.add.image(0,0,texture);
        this.setPressedButton(button);
    }

    createButtonTitleComponent(){
        let scene = this.getScene(),
            texture = this.getTitleTexture(),
            title = scene.add.image(0,0,texture);
        this.setButtonTitle(title);
    }

    createBrighteningEffect(){
        let scene = this.getScene(),
            effect = scene.add.image(0,0,assistantMenuResources.BRIGHTENING_EFFECT.key);
        this.setBrighteningEffect(effect);
    }

    addComponents(){
        this.createBrighteningEffect();
        this.createButtonComponent();
        this.createButtonTitleComponent();
    }

    buildAssistantButton(){
        let scene = this.getScene(),
            effect = this.getBrighteningEffect(),
            button = this.getButton(),
            title = this.getButtonTitle(),
            gridConfig = this.getGridConfig(),
            group = scene.add.group([button,title]);
            this.add(effect);
            this.hideEffect();
        
        gridAlignGroupElements(scene,group,gridConfig);
        addChildrenToContainer(this,group.getChildren());
    }

    hideEffect(){ this.getBrighteningEffect().setVisible(false) }
    showEffect(){ this.getBrighteningEffect().setVisible(true) }

    activeButton(){
        let button = this.getButton();
        this.setSize(button.width,button.height).setInteractive({useHandCursor: true});
        this.on('pointerdown',()=>{this.changeToPressedButton()});
        this.on('pointerup',()=>{this.changeToNormalButton()});
        this.on('pointerover',()=>{this.showEffect()})
        this.on('pointerout',()=>{this.hideEffect()})
    }

    changeToPressedButton(){
        let button = this.getButton(),
        pressedButtonTexture = this.getPressedButtonTexture();
        button.setTexture(pressedButtonTexture);
    }

    colorButton(){
        let button = this.getButton();
        button.setTint(0x2d2d2d,0.1);
    }

    clearButton(){
        let button = this.getButton();
        button.clearTint();
    }

    changeToNormalButton(){
        let button = this.getButton(),
        buttonTexture = this.getButtonTexture();
        button.setTexture(buttonTexture);
    }


}