import Phaser from 'phaser';

import ContentScene from "../baseScenes/contentScene";
import { scenesKey } from "../../../utils/gameConstants";
import { creditsLines } from "../../../utils/information/credits/creditsDescription";
import { welcomeSceneResources } from '../../../utils/assetsPath';

export default class CreditsScene extends ContentScene{
    constructor(){
        super({key : scenesKey.CREDITS_SCENE});
        this.setInitialProperties();
    }

    create(){
        this.buildScene();
        this.createCredits();
        this.addCreditsTitle();
    }

    getContent(){ return this.content }
    setContent(content){ this.content = content }

    getTextMask(){ return this.textMask }
    setTextMask(mask){ this.textMask = mask }

    getSceneGraphics(){ return this.sceneGraphics }
    setSceneGraphics(graphics){ this.sceneGraphics = graphics }

    getSceneText(){ return this.sceneText }
    setSceneText(text){ this.sceneText = text }

    setTextStyle(style){ this.textStyle = style }
    getTextStyle(){ return this.textStyle }

    setCreditsContainer(container){ this.creditsContainer = container }
    getCreditsContainer(){ return this.creditsContainer }

    setInitialProperties(){
        this.setContent(creditsLines);
        this.setTextStyle({ fontFamily: 'Franklin Gothic Heavy', fontSize :'34px', color: '#ffffff', strokeThickness : 5, stroke :'#000000', wordWrap: { width: 533.5 }});
    }

    adjustTextFontSize(){
        let width = this.getGameWidth(), height = this.getGameHeight();
        let text = this.getSceneText();
        text.setScale(width*0.0007).setWordWrapWidth(800);
    }

    createContainer(){
        let gameWidth = this.getGameWidth(), gameHeight = this.getGameHeight();
        let containerWidth = gameWidth*0.66, containerHeight = gameHeight * 0.66,
            containerX = gameWidth/2 - containerWidth/2, containerY = gameHeight/2-containerHeight/2;
        let container = this.add.container(containerX,containerY);
        container.setSize(containerWidth,containerHeight);
        this.setCreditsContainer(container)
    }

    addCreditsTitle(){
        let gameWidth = this.getGameWidth(), gameHeight = this.getGameHeight();
        let title = this.add.image(gameWidth/2,gameHeight/2-gameHeight/4,welcomeSceneResources.TITLE_CREDITS.key);
        title.setScale(0.6);
    }

    createGraphics(){
        let container = this.getCreditsContainer(),
            graphics = this.add.graphics();
            // graphics.lineStyle(7,0xfff,1);
        graphics.fillRect(0,0,container.width,container.height);
        // graphics.strokeRect(0,0,container.width,container.height);
        this.setSceneGraphics(graphics);
        container.add(this.getSceneGraphics());
    }

    createMask(){
        let graphics = this.add.graphics(),
            container = this.getCreditsContainer();
            graphics.fillStyle('#000',0);
        // graphics.fillRect(0,230,container.width+container.width/5+10,200);
        graphics.fillRect(0,container.height/2+50,container.width+200,250);
        let mask = new Phaser.Display.Masks.GeometryMask(this,graphics);
        this.setTextMask(mask);
    }

    createText(){
        let container = this.getCreditsContainer(),
            Xposition = this.getGameWidth()/2-this.getGameWidth()/4,
            Yposition = this.getGameHeight()/2-this.getGameHeight()/4,
            content = this.getContent(), style = this.getTextStyle();
        let text = this.add.text(Xposition,Yposition,content,style).setOrigin(0);
        text.setMask(this.getTextMask());
        this.setSceneText(text);
    }

    createCredits(){
        this.createContainer();
        this.createGraphics();
        this.createMask();
        this.createText();
        this.adjustTextFontSize();

        this.moveCredits();
    }

    moveCredits(){
        let text = this.getSceneText();
        text.y -= 1;
        text.y = Phaser.Math.Clamp(text.y, -820, 300);
        
        setTimeout(()=>{this.moveCredits()},100);
    }
}