import Phaser from 'phaser'

export default class BubbleDialogButton extends Phaser.GameObjects.Container{
    constructor(scene,x,y,data){
        super(scene,x,y);
        this.setScene(scene);
        this.setInitialProperties(data);
        this.createButton();
        this.activeButton();
    }

    setScene(scene){ this.scene = scene }
    getScene(){ return this.scene }

    setButtonTexture(texture){ this.buttonTexture = texture }
    getButtonTexture(){ return this.buttonTexture }

    setPressedButtonTexture(texture){ this.pressedButtonTexture = texture }
    getPressedButtonTexture(){ return this.pressedButtonTexture }

    setButton(button){ this.button = button }
    getButton(){ return this.button }

    setInitialProperties(data){
        this.setButtonTexture(data.buttonTexture);
        this.setPressedButtonTexture(data.pressedButtonTexture);
    }

    createButton(){
        let scene = this.getScene(),
            texture = this.getButtonTexture(),
            button = scene.add.image(0,0,texture);
        this.setButton(button);
        this.add(this.getButton());
    }

    activeButton(){
        let button = this.getButton();
        this.setSize(button.width,button.height).setInteractive({useHandCursor: true});

        this.on('pointerdown',()=>{this.changeToPressedButton()});
        this.on('pointerup',()=>{this.changeToNormalButton()});
    }

    changeToPressedButton(){
        let button = this.getButton(),
            texture = this.getPressedButtonTexture();
        button.setTexture(texture);
    }

    changeToNormalButton(){
        let button = this.getButton(),
            texture = this.getButtonTexture();
        button.setTexture(texture);
    }

}